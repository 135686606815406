import { useState, Fragment, ReactNode } from 'react';
import Container from 'components/Container';
import useFetchDepartments from './useFetchDepartments';
import { Button, Card, Metric, Title } from '@tremor/react';
import Modal from 'components/Modal';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { DefaultTableDropDownTrigger } from 'components/TableDropdownTrigger';
import { DropdownMenu } from 'components/DropdownMenu';
import { createColumnHelper } from '@tanstack/react-table';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import { MODAL_LEAVE_SPEED } from 'config';
import { hasPermission, titleCase } from 'helpers';
import EditDepartmentForm from './EditDepartmentForm';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { HiExclamation } from 'react-icons/hi';
import { IDepartment, IFetchDepartmentsActionParams } from './departmentInterfaces';
import { deleteDepartment } from './departmentQueries';
import Table from 'components/Table';
import { DeleteIcon, EditIcon } from 'components/FormActionIcons';
import SearchInput from 'components/SearchInput';
import { TiCancelOutline } from 'react-icons/ti';
import { PermissionKeys } from 'features/auth/permissions';
import ContentBlockedFromView from 'components/ContentBlockedFromView';

interface DepartmentsTableColumns extends IDepartment {
	action: ReactNode;
}

const columnHelper = createColumnHelper<DepartmentsTableColumns>();

const DepartmentsPage = () => {
	const [fetchParams, setFetchParams] = useState<IFetchDepartmentsActionParams>({});
	const [isEditDepartmentFormOpen, setEditDepartmentFormOpen] = useState(false);
	const [isConfirmDeleteDepartmentDialogOpen, setConfirmDeleteDepartmentDialogOpen] = useState(false);
	const [currentDepartment, setCurrentDepartment] = useState<IDepartment | null>(null);

	const { isDepartmentsLoading, isDepartmentsFetching, refetchDepartments, departments, paginationMetadata } = useFetchDepartments({
		fetchParams,
		queryKey: fetchParams,
	});

	// ======================
	// * Delete Department
	// ======================
	const { isLoading: isDeleteDepartmentLoading, mutate: deleteDepartmentMutation } = useMutation({
		mutationKey: ['authorization/delete-department'],
		mutationFn: (departmentId: number) => deleteDepartment(departmentId),
	});

	const onConfirmDeleteDepartment = () => {
		if (!currentDepartment) return toast.error('No department selected for this action');

		deleteDepartmentMutation(currentDepartment.id, {
			onSuccess: () => {
				toast.success('Department deleted successfully.');
				setEditDepartmentFormOpen(false);
			},
			onError: () => {
				toast.error('Failed to delete department.');
			},
			onSettled() {
				setTimeout(() => setCurrentDepartment(null), MODAL_LEAVE_SPEED);
				refetchDepartments();
			},
		});

		setConfirmDeleteDepartmentDialogOpen(false);
	};

	const tableColumns = [
		columnHelper.accessor('action', {
			header: 'Actions',
			cell: (info) => {
				const menuOptions = [];

				if (hasPermission(PermissionKeys.UPDATE_DEPARTMENT))
					menuOptions.push({
						type: 'button',
						text: 'Update',
						action: (e: any) => {
							setCurrentDepartment(info.row.original);
							setEditDepartmentFormOpen(true);
							e.stopPropagation();
						},
						icon: <EditIcon />,
					});

				if (hasPermission(PermissionKeys.DELETE_DEPARTMENT))
					menuOptions.push({
						type: 'button',
						text: 'Delete',
						action: (e: any) => {
							setCurrentDepartment(info.row.original);
							setConfirmDeleteDepartmentDialogOpen(true);
							e.stopPropagation();
						},
						icon: <DeleteIcon />,
					});

				if (menuOptions.length === 0) {
					menuOptions.push({
						type: 'button',
						text: 'NOT AUTHORIZED',
						action: (e: any) => toast.error('You are not authorized perform any action on branches'),
						icon: <TiCancelOutline />,
					});
				}

				return <DropdownMenu renderTriggerElement={(state) => <DefaultTableDropDownTrigger state={state} />} menuOptions={menuOptions as any} />;
			},
		}),
		columnHelper.accessor('name', {
			cell: (info) => info.getValue(),
			header: 'Department Name',
			id: 'department_name',
		}),
		columnHelper.accessor('code', {
			cell: (info) => info.getValue(),
			header: 'Department Code',
			id: 'department_code',
		}),
	];

	return (
		<Container className='px-2 pt-4 pb-8 overflow-y-auto'>
			<Metric className='!text-2xl mb-4'>Departments</Metric>

			<div className='flex items-start justify-between gap-6'>
				{hasPermission(PermissionKeys.VIEW_DEPARTMENT) && (
					<SearchInput
						onSearch={(term) =>
							setFetchParams((prevValue) => ({
								...prevValue,
								filterBy: {
									...prevValue.filterBy,
									name: term,
								},
							}))
						}
						isLoading={isDepartmentsFetching && fetchParams.filterBy?.name ? true : false}
						className='lg:max-w-[400px]'
						placeholder='Search department name...'
					/>
				)}

				{hasPermission(PermissionKeys.UPDATE_DEPARTMENT) && (
					<Button className='!rounded-full' onClick={() => setEditDepartmentFormOpen(true)}>
						Create Department
					</Button>
				)}
			</div>

			<div>
				{hasPermission(PermissionKeys.VIEW_DEPARTMENT) ? (
					<Fragment>
						{!departments && isDepartmentsLoading ? (
							<div className='mt-6'>
								<ContentLoadingCardSpinner message='Loading departments...' />
							</div>
						) : departments && departments.length > 0 ? (
							<div className='mt-8'>
								<Card className='!p-0 pt-6 overflow-hidden'>
									<Title className='px-4 py-4 border-b'>All Departments</Title>

									<Table
										data={departments}
										columns={tableColumns}
										pagination={{
											handlePageChange: (page: number) => {
												setFetchParams((prevValue) => ({
													...prevValue,
													page,
												}));
											},
											metadata: paginationMetadata,
										}}
										useInbuiltPagination
									/>
								</Card>
							</div>
						) : (
							<SimpleEmptyResourceIndicator containerClassName='lg:mt-8 lg:!py-10' message='No department found' />
						)}
					</Fragment>
				) : (
					<div className='mt-6'>
						<ContentBlockedFromView message='You are not authorized to view departments.' containerClassName='min-h-[350px]' />
					</div>
				)}
			</div>

			<Modal
				width={600}
				closeButton={{ styles: 'bg-gray-200' }}
				isModalOpen={isEditDepartmentFormOpen}
				setModalOpen={setEditDepartmentFormOpen}
				onCloseModal={() => {
					setEditDepartmentFormOpen(false);
					setTimeout(() => setCurrentDepartment(null), 500);
				}}
				shouldCloseOnOutsideClick={false}
			>
				<EditDepartmentForm department={currentDepartment} setCurrentDepartment={setCurrentDepartment} setEditDepartmentFormOpen={setEditDepartmentFormOpen} refetchDepartments={refetchDepartments} />
			</Modal>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmDeleteDepartmentDialogOpen}
				setOpen={setConfirmDeleteDepartmentDialogOpen}
				message='Delete Department'
				subMessage={`Are you sure you want to delete ${titleCase(currentDepartment?.name as string) ?? 'this  department'}? This action cannot be reversed.`}
				onClose={() => {
					setTimeout(() => setCurrentDepartment(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmDeleteDepartment,
					label: 'Delete',
					isLoading: isDeleteDepartmentLoading,
					loadingText: 'Deleting',
					color: 'red',
				}}
				cancelButton={{
					action: () => setConfirmDeleteDepartmentDialogOpen(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-500/10`}>
						<HiExclamation className='w-6 h-6 text-red-500' aria-hidden='true' />
					</div>
				)}
			/>
		</Container>
	);
};

export default DepartmentsPage;
