import { useState, ReactNode } from 'react';
import { IInsuranceProductsFormValue, IProspect, IProspectInsuranceProduct, IUpdateProspectFormValues } from './prospectInterfaces';
import { useForm } from 'react-hook-form';
import { Button, Card, DatePicker, Divider, NumberInput, Select, SelectItem, Text, TextInput, Title } from '@tremor/react';
import { PiBuildingsFill, PiStepsFill } from 'react-icons/pi';
import { BiSolidBriefcaseAlt, BiSolidPhone } from 'react-icons/bi';
import useFetchBusinessTypes from 'features/settings/prospecting/businessType/useFetchBusinessTypes';
import useFetchSalesStages from 'features/settings/prospecting/useFetchSalesStages';
import useFetchDecisionMakers from 'features/settings/prospecting/decisionMakers/useFetchDecisionMakers';
import useFetchSourceTypes from 'features/settings/prospecting/sourceTypes/useFetchSourceTypes';
import { HiPlus, HiUser } from 'react-icons/hi2';
import { MdEmail } from 'react-icons/md';
import { BsPersonLinesFill } from 'react-icons/bs';
import { SiCrowdsource } from 'react-icons/si';
import { formatDate } from 'helpers/dateHelpers';
import { useMutation } from '@tanstack/react-query';
import { updateProspect } from './prospectQueries';
import toast from 'react-hot-toast';
import { formatCurrency, hasRole, isAdmin, processReactHookFormErrors } from 'helpers/index';
import Modal from 'components/Modal';
import { MODAL_LEAVE_SPEED } from 'config';
import EditCreateProspectInsuranceProductForm from './create/EditCreateProspectInsuranceProductForm';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { ConfirmationDialogIcon, DeleteIcon, EditIcon } from 'components/FormActionIcons';
import _ from 'lodash';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import { createColumnHelper } from '@tanstack/react-table';
import Table from 'components/Table';
import { DropdownMenu } from 'components/DropdownMenu';
import { DefaultTableDropDownTrigger } from 'components/TableDropdownTrigger';
import { TiCancelOutline } from 'react-icons/ti';
import { DefaultRoles } from 'features/settings/authorization/authorizationInterface';

interface Props {
	prospect: IProspect;
	afterUpdate: () => void;
}

interface ICreateProspectInsuranceProductTableColumns extends IProspectInsuranceProduct {
	action: ReactNode;
	commission: number;
}

const columnHelper = createColumnHelper<ICreateProspectInsuranceProductTableColumns>();

const UpdateProspectInformationForm = ({ prospect, afterUpdate }: Props) => {
	const [isEditProductFormOpen, setEditProductFormOpen] = useState<boolean>(false);
	const [products, setProducts] = useState<IProspectInsuranceProduct[]>(
		prospect?.insurance_products?.length > 0 ? prospect?.insurance_products.map((prod) => ({ ...prod, insurance_product: prod.insurance_product_type })) : []
	);
	const [currentProduct, setCurrentProduct] = useState<IInsuranceProductsFormValue | null>(null);
	const [isConfirmDeleteProductDialogOpen, setConfirmDeleteProductDialogOpen] = useState(false);

	const [selectFields, setSelectFields] = useState<IUpdateProspectFormValues>({
		...prospect,
		sale_stage_id: prospect?.sales_stage.id,
	} as any);
	const { businessTypes, isBusinessTypesLoading } = useFetchBusinessTypes({});
	const { decisionMakers, isDecisionMakersLoading } = useFetchDecisionMakers({});
	const { sourceTypes, isSourceTypesLoading } = useFetchSourceTypes({});

	const {
		setValue,
		setError,
		register,
		clearErrors,
		handleSubmit,
		formState: { errors },
	} = useForm<IUpdateProspectFormValues>({
		defaultValues: {
			...(prospect as any),
		},
	});

	const tableColumns = [
		columnHelper.accessor('action', {
			header: 'Actions',
			cell: (info) => {
				const product = info.row.original;

				const menuOptions = [
					{
						type: 'button',
						text: 'Edit Product',

						action: (e: any) => {
							if (product.user && !isAdmin() && hasRole(DefaultRoles.Marketer))
								return toast.error('You cannot modify recorded products, contact your manager for assistance.', { id: 'product-error' });

							setCurrentProduct({
								...product,
								commission: calculateCommission(product),
							});
							setEditProductFormOpen(true);
							e.stopPropagation();
						},
						icon: <EditIcon />,
					},
					{
						type: 'button',
						text: 'Delete Product',
						action: (e: any) => {
							if (product.user && !isAdmin() && hasRole(DefaultRoles.Marketer))
								return toast.error('You cannot delete recorded products, contact your manager for assistance.', { id: 'product-error' });

							setCurrentProduct(product);
							setConfirmDeleteProductDialogOpen(true);
							e.stopPropagation();
						},
						icon: <DeleteIcon />,
					},
				];

				return <DropdownMenu renderTriggerElement={(state) => <DefaultTableDropDownTrigger state={state} />} menuOptions={menuOptions as any} />;
			},
		}),
		columnHelper.accessor('insurance_product_type_id', {
			header: 'Product',
			cell: ({ row }) => {
				return <div className='sm:font-bold'>{row.original?.insurance_product?.name}</div>;
			},
		}),
		columnHelper.accessor('expected_close_date', {
			header: 'Expected Close Date',
			cell: ({ row }) => <div className='sm:font-semibold'>{formatDate(row.original.expected_close_date, 'Jan 31, 1999')}</div>,
		}),
		columnHelper.accessor('expected_premium', {
			header: 'Expected Premium',
			cell: ({ row }) => <div className='text-blue-600 sm:font-semibold'>{formatCurrency(row.original.expected_premium)}</div>,
		}),
		columnHelper.accessor('achieved_premium', {
			header: 'Achieved Premium',
			cell: ({ row }) => <div className='text-green-500 sm:font-semibold'>{formatCurrency(row.original.achieved_premium)}</div>,
		}),
		columnHelper.accessor('commission_rate', {
			header: 'Commission Rate',
			cell: ({ row }) => <div className='sm:font-semibold'>{row.original.commission_rate}%</div>,
		}),
		columnHelper.accessor('commission', {
			header: 'Commission',
			cell: ({ row }) => {
				return <div className='text-orange-600 sm:font-semibold'>{formatCurrency(calculateCommission(row.original))}</div>;
			},
		}),
	];

	const calculateCommission = (product: IProspectInsuranceProduct) =>
		product.achieved_premium ? product.achieved_premium * (Number(product.commission_rate) / 100) : product.expected_premium * (Number(product.commission_rate) / 100);

	// ============================
	// * Update Prospect
	// ============================
	const { isLoading: isUpdateProspectLoading, mutate: updateProspectMutation } = useMutation({
		mutationKey: ['authorization/update-prospect'],
		mutationFn: ({ formValues, prospectId }: { formValues: IUpdateProspectFormValues; prospectId: number }) => updateProspect({ formValues, prospectId }),
	});

	const onConfirmDeleteProduct = () => {
		if (!currentProduct) return toast.error('No product selected for this action');

		const existingProducts = [...products];
		const currentEditIndex = existingProducts.findIndex((p) => _.isEqual(p.insurance_product_type_id, currentProduct.insurance_product_type_id));
		existingProducts.splice(currentEditIndex, 1);
		setProducts(existingProducts);

		setCurrentProduct(null);
		setConfirmDeleteProductDialogOpen(false);
	};

	const onSubmit = (values: IUpdateProspectFormValues) => {
		const formValues: any = _.pickBy(values, _.identity);

		if (!prospect) return toast.error('No prospect to update');

		const { business_type_id, decision_maker_type_id, source_type_id, contact_date } = formValues;

		if (business_type_id === null || business_type_id === undefined) {
			return setError('business_type_id', { message: 'Business type is required' });
		} else if (decision_maker_type_id === null || decision_maker_type_id === undefined) {
			return setError('decision_maker_type_id', { message: 'Decision maker is required' });
		} else if (source_type_id === null || source_type_id === undefined) {
			return setError('source_type_id', { message: 'Source type is required' });
		} else if (contact_date === null || contact_date === undefined) {
			return setError('contact_date', { message: 'Contact date is required' });
		}

		formValues.insurance = products;

		updateProspectMutation(
			{ formValues, prospectId: prospect.id },
			{
				onSuccess: () => {
					toast.success('Prospect updated successfully!');
					afterUpdate?.();
				},
				onError: (error) => {
					processReactHookFormErrors(error, 'Failed to update user', setError);
				},
			}
		);
	};

	return (
		<form className='relative w-full h-[calc(100vh-80px)]' onSubmit={handleSubmit(onSubmit)}>
			<div className='overflow-y-auto h-[calc(100vh-150px)]'>
				{/* Prospect Info */}
				<section className='px-2 pt-4 pb-0 space-y-2 lg:px-4'>
					<Title>Prospect Information</Title>

					<div className='grid gap-4 lg:grid-cols-2'>
						<div className='w-full form-control'>
							<label htmlFor='company_name' className='required-field-indicator'>
								Company Name
							</label>

							<div>
								<TextInput
									type='text'
									id='company_name'
									icon={PiBuildingsFill}
									{...register('company_name', {
										required: {
											value: true,
											message: 'Company name is required',
										},
									})}
									placeholder='Enter prospect company name'
									error={errors.company_name ? true : false}
									errorMessage={errors.company_name?.message}
								/>
							</div>
						</div>

						<div className='w-full form-control'>
							<label htmlFor='contact_name' className='required-field-indicator'>
								Contact Name
							</label>

							<div>
								<TextInput
									type='text'
									id='contact_name'
									icon={HiUser}
									{...register('contact_name', {
										required: {
											value: true,
											message: 'Contact name is required',
										},
									})}
									placeholder='Enter contact name'
									error={errors.contact_name ? true : false}
									errorMessage={errors.contact_name?.message}
								/>
							</div>
						</div>
					</div>

					<div className='grid gap-4 lg:grid-cols-2'>
						<div className='w-full form-control'>
							<label htmlFor='contact_email'>Contact Email</label>

							<div>
								<TextInput
									type='text'
									id='email'
									icon={MdEmail}
									{...register('contact_email')}
									placeholder='Enter contact email'
									error={errors.contact_email ? true : false}
									errorMessage={errors.contact_email?.message}
								/>
							</div>
						</div>

						<div className='w-full form-control'>
							<label htmlFor='contact_phone' className='required-field-indicator'>
								Contact Phone
							</label>

							<div>
								<NumberInput
									id='contact_phone'
									icon={BiSolidPhone}
									{...register('contact_phone', {
										required: {
											value: true,
											message: 'Contact phone is required',
										},
									})}
									enableStepper={false}
									placeholder='Enter contact phone'
									error={errors.contact_phone ? true : false}
									errorMessage={errors.contact_phone?.message}
								/>
							</div>
						</div>
					</div>

					<div className='grid gap-4 lg:grid-cols-2'>
						<div className='w-full form-control'>
							<label htmlFor='type_of_business' className='required-field-indicator'>
								Type of Business
							</label>

							<div>
								<Select
									id='type_of_business'
									placeholder='Select type_of_business'
									icon={BiSolidBriefcaseAlt}
									disabled={isBusinessTypesLoading}
									value={selectFields?.business_type_id?.toString()}
									onValueChange={(val) => {
										setValue('business_type_id', Number(val));
										setSelectFields({
											...selectFields,
											business_type_id: Number(val),
										} as any);
										if (errors.business_type_id) clearErrors('business_type_id');
									}}
								>
									{businessTypes && businessTypes.length > 0 ? (
										businessTypes?.map((businessType, index) => (
											<SelectItem key={index} value={businessType.id.toString()}>
												{businessType.name}
											</SelectItem>
										))
									) : (
										<div className='px-2 py-2'>No Business Type</div>
									)}
								</Select>
								{errors?.business_type_id && <span className='form-message'>{errors?.business_type_id?.message}</span>}
							</div>
						</div>

						<div className='w-full form-control'>
							<label htmlFor='contact_date' className='required-field-indicator'>
								Initial Contact Date
							</label>

							<div>
								<DatePicker
									id='contact_date'
									enableClear
									className={`!max-w-full`}
									enableYearNavigation
									placeholder={'Select date...'}
									value={selectFields?.contact_date ? new Date(selectFields?.contact_date as any) : undefined}
									onValueChange={(val) => {
										setValue('contact_date', formatDate(val, '1999-12-31') as any);
										setSelectFields({
											...selectFields,
											contact_date: formatDate(val, '1999-12-31'),
										} as any);
										if (errors.contact_date) clearErrors('contact_date');
									}}
								/>
								{errors?.contact_date && <span className='form-message'>{errors?.contact_date?.message}</span>}
							</div>
						</div>
					</div>

					<div className='grid gap-4 lg:grid-cols-2'>
						<div className='w-full form-control'>
							<label htmlFor='source_type' className='required-field-indicator'>
								Source Type
							</label>

							<div>
								<Select
									id='source_type'
									icon={SiCrowdsource}
									placeholder='Select source type'
									disabled={isSourceTypesLoading}
									value={selectFields?.source_type_id?.toString()}
									onValueChange={(val) => {
										setValue('source_type_id', Number(val));
										setSelectFields({
											...selectFields,
											source_type_id: Number(val),
										} as any);
										if (errors.source_type_id) clearErrors('source_type_id');
									}}
								>
									{sourceTypes && sourceTypes.length > 0 ? (
										sourceTypes?.map((sourceType, index) => (
											<SelectItem key={index} value={sourceType.id.toString()}>
												{sourceType.name}
											</SelectItem>
										))
									) : (
										<div className='px-2 py-2'>No source type</div>
									)}
								</Select>
								{errors?.source_type_id && <span className='form-message'>{errors?.source_type_id?.message}</span>}
							</div>
						</div>

						<div className='w-full form-control'>
							<label htmlFor='decision_maker_type' className='required-field-indicator'>
								Decision Maker Type
							</label>

							<div>
								<Select
									id='decision_maker_type'
									icon={BsPersonLinesFill}
									placeholder='Select decision maker type'
									disabled={isDecisionMakersLoading}
									value={selectFields?.decision_maker_type_id?.toString()}
									onValueChange={(val) => {
										setValue('decision_maker_type_id', Number(val));
										setSelectFields({
											...selectFields,
											decision_maker_type_id: Number(val),
										} as any);
										if (errors.decision_maker_type_id) clearErrors('decision_maker_type_id');
									}}
								>
									{decisionMakers && decisionMakers.length > 0 ? (
										decisionMakers?.map((decisionMaker, index) => (
											<SelectItem key={index} value={decisionMaker.id.toString()}>
												{decisionMaker.name}
											</SelectItem>
										))
									) : (
										<div className='px-2 py-2'>No decision maker type</div>
									)}
								</Select>

								{errors?.decision_maker_type_id && <span className='form-message'>{errors?.decision_maker_type_id?.message}</span>}
							</div>
						</div>
					</div>
				</section>

				<Divider />

				{/* Sale Stage */}
				{/* <section className='px-2 py-0 space-y-2 lg:px-4'>
					<Title>Sale Stage</Title>

					<div className='w-full form-control'>
						<label htmlFor='sales_stage_id' className='required-field-indicator'>
							Select Stage
						</label>

						<div>
							<Select
								id='sales_stage_id'
								icon={PiStepsFill}
								disabled={true}
								value={selectFields?.sales_stage_id?.toString()}
								onValueChange={(val) => {
									setSelectFields({
										...selectFields,
										sales_stage_id: Number(val),
									} as any);
									setValue('sales_stage_id', Number(val));

									if (errors.sales_stage_id) clearErrors('sales_stage_id');
								}}
							>
								{salesStages && salesStages.length > 0 ? (
									salesStages?.map((stage, index) => (
										<SelectItem key={index} value={stage.id.toString()}>
											{stage.name}
										</SelectItem>
									))
								) : (
									<div className='px-2 py-2'>No sale stage</div>
								)}
							</Select>
							{errors?.sales_stage_id && <span className='form-message'>{errors?.sales_stage_id?.message}</span>}
						</div>
					</div>
				</section>

				<Divider /> */}

				{/* Insurance Products */}
				<section className='px-2 pb-6 lg:px-4'>
					<Title>Insurance Products</Title>

					{products && products.length > 0 ? (
						<div className='mt-4 lg:mt-8'>
							<Card className='!p-0 pt-6 overflow-hidden'>
								<div className='flex items-center justify-between pr-4 border-b'>
									<Title className='px-4 py-4 border-b'>Products</Title>

									<Button type='button' size='xs' variant='secondary' color='green' className='!rounded-full' icon={HiPlus} onClick={() => setEditProductFormOpen(true)}>
										Add Product
									</Button>
								</div>

								<Table data={products} columns={tableColumns} useInbuiltPagination />
							</Card>
						</div>
					) : (
						<SimpleEmptyResourceIndicator
							containerClassName='lg:mt-2 lg:!py-10'
							message='No product added'
							actionButton={{
								onClick: () => setEditProductFormOpen(true),
								text: 'Add Product',
							}}
						/>
					)}
				</section>
			</div>

			{/* Actions */}
			<section className='h-[70px] space-y-2 p-4 flex items-center gap-4 w-full bg-gray-100'>
				<Button type={'submit'} disabled={isUpdateProspectLoading} color={'green'} loading={isUpdateProspectLoading} loadingText={'Updating prospect...'} className='!rounded-full'>
					Save Changes
				</Button>
			</section>

			<Modal
				width={450}
				closeButton={{ styles: 'bg-gray-200' }}
				isModalOpen={isEditProductFormOpen}
				setModalOpen={setEditProductFormOpen}
				onCloseModal={() => {
					setEditProductFormOpen(false);
					setTimeout(() => setCurrentProduct(null), MODAL_LEAVE_SPEED);
				}}
			>
				<EditCreateProspectInsuranceProductForm
					setFormOpen={setEditProductFormOpen}
					product={currentProduct}
					setCurrentProduct={setCurrentProduct}
					setUpdateProspectFormProducts={setProducts as any}
					updateProspectFormProducts={products as any}
				/>
			</Modal>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmDeleteProductDialogOpen}
				setOpen={setConfirmDeleteProductDialogOpen}
				message='Delete Product'
				subMessage={`Are you sure you want to delete product?`}
				shouldCloseOnOutsideClick={false}
				onClose={() => {
					setCurrentProduct(null);
				}}
				confirmButton={{
					action: onConfirmDeleteProduct,
					label: 'Delete',
					color: 'red',
				}}
				cancelButton={{
					action: () => setConfirmDeleteProductDialogOpen(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-500/10`}>
						<ConfirmationDialogIcon className='w-6 h-6 text-red-500' aria-hidden='true' />
					</div>
				)}
			/>
		</form>
	);
};

export default UpdateProspectInformationForm;
