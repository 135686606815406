import { useState } from 'react';
import { IVehicleRequest } from '../vehicle-requests/vehicleRequestsInterfaces';
import { Button, Select, SelectItem, Title } from '@tremor/react';
import { useForm } from 'react-hook-form';
import useFetchVehicles from '../vehicles/useFetchVehicles';
import useFetchUsers from 'features/settings/users/useFetchUsers';
import { useMutation } from '@tanstack/react-query';
import { createVehicleAllocation, updateVehicleAllocation } from './vehicleAllocationQueries';
import { ICreateVehicleAllocationFormValues } from './vehicleAllocationInterfaces';
import toast from 'react-hot-toast';
import { processReactHookFormErrors } from 'helpers/index';
import { TRANSPORT_OFFICER_ROLE_ID } from 'config';

interface Props {
	vehicleRequest: IVehicleRequest;
	onCloseForm: () => void;
	afterEdit: () => void;
}

const AllocateVehicleForm = (props: Props) => {
	const { vehicleRequest, onCloseForm, afterEdit } = props;

	const [selectFields, setSelectFields] = useState<ICreateVehicleAllocationFormValues>({
		vehicle_request_id: vehicleRequest.id,
		transport_officer_id: vehicleRequest?.vehicle_allocation?.transport_officer_id ?? ('' as any),
		vehicle_id: vehicleRequest?.vehicle_allocation?.vehicle_id ?? ('' as any),
	});

	const { isVehiclesLoading, vehicles } = useFetchVehicles({});
	const { isUsersLoading, users } = useFetchUsers({
		fetchParams: {
			filterBy: {
				roleId: TRANSPORT_OFFICER_ROLE_ID,
			},
		},
	});

	// =============================
	// Create Vehicle Allocation
	// =============================
	const { isLoading: isCreateVehicleAllocationLoading, mutate: createVehicleAllocationMutation } = useMutation({
		mutationKey: ['vehicle-allocation/create-vehicle-allocation'],
		mutationFn: (formValues: ICreateVehicleAllocationFormValues) => createVehicleAllocation(formValues),
	});

	// =============================
	// Update Vehicle Allocation
	// =============================
	const { isLoading: isUpdateVehicleAllocationLoading, mutate: updateVehicleAllocationMutation } = useMutation({
		mutationKey: ['vehicle-allocation/update-vehicle-allocation'],
		mutationFn: ({ formValues, allocationId }: { formValues: ICreateVehicleAllocationFormValues; allocationId: number }) => updateVehicleAllocation(formValues, allocationId),
	});

	const {
		setValue,
		setError,
		clearErrors,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: {
			vehicle_request_id: vehicleRequest.id,
			transport_officer_id: vehicleRequest?.vehicle_allocation?.transport_officer_id,
			vehicle_id: vehicleRequest?.vehicle_allocation?.vehicle_id,
		},
	});

	const onSubmit = (formValues: ICreateVehicleAllocationFormValues) => {
		const { transport_officer_id, vehicle_id } = formValues;

		if (!transport_officer_id)
			return setError('transport_officer_id', {
				message: 'Transport officer is required',
			});
		if (!vehicle_id)
			return setError('vehicle_id', {
				message: 'Vehicle is required',
			});

		if (vehicleRequest.vehicle_allocation)
			return updateVehicleAllocationMutation(
				{ formValues, allocationId: vehicleRequest.vehicle_allocation?.id },
				{
					onSuccess: () => {
						toast.success('Allocation updated successfully!');
						afterEdit();
					},
					onError: (error) => {
						processReactHookFormErrors(error, 'Failed to update allocation', setError);
					},
				}
			);

		createVehicleAllocationMutation(formValues, {
			onSuccess: () => {
				toast.success('Vehicle allocated successfully!');
				afterEdit();
			},
			onError: (error) => {
				processReactHookFormErrors(error, 'Failed to allocate vehicle', setError);
			},
		});
	};

	return (
		<form className='w-full' onSubmit={handleSubmit(onSubmit)}>
			<header
				className={`${
					vehicleRequest?.vehicle_allocation ? 'bg-gradient-to-r from-gray-800 to-gray-500' : 'bg-gradient-to-r from-primary-600 to-primary-300'
				} bg-dark p-6 h-[80px] flex items-center gap-2`}
			>
				<Title className='text-white'>{vehicleRequest?.vehicle_allocation ? 'Update Vehicle Allocation' : 'Allocate Vehicle'}</Title>
			</header>

			<section className='p-6 space-y-3 text-left'>
				<div className='form-control'>
					<label htmlFor='transport_officer_id' className='required-field-indicator'>
						Transport Officer
					</label>

					<div>
						<Select
							value={selectFields.transport_officer_id?.toString()}
							onValueChange={(val) => {
								setValue('transport_officer_id', Number(val));
								setSelectFields({
									...selectFields,
									transport_officer_id: Number(val),
								});
								if (errors.transport_officer_id) clearErrors('transport_officer_id');
							}}
							id='transport_officer'
							placeholder='Select transport officer'
							disabled={isUsersLoading}
						>
							{users && users.length > 0 ? (
								users?.map((user, index) => (
									<SelectItem key={index} value={user.id.toString()}>
										{user.first_name} {user.middle_name} {user.last_name}
									</SelectItem>
								))
							) : (
								<div>No user</div>
							)}
						</Select>

						{errors?.transport_officer_id && <span className='form-message'>{errors?.transport_officer_id?.message}</span>}
					</div>
				</div>

				<div className='form-control'>
					<label htmlFor='vehicle' className='required-field-indicator'>
						Vehicle
					</label>

					<div>
						<Select
							value={selectFields.vehicle_id?.toString()}
							onValueChange={(val) => {
								setValue('vehicle_id', Number(val));
								setSelectFields({
									...selectFields,
									vehicle_id: Number(val),
								});
								if (errors.vehicle_id) clearErrors('vehicle_id');
							}}
							id='vehicle'
							placeholder='Select vehicle'
							disabled={isVehiclesLoading}
						>
							{vehicles && vehicles.length > 0 ? (
								vehicles?.map((vehicle, index) => (
									<SelectItem key={index} value={vehicle.id.toString()}>
										{vehicle.model} ({vehicle.number})
									</SelectItem>
								))
							) : (
								<div className='px-4 py-2'>No vehicle</div>
							)}
						</Select>

						{errors?.vehicle_id && <span className='form-message'>{errors?.vehicle_id?.message}</span>}
					</div>
				</div>
			</section>

			<footer className='flex items-center justify-end gap-2 p-6 bg-gray-100 border-t'>
				<Button onClick={() => onCloseForm()} type='button' color='gray' variant='secondary' className='px-6 py-3 duration-300 !rounded-full'>
					Cancel
				</Button>

				<Button
					type='submit'
					loading={isCreateVehicleAllocationLoading || isUpdateVehicleAllocationLoading}
					loadingText={vehicleRequest?.vehicle_allocation ? 'Reallocating' : 'Allocating'}
					disabled={isCreateVehicleAllocationLoading}
					className='px-6 py-3 duration-300 !rounded-full'
				>
					{vehicleRequest?.vehicle_allocation ? 'Update Allocation' : 'Allocate Vehicle'}
				</Button>
			</footer>
		</form>
	);
};

export default AllocateVehicleForm;
