import { NavLink, useLocation } from 'react-router-dom';
import SubMenu from './SubMenu';
import _ from 'lodash';
import { VscChevronRight } from 'react-icons/vsc';
import { AppNavigationMenu, AppNavigationMenuItem, AppNavigationSubMenuItem } from 'features/settings/settingsInterfaces';
import { useAppDispatch } from 'hooks/storeConnect';
import { setActiveAppNavigationMenuItem } from 'features/settings/settingsSlice';

interface Props {
	links: AppNavigationMenu['links'];
	activeMenu: string;
	setActiveMenu: (val: string) => void;
}

const Menu = (props: Props) => {
	const { links, activeMenu, setActiveMenu } = props || {};

	const dispatch = useAppDispatch();
	const location = useLocation();

	const isActiveLinkNested = (menu: any[], pathname: string): boolean => {
		for (const menuItem of menu) {
			if (isNested(menuItem, pathname)) {
				return true; // You can define your own class name for the active state
			}
		}

		return false;
	};

	const isNested = (obj: any, target: any): boolean => {
		if (_.isEqual(obj, target)) {
			return true;
		}

		if (_.isObject(obj)) {
			return _.some(obj, (value: any) => isNested(value, target));
		}

		if (_.isArray(obj)) {
			return _.some(obj, (value: any) => isNested(value, target));
		}

		return false;
	};

	const countMenusAndSubmenus = (menu: AppNavigationSubMenuItem[]): number => {
		let count = 0;

		const countSubmenus = (submenu: AppNavigationSubMenuItem[]): void => {
			submenu.forEach((submenuItem) => {
				count++;
				if (submenuItem?.menu) {
					countSubmenus(submenuItem.menu);
				}
			});
		};

		menu.forEach((menuItem) => {
			count++;
			if (menuItem?.menu) {
				countSubmenus(menuItem.menu);
			}
		});

		return count;
	};

	const onLinkSelected = (menu: AppNavigationMenuItem | undefined) => {
		const menuOption: any = {
			...menu,
			icon: 'Icon',
		};
		dispatch(setActiveAppNavigationMenuItem(menuOption || null));
	};

	return (
		<ul className=''>
			{links.map((link, index) => {
				if (!link) return null;

				const { name, menu, icon, url, disabled } = link || {};

				if (disabled) return '';

				const isChildLinkActive = menu && isActiveLinkNested(menu, location.pathname);
				const isMainLinkActive = url === location.pathname;

				return (
					<li key={index} className='group/main'>
						<NavLink
							to={url}
							onClick={() => onLinkSelected(link)}
							className={`${isMainLinkActive ? `before:absolute before:inset-0 before:z-10 before:bg-primary-500/70` : ''} relative z-20 flex items-center py-1`}
						>
							{/* // > Icon */}
							<span
								className={`${
									isMainLinkActive || isChildLinkActive ? 'before:!translate-x-0 before:!border-primary-700/50 before:!bg-primary-100' : ''
								} relative inset-0 z-20 inline-flex h-8 items-center py-1 pr-2 before:absolute before:inline-block before:h-full before:w-full before:-translate-x-full before:rounded-r-lg before:border-r-2 before:border-primary-700/50 before:bg-primary-100 before:duration-200 before:group-hover/main:translate-x-0`}
							>
								<span
									className={`${
										isMainLinkActive || isChildLinkActive ? '!text-primary-700/50' : ''
									} relative z-20 ml-4 inline-block h-5 w-5 text-gray-600/70 duration-200 group-hover/main:text-primary-700/50`}
								>
									{icon}
								</span>
							</span>

							{/* // > Text */}
							<span
								className={`${
									isMainLinkActive ? '!translate-x-2 !text-white' : isChildLinkActive ? '!translate-x-2' : ''
								} relative z-20 inline-block pl-1.5 text-sm font-medium text-gray-600 duration-300 group-hover/main:translate-x-2 group-hover/main:text-primary-700/70`}
							>
								{name}
							</span>

							{/* // > Toggle Submenu */}
							{menu && menu?.length > 0 && (
								<button
									title='Expand/Collapse'
									type='button'
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										setActiveMenu(name.toLowerCase());
									}}
									className={`${activeMenu === name.toLowerCase() ? '-rotate-[270deg]' : 'rotate-0'} ${
										isMainLinkActive ? '!text-white hover:!bg-gray-500' : 'text-gray-600 hover:bg-gray-200'
									} relative z-30 ml-auto mr-2 flex h-6 w-6 shrink-0 items-center justify-center rounded-full duration-300`}
								>
									<VscChevronRight className='w-4 h-4' />
								</button>
							)}
						</NavLink>

						{/* // > Submenu */}
						{menu && (
							<div
								className={`h-fit overflow-hidden duration-300`}
								style={{
									maxHeight: activeMenu === name?.toLowerCase() ? countMenusAndSubmenus(menu) * 40 + 'px' : '0px',
								}}
							>
								<SubMenu menu={menu} activeAppMenuOption={link} onLinkSelected={onLinkSelected} countMenusAndSubmenus={countMenusAndSubmenus} />
							</div>
						)}
					</li>
				);
			})}
		</ul>
	);
};

export default Menu;
