import AppNavigation from 'components/AppNavigation';
import appNavigationMenu from 'components/Appbar/appNavigationMenu';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const ProspectingSettingsLayout = () => {
	const { pathname } = useLocation();

	if (pathname === '/app/settings/admin/prospecting') return <Navigate to={'/app/settings/admin/prospecting/sales-stages'} />;

	const getSettingsNavigationMenuLinks = () => appNavigationMenu.find((item) => item.id === 'settings')?.links.find((link) => link?.id === 'prospecting-settings');

	return (
		<div className='h-[100%] w-full relative'>
			<div className='hidden sm:block sticky inset-x-0 top-0 z-20 h-[var(--app-navigation-height)]'>
				<AppNavigation activeAppNavigationMenuOption={getSettingsNavigationMenuLinks() as any} />
			</div>

			<Outlet />
		</div>
	);
};

export default ProspectingSettingsLayout;
