import { Button, Title } from '@tremor/react';
import BottomSheet from 'components/BottomSheet';
import Container from 'components/Container';
import Filters from 'components/Filters';
import { InteractionIcon } from 'components/FormActionIcons';
import Modal from 'components/Modal';
import EditInteractionForm from 'features/interactions/EditInteractionForm';
import Interaction from 'features/interactions/Interaction';
import { IFetchInteractionsActionParams, IInteraction } from 'features/interactions/interactionInterfaces';
import useFetchInteractions from 'features/interactions/useFetchInteractions';
import useFetchSalesStages from 'features/settings/prospecting/useFetchSalesStages';
import { FilterComponentTypes } from 'features/settings/settingsInterfaces';
import { formatDateRangePickerValue } from 'helpers/dateHelpers';
import { useAppSelector } from 'hooks/storeConnect';
import { useState } from 'react';
import { RiFilter2Fill } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { prospectsState } from '../prospectsSlice';
import { IProspect } from '../prospectInterfaces';
import useFetchInteractionTypes from 'features/settings/prospecting/interactionTypes/useFetchInteractionTypes';
import InteractionsPane from 'features/interactions/InteractionsPane';

const ProspectInteractionsPage = () => {
	const { id } = useParams();
	const { activeProspect } = useAppSelector(prospectsState);
	const [fetchParams, setFetchParams] = useState<IFetchInteractionsActionParams>({
		includes: ['sales_stage', 'user', 'prospect', 'interaction_type'],
		filterBy: {
			prospectId: Number(id),
		},
	});

	const [filters, setFilters] = useState({
		prospectId: Number(id),
		createdBetween: undefined as any,
		interactionTypeId: undefined as any,
		saleStageId: undefined as any,
	});
	const [isEditInteractionModalOpen, setEditInteractionModalOpen] = useState(false);
	const [isMobileFiltersOpen, setMobileFiltersOpen] = useState<boolean>(false);
	const [currentInteraction, setCurrentInteraction] = useState<IInteraction | null>(null);

	const { interactionTypes, isInteractionTypesLoading } = useFetchInteractionTypes({});
	const { salesStages, isSalesStagesLoading } = useFetchSalesStages({});
	const { interactions, isInteractionsLoading, refetchInteractions } = useFetchInteractions({
		fetchParams,
		queryKey: fetchParams,
	});

	const renderInteractions = (interactions: IInteraction[]) => interactions.map((interaction, index) => <Interaction key={index} interaction={interaction} />);

	const onSubmitFilters = (filters: { [key: string]: any }) => {
		setFetchParams({
			...fetchParams,
			filterBy: {
				prospectId: Number(id),
				createdBetween: filters.createdBetween?.to ? formatDateRangePickerValue(filters.createdBetween) : '',
				interactionTypeId: filters.interactionTypeId,
				saleStageId: filters.saleStageId,
			},
		});

		setMobileFiltersOpen(false);
	};

	return (
		<Container className='pb-4'>
			<div className='flex flex-col items-start justify-between h-full gap-4 py-6'>
				<Title className='lg:hidden'>All Interactions</Title>

				<div className='flex items-center justify-between w-full'>
					<Title className='hidden lg:uppercase lg:block'>All Interactions</Title>

					{/* {hasPermission(PermissionKeys.VIEW_INTERACTION) && ( */}
					<Button
						color='gray'
						variant='secondary'
						onClick={() => setMobileFiltersOpen(true)}
						icon={RiFilter2Fill}
						className='!rounded-full hover:bg-gray-700 hover:!text-gray-100 duration-300 lg:hidden'
					>
						Filters
					</Button>
					{/* )} */}

					{/* {hasPermission(PermissionKeys.CREATE_INTERACTION) && ( */}
					<Button onClick={() => setEditInteractionModalOpen(true)} className='!rounded-full !gap-2' icon={() => <InteractionIcon variant='light' />}>
						Create Interaction
					</Button>
					{/* )} */}
				</div>
			</div>

			{/* {hasPermission(PermissionKeys.VIEW_INTERACTION) ? ( */}
			<InteractionsPane
				interactions={interactions}
				isInteractionsLoading={isInteractionsLoading}
				renderInteractions={renderInteractions}
				filters={filters}
				setFilters={setFilters}
				interactionTypes={interactionTypes}
				isInteractionTypesLoading={isInteractionTypesLoading}
				salesStages={salesStages}
				isSalesStagesLoading={isSalesStagesLoading}
				fetchParams={fetchParams}
				setFetchParams={setFetchParams}
			/>
			{/* ) : (
				<ContentBlockedFromView message='You are not authorized to view interactions.' containerClassName='min-h-[350px]' />
			)} */}

			<Modal
				width={600}
				closeButton={{ styles: 'bg-gray-200' }}
				isModalOpen={isEditInteractionModalOpen}
				setModalOpen={setEditInteractionModalOpen}
				onCloseModal={() => {
					setEditInteractionModalOpen(false);
					setTimeout(() => setCurrentInteraction(null), 500);
				}}
				shouldCloseOnOutsideClick={false}
			>
				<EditInteractionForm
					interaction={currentInteraction}
					prospect={activeProspect as IProspect}
					afterEdit={() => {
						refetchInteractions();
						setEditInteractionModalOpen(false);
					}}
					setEditInteractionModalOpen={setEditInteractionModalOpen}
				/>
			</Modal>

			<BottomSheet
				isOpen={isMobileFiltersOpen}
				setOpen={setMobileFiltersOpen}
				closeOnOutSideClick={true}
				header={{
					text: 'Filter prospect Interactions',
				}}
			>
				<Filters
					filters={[
						{
							type: FilterComponentTypes.DateRange,
							label: 'Interaction Dates',
							name: 'createdBetween',
							placeholder: 'Select date interval',
						},
						{
							type: FilterComponentTypes.Select,
							label: 'Prospect Interaction Stage',
							name: 'saleStageId',
							dropdownOptions:
								salesStages?.map((stage) => ({
									id: stage.id,
									value: stage.id,
									label: stage.name,
								})) ?? [],
							placeholder: 'Select interaction stage',
						},
						{
							type: FilterComponentTypes.Select,
							label: 'Interaction Type',
							name: 'interactionTypeId',
							dropdownOptions:
								interactionTypes?.map((type) => ({
									id: type.id,
									value: type.id,
									label: type.name,
								})) ?? [],
							placeholder: 'Select interaction type',
						},
					]}
					submitButton={{
						label: 'Apply Filters',
						onSubmit: onSubmitFilters,
					}}
				/>
			</BottomSheet>
		</Container>
	);
};

export default ProspectInteractionsPage;
