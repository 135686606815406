import { Card, Text, Title } from '@tremor/react';
import { ReactNode, useRef, useLayoutEffect, useState } from 'react';

interface Props {
	header: {
		type: 'filled' | 'light' | 'success' | 'danger' | 'info';
		primaryText: string;
		superText?: string;
		secondaryContent?: ReactNode;
	};
	children: ReactNode;
	containerClassName?: string;
	height?: number;
}

const InfoCard = (props: Props) => {
	const { header, height, children, containerClassName } = props;
	const headerRef = useRef<HTMLElement | null>(null);
	const containerRef = useRef<HTMLElement | null>(null);

	const [headerHeight, setHeaderHeight] = useState(0);
	const [containerHeight, setContainerHeight] = useState(0);

	useLayoutEffect(() => {
		setHeaderHeight(headerRef?.current?.offsetHeight as number);
		setContainerHeight(containerRef?.current?.offsetHeight as number);
	}, []);

	const getHeaderStyle = () =>
		header.type === 'filled' ? 'bg-gray-200' : header.type === 'success' ? 'bg-green-200' : header.type === 'danger' ? 'bg-red-200' : header.type === 'info' ? 'bg-blue-200' : 'bg-gray-50';

	return (
		<Card ref={containerRef as any} className={`!p-0 overflow-hidden ${containerClassName}`}>
			<header ref={headerRef} className={`h-fit flex gap-4 items-center justify-between p-3 border-b ${getHeaderStyle()}`}>
				<div className='flex-1'>
					{header?.superText && <Title className='!text-xs font-normal uppercase'>{header?.superText}</Title>}
					<Title className='!text-base !text-black'>{header.primaryText}</Title>
				</div>
				{header?.secondaryContent && <div className='h-full flex items-center'>{header?.secondaryContent}</div>}
			</header>

			<div className='with-scrollbar overflow-y-auto overflow-x-hidden flex-1' style={{ height: height ? height - headerHeight : containerHeight ? containerHeight - headerHeight : undefined }}>
				{children}
			</div>
		</Card>
	);
};

export default InfoCard;
