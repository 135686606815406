import { DatePickerValue, DateRangePickerValue } from '@tremor/react';
import { PermissionKeys } from 'features/auth/permissions';

export interface SettingsState {
	isMobileSidebarOpen: boolean;
	activeAppNavigationMenuOption: AppNavigationMenuItem | null;
	settingsError: any;
}

export interface AppNavigationMenu {
	links: AppNavigationMenuItem[];
	title?: string;
	id?: string;
	disabled?: boolean;
	descriptions?: string;
}

export type AppNavigationMenuItem = {
	name: string;
	id?: string;
	url: string;
	description: string;
	icon: any;
	disabled?: boolean;
	menu?: AppNavigationSubMenuItem[];
} | null;

export type AppNavigationSubMenuItem = AppNavigationSubMenuLink | AppNavigationSubMenuButton;

export type AppNavigationSubMenuLink = {
	name: string;
	id?: string;
	url: string;
	type: 'link';
	disabled?: boolean;
	menu?: AppNavigationSubMenuItem[];
} | null;

export interface AppNavigationSubMenuButton {
	name: string;
	id?: string;
	action: (e: any) => void;
	url: string;
	type: 'button';
	disabled?: boolean;
	menu?: AppNavigationSubMenuItem[];
}

export enum GeneralFilterNames {
	CreatedAt = 'created_at',
	UpdatedAt = 'updated_at',
	CreatedBetween = 'created_between',
	Staff = 'staff',
	User = 'user',
}
export enum SpecialFilter {
	LastInteractionDate = 'last_interaction',
}
export type AppFilters = GeneralFilterNames | SpecialFilter;

export interface INamedFilter {
	name: GeneralFilterNames;
	filter: IFilter;
}

export enum FilterComponentTypes {
	TextInput = 'textInput',
	Select = 'select',
	Date = 'date',
	DateRange = 'dateRange',
	DateRangeWithSelect = 'dateRangeWithSelect',
	Switch = 'switch',
}
export type IFilter = ISelectFilter | ITextInputFilter | IDateFilter | IDateRangeFilter | ISwitch | IDateRangeWithSelectFilter;
export type IBaseFilterFields = {
	label: string;
	name: string;
	className?: string;
  permission?: PermissionKeys;
	hideLabel?: boolean;
};
export type ISelectFilter = IBaseFilterFields & {
	type: FilterComponentTypes.Select;
	defaultValue?: string;
	placeholder: string;
	dropdownOptions: IDropdownOption[];
};
export type ITextInputFilter = IBaseFilterFields & {
	type: FilterComponentTypes.TextInput;
	defaultValue?: string;
	placeholder: string;
};
export type IDateFilter = IBaseFilterFields & {
	type: FilterComponentTypes.Date;
	name: string;
	defaultValue?: DatePickerValue | null;
	placeholder: string;
};
export type IDateRangeFilter = IBaseFilterFields & {
	type: FilterComponentTypes.DateRange;
	name: string;
	defaultValue?: DateRangePickerValue;
	minDate?: Date;
	maxDate?: Date;
	placeholder: string;
};
export type IDateRangeWithSelectFilter = {
	type: FilterComponentTypes.DateRangeWithSelect;
	select: ISelectFilter;
	dateRange: IDateRangeFilter;
	hideLabel?: boolean;
  permission?: PermissionKeys;
	label: string;
};
// export type IDateRangeWithSelectFilter = IBaseFilterFields & {
// 	type: FilterComponentTypes.DateRangeWithSelect;
// 	selectOptions: IDropdownOption[];
// 	enableClear?: boolean;
// 	minDate?: Date;
// 	maxDate?: Date;
// 	dateRangePlaceholder?: string;
// 	selectPlaceholder?: string;
// 	defaultSelectValue?: IDropdownOption;
// 	defaultDateRangeValue?: DateRangePickerValue | null | undefined;
// };
export type ISwitch = IBaseFilterFields & {
	type: FilterComponentTypes.Switch;
	name: string;
	defaultValue?: IDropdownOption;
	icon: React.ReactNode;
};
