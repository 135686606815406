import { IMarketingManagerDashboardData } from './dashboardInterfaces';
import { IReport } from 'features/reports/reportInterfaces';
import { hasRole, hasRoles, isAdmin } from 'helpers/index';
import { DefaultRoles } from 'features/settings/authorization/authorizationInterface';
import { useAppSelector } from 'hooks/storeConnect';
import { authState } from 'features/auth/authSlice';
import StatisticsCardGroup from 'components/StatisticsCard/StatisticsCardGroup';
import StatisticsCardItem from 'components/StatisticsCard/StatisticsCardItem';
import { useNavigate } from 'react-router-dom';

interface Props {
	data: IMarketingManagerDashboardData | undefined;
	isDataLoading: boolean;
	reports: IReport | undefined;
	isReportsLoading: boolean;
}

const MarketingDashboardStatisticsCards = ({ data, isDataLoading, reports, isReportsLoading }: Props) => {
	const navigate = useNavigate();
	const { authUser } = useAppSelector(authState);
	if (!authUser) return <div></div>;

	// Todo: Change first name to user id
	const prospectClosingPercentage = reports?.closing_percentage.find((percentage) => percentage.name.toLowerCase().includes(authUser.first_name.toLowerCase()))?.closing_percentage ?? 0;

	return (
		<StatisticsCardGroup decorationColor='sky'>
			{(isAdmin() || hasRole(DefaultRoles.MarketingManager)) && <StatisticsCardItem title='Total Prospects' metric={(data?.total_prospects ?? 0) as any} loading={isDataLoading} />}

			<StatisticsCardItem unitPrefix='GH₵' title='Total Expected Revenue' metric={(data?.total_expected_revenue ?? 0) as any} loading={isDataLoading} />

			<StatisticsCardItem unitPrefix='GH₵' title='Total Achieved Revenue' metric={(data?.total_achieved_revenue ?? 0) as any} loading={isDataLoading} />

			<StatisticsCardItem title='Total Pending Reminders' metric={(reports?.total_follow_ups[reports?.total_follow_ups.length - 1]?.pending ?? 0) as any} loading={isDataLoading} />
			<StatisticsCardItem title='Total Completed Reminders' metric={(reports?.total_follow_ups[reports?.total_follow_ups.length - 1]?.done ?? 0) as any} loading={isDataLoading} />

			<StatisticsCardItem unitPrefix='GH₵' title='Average Achieved Premium' metric={(reports?.total_aggregate.avg_achieved_premium ?? 0) as any} loading={isReportsLoading} />

			<StatisticsCardItem title='Number of Won Opportunities' metric={(reports?.total_aggregate.won_opportunities ?? 0) as any} loading={isReportsLoading} />

			<StatisticsCardItem unitPrefix='GH₵' title='Yearly Target' metric={(data?.total_yearly_target ?? 0) as any} loading={isDataLoading} />

			<StatisticsCardItem
				action={
					isAdmin() || hasRole(DefaultRoles.MarketingManager)
						? {
								// hoverMessage: 'View all staff closing percentage',
								onClick(data) {
									navigate('/app/dashboard/marketing/reports/staff-closing-percentage');
								},
								actionLabel: 'View for all staff',
						  }
						: undefined
				}
				title='Your Prospects Closing Percentage'
				unitSuffix='%'
				metric={(prospectClosingPercentage ?? 0) as any}
				loading={isReportsLoading}
			/>
		</StatisticsCardGroup>
	);
};

export default MarketingDashboardStatisticsCards;
