import { useQuery, RefetchOptions, RefetchQueryFilters, QueryObserverResult } from '@tanstack/react-query';
import { fetchSalesStages } from './prospectSettingsQueuries';
import { IFetchSalesStagesActionParams, ISalesStage } from './prospectSettingsInterfaces';

interface ReturnType {
	salesStages: ISalesStage[] | undefined;
	isSalesStagesLoading: boolean;
	refetchSalesStages: (options?: RefetchOptions & RefetchQueryFilters) => Promise<QueryObserverResult>;
}

interface Props {
	queryKey?: string;
	fetchParams?: IFetchSalesStagesActionParams;
	enabled?: boolean;
	onSuccess?: (salesStages: ISalesStage[]) => void;
}

const useFetchSalesStages = ({ fetchParams, queryKey, enabled, onSuccess }: Props): ReturnType => {
	const { data, isLoading, refetch } = useQuery({
		queryKey: [queryKey ?? 'auth/fetch-salesStages', fetchParams],
		queryFn: () => fetchSalesStages(),
		enabled,
		onSuccess: onSuccess ? (data) => onSuccess?.(data?.data) : undefined,
	});

	const salesStageData = data?.data?.data;

	return { isSalesStagesLoading: isLoading, salesStages: salesStageData, refetchSalesStages: refetch };
};

export default useFetchSalesStages;
