import { QueryObserverResult, useMutation } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import { Badge, Button, Card, Title } from '@tremor/react';
import ConfirmationDialog from 'components/ConfirmationDialog';
import Container from 'components/Container';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import { DropdownMenu } from 'components/DropdownMenu';
import { AppointmentIcon, ConfirmationDialogIcon } from 'components/FormActionIcons';
import Modal from 'components/Modal';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import SlideOver from 'components/SlideOver';
import Table from 'components/Table';
import { DefaultTableDropDownTrigger } from 'components/TableDropdownTrigger';
import { MODAL_LEAVE_SPEED } from 'config';
import ScheduleAppointmentForm from 'features/ScheduleAppointmentForm';
import { AppointmentStatuses, IAppointment } from 'features/appointments/appointmentInterfaces';
import { deleteAppointment, updateAppointmentStatus } from 'features/appointments/appointmentQueries';
import useFetchAppointments from 'features/appointments/useFetchAppointments';
import VehicleRequestForm from 'features/navigation/VehicleRequestForm';
import { formatDate } from 'helpers/dateHelpers';
import { useAppSelector } from 'hooks/storeConnect';
import { useState, ReactNode, Fragment, useEffect } from 'react';
import toast from 'react-hot-toast';
import { prospectsState } from '../prospectsSlice';
import { authState } from 'features/auth/authSlice';
import { getAppointmentTableDropDownMenuOptions } from 'features/appointments/AppointmentsPage';
import AppointmentComments from 'features/appointments/AppointmentComments';
import { AxiosResponse } from 'axios';
import { getAppointmentState, isMarketer } from 'utils/index';
import { hasPermission, hasRoles, isAdmin } from 'helpers/index';
import { PermissionKeys } from 'features/auth/permissions';
import ContentBlockedFromView from 'components/ContentBlockedFromView';
import { DefaultRoles } from 'features/settings/authorization/authorizationInterface';

interface IAppointmentsTableColumns extends IAppointment {
	action: ReactNode;
}

const columnHelper = createColumnHelper<IAppointmentsTableColumns>();

const ProspectAppointmentsPage = () => {
	const [isScheduleAppointmentModalOpen, setScheduleAppointmentModalOpen] = useState(false);
	const [isVehicleRequestFormOpen, setVehicleRequestFormOpen] = useState(false);
	const [isConfirmApproveAppointmentDialog, setConfirmApproveAppointmentDialog] = useState(false);
	const [isConfirmCancelAppointmentDialog, setConfirmCancelAppointmentDialog] = useState(false);
	const [isConfirmDeclineAppointmentDialog, setConfirmDeclineAppointmentDialog] = useState(false);
	const [isConfirmDeleteAppointmentDialogOpen, setConfirmDeleteAppointmentDialogOpen] = useState(false);
	const [currentAppointment, setCurrentAppointment] = useState<IAppointment | null>(null);
	const [isCommentFormOpen, setCommentFormOpen] = useState(false);
	const { activeProspect } = useAppSelector(prospectsState);
	const { authUser } = useAppSelector(authState);
	const [currentPage, setCurrentPage] = useState(1);

	const { isAppointmentsLoading, refetchAppointments, appointments, paginationMetadata } = useFetchAppointments({
		fetchParams: {
			include: ['prospect', 'sales_stage', 'user', 'comments'],
			filterBy: {
				prospectId: activeProspect?.id,
				staffId: isMarketer() ? authUser?.id : undefined,
			},
			page: currentPage,
		},
		queryKey: ['prospect-appointments', currentPage],
	});

	useEffect(() => {
		if (!currentAppointment || !appointments) return;

		const thisAppointment = appointments.find((appointment) => appointment.id === currentAppointment?.id);
		setCurrentAppointment(thisAppointment as any);
		// eslint-disable-next-line
	}, [appointments]);

	// ================================
	// * Update Vehicle Request Status
	// ================================
	const { isLoading: isUpdateAppointmentStatusLoading, mutate: updateAppointmentStatusMutation } = useMutation({
		mutationKey: ['appointment/update-appointmentStatus'],
		mutationFn: (formValues: { appointmentId: number; status: AppointmentStatuses }) => updateAppointmentStatus(formValues),
	});

	// =====================
	// * Delete Appointment
	// =====================
	const { isLoading: isDeleteAppointmentLoading, mutate: deleteAppointmentMutation } = useMutation({
		mutationKey: ['authorization/delete-appointment'],
		mutationFn: (appointmentId: number) => deleteAppointment(appointmentId),
	});

	const onConfirmApproveAppointment = () => {
		if (!currentAppointment) return toast.error('No appointment selected for this action');

		updateAppointmentStatusMutation(
			{
				appointmentId: currentAppointment.id,
				status: AppointmentStatuses.Approved,
			},
			{
				onSuccess: () => {
					toast.success('Appointment has been approved successfully.');
					setScheduleAppointmentModalOpen(false);
				},
				onError: () => {
					toast.error('Failed to approve appointment.');
				},
				onSettled() {
					setTimeout(() => setCurrentAppointment(null), MODAL_LEAVE_SPEED);
					refetchAppointments();
				},
			}
		);

		setConfirmApproveAppointmentDialog(false);
	};

	const onConfirmCancelAppointment = () => {
		if (!currentAppointment) return toast.error('No appointment selected for this action');

		updateAppointmentStatusMutation(
			{
				appointmentId: currentAppointment.id,
				status: AppointmentStatuses.Approved,
			},
			{
				onSuccess: () => {
					toast.success('Appointment cancelled successfully.');
					setScheduleAppointmentModalOpen(false);
				},
				onError: () => {
					toast.error('Failed to cancel appointment.');
				},
				onSettled() {
					setTimeout(() => setCurrentAppointment(null), MODAL_LEAVE_SPEED);
					refetchAppointments();
				},
			}
		);

		setConfirmCancelAppointmentDialog(false);
	};

	const onConfirmDeleteAppointment = () => {
		if (!currentAppointment) return toast.error('No appointment selected for this action');

		deleteAppointmentMutation(currentAppointment.id, {
			onSuccess: () => {
				toast.success('Appointment deleted successfully.');
				setScheduleAppointmentModalOpen(false);
			},
			onError: () => {
				toast.error('Failed to delete appointment.');
			},
			onSettled() {
				setTimeout(() => setCurrentAppointment(null), MODAL_LEAVE_SPEED);
				refetchAppointments();
			},
		});

		setConfirmDeleteAppointmentDialogOpen(false);
	};

	const onConfirmDeclineAppointment = () => {
		if (!currentAppointment) return toast.error('No appointment selected for this action');

		updateAppointmentStatusMutation(
			{
				appointmentId: currentAppointment.id,
				status: AppointmentStatuses.Declined,
			},
			{
				onSuccess: () => {
					toast.success('Appointment declined successfully.');
					setScheduleAppointmentModalOpen(false);
				},
				onError: () => {
					toast.error('Failed to declined appointment.');
				},
				onSettled() {
					setTimeout(() => setCurrentAppointment(null), MODAL_LEAVE_SPEED);
					refetchAppointments();
				},
			}
		);

		setConfirmCancelAppointmentDialog(false);
	};

	const tableColumns = [
		columnHelper.accessor('action', {
			header: 'Actions',
			cell: (info) => {
				const appointment: IAppointment = info.row.original;

				return (
					<DropdownMenu
						renderTriggerElement={(state) => <DefaultTableDropDownTrigger state={state} />}
						menuOptions={getAppointmentTableDropDownMenuOptions(appointment, {
							authUser: authUser as any,
							setCurrentAppointment,
							setVehicleRequestFormOpen,
							setScheduleAppointmentModalOpen,
							setConfirmApproveAppointmentDialog,
							setConfirmCancelAppointmentDialog,
							setConfirmDeclineAppointmentDialog,
							setConfirmDeleteAppointmentDialogOpen,
							setCommentFormOpen,
						})}
					/>
				);
			},
		}),
		columnHelper.accessor('appointment_date', {
			header: 'Appointment Date',
			cell: ({ row }) => <div className='sm:font-semibold'>{formatDate(row.original.appointment_date, 'Jan 31, 1999')}</div>,
		}),
		columnHelper.accessor('status', {
			header: 'Status',
			cell: ({ row }) => {
				const { isApproved, isFulfilled, isPending } = getAppointmentState(row.original);

				return (
					<Badge size='sm' className={`ml-1 text-white ${isPending ? 'bg-gray-500' : isApproved ? 'bg-yellow-500' : isFulfilled ? 'bg-green-600' : 'bg-red-700'}`}>
						{isPending ? 'Pending' : isApproved ? 'Approved' : isFulfilled ? 'Fulfilled' : 'Unfulfilled'}
					</Badge>
				);
			},
		}),
		columnHelper.accessor('prospect.sales_stage', {
			header: 'Sale Stage',
			cell: ({ row }) => <div className='sm:font-semibold'>{row.original.sales_stage.name}</div>,
		}),
		columnHelper.accessor('comments', {
			header: 'Comments',
			cell: ({ row }) => {
				const comments = row.original.comments;

				return (
					<button
						onClick={() => {
							setCommentFormOpen(true);
							setCurrentAppointment(row.original);
						}}
						type='button'
						className='flex items-center gap-2'
					>
						<div className='flex items-center justify-center w-8 h-8 p-1 font-semibold border rounded-full text-primary-700 bg-primary-50 border-primary-100'>{comments?.length}</div>
						{/* Comment{comments.length > 0 ? 's' : ''} */}
					</button>
				);
			},
		}),

		columnHelper.accessor('prospect.sales_stage', {
			header: 'Created By',
			cell: ({ row }) => (
				<div className='sm:font-semibold'>
					{row.original.user.first_name} {row.original.user.middle_name} {row.original.user.last_name}
				</div>
			),
		}),
		columnHelper.accessor('created_at', {
			header: 'Date Created',
			cell: ({ row }) => formatDate(row.original.created_at, 'Jan 31, 1999'),
		}),
	];

	const resetCurrentAppointment = async () => {
		const appointmentsResponse: QueryObserverResult = await refetchAppointments();
		const axiosResponse: AxiosResponse = appointmentsResponse?.data as any;
		const appointments: IAppointment[] | undefined = axiosResponse?.data?.data;

		if (!appointments) return;

		const thisAppointment = appointments.find((request) => request.id === currentAppointment?.id);
		setCurrentAppointment(thisAppointment as any);
	};

	return (
		<Container className='!pb-6 lg:h-[calc(100vh-var(--appbar-height-desktop)-var(--app-navigation-height)-var(--prospect-detail-page-height))]'>
			<div className='flex items-center justify-between py-6'>
				<Title className='lg:uppercase'>Appointments</Title>

				{hasPermission(PermissionKeys.CREATE_APPOINTMENT) && (
					<Button onClick={() => setScheduleAppointmentModalOpen(true)} className='!rounded-full !gap-2' icon={() => <AppointmentIcon variant='light' />}>
						Schedule Appointment
					</Button>
				)}
			</div>

			{hasPermission(PermissionKeys.VIEW_APPOINTMENT) ? (
				<Fragment>
					{!appointments && isAppointmentsLoading ? (
						<div className='mt-6'>
							<ContentLoadingCardSpinner message='Loading appointments...' />
						</div>
					) : appointments && appointments.length > 0 ? (
						<Card className='!p-0 pt-6 overflow-hidden'>
							<Title className='px-4 py-4 border-b'>Your Appointments</Title>

							<Table
								data={appointments}
								columns={tableColumns}
								pagination={{
									handlePageChange: (page: number) => {
										setCurrentPage(page);
									},
									metadata: paginationMetadata,
								}}
							/>
						</Card>
					) : (
						<SimpleEmptyResourceIndicator containerClassName='lg:mt-8 lg:!py-10' message='No appointment found' />
					)}
				</Fragment>
			) : (
				<ContentBlockedFromView message='You are not authorized to view appointments.' containerClassName='min-h-[350px]' />
			)}

			<Modal
				width={600}
				closeButton={{ styles: 'bg-gray-200' }}
				isModalOpen={isScheduleAppointmentModalOpen}
				setModalOpen={setScheduleAppointmentModalOpen}
				onCloseModal={() => {
					setScheduleAppointmentModalOpen(false);
					setTimeout(() => setCurrentAppointment(null), 500);
				}}
			>
				<ScheduleAppointmentForm
					appointment={currentAppointment as IAppointment}
					prospect={activeProspect as any}
					afterEdit={() => {
						setTimeout(() => setCurrentAppointment(null), 500);
						refetchAppointments();
						setScheduleAppointmentModalOpen(false);
					}}
				/>
			</Modal>

			<SlideOver
				title={{
					text: 'Request Vehicle',
					theme: 'primary',
					styles: 'text-gray-100',
				}}
				subTitle={{
					text: `Request vehicle for prospecting on ${activeProspect?.company_name}.`,
					styles: 'text-gray-200',
				}}
				onClose={() => null}
				open={isVehicleRequestFormOpen}
				setOpen={setVehicleRequestFormOpen}
				width={450}
				isScrollable
				shouldCloseOnOutsideClick={false}
			>
				<VehicleRequestForm
					prospect={activeProspect as any}
					afterEdit={() => {
						setVehicleRequestFormOpen(false);
					}}
				/>
			</SlideOver>

			<SlideOver
				title={{
					text: 'Add Comment',
					theme: 'primary',
					styles: 'text-gray-100',
				}}
				subTitle={{
					text: `Pass comments on appointment scheduled at ${formatDate(currentAppointment?.appointment_date, 'Jan 31, 1999')}`,
					styles: 'text-gray-200',
				}}
				onClose={() => null}
				afterClose={() => {
					setTimeout(() => {
						setCurrentAppointment(null);
					}, MODAL_LEAVE_SPEED);
				}}
				open={isCommentFormOpen}
				setOpen={setCommentFormOpen}
				width={450}
				isScrollable={false}
				shouldCloseOnOutsideClick={false}
			>
				<AppointmentComments
					appointment={currentAppointment as IAppointment}
					afterChange={async () => {
						await resetCurrentAppointment();
					}}
				/>
			</SlideOver>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmCancelAppointmentDialog}
				setOpen={setConfirmCancelAppointmentDialog}
				message='Cancel Appointment'
				subMessage={`Are you sure you want to cancel appointment scheduled at ${formatDate(currentAppointment?.appointment_date, 'Jan 31, 1999')}. This action cannot be reversed.`}
				onClose={() => {
					setTimeout(() => setCurrentAppointment(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmCancelAppointment,
					label: 'Proceed',
					isLoading: isUpdateAppointmentStatusLoading,
					loadingText: 'Processing',
					color: 'orange',
				}}
				cancelButton={{
					action: () => setConfirmCancelAppointmentDialog(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-500/10`}>
						<ConfirmationDialogIcon className='text-orange-500' />
					</div>
				)}
			/>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmApproveAppointmentDialog}
				setOpen={setConfirmApproveAppointmentDialog}
				message='Approve Appointment'
				subMessage={`Are you sure you want to approve appointment scheduled at ${formatDate(
					currentAppointment?.appointment_date,
					'Jan 31, 1999'
				)}. The only action that can be taken after approval is vehicle request.`}
				onClose={() => {
					setTimeout(() => setCurrentAppointment(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmApproveAppointment,
					label: 'Approve',
					isLoading: isUpdateAppointmentStatusLoading,
					loadingText: 'Approving',
					color: 'green',
				}}
				cancelButton={{
					action: () => setConfirmApproveAppointmentDialog(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-500/10`}>
						<ConfirmationDialogIcon className='text-green-500' />
					</div>
				)}
			/>
			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmDeclineAppointmentDialog}
				setOpen={setConfirmDeclineAppointmentDialog}
				message='Decline Appointment'
				subMessage={`Are you sure you want to decline appointment scheduled at ${formatDate(currentAppointment?.appointment_date, 'Jan 31, 1999')}. This action cannot be reversed.`}
				onClose={() => {
					setTimeout(() => setCurrentAppointment(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmDeclineAppointment,
					label: 'Decline',
					isLoading: isUpdateAppointmentStatusLoading,
					loadingText: 'Declining',
					color: 'rose',
				}}
				cancelButton={{
					action: () => setConfirmDeclineAppointmentDialog(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-rose-500/10`}>
						<ConfirmationDialogIcon className='text-rose-500' />
					</div>
				)}
			/>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmDeleteAppointmentDialogOpen}
				setOpen={setConfirmDeleteAppointmentDialogOpen}
				message='Delete Appointment'
				subMessage={`Are you sure you want to delete appointment scheduled at ${formatDate(currentAppointment?.appointment_date, 'Jan 31, 1999')}. This action cannot be reversed.`}
				onClose={() => {
					setTimeout(() => setCurrentAppointment(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmDeleteAppointment,
					label: 'Delete',
					isLoading: isDeleteAppointmentLoading,
					loadingText: 'Deleting',
					color: 'red',
				}}
				cancelButton={{
					action: () => setConfirmDeleteAppointmentDialogOpen(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-500/10`}>
						<ConfirmationDialogIcon className='text-red-500' />
					</div>
				)}
			/>
		</Container>
	);
};

export default ProspectAppointmentsPage;
