export enum PermissionKeys {
	CREATE_SOURCE_TYPE = 'Create Source Type',
	UPDATE_SOURCE_TYPE = 'Update Source Type',
	DELETE_SOURCE_TYPE = 'Delete Source Type',
	VIEW_SOURCE_TYPE = 'View Source Type',
	CREATE_BUSINESS_TYPE = 'Create Business Type',
	UPDATE_BUSINESS_TYPE = 'Update Business Type',
	DELETE_BUSINESS_TYPE = 'Delete Business Type',
	VIEW_BUSINESS_TYPE = 'View Business Type',
	CREATE_INTERACTION_TYPE = 'Create Interaction Type',
	UPDATE_INTERACTION_TYPE = 'Update Interaction Type',
	DELETE_INTERACTION_TYPE = 'Delete Interaction Type',
	VIEW_INTERACTION_TYPE = 'View Interaction Type',
	CREATE_INSURANCE_PRODUCT_TYPE = 'Create Insurance Product Type',
	UPDATE_INSURANCE_PRODUCT_TYPE = 'Update Insurance Product Type',
	DELETE_INSURANCE_PRODUCT_TYPE = 'Delete Insurance Product Type',
	VIEW_INSURANCE_PRODUCT_TYPE = 'View Insurance Product Type',
	CREATE_DECISION_MAKER_TYPE = 'Create Decision Maker Type',
	UPDATE_DECISION_MAKER_TYPE = 'Update Decision Maker Type',
	DELETE_DECISION_MAKER_TYPE = 'Delete Decision Maker Type',
	VIEW_DECISION_MAKER_TYPE = 'View Decision Maker Type',
	CREATE_SALES_STAGE = 'Create Sales Stage',
	UPDATE_SALES_STAGE = 'Update Sales Stage',
	DELETE_SALES_STAGE = 'Delete Sales Stage',
	VIEW_SALES_STAGE = 'View Sales Stage',
	CREATE_BRANCH = 'Create Branch',
	UPDATE_BRANCH = 'Update Branch',
	DELETE_BRANCH = 'Delete Branch',
	VIEW_BRANCH = 'View Branch',
	CREATE_DEPARTMENT = 'Create Department',
	UPDATE_DEPARTMENT = 'Update Department',
	DELETE_DEPARTMENT = 'Delete Department',
	VIEW_DEPARTMENT = 'View Department',
	CREATE_USER = 'Create User',
	UPDATE_USER = 'Update User',
	DELETE_USER = 'Delete User',
	VIEW_USER = 'View User',
	UPDATE_USER_STATUS = 'Update User Status',
	CREATE_TARGET = 'Create Target',
	UPDATE_TARGET = 'Update Target',
	DELETE_TARGET = 'Delete Target',
	VIEW_TARGET = 'View Target',
	CREATE_ROLE = 'Create Role',
	UPDATE_ROLE = 'Update Role',
	REVOKE_ROLE = 'Revoke Role',
	DELETE_ROLE = 'Delete Role',
	ASSIGN_PERMISSIONS = 'Assign Permissions',
	CREATE_PROSPECT = 'Create Prospect',
	UPDATE_PROSPECT = 'Update Prospect',
	DELETE_PROSPECT = 'Delete Prospect',
	VIEW_PROSPECT = 'View Prospect',
	CREATE_FOLLOW_UP = 'Create Follow Up',
	UPDATE_FOLLOW_UP = 'Update Follow Up',
	UPDATE_FOLLOW_UP_DATE = 'Update Follow Up Date',
	DELETE_FOLLOW_UP = 'Delete Follow Up',
	VIEW_FOLLOW_UP = 'View Follow Up',
	CREATE_APPOINTMENT = 'Create Appointment',
	DELETE_APPOINTMENT = 'Delete Appointment',
	VIEW_APPOINTMENT = 'View Appointment',
	UPDATE_APPOINTMENT = 'Update Appointment',
	UPDATE_APPOINTMENT_STATUS = 'Update Appointment Status',
	CREATE_TEAM_MEMBER = 'Create Team Member',
	UPDATE_TEAM_MEMBER = 'Update Team Member',
	DELETE_TEAM_MEMBER = 'Delete Team Member',
	VIEW_TEAM_MEMBER = 'View Team Member',
	CREATE_VEHICLE = 'Create Vehicle',
	UPDATE_VEHICLE = 'Update Vehicle',
	DELETE_VEHICLE = 'Delete Vehicle',
	VIEW_VEHICLE = 'View Vehicle',
	CREATE_VEHICLE_REQUEST = 'Create Vehicle Request',
	UPDATE_VEHICLE_REQUEST = 'Update Vehicle Request',
	DELETE_VEHICLE_REQUEST = 'Delete Vehicle Request',
	VIEW_VEHICLE_REQUEST = 'View Vehicle Request',
	UPDATE_VEHICLE_REQUEST_STATUS = 'Update Vehicle Request Status',
	CREATE_VEHICLE_ALLOCATION = 'Create Vehicle Allocation',
	UPDATE_VEHICLE_ALLOCATION = 'Update Vehicle Allocation',
	DELETE_VEHICLE_ALLOCATION = 'Delete Vehicle Allocation',
	VIEW_VEHICLE_ALLOCATION = 'View Vehicle Allocation',
	VIEW_VEHICLE_AND_DRIVE_STATISTICS = 'View Vehicle and Driver Statistics',

	VIEW_INTERACTION = 'View Interaction',
	CREATE_INTERACTION = 'Create Interaction',

	VIEW_USER_FILTERS = 'View User Filter',
}

// Type for the configuration object
export type IPermissionKeys = {
	[key in PermissionKeys]: string;
};
