import axios, { AxiosInstance } from 'axios';
import { API_URL } from 'config/index';

export const lsmsAPI: AxiosInstance = axios.create({
	baseURL: API_URL,
});

export const setAuthToken = (token: string | null) => {
	if (token) {
		localStorage.setItem('token', token);
		lsmsAPI.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	} else {
		delete lsmsAPI.defaults.headers.common['Authorization'];
	}
};
