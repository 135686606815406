import { Fragment, ReactNode, useState } from 'react';
import { Button, Card, Text, Title } from '@tremor/react';
import { IInsuranceProductsFormValue } from '../prospectInterfaces';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import { useAppDispatch, useAppSelector } from 'hooks/storeConnect';
import { prospectsState, setCreateProspectFormValues } from '../prospectsSlice';
import { createColumnHelper } from '@tanstack/table-core';
import { ConfirmationDialogIcon, DeleteIcon, EditIcon } from 'components/FormActionIcons';
import CreateProspectFormActionButtons from './CreateProspectFormActionButtons';
import Modal from 'components/Modal';
import EditCreateProspectInsuranceProductForm from './EditCreateProspectInsuranceProductForm';
import Table from 'components/Table';
import { formatCurrency, hasPermission } from 'helpers/index';
import { formatDate } from 'helpers/dateHelpers';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { MODAL_LEAVE_SPEED } from 'config';
import toast from 'react-hot-toast';
import _ from 'lodash';
import { createProspectStepperPages } from './CreateProspectStepper';
import { useLocation, useNavigate } from 'react-router';
import { PermissionKeys } from 'features/auth/permissions';

interface ICreateProspectInsuranceProductTableColumns extends IInsuranceProductsFormValue {
	action: ReactNode;
}

const columnHelper = createColumnHelper<ICreateProspectInsuranceProductTableColumns>();

const CreateProspectFormInsuranceProductsStep = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const dispatch = useAppDispatch();
	const { createProspectFormValues } = useAppSelector(prospectsState);
	const [currentProduct, setCurrentProduct] = useState<IInsuranceProductsFormValue | null>(null);
	const [isMobileEditProductFormOpen, setMobileEditProductFormOpen] = useState<boolean>(false);
	const [isConfirmDeleteProductDialogOpen, setConfirmDeleteProductDialogOpen] = useState(false);

	const insuranceProducts = createProspectFormValues?.insuranceProductsStep;

	const tableColumns = [
		columnHelper.accessor('action', {
			header: 'Actions',
			cell: (info) => {
				const product = info.row.original;

				return (
					<div className='flex items-center gap-6'>
						<button
							onClick={() => {
								setCurrentProduct(product);
								setMobileEditProductFormOpen(true);
							}}
							type='button'
							title='Edit Product'
							className='flex items-center justify-center gap-2 lg:hidden group'
						>
							<div className='flex items-center justify-center text-yellow-600 duration-500 border border-yellow-100 lg:h-10 lg:w-10 lg:bg-yellow-50 lg:rounded-full lg:group-hover:text-white lg:group-hover:bg-gray-700'>
								<EditIcon />
							</div>

							<Text className='lg:font-medium'>Edit</Text>
						</button>
						<button onClick={() => setCurrentProduct(product)} type='button' title='Edit Product' className='items-center justify-center hidden gap-2 lg:flex group'>
							<div className='flex items-center justify-center text-yellow-600 duration-500 border border-yellow-100 lg:h-10 lg:w-10 lg:bg-yellow-50 lg:rounded-full lg:group-hover:text-white lg:group-hover:bg-gray-700'>
								<EditIcon />
							</div>

							<Text className='lg:font-medium'>Edit</Text>
						</button>

						<button
							onClick={() => {
								setCurrentProduct(product);
								setConfirmDeleteProductDialogOpen(true);
							}}
							type='button'
							title='Delete Product'
							className='flex items-center justify-center gap-2 group '
						>
							<div className='flex items-center justify-center text-red-600 duration-500 border border-red-100 lg:h-10 lg:w-10 lg:bg-red-50 lg:rounded-full lg:group-hover:text-white lg:group-hover:bg-gray-700'>
								<DeleteIcon />
							</div>
							<Text className='lg:font-medium'>Delete</Text>
						</button>
					</div>
				);
			},
		}),
		columnHelper.accessor('insurance_product_type_id', {
			header: 'Product',
			cell: ({ row }) => <div className='sm:font-bold'>{row.original.insurance_product?.name}</div>,
		}),
		columnHelper.accessor('expected_close_date', {
			header: 'Expected Close Date',
			cell: ({ row }) => <div className='sm:font-semibold'>{formatDate(row.original.expected_close_date, 'Jan 31, 1999')}</div>,
		}),
		columnHelper.accessor('expected_premium', {
			header: 'Expected Premium',
			cell: ({ row }) => <div className='text-blue-600 sm:font-semibold'>{formatCurrency(row.original.expected_premium)}</div>,
		}),
		columnHelper.accessor('achieved_premium', {
			header: 'Achieved Premium',
			cell: ({ row }) => <div className='text-green-500 sm:font-semibold'>{formatCurrency(row.original.achieved_premium)}</div>,
		}),
		columnHelper.accessor('commission_rate', {
			header: 'Commission Rate',
			cell: ({ row }) => <div className='sm:font-semibold'>{row.original.commission_rate}%</div>,
		}),
		columnHelper.accessor('commission', {
			header: 'Commission',
			cell: ({ row }) => <div className='text-orange-600 sm:font-semibold'>{formatCurrency(row.original.commission)}</div>,
		}),
	];

	const onConfirmDeleteProduct = () => {
		if (!currentProduct || !createProspectFormValues?.insuranceProductsStep) return toast.error('No product selected for this action');

		const existingProducts = [...createProspectFormValues?.insuranceProductsStep];
		const currentEditIndex = existingProducts.findIndex((p) => _.isEqual(p.insurance_product_type_id, currentProduct.insurance_product_type_id));
		existingProducts.splice(currentEditIndex, 1);

		dispatch(
			setCreateProspectFormValues({
				...createProspectFormValues,
				insuranceProductsStep: existingProducts,
			} as any)
		);

		setCurrentProduct(null);
		setConfirmDeleteProductDialogOpen(false);
	};

	return (
		<div className='px-2 pb-10 lg:px-0 lg:relative lg:pb-0'>
			<Title className='hidden px-4 text-primary-500 lg:block'>Insurance Information</Title>

			<div className='relative lg:bg-[#f5f6f7] lg:pt-4 lg:pb-24 lg:px-4 lg:border-t mt-4 w-full lg:h-[calc(100vh-var(--appbar-height-desktop)-var(--create-prospect-page-height)+200px)]'>
				<section className='pb-10 lg:pb-4'>
					<div className='flex items-start justify-between gap-4'>
						<div>
							<h3 className='text-sm font-medium text-black uppercase'>Insurance Products</h3>
							<Text className='mb-3 !text-gray-500'>Add insurance products.</Text>
						</div>

						<div className='lg:hidden'>
							<Button
								onClick={() => {
									setMobileEditProductFormOpen(true);
								}}
								type='submit'
								className='!rounded-full [&>svg]:hidden sm:[&>svg]:inline !bg-black !border-black !text-white'
							>
								Add Product
							</Button>
						</div>
					</div>

					<div className='flex-col items-end hidden w-full gap-2 lg:flex'>
						<EditCreateProspectInsuranceProductForm
							isConfirmDeleteProductDialogOpen={isConfirmDeleteProductDialogOpen}
							product={currentProduct}
							setCurrentProduct={setCurrentProduct}
							setFormOpen={setMobileEditProductFormOpen}
						/>
					</div>

					<Fragment>
						{insuranceProducts && insuranceProducts.length > 0 ? (
							<div className='mt-4 lg:mt-8'>
								<Card className='!p-0 pt-6 overflow-hidden'>
									<Title className='px-4 py-4 border-b'>All Insurance Products</Title>

									<Table data={insuranceProducts} columns={tableColumns} useInbuiltPagination />
								</Card>
							</div>
						) : (
							<SimpleEmptyResourceIndicator containerClassName='lg:mt-2 lg:!py-10' message='No product added' />
						)}
					</Fragment>
				</section>

				{hasPermission(PermissionKeys.CREATE_PROSPECT) && (
					<CreateProspectFormActionButtons
						nextStepButton={{
							isDisabled: !createProspectFormValues?.insuranceProductsStep,
							onClick: () => {
								const currentPageIndex = createProspectStepperPages.findIndex((page) => page.url === pathname);
								navigate(createProspectStepperPages[currentPageIndex + 1].url);
							},
						}}
					/>
				)}
			</div>

			<Modal
				width={600}
				closeButton={{ styles: 'bg-gray-200' }}
				isModalOpen={isMobileEditProductFormOpen}
				setModalOpen={setMobileEditProductFormOpen}
				onCloseModal={() => {
					setMobileEditProductFormOpen(false);
					setTimeout(() => setCurrentProduct(null), MODAL_LEAVE_SPEED);
				}}
			>
				<EditCreateProspectInsuranceProductForm setFormOpen={setMobileEditProductFormOpen} product={currentProduct} setCurrentProduct={setCurrentProduct} />
			</Modal>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmDeleteProductDialogOpen}
				setOpen={setConfirmDeleteProductDialogOpen}
				message='Delete Product'
				subMessage={`Are you sure you want to delete product?`}
				shouldCloseOnOutsideClick={false}
				onClose={() => {
					setCurrentProduct(null);
				}}
				confirmButton={{
					action: onConfirmDeleteProduct,
					label: 'Delete',
					color: 'red',
				}}
				cancelButton={{
					action: () => setConfirmDeleteProductDialogOpen(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-500/10`}>
						<ConfirmationDialogIcon className='w-6 h-6 text-red-500' aria-hidden='true' />
					</div>
				)}
			/>
		</div>
	);
};

export default CreateProspectFormInsuranceProductsStep;
