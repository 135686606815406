import { Title } from '@tremor/react';
import Menu from 'components/Appbar/AppMobileNavigation/Menu';
import appNavigationMenu from 'components/Appbar/appNavigationMenu';
import { useState } from 'react';

const SettingsNavigation = () => {
	const [activeMenu, setActiveMenu] = useState<string>('settings');

	const menu = appNavigationMenu.find((section) => section.title?.toLowerCase() === 'settings');

	if (!menu) return <div></div>;

	return (
		<div className='py-4 w-fit lg:w-[var(--app-branding-width-lg)] bg-gray-50 h-full lg:border-r border-gray-100'>
			<Title className='px-4 mb-4'>Settings</Title>

			<nav>
				<Menu activeMenu={activeMenu} links={menu.links} setActiveMenu={setActiveMenu} />
			</nav>
		</div>
	);
};

export default SettingsNavigation;
