import React, { ReactNode } from 'react';

interface Props {
	className?: string;
	children: ReactNode;
}

const Container = (props: Props) => {
	const { className, children } = props;

	return <div className={`px-2 sm:px-4 ${className ? className : ''}`}>{children}</div>;
};

export default Container;
