import { useState } from 'react';
import { ICreateTeamMemberFormValues, IProspect } from '../prospectInterfaces';
import { Button, List, ListItem, Select, SelectItem, Text, Title } from '@tremor/react';
import { IUser } from 'features/auth/authInterfaces';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { createTeamMember, deleteTeamMember, transferTeamLeadership } from './teamMemberQueries';
import { hasPermission, processReactHookFormErrors, titleCase } from 'helpers/index';
import useFetchUsers from 'features/settings/users/useFetchUsers';
import toast from 'react-hot-toast';
import { ConfirmationDialogIcon, DeleteIcon } from 'components/FormActionIcons';
import { useAppSelector } from 'hooks/storeConnect';
import { authState } from 'features/auth/authSlice';
import { BiUserPlus } from 'react-icons/bi';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import { HiUser } from 'react-icons/hi2';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { MODAL_LEAVE_SPEED } from 'config';
import { Tooltip } from 'react-tooltip';
import { RiShieldUserLine } from 'react-icons/ri';
import { PermissionKeys } from 'features/auth/permissions';
import ContentBlockedFromView from 'components/ContentBlockedFromView';

interface Props {
	prospect: IProspect;
	afterChange?: () => void;
}

const ManageProspectingTeamForm = ({ prospect, afterChange }: Props) => {
	const { authUser } = useAppSelector(authState);
	const [currentTeamMember, setCurrentTeamMember] = useState<IUser | null>(null);
	const [isConfirmRemoveTeamMemberDialogOpen, setConfirmRemoveTeamMemberDialogOpen] = useState<boolean>(false);
	const [isConfirmOwnershipDialogOpenDialogOpen, setConfirmOwnershipDialogOpenDialogOpen] = useState<boolean>(false);
	const [selectUser, setSelectUser] = useState<number | undefined>(undefined);
	const { users, isUsersLoading } = useFetchUsers({});

	const {
		handleSubmit,
		setError,
		setValue,
		clearErrors,
		formState: { errors },
	} = useForm<ICreateTeamMemberFormValues>();

	// ============================
	// * Update Team Leadership
	// ============================
	const { isLoading: isUpdateTeamLeadershipLoading, mutate: updateTeamLeadershipMutation } = useMutation({
		mutationKey: ['team-members/change-leadership'],
		mutationFn: (teamMemberId: number) => transferTeamLeadership(teamMemberId),
	});

	// ============================
	// * Add Team Member
	// ============================
	const { isLoading: isCreateTeamMemberLoading, mutate: createTeamMemberMutation } = useMutation({
		mutationKey: ['team-members/add-member'],
		mutationFn: (params: ICreateTeamMemberFormValues) => createTeamMember(params),
	});

	// ======================
	// * Remove Team Member
	// ======================
	const { isLoading: isRemoveTeamMemberLoading, mutate: removeTeamMemberMutation } = useMutation({
		mutationKey: ['team-members/remove-teamMember'],
		mutationFn: (teamMemberId: number) => deleteTeamMember(teamMemberId),
	});

	const onSubmit = (formValues: ICreateTeamMemberFormValues) => {
		if (!formValues.team_member_id) return setError('team_member_id', { message: 'Please select a team member' });

		createTeamMemberMutation(
			{
				team_member_id: formValues.team_member_id,
				prospect_id: prospect.id,
			},
			{
				onSuccess: () => {
					toast.success('Team member added successfully!');
					afterChange?.();
				},
				onError: (error) => {
					processReactHookFormErrors(error, 'Failed to add staff as team member', setError);
				},
			}
		);
	};

	const onConfirmRemoveTeamMember = () => {
		if (!currentTeamMember) return toast.error('No team member selected for this action');

		removeTeamMemberMutation(currentTeamMember.id, {
			onSuccess: () => {
				toast.success('Team member updated successfully!');
				afterChange?.();
				setConfirmRemoveTeamMemberDialogOpen(false);
			},
			onError: () => {
				toast.error('Failed to remove team member');
			},
		});
	};

	const onConfirmTransferTeamLeadership = () => {
		if (!currentTeamMember) return toast.error('No team member selected for this action');

		updateTeamLeadershipMutation(currentTeamMember.id, {
			onSuccess: () => {
				toast.success('Team leader updated successfully!');
				afterChange?.();
				setConfirmOwnershipDialogOpenDialogOpen(false);
			},
			onError: () => {
				toast.error('Failed to change team leader');
			},
		});
	};

	return (
		<div className='w-full bg-white'>
			{hasPermission(PermissionKeys.CREATE_TEAM_MEMBER) && (
				<form onSubmit={handleSubmit(onSubmit)} className='px-4 pt-4'>
					<div className='w-full form-control'>
						<label htmlFor='team_member_id' className='required-field-indicator'>
							Team Member
						</label>

						<div>
							<Select
								id='team_member_id'
								icon={HiUser}
								placeholder='Select team member'
								disabled={isUsersLoading}
								value={selectUser?.toString()}
								onValueChange={(val) => {
									setValue('team_member_id', Number(val));

									setSelectUser(Number(val) as any);

									if (errors.team_member_id) clearErrors('team_member_id');
								}}
							>
								{users && users.length > 0 ? (
									users
										.filter((user) => user.id !== authUser?.id)
										?.map((user, index) => (
											<SelectItem key={index} value={user.id.toString()}>
												{user.first_name} {user?.middle_name} {user.last_name}
											</SelectItem>
										))
								) : (
									<div className='px-2 py-2'>No user</div>
								)}
							</Select>
							{errors?.team_member_id && <span className='form-message'>{errors?.team_member_id?.message}</span>}
						</div>
					</div>

					<div className='flex justify-end mt-2'>
						<Button
							type='submit'
							size='md'
							iconPosition='left'
							icon={BiUserPlus}
							loading={isCreateTeamMemberLoading}
							loadingText={'Adding Member'}
							disabled={isCreateTeamMemberLoading}
							color={'green'}
							className='duration-300 !rounded-full'
						>
							Add Member
						</Button>
					</div>
				</form>
			)}

			<Title className='px-4'>Team</Title>

			{hasPermission(PermissionKeys.VIEW_TEAM_MEMBER) ? (
				<div className='p-4'>
					<List className='border rounded-xl'>
						<ListItem className='px-4 !py-2 flex-col items-start'>
							<h4 className='!font-semibold text-sm lg:text-xs text-black'>Leader</h4>
							<Text>
								{prospect?.user?.first_name} {prospect?.user?.middle_name} {prospect?.user?.last_name}
							</Text>
						</ListItem>
						<ListItem className='px-4 !py-4 sm:!py-3 sm:flex-col sm:items-start flex-col items-start'>
							<h4 className='!font-semibold text-sm lg:text-xs text-black '>Other Members</h4>
							{prospect.team_members && prospect.team_members.length > 0 ? (
								<List className='mt-2 border rounded-lg bg-gray-50'>
									{prospect.team_members.map((member, index) => (
										<ListItem key={index} className='!py-3 px-4 flex-col items-start gap-2'>
											<span>
												{member.first_name} {member.middle_name} {member.last_name}
											</span>

											<div className='flex items-center justify-center gap-2'>
												{hasPermission(PermissionKeys.DELETE_TEAM_MEMBER) && (
													<button
														title='Remove member'
														onClick={() => {
															setCurrentTeamMember(member);
															setConfirmRemoveTeamMemberDialogOpen(true);
														}}
														type='button'
														data-tooltip-content='Remove Member'
														data-tooltip-id='team-members-action'
														className='flex items-center justify-center w-10 h-10 text-red-600 duration-500 border border-red-100 rounded-full bg-red-50 hover:text-white hover:bg-gray-700'
													>
														<DeleteIcon />
													</button>
												)}

												{hasPermission(PermissionKeys.UPDATE_TEAM_MEMBER) && (
													<button
														title='Make leader'
														onClick={() => {
															setCurrentTeamMember(member);
															setConfirmOwnershipDialogOpenDialogOpen(true);
														}}
														type='button'
														className='flex items-center justify-center h-10 px-4 text-red-600 duration-500 border border-red-100 rounded-full bg-red-50 hover:text-white hover:bg-gray-700'
													>
														<RiShieldUserLine className='w-5 h-5 shrink-0' /> Make Leader
													</button>
												)}
											</div>
										</ListItem>
									))}

									<Tooltip id='team-members-action' />
								</List>
							) : (
								<SimpleEmptyResourceIndicator message='No team member added' />
							)}
						</ListItem>
					</List>
				</div>
			) : (
				<p className='p-4'>
					<ContentBlockedFromView message='You are not authorized to view team members.' containerClassName='min-h-[300px]' />
				</p>
			)}

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmRemoveTeamMemberDialogOpen}
				setOpen={setConfirmRemoveTeamMemberDialogOpen}
				message='Remove Team Member'
				subMessage={`Are you sure you want to remove ${titleCase(currentTeamMember?.first_name as string) + ' team member' ?? 'this team member'}? This action cannot be reversed.`}
				onClose={() => {
					setTimeout(() => setCurrentTeamMember(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmRemoveTeamMember,
					label: 'Remove',
					isLoading: isRemoveTeamMemberLoading,
					loadingText: 'Removing',
					color: 'red',
				}}
				cancelButton={{
					action: () => setConfirmRemoveTeamMemberDialogOpen(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-500/10`}>
						<ConfirmationDialogIcon className='w-6 h-6 text-red-500' aria-hidden='true' />
					</div>
				)}
			/>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmOwnershipDialogOpenDialogOpen}
				setOpen={setConfirmOwnershipDialogOpenDialogOpen}
				message='Transfer Team Leadership'
				subMessage={`Are you sure you want to transfer team leader ${currentTeamMember ? `to ${titleCase(currentTeamMember?.first_name as string)}` : ''}?`}
				onClose={() => {
					setTimeout(() => setCurrentTeamMember(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmTransferTeamLeadership,
					label: 'Confirm',
					isLoading: isUpdateTeamLeadershipLoading,
					loadingText: 'Processing...',
					color: 'green',
				}}
				cancelButton={{
					action: () => setConfirmRemoveTeamMemberDialogOpen(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-500/10`}>
						<ConfirmationDialogIcon className='w-6 h-6 text-green-500' aria-hidden='true' />
					</div>
				)}
			/>
		</div>
	);
};

export default ManageProspectingTeamForm;
