import { Fragment, useState } from 'react';
import { Button, Callout, Divider, Metric, Text, Title } from '@tremor/react';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import useFetchInteractionTypes from './useFetchInteractionTypes';
import { IInteractionType } from '../prospectSettingsInterfaces';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { HiExclamation } from 'react-icons/hi';
import toast from 'react-hot-toast';
import { MODAL_LEAVE_SPEED } from 'config';
import { useMutation } from '@tanstack/react-query';
import { deleteInteractionType } from '../prospectSettingsQueuries';
import { hasPermission, titleCase } from 'helpers';
import Modal from 'components/Modal';
import EditInteractionTypeForm from './EditInteractionTypeForm';
import { DeleteIcon, EditIcon } from 'components/FormActionIcons';
import { PermissionKeys } from 'features/auth/permissions';
import ContentBlockedFromView from 'components/ContentBlockedFromView';

const InteractionTypesPage = () => {
	const [isEditInteractionTypeFormOpen, setEditInteractionTypeFormOpen] = useState(false);
	const [isConfirmDeleteInteractionTypeDialogOpen, setConfirmDeleteInteractionTypeDialogOpen] = useState(false);
	const [currentInteractionType, setCurrentInteractionType] = useState<IInteractionType | null>(null);

	const { isInteractionTypesLoading, refetchInteractionTypes, interactionTypes } = useFetchInteractionTypes({
		enabled: hasPermission(PermissionKeys.VIEW_INTERACTION_TYPE),
	});

	// ======================
	// * Delete Interaction Type
	// ======================
	const { isLoading: isDeleteInteractionTypeLoading, mutate: deleteInteractionTypeMutation } = useMutation({
		mutationKey: ['authorization/delete-interactionType'],
		mutationFn: (interactionTypeId: number) => deleteInteractionType(interactionTypeId),
	});

	const onConfirmDeleteInteractionType = () => {
		if (!currentInteractionType) return toast.error('No interaction type selected for this action');

		deleteInteractionTypeMutation(currentInteractionType.id, {
			onSuccess: () => {
				toast.success('Interaction Type updated successfully!');
				setEditInteractionTypeFormOpen(false);
			},
			onError: () => {
				toast.error('Failed to delete interaction type');
			},
			onSettled() {
				setTimeout(() => setCurrentInteractionType(null), MODAL_LEAVE_SPEED);
				refetchInteractionTypes();
			},
		});

		setConfirmDeleteInteractionTypeDialogOpen(false);
	};

	const renderActionButtons = (interactionType: IInteractionType) => (
		<div className='flex items-center gap-6'>
			{hasPermission(PermissionKeys.UPDATE_INTERACTION_TYPE) && (
				<button
					onClick={() => {
						setCurrentInteractionType(interactionType);
						setEditInteractionTypeFormOpen(true);
					}}
					type='button'
					title='Edit Interaction Type'
					className='flex items-center justify-center gap-2 group'
				>
					<div className='flex items-center justify-center text-yellow-600 duration-500 border border-yellow-100 lg:h-10 lg:w-10 lg:bg-yellow-50 lg:rounded-full lg:group-hover:text-white lg:group-hover:bg-gray-700'>
						<EditIcon />
					</div>

					<Text className='lg:font-medium'>Edit</Text>
				</button>
			)}

			{hasPermission(PermissionKeys.DELETE_INTERACTION_TYPE) && (
				<button
					onClick={() => {
						setCurrentInteractionType(interactionType);
						setConfirmDeleteInteractionTypeDialogOpen(true);
					}}
					type='button'
					title='Delete Interaction Type'
					className='flex items-center justify-center gap-2 group '
				>
					<div className='flex items-center justify-center text-red-600 duration-500 border border-red-100 lg:h-10 lg:w-10 lg:bg-red-50 lg:rounded-full lg:group-hover:text-white lg:group-hover:bg-gray-700'>
						<DeleteIcon />
					</div>
					<Text className='lg:font-medium'>Delete</Text>
				</button>
			)}

			{!(hasPermission(PermissionKeys.UPDATE_INTERACTION_TYPE) && hasPermission(PermissionKeys.DELETE_INTERACTION_TYPE)) && (
				<Callout title='Not authorized to perform action' icon={HiExclamation} color='yellow' />
			)}
		</div>
	);

	const renderInteractionTypes = (interactionTypes: IInteractionType[]) =>
		interactionTypes.map((interactionType, index) => (
			<div key={index}>
				<Divider />

				<div className='items-start gap-2 lg:gap-4 lg:items-center grid sm:grid-cols-[1fr,200px]'>
					<div>
						<Title>{interactionType.name}</Title>
						{interactionType?.description && <Text>{interactionType.description}</Text>}
					</div>

					<div className='mt-2 sm:mt-0'>{renderActionButtons(interactionType)}</div>
				</div>
			</div>
		));

	return (
		<Fragment>
			<div className='px-2 py-6 sm:px-4'>
				<div className='flex items-center justify-between'>
					<div className='flex items-center gap-3'>
						<Metric className='hidden sm:block'>Prospecting</Metric>

						{/* Separator */}
						<span className='hidden sm:inline-block w-[1px] h-[40px] bg-black rotate-12' />

						<Title className='lg:mt-1'>Interaction Types</Title>
					</div>

					{hasPermission(PermissionKeys.CREATE_INTERACTION_TYPE) && (
						<Button className='!rounded-full' onClick={() => setEditInteractionTypeFormOpen(true)}>
							Create Interaction Type
						</Button>
					)}
				</div>

				<div>
					{hasPermission(PermissionKeys.VIEW_INTERACTION_TYPE) ? (
						<Fragment>
							{!interactionTypes && isInteractionTypesLoading ? (
								<div className='mt-6'>
									<ContentLoadingCardSpinner message='Loading interaction types...' />
								</div>
							) : interactionTypes && interactionTypes.length > 0 ? (
								<Fragment>
									<div className='sm:grid-cols-[1fr,200px] hidden sm:grid sm:mt-6'>
										<h1 className='text-sm font-semibold text-gray-600 uppercase sm:text-xs'>Interaction Type</h1>
										<h1 className='text-sm font-semibold text-gray-600 uppercase sm:text-xs'>Action</h1>
									</div>

									{renderInteractionTypes(interactionTypes)}
								</Fragment>
							) : (
								<SimpleEmptyResourceIndicator containerClassName='lg:mt-8' message='No interaction type found' />
							)}
						</Fragment>
					) : (
						<div className='mt-6'>
							<ContentBlockedFromView message='You are not authorized to view interaction types.' containerClassName='min-h-[350px]' />
						</div>
					)}
				</div>
			</div>

			<Modal
				width={600}
				closeButton={{ styles: 'bg-gray-200' }}
				isModalOpen={isEditInteractionTypeFormOpen}
				setModalOpen={setEditInteractionTypeFormOpen}
				onCloseModal={() => {
					setEditInteractionTypeFormOpen(false);
					setTimeout(() => setCurrentInteractionType(null), 500);
				}}
				shouldCloseOnOutsideClick={false}
			>
				<EditInteractionTypeForm
					interactionType={currentInteractionType}
					setCurrentInteractionType={setCurrentInteractionType}
					setEditInteractionTypeFormOpen={setEditInteractionTypeFormOpen}
					refetchInteractionTypes={refetchInteractionTypes}
				/>
			</Modal>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmDeleteInteractionTypeDialogOpen}
				setOpen={setConfirmDeleteInteractionTypeDialogOpen}
				message='Delete Interaction Type'
				subMessage={`Are you sure you want to delete ${titleCase(currentInteractionType?.name as string) ?? 'this  interaction type'}? This action cannot be reversed.`}
				onClose={() => {
					setTimeout(() => setCurrentInteractionType(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmDeleteInteractionType,
					label: 'Delete',
					isLoading: isDeleteInteractionTypeLoading,
					loadingText: 'Deleting',
					color: 'red',
				}}
				cancelButton={{
					action: () => setConfirmDeleteInteractionTypeDialogOpen(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-500/10`}>
						<HiExclamation className='w-6 h-6 text-red-500' aria-hidden='true' />
					</div>
				)}
			/>
		</Fragment>
	);
};

export default InteractionTypesPage;
