import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store/index';
import SliceNames from 'store/sliceNames';
import { IProspect, ProspectsState, ICreateProspectFormValues } from './prospectInterfaces';

export const loyaltyInsuranceHeadOfficeLocationInfo: ILocationInfo = {
	coordinate: {
		lat: 5.631773400000001,
		lng: -0.2438460999999999,
	},
	name: 'Loyalty Insurance Company Limited',
	url: 'https://maps.google.com/?cid=14999875927681908905',
};

export const initialProspectFormValues: ICreateProspectFormValues = {
	locationStep: loyaltyInsuranceHeadOfficeLocationInfo,
} as any;

const initialState: ProspectsState = {
	activeProspect: null,
	prospectsError: null,
	createProspectFormValues: initialProspectFormValues as any,
};

export const ProspectsSlice = createSlice({
	name: SliceNames.Prospects,
	initialState,
	reducers: {
		setActiveProspect: (state, action: PayloadAction<IProspect | null>) => {
			state.activeProspect = action.payload;
		},

		setCreateProspectFormValues: (state, action: PayloadAction<ICreateProspectFormValues>) => {
			state.createProspectFormValues = action.payload;
		},
	},
});

//================ Exports & Actions ====================
export const { setActiveProspect, setCreateProspectFormValues } = ProspectsSlice.actions;
export const prospectsState = (state: RootState) => state.prospects;
export default ProspectsSlice.reducer;
