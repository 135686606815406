import { Fragment, useState, useEffect } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { HiCheck, HiChevronUpDown } from 'react-icons/hi2';

interface IPlaceItem {
	id: number;
	name: string;
	value: google.maps.places.AutocompletePrediction;
}

interface Props {
	menu: IPlaceItem[];
	placeholder?: string;
	selectedItemName?: string | null;
	onSearch: (searchTerm: string) => void;
	onPlaceChange: (place: google.maps.places.AutocompletePrediction) => void;
}

const DEBOUNCE_TIMER = 700;

export default function PlacesCombobox(props: Props) {
	const { menu, placeholder, onSearch, onPlaceChange, selectedItemName } = props;

	const [term, setTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);
	const [selectedPlace, setSelectedPlace] = useState<IPlaceItem | null>(
		selectedItemName && menu?.length
			? (menu.find((place) => {
					return selectedItemName.toLowerCase() === place.value.description.split(',')?.[0]?.toLowerCase();
			  }) as any)
			: null
	);

	useEffect(() => {
		// > Set timeout and return clean up function
		const timerId = setTimeout(() => {
			setDebouncedTerm(term);
		}, DEBOUNCE_TIMER);

		// > Return clean up function to clear previous setTimeOut
		return () => {
			clearTimeout(timerId);
		};

		//eslint-disable-next-line
	}, [term]);

	useEffect(() => {
		// > Execute search if debouncedTerm finally goes through in first useEffect
		onSearch(debouncedTerm.trim());

		//eslint-disable-next-line
	}, [debouncedTerm]);

	useEffect(() => {
		if (!selectedPlace) return;

		onPlaceChange(selectedPlace.value);

		// eslint-disable-next-line
	}, [selectedPlace]);

	return (
		<div className='w-full'>
			<Combobox value={selectedPlace} onChange={setSelectedPlace}>
				<div className='relative'>
					<div className='relative w-full cursor-default ring-1 overflow-hidden rounded-lg bg-white text-left shadow-sm focus-within:ring-2 ring-gray-300 focus-within:ring-primary-300 sm:text-sm'>
						<Combobox.Input
							placeholder={placeholder || 'Search...'}
							className='w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900'
							displayValue={(menuIItem: IPlaceItem) => menuIItem?.name}
							onChange={(e) => setTerm(e.target.value)}
						/>
						<Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-2'>
							<HiChevronUpDown className='h-5 w-5 text-gray-400' aria-hidden='true' />
						</Combobox.Button>
					</div>

					<Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
						<Combobox.Options className='absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm'>
							{menu.length === 0 ? (
								<div className='relative cursor-default select-none py-2 px-4 text-gray-700'>Nothing found.</div>
							) : (
								<Fragment>
									{menu.map((menuItem) => {
										return (
											<Combobox.Option
												key={menuItem.id}
												className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-primary-500 text-white' : 'text-gray-900'}`}
												value={menuItem}
											>
												{({ selected, active }) => (
													<>
														<span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{menuItem.name}</span>
														{selected ? (
															<span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-primary-600'}`}>
																<HiCheck className='h-5 w-5' aria-hidden='true' />
															</span>
														) : null}
													</>
												)}
											</Combobox.Option>
										);
									})}
								</Fragment>
							)}
						</Combobox.Options>
					</Transition>
				</div>
			</Combobox>
		</div>
	);
}
