import { createColumnHelper } from '@tanstack/react-table';
import { Badge, Button, Text, Title } from '@tremor/react';
import BottomSheet from 'components/BottomSheet';
import ContentBlockedFromView from 'components/ContentBlockedFromView';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import FileOpener from 'components/FileOpener';
import Filters from 'components/Filters';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import Table from 'components/Table';
import { authState } from 'features/auth/authSlice';
import { PermissionKeys } from 'features/auth/permissions';
import { IFetchInteractionsActionParams, IInteraction } from 'features/interactions/interactionInterfaces';
import useFetchInteractions from 'features/interactions/useFetchInteractions';
import useFetchProspects from 'features/prospects/useFetchProspects';
import { DefaultRoles } from 'features/settings/authorization/authorizationInterface';
import useFetchInteractionTypes from 'features/settings/prospecting/interactionTypes/useFetchInteractionTypes';
import useFetchSalesStages from 'features/settings/prospecting/useFetchSalesStages';
import { FilterComponentTypes } from 'features/settings/settingsInterfaces';
import useFetchUsers from 'features/settings/users/useFetchUsers';
import { formatDate, formatDateRangePickerValue } from 'helpers/dateHelpers';
import { convertJSONToExcel, hasPermission, hasRole, isAdmin } from 'helpers/index';
import { useAppSelector } from 'hooks/storeConnect';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { RiFilter2Fill } from 'react-icons/ri';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { buildURLFilters, isMarketer } from 'utils/index';

type Props = {};

const columnHelper = createColumnHelper<IInteraction>();

const InteractionsReportPage = (props: Props) => {
	const navigate = useNavigate();
	const { users } = useFetchUsers({});
	const { authUser } = useAppSelector(authState);
	const [isMobileFiltersOpen, setMobileFiltersOpen] = useState<boolean>(false);
	const [urlParams, setUrlParams] = useSearchParams();
	const [filters, setFilters] = useState({
		prospectId: undefined as any,
		createdBetween: undefined as any,
		interactionTypeId: undefined as any,
		saleStageId: undefined as any,
		userId: isMarketer() ? authUser?.id : undefined,
	});
	const [fetchParams, setFetchParams] = useState<IFetchInteractionsActionParams>({
		filterBy: filters,
		includes: ['sales_stage', 'prospect', 'interaction_type', 'user'],
	});
	const { interactions, isInteractionsLoading, paginationMetadata } = useFetchInteractions({
		fetchParams,
		queryKey: fetchParams,
	});
	const { prospects: prospectsFilterData } = useFetchProspects({
		queryKey: ['reports/prospects-filter'],
	});
	const { salesStages: salesStagesFilterData } = useFetchSalesStages({
		queryKey: 'reports/sales-stages-filter',
	});
	const { interactionTypes: interactionTypesFilterData } = useFetchInteractionTypes({
		queryKey: 'reports/interaction-types-filter',
	});

	// Manage url filters
	const dateRange = urlParams.get('dateRange') ?? '';
	const prospectId = urlParams.get('prospectId') ?? '';
	const saleStageId = urlParams.get('saleStageId') ?? '';
	const interactionTypeId = urlParams.get('interactionTypeId') ?? '';

	const userId = !isAdmin() && hasRole(DefaultRoles.Marketer) ? authUser?.id : urlParams.get('userId') ?? '';

	useEffect(() => {
		const urlFilters = {
			userId: userId || undefined,
			prospectId: prospectId || undefined,
			saleStageId: saleStageId || undefined,
			interactionTypeId: interactionTypeId || undefined,
			createdBetween: dateRange || undefined,
		};

		setFetchParams((prevValue) => ({
			...prevValue,
			filterBy: _.pickBy(urlFilters, _.identity) as any,
		}));

		// eslint-disable-next-line
	}, [dateRange, userId, prospectId, saleStageId, interactionTypeId]);

	const tableColumns = [
		columnHelper.accessor('prospect.company_name', {
			header: 'Company Name',
			cell: ({ row }) => <div className='sm:font-semibold whitespace-nowrap'>{row.original.prospect.company_name}</div>,
		}),
		columnHelper.accessor('comment', {
			header: 'Comment',
			cell: ({ row }) => (
				<div title='comment' className='line-clamp-3 text-ellipsis'>
					{row.original.comment}
				</div>
			),
		}),
		columnHelper.accessor('interaction_after_win', {
			header: 'Interacted After Winning Prospect',
			cell: ({ row }) => (
				<div className='sm:font-semibold whitespace-nowrap'>{row.original.interaction_after_win ? <Badge color={'blue'}>After Win</Badge> : <Badge color={'gray'}>Before Win</Badge>}</div>
			),
		}),
		columnHelper.accessor('sales_stage.name', {
			header: 'Sale Stage',
			cell: ({ row }) => <div className='whitespace-nowrap'>{row.original.sales_stage.name}</div>,
		}),
		columnHelper.accessor('interaction_type.name', {
			header: 'Interaction Type',
			cell: ({ row }) => <div className='whitespace-nowrap'>{row.original.interaction_type.name}</div>,
		}),
		columnHelper.accessor('user', {
			header: 'Created By',
			cell: ({ row }) => {
				const user = row.original.user;

				<div className='sm:font-semibold whitespace-nowrap'>
					{user?.first_name} ${user?.middle_name ?? ''} ${user?.last_name}
				</div>;
			},
		}),
		columnHelper.accessor('files', {
			header: 'Attachments',
			cell: ({ row }) => {
				const files = row.original?.files;

				return (
					<div className='flex flex-wrap items-center gap-2 sm:font-semibold whitespace-nowrap'>
						{files?.map((file) => {
							return <FileOpener file={file} />;
						}) ?? <div className='font-medium'>No File Attached</div>}
					</div>
				);
			},
		}),
		columnHelper.accessor('created_at', {
			header: 'Date Created',
			cell: ({ row }) => <div className='sm:font-semibold whitespace-nowrap'>{formatDate(row.original.created_at, 'Jan 31, 1999') || 'N/A'}</div>,
		}),
	];

	const onSubmitFilters = (filters: { [key: string]: any }) => {
		const urlFilters = {
			...fetchParams.filterBy,
			userId: filters.userId,
			prospectId: filters.prospectId,
			interactionTypeId: filters.interactionTypeId,
			saleStageId: filters.saleStageId,
			dateRange: filters.createdBetween ? formatDateRangePickerValue(filters?.createdBetween as any) : undefined,
		};

		delete urlFilters.createdBetween;

		navigate(`/app/reports/interactions?${buildURLFilters(_.pickBy(urlFilters, _.identity) as any)}`);
	};

	const exportReportToExcel = (interactions: IInteraction[] | undefined) => {
		if (!interactions || interactions?.length === 0) return toast.error('No data to export. Please make sure your report has at least one record.');

		const sheetStructure = interactions.map((interaction) => {
			const { user, comment, created_at, interaction_after_win, files, interaction_type, prospect, sales_stage } = interaction;

			return {
				Prospect: prospect.company_name,
				Comment: comment,
				'Issued After Winning Prospect': interaction_after_win ? 'Issued After Win' : 'Not Before Win',
				'Sale Stage': `${sales_stage.name}`,
				'Interaction Type': `${interaction_type.name}`,
				Attachments: `${files.length} ${files.length > 0 ? 'files' : 'file'} attached`,
				'Created By': `${user?.first_name} ${user?.middle_name ?? ''} ${user?.last_name}`,
				'Date Created': formatDate(created_at, 'Jan 31, 1999') || 'N/A',
			};
		});
		convertJSONToExcel(sheetStructure, `Expected Revenue`);
	};

	return (
		<div className='flex flex-col w-full overflow-hidden h-[calc(100vh-var(--appbar-height-mobile))] lg:h-[calc(100vh-var(--appbar-height-desktop))]'>
			<div className='h-[160px] px-4 py-4 bg-gray-100 border w-full flex items-start justify-between'>
				<div>
					<Title className='!font-bold text-gray-800'>Interactions</Title>
					<Text className='2xl:hidden'>Interactions report.</Text>
				</div>

				<div className='inline-flex rounded-md shadow-sm ' role='group'>
					<button
						onClick={() => exportReportToExcel(interactions)}
						type='button'
						className='px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-sky-700 focus:z-10 focus:ring-2 focus:ring-sky-700 focus:text-sky-700'
					>
						Export To Excel
					</button>

					{/* <button
						type='button'
						className='px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-sky-700 focus:z-10 focus:ring-2 focus:ring-sky-700 focus:text-sky-700'
					>
						Download PDF
					</button> */}
				</div>
			</div>

			<div className='relative w-full h-[calc(100%-100px)] lg:h-[calc(100%-160px)] 2xl:h-[calc(100%-100px)] bg-white'>
				<div className='absolute -top-14 lg:-top-24 2xl:-top-14 bottom-0 inset-x-0 mx-4 pb-4 h-[calc(100%+60px)] lg:h-[calc(100%+100px)] 2xl:h-[calc(100%+60px)] bg-white shadow-sm border rounded-t-xl'>
					<div className='h-[55px] lg:h-[95px] 2xl:h-[55px] sticky inset-x-0 top-0 z-20 flex items-center justify-between px-4 lg:px-2 py-2 bg-white rounded-t-xl border-b'>
						<p className='text-sm font-semibold lg:hidden'>{interactions && interactions?.length > 0 ? `${interactions.length} records` : '0 records'}</p>

						<div className='relative hidden w-full h-full lg:block'>
							<Filters
								wrapperClassName={`lg:!flex lg:!flex-wrap lg:!h-full`}
								filters={[
									{
										type: FilterComponentTypes.DateRange,
										name: 'createdBetween',
										label: 'Dates',
										hideLabel: true,
										placeholder: 'Select date range',
										defaultValue: dateRange ? { from: new Date(dateRange.split(',')[0]), to: new Date(dateRange.split(',')[1]) } : undefined,
									},
									{
										type: FilterComponentTypes.Select,
										name: 'userId',
										hideLabel: true,
										defaultValue: userId?.toString(),
										label: 'Staff',
										dropdownOptions:
											users
												?.filter((user) => {
													// return user.roles.includes(DefaultRoles.Marketer) || user.roles.includes(DefaultRoles.MarketingManager)
													return !user.roles.includes(DefaultRoles.SuperAdmin);
												})
												.map((user: any) => ({
													id: user.id,
													label: `${user.first_name} ${user?.middle_name ?? ' '} ${user.last_name}`,
													value: user.id,
												})) ?? [],
										placeholder: 'Select staff',
										permission: PermissionKeys.VIEW_USER_FILTERS,
									},
									{
										label: 'Prospect',
										name: 'prospectId',
										hideLabel: true,
										type: FilterComponentTypes.Select,
										placeholder: 'Select prospect',
										dropdownOptions:
											prospectsFilterData?.map((prospect) => ({
												id: prospect.id,
												label: prospect.company_name,
												value: prospect.id,
											})) ?? [],
									},
									{
										label: 'Sale Stage',
										name: 'saleStageId',
										hideLabel: true,
										type: FilterComponentTypes.Select,
										placeholder: 'Select stage',
										dropdownOptions:
											salesStagesFilterData?.map((stage) => ({
												id: stage.id,
												label: stage.name,
												value: stage.id,
											})) ?? [],
									},
									{
										label: 'Interaction Type',
										name: 'interactionTypeId',
										hideLabel: true,
										type: FilterComponentTypes.Select,
										placeholder: 'Interaction type',
										dropdownOptions:
											interactionTypesFilterData?.map((interactionType) => ({
												id: interactionType.id,
												label: interactionType.name,
												value: interactionType.id,
											})) ?? [],
									},
								]}
								submitButton={{
									className: `!bg-gray-800 hover:!bg-gray-600 text-white !capitalize border-0 duration-300`,
									label: 'Apply Filters',
									onSubmit: onSubmitFilters,
								}}
							/>
						</div>

						<div className='lg:hidden'>
							<Button
								color='gray'
								variant='secondary'
								onClick={() => setMobileFiltersOpen(true)}
								icon={RiFilter2Fill}
								className='!rounded-full hover:bg-gray-700 hover:!text-gray-100 duration-300 lg:hidden'
							>
								Filters
							</Button>
						</div>
					</div>

					<div className='h-[calc(100%-40px)] lg:h-[calc(100%-80px)] 2xl:h-[calc(100%-40px)] overflow-x-hidden overflow-y-auto w-full relative z-10'>
						{hasPermission(PermissionKeys.VIEW_PROSPECT) ? (
							<Fragment>
								{!interactions && isInteractionsLoading ? (
									<div className='mt-6'>
										<ContentLoadingCardSpinner containerClassName='!shadow-none !border-0 !ring-0' message='Loading interactions...' />
									</div>
								) : interactions && interactions.length > 0 ? (
									<div className='w-full h-full overflow-x-hidden overflow-y-auto'>
										<div className='w-full top-0 inset-x-0 sticky shadow-sm flex justify-between items-center px-2 h-[36px] z-20 border-b bg-white'>
											<p className='hidden text-sm font-semibold lg:block '>{interactions && interactions?.length > 0 ? `${interactions.length} records` : '0 records'}</p>
										</div>

										<Table
											data={interactions}
											columns={tableColumns}
											pagination={{
												handlePageChange: (page: number) => {
													setFetchParams((prevValue) => ({
														...prevValue,
														page,
													}));
												},
												metadata: paginationMetadata,
											}}
										/>
									</div>
								) : (
									<SimpleEmptyResourceIndicator containerClassName='lg:mt-8 lg:!py-10 !shadow-none !border-0 !ring-0' message='No prospect found' />
								)}
							</Fragment>
						) : (
							<ContentBlockedFromView message='You are not authorized to view prospects.' containerClassName='min-h-[350px] !shadow-none !border-0 !ring-0' />
						)}
					</div>
				</div>
			</div>

			<BottomSheet
				isOpen={isMobileFiltersOpen}
				setOpen={setMobileFiltersOpen}
				closeOnOutSideClick
				header={{
					text: 'Filter Analytics',
				}}
			>
				<Filters
					filters={[
						{
							type: FilterComponentTypes.DateRange,
							name: 'createdBetween',
							label: 'Dates',
							hideLabel: true,
							placeholder: 'Select date range',
							defaultValue: dateRange ? { from: new Date(dateRange.split(',')[0]), to: new Date(dateRange.split(',')[1]) } : undefined,
						},
						{
							type: FilterComponentTypes.Select,
							name: 'userId',
							hideLabel: true,
							defaultValue: userId?.toString(),
							label: 'Staff',
							dropdownOptions:
								users
									?.filter((user) => {
										// return user.roles.includes(DefaultRoles.Marketer) || user.roles.includes(DefaultRoles.MarketingManager)
										return !user.roles.includes(DefaultRoles.SuperAdmin);
									})
									.map((user: any) => ({
										id: user.id,
										label: `${user.first_name} ${user?.middle_name ?? ' '} ${user.last_name}`,
										value: user.id,
									})) ?? [],
							placeholder: 'Select staff',
							permission: PermissionKeys.VIEW_USER_FILTERS,
						},
						{
							label: 'Prospect',
							name: 'prospectId',
							hideLabel: true,
							type: FilterComponentTypes.Select,
							placeholder: 'Select prospect',
							dropdownOptions:
								prospectsFilterData?.map((prospect) => ({
									id: prospect.id,
									label: prospect.company_name,
									value: prospect.id,
								})) ?? [],
						},
						{
							label: 'Sale Stage',
							name: 'saleStageId',
							hideLabel: true,
							type: FilterComponentTypes.Select,
							placeholder: 'Select stage',
							dropdownOptions:
								salesStagesFilterData?.map((stage) => ({
									id: stage.id,
									label: stage.name,
									value: stage.id,
								})) ?? [],
						},
						{
							label: 'Interaction Type',
							name: 'interactionTypeId',
							hideLabel: true,
							type: FilterComponentTypes.Select,
							placeholder: 'Interaction type',
							dropdownOptions:
								interactionTypesFilterData?.map((interactionType) => ({
									id: interactionType.id,
									label: interactionType.name,
									value: interactionType.id,
								})) ?? [],
						},
					]}
					submitButton={{
						className: `!bg-gray-800 hover:!bg-gray-600 text-white !capitalize border-0 duration-300`,
						label: 'Apply Filters',
						onSubmit: onSubmitFilters,
					}}
				/>
			</BottomSheet>
		</div>
	);
};

export default InteractionsReportPage;
