import { Text, TextInput } from '@tremor/react';
import React, { ChangeEvent, useState } from 'react';
import OutsideClickHandler from './OutsideClickHandler';

interface Props {
	type: 'text' | 'email' | 'password' | 'url' | undefined;
	icon?: any;
	menu: string[];
	hasError: boolean;
	id: string;
	errorMessage?: string;
	placeholder?: string;
	onValueChange: (value: any) => void;
	rest?: any;
}

const InputWithAutocomplete = (props: Props) => {
	const { hasError, placeholder, type, errorMessage, id, icon, menu, onValueChange, ...rest } = props;

	const [inputValue, setInputValue] = useState('');
	const [suggestions, setSuggestions] = useState<string[]>([]);

	const handleInputChange = (event: any) => {
		const value = event.target.value;

		setInputValue(value);

		if (value === 'No matches found') onValueChange(undefined);
		else onValueChange(value);

		if (value.length > 0) {
			const filteredSuggestions = menu.filter((suggestion) => suggestion?.toLowerCase().includes(value?.toLowerCase()));
			setSuggestions(filteredSuggestions.length > 0 ? filteredSuggestions : ['No matches found']);
		} else {
			setSuggestions([]);
		}
	};

	const handleSuggestionClick = (value: any) => {
		if (value === 'No matches found') {
			setInputValue('');
			onValueChange(undefined);
		} else {
			setInputValue(value);
			onValueChange(value);
		}

		setSuggestions([]);
	};

	return (
		<OutsideClickHandler onOutsideClick={() => setSuggestions([])}>
			<div className='relative w-full h-fit z-[20]'>
				<TextInput
					{...rest}
					autoComplete='off'
					type={type}
					id={id}
					onChange={handleInputChange}
					value={inputValue}
					icon={icon ?? undefined}
					placeholder={placeholder}
					error={hasError}
					errorMessage={errorMessage}
					className='[&~p]:absolute'
				/>

				{suggestions.length > 0 && (
					<ul className='absolute with-scrollbar max-h-[200px] overflow-y-auto inset-x-0 top-[calc(100%+2px)] overflow-hidden bg-white divide-y rounded-lg shadow suggestions-list'>
						{suggestions.map((suggestion, index) => (
							<li className='px-4 py-2 bg-white cursor-pointer hover:bg-primary-200 duration-0' key={index} onClick={() => handleSuggestionClick(suggestion)}>
								<Text className='text-black'>{suggestion}</Text>
							</li>
						))}
					</ul>
				)}
			</div>
		</OutsideClickHandler>
	);
};

export default InputWithAutocomplete;
