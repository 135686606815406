import React from 'react';

interface Props {
	className?: string;
}

const AppLogoEmblem = ({ className }: Props) => {
	return <img src='/images/loyalty_logo_emblem.svg' alt='Loyalty Insurance Company Limited Logo' className={`h-[60%] w-[60%] lg:h-[60%] lg:w-[60%] ${className}`} />;
};

export default AppLogoEmblem;
