import { MdDoNotDisturbOnTotalSilence, MdPending } from 'react-icons/md';
import { RiDashboard3Fill } from 'react-icons/ri';
import { PiClockCountdownFill } from 'react-icons/pi';
import { IDashboardItemTheme, ITransportManagerDashboardData, dashboardStatisticsColorOrder2 } from './dashboardInterfaces';
import StatisticsCardGroup from 'components/StatisticsCard/StatisticsCardGroup';
import StatisticsCardItem from 'components/StatisticsCard/StatisticsCardItem';

interface Props {
	data: ITransportManagerDashboardData | undefined;
	isDataLoading: boolean;
}

export const transportDashboardItemsTheme: IDashboardItemTheme[] = [
	{
		icon: MdPending,
		color: dashboardStatisticsColorOrder2[0],
	},
	{
		icon: RiDashboard3Fill,
		color: dashboardStatisticsColorOrder2[1],
	},
	{
		icon: PiClockCountdownFill,
		color: dashboardStatisticsColorOrder2[2],
	},
	{
		icon: MdDoNotDisturbOnTotalSilence,
		color: dashboardStatisticsColorOrder2[3],
	},
];

const TransportDashboardStatisticsCards = ({ data, isDataLoading }: Props) => {
	return (
		<StatisticsCardGroup decorationColor='sky'>
			<StatisticsCardItem title='Total Trips' metric={(data?.total_trips ?? 0) as any} loading={isDataLoading} />
			<StatisticsCardItem title='Total Active Trips' metric={(data?.total_active_trips ?? 0) as any} loading={isDataLoading} />
			<StatisticsCardItem title='Total Pending Trips' metric={(data?.total_pending_trips ?? 0) as any} loading={isDataLoading} />
			<StatisticsCardItem title='Total Completed Trips' metric={(data?.total_completed_trips ?? 0) as any} loading={isDataLoading} />
		</StatisticsCardGroup>
	);
};

export default TransportDashboardStatisticsCards;

/* <Grid numItemsSm={2} className='w-full gap-6 xl:grid-cols-4'>
				<Fragment>
					<StatisticsCard
						color={transportDashboardItemsTheme[0].color}
						icon={{
							icon: transportDashboardItemsTheme[0].icon,
						}}
						title='Total Trips'
						value={data?.total_trips as any}
						loading={isDataLoading}
					/>
					<StatisticsCard
						color={transportDashboardItemsTheme[1].color}
						icon={{
							icon: transportDashboardItemsTheme[1].icon,
						}}
						title='Total Active Trips'
						value={data?.total_active_trips as any}
						loading={isDataLoading}
					/>
					<StatisticsCard
						color={transportDashboardItemsTheme[2].color}
						icon={{
							icon: transportDashboardItemsTheme[2].icon,
						}}
						title='Total Pending Trips'
						value={data?.total_pending_trips as any}
						loading={isDataLoading}
					/>
					<StatisticsCard
						color={transportDashboardItemsTheme[3].color}
						icon={{
							icon: transportDashboardItemsTheme[3].icon,
						}}
						title='Total Completed Trips'
						value={data?.total_completed_trips as any}
						loading={isDataLoading}
					/>
				</Fragment>
			</Grid> */
