import { BadgeDelta, Button, Card, Color, Flex, Metric, Text } from '@tremor/react';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { Tooltip } from 'react-tooltip';

interface Props {
	title: string;
	metric: string;
	delta?: {
		icon: any;
		value?: number;
		color: Color;
		label: string;
	};
	action?: {
		onClick: (data: any) => void;
		hoverMessage?: string;
		className?: string;
		actionLabel?: string;
	};
	unitPrefix?: string;
	unitSuffix?: string;
	loading?: boolean;
	alt?: boolean;
}

const StatisticsCardItem = (props: Props) => {
	const { delta, unitPrefix, unitSuffix, metric, title, loading, action, alt } = props;

	return (
		<div className='self-stretch flex-1 flex-shrink-0 p-2 min-w-[220px]'>
			<Card
				onClick={() => action?.onClick(props)}
				className={`!py-2 !px-3 h-full w-full flex-grow-0 flex-shrink-0 duration-300 !border-0 !shadow-none ring-0 flex flex-col justify-between
        ${action ? 'hover:!bg-[#f3f3f3] hover:cursor-pointer' : 'hover:!cursor-not-allowed'} ${alt ? 'bg-[#f3f3f3] hover:!bg-[#dfdfdf] hover:cursor-pointer' : ''}`}
				data-tooltip-id={title}
				data-tooltip-content={action?.hoverMessage}
			>
				<div>{!loading ? <Text className='!text-sm !font-medium text-gray-500'>{title}</Text> : <div className='h-4 bg-[#e3e3e3] rounded-full animate-pulse w-14' />}</div>

				<div>
					{action?.actionLabel && (
						<Button
							icon={IoIosArrowRoundForward}
							iconPosition='right'
							className={`duration-300 !px-0 !py-0 my-2 !font-normal !text-xs !uppercase [&>span]:!text-xs ${action?.className}`}
							variant='light'
							color='gray'
							size='xs'
							onClick={() => action?.onClick}
						>
							{action?.actionLabel}
						</Button>
					)}

					<Flex justifyContent='between' alignItems='baseline' className='space-x-3 truncate'>
						{loading ? (
							<div className='h-5 mt-2 bg-[#e3e3e3] rounded-full animate-pulse w-14' />
						) : (
							<Metric className='!text-[1.8rem] !text-gray-700 !font-bold'>
								<span className='text-xs font-medium'>{unitPrefix}</span> {Number(metric).toLocaleString()} <span className='text-xs font-medium'>{unitSuffix}</span>
							</Metric>
						)}
					</Flex>

					{delta && (
						<Flex justifyContent='start' className='mt-4 space-x-2'>
							<BadgeDelta deltaType={delta.icon} />

							<Flex justifyContent='start' className='space-x-1 truncate'>
								<Text color={delta.color}>{delta.value}</Text>
								<Text className='truncate'>{delta.label}</Text>
							</Flex>
						</Flex>
					)}
				</div>
			</Card>

			{action && <Tooltip id={title} place='top' positionStrategy='fixed' variant='dark' float />}
		</div>
	);
};

export default StatisticsCardItem;
