import { lsmsAPI } from 'ajax/httpClient';
import { ICreateTeamMemberFormValues } from '../prospectInterfaces';

export const createTeamMember = (formValues: ICreateTeamMemberFormValues) => lsmsAPI.post('/team-members', formValues);

export const transferTeamLeadership = (teamMemberId: number) => lsmsAPI.put(`/team-members/${teamMemberId}`, {});

export const updateTeamMember = ({ formValues, teamMemberId }: { formValues: ICreateTeamMemberFormValues; teamMemberId: number }) => lsmsAPI.put(`/team-members/${teamMemberId}`, formValues);

export const deleteTeamMember = (teamMemberId: number) => lsmsAPI.delete(`/team-members/${teamMemberId}`);
