import { useForm } from 'react-hook-form';
import AuthBranding from 'components/AuthBranding';
import { IForgotPasswordActionTypes, IForgotPasswordFormTypes } from './authInterfaces';
import { TextInput, Button } from '@tremor/react';
import { HiArrowLongLeft } from 'react-icons/hi2';
import { forgotPassword } from './authQueries';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { processReactHookFormErrors } from 'helpers';

const ForgotPasswordPage = () => {
	const {
		register,
		handleSubmit,
		setError,
		reset,
		formState: { errors },
	} = useForm<IForgotPasswordFormTypes>({});

	const { isLoading: isForgotPasswordLoading, mutate: forgotPasswordMutation } = useMutation({
		mutationKey: ['auth/forgot-password'],
		mutationFn: (formValues: IForgotPasswordActionTypes) => forgotPassword(formValues),
	});

	const onSubmit = (formValues: IForgotPasswordFormTypes) => {
		formValues.email = formValues.email.trim();

		forgotPasswordMutation(formValues, {
			onSuccess() {
				toast.success('Password reset link has been sent to your mailbox. Kindly use the instructions provided in the mail to reset your password.');
				reset();
			},
			onError(errors) {
				processReactHookFormErrors(errors, 'Failed to request password reset', setError);
			},
		});
	};

	return (
		<div id='login-page' className='w-full h-full'>
			<div className='h-full p-4 bg-gradient-to-br from-gray-200 to-primary-100 lg:p-8 lg:from-white lg:to-white lg:max-w-[400px] mx-auto'>
				{/* Branding */}
				<div>
					<AuthBranding />
				</div>

				<div className='mt-16 sm:mt-[10vh] flex flex-col items-center lg:items-start'>
					<a href='/auth/login' className='flex items-center gap-2 py-2 mb-4 duration-300 hover:text-secondary-500'>
						<HiArrowLongLeft className='' /> Back to login
					</a>

					<h1 className='text-3xl font-bold text-gray-700 lg:font-semibold'>Forgot Password</h1>
					<p className='mt-4 text-gray-500'>Please enter your email to request password reset.</p>
				</div>

				<form onSubmit={handleSubmit(onSubmit)} className='flex flex-col items-center mt-10 lg:items-start'>
					<div className='space-y-4 w-11/12 sm:w-[400px]'>
						<div className='grid gap-1 form-control'>
							<label htmlFor='email' className='text-sm font-medium uppercase lg:font-normal lg:capitalize '>
								Email
							</label>
							<TextInput
								id='email'
								type='text'
								{...register('email')}
								name='email'
								placeholder='Enter email'
								className='text-lg sm:h-[40px]'
								error={errors.email ? true : false}
								errorMessage={errors.email?.message}
							/>
						</div>
					</div>

					<div className='py-8 text-center lg:mt-5'>
						<Button size='xl' type='submit' loading={isForgotPasswordLoading} loadingText='Requesting Reset' className='px-8 border-none !rounded-full uppercase bg-primary-500'>
							Reset Password
						</Button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ForgotPasswordPage;
