import { IUser } from 'features/auth/authInterfaces';
import { IProspect } from 'features/prospects/prospectInterfaces';
import { ISalesStage } from 'features/settings/prospecting/prospectSettingsInterfaces';

export interface IAppointment {
	id: number;
	appointment_date: Date;
	status: AppointmentStatuses;
	fulfillment_status: AppointmentFulfillmentStatuses;
	comment: null;
	prospect_id: number;
	user_id: number;
	created_at: Date;
	updated_at: Date;
	prospect: IProspect;
	sales_stage: ISalesStage;
	user: IUser;
	comments: any[];
}

export enum AppointmentStatuses {
	Pending = 'Pending',
	Declined = 'Declined',
	Approved = 'Approved',
	Cancelled = 'Cancelled',
}
export enum AppointmentFulfillmentStatuses {
	Fulfilled = 'Fulfilled',
	Pending = 'Pending',
}

export interface ICreateAppointmentFormValues {
	appointment_date: string;
	prospect_id: number;
}

export interface ICreateAppointmentInsuranceProduct {}

export type IFetchAppointmentIncludes = 'prospect' | 'user' | 'sales_stage' | 'comments';
export type IFetchAppointmentFilters = {
	dateType?: string;
	dateRange?: string;
	status?: AppointmentStatuses;
	staffId?: number;
	prospectId?: number;
	createdAt?: string;
	createdBetween?: string;
	appointmentDate?: string;
};
export interface IFetchAppointmentsActionParams {
	include?: IFetchAppointmentIncludes[];
	filterBy?: IFetchAppointmentFilters;
	page?: number;
}

export interface IAppointmentCommentFormValues {
	comment: string;
	appointmentId: number;
}

export interface IAppointmentComment {
	id: number;
	comment: string;
	created_at: Date;
	created_by: IUser;
}
