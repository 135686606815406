import { HOST_URL } from 'config';
import { IInteractionFile } from 'features/interactions/interactionInterfaces';
import { IoOpenOutline } from 'react-icons/io5';

interface Props {
	file: IInteractionFile;
	className?: string;
}

const FileOpener = ({ file, className }: Props) => {
	const openFile = () => {
		const fileUrl = `${HOST_URL}/${file.file_path}`;

		const newWindow = window.open(fileUrl, '_blank');
		if (newWindow) {
			newWindow.focus();
		} else {
			alert('Please allow pop-ups to open the file.');
		}
	};

	return (
		<button
			className={`grid grid-cols-[1fr,30px] h-[32px] min-w-[100px] max-w-[200px] overflow-hidden text-white bg-gray-700 border rounded-full line-clamp-1 relative gap-2 text-ellipsis ${className}`}
			onClick={openFile}
		>
			<span className='pl-3 py-1.5 overflow-hidden text-xs text-ellipsis text-left'>
				<span className='capitalize'>{file.file_name}</span>.<span className='lowercase'>{file.file_ext}</span>
			</span>

			<span className='flex items-center justify-center pr-0.5 w-8 h-full  border-l bg-black border-gray-500 cursor-pointer'>
				<IoOpenOutline className='w-4 h-4 shrink-0' />
			</span>
		</button>
	);
};

export default FileOpener;
