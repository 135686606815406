import { Fragment, useState } from 'react';
import Container from 'components/Container';
import TransportDashboardStatisticsCards from './TransportDashboardStatisticsCards';
import DashboardCompletedTrips from './DashboardCompletedTrips';
import { convertJSONToExcel, hasPermission, hasRole, hasRoles, isAdmin, isSuperAdmin } from 'helpers/index';
import { DefaultRoles } from 'features/settings/authorization/authorizationInterface';
import { useQuery } from '@tanstack/react-query';
import { IFetchTransportManagerDashboardFilters, ITransportManagerDashboardData } from './dashboardInterfaces';
import { fetchTransportManagerDashboard, fetchTransportOfficerDashboard } from './dashboardQueries';
import { Button, Title } from '@tremor/react';
import { formatDate } from 'helpers/dateHelpers';
import Filters from 'components/Filters';
import { FilterComponentTypes } from 'features/settings/settingsInterfaces';
import useFetchUsers from 'features/settings/users/useFetchUsers';
import BottomSheet from 'components/BottomSheet';
import { RiFilter2Fill } from 'react-icons/ri';
import { PermissionKeys } from 'features/auth/permissions';
import { SiMicrosoftexcel } from 'react-icons/si';
import toast from 'react-hot-toast';
import { useAppSelector } from 'hooks/storeConnect';
import { authState } from 'features/auth/authSlice';
import { isTransportOfficer } from 'utils/index';

const TransportDashboardPage = () => {
	const { authUser } = useAppSelector(authState);
	const [fetchFilters, setFetchFilters] = useState<IFetchTransportManagerDashboardFilters>({
		transportOfficerId: isTransportOfficer() ? authUser?.id : undefined,
	});

	const { users } = useFetchUsers({
		enabled: hasPermission(PermissionKeys.VIEW_USER),
	});
	const [isMobileFiltersOpen, setMobileFiltersOpen] = useState<boolean>(false);

	// Fetch Transport Manager Dashboard
	const { data: transportManagerData, isLoading: isTransportManagerDashboardLoading } = useQuery({
		queryKey: ['dashboard/fetch-transport-manager', fetchFilters],
		queryFn: () => fetchTransportManagerDashboard(fetchFilters ?? {}),
		enabled: hasRole(DefaultRoles.TransportManager),
	});

	// Fetch Transport Officer Dashboard
	const { data: transportOfficerData, isLoading: isTransportOfficerDashboardLoading } = useQuery({
		queryKey: ['dashboard/fetch-transport-officer', fetchFilters],
		queryFn: () => fetchTransportOfficerDashboard(fetchFilters ?? {}),
		enabled: hasRole(DefaultRoles.TransportOfficer),
	});
	const transportManagerDashboardData: ITransportManagerDashboardData | undefined = transportManagerData?.data.data;
	const transportOfficerDashboardData: ITransportManagerDashboardData | undefined = transportOfficerData?.data.data;

	const onSubmitFilters = (filters: IFetchTransportManagerDashboardFilters) => {
		setFetchFilters({
			...fetchFilters,
			...filters,
			createdAt: filters.createdAt ? formatDate(filters?.createdAt as any, '1999-12-31') : undefined,
		});
	};

	const buildSheetMetrics = (): ISheetStructure | null => {
		const sheetMetrics: ISheetStructure = [];
		const dashboardData = authUser?.roles.includes(DefaultRoles.TransportManager) ? transportManagerDashboardData : transportOfficerDashboardData;

		if (!dashboardData) return null;

		sheetMetrics.push({
			'Metric Name': 'Total Trips',
			Value: dashboardData.total_trips as any,
		});
		sheetMetrics.push({
			'Metric Name': 'Total Active Trips',
			Value: dashboardData.total_active_trips as any,
		});
		sheetMetrics.push({
			'Metric Name': 'Total Pending Trips',
			Value: dashboardData.total_pending_trips as any,
		});
		sheetMetrics.push({
			'Metric Name': 'Total Completed Trips',
			Value: dashboardData.total_completed_trips as any,
		});

		return sheetMetrics;
	};

	const onExportMetricsToExcel = () => {
		const sheetStructure: ISheetStructure | null = buildSheetMetrics();

		if (sheetStructure == null) return toast.error('No data to export.');

		let [startDate, endDate]: any = fetchFilters?.createdBetween ? fetchFilters.createdBetween.split(',') : [formatDate(new Date(), '1999-12-31'), null];

		if (sheetStructure.length === 0) return toast.error('No data to export. Please make sure your report has at least one record.');

		convertJSONToExcel(sheetStructure, `Report ${formatDate(startDate, '31-12-1999')}${endDate ? ` to ${formatDate(endDate, '31-12-1999')}` : ''}`);
	};

	return (
		<div className='w-full h-full pt-2 pb-6'>
			<Container>
				<div className='flex items-center justify-between gap-2 pb-4'>
					<Title className='!text-2xl'>{isAdmin() && <span className='hidden sm:inline'>Transport</span>} Dashboard</Title>

					{/* ===============
          // Filters
          =============== */}
					<div className='hidden ml-auto lg:block'>
						<Filters
							filters={[
								{
									type: FilterComponentTypes.DateRange,
									name: 'createdBetween',
									label: 'Dates',
									className: 'lg:!min-w-[300px]',
									hideLabel: true,
									placeholder: 'Select date range',
								},
								hasRoles([DefaultRoles.TransportManager, DefaultRoles.MarketingManager])
									? {
											type: FilterComponentTypes.Select,
											name: 'transportOfficerId',
											label: 'Staff',
											hideLabel: true,
											dropdownOptions:
												users
													?.filter((user) => user.roles.includes(DefaultRoles.TransportManager) || user.roles.includes(DefaultRoles.TransportOfficer))
													?.map((user: any) => ({
														id: user.id,
														label: `${user.first_name} ${user?.middle_name ?? ' '} ${user.last_name}`,
														value: user.id,
													})) ?? [],
											placeholder: 'Select officer',
									  }
									: null,
							]}
							submitButton={{
								label: 'Apply Filters',
								onSubmit: onSubmitFilters,
							}}
						/>
					</div>

					<Button
						color='gray'
						variant='secondary'
						onClick={() => setMobileFiltersOpen(true)}
						icon={RiFilter2Fill}
						className='!rounded-full ml-auto hover:bg-gray-700 hover:!text-gray-100 duration-300 lg:hidden'
					>
						Filters
					</Button>

					<Button onClick={onExportMetricsToExcel} iconPosition='left' size='sm' color='gray' className='!rounded-full uppercase'>
						Export
					</Button>
				</div>

				<Fragment>
					<div className=''>
						{/* ======= Transport Manager ======= */}
						{hasRole(DefaultRoles.TransportManager) && <TransportDashboardStatisticsCards data={transportManagerDashboardData} isDataLoading={isTransportManagerDashboardLoading} />}
						{/* ======= Transport Officer ======= */}
						{!isAdmin() && hasRole(DefaultRoles.TransportOfficer) && <TransportDashboardStatisticsCards data={transportOfficerDashboardData} isDataLoading={isTransportOfficerDashboardLoading} />}

						{/* ====== Transport Manager ======*/}
						{hasRole(DefaultRoles.TransportManager) && (
							<div className='w-full mt-8'>
								<DashboardCompletedTrips data={transportManagerDashboardData} isDataLoading={isTransportManagerDashboardLoading} />
							</div>
						)}
						{/* ====== Transport Officer ======*/}
						{!isAdmin() && hasRole(DefaultRoles.TransportOfficer) && (
							<div className='w-full mt-8'>
								<DashboardCompletedTrips data={transportOfficerDashboardData} isDataLoading={isTransportOfficerDashboardLoading} />
							</div>
						)}
					</div>
				</Fragment>
			</Container>

			<BottomSheet
				isOpen={isMobileFiltersOpen}
				setOpen={setMobileFiltersOpen}
				closeOnOutSideClick
				header={{
					text: 'Filter Dashboard Data',
				}}
			>
				<Filters
					filters={[
						{
							type: FilterComponentTypes.DateRange,
							name: 'createdBetween',
							label: 'Dates',
							className: 'lg:!min-w-[300px]',
							hideLabel: true,
							placeholder: 'Select date range',
						},
						hasRoles([DefaultRoles.TransportManager, DefaultRoles.MarketingManager, DefaultRoles.Admin])
							? {
									type: FilterComponentTypes.Select,
									name: 'transportOfficerId',
									label: 'Staff',
									hideLabel: true,
									dropdownOptions:
										users
											?.filter((user) => user.roles.includes(DefaultRoles.TransportManager) || user.roles.includes(DefaultRoles.TransportOfficer))
											.map((user: any) => ({
												id: user.id,
												label: `${user.first_name} ${user?.middle_name ?? ' '} ${user.last_name}`,
												value: user.id,
											})) ?? [],
									placeholder: 'Select officer',
							  }
							: null,
					]}
					submitButton={{
						label: 'Apply Filters',
						onSubmit: onSubmitFilters,
					}}
				/>
			</BottomSheet>
		</div>
	);
};

export default TransportDashboardPage;
