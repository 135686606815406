import _ from 'lodash';
import { Fragment } from 'react';
import { FollowUpStatuses, IFollowUp } from './followUpInterfaces';
import { Badge, Button, Callout, Text, Title } from '@tremor/react';
import { useNavigate } from 'react-router-dom';
import { DropdownMenu } from 'components/DropdownMenu';
import { DeleteIcon, EditIcon, VerticalDotsIcons } from 'components/FormActionIcons';
import { Tooltip } from 'react-tooltip';
import { formatDateComparison } from 'helpers/dateHelpers';
import { setActiveProspect } from 'features/prospects/prospectsSlice';
import { useAppDispatch, useAppSelector } from 'hooks/storeConnect';
import { RiSpeakLine } from 'react-icons/ri';
import toast from 'react-hot-toast';
import { TiCancelOutline } from 'react-icons/ti';
import { hasPermission, hasRoles } from 'helpers/index';
import { PermissionKeys } from 'features/auth/permissions';
import { HiExclamation } from 'react-icons/hi';
import { DefaultRoles } from 'features/settings/authorization/authorizationInterface';
import { authState } from 'features/auth/authSlice';
import { getFollowUpState } from 'utils/index';

interface Props {
	followUp: IFollowUp;
	onCompleteFollowUp: (followUp: IFollowUp) => void;
	onRescheduleFollowUp: (followUp: IFollowUp) => void;
	onDeleteFollowUp: (followUp: IFollowUp) => void;
}

const FollowUp = (props: Props) => {
	const { followUp, onCompleteFollowUp, onRescheduleFollowUp, onDeleteFollowUp } = props;
	const { prospect, follow_up_date, status, user } = followUp;
	const { insurance_products } = prospect;
	const products = _.uniqBy(insurance_products, 'insurance_product_type_id');

	const { authUser } = useAppSelector(authState);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const followUpActions: DropdownMenu = [];

	// hasPermission(PermissionKeys.VIEW_INTERACTION) &&
	followUpActions.push({
		type: 'button',
		text: 'Interact',
		action: (e: any) => {
			if (status !== FollowUpStatuses.COMPLETED) return toast.error('You cannot interact on unfulfilled reminders. Kindly complete reminder first.');

			dispatch(setActiveProspect(prospect));
			navigate(`/app/prospects/${prospect.id}/interactions`);
		},
		icon: <RiSpeakLine className='w-6 h-6' />,
	});

	if (status !== FollowUpStatuses.COMPLETED && hasPermission(PermissionKeys.UPDATE_FOLLOW_UP) && hasRoles([DefaultRoles.MarketingManager, DefaultRoles.Admin])) {
		followUpActions.push({
			type: 'button',
			text: 'Reschedule',
			action: (e: any) => {
				onRescheduleFollowUp(followUp);
			},
			icon: <EditIcon />,
		});
		hasPermission(PermissionKeys.DELETE_FOLLOW_UP) &&
			followUpActions.push({
				type: 'button',
				text: 'Delete Reminder',
				action: (e: any) => {
					onDeleteFollowUp(followUp);
				},
				icon: <DeleteIcon />,
			});
	}

	if (followUpActions.length === 0) {
		followUpActions.push({
			type: 'button',
			text: 'NOT AUTHORIZED',
			action: (e: any) => toast.error('You are not authorized perform any action on follow ups'),
			icon: <TiCancelOutline />,
		});
	}

	const { formattedDuration } = formatDateComparison(follow_up_date);
	const { isCompleted, isUncompleted } = getFollowUpState(followUp);

	const MAX_SHOW_PRODUCTS = 2;
	const IS_MORE_THAN_PRODUCT_TO_SHOW = products.length > MAX_SHOW_PRODUCTS;

	return (
		<div className={`bg-light space-y-2 border border-l-[3px] p-3 rounded-lg ${isUncompleted ? 'border-l-red-500' : isCompleted ? 'border-l-green-500' : 'border-l-yellow-500'}`}>
			{/* Top Content */}
			<div>
				<div className='flex items-start justify-between gap-6'>
					<Text className='text-sm text-gray-900 uppercase'>{followUp.sales_stage.name}</Text>
					<div className='flex items-center divide-x'>
						<div className='pl-2 text-sm text-gray-600'>
							<span className='flex items-center divide-x'>
								{/* Insurance Products */}
								{products.length > 0
									? products.slice(0, MAX_SHOW_PRODUCTS).map((product, index) => (
											<span key={index} className={`flex px-1 first-of-type:pl-0 py-0 items-center text-gray-500 gap-2 ${IS_MORE_THAN_PRODUCT_TO_SHOW ? '' : 'last-of-type:pr-0'}`}>
												<Text className='capitalize'>{product.insurance_product_type.name}</Text>
											</span>
									  ))
									: 'N/a'}

								{IS_MORE_THAN_PRODUCT_TO_SHOW && (
									<Fragment>
										<Badge
											size='xs'
											className='ml-1 text-black bg-gray-300'
											data-tooltip-id='app-follow-products-tooltip'
											data-tooltip-content={products.map((product) => product.insurance_product_type.name).join(' | ')}
										>
											+{products.length - MAX_SHOW_PRODUCTS}{' '}
										</Badge>
										<Tooltip id={'app-follow-products-tooltip'} place='left' variant='dark' />
									</Fragment>
								)}
							</span>
						</div>
					</div>
				</div>

				<Text className='text-xs text-gray-500'>
					<span className='font-medium text-black'>Schedule:</span> {formattedDuration.charAt(0).toUpperCase() + formattedDuration.slice(1)}
				</Text>

				<Text className='text-xs text-gray-500'>
					<span className='font-medium text-black'>Last Interaction:</span> {formattedDuration.charAt(0).toUpperCase() + formattedDuration.slice(1)}
				</Text>
			</div>

			{/* Mid Content */}
			<div className='pr-4'>
				<Title>{prospect.company_name}</Title>
			</div>

			{/* Actions */}
			<div className='flex flex-col'>
				<div className='flex items-center justify-between gap-2'>
					{isUncompleted ? (
						<Badge size='xl' className='ml-1 text-white bg-red-600'>
							Unfulfilled
						</Badge>
					) : isCompleted ? (
						<Badge size='xl' className='ml-1 text-white bg-green-600'>
							Completed
						</Badge>
					) : hasPermission(PermissionKeys.UPDATE_FOLLOW_UP_DATE) ? (
						<Button onClick={() => onCompleteFollowUp(followUp)} color='yellow' variant='secondary' size='xs' className='!rounded-full !font-normal'>
							Mark As Done
						</Button>
					) : (
						<Callout title='Unauthorized to update' icon={HiExclamation} color='yellow' />
					)}

					<DropdownMenu
						renderTriggerElement={() => {
							return (
								<Button variant='light' color='gray' className='p-1 bg-white border rounded-full'>
									<VerticalDotsIcons />
								</Button>
							);
						}}
						menuOptions={followUpActions}
					/>
				</div>
			</div>

			{hasRoles([DefaultRoles.MarketingManager, DefaultRoles.Admin]) && (
				<Text className='mt-2 text-sm text-gray-500'>
					<span className='font-semibold text-black'>Scheduled By: </span>
					{authUser?.id === user.id ? 'You' : `${user.first_name} ${user.middle_name ?? ''} ${user.last_name}`}
				</Text>
			)}
		</div>
	);
};

export default FollowUp;
