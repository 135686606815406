import AppLogo from './Appbar/AppLogo';

const AuthBranding = () => {
	return (
		<div className='flex flex-col items-center w-full gap-2 lg:items-start'>
			{/* <h1 className='text-xl font-bold sm:text-2xl text-gray-800 uppercase tracking-[0.2em] sm:tracking-[0.25em]'>LeadCX</h1> */}

			<AppLogo className='w-[200px] h-[70px] lg:-translate-x-[10px]' />
		</div>
	);
};

export default AuthBranding;
