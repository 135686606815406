import { TabGroup, TabList, Tab, TabPanels, TabPanel, List } from '@tremor/react';
import { AppointmentFulfillmentStatuses, AppointmentStatuses, IAppointment } from './appointmentInterfaces';
import AppointmentCard from './AppointmentCard';
import PendingAppointmentRequestListItem from './PendingAppointmentRequestCard';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import { IUser } from 'features/auth/authInterfaces';
import { hasPermission, hasRole, isAdmin } from 'helpers/index';
import { DefaultRoles } from 'features/settings/authorization/authorizationInterface';
import { Fragment } from 'react';
import { isAfter } from 'date-fns';
import { getAppointmentState } from 'utils/index';
import { PermissionKeys } from 'features/auth/permissions';
import ContentBlockedFromView from 'components/ContentBlockedFromView';

export type IGroupAppointmentsByUser = {
	user: IUser;
	appointments: IAppointment[];
};

interface Props {
	appointments: IAppointment[] | undefined;
	isAppointmentsLoading: boolean;
	refetchAppointments: () => void;
	onFulfillAppointment: (appointment: IAppointment) => void;
}

const UpcomingAppointmentsSection = ({ appointments, isAppointmentsLoading, refetchAppointments, onFulfillAppointment }: Props) => {
	const pendingAppointments = appointments?.filter((appointment) => getAppointmentState(appointment).isPending);
	const approvedAppointments = appointments?.filter((appointment) => getAppointmentState(appointment).isApproved);
	const fulfilledAppointments = appointments?.filter((appointment) => getAppointmentState(appointment).isFulfilled);
	const unfulfilledAppointments = appointments?.filter((appointment) => getAppointmentState(appointment).isUnfulFilled);

	// Pending Appointments
	const renderMarketerPendingAppointments = (appointments: IAppointment[]) =>
		appointments.map((appointment, index) => <AppointmentCard appointment={appointment} key={index} refetchAppointments={refetchAppointments} onFulfillAppointment={() => null} />);

	const groupAppointmentsByUser = (appointments: IAppointment[]): IGroupAppointmentsByUser[] => {
		const groupedAppointments: any = {};

		appointments.forEach((appointment) => {
			if (appointment.status === 'Pending') {
				const userId = appointment.user.id;

				if (!groupedAppointments[userId]) {
					groupedAppointments[userId] = {
						user: appointment.user,
						appointments: [],
					};
				}

				groupedAppointments[userId].appointments.push(appointment);
			}
		});

		const result = Object.values(groupedAppointments);

		return result as any;
	};
	const renderManagerPendingAppointmentCounts = (appointments: IAppointment[]) => {
		const renderAppointments: IGroupAppointmentsByUser[] = groupAppointmentsByUser(appointments);
		return (
			<List>
				{renderAppointments.map((userAppointments, index) => {
					return <PendingAppointmentRequestListItem userAppointments={userAppointments} key={index} />;
				})}
			</List>
		);
	};

	// Appointments
	const renderAppointments = (appointments: IAppointment[]) =>
		appointments.map((appointment, index) => (
			<AppointmentCard appointment={appointment} key={index} refetchAppointments={refetchAppointments} onFulfillAppointment={() => onFulfillAppointment(appointment)} showControls />
		));

	return (
		<TabGroup defaultIndex={0} className='sticky inset-x-0 top-0 w-full h-full pb-4 bg-white'>
			<div className='top-0 inset-x-0 sticky shadow p-2 h-[50px]'>
				{/* Marketers */}
				<TabList variant='solid' className='space-x-0 border'>
					<Tab className='px-2'>Pending</Tab>
					<Tab className='px-2'>Approved</Tab>
					<Tab className='px-2'>Fulfilled</Tab>
					<Tab className='px-2'>Unfulfilled</Tab>
				</TabList>
			</div>

			{/* Main Content */}
			{hasPermission(PermissionKeys.VIEW_APPOINTMENT) ? (
				<TabPanels className='p-2 overflow-y-auto h-[calc(100%-34px)] with-scrollbar'>
					{/* ===================
          Pending Approvals
        =================== */}
					<TabPanel className='space-y-3'>
						{!pendingAppointments && isAppointmentsLoading ? (
							<div className=''>
								<ContentLoadingCardSpinner message='Loading pending appointments...' />
							</div>
						) : pendingAppointments && pendingAppointments.length > 0 ? (
							<Fragment>
								{!isAdmin() && hasRole(DefaultRoles.Marketer) ? (
									<div className='space-y-2'>{renderMarketerPendingAppointments(pendingAppointments)}</div>
								) : (
									<div className='space-y-2'>{renderManagerPendingAppointmentCounts(pendingAppointments)}</div>
								)}
							</Fragment>
						) : (
							<SimpleEmptyResourceIndicator containerClassName='!mt-1' message='No pending appointment found' />
						)}
					</TabPanel>

					{/* ======================
          Approved Unfulfilled
        ====================== */}
					<TabPanel className='space-y-3'>
						{!approvedAppointments && isAppointmentsLoading ? (
							<div className=''>
								<ContentLoadingCardSpinner message='Loading approved appointments...' />
							</div>
						) : approvedAppointments && approvedAppointments.length > 0 ? (
							<div className='space-y-2'>{renderAppointments(approvedAppointments)}</div>
						) : (
							<SimpleEmptyResourceIndicator containerClassName='!mt-1' message='No approved appointment found' />
						)}
					</TabPanel>

					{/* ========================
          Fulfilled Appointments
        ======================== */}
					<TabPanel className='space-y-3'>
						{!fulfilledAppointments && isAppointmentsLoading ? (
							<div className=''>
								<ContentLoadingCardSpinner message='Loading fulfilled appointments...' />
							</div>
						) : fulfilledAppointments && fulfilledAppointments.length > 0 ? (
							<div className='space-y-2'>{renderAppointments(fulfilledAppointments)}</div>
						) : (
							<SimpleEmptyResourceIndicator containerClassName='!mt-1' message='No fulfilled appointment found' />
						)}
					</TabPanel>

					{/* ========================
          Unfulfilled Appointments
        ======================== */}
					<TabPanel className='space-y-3'>
						{!unfulfilledAppointments && isAppointmentsLoading ? (
							<div className=''>
								<ContentLoadingCardSpinner message='Loading unfulfilled appointments...' />
							</div>
						) : unfulfilledAppointments && unfulfilledAppointments.length > 0 ? (
							<div className='space-y-2'>{renderAppointments(unfulfilledAppointments)}</div>
						) : (
							<SimpleEmptyResourceIndicator containerClassName='!mt-1' message='No unfulfilled appointment found' />
						)}
					</TabPanel>
				</TabPanels>
			) : (
				<div className='p-4'>
					<ContentBlockedFromView message='You are not authorized to view appointment categories.' containerClassName='min-h-[350px]' />
				</div>
			)}
		</TabGroup>
	);
};

export default UpcomingAppointmentsSection;
