import { PermissionKeys } from 'features/auth/permissions';

export interface IRole {
	id: number;
	name: string;
	guard_name: GuardName;
	created_at: Date;
	updated_at: Date;
	permissions: IPermission[];
}

export interface IPermission {
	id: number;
	name: PermissionKeys;
	guard_name: GuardName;
	module: ISettingsModule;
	created_at: null;
	updated_at: null;
}

export enum ISettingsModule {
	Navigation = 'navigation',
	Others = 'others',
	Prospecting = 'prospecting',
	Settings = 'settings',
}

export enum DefaultRoles {
	SuperAdmin = 'Super Admin',
	Admin = 'Admin',
	MarketingManager = 'Marketing Manager',
	Marketer = 'Marketer',
	TransportManager = 'Transport Manager',
	TransportOfficer = 'Transport Officer',
}
export const defaultRoles = [DefaultRoles.SuperAdmin, DefaultRoles.Marketer, DefaultRoles.MarketingManager, DefaultRoles.TransportOfficer, DefaultRoles.TransportManager];

export enum GuardName {
	API = 'api',
}

export interface IRoleFormValues {
	name: string;
	permissions: string[];
}

export interface IFetchPermissionsParams {}
export interface IFetchRolesParams {}
