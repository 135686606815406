import { lsmsAPI } from 'ajax/httpClient';
import { IFetchVehiclesActionParams } from '../navigateInterfaces';
import { ICreateVehicleFormValues } from './vehicleInterfaces';

export const fetchVehicles = ({ filterBy, includes }: IFetchVehiclesActionParams) => {
	return lsmsAPI.get('/vehicles', {
		params: {
			include: includes?.join(','),
		},
	});
};

export const createVehicle = (formValues: ICreateVehicleFormValues) => lsmsAPI.post(`/vehicles`, formValues);

export const updateVehicle = (vehicleId: number, formValues: ICreateVehicleFormValues) => lsmsAPI.put(`/vehicles/${vehicleId}`, formValues);

export const deleteVehicle = (vehicleId: number) => lsmsAPI.delete(`/vehicles/${vehicleId}`);
