import { Card, Title, Button, TabGroup, TabList, Tab, TabPanels, TabPanel, Badge } from '@tremor/react';

import Container from 'components/Container';
import { Fragment, ReactNode, useState } from 'react';
import useFetchVehicles from '../vehicles/useFetchVehicles';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import Table from 'components/Table';
import { IVehicle } from '../navigateInterfaces';
import { DropdownMenu } from 'components/DropdownMenu';
import { DefaultTableDropDownTrigger } from 'components/TableDropdownTrigger';
import { createColumnHelper } from '@tanstack/react-table';
import { CarIcon, DeleteIcon, EditIcon } from 'components/FormActionIcons';
import { HiExclamation } from 'react-icons/hi';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { hasPermission, titleCase } from 'helpers/index';
import { MODAL_LEAVE_SPEED, TRANSPORT_OFFICER_ROLE_ID } from 'config';
import { useMutation } from '@tanstack/react-query';
import { deleteVehicle } from '../vehicles/vehicleQueries';
import toast from 'react-hot-toast';
import Modal from 'components/Modal';
import EditVehicleForm from '../vehicles/EditVehicleForm';
import { formatDate } from 'helpers/dateHelpers';
import useFetchUsers from 'features/settings/users/useFetchUsers';
import { IUser } from 'features/auth/authInterfaces';
import VehicleDriverStats from 'features/dashboard/VehicleDriverStats';
import useFetchVehicleDriverStats from 'features/dashboard/useFetchVehicleDriverStats';
import { TiCancelOutline } from 'react-icons/ti';
import { PermissionKeys } from 'features/auth/permissions';

interface IVehicleTableColumns extends IVehicle {
	action: ReactNode;
}
interface IUserTableColumns extends IUser {
	action: ReactNode;
}

const columnTransportOfficerHelper = createColumnHelper<IUserTableColumns>();
const columnVehicleTableHelper = createColumnHelper<IVehicleTableColumns>();

const VehiclesAndDriversPage = () => {
	const [currentVehicle, setCurrentVehicle] = useState<IVehicle | null>(null);
	const [currentTabIndex, setCurrentTabIndex] = useState(0);
	const [isEditVehicleFormOpen, setEditVehicleFormOpen] = useState<boolean>(false);
	const [isConfirmDeleteVehicleDialogOpen, setConfirmDeleteVehicleDialogOpen] = useState<boolean>(false);

	const { isVehicleDriverStatsLoading, vehicleDriverStats } = useFetchVehicleDriverStats({});
	const { vehicles, isVehiclesLoading, refetchVehicles } = useFetchVehicles({});
	const { users, isUsersLoading } = useFetchUsers({
		fetchParams: {
			filterBy: {
				roleId: TRANSPORT_OFFICER_ROLE_ID,
			},
		},
		enabled: hasPermission(PermissionKeys.VIEW_USER),
	});

	// =====================
	// * Delete Vehicle
	// =====================
	const { isLoading: isDeleteVehicleMutationLoading, mutate: deleteVehicleMutation } = useMutation({
		mutationKey: ['vehicles/delete-vehicle'],
		mutationFn: (vehicleId: number) => deleteVehicle(vehicleId),
	});

	const transportOfficerTableColumns = [
		columnTransportOfficerHelper.accessor((row) => `${row.first_name} ${row.middle_name || ' '} ${row.last_name}`, {
			cell: (info) => info.getValue(),
			header: 'Name',
			id: 'name',
		}),

		columnTransportOfficerHelper.accessor((row) => row, {
			header: 'Availability',
			cell: ({ row }) => {
				if (!vehicleDriverStats)
					return (
						<Badge size='xs' className='ml-1 text-black bg-gray-300'>
							Unknown
						</Badge>
					);

				const busyOfficers = vehicleDriverStats?.transport_officers?.active_officers;
				const isBusy = busyOfficers?.some((officer) => officer.transport_officer.id === row.original.id);

				return (
					<Badge size='xs' className={`text-white ${isBusy ? 'bg-red-600' : 'bg-green-600'}`}>
						{isBusy ? 'Busy' : 'Available'}
					</Badge>
				);
			},
		}),
	];

	const vehicleTableColumns = [
		columnVehicleTableHelper.accessor('action', {
			header: 'Actions',
			cell: (info) => {
				const vehicle: IVehicle = info.row.original;

				const menuOptions: DropdownMenuOption[] = [
					{
						type: 'button',
						text: 'Edit Vehicle',
						action: (e: any) => {
							setCurrentVehicle(vehicle);
							setEditVehicleFormOpen(true);
							e.stopPropagation();
						},
						icon: <EditIcon variant='solid' />,
					},
					{
						type: 'button',
						text: 'Delete Vehicle',
						action: (e: any) => {
							setCurrentVehicle(vehicle);
							setConfirmDeleteVehicleDialogOpen(true);
							e.stopPropagation();
						},
						icon: <DeleteIcon variant='solid' />,
					},
				];

				if (menuOptions.length === 0) {
					menuOptions.push({
						type: 'button',
						text: 'NOT AUTHORIZED',
						action: (e: any) => toast.error('You are not authorized perform any action on vehicles'),
						icon: <TiCancelOutline />,
					});
				}

				return <DropdownMenu renderTriggerElement={(state) => <DefaultTableDropDownTrigger state={state} />} menuOptions={menuOptions} />;
			},
		}),
		columnVehicleTableHelper.accessor('model', {
			header: 'Vehicle',
			cell: ({ row }) => <div className='sm:font-semibold'>{row.original.model}</div>,
		}),
		columnVehicleTableHelper.accessor((row) => row, {
			header: 'Availability',
			cell: ({ row }) => {
				if (!vehicleDriverStats)
					return (
						<Badge size='xs' className='ml-1 text-black bg-gray-300'>
							Unknown
						</Badge>
					);

				const busyVehicles = vehicleDriverStats?.vehicles?.active_vehicles;
				const isBusy = busyVehicles?.some((v) => v.vehicle.id === row.original.id);

				return (
					<Badge size='lg' className={`text-white ${isBusy ? 'bg-red-600' : 'bg-green-600'}`}>
						{isBusy ? 'Busy' : 'Available'}
					</Badge>
				);
			},
		}),
		columnVehicleTableHelper.accessor('number', {
			header: 'Registration Number',
		}),
		columnVehicleTableHelper.accessor('color', {
			header: 'Color',
			cell: ({ row }) => <div>{row.original.color ?? 'N/A'}</div>,
		}),
		columnVehicleTableHelper.accessor('created_at', {
			header: 'Date Created',
			cell: ({ row }) => formatDate(row.original.created_at, 'Jan 31, 1999'),
		}),
	];

	const onConfirmDeleteVehicle = () => {
		if (!currentVehicle) return toast.error('No vehicle selected for this action');

		deleteVehicleMutation(currentVehicle.id, {
			onSuccess: () => {
				toast.success('Vehicle deleted successfully.');
				setEditVehicleFormOpen(false);
			},
			onError: () => {
				toast.error('Failed to delete vehicle.');
			},
			onSettled() {
				setTimeout(() => setCurrentVehicle(null), MODAL_LEAVE_SPEED);
				refetchVehicles();
			},
		});

		setConfirmDeleteVehicleDialogOpen(false);
	};

	const renderDrivers = () => (
		<div>
			{!users && isUsersLoading ? (
				<div className='mt-6'>
					<ContentLoadingCardSpinner message='Loading transport officers...' />
				</div>
			) : users && users.length > 0 ? (
				<Card className='!p-0 mt-8 pt-6 overflow-hidden'>
					<Title className='px-4 py-4 border-b'>Transport Officers</Title>
					<Table data={users} columns={transportOfficerTableColumns} useInbuiltPagination />
				</Card>
			) : (
				<SimpleEmptyResourceIndicator containerClassName='lg:mt-8 lg:!py-10' message='No transport officer found' />
			)}
		</div>
	);

	return (
		<Fragment>
			<Container className='py-4'>
				<VehicleDriverStats isVehicleDriverStatsLoading={isVehicleDriverStatsLoading} vehicleDriverStats={vehicleDriverStats} />

				{hasPermission(PermissionKeys.VIEW_VEHICLE) ? (
					<TabGroup className='mt-8' defaultIndex={currentTabIndex} onIndexChange={setCurrentTabIndex}>
						<div className='flex items-center justify-between'>
							<TabList variant='solid'>
								<Tab className='!px-4'>Vehicles</Tab>
								<Tab className='!px-4'>Drivers</Tab>
							</TabList>

							{currentTabIndex === 0 && (
								<Button className='!rounded-full' icon={CarIcon} onClick={() => setEditVehicleFormOpen(true)}>
									Add Vehicle
								</Button>
							)}
						</div>

						<TabPanels>
							<TabPanel>
								<div>
									{!vehicles && isVehiclesLoading ? (
										<div className='mt-6'>
											<ContentLoadingCardSpinner message='Loading vehicles...' />
										</div>
									) : vehicles && vehicles.length > 0 ? (
										<Card className='!p-0 mt-8 pt-6 overflow-hidden'>
											<Title className='px-4 py-4 border-b'>Vehicles</Title>
											<Table data={vehicles} columns={vehicleTableColumns} useInbuiltPagination />
										</Card>
									) : (
										<SimpleEmptyResourceIndicator containerClassName='lg:mt-8 lg:!py-10' message='No vehicle found' />
									)}
								</div>
							</TabPanel>
							<TabPanel>{renderDrivers()}</TabPanel>
						</TabPanels>
					</TabGroup>
				) : (
					<Fragment>{renderDrivers()}</Fragment>
				)}
			</Container>

			<Modal
				width={500}
				closeButton={{ styles: 'bg-gray-200' }}
				isModalOpen={isEditVehicleFormOpen}
				setModalOpen={setEditVehicleFormOpen}
				onCloseModal={() => {
					setEditVehicleFormOpen(false);
					setTimeout(() => setCurrentVehicle(null), 500);
				}}
			>
				<EditVehicleForm
					vehicle={currentVehicle as IVehicle}
					afterEdit={() => {
						refetchVehicles();
						setEditVehicleFormOpen(false);
						setTimeout(() => setCurrentVehicle(null), 500);
					}}
					onClose={() => {
						setEditVehicleFormOpen(false);
						setTimeout(() => setCurrentVehicle(null), 500);
					}}
				/>
			</Modal>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmDeleteVehicleDialogOpen}
				setOpen={setConfirmDeleteVehicleDialogOpen}
				message='Delete Vehicle'
				subMessage={`Are you sure you want to delete ${titleCase(`${currentVehicle?.model}(${currentVehicle?.number})`) ?? 'this vehicle'}? This action cannot be reversed.`}
				onClose={() => {
					setTimeout(() => setCurrentVehicle(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmDeleteVehicle,
					label: 'Delete',
					isLoading: isDeleteVehicleMutationLoading,
					loadingText: 'Deleting',
					color: 'red',
				}}
				cancelButton={{
					action: () => setConfirmDeleteVehicleDialogOpen(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-500/10`}>
						<HiExclamation className='w-6 h-6 text-red-500' aria-hidden='true' />
					</div>
				)}
			/>
		</Fragment>
	);
};

export default VehiclesAndDriversPage;
