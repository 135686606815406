import React, { useEffect } from 'react';
import { Button, Text, Title } from '@tremor/react';
import CreateProspectFormActionButtons from './CreateProspectFormActionButtons';
import { useAppDispatch, useAppSelector } from 'hooks/storeConnect';
import { prospectsState, setCreateProspectFormValues } from '../prospectsSlice';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router';
import { createProspectStepperPages } from './CreateProspectStepper';
import withMap, { MapComponentProps } from 'components/withMap';
import PlacesCombobox from 'components/PlacesCombobox';
import { geoFindMe } from 'utils/index';
import { LocationIcon } from 'components/FormActionIcons';
import { hasPermission } from 'helpers/index';
import { PermissionKeys } from 'features/auth/permissions';

interface Props extends MapComponentProps {}

const CreateProspectCompanyLocationStep = React.forwardRef((props: Props, ref) => {
	const { mapRef, placesSearch, getPlaceInfoFromPrediction, setMapPosition, setPredictions } = props;
	const { predictions, setMapSearchText } = placesSearch || {};

	const dispatch = useAppDispatch();
	const { createProspectFormValues } = useAppSelector(prospectsState);

	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { coordinate, name } = createProspectFormValues?.locationStep || {};

	useEffect(() => {
		if (coordinate) {
			setMapPosition?.(coordinate);
		}
		//  eslint-disable-next-line
	}, [coordinate]);

	return (
		<div className='relative z-10'>
			<Title className='hidden px-4 text-primary-500 lg:block'>Company Location</Title>

			<div className='relative lg:bg-[#f5f6f7] lg:pt-4 lg:pb-24 lg:border-t mt-4 w-full lg:h-[calc(100vh-var(--appbar-height-desktop)-var(--create-prospect-page-height))]'>
				<section className='relative z-20 px-2 pb-4 lg:pb-4 lg:px-4'>
					<h3 className='text-sm font-medium text-black uppercase'>Add Company Location</h3>
					<Text className='mb-3 !text-gray-500'>Search or select location on map</Text>

					<div className='flex items-center gap-4'>
						<PlacesCombobox
							menu={
								predictions && predictions?.length > 0
									? predictions?.map((result, index) => ({
											id: index,
											name: result.description,
											value: result,
									  }))
									: []
							}
							placeholder='Search an address'
							onSearch={(value) => {
								if (!value) setMapSearchText?.('Loyalty Insurance Ghana');

								setMapSearchText?.(value);
							}}
							onPlaceChange={(place: google.maps.places.AutocompletePrediction) => {
								getPlaceInfoFromPrediction?.(place, (placeInfo: google.maps.places.PlaceResult) => {
									const { geometry, url, name } = placeInfo;
									const { lat, lng } = geometry?.location || {};

									dispatch(
										setCreateProspectFormValues({
											...createProspectFormValues,
											locationStep: {
												coordinate: { lat: lat?.(), lng: lng?.() },
												url,
												name,
											},
										} as any)
									);
								});
							}}
							selectedItemName={name ?? null}
						/>

						{/* <Button
							onClick={async () => {
								try {
									const { lat, lng } = await geoFindMe();

									setMapPosition?.({ lat, lng } as any);

									setPredictions?.(null);
								} catch (error: any) {
									toast.error(error.message ?? 'Unable to retrieve your location');
								}
							}}
							color='gray'
						>
							Show My Location
						</Button> */}
					</div>
				</section>

				<section className='relative z-10 h-[calc(100vh-var(--appbar-height-mobile)-300px)] sm:h-[calc(100vh-var(--appbar-height-mobile)-320px)] border-y lg:h-[calc(100vh-520px)] w-full bg-slate-400'>
					{/* Selected Location */}
					<div className='absolute z-40 flex items-center px-4 py-3 border rounded-lg shadow-lg top-2 backdrop-blur-md bg-black/10 border-black/5 inset-x-4'>
						<LocationIcon variant='solid' className='text-red-500 h-7 w-7' />
						<Text className='!font-bold !text-lg flex items-center capitalize line-clamp-1 text-ellipsis !text-gray-600'>{name}</Text>
					</div>

					{/* Map */}
					<div className='relative w-full h-full' ref={mapRef} />
				</section>

				<CreateProspectFormActionButtons
					nextStepButton={{
						isDisabled: !coordinate,
						onClick: () => {
							if (!coordinate) return toast.error('Please select a location');
							const currentPageIndex = createProspectStepperPages.findIndex((page) => page.url === pathname);
							navigate(createProspectStepperPages[currentPageIndex + 1].url);
						},
					}}
				/>
			</div>
		</div>
	);
});

export default withMap(CreateProspectCompanyLocationStep);
