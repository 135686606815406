import { Button, Icon, Metric } from '@tremor/react';
import { Outlet, useNavigate } from 'react-router-dom';
import CreateProspectStepper from './CreateProspectStepper';
import { useEffect, useState } from 'react';
import { IoArrowBackSharp } from 'react-icons/io5';
import { Tooltip } from 'react-tooltip';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { HiExclamation } from 'react-icons/hi';

const CreateProspectFormLayout = () => {
	const navigate = useNavigate();
	const [isLeavePromptOpen, setLeavePromptOpen] = useState(false);

	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			// setLeavePromptOpen(true);
			event.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	// Todo: Remove this feature of restricting the user from leaving the layout without saving
	// Todo: Find a way to get the leave notification feature working to prevent any sudden lost of form data

	return (
		<div className='relative h-full overflow-y-auto'>
			<div className='flex items-center gap-2 z-[60] relative px-2 sm:px-4 py-4'>
				<Button data-tooltip-id='leave-prospects-page' data-tooltip-content={'Back to Prospects List'} variant='light' onClick={() => navigate('/app/prospects')}>
					<Icon icon={IoArrowBackSharp} variant={'solid'} color='gray' className='!rounded-full' />
				</Button>
				<Tooltip id='leave-prospects-page' />

				<Metric className='!text-2xl mb-1'>Create New Prospect</Metric>
			</div>

			<div className='sticky inset-x-0 z-50 top-0 w-full px-2 py-4 pb-10 bg-white shadow lg:shadow-none sm:px-4'>
				<CreateProspectStepper />
			</div>

			<Outlet />

			<ConfirmationDialog
				isPortrait
				isOpen={isLeavePromptOpen}
				setOpen={setLeavePromptOpen}
				message='Unsaved Changes Detected'
				subMessage={`You will lose all prospect details when you leave. Are you sure you want to proceed?`}
				onClose={() => null}
				confirmButton={{
					action: () => navigate('/app/prospects/list'),
					label: 'Leave',
					color: 'yellow',
				}}
				cancelButton={{
					action: () => setLeavePromptOpen(false),
					label: 'Cancel',
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-500/10`}>
						<HiExclamation className='w-6 h-6 text-yellow-500' aria-hidden='true' />
					</div>
				)}
			/>
		</div>
	);
};

export default CreateProspectFormLayout;
