import { Button, TextInput, Title } from '@tremor/react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { processReactHookFormErrors } from 'helpers';
import { IProspectBusinessType, IProspectBusinessTypeFormValues } from '../prospectSettingsInterfaces';
import { createProspectBusinessType, updateProspectBusinessType } from '../prospectSettingsQueuries';
import InputWithAutocomplete from 'components/InputWithAutocomplete';

interface Props {
	businessTypes: IProspectBusinessType[] | undefined;
	businessType: IProspectBusinessType | null;
	refetchBusinessTypes: () => void;
	setCurrentBusinessType: (value: IProspectBusinessType | null) => void;
	setEditBusinessTypeFormOpen: (value: boolean) => void;
}

const EditProspectBusinessTypeForm = (props: Props) => {
	const { businessType, businessTypes, setCurrentBusinessType, setEditBusinessTypeFormOpen, refetchBusinessTypes } = props;

	const {
		register,
		handleSubmit,
		setError,
		setValue,
		clearErrors,
		formState: { errors },
	} = useForm<IProspectBusinessTypeFormValues>({
		defaultValues: {
			name: businessType?.name,
			description: businessType?.description || '',
		},
	});

	// ============================
	// * Create Business Type
	// ============================
	const { isLoading: isCreateBusinessTypeLoading, mutate: createBusinessTypeMutation } = useMutation({
		mutationKey: ['authorization/create-businessType'],
		mutationFn: (params: IProspectBusinessTypeFormValues) => createProspectBusinessType(params),
	});

	// ============================
	// * Update Business Type
	// ============================
	const { isLoading: isUpdateBusinessTypeLoading, mutate: updateBusinessTypeMutation } = useMutation({
		mutationKey: ['authorization/update-businessType'],
		mutationFn: ({ formValues, businessTypeId }: { formValues: IProspectBusinessTypeFormValues; businessTypeId: number }) => updateProspectBusinessType({ formValues, businessTypeId }),
	});

	const onSubmit = (formValues: IProspectBusinessTypeFormValues) => {
		if (businessTypes?.some((p) => p.name?.toLowerCase() === formValues.name?.toLowerCase())) return setError('name', { message: 'Business type already exist.' });

		if (businessType)
			return updateBusinessTypeMutation(
				{ formValues, businessTypeId: businessType.id },
				{
					onSuccess: () => {
						toast.success('Business type updated successfully!');
						setTimeout(() => setCurrentBusinessType(null), 500);
						setEditBusinessTypeFormOpen(false);
						refetchBusinessTypes();
					},
					onError: (error) => {
						processReactHookFormErrors(error, 'Failed to update business type', setError);
					},
				}
			);

		createBusinessTypeMutation(formValues, {
			onSuccess: () => {
				toast.success('Business type created successfully!');
				setEditBusinessTypeFormOpen(false);
				refetchBusinessTypes();
			},
			onError: (error) => {
				processReactHookFormErrors(error, 'Failed to create business type', setError);
			},
		});
	};

	return (
		<form className='bg-white' onSubmit={handleSubmit(onSubmit)}>
			<header className={`${businessType ? 'bg-gradient-to-r from-gray-800 to-gray-500' : 'bg-gradient-to-r from-primary-600 to-primary-300'} bg-dark p-6 h-[80px] flex items-center gap-2`}>
				<Title className='text-white'>{businessType ? 'Update' : 'Create'} Business Type</Title>
			</header>

			<section className='p-6 space-y-6 text-left'>
				<div className='form-control'>
					<label htmlFor='name' className='required-field-indicator'>
						Name
					</label>

					<div>
						<InputWithAutocomplete
							type='text'
							id='name'
							placeholder='Enter business type name'
							onValueChange={(val: string) => {
								if (val && val !== 'No matches found') clearErrors('name');

								setValue('name', val);
							}}
							menu={businessTypes ? (businessTypes?.map((p) => p.name) as string[]) : []}
							{...register('name', {
								required: {
									value: true,
									message: 'Name of business type is required',
								},
							})}
							hasError={errors.name ? true : false}
							errorMessage={errors.name?.message}
						/>
					</div>
				</div>

				<div className='form-control'>
					<label htmlFor='description'>Description</label>

					<div>
						<textarea id='description' {...register('description')} placeholder='Describe business type' />
						{errors?.description && <span className='mr-1 text-red-400 form-message'>{errors?.description?.message}</span>}
					</div>
				</div>
			</section>

			<footer className='flex items-center justify-end gap-2 p-6 bg-gray-100'>
				<Button
					onClick={() => {
						setEditBusinessTypeFormOpen(false);
						setTimeout(() => setCurrentBusinessType(null), 500);
					}}
					type='button'
					color='gray'
					variant='secondary'
					className='px-6 py-3 duration-300 !rounded-full'
				>
					Cancel
				</Button>

				<Button
					type='submit'
					loading={isCreateBusinessTypeLoading || isUpdateBusinessTypeLoading}
					loadingText={businessType ? 'Updating' : 'Creating'}
					disabled={isCreateBusinessTypeLoading || isUpdateBusinessTypeLoading}
					color={businessType ? 'gray' : undefined}
					className='px-6 py-3 duration-300 !rounded-full'
				>
					{businessType ? 'Update Business Type' : 'Create Business Type'}
				</Button>
			</footer>
		</form>
	);
};

export default EditProspectBusinessTypeForm;
