import { Loader } from '@googlemaps/js-api-loader';
import React, { useRef, useEffect, useState } from 'react';
import { loyaltyInsuranceHeadOfficeLocationInfo } from 'features/prospects/prospectsSlice';
import toast from 'react-hot-toast';

type GetPlaceInfoFromPrediction = (prediction: google.maps.places.AutocompletePrediction, handleResult: (placeInfo: google.maps.places.PlaceResult) => void) => void;

type GetPlaceInfoFromCoordinate = (coordinate: IMapCoordinate, handleResult: (placeInfo: google.maps.places.PlaceResult) => void) => void;

// Define the type for the props that the wrapped component will receive
export interface MapComponentProps {
	map?: google.maps.Map | null;
	mapRef?: React.RefObject<HTMLDivElement>;
	isMapLoaded?: boolean;
	setMapPosition?: (position: IMapCoordinate | null) => void;
	setMapPositions?: (positions: IMapCoordinate[]) => void;
	setPredictions?: (predictions: google.maps.places.AutocompletePrediction[] | null) => void;
	placesSearch?: {
		setMapSearchText?: (term: string) => void | null;
		predictions?: google.maps.places.AutocompletePrediction[];
	};
	getPlaceInfoFromPrediction?: GetPlaceInfoFromPrediction;
	getPlaceInfoFromCoordinate?: GetPlaceInfoFromCoordinate;
}

// This HOC injects map-related functionality into a component
const withMap = <P extends MapComponentProps>(WrappedComponent: React.ComponentType<P>) => {
	return function WithMap(props: P) {
		const mapRef = useRef<HTMLDivElement | null>(null);
		const [map, setMap] = useState<google.maps.Map | null>(null);
		const [position, setPosition] = useState<IMapCoordinate | null>(loyaltyInsuranceHeadOfficeLocationInfo.coordinate);
		const [positions, setPositions] = useState<IMapCoordinate[] | null>(null);
		const [isMapLoaded, setIsMapLoaded] = useState<boolean>(false);
		const [mapSearchText, setMapSearchText] = useState<string | null>(null);
		const [predictions, setPredictions] = useState<google.maps.places.AutocompletePrediction[] | null>(null);

		let loader: Loader;
		// let placeService: google.maps.places.PlacesService;
		// let infoWindow: google.maps.InfoWindow;
		let autoCompleteService: google.maps.places.AutocompleteService;

		useEffect(() => {
			try {
				initMap();
			} catch (e: any) {
				toast.error('Error initializing map');
			}

			// eslint-disable-next-line
		}, [position, positions]);

		// ============================
		// Get Predictions on search
		// ============================
		useEffect(() => {
			if (!mapSearchText) return;

			const displayPredictions = function (predictions: google.maps.places.AutocompletePrediction[], status: google.maps.places.PlacesServiceStatus) {
				if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
					return [];
				}

				setPredictions(predictions);
			};

			const getPredictions = () => {
				// eslint-disable-next-line
				if (!autoCompleteService) autoCompleteService = new google.maps.places.AutocompleteService(); // Todo: Move value of autoCompleteService to useRef hook
				autoCompleteService.getQueryPredictions({ input: mapSearchText }, displayPredictions as any);
			};

			try {
				getPredictions();
			} catch (e: any) {
				toast.error('Error getting predictions');
			}
		}, [mapSearchText]);

		// ============
		// Init Map
		// ============
		const initMap = async () => {
			if (!loader) {
				loader = new Loader({
					apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
					version: 'weekly',
					libraries: ['places'],
				});
			}

			try {
				const { Map } = await loader.importLibrary('maps');
				const { Marker } = await loader.importLibrary('marker');

				const mapOptions: google.maps.MapOptions = {
					center: position,
					fullscreenControl: false,
					zoomControl: false,
					mapTypeControl: false,
					streetViewControl: false,
					zoom: 17,
					mapId: 'lsms-map-id',
				};

				if (!mapRef.current) return;

				let mapInstance = new Map(mapRef.current as HTMLDivElement, mapOptions);
				// infoWindow = new google.maps.InfoWindow();
				// placeService = new google.maps.places.PlacesService(mapInstance);
				if (!autoCompleteService) autoCompleteService = new google.maps.places.AutocompleteService();

				if (position) {
					new Marker({
						position,
						map: mapInstance,
						clickable: false,
					});
					mapInstance.panTo(position);
					mapInstance.setCenter(position);
				}

				if (positions && positions.length > 0) {
					positions.forEach((position) => {
						new Marker({
							position,
							map: mapInstance,
							clickable: false,
						});
					});

					mapInstance.panTo(positions[positions.length - 1]);
					mapInstance.setZoom(15);
					mapInstance.setCenter(positions[positions.length - 1]);
				}

				setIsMapLoaded(true);
				setMap(mapInstance);
			} catch (error) {
				toast.error('Error rendering map. Check your internet connection and reload the page.', { id: 'map-error' });
			}
		};

		const getPlaceInfoFromPrediction: GetPlaceInfoFromPrediction = (prediction, handleResult) => {
			const service = new google.maps.places.PlacesService(document.createElement('input'));

			service.getDetails({ placeId: prediction.place_id, fields: ['name', 'geometry', 'url'] }, (place, status) => {
				if (status === google.maps.places.PlacesServiceStatus.OK && place && place.geometry) {
					const { lat, lng } = place.geometry.location?.toJSON() || {};
					setPosition({ lat: lat as number, lng: lng as number });

					handleResult(place);
				} else {
					toast.error('Failed to retrieve place details');
				}
			});
		};

		const getPlaceInfoFromCoordinate: GetPlaceInfoFromCoordinate = (coordinate, handleResult) => {
			const service = new google.maps.places.PlacesService(document.createElement('input'));
			const location = new google.maps.LatLng(coordinate.lat, coordinate.lng);
			const request = {
				location: location,
				radius: 10, // Set the radius in meters to find places nearby (adjust as needed)
				fields: ['name', 'place_id', 'geometry'], // Define the fields you want to retrieve
			};

			service.nearbySearch(request, (results, status) => {
				console.log(status);
				// if (status === google.maps.places.PlacesServiceStatus.OK && results) {
				// 	const place = results[0]; // Get the first place found
				// 	if (place && place.geometry) {
				// 		handleResult(place);
				// 	} else {
				// 		toast.error('Failed to retrieve place details');
				// 	}
				// } else {
				// 	toast.error('Failed to retrieve nearby places');
				// }
			});
		};

		const setMapPositions = (positions: IMapCoordinate[]) => {
			setPosition(null);
			setPositions(positions);
		};

		const setMapPosition = (position: IMapCoordinate) => {
			setPositions(null);
			setPosition(position);
		};

		// const createPlaceMarker = (place: google.maps.places.PlaceResult) => {
		// 	if (!place.geometry || !place.geometry.location) return;

		// 	const marker = new google.maps.Marker({
		// 		map,
		// 		position: place.geometry.location,
		// 	});

		// 	google.maps.event.addListener(marker, 'click', () => {
		// 		infoWindow.setContent(place.name || '');
		// 		infoWindow.open(map);
		// 	});
		// };

		return (
			<WrappedComponent
				map={map}
				isMapLoaded={isMapLoaded}
				mapRef={mapRef}
				setMapPosition={setMapPosition}
				setMapPositions={setMapPositions}
				predictions={predictions}
				setPredictions={setPredictions}
				getPlaceInfoFromPrediction={getPlaceInfoFromPrediction}
				getPlaceInfoFromCoordinate={getPlaceInfoFromCoordinate}
				placesSearch={{
					setMapSearchText,
					predictions,
				}}
				{...props}
			/>
		);
	};
};

export default withMap;
