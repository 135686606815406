import { IProspectsBySaleStage, IReport } from 'features/reports/reportInterfaces';
import { useAppSelector } from 'hooks/storeConnect';
import { authState } from 'features/auth/authSlice';
import StatisticsCardGroup from 'components/StatisticsCard/StatisticsCardGroup';
import StatisticsCardItem from 'components/StatisticsCard/StatisticsCardItem';
import { Text, Title, Card } from '@tremor/react';
import { RiLoader4Line } from 'react-icons/ri';

interface Props {
	isReportLoading: boolean;
	prospectsByBusinessType: IReport['prospects_by_busines_type'] | undefined;
}

const ProspectsByBusinessTypeMetrics = ({ isReportLoading, prospectsByBusinessType }: Props) => {
	const { authUser } = useAppSelector(authState);
	if (!authUser) return <div></div>;

	return (
		<div>
			<Title className='mb-2 !text-base'>Prospects By Business Types</Title>

			{isReportLoading ? (
				<Card className='flex flex-col items-center justify-center gap-4 p-10'>
					<RiLoader4Line className='w-8 h-8 text-lg text-gray-400 animate animate-spin ' />
					<Text className='!text-base'>Loading business types...</Text>
				</Card>
			) : prospectsByBusinessType && prospectsByBusinessType.length > 0 ? (
				<div className=''>
					<StatisticsCardGroup decorationColor='yellow'>
						{prospectsByBusinessType.map((businessType: IProspectsBySaleStage, index: number) => (
							<StatisticsCardItem key={index} title={businessType.name} metric={businessType?.count.toString()} />
						))}
					</StatisticsCardGroup>
				</div>
			) : (
				<Card className='flex items-center justify-center w-full h-full'>
					<Text className={'!text-base font-semibold'}>No business type found</Text>
				</Card>
			)}
		</div>
	);
};

export default ProspectsByBusinessTypeMetrics;
