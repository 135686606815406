import { Route, Routes } from 'react-router-dom';
import { setAuthToken } from 'ajax/httpClient';
import AuthLayout from 'features/auth/AuthLayout';
import LoginPage from 'features/auth/LoginPage';
import ForgotPasswordPage from 'features/auth/ForgotPasswordPage';
import ResetPasswordPage from 'features/auth/ResetPasswordPage';
import SettingsLayout from 'features/settings/SettingsLayout';
import UserAccountsManagementPage from 'features/settings/users/UserAccountsManagementPage';
import AppLayout from 'pages/AppLayout';
import ProspectingSettingsLayout from 'features/settings/prospecting/ProspectingSettingsLayout';
import PasswordSettingsPage from 'features/settings/PasswordSettingsPage';
import ProfileSettingsPage from 'features/settings/ProfileSettingsPage';
import PageNotFound from 'pages/PageNotFound';
import AuthorizationLayout from 'features/settings/authorization/AuthorizationLayout';
import RolesPage from 'features/settings/authorization/RolesPage';
import PermissionsPage from 'features/settings/authorization/PermissionsPage';
import Portal from 'components/Portal';
import ToastNotifications from 'components/ToastNotifications';
import SalesStagesPage from 'features/settings/prospecting/salesStages/SalesStagesPage';
import AdminSettingsLayout from 'features/settings/AdminSettingsLayout';
import InsuranceProductsPage from 'features/settings/prospecting/insuranceProducts/InsuranceProductsPage';
import DecisionMakersPage from 'features/settings/prospecting/decisionMakers/DecisionMakersPage';
import SourceTypesPage from 'features/settings/prospecting/sourceTypes/SourceTypesPage';
import InteractionTypesPage from 'features/settings/prospecting/interactionTypes/InteractionTypesPage';
import ProspectBusinessTypePage from 'features/settings/prospecting/businessType/ProspectBusinessTypePage';
import DepartmentsPage from 'features/settings/departments/DepartmentsPage';
import BranchesPage from 'features/settings/branches/BranchesPage';
import MarketingDashboardPage from 'features/dashboard/MarkertingDashboardPage';
import ProspectsLayout from 'features/prospects/ProspectsLayout';
import ProspectsListPage from 'features/prospects/ProspectsListPage';
import CreateProspectFormInsuranceProductsStep from 'features/prospects/create/CreateProspectFormInsuranceProductsStep';
import CreateProspectFormCompanyInfoStep from 'features/prospects/create/CreateProspectFormCompanyInfoStep';
import CreateProspectFormLayout from 'features/prospects/create/CreateProspectFormLayout';
import CreateProspectCompanyLocationStep from 'features/prospects/create/CreateProspectCompanyLocationStep';
import CreateProspectSalesStagesStep from 'features/prospects/create/CreateProspectSalesStagesStep';
import CreateProspectFormReviewStep from 'features/prospects/create/CreateProspectFormReviewStep';
import ProspectInformationPage from 'features/prospects/detail/ProspectInformationPage';
import ProspectAppointmentsPage from 'features/prospects/detail/ProspectAppointmentsPage';
import ProspectInteractionsPage from 'features/prospects/detail/ProspectInteractionsPage';
import ProspectDetailLayout from 'features/prospects/detail/ProspectDetailLayout';
import AppointmentsPage from 'features/appointments/AppointmentsPage';
import RootLayout from 'pages/RootLayout';
import NavigateLayout from 'features/navigate/NavigateLayout';
import VehicleRequestsPage from 'features/navigate/vehicle-requests/VehicleRequestsPage';
import StaffLocationsPage from 'features/navigate/StaffLocationsPage';
import VehiclesAndDriversPage from 'features/navigate/vehicle-requests/VehiclesAndDriversPage';
import TransportOfficerVehicleAllocationsPage from 'features/navigate/vehicle-allocations/TransportOfficerVehicleAllocationsPage';
import TransportDashboardPage from 'features/dashboard/TransportDashboardPage';
import DashboardLayout from 'features/dashboard/DashboardLayout';
import BlockedUsersPage from 'pages/BlockedUsersPage';
import FirstTimeUsersPasswordResetPage from 'pages/FirstTimeUsersPasswordResetPage';
import AccountIssuesLayout from 'pages/AccountIssuesLayout';
import DashboardReportsLayout from 'features/dashboard/DashboardReportsLayout';
import TargetPerStaffReportPage from 'features/reports/TargetPerStaffReportPage';
import EarningsPerProspectReportPage from 'features/reports/EarningsPerProspectReportPage';
import StaffClosingPercentageReportPage from 'features/reports/StaffClosingPercentageReportPage';
import ReportsLayout from 'features/reports/ReportsLayout';
import AnalyticsReportPage from 'features/reports/AnalyticsReportPage';
import ProspectsReportPage from 'features/reports/ProspectsReportPage';
import ProspectiveClientsReportPage from 'features/reports/ProspectiveClientsReportPage';
import WonProspectsReportPage from 'features/reports/WonProspectsReportPage';
import ExpectedRevenueReportPage from 'features/reports/ExpectedRevenueReportPage';
import AchievedRevenueReportPage from 'features/reports/AchievedRevenueReportPage';
import YearlyTargetsReportPage from 'features/reports/YearlyTargetsReportPage';
import AverageAchievedPremiumReportPage from 'features/reports/AverageAchievedPremiumReportPage';
import FollowUpsReportPage from 'features/reports/FollowUpsReportPage';
import ProspectsPerSalesStageReportPage from 'features/reports/ProspectsPerSalesStageReportPage';
import ProspectsPerBusinessTypeReportPage from 'features/reports/ProspectsPerBusinessTypeReportPage';
import NumberOfWonOpportunitiesReportPage from 'features/reports/NumberOfWonOpportunitiesReportPage';
import InteractionsReportPage from 'features/reports/InteractionsReportPage';

// Todo: Implement more filters for large screen sizes on any page that cannot show all filters on the available space

const App = () => {
	setAuthToken(localStorage.getItem('token'));

	return (
		<div id='app'>
			<Portal rootElementId='toast-portal-root'>
				<ToastNotifications />
			</Portal>

			<Routes>
				<Route path='/' element={<RootLayout />}>
					<Route path='auth' element={<AuthLayout />}>
						<Route path='login' element={<LoginPage />} />
						<Route path='forgot-password' element={<ForgotPasswordPage />} />
						<Route path='reset-password' element={<ResetPasswordPage />} />
					</Route>

					<Route path='/app/accounts-issues/redirect' element={<AccountIssuesLayout />}>
						<Route path='change-password' element={<FirstTimeUsersPasswordResetPage />} />
						<Route path='blocked-accounts' element={<BlockedUsersPage />} />
					</Route>

					<Route path='app' element={<AppLayout />}>
						<Route path='reports' element={<ReportsLayout />}>
							<Route path='analytics' element={<AnalyticsReportPage />} />
							<Route path='prospects' element={<ProspectsReportPage />} />
							<Route path='prospective-clients' element={<ProspectiveClientsReportPage />} />
							<Route path='won-prospects' element={<WonProspectsReportPage />} />
							{/* <Route path='number-of-won-prospects' element={<NumberOfWonProspectsReportPage />} /> */}
							<Route path='number-of-won-opportunities' element={<NumberOfWonOpportunitiesReportPage />} />
							<Route path='expected-revenue' element={<ExpectedRevenueReportPage />} />
							<Route path='achieved-revenue' element={<AchievedRevenueReportPage />} />
							<Route path='yearly-targets' element={<YearlyTargetsReportPage />} />
							<Route path='staff-closing-percentage' element={<StaffClosingPercentageReportPage />} />
							<Route path='average-achieved-premium' element={<AverageAchievedPremiumReportPage />} />
							<Route path='follow-ups' element={<FollowUpsReportPage />} />
							<Route path='interactions' element={<InteractionsReportPage />} />
							<Route path='sales-stages' element={<ProspectsPerSalesStageReportPage />} />
							<Route path='business-types' element={<ProspectsPerBusinessTypeReportPage />} />
							<Route path='earnings-per-account' element={<EarningsPerProspectReportPage />} />
						</Route>

						<Route path='dashboard' element={<DashboardLayout />}>
							<Route path='marketing' element={<MarketingDashboardPage />} />
							<Route path='transport' element={<TransportDashboardPage />} />
							<Route path='marketing/reports' element={<DashboardReportsLayout />}>
								<Route path='target-per-staff' element={<TargetPerStaffReportPage />} />
								<Route path='earnings-per-prospect' element={<EarningsPerProspectReportPage />} />
								<Route path='staff-closing-percentage' element={<StaffClosingPercentageReportPage />} />
							</Route>
						</Route>

						<Route path='prospects' element={<ProspectsLayout />}>
							<Route path='list' element={<ProspectsListPage />} />
							<Route path='appointments' element={<AppointmentsPage />} />
						</Route>

						<Route path='prospects/create' element={<CreateProspectFormLayout />}>
							<Route path='company-information' element={<CreateProspectFormCompanyInfoStep />} />
							<Route path='insurance-products' element={<CreateProspectFormInsuranceProductsStep />} />
							<Route path='company-location' element={<CreateProspectCompanyLocationStep />} />
							<Route path='sale-stage' element={<CreateProspectSalesStagesStep />} />
							<Route path='prospect-review' element={<CreateProspectFormReviewStep />} />
						</Route>

						<Route path='prospects/:id' element={<ProspectDetailLayout />}>
							<Route path='information' element={<ProspectInformationPage />} />
							<Route path='appointments' element={<ProspectAppointmentsPage />} />
							<Route path='interactions' element={<ProspectInteractionsPage />} />
						</Route>

						<Route path='navigate' element={<NavigateLayout />}>
							<Route path='vehicle-requests' element={<VehicleRequestsPage />} />
							<Route path='staff-locations' element={<StaffLocationsPage />} />
							<Route path='vehicle-and-drivers' element={<VehiclesAndDriversPage />} />
							<Route path='assigned-vehicle-requests' element={<TransportOfficerVehicleAllocationsPage />} />
						</Route>

						<Route path='settings' element={<SettingsLayout />}>
							<Route path='profile' element={<ProfileSettingsPage />} />
							<Route path='password' element={<PasswordSettingsPage />} />

							<Route path='admin' element={<AdminSettingsLayout />}>
								<Route path='authorization' element={<AuthorizationLayout />}>
									<Route path='roles' element={<RolesPage />} />
									<Route path='permissions' element={<PermissionsPage />} />
								</Route>

								<Route path='prospecting' element={<ProspectingSettingsLayout />}>
									<Route path='sales-stages' element={<SalesStagesPage />} />
									<Route path='insurance-products' element={<InsuranceProductsPage />} />
									<Route path='decision-makers' element={<DecisionMakersPage />} />
									<Route path='source-types' element={<SourceTypesPage />} />
									<Route path='interaction-types' element={<InteractionTypesPage />} />
									<Route path='prospect-business-types' element={<ProspectBusinessTypePage />} />
								</Route>

								<Route path='branches' element={<BranchesPage />} />
								<Route path='departments' element={<DepartmentsPage />} />
								<Route path='user-accounts' element={<UserAccountsManagementPage />} />
							</Route>
						</Route>

						<Route path='*' element={<PageNotFound />} />
					</Route>
				</Route>
			</Routes>
		</div>
	);
};

export default App;
