import { lsmsAPI } from 'ajax/httpClient';
import { AppointmentFulfillmentStatuses, AppointmentStatuses, IAppointmentCommentFormValues, ICreateAppointmentFormValues, IFetchAppointmentsActionParams } from './appointmentInterfaces';

export const fetchAppointments = ({ filterBy, include, page }: IFetchAppointmentsActionParams) => {
	const { createdAt, createdBetween, prospectId, status, staffId, appointmentDate } = filterBy || {};

	return lsmsAPI.get('/appointments', {
		params: {
			'filter[prospect_id]': prospectId,
			'filter[created_between]': createdBetween,
			'filter[appointment_date]': appointmentDate,
			'filter[created_at]': createdAt,
			'filter[status]': status,
			'filter[staff_id]': staffId,
			sort: '-created_at',
			include: include?.join(','),
			page: page ?? 1,
		},
	});
};

export const createAppointment = (formValues: ICreateAppointmentFormValues) => lsmsAPI.post('/appointments', formValues);

export const updateAppointment = ({ formValues, appointmentId }: { formValues: ICreateAppointmentFormValues; appointmentId: number }) => lsmsAPI.put(`/appointments/${appointmentId}`, formValues);

export const updateAppointmentStatus = ({ status, appointmentId, fulfillment_status }: { appointmentId: number; status?: AppointmentStatuses; fulfillment_status?: AppointmentFulfillmentStatuses }) =>
	lsmsAPI.put(
		`/appointments/${appointmentId}`,
		status
			? {
					status,
			  }
			: fulfillment_status
			? { fulfillment_status }
			: {}
	);
export const approveAppointment = (appointmentId: number) =>
	lsmsAPI.put(`/appointments/${appointmentId}`, {
		status: AppointmentStatuses.Approved,
	});
export const cancelAppointment = (appointmentId: number) =>
	lsmsAPI.put(`/appointments/${appointmentId}`, {
		status: AppointmentStatuses.Cancelled,
	});

export const deleteAppointment = (appointmentId: number) => lsmsAPI.delete(`/appointments/${appointmentId}`);

export const createAppointmentComment = (formValues: IAppointmentCommentFormValues) =>
	lsmsAPI.put(`/appointments/${formValues.appointmentId}`, {
		comment: formValues.comment,
	});
