import type { OverlayTriggerState } from 'react-stately';
import type { AriaPopoverProps } from '@react-aria/overlays';
import * as React from 'react';
import { usePopover, DismissButton, Overlay } from '@react-aria/overlays';

interface PopoverProps extends Omit<AriaPopoverProps, 'popoverRef'> {
	children: React.ReactNode;
	state: OverlayTriggerState;
}

export function Popover(props: PopoverProps) {
	let ref = React.useRef<HTMLDivElement>(null);
	let { state, children } = props;

	let { popoverProps, underlayProps } = usePopover(
		{
			...props,
			popoverRef: ref,
		},
		state
	);

	return (
		<Overlay>
			<div {...underlayProps} className='fixed inset-0' />
			<div {...popoverProps} ref={ref} className='!z-[var(--dropdown-z-index)] mt-2 rounded-md border border-gray-300 bg-white shadow-lg'>
				<DismissButton onDismiss={state.close} />
				{children}
				<DismissButton onDismiss={state.close} />
			</div>
		</Overlay>
	);
}
