import { useMutation} from '@tanstack/react-query';
import { Button, List, ListItem, Metric, Subtitle, Text, Title } from '@tremor/react';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { ConfirmationDialogIcon, DeleteIcon, EditIcon } from 'components/FormActionIcons';
import Modal from 'components/Modal';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import { MODAL_LEAVE_SPEED } from 'config';
import { IUser, IYearlyTarget } from 'features/auth/authInterfaces';
import { deleteUserTarget } from 'features/auth/authQueries';
import { processReactHookFormErrors, titleCase } from 'helpers/index';
import { useState, Fragment } from 'react';
import toast from 'react-hot-toast';
import EditUserTargetForm from './EditUserTargetForm';
import {AxiosResponse} from "axios";

interface Props {
	currentUser: IUser | null;
	setUserTargetsSliderOpen: (val: boolean) => void;
	setCurrentUser: (val: IUser | null) => void;
	refetchUsers: () => void;
}

const UserTargetsSection = (props: Props) => {
	const { currentUser, setCurrentUser, refetchUsers } = props;

	const [currentTarget, setCurrentTarget] = useState<IYearlyTarget | null>(null);
	const [isConfirmDeleteYearlyTargetDialogOpen, setConfirmDeleteYearlyTargetDialogOpen] = useState(false);
	const [isEditUserTargetFormOpen, setEditUserTargetFormOpen] = useState<boolean>(false);

	const { first_name, last_name, middle_name, yearly_targets, email } = currentUser || {};

	// ======================
	// * Delete Target
	// ======================
	const { isLoading: isDeleteTargetLoading, mutate: deleteTargetMutation } = useMutation({
		mutationKey: ['authorization/delete-user-target'],
		mutationFn: (targetId: number) => deleteUserTarget(targetId),
	});

	const onConfirmDeleteTarget = () => {
		if (!currentTarget || !currentUser) return toast.error('No target or user selected for this action');

		deleteTargetMutation(currentTarget.id, {
			onSuccess: () => {
				toast.success('User target deleted successfully.');
				setEditUserTargetFormOpen(false);
			},
			onError: (error) => {
				processReactHookFormErrors(error, 'Failed to delete user target.');
			},
			onSettled() {
				setTimeout(() => setCurrentTarget?.(null), MODAL_LEAVE_SPEED);
				refetchUsers();
			},
		});

		setConfirmDeleteYearlyTargetDialogOpen(false);
	};

	const renderActionButtons = (yearlyTarget: IYearlyTarget) => (
		<div className='flex items-center gap-2'>
			<Button
				onClick={() => {
					setCurrentTarget?.(yearlyTarget);
					setEditUserTargetFormOpen(true);
				}}
				type='button'
				icon={EditIcon}
				title='Edit Target'
				variant='secondary'
				color='yellow'
				className='flex items-center !rounded-full justify-center'
			>
				Edit Target
			</Button>

			<Button
				onClick={() => {
					setCurrentTarget?.(yearlyTarget);
					setConfirmDeleteYearlyTargetDialogOpen(true);
				}}
				type='button'
				title='Delete Target'
				icon={DeleteIcon}
				variant='secondary'
				color='red'
				className='flex items-center !rounded-full justify-center'
			>
				Delete Target
			</Button>
		</div>
	);

	return (
		<Fragment>
			<div className='p-4 w-full'>
				<div className='bg-gray-50 border flex items-start justify-between gap-4 rounded-xl py-4 px-4 w-full'>
					<div>
						<Title className='!text-base'>
							{first_name} {middle_name} {last_name}
						</Title>
						<Subtitle className='!text-sm'>{email}</Subtitle>
					</div>

					<Button onClick={() => setEditUserTargetFormOpen(true)} color='green' className='mt-2 !rounded-full'>
						Add Target
					</Button>
				</div>

				<div className='mt-4'>
					<Title className='uppercase !text-sm border-y py-2'>Annual Targets</Title>

					{yearly_targets && yearly_targets?.length > 0 ? (
						<List>
							{yearly_targets?.map((target, index) => (
								<ListItem key={index} className='flex-col items-start gap-2 !w-full'>
									<div className='flex items-center w-full justify-between'>
										<Text className='!text-lg text-black font-bold'>{new Date(target.created_at).getFullYear()}</Text>

										<Metric className='flex items-end'>
											<span className='text-sm font-normal inline-block mb-1'>GH₵</span>

											{target.yearly_target.toLocaleString('en-US', {
												style: 'decimal',
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											})}
										</Metric>
									</div>
									<div className='flex items-center justify-center'>{renderActionButtons(target)}</div>
								</ListItem>
							))}
						</List>
					) : (
						<SimpleEmptyResourceIndicator message='No target created for this user' containerClassName='' />
					)}
				</div>
			</div>

			<Modal
				width={500}
				closeButton={{ styles: 'bg-gray-200' }}
				isModalOpen={isEditUserTargetFormOpen}
				setModalOpen={setEditUserTargetFormOpen}
				closeEventListenerName='closeUserTargetForm'
				onCloseModal={() => {
					setEditUserTargetFormOpen(false);
					setTimeout(() => {
						setCurrentTarget(null);
					}, 500);
				}}
				shouldCloseOnOutsideClick={false}
			>
				<EditUserTargetForm
					currentUser={currentUser as IUser}
					setEditUserTargetFormOpen={setEditUserTargetFormOpen}
					setCurrentTarget={setCurrentTarget}
					currentTarget={currentTarget}
					afterEdit={async () => {
						setTimeout(() => setCurrentTarget(null), 500);
						setEditUserTargetFormOpen(false);
						const usersResponse: any = await refetchUsers();

						const axiosResponse: AxiosResponse = usersResponse?.data as any;
						const users: IUser[] | undefined = axiosResponse?.data?.data?.data;
						if (!users) return;

						const thisUser = users.find((user) => user.id === currentUser?.id);
						setCurrentUser(thisUser as any);
					}}
				/>
			</Modal>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmDeleteYearlyTargetDialogOpen}
				setOpen={setConfirmDeleteYearlyTargetDialogOpen}
				message='Delete User'
				subMessage={`Are you sure you want to delete ${titleCase(currentUser?.first_name as string) ?? 'this  user'}? This action cannot be reversed.`}
				onClose={() => {
					setTimeout(() => setCurrentTarget(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmDeleteTarget,
					label: 'Delete',
					isLoading: isDeleteTargetLoading,
					loadingText: 'Deleting',
					color: 'red',
				}}
				cancelButton={{
					action: () => setConfirmDeleteYearlyTargetDialogOpen(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-500/10`}>
						<ConfirmationDialogIcon className='w-6 h-6 text-red-500' aria-hidden='true' />
					</div>
				)}
			/>
		</Fragment>
	);
};

export default UserTargetsSection;
