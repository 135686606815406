import _ from 'lodash';
import Table from 'components/Table';
import toast from 'react-hot-toast';
import { Button, Text, Title } from '@tremor/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RiFilter2Fill } from 'react-icons/ri';
import { Fragment, ReactNode, useEffect, useState } from 'react';
import { IWonProspect, IFetchReportActionParams } from './reportInterfaces';
import { createColumnHelper } from '@tanstack/react-table';
import { convertJSONToExcel, formatCurrency, hasRole, hasRoles, isAdmin } from 'helpers/index';
import { formatDateRangePickerValue } from 'helpers/dateHelpers';
import { IDashboardFilters } from 'features/dashboard/dashboardInterfaces';
import { buildURLFilters, isMarketer } from 'utils/index';
import { authState } from 'features/auth/authSlice';
import { useAppSelector } from 'hooks/storeConnect';
import { FilterComponentTypes } from 'features/settings/settingsInterfaces';
import { DefaultRoles } from 'features/settings/authorization/authorizationInterface';
import Filters from 'components/Filters';
import useFetchUsers from 'features/settings/users/useFetchUsers';
import useFetchReports from './useFetchReports';
import BottomSheet from 'components/BottomSheet';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import { PermissionKeys } from 'features/auth/permissions';

interface IWonProspectsTableColumns extends IWonProspect {
	action: ReactNode;
}

const columnHelper = createColumnHelper<IWonProspectsTableColumns>();

const WonProspectsReportPage = () => {
	const navigate = useNavigate();
	const [isMobileFiltersOpen, setMobileFiltersOpen] = useState<boolean>(false);
	const { users } = useFetchUsers({});
	const [urlParams, setUrlParams] = useSearchParams();
	const { authUser } = useAppSelector(authState);
	const [fetchParams, setFetchParams] = useState<IFetchReportActionParams>({
		filterBy: {
			userId: isMarketer() ? authUser?.id : undefined,
		},
	});

	// Fetch reports
	const { reports, isReportsLoading } = useFetchReports({
		fetchParams,
		queryKey: ['reports/won-prospects', fetchParams],
	});

	const wonProspects: IWonProspect[] | undefined = reports?.won_prospects;

	// Manage url filters
	const dateRange = urlParams.get('dateRange');
	const userId = !isAdmin() && hasRole(DefaultRoles.Marketer) ? authUser?.id : urlParams.get('userId') ?? '';

	useEffect(() => {
		const urlFilters: IDashboardFilters = {
			createdBetween: dateRange || undefined,
			userId: Number(userId) || undefined,
		};

		setFetchParams({
			...fetchParams,
			filterBy: _.pickBy(urlFilters, _.identity),
		});

		// eslint-disable-next-line
	}, [dateRange, userId]);

	const onSubmitFilters = (filters: IDashboardFilters) => {
		const urlFilters = {
			...fetchParams.filterBy,
			userId: filters.userId,
			dateRange: filters.createdBetween ? formatDateRangePickerValue(filters?.createdBetween as any) : undefined,
		};

		delete urlFilters.createdBetween;

		navigate(`/app/reports/won-prospects?${buildURLFilters(_.pickBy(urlFilters, _.identity) as any)}`);
	};

	const exportReportToExcel = (percentages: IWonProspect[] | undefined) => {
		if (!percentages || percentages?.length === 0) return toast.error('No data to export. Please make sure your report has at least one record.');

		const sheetStructure = percentages.map((targetItem) => {
			const { achieved_premium, appointment_letter_received, business_type, prospect } = targetItem;

			return {
				Prospect: `${prospect}`,
				'Achieved Premium': formatCurrency(achieved_premium ?? 0),
				'Business Type': business_type,
				'Appointment Letter Received': appointment_letter_received ? 'YES' : 'NO',
			};
		});

		convertJSONToExcel(sheetStructure, `Staff Closing Percentages`);
	};

	const tableColumns = [
		columnHelper.accessor('prospect', {
			cell: ({ row }) => <span className='font-semibold'>{row.original.prospect}</span>,
			header: 'Prospect',
		}),
		columnHelper.accessor('achieved_premium', {
			cell: ({ row }) => <span className={`text-blue-600 font-semibold`}>{formatCurrency(row.original.achieved_premium ?? 0)}</span>,
			header: 'Achieved Premium',
		}),
		columnHelper.accessor('business_type', {
			cell: ({ row }) => <span className={``}>{row.original.business_type}</span>,
			header: 'Business Type',
		}),
		columnHelper.accessor('appointment_letter_received', {
			cell: ({ row }) => <span className={`uppercase font-semibold`}>{row.original.appointment_letter_received ? 'Yes' : 'No'}</span>,
			header: 'Appointment Letter Received',
		}),
	];

	return (
		<div className='flex flex-col w-full overflow-hidden h-[calc(100vh-var(--appbar-height-mobile))] lg:h-[calc(100vh-var(--appbar-height-desktop))]'>
			<div className='h-[160px] px-4 py-4 bg-gray-100 border w-full flex items-start justify-between'>
				<div>
					<Title className='!font-bold text-gray-800'>Won Prospects</Title>
					{/* // Todo: Add description for this page */}
					{/* <Text>Staff prospect closing percentage.</Text> */}
				</div>

				<div className='inline-flex rounded-md shadow-sm ' role='group'>
					<button
						onClick={() => exportReportToExcel(wonProspects)}
						type='button'
						className='px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-sky-700 focus:z-10 focus:ring-2 focus:ring-sky-700 focus:text-sky-700'
					>
						Export To Excel
					</button>

					{/* <button
						type='button'
						className='px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-sky-700 focus:z-10 focus:ring-2 focus:ring-sky-700 focus:text-sky-700'
					>
						Download PDF
					</button> */}
				</div>
			</div>

			<div className='relative w-full h-[calc(100%-160px)] bg-white'>
				<div className='absolute -top-14 bottom-0 inset-x-0 mx-4 pb-4 h-[calc(100%+60px)] bg-white shadow-sm border rounded-t-xl overflow-hidden'>
					<div className='h-[55px] sticky inset-x-0 top-0 z-20 flex items-center justify-between px-4 py-2 bg-white border-b'>
						<p className='text-sm font-semibold'>{wonProspects && wonProspects?.length > 0 ? `${wonProspects.length} prospect(s) found` : '0 prospect(s)'}</p>

						<div className='hidden lg:block'>
							<Filters
								filters={[
									{
										type: FilterComponentTypes.DateRange,
										name: 'createdBetween',
										label: 'Dates',
										hideLabel: true,
										placeholder: 'Select date range',
										defaultValue: dateRange ? { from: new Date(dateRange.split(',')[0]), to: new Date(dateRange.split(',')[1]) } : undefined,
									},
									{
										type: FilterComponentTypes.Select,
										name: 'userId',
										label: 'Staff',
										hideLabel: true,
										defaultValue: userId?.toString(),
										dropdownOptions:
											users
												?.filter((user) => {
													// return user.roles.includes(DefaultRoles.Marketer) || user.roles.includes(DefaultRoles.MarketingManager)
													return !user.roles.includes(DefaultRoles.SuperAdmin);
												})
												.map((user: any) => ({
													id: user.id,
													label: `${user.first_name} ${user?.middle_name ?? ' '} ${user.last_name}`,
													value: user.id,
												})) ?? [],
										permission: PermissionKeys.VIEW_USER_FILTERS,
										placeholder: 'Select staff',
									},
								]}
								submitButton={{
									className: `!bg-gray-800 hover:!bg-gray-600 text-white !capitalize border-0 duration-300`,
									label: 'Apply Filters',
									onSubmit: onSubmitFilters,
								}}
							/>
						</div>

						<div className='lg:hidden'>
							<Button
								color='gray'
								variant='secondary'
								// onClick={() => setMobileFiltersOpen(true)}
								icon={RiFilter2Fill}
								className='!rounded-full hover:bg-gray-700 hover:!text-gray-100 duration-300 lg:hidden'
							>
								Filters
							</Button>
						</div>
					</div>

					<div className='h-[calc(100%-40px)] overflow-x-hidden overflow-y-auto w-full'>
						<Fragment>
							{!wonProspects && isReportsLoading ? (
								<div className='mt-6'>
									<ContentLoadingCardSpinner containerClassName='!shadow-none !border-0 !ring-0' message='Loading prospect data...' />
								</div>
							) : wonProspects && wonProspects.length > 0 ? (
								<div className='w-full h-full overflow-x-hidden overflow-y-auto'>
									<Table
										data={wonProspects}
										columns={tableColumns}
										// pagination={{
										// 	handlePageChange: (page: number) => {
										// 		setFetchParams((prevValue) => ({
										// 			...prevValue,
										// 			page,
										// 		}));
										// 	},
										// 	metadata: paginationMetadata,
										// }}
									/>
								</div>
							) : (
								<SimpleEmptyResourceIndicator containerClassName='lg:mt-8 lg:!py-10 !shadow-none !border-0 !ring-0' message='No prospect data found' />
							)}
						</Fragment>
					</div>
				</div>
			</div>

			<BottomSheet
				isOpen={isMobileFiltersOpen}
				setOpen={setMobileFiltersOpen}
				closeOnOutSideClick
				header={{
					text: 'Filter Won Prospects',
				}}
			>
				<Filters
					filters={[
						{
							type: FilterComponentTypes.DateRange,
							name: 'createdBetween',
							label: 'Dates',
							hideLabel: true,
							placeholder: 'Select date range',
							defaultValue: dateRange ? { from: new Date(dateRange.split(',')[0]), to: new Date(dateRange.split(',')[1]) } : undefined,
						},
						{
							type: FilterComponentTypes.Select,
							name: 'userId',
							label: 'Staff',
							hideLabel: true,
							defaultValue: userId?.toString(),
							dropdownOptions:
								users
									?.filter((user) => {
										// return user.roles.includes(DefaultRoles.Marketer) || user.roles.includes(DefaultRoles.MarketingManager)
										return !user.roles.includes(DefaultRoles.SuperAdmin);
									})
									.map((user: any) => ({
										id: user.id,
										label: `${user.first_name} ${user?.middle_name ?? ' '} ${user.last_name}`,
										value: user.id,
									})) ?? [],
							permission: PermissionKeys.VIEW_USER_FILTERS,
							placeholder: 'Select staff',
						},
					]}
					submitButton={{
						className: `!bg-gray-800 hover:!bg-gray-600 text-white !capitalize border-0 duration-300`,
						label: 'Apply Filters',
						onSubmit: onSubmitFilters,
					}}
				/>
			</BottomSheet>
		</div>
	);
};

export default WonProspectsReportPage;
