import { Title } from '@tremor/react';
import { Fragment } from 'react';
import { BiSolidFile } from 'react-icons/bi';
import { BsFillBuildingsFill } from 'react-icons/bs';
import { GiLevelEndFlag, GiUmbrella, GiCheckMark } from 'react-icons/gi';
import { IoLocation } from 'react-icons/io5';
import { NavLink, useLocation } from 'react-router-dom';

export const createProspectStepperPages = [
	{
		id: 'company-info',
		label: 'Company Information',
		icon: <BsFillBuildingsFill className='w-full h-full' />,
		url: '/app/prospects/create/company-information',
	},
	{
		id: 'insurance-info',
		label: 'Insurance Information',
		icon: <GiUmbrella className='w-full h-full' />,
		url: '/app/prospects/create/insurance-products',
	},
	{
		id: 'company-info',
		label: 'Company Location',
		icon: <IoLocation className='w-full h-full' />,
		url: '/app/prospects/create/company-location',
	},
	{
		id: 'sale-stage',
		label: 'Sale Stage',
		icon: <GiLevelEndFlag className='w-full h-full' />,
		url: '/app/prospects/create/sale-stage',
	},
	{
		id: 'data-review',
		label: 'Prospect Data Review',
		icon: <BiSolidFile className='w-full h-full' />,
		url: '/app/prospects/create/prospect-review',
	},
];

const CreateProspectStepper = () => {
	const location = useLocation();

	return (
		<div className='relative flex items-center justify-between before:absolute before:w-full before:h-px before:bg-gray-200 before:lg:mb-[1.5%] before:lg:w-[calc(100%-140px)]'>
			{createProspectStepperPages.map((page, index) => {
				const { url, icon, label } = page;

				const isPageMatch = location.pathname === url;
				const activePageIndex = createProspectStepperPages.findIndex((page) => page.url === location.pathname);
				const isBeforeCurrentPage = index < activePageIndex;

				return (
					<Fragment key={index}>
						<div className='lg:flex lg:flex-col lg:items-start'>
							<NavLink
								to={url as string}
								className={({ isActive }) =>
									`relative z-20 w-12 h-12 sm:w-14 sm:h-14 justify-center text-xl font-medium rounded-full flex items-center ${
										isPageMatch || isBeforeCurrentPage ? 'bg-gradient-to-r from-primary-600 to-primary-300 text-white border border-primary-300' : 'bg-gray-100 border text-gray-600'
									}`
								}
								onClick={(e) => e.preventDefault()}
							>
								{isBeforeCurrentPage ? (
									<span>
										<GiCheckMark className='w-6 h-6' />
									</span>
								) : (
									<Fragment>
										{/* Number */}
										<span className='sm:hidden'>{index + 1}</span>
										<span className='hidden w-6 h-6 sm:inline-block'>{icon}</span>
									</Fragment>
								)}
							</NavLink>

							<div className={`hidden lg:block text-sm mt-1 capitalize font-medium ${isPageMatch ? ' text-primary-500' : 'text-gray-600'}`}>
								{index + 1}. {label}
							</div>
						</div>

						{isPageMatch && <Title className='absolute -bottom-[1.85rem] !text-base lg:hidden text-gray-600'>{label}</Title>}
					</Fragment>
				);
			})}
		</div>
	);
};

export default CreateProspectStepper;
