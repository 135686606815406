// ==============
// User Targets
// ==============

import { lsmsAPI } from 'ajax/httpClient';
import { IFetchVehicleRequestsActionParams, IVehicleRequestCommentFormValues, IVehicleRequestFormValues, VehicleRequestStatuses } from './vehicleRequestsInterfaces';

export const fetchVehicleRequests = ({ filterBy, includes, page }: IFetchVehicleRequestsActionParams) => {
	const { createdAt, departureDate, staffId, requestStatus } = filterBy || {};

	return lsmsAPI.get('/vehicle-requests', {
		params: {
			'filter[departure_date]': departureDate,
			'filter[created_at]': createdAt,
			'filter[request_status]': requestStatus,
			'filter[user.id]': staffId,
			include: includes?.join(','),
			page: page ?? 1,
		},
	});
};

export const updateVehicleRequestStatus = (formValues: { vehicleRequestId: number; requestStatus: VehicleRequestStatuses }) =>
	lsmsAPI.put(`/vehicle-requests/${formValues.vehicleRequestId}`, {
		request_status: formValues.requestStatus,
	});

export const createVehicleRequest = (formValues: IVehicleRequestFormValues) => lsmsAPI.post('/vehicle-requests', formValues);

export const createVehicleRequestComment = (formValues: IVehicleRequestCommentFormValues) => lsmsAPI.post('/vehicle-request-comments', formValues);

export const updateVehicleRequest = ({ formValues, vehicleRequestId }: { formValues: IVehicleRequestFormValues; vehicleRequestId: number }) =>
	lsmsAPI.put(`/vehicle-requests/${vehicleRequestId}`, formValues);

export const deleteVehicleRequest = (vehicleRequestId: number) => lsmsAPI.delete(`/vehicle-requests/${vehicleRequestId}`);
