import React, { useState } from 'react';
import { IProspect } from 'features/prospects/prospectInterfaces';
import { ICreateFollowUpFormValues, IFollowUp } from './followUpInterfaces';
import { Button, DatePicker, Select, SelectItem, Title } from '@tremor/react';
import { PiBuildingsFill } from 'react-icons/pi';
import useFetchProspects from 'features/prospects/useFetchProspects';
import { useForm } from 'react-hook-form';
import SpaceFillerCard from 'components/SpaceFillerCard';
import { formatDate } from 'helpers/dateHelpers';
import { createFollowUp, updateFollowUp } from './followUpQueries';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { processReactHookFormErrors } from 'helpers/index';

interface Props {
	prospect: IProspect | null;
	followUp?: IFollowUp | null;
	afterEdit: () => void;
}

const ScheduleFollowUpForm = (props: Props) => {
	const { prospect, followUp, afterEdit } = props || {};

	const [selectFields, setSelectFields] = useState<ICreateFollowUpFormValues>({
		prospect_id: prospect ? prospect.id : followUp ? followUp?.prospect_id : undefined,
		follow_up_date: followUp?.follow_up_date,
	} as any);

	const { prospects } = useFetchProspects({
		fetchParams: {
			per_page: 100_000_000_000_000,
		},
		queryKey: 'prospects-dropdown',
	});

	const {
		handleSubmit,
		setError,
		setValue,
		clearErrors,
		formState: { errors },
	} = useForm<ICreateFollowUpFormValues>({
		defaultValues: {
			prospect_id: prospect ? prospect.id : followUp ? followUp?.prospect_id : undefined,
			follow_up_date: followUp?.follow_up_date as any,
		},
	});

	// ========================
	// * Create Follow Up
	// ========================
	const { isLoading: isCreateFollowUpLoading, mutate: createFollowUpMutation } = useMutation({
		mutationKey: ['followUps/create-follow-up'],
		mutationFn: (params: ICreateFollowUpFormValues) => createFollowUp(params),
	});

	// =========================
	// * Update FollowUp
	// =========================
	const { isLoading: isUpdateFollowUpLoading, mutate: updateFollowUpMutation } = useMutation({
		mutationKey: ['followUps/update-follow-up'],
		mutationFn: ({ formValues, followUpId }: { formValues: ICreateFollowUpFormValues; followUpId: number }) => updateFollowUp({ formValues, followUpId }),
	});

	const onSubmit = (formValues: ICreateFollowUpFormValues) => {
		const { follow_up_date, prospect_id } = formValues;
		if (follow_up_date === null || follow_up_date === undefined) {
			return setError('follow_up_date', { message: 'Reminder date is required' });
		} else if (prospect_id === null || prospect_id === undefined) {
			return setError('prospect_id', { message: 'Prospect is required' });
		}

		if (followUp)
			return updateFollowUpMutation(
				{ formValues, followUpId: followUp.id },
				{
					onSuccess: () => {
						toast.success('Rescheduled successfully!');
						afterEdit?.();
					},
					onError: (error) => {
						processReactHookFormErrors(error, 'Failed to schedule reminder', setError);
					},
				}
			);

		createFollowUpMutation(formValues, {
			onSuccess: () => {
				toast.success('Reminder created successfully!');
				afterEdit?.();
			},
			onError: (error) => {
				processReactHookFormErrors(error, 'Failed to update reminder', setError);
			},
		});
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<header className={`${followUp ? 'bg-gradient-to-r from-gray-800 to-gray-500' : 'bg-gradient-to-r from-primary-600 to-primary-300'} bg-dark p-6 h-[80px] flex items-center gap-2`}>
				<Title className='text-white'>{followUp ? 'Reschedule' : 'Schedule'} Reminder</Title>
			</header>

			<section className='p-4 space-y-2 text-left'>
				<div className='w-full form-control'>
					<label htmlFor='prospect_id' className='required-field-indicator'>
						Prospect
					</label>

					<div>
						<Select
							id='prospect_id'
							icon={PiBuildingsFill}
							disabled={true}
							value={selectFields?.prospect_id?.toString()}
							onValueChange={(val) => {
								setValue('prospect_id', Number(val));
								setSelectFields({
									...selectFields,
									prospect_id: Number(val),
								} as any);
								if (errors.prospect_id) clearErrors('prospect_id');
							}}
						>
							{prospects && prospects.length > 0 ? (
								prospects?.map((prospect, index) => (
									<SelectItem key={index} value={prospect.id.toString()}>
										{prospect.company_name}
									</SelectItem>
								))
							) : (
								<div className='px-2 py-2'>No prospect</div>
							)}
						</Select>

						{errors?.prospect_id && <span className='form-message'>{errors?.prospect_id?.message}</span>}
					</div>
				</div>

				<div className='w-full form-control'>
					<label htmlFor='follow_up_date' className='required-field-indicator'>
						Reminder Date
					</label>

					<div>
						<DatePicker
							id='follow_up_date'
							enableClear
							className={`!max-w-full`}
							enableYearNavigation
							minDate={new Date()}
							placeholder={'Select date...'}
							value={selectFields?.follow_up_date ? new Date(selectFields?.follow_up_date as any) : undefined}
							onValueChange={(val) => {
								setValue('follow_up_date', formatDate(val, '1999-12-31') as any);
								setSelectFields({
									...selectFields,
									follow_up_date: formatDate(val, '1999-12-31'),
								} as any);
								if (errors.follow_up_date) clearErrors('follow_up_date');
							}}
						/>
						{errors?.follow_up_date && <span className='form-message'>{errors?.follow_up_date?.message}</span>}
					</div>
				</div>

				<SpaceFillerCard className='lg:block' />
			</section>

			<footer className='flex items-center justify-end gap-2 p-6 bg-gray-100 border-t'>
				<Button
					onClick={() => {
						afterEdit?.();
					}}
					type='button'
					color='gray'
					variant='secondary'
					className='px-6 py-3 duration-300 !rounded-full'
				>
					Cancel
				</Button>

				<Button
					type='submit'
					loading={isCreateFollowUpLoading || isUpdateFollowUpLoading}
					loadingText={followUp ? 'Updating' : 'Scheduling'}
					disabled={isCreateFollowUpLoading || isUpdateFollowUpLoading}
					color={followUp ? 'gray' : undefined}
					className='px-6 py-3 duration-300 !rounded-full'
				>
					{followUp ? 'Update' : 'Schedule'}
				</Button>
			</footer>
		</form>
	);
};

export default ScheduleFollowUpForm;
