import { useMemo } from 'react';
import useFetchUsers from 'features/settings/users/useFetchUsers';
import { FilterComponentTypes, GeneralFilterNames, IFilter, INamedFilter } from 'features/settings/settingsInterfaces';
import { hasPermission } from 'helpers/index';
import { PermissionKeys } from 'features/auth/permissions';

type ReturnType = {
	defaultNamedFilters: INamedFilter[];
	defaultFilters: IFilter[];
};

const useDefaultFilters = (): ReturnType => {
	const { users } = useFetchUsers({
		enabled: hasPermission(PermissionKeys.VIEW_USER),
	});

	const namedFilters: INamedFilter[] = useMemo(() => {
		return [
			{
				name: GeneralFilterNames.CreatedAt,
				filter: {
					type: FilterComponentTypes.Date,
					name: 'createdAt',
					label: 'Date Created',
					placeholder: 'Search date created',
				},
			},
			{
				name: GeneralFilterNames.CreatedBetween,
				filter: {
					type: FilterComponentTypes.DateRange,
					name: 'createdBetween',
					label: 'Date created range',
					placeholder: 'Search date range',
				},
			},
			{
				name: GeneralFilterNames.Staff,
				filter: {
					type: FilterComponentTypes.Select,
					name: 'staffId',
					label: 'Staff',
					dropdownOptions:
						users?.map((user) => ({
							id: user.id,
							label: `${user.first_name} ${user?.middle_name ?? ' '} ${user.last_name}`,
							value: user.id,
						})) ?? [],
					placeholder: 'Select staff',
				},
			},
			{
				name: GeneralFilterNames.User,
				filter: {
					type: FilterComponentTypes.Select,
					name: 'user.id',
					label: 'Staff',
					dropdownOptions:
						users?.map((user) => ({
							id: user.id,
							label: `${user.first_name} ${user?.middle_name ?? ' '} ${user.last_name}`,
							value: user.id,
						})) ?? [],
					placeholder: 'Select staff',
				},
			},
		];

		// eslint-disable-next-line
	}, []);

	return {
		defaultNamedFilters: namedFilters,
		defaultFilters: namedFilters.map((filter) => filter.filter),
	};
};

export default useDefaultFilters;
