import { isPast } from 'date-fns';
import { useState } from 'react';
import { Badge, Button, List, ListItem, Text, Title } from '@tremor/react';
import Container from 'components/Container';
import { EditIcon } from 'components/FormActionIcons';
import InfoCard from 'components/InfoCard';
import { useAppDispatch, useAppSelector } from 'hooks/storeConnect';
import { prospectsState, setActiveProspect } from '../prospectsSlice';
import { formatDate, formatDateComparison } from 'helpers/dateHelpers';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import { fetchSingleProspect } from '../prospectQueries';
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import ManageProspectingTeamForm from '../team-members/ManageProspectingTeamForm';
import SlideOver from 'components/SlideOver';
import UpdateProspectInformationForm from '../UpdateProspectInformationForm';
import { formatCurrency, hasPermission } from 'helpers/index';
import { IProspect } from '../prospectInterfaces';
import withMap, { MapComponentProps } from 'components/withMap';
import PreviewProspectLocationMap from '../PreviewProspectLocationMap';
import { PermissionKeys } from 'features/auth/permissions';
import ContentBlockedFromView from 'components/ContentBlockedFromView';

interface Props extends MapComponentProps {}

const ProspectInformationPage = (props: Props) => {
	const {} = props;

	const { activeProspect } = useAppSelector(prospectsState);
	const {
		company_name,
		business_type,
		contact_name,
		contact_email,
		contact_phone,
		decision_maker,
		contact_date,
		last_interaction_date,
		insurance_products,
		created_at,
		location,
		source_type,
		user,
		team_members,
		sales_stage,
	} = activeProspect || {};
	const { id } = useParams();
	const dispatch = useAppDispatch();
	const [isEditProspectInfoFormOpen, setEditProspectInfoFormOpen] = useState(false);
	const [isManageTeamMemberFormOpen, setManageProspectingTeamFormOpen] = useState(false);

	const {
		isLoading,
		data,
		refetch: refetchProspect,
	} = useQuery({
		queryKey: ['prospects/fetch-single-prospects'],
		queryFn: () => fetchSingleProspect(Number(id)),
		onSuccess(data) {
			const prospectData = data.data.data;
			dispatch(setActiveProspect(prospectData));
		},
		onError: () => {
			toast.error('Error loading prospect information', { id: 'prospect-detail-error' });
		},
	});

	const prospect = data?.data?.data;

	if (!activeProspect)
		return (
			<SimpleEmptyResourceIndicator
				actionButton={{
					text: 'Try again',
					onClick: () => refetchProspect(),
				}}
				containerClassName='lg:mt-8 lg:!py-10'
				message='No prospect found'
			/>
		);

	const renderProspectInformation = () => (
		<div className='grid lg:h-[calc(100vh-var(--appbar-height-desktop)-var(--app-navigation-height)-var(--prospect-detail-page-height-information-page))] items-start sm:grid-cols-2 lg:grid-cols-3 gap-4'>
			{/* ======================================
      // Company and Contact Person Information
      ========================================*/}
			<InfoCard
				header={{
					type: 'filled',
					primaryText: 'Company & Contact Person Information',
				}}
				containerClassName='border border-gray-300 h-full'
			>
				<List>
					<ListItem className='px-4 !py-4 sm:!py-3 sm:flex-col 2xl:flex-row sm:items-start'>
						<h4 className='!font-semibold text-xs lg:text-sm  text-black '>Company Name</h4>
						<Text>{company_name || 'N/A'}</Text>
					</ListItem>
					<ListItem className='px-4 !py-4 sm:!py-3 sm:flex-col 2xl:flex-row sm:items-start'>
						<h4 className='!font-semibold text-xs lg:text-sm  text-black '>Type Of Business</h4>
						<Text>{business_type?.name || 'N/A'}</Text>
					</ListItem>
					<ListItem className='px-4 !py-4 sm:!py-3 sm:flex-col 2xl:flex-row sm:items-start'>
						<h4 className='!font-semibold text-xs lg:text-sm  text-black '>Person Contacted</h4>
						<Text>{contact_name || 'N/A'}</Text>
					</ListItem>
					<ListItem className='px-4 !py-4 sm:!py-3 sm:flex-col 2xl:flex-row sm:items-start'>
						<h4 className='!font-semibold text-xs lg:text-sm  text-black '>Contact Person Phone Number</h4>
						<Text>{contact_phone || 'N/A'}</Text>
					</ListItem>
					<ListItem className='px-4 !py-4 sm:!py-3 sm:flex-col 2xl:flex-row sm:items-start'>
						<h4 className='!font-semibold text-xs lg:text-sm  text-black '>Contact Person Email</h4>
						<Text>{contact_email || 'N/A'}</Text>
					</ListItem>
					<ListItem className='px-4 !py-4 sm:!py-3 sm:flex-col 2xl:flex-row sm:items-start'>
						<h4 className='!font-semibold text-xs lg:text-sm  text-black '>Source Type</h4>
						<Text>{source_type?.name || 'N/A'}</Text>
					</ListItem>
					<ListItem className='px-4 !py-4 sm:!py-3 sm:flex-col 2xl:flex-row sm:items-start'>
						<h4 className='!font-semibold text-xs lg:text-sm  text-black '>Decision Maker</h4>
						<Text>{decision_maker?.name || 'N/A'}</Text>
					</ListItem>
					<ListItem className='px-4 !py-4 sm:!py-3 sm:flex-col 2xl:flex-row sm:items-start'>
						<h4 className='!font-semibold text-xs lg:text-sm  text-black '>Contact Date</h4>
						<Text>{formatDate(contact_date, 'January 31st, 1999') || 'N/A'}</Text>
					</ListItem>
					<ListItem className='px-4 !py-4 sm:!py-3 sm:flex-col 2xl:flex-row sm:items-start'>
						<h4 className='!font-semibold text-xs lg:text-sm  text-black '>Last Interaction Date</h4>
						<Text>{last_interaction_date || 'N/A'}</Text>
					</ListItem>
					<ListItem className='px-4 !py-4 sm:!py-3 sm:flex-col 2xl:flex-row sm:items-start'>
						<h4 className='!font-semibold text-xs lg:text-sm  text-black '>Date Created</h4>
						<Text>{formatDate(created_at, 'January 31st, 1999') || 'N/A'}</Text>
					</ListItem>
				</List>
			</InfoCard>

			{/* =====================
      // Insurance Information
      =======================*/}
			<InfoCard
				header={{
					type: 'filled',
					primaryText: 'Insurance Information',
				}}
				containerClassName='border border-gray-300 h-full'
			>
				<div className='grid gap-4 p-2'>
					{/* Products Summary */}
					{(() => {
						const totalExpectedPremium = insurance_products?.reduce((acc, product) => acc + product.expected_premium, 0) ?? 0;
						const totalAchievedPremium = insurance_products?.reduce((acc, product) => acc + product.achieved_premium, 0) ?? 0;
						const totalExpectedCommission =
							insurance_products?.reduce((acc, product) => {
								const achievedCommission = product.achieved_premium ? product.expected_premium / product.commission_rate : 0;
								return achievedCommission + acc;
							}, 0) ?? 0;
						const totalAchievedCommission =
							insurance_products?.reduce((acc, product) => {
								const achievedCommission = product.achieved_premium ? product.achieved_premium / product.commission_rate : 0;
								return achievedCommission + acc;
							}, 0) ?? 0;

						return (
							<ul className='grid gap-2'>
								<li className='px-4 py-4 border border-gray-300 rounded-lg '>
									<Text className='text-xs font-medium uppercase'>Total Expected Premium</Text>
									<Text className='font-bold !text-[16px] text-blue-600'>{formatCurrency(totalExpectedPremium) || 'N/A'}</Text>
								</li>
								<li className='px-4 py-4 border border-green-400 rounded-lg '>
									<Text className='text-xs font-medium uppercase'>Total Achieved Premium</Text>
									<Text className='font-bold !text-[16px] text-green-600'>{formatCurrency(totalAchievedPremium) || 'N/A'}</Text>
								</li>
								<li className='px-4 py-4 border border-yellow-300 rounded-lg '>
									<Text className='text-xs font-medium uppercase'>Total Expected Commission</Text>
									<Text className='font-bold !text-[16px] text-yellow-600'>{formatCurrency(totalExpectedCommission) || 'N/A'}</Text>
								</li>
								<li className='px-4 py-4 border border-orange-300 rounded-lg '>
									<Text className='text-xs font-medium uppercase'>Total Achieved Commission</Text>
									<Text className='font-bold !text-[16px] text-orange-600'>{formatCurrency(totalAchievedCommission) || 'N/A'}</Text>
								</li>
							</ul>
						);
					})()}

					<Title>
						Products <Badge color={'gray'}>{insurance_products?.length}</Badge>
					</Title>

					{insurance_products?.map((product, index) => {
						const { achieved_premium, commission_rate, expected_close_date, expected_premium, insurance_product_type } = product;
						const { durationType, formattedDuration } = formatDateComparison(expected_close_date);

						return (
							<InfoCard
								key={index}
								header={{
									type: achieved_premium ? 'success' : durationType === 'past' ? 'danger' : 'light',
									primaryText: insurance_product_type.name,
									superText: 'Insurance Product',
								}}
								containerClassName='border border-gray-300 h-full'
							>
								<div className='w-full h-full overflow-hidden overflow-y-auto'>
									<List>
										<ListItem className='px-4 grid grid-cols-2 gap-6 !py-4 sm:!py-3 sm:flex-col sm:items-start'>
											<div>
												<h4 className='!font-semibold text-sm lg:text-xs text-black'>Expected Premium</h4>
												<Text className='!text-base text-blue-600 font-semibold'>{formatCurrency(expected_premium) || 'N/A'}</Text>
											</div>
											<div>
												<h4 className='!font-semibold text-sm lg:text-xs text-black'>Achieved Premium</h4>
												<Text className='!text-base text-green-600 font-semibold'>{formatCurrency(achieved_premium) || 'N/A'}</Text>
											</div>
										</ListItem>

										<ListItem className='px-4 grid grid-cols-2 gap-6 !py-4 sm:!py-3 sm:flex-col sm:items-start'>
											<div>
												<h4 className='!font-semibold text-sm lg:text-xs text-black'>{achieved_premium ? 'Achieved' : 'Expected'} Commission</h4>
												<Text className='!text-base text-orange-600 font-semibold'>{formatCurrency((achieved_premium || expected_premium) / commission_rate) || 'N/A'}</Text>
											</div>
											<div>
												<h4 className='!font-semibold text-sm lg:text-xs text-black'>Commission Rate</h4>
												<Text className='!text-base text-gray-600 font-semibold'>{`${commission_rate?.toFixed(2)}%` || 'N/A'}</Text>
											</div>
										</ListItem>

										<ListItem className='px-4 !py-4 sm:!py-3 sm:flex-col sm:items-start'>
											<div>
												<h4 className='!font-semibold text-sm lg:text-xs text-black'>Expected Close Date</h4>
												<Text className='!text-base'>
													{formatDate(expected_close_date, 'January 31st, 1999') || 'N/A'}{' '}
													{formattedDuration && (
														<span className={`!text-xs capitalize font-semibold ${durationType === 'now' ? 'text-yellow-500' : durationType === 'past' ? 'text-red-500' : 'text-green-500'}`}>
															({formattedDuration || 'N/A'})
														</span>
													)}
												</Text>
											</div>
										</ListItem>
									</List>
								</div>
							</InfoCard>
						);
					})}
				</div>
			</InfoCard>

			<div className='lg:h-full lg:overflow-hidden gap-4 grid lg:grid-rows-[105px,220px,1fr] sm:grid-cols-2 lg:grid-cols-1 sm:col-span-2 lg:col-span-1'>
				{/* ============
        // Sale Stage
        ==============*/}
				<InfoCard
					header={{
						type: 'filled',
						primaryText: 'Sales Stage',
					}}
					containerClassName='border border-gray-300 h-'
				>
					<div className='p-4'>
						{sales_stage?.name ? (
							<Text className='!text-base font-semibold'>
								<span className='text-primary-500'>{sales_stage?.name}</span> - {'5'}% probability of achieving sale
							</Text>
						) : (
							<Text>N/A</Text>
						)}
					</div>
				</InfoCard>

				{/* ==============
        // Team Assigned
        ================*/}
				<InfoCard
					header={{
						type: 'filled',
						primaryText: 'Team Assigned',
						secondaryContent: (
							<Button onClick={() => setManageProspectingTeamFormOpen(true)} icon={() => <EditIcon variant='solid' />} variant='light' className='hover:!text-black duration-200 gap-1'>
								Manage
							</Button>
						),
					}}
					containerClassName='border border-gray-300 h-full'
				>
					{hasPermission(PermissionKeys.VIEW_TEAM_MEMBER) ? (
						<List>
							<ListItem className='px-4 !py-4 sm:!py-3 flex-col items-start'>
								<h4 className='!font-semibold text-sm lg:text-xs text-black'>Leader</h4>
								<Text>
									{user?.first_name} {user?.middle_name} {user?.last_name}
								</Text>
							</ListItem>
							<ListItem className='px-4 !py-4 sm:!py-3 sm:flex-col sm:items-start flex-col items-start'>
								<h4 className='!font-semibold text-sm lg:text-xs text-black '>Other Members</h4>
								{team_members && team_members.length > 0 ? (
									<List className='mt-2 bg-gray-100 border rounded-lg'>
										{team_members.map((member, index) => (
											<ListItem key={index} className='!py-3 px-4'>
												{member.first_name} {member.middle_name} {member.last_name}
											</ListItem>
										))}
									</List>
								) : (
									<SimpleEmptyResourceIndicator
										message='No team member added'
										actionButton={{
											onClick: () => setManageProspectingTeamFormOpen(true),
											text: 'Add Team Member',
										}}
										containerClassName='lg:!py-4 !gap-3'
									/>
								)}
							</ListItem>
						</List>
					) : (
						<ContentBlockedFromView message='You are not authorized to view team members.' />
					)}
				</InfoCard>

				{/* ========================
        // Location
        ==========================*/}
				<InfoCard
					header={{
						type: 'filled',
						primaryText: 'Company Location',
					}}
					containerClassName='sm:col-span-2 lg:col-span-1 min-h-[320px] lg:min-[160px] border border-gray-300'
				>
					{location ? (
						<div className='relative w-full h-full'>
							<PreviewProspectLocationMap locationInformation={JSON.parse(location) as any} />
						</div>
					) : (
						<div className='w-full h-full bg-gray-400 animate-pulse' />
					)}
				</InfoCard>
			</div>
		</div>
	);

	return (
		<Container className='!px-2 lg:px-4'>
			{isLoading ? (
				<div className='mt-6'>
					<ContentLoadingCardSpinner message='Loading prospect information...' />
				</div>
			) : prospect ? (
				<div className='pb-4'>
					<div className='flex items-center justify-between py-6'>
						<Title className='lg:uppercase'>Prospect Information</Title>

						{hasPermission(PermissionKeys.UPDATE_PROSPECT) && (
							<Button onClick={() => setEditProspectInfoFormOpen(true)} className='!rounded-full !gap-2' icon={() => <EditIcon variant='light' />}>
								Edit Prospect <span className='hidden lg:inline-block'>Information</span>
							</Button>
						)}
					</div>

					{hasPermission(PermissionKeys.VIEW_PROSPECT) ? (
						renderProspectInformation()
					) : (
						<ContentBlockedFromView message='You are not authorized to view prospect information.' containerClassName='min-h-[350px]' />
					)}
				</div>
			) : (
				<SimpleEmptyResourceIndicator
					actionButton={{
						text: 'Try again',
						onClick: () => refetchProspect(),
					}}
					containerClassName='lg:mt-8 lg:!py-10'
					message='Failed to load prospect information. Kindly try refetching.'
				/>
			)}

			<SlideOver
				title={{
					text: company_name,
					theme: 'dark',
					styles: 'text-gray-200',
				}}
				subTitle={{
					text: 'Edit prospect information',
					styles: 'text-gray-300',
				}}
				onClose={() => setEditProspectInfoFormOpen(false)}
				open={isEditProspectInfoFormOpen}
				setOpen={setEditProspectInfoFormOpen}
				width={750}
				isScrollable={false}
				afterClose={() => null}
				shouldCloseOnOutsideClick={false}
			>
				<UpdateProspectInformationForm
					prospect={activeProspect}
					afterUpdate={() => {
						refetchProspect();
						setEditProspectInfoFormOpen(false);
					}}
				/>
			</SlideOver>

			<SlideOver
				title={{
					text: 'Prospecting Team',
					theme: 'primary',
					styles: 'text-gray-100',
				}}
				subTitle={{
					text: `Manage ${company_name} prospecting team`,
					styles: 'text-gray-200',
				}}
				onClose={() => setManageProspectingTeamFormOpen(false)}
				open={isManageTeamMemberFormOpen}
				setOpen={setManageProspectingTeamFormOpen}
				width={450}
				isScrollable
				afterClose={() => null}
				shouldCloseOnOutsideClick={false}
			>
				<ManageProspectingTeamForm
					prospect={activeProspect as IProspect}
					afterChange={() => {
						refetchProspect();
					}}
				/>
			</SlideOver>
		</Container>
	);
};

export default withMap(ProspectInformationPage);
