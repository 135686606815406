import { Fragment, useState } from 'react';
import { Button, Callout, Divider, Metric, Text, Title } from '@tremor/react';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import useFetchSourceTypes from './useFetchSourceTypes';
import { ISourceType } from '../prospectSettingsInterfaces';
import { BsTrash3Fill } from 'react-icons/bs';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { HiExclamation } from 'react-icons/hi';
import toast from 'react-hot-toast';
import { MODAL_LEAVE_SPEED } from 'config';
import { useMutation } from '@tanstack/react-query';
import { deleteSourceType } from '../prospectSettingsQueuries';
import { hasPermission, titleCase } from 'helpers';
import { FiEdit } from 'react-icons/fi';
import Modal from 'components/Modal';
import EditSourceTypeForm from './EditSourceTypeForm';
import { PermissionKeys } from 'features/auth/permissions';
import ContentBlockedFromView from 'components/ContentBlockedFromView';

const SourceTypesPage = () => {
	const [isEditSourceTypeFormOpen, setEditSourceTypeFormOpen] = useState(false);
	const [isConfirmDeleteSourceTypeDialogOpen, setConfirmDeleteSourceTypeDialogOpen] = useState(false);
	const [currentSourceType, setCurrentSourceType] = useState<ISourceType | null>(null);

	const { isSourceTypesLoading, refetchSourceTypes, sourceTypes } = useFetchSourceTypes({
		enabled: hasPermission(PermissionKeys.VIEW_SOURCE_TYPE),
	});

	// ======================
	// * Delete Source Type
	// ======================
	const { isLoading: isDeleteSourceTypeLoading, mutate: deleteSourceTypeMutation } = useMutation({
		mutationKey: ['authorization/delete-sourceType'],
		mutationFn: (sourceTypeId: number) => deleteSourceType(sourceTypeId),
	});

	const onConfirmDeleteSourceType = () => {
		if (!currentSourceType) return toast.error('No source type selected for this action');

		deleteSourceTypeMutation(currentSourceType.id, {
			onSuccess: () => {
				toast.success('Source Type updated successfully!');
				setEditSourceTypeFormOpen(false);
			},
			onError: () => {
				toast.error('Failed to delete source type');
			},
			onSettled() {
				setTimeout(() => setCurrentSourceType(null), MODAL_LEAVE_SPEED);
				refetchSourceTypes();
			},
		});

		setConfirmDeleteSourceTypeDialogOpen(false);
	};

	const renderActionButtons = (sourceType: ISourceType) => (
		<div className='flex items-center gap-6'>
			{hasPermission(PermissionKeys.UPDATE_SOURCE_TYPE) && (
				<button
					onClick={() => {
						setCurrentSourceType(sourceType);
						setEditSourceTypeFormOpen(true);
					}}
					type='button'
					title='Edit Source Type'
					className='flex items-center justify-center gap-2 group'
				>
					<div className='flex items-center justify-center text-yellow-600 duration-500 border border-yellow-100 lg:h-10 lg:w-10 lg:bg-yellow-50 lg:rounded-full lg:group-hover:text-white lg:group-hover:bg-gray-700'>
						<FiEdit className='w-5 h-5' />
					</div>

					<Text className='lg:font-medium'>Edit</Text>
				</button>
			)}

			{hasPermission(PermissionKeys.DELETE_SOURCE_TYPE) && (
				<button
					onClick={() => {
						setCurrentSourceType(sourceType);
						setConfirmDeleteSourceTypeDialogOpen(true);
					}}
					type='button'
					title='Delete Source Type'
					className='flex items-center justify-center gap-2 group '
				>
					<div className='flex items-center justify-center text-red-600 duration-500 border border-red-100 lg:h-10 lg:w-10 lg:bg-red-50 lg:rounded-full lg:group-hover:text-white lg:group-hover:bg-gray-700'>
						<BsTrash3Fill className='w-5 h-5' />
					</div>
					<Text className='lg:font-medium'>Delete</Text>
				</button>
			)}

			{!(hasPermission(PermissionKeys.UPDATE_SOURCE_TYPE) && hasPermission(PermissionKeys.DELETE_SOURCE_TYPE)) && (
				<Callout title='Not authorized to perform action' icon={HiExclamation} color='yellow' />
			)}
		</div>
	);

	const renderSourceTypes = (sourceTypes: ISourceType[]) =>
		sourceTypes.map((sourceType, index) => (
			<div key={index}>
				<Divider />

				<div className='items-start gap-2 lg:gap-4 lg:items-center grid sm:grid-cols-[1fr,200px]'>
					<div>
						<Title>{sourceType.name}</Title>
						{sourceType?.description && <Text>{sourceType.description}</Text>}
					</div>

					<div className='mt-2 sm:mt-0'>{renderActionButtons(sourceType)}</div>
				</div>
			</div>
		));

	return (
		<Fragment>
			<div className='px-2 py-6 sm:px-4'>
				<div className='flex items-center justify-between'>
					<div className='flex items-center gap-3'>
						<Metric className='hidden sm:block'>Prospecting</Metric>

						{/* Separator */}
						<span className='hidden sm:inline-block w-[1px] h-[40px] bg-black rotate-12' />

						<Title className='lg:mt-1'>Source Types</Title>
					</div>

					{hasPermission(PermissionKeys.CREATE_SOURCE_TYPE) && (
						<Button className='!rounded-full' onClick={() => setEditSourceTypeFormOpen(true)}>
							Create Source Type
						</Button>
					)}
				</div>

				<div>
					{hasPermission(PermissionKeys.VIEW_SOURCE_TYPE) ? (
						<Fragment>
							{!sourceTypes && isSourceTypesLoading ? (
								<div className='mt-6'>
									<ContentLoadingCardSpinner message='Loading source types...' />
								</div>
							) : sourceTypes && sourceTypes.length > 0 ? (
								<Fragment>
									<div className='sm:grid-cols-[1fr,200px] hidden sm:grid sm:mt-6'>
										<h1 className='text-sm font-semibold text-gray-600 uppercase sm:text-xs'>Source Type</h1>
										<h1 className='text-sm font-semibold text-gray-600 uppercase sm:text-xs'>Action</h1>
									</div>

									{renderSourceTypes(sourceTypes)}
								</Fragment>
							) : (
								<SimpleEmptyResourceIndicator containerClassName='lg:mt-8' message='No source type found' />
							)}
						</Fragment>
					) : (
						<div className='mt-6'>
							<ContentBlockedFromView message='You are not authorized to view source types.' containerClassName='min-h-[350px]' />
						</div>
					)}
				</div>
			</div>

			<Modal
				width={600}
				closeButton={{ styles: 'bg-gray-200' }}
				isModalOpen={isEditSourceTypeFormOpen}
				setModalOpen={setEditSourceTypeFormOpen}
				onCloseModal={() => {
					setEditSourceTypeFormOpen(false);
					setTimeout(() => setCurrentSourceType(null), 500);
				}}
				shouldCloseOnOutsideClick={false}
			>
				<EditSourceTypeForm sourceType={currentSourceType} setCurrentSourceType={setCurrentSourceType} setEditSourceTypeFormOpen={setEditSourceTypeFormOpen} refetchSourceTypes={refetchSourceTypes} />
			</Modal>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmDeleteSourceTypeDialogOpen}
				setOpen={setConfirmDeleteSourceTypeDialogOpen}
				message='Delete Source Type'
				subMessage={`Are you sure you want to delete ${titleCase(currentSourceType?.name as string) ?? 'this  source type'}? This action cannot be reversed.`}
				onClose={() => {
					setTimeout(() => setCurrentSourceType(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmDeleteSourceType,
					label: 'Delete',
					isLoading: isDeleteSourceTypeLoading,
					loadingText: 'Deleting',
					color: 'red',
				}}
				cancelButton={{
					action: () => setConfirmDeleteSourceTypeDialogOpen(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-500/10`}>
						<HiExclamation className='w-6 h-6 text-red-500' aria-hidden='true' />
					</div>
				)}
			/>
		</Fragment>
	);
};

export default SourceTypesPage;
