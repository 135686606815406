// * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// > REQUIREMENTS
// !  Dependencies
//    npm i @headlessui/react
//    npm i react-icons (optional bcos u can use any icon library)

// >  Props
// *  children (any) - Child content of the modal
// *  isModalOpen (bool: state)
// *  setModalOpen (func: state)
// *  title (string) - Title of the modal
// *  titleStyles (string) - Modal title styles
// *  containerStyle (string) - styles for the modal container
// *  closeButtonStyle (string)
// *  modalPosition (string) - vertical position of the modal on the viewport (values: top, bottom, center)
// *  shouldCloseOnOutsideClick (bool) - should modal close when click outside?
// * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

import { Fragment, ReactNode, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { VscClose } from 'react-icons/vsc';
import useMediaQuery from 'hooks/useMediaQuery';

interface Props {
	width: number;
	children: ReactNode;
	isModalOpen: boolean;
	setModalOpen: (value: boolean) => void;
	onCloseModal: () => void;
	title?: {
		text: string;
		styles?: string;
	};
	container?: {
		styles?: string;
	};
	closeButton?: {
		styles?: string;
	};
	modalPosition?: string;
	shouldCloseOnOutsideClick?: boolean;
	closeEventListenerName?: string;
}

const Modal = (props: Props) => {
	const { width, children, isModalOpen, setModalOpen, onCloseModal, title, container, closeButton, shouldCloseOnOutsideClick, closeEventListenerName } = props;

	const isMinTablet = useMediaQuery({ query: '(min-width: 640px)' });

	useEffect(() => {
		const openModal = () => {
			setModalOpen(false);
			setTimeout(() => onCloseModal(), 500);
		};

		document.addEventListener(!closeEventListenerName ? 'closeModal' : closeEventListenerName, openModal);

		return () => {
			document.removeEventListener(!closeEventListenerName ? 'closeModal' : closeEventListenerName, openModal);
		};

		//eslint-disable-next-line
	}, []);

	return (
		<Transition appear show={isModalOpen} as={Fragment}>
			<Dialog as='div' className='fixed inset-0 z-[var(--modal-z-index)] overflow-y-auto' onClose={() => setModalOpen(false)}>
				<div className='min-h-screen px-2 text-center'>
					<Transition.Child as={'div'} enter='ease-out duration-300' enterFrom='opacity-0' enterTo='opacity-100' leave='ease-in duration-200' leaveFrom='opacity-100' leaveTo='opacity-0'>
						<Dialog.Overlay className={`fixed inset-0 bg-black opacity-40 filter ${!shouldCloseOnOutsideClick ? 'pointer-events-none' : ''}`} />
					</Transition.Child>

					<div className='relative mt-[100px] flex items-center justify-center py-6'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
							enterTo='opacity-100 translate-y-0 sm:scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 translate-y-0 sm:scale-100'
							leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
						>
							<div
								className={`relative inline-block transform overflow-x-hidden rounded-xl shadow-xl transition-all align-middle ${container?.styles ? container.styles : 'bg-white'}`}
								style={{ width: isMinTablet ? width : '90vw' }}
							>
								{title && (
									<Dialog.Title as='h2' className={`${title.styles ? title.styles : 'mb-2 border-b border-gray-300 border-opacity-100 pb-2 text-lg font-semibold uppercase text-primary-600'}`}>
										{title.text}
									</Dialog.Title>
								)}

								<button
									title='Close'
									type='button'
									onClick={() => {
										onCloseModal();
										setModalOpen(false);
									}}
									className={`z-90 absolute right-4 top-4 flex items-center justify-center rounded-lg duration-200 bg-gray-200 w-7 h-7 text-red-500 ${
										closeButton?.styles ? closeButton.styles : 'text-2xl'
									}`}
								>
									<VscClose className='h-6 w-6' />
								</button>

								{children}
							</div>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};

Modal.defaultProps = {
	modalPosition: 'align-text-bottom',
};

export default Modal;
