import { Card, CategoryBar, Grid, Legend, Metric, Text } from '@tremor/react';
import { IVehicleDriverStat, IVehicleDriverStatData } from './dashboardInterfaces';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import { hasPermission } from 'helpers/index';
import { PermissionKeys } from 'features/auth/permissions';

interface Props {
	isVehicleDriverStatsLoading: boolean;
	vehicleDriverStats: IVehicleDriverStat | undefined;
}

interface ICategory {
	title: string;
	total: number;
	subCategoryValues: number[];
	subCategoryColors: string[];
	subCategoryTitles: string[];
}

const VehicleDriverStats = (props: Props) => {
	const { isVehicleDriverStatsLoading, vehicleDriverStats } = props;

	const generatedCategories = (vehicleDriverStats: IVehicleDriverStat): (ICategory | null)[] => {
		return Object.entries(vehicleDriverStats).map((entry, index) => {
			if (!hasPermission(PermissionKeys.VIEW_VEHICLE) && entry[0] === 'vehicles') return null;

			const title = entry[0];
			const stat: IVehicleDriverStatData = entry[1];

			const percentageActive = stat.total > 0 ? (stat.total / stat.total_active) * 100 : 0;
			const percentageInactive = stat.total > 0 ? (stat.total / stat.total_inactive) * 100 : 0;

			return {
				title,
				total: stat.total,
				subCategoryValues: [percentageActive, percentageInactive],
				subCategoryColors: index === 0 ? ['red', 'emerald'] : ['rose', 'green'],
				subCategoryTitles:
					index === 0 ? [`Active drivers: ${stat.total_active}`, `Inactive drivers: ${stat.total_inactive}`] : [`Active vehicles: ${stat.total_active}`, `Inactive vehicles: ${stat.total_inactive}`],
			};
		});
	};

	if (isVehicleDriverStatsLoading)
		return (
			<Grid numItemsSm={2} className='gap-4'>
				{Array.from({ length: 2 }, (_, i) => i).map((index) => (
					<Card key={index} className='min-h-[200px] !bg-gray-400 animate-pulse' />
				))}
			</Grid>
		);

	if (!vehicleDriverStats) return <SimpleEmptyResourceIndicator message='No statistics found' containerClassName='min-h-[180px]' />;

	return (
		<Grid numItemsSm={hasPermission(PermissionKeys.VIEW_VEHICLE) ? 2 : 1} className='gap-4'>
			{generatedCategories(vehicleDriverStats).map((item) => {
				if (!item) return '';

				return (
					<Card key={item.title}>
						<Text className='capitalize font-semibold'>{item.title.split('_').join(' ')}</Text>
						<Metric>{item.total}</Metric>
						<CategoryBar values={item.subCategoryValues} colors={item.subCategoryColors as any} className='mt-4' />
						<Legend categories={item.subCategoryTitles} colors={item.subCategoryColors as any} className='mt-3' />
					</Card>
				);
			})}
		</Grid>
	);
};

export default VehicleDriverStats;
