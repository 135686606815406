import { Fragment, useState } from 'react';
import Container from 'components/Container';
import MarketingDashboardStatisticsCards from './MarketingDashboardStatisticsCards';
import ProductionChart from './ProductionChart';
import ProductRevenueShareChart from './ProductRevenueShareChart';
import TopSellingReps from './TopSellingReps';
import YearlyProductionPercentageChart from './YearlyProductionPercentageChart';
import { convertJSONToExcel, formatCurrency, hasRole, hasRoles, isAdmin } from 'helpers/index';
import { DefaultRoles } from 'features/settings/authorization/authorizationInterface';
import { useQuery } from '@tanstack/react-query';
import { IDashboardFilters, IMarketingManagerDashboardData } from './dashboardInterfaces';
import { fetchMarketerDashboard, fetchMarketingManagerDashboard } from './dashboardQueries';
import { Button, Title } from '@tremor/react';
import { formatDate, formatDateRangePickerValue } from 'helpers/dateHelpers';
import Filters from 'components/Filters';
import { FilterComponentTypes } from 'features/settings/settingsInterfaces';
import useFetchUsers from 'features/settings/users/useFetchUsers';
import BottomSheet from 'components/BottomSheet';
import { RiFilter2Fill } from 'react-icons/ri';
import useFetchReports from 'features/reports/useFetchReports';
import { authState } from 'features/auth/authSlice';
import { useAppSelector } from 'hooks/storeConnect';
import ProspectsBySaleStageMetrics from '../reports/ProspectsBySaleStageMetrics';
import ProspectsByBusinessTypeMetrics from 'features/reports/ProspectsByBusinessTypeMetrics';
import { SiMicrosoftexcel } from 'react-icons/si';
import toast from 'react-hot-toast';
import { isMarketer } from 'utils/index';
import { PermissionKeys } from 'features/auth/permissions';

const MarketingDashboardPage = () => {
	const { authUser } = useAppSelector(authState);
	const [fetchFilters, setFetchFilters] = useState<IDashboardFilters>({
		userId: !isAdmin() && hasRoles([DefaultRoles.TransportOfficer, DefaultRoles.Marketer]) ? authUser?.id : undefined,
	});

	const { users } = useFetchUsers({});
	const [isMobileFiltersOpen, setMobileFiltersOpen] = useState<boolean>(false);

	// Fetch reports
	const { reports, isReportsLoading } = useFetchReports({
		fetchParams: {
			filterBy: fetchFilters,
		},
		queryKey: ['dashboard/dashboard-reports', fetchFilters],
	});
	// Fetch Marketing Manager Dashboard
	const { data: marketingManagerData, isLoading: isMarketingManagerDashboardLoading } = useQuery({
		queryKey: ['dashboard/fetch-marketing-manager', fetchFilters],
		queryFn: () => fetchMarketingManagerDashboard(fetchFilters),
		enabled: hasRoles([DefaultRoles.MarketingManager, DefaultRoles.Admin]),
	});
	// Fetch Marketer Dashboard
	const { data: marketerData, isLoading: isMarketerDashboardLoading } = useQuery({
		queryKey: ['dashboard/fetch-marketer', fetchFilters],
		queryFn: () => fetchMarketerDashboard(fetchFilters),
		enabled: isMarketer(),
	});

	const marketingManagerDashboardData: IMarketingManagerDashboardData | undefined = marketingManagerData?.data.data;
	const marketerDashboardData: IMarketingManagerDashboardData | undefined = marketerData?.data.data;

	const onSubmitFilters = (filters: IDashboardFilters) => {
		setFetchFilters({
			...fetchFilters,
			...filters,
			createdBetween: filters.createdBetween ? formatDateRangePickerValue(filters?.createdBetween as any) : undefined,
		});
	};

	const buildSheetMetrics = (): ISheetStructure | null => {
		const sheetMetrics: ISheetStructure = [];
		const dashboardData = authUser?.roles.includes(DefaultRoles.Marketer) ? marketerDashboardData : marketingManagerDashboardData;

		if (!dashboardData) return null;

		// =================================================================
		sheetMetrics.push({
			'Metric Name': '_________________________________________________',
			Value: '_______________________',
		});

		sheetMetrics.push({
			'Metric Name': 'Total Prospects',
			Value: dashboardData.total_prospects as any,
		});
		sheetMetrics.push({
			'Metric Name': 'Total Expected Revenue',
			Value: formatCurrency(dashboardData.total_expected_revenue ?? 0) as any,
		});
		sheetMetrics.push({
			'Metric Name': 'Total Achieved Revenue',
			Value: formatCurrency(dashboardData.total_achieved_revenue ?? 0) as any,
		});
		sheetMetrics.push({
			'Metric Name': 'Total Pending Follow Ups',
			Value: reports?.total_follow_ups[0].pending as any,
		});
		sheetMetrics.push({
			'Metric Name': 'Total Completed Follow Ups',
			Value: reports?.total_follow_ups[0].done as any,
		});
		sheetMetrics.push({
			'Metric Name': 'Average Achieved Premium',
			Value: formatCurrency(reports?.total_aggregate.avg_achieved_premium ?? 0) as any,
		});
		sheetMetrics.push({
			'Metric Name': 'Number of Won Opportunities',
			Value: reports?.total_aggregate.won_opportunities as any,
		});
		sheetMetrics.push({
			'Metric Name': 'Yearly Target',
			Value: formatCurrency(dashboardData.total_yearly_target ?? 0) as any,
		});
		sheetMetrics.push({
			'Metric Name': `Your Prospect Closing Percentage (${authUser?.first_name} ${authUser?.middle_name ?? ''} ${authUser?.last_name})`,
			Value: reports?.closing_percentage.find((percentage) => percentage.name.toLowerCase().includes(authUser?.first_name.toLowerCase() ?? ''))?.closing_percentage ?? (0 as any),
		});

		// =================================================================
		sheetMetrics.push({
			'Metric Name': '_________________________________________________',
			Value: '_______________________',
		});
		sheetMetrics.push({
			'Metric Name': 'PROSPECTS BY SALES STAGE',
			Value: ' ',
		});
		reports?.prospects_by_sales_stage.forEach((r) =>
			sheetMetrics.push({
				'Metric Name': r.name,
				Value: r.count as any,
			})
		);

		// =================================================================
		sheetMetrics.push({
			'Metric Name': '_________________________________________________',
			Value: '_______________________',
		});
		sheetMetrics.push({
			'Metric Name': 'PROSPECTS BY BUSINESS TYPE',
			Value: ' ',
		});
		reports?.prospects_by_busines_type.forEach((r) =>
			sheetMetrics.push({
				'Metric Name': r.name,
				Value: r.count as any,
			})
		);

		// =================================================================
		sheetMetrics.push({
			'Metric Name': '_________________________________________________',
			Value: '_______________________',
		});
		sheetMetrics.push({
			'Metric Name': 'INSURANCE PRODUCTS REVENUE SHARE',
			Value: ' ',
		});
		dashboardData.insurance_product_percentage.forEach((r) =>
			sheetMetrics.push({
				'Metric Name': r.insurance_product_type.name,
				Value: formatCurrency(r.total_premium ?? 0) as any,
			})
		);

		// =================================================================
		sheetMetrics.push({
			'Metric Name': '_________________________________________________',
			Value: '_______________________',
		});
		sheetMetrics.push({
			'Metric Name': 'PERCENTAGE OF YEARLY TARGET ACHIEVED',
			Value: ' ',
		});

		const total_yearly_target_achieved = (isMarketer() ? dashboardData.total_achieved_revenue : dashboardData.total_achieved_yearly_target) || 0;
		const percentageAchieved = dashboardData.total_yearly_target !== 0 ? ((total_yearly_target_achieved / dashboardData.total_yearly_target ?? 0) * 100).toFixed(2) : 0;

		sheetMetrics.push({
			'Metric Name': 'Total Yearly Target',
			Value: formatCurrency(dashboardData.total_yearly_target ?? 0) as any,
		});
		sheetMetrics.push({
			'Metric Name': 'Total Achieved Target',
			Value: formatCurrency(dashboardData.total_achieved_revenue ?? 0) as any,
		});
		sheetMetrics.push({
			'Metric Name': 'Percentage Achieved',
			Value: (percentageAchieved + '%') as any,
		});

		// =================================================================
		sheetMetrics.push({
			'Metric Name': '_________________________________________________',
			Value: '_______________________',
		});
		sheetMetrics.push({
			'Metric Name': 'PRODUCTION TREND',
			Value: ' ',
		});
		dashboardData.revenue_per_month.forEach((r) =>
			sheetMetrics.push({
				'Metric Name': r.month,
				Value: `Expected: ${formatCurrency(r.expected_revenue ?? 0)} | Achieved: ${formatCurrency(r.achieved_revenue ?? 0)}` as any,
			})
		);

		if (isMarketer()) return sheetMetrics;
		// =================================================================
		sheetMetrics.push({
			'Metric Name': '_________________________________________________',
			Value: '_______________________',
		});
		sheetMetrics.push({
			'Metric Name': 'TOP SELLING REPS',
			Value: ' ',
		});
		dashboardData.top_selling_staffs.forEach(({ user, total_achieved_premium, total_expected_premium }) =>
			sheetMetrics.push({
				'Metric Name': `${user.first_name} ${user.middle_name ?? ''} ${user.last_name}`,
				Value: `Expected: ${formatCurrency(total_expected_premium ?? 0)} | Achieved: ${formatCurrency(total_achieved_premium ?? 0)}` as any,
			})
		);

		return sheetMetrics;
	};

	const onExportMetricsToExcel = () => {
		const sheetStructure: ISheetStructure | null = buildSheetMetrics();

		if (sheetStructure == null) return toast.error('No data to export.');

		let [startDate, endDate]: any = fetchFilters?.createdBetween ? fetchFilters.createdBetween.split(',') : [formatDate(new Date(), '1999-12-31'), null];

		if (sheetStructure.length === 0) return toast.error('No data to export. Please make sure your report has at least one record.');

		convertJSONToExcel(sheetStructure, `Report ${formatDate(startDate, '31-12-1999')}${endDate ? ` to ${formatDate(endDate, '31-12-1999')}` : ''}`);
	};

	return (
		<div className='w-full h-full pt-2'>
			<Container>
				<div className='flex items-center justify-between gap-2 pb-4'>
					<Title className='!text-2xl'>{isAdmin() && <span className='hidden sm:inline'>Marketing</span>} Dashboard</Title>

					{/* ===============
          // Filters
          =============== */}
					<div className='items-center hidden gap-2 ml-auto lg:flex'>
						<Filters
							filters={[
								{
									type: FilterComponentTypes.DateRange,
									name: 'createdBetween',
									label: 'Dates',
									hideLabel: true,
									placeholder: 'Select date range',
								},
								{
									type: FilterComponentTypes.Select,
									name: 'userId',
									label: 'Staff',
									hideLabel: true,
									dropdownOptions:
										users
											?.filter((user) => {
												return !user.roles.includes(DefaultRoles.SuperAdmin);
											})
											.map((user: any) => ({
												id: user.id,
												label: `${user.first_name} ${user?.middle_name ?? ' '} ${user.last_name}`,
												value: user.id,
											})) ?? [],
									placeholder: 'Select staff',
									permission: PermissionKeys.VIEW_USER_FILTERS,
								},
							]}
							submitButton={{
								label: 'Apply Filters',
								onSubmit: onSubmitFilters,
							}}
						/>
					</div>

					<Button
						color='gray'
						variant='secondary'
						onClick={() => setMobileFiltersOpen(true)}
						icon={RiFilter2Fill}
						className='!rounded-full ml-auto hover:bg-gray-700 hover:!text-gray-100 duration-300 lg:hidden'
					>
						Filters
					</Button>

					<Button onClick={onExportMetricsToExcel} iconPosition='left' size='sm' color='gray' className='!rounded-full uppercase'>
						Export
					</Button>
				</div>

				{!isAdmin() ? (
					<Fragment>
						<div className=''>
							{/* ======= Marketing Manager =======*/}
							{/* ======= Marketer =======*/}
							{hasRole(DefaultRoles.Marketer) && (
								<div className='w-full overflow-x-hidden px-[1px]'>
									<div className='space-y-6'>
										<MarketingDashboardStatisticsCards data={marketerDashboardData} isDataLoading={isMarketerDashboardLoading} reports={reports} isReportsLoading={isReportsLoading} />

										<ProspectsBySaleStageMetrics isReportLoading={isReportsLoading} prospectsBySalesStage={reports?.prospects_by_sales_stage} />

										<ProspectsByBusinessTypeMetrics isReportLoading={isReportsLoading} prospectsByBusinessType={reports?.prospects_by_busines_type} />
									</div>

									<div className='mt-8 grid gap-6 2xl:grid-cols-2 p-[2px]'>
										<div className='w-full'>
											<ProductionChart data={marketerDashboardData} isDataLoading={isMarketerDashboardLoading} />
										</div>

										<div className='grid w-full gap-6 pb-8 2xl:pb-0 sm:grid-cols-2'>
											<div className='z-20 w-full lg:self-stretch'>
												<ProductRevenueShareChart data={marketerDashboardData} isDataLoading={isMarketerDashboardLoading} />
											</div>

											<div className='w-full lg:self-stretch'>
												<YearlyProductionPercentageChart selectedDateRange={fetchFilters.createdBetween} data={marketerDashboardData} isDataLoading={isMarketerDashboardLoading} />
											</div>
										</div>
									</div>
								</div>
							)}

							{/* ======= Marketing Manager =======*/}
							{hasRole(DefaultRoles.MarketingManager) && (
								<Fragment>
									<div className='space-y-6'>
										<MarketingDashboardStatisticsCards data={marketingManagerDashboardData} isDataLoading={isMarketingManagerDashboardLoading} reports={reports} isReportsLoading={isReportsLoading} />

										<ProspectsBySaleStageMetrics isReportLoading={isReportsLoading} prospectsBySalesStage={reports?.prospects_by_sales_stage} />

										<ProspectsByBusinessTypeMetrics isReportLoading={isReportsLoading} prospectsByBusinessType={reports?.prospects_by_busines_type} />
									</div>

									<div className='w-full mt-8'>
										<ProductionChart data={marketingManagerDashboardData} isDataLoading={isMarketingManagerDashboardLoading} />
									</div>

									<div className='grid w-full gap-6 mt-8 2xl:gap-8 sm:grid-cols-2 lg:flex'>
										<div className='w-full lg:self-stretch lg:w-3/5 sm:col-span-2'>
											<TopSellingReps data={marketingManagerDashboardData} isDataLoading={isMarketingManagerDashboardLoading} />
										</div>

										<div className='z-20 w-full lg:self-stretch lg:w-1/5'>
											<ProductRevenueShareChart data={marketingManagerDashboardData} isDataLoading={isMarketingManagerDashboardLoading} />
										</div>

										<div className='w-full lg:self-stretch lg:w-1/5'>
											<YearlyProductionPercentageChart selectedDateRange={fetchFilters.createdBetween} data={marketingManagerDashboardData} isDataLoading={isMarketingManagerDashboardLoading} />
										</div>
									</div>
								</Fragment>
							)}
						</div>
					</Fragment>
				) : (
					<div className='pb-8'>
						<div className='flex flex-col gap-4'>
							<div className='space-y-6'>
								<MarketingDashboardStatisticsCards data={marketingManagerDashboardData} isDataLoading={isMarketingManagerDashboardLoading} reports={reports} isReportsLoading={isReportsLoading} />

								<ProspectsBySaleStageMetrics isReportLoading={isReportsLoading} prospectsBySalesStage={reports?.prospects_by_sales_stage} />

								<ProspectsByBusinessTypeMetrics isReportLoading={isReportsLoading} prospectsByBusinessType={reports?.prospects_by_busines_type} />
							</div>
						</div>

						<div className='w-full mt-8'>
							<ProductionChart data={marketingManagerDashboardData} isDataLoading={isMarketingManagerDashboardLoading} />
						</div>

						<div className='grid w-full gap-6 mt-8 2xl:gap-8 sm:grid-cols-2 lg:flex'>
							<div className='w-full lg:self-stretch lg:w-3/5 sm:col-span-2'>
								<TopSellingReps data={marketingManagerDashboardData} isDataLoading={isMarketingManagerDashboardLoading} />
							</div>
							<div className='z-20 w-full lg:self-stretch lg:w-1/5'>
								<ProductRevenueShareChart data={marketingManagerDashboardData} isDataLoading={isMarketingManagerDashboardLoading} />
							</div>
							<div className='w-full lg:self-stretch lg:w-1/5'>
								<YearlyProductionPercentageChart selectedDateRange={fetchFilters.createdBetween} data={marketingManagerDashboardData} isDataLoading={isMarketingManagerDashboardLoading} />
							</div>
						</div>
					</div>
				)}
			</Container>

			<BottomSheet
				isOpen={isMobileFiltersOpen}
				setOpen={setMobileFiltersOpen}
				closeOnOutSideClick
				header={{
					text: 'Filter Dashboard Data',
				}}
			>
				<Filters
					filters={[
						{
							type: FilterComponentTypes.DateRange,
							name: 'createdBetween',
							label: 'Dates',
							hideLabel: true,
							placeholder: 'Select date range',
						},
						{
							type: FilterComponentTypes.Select,
							name: 'userId',
							label: 'Staff',
							hideLabel: true,
							dropdownOptions:
								users
									?.filter((user) => {
										// return user.roles.includes(DefaultRoles.Marketer) || user.roles.includes(DefaultRoles.MarketingManager)
										return !user.roles.includes(DefaultRoles.SuperAdmin);
									})
									?.map((user: any) => ({
										id: user.id,
										label: `${user.first_name} ${user?.middle_name ?? ' '} ${user.last_name}`,
										value: user.id,
									})) ?? [],
							placeholder: 'Select staff',
							permission: PermissionKeys.VIEW_USER_FILTERS,
						},
					]}
					submitButton={{
						label: 'Apply Filters',
						onSubmit: onSubmitFilters,
					}}
				/>
			</BottomSheet>
		</div>
	);
};

export default MarketingDashboardPage;
