import { useReactTable, getCoreRowModel, flexRender, getPaginationRowModel } from '@tanstack/react-table';
import { GoChevronLeft, GoChevronRight } from 'react-icons/go';
import { HiOutlineChevronDoubleLeft, HiOutlineChevronDoubleRight } from 'react-icons/hi2';

interface IServerPagination {
	handlePageChange: (page: number) => void;
	metadata: APIPaginationMetadata;
}

interface Props {
	columns: any;
	data: any;
	useInbuiltPagination?: boolean;
	pagination?: IServerPagination;
}

const Table = (props: Props) => {
	const { data, columns, useInbuiltPagination, pagination } = props;

	const table = useReactTable({
		columns,
		data,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: useInbuiltPagination ? getPaginationRowModel() : undefined,
	});

	const renderInbuiltPagination = (pagination?: IServerPagination) => {
		const { handlePageChange, metadata } = pagination || {};

		const { current_page, last_page } = metadata || {};

		const defaultInbuiltPaginationStyles =
			'h-full flex items-center px-3 py-1.5 text-gray-600 text-sm hover:bg-primary-500 hover:text-white duration-200 disabled:bg-gray-100 disabled:hover:bg-gray-100 disabled:hover:text-gray-600';

		return (
			<div className='flex items-center justify-center w-full px-4 py-4 border-t lg:justify-between'>
				<div className='hidden lg:block'>
					{metadata && (
						<div className='flex items-center gap-1'>
							{' '}
							Page <span className='inline-block px-1 py-0.5 rounded border bg-gray-50 text-black font-medium'>{current_page}</span> of{' '}
							<span className='inline-block px-1 py-0.5 rounded border bg-gray-50 text-black font-medium'>{last_page}</span>
						</div>
					)}{' '}
				</div>

				<div className='flex items-center overflow-hidden text-gray-600 border divide-x rounded-lg w-fit'>
					<button
						type='button'
						title='First Page'
						onClick={() => {
							if (handlePageChange && metadata) handlePageChange(1);
							else table.setPageIndex(0);
						}}
						className={`${defaultInbuiltPaginationStyles}`}
					>
						<span className='sm:hidden'>
							<HiOutlineChevronDoubleLeft className='w-5 h-5' />
						</span>
						<span className='hidden sm:inline'>First Page</span>
					</button>

					<button
						type='button'
						title='Previous Page'
						disabled={metadata ? current_page === 1 : !table.getCanPreviousPage()}
						onClick={() => {
							if (handlePageChange && metadata) handlePageChange(metadata.current_page - 1);
							else table.previousPage();
						}}
						className={`${defaultInbuiltPaginationStyles}`}
					>
						<GoChevronLeft className='w-5 h-5' />
						Previous
					</button>

					<button
						type='button'
						title='Next Page'
						disabled={metadata ? current_page === last_page : !table.getCanNextPage()}
						onClick={() => {
							if (handlePageChange && metadata) handlePageChange(metadata.current_page + 1);
							else table.nextPage();
						}}
						className={`${defaultInbuiltPaginationStyles}`}
					>
						Next
						<GoChevronRight className='flex items-center w-5 h-5' />
					</button>

					<button
						type='button'
						title='Last Page'
						onClick={() => {
							if (handlePageChange && metadata) handlePageChange(metadata.last_page);
							else table.setPageIndex(table.getPageCount() - 1);
						}}
						className={`${defaultInbuiltPaginationStyles}`}
					>
						<span className='hidden sm:inline'>Last Page</span>
						<span className='sm:hidden'>
							<HiOutlineChevronDoubleRight className='w-5 h-5' />
						</span>
					</button>
				</div>
			</div>
		);
	};

	return (
		<div className='w-full overflow-x-auto'>
			{(pagination || useInbuiltPagination) && (
				<div className='flex justify-start w-full border-b sm:hidden'>
					<div className='w-fit'>{renderInbuiltPagination(pagination)}</div>
				</div>
			)}

			<table className='w-full custom-table'>
				<thead className='border-b bg-gray-50'>
					{table.getHeaderGroups().map((headerGroups, index) => {
						const { headers } = headerGroups;

						return (
							<tr key={index}>
								{headers.map((header, index) => {
									return (
										<th key={index} className='px-2 py-2 text-sm font-semibold text-left text-gray-500 capitalize first-of-type:pl-4 last-of-type:pr-4 sm:first-of-type:pl-4 sm:last-of-type:pr-4'>
											{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
										</th>
									);
								})}
							</tr>
						);
					})}
				</thead>

				<tbody className='divide-y'>
					{table.getRowModel().rows.map((row, index) => (
						<tr key={index} className='duration-300 even:bg-gray-50 hover:bg-gray-100'>
							{row.getVisibleCells().map(({ column, getContext }, index) => {
								return (
									<td key={index} data-cell={column.columnDef.header} className='relative px-2 py-3 text-sm text-gray-700 first-of-type:pl-4 last-of-type:pr-4'>
										{flexRender(column.columnDef.cell, getContext())}
									</td>
								);
							})}
						</tr>
					))}
				</tbody>
			</table>

			{(pagination || useInbuiltPagination) && <div className='w-fit sm:w-full'>{renderInbuiltPagination(pagination)}</div>}
		</div>
	);
};

export default Table;
