import { Card, Color } from '@tremor/react';
import { ReactNode } from 'react';

interface Props {
	children: ReactNode;
	className?: string;
	decorationColor?: Color;
	alt?: boolean;
}

const StatisticsCardGroup = ({ children, className, decorationColor, alt }: Props) => {
	return (
		<Card
			decoration={!decorationColor ? undefined : 'top'}
			decorationColor={decorationColor || 'gray'}
			className={`!p-0 divide-y !w-full divide-x flex items-center flex-wrap overflow-x-auto width-scrollbar ${className} ${alt ? '!divide-x-0 !divide-y-0' : ''}`}
		>
			{children}
		</Card>
	);
};

export default StatisticsCardGroup;
