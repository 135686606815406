import { Fragment } from 'react';
import { Badge, ListItem, Title } from '@tremor/react';
import { UserIcon } from 'components/FormActionIcons';
import { IGroupAppointmentsByUser } from './AppointmentsSection';

interface Props {
	userAppointments: IGroupAppointmentsByUser;
}

const PendingAppointmentRequestListItem = ({ userAppointments }: Props) => {
	const { user, appointments } = userAppointments;

	return (
		<Fragment>
			<ListItem>
				<div className='flex items-center gap-2 justify-between'>
					<div className='flex items-center justify-center shrink-0 w-10 h-10 rounded-full bg-primary-100 border'>
						<UserIcon variant='solid' className='text-primary-500 lg:h-6 lg:w-6' />
					</div>

					<Title className='!text-sm text-gray-700 capitalize'>
						{user.first_name} {user.middle_name} {user.last_name}
					</Title>
				</div>

				<Badge size='sm' color='gray' className='!font-bold bg-gray-200 border border-gray-300'>
					{appointments.length}
				</Badge>
			</ListItem>
		</Fragment>
	);
};

export default PendingAppointmentRequestListItem;
