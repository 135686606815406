import { Card, Text } from '@tremor/react';
import React from 'react';
import { RiLoader4Line } from 'react-icons/ri';

interface Props {
	message: string;
	containerClassName?: string;
}

const ContentLoadingCardSpinner = (props: Props) => {
	const { message, containerClassName } = props;

	return (
		<Card className={`flex-col justify-center flex items-center p-10 gap-4 ${containerClassName}`}>
			<RiLoader4Line className='w-8 h-8 animate animate-spin text-lg text-gray-400 ' />
			<Text className='text-lg'>{message}</Text>
		</Card>
	);
};

export default ContentLoadingCardSpinner;
