import { UserIcon } from 'components/FormActionIcons';
import { authState } from 'features/auth/authSlice';
import { useAppSelector } from 'hooks/storeConnect';
import { FiChevronDown } from 'react-icons/fi';
import { MenuTriggerState } from 'react-stately';

interface Props {
	triggerButtonState: MenuTriggerState;
}

const AppbarProfileMenu = (props: Props) => {
	const { triggerButtonState } = props;

	const { authUser } = useAppSelector(authState);

	return (
		<button className={`group flex items-center gap-2`}>
			<span className='flex items-center w-full gap-2 cursor-pointer select-none'>
				<span className='flex items-center justify-center bg-white border rounded-full w-9 h-9 shrink-0 bg-ash'>
					<UserIcon variant='light' className='w-5 h-5 text-gray-500' />
				</span>

				<div className='mt-2 text-left'>
					<h2 className='text-sm font-medium leading-3 capitalize line-clamp-1 text-ellipsis text-ash'>
						{authUser?.first_name} {authUser?.last_name}
					</h2>
					{authUser && <span className='text-xs capitalize text-ash line-clamp-1 text-ellipsis'>{authUser?.roles?.length > 0 ? authUser.roles[0] : ''}</span>}
				</div>
			</span>

			<span
				className={`flex h-5 w-5 shrink-0 items-center justify-center rounded-full border bg-gray-50 text-gray-400 duration-200 group-hover:bg-gray-100 ${
					triggerButtonState.isOpen ? '-rotate-180' : ''
				}`}
			>
				<FiChevronDown className='w-4 h-4' />
			</span>
		</button>
	);
};

export default AppbarProfileMenu;
