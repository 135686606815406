import withMap, { MapComponentProps } from 'components/withMap';
import React, { useEffect } from 'react';
import { LocationIcon } from 'components/FormActionIcons';
import { Text } from '@tremor/react';
import { Link } from 'react-router-dom';

interface Props extends MapComponentProps {
	locationInformation: ILocationInfo;
}

const PreviewProspectLocationMap = React.forwardRef((props: Props, ref) => {
	const { mapRef, locationInformation, setMapPosition, map } = props;

	const { coordinate, name, url } = locationInformation || {};

	useEffect(() => {
		if (coordinate) {
			setMapPosition?.(coordinate);
		}

		//  eslint-disable-next-line
	}, [coordinate]);

	return (
		<div className='w-full h-full relative'>
			<div className='w-full h-full relative' ref={mapRef} />

			{name && (
				<div className='absolute bottom-4 backdrop-blur-md bg-white/30 flex border-white/80 inset-x-4 shadow-xl border rounded-lg py-3 px-4'>
					<LocationIcon variant='solid' className='h-8 w-8 text-red-500' />

					<div>
						<Text className='!font-bold !text-xl flex items-center capitalize line-clamp-1 text-ellipsis'>{name}</Text>

						{url && (
							<Link to={url} className='text-xs uppercase font-light text-blue-500 underline' target='_blank'>
								Open in google map
							</Link>
						)}
					</div>
				</div>
			)}
		</div>
	);
});

export default withMap(PreviewProspectLocationMap);
