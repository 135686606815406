import { useQuery, RefetchOptions, RefetchQueryFilters, QueryObserverResult } from '@tanstack/react-query';
import { fetchUserProfile } from './authQueries';
import { IFetchUserProfileActionParams, IUser } from './authInterfaces';

interface ReturnType {
	user: IUser | undefined;
	isUserProfileLoading: boolean;
	refetchUserProfile: (options?: RefetchOptions & RefetchQueryFilters) => Promise<QueryObserverResult>;
}

interface Props {
	queryKey?: string;
	fetchParams?: IFetchUserProfileActionParams;
	enabled?: boolean;
	onSuccess?: (data: { [key: string]: IUser[] }) => void;
}

const useFetchUserProfile = ({ fetchParams, queryKey, enabled, onSuccess }: Props): ReturnType => {
	const { data, isLoading, refetch } = useQuery({
		queryKey: [queryKey ?? 'auth/fetch-user-profile', fetchParams],
		queryFn: () => fetchUserProfile(),
		enabled,
		onSuccess: onSuccess ? (data) => onSuccess?.(data?.data) : undefined,
	});

	const userProfileData = data?.data?.data;

	return { isUserProfileLoading: isLoading, user: userProfileData, refetchUserProfile: refetch };
};

export default useFetchUserProfile;
