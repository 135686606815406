import { Fragment } from 'react';
import { Tab, TabGroup, TabList } from '@tremor/react';
import { DefaultRoles } from 'features/settings/authorization/authorizationInterface';
import { hasRole, isAdmin } from 'helpers/index';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

const DashboardLayout = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	if (pathname === `/app/dashboard` && (isAdmin() || hasRole(DefaultRoles.Marketer) || hasRole(DefaultRoles.MarketingManager))) {
		return <Navigate to={'/app/dashboard/marketing'} />;
	}

	if (pathname === `/app/dashboard` && !isAdmin() && (hasRole(DefaultRoles.TransportOfficer) || hasRole(DefaultRoles.TransportManager))) {
		return <Navigate to={'/app/dashboard/transport'} />;
	}

	return (
		<div className='w-full h-full overflow-y-auto'>
			<TabGroup className='h-[calc(100vh-var(--appbar-height-mobile)] sm:h-[calc(100vh-var(--appbar-height-desktop))]'>
				<Fragment>
					{isAdmin() && (
						<div className='sticky h-[42px] top-0 inset-x-0 z-20 shadow bg-white w-full py-1 px-2 lg:px-4'>
							<TabList variant='solid'>
								<Tab className='!px-4' onClick={() => navigate('/app/dashboard/marketing')}>
									Marketing
								</Tab>
								<Tab className='!px-4' onClick={() => navigate('/app/dashboard/transport')}>
									Transport
								</Tab>
							</TabList>
						</div>
					)}

					<div className='relative z-10 w-full h-full'>
						<Outlet />
					</div>
				</Fragment>
			</TabGroup>
		</div>
	);
};

export default DashboardLayout;
