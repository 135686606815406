import _ from 'lodash';
import { Button, DateRangePicker, Text, Title } from '@tremor/react';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import Filters from 'components/Filters';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import { authState } from 'features/auth/authSlice';
import { IFetchFollowUpsActionParams, IFollowUp } from 'features/followups/followUpInterfaces';
import useFetchFollowUps from 'features/followups/useFetchFollowUps';
import { DefaultRoles } from 'features/settings/authorization/authorizationInterface';
import { FilterComponentTypes } from 'features/settings/settingsInterfaces';
import useFetchUsers from 'features/settings/users/useFetchUsers';
import { convertJSONToExcel, hasRole, hasRoles, isAdmin } from 'helpers/index';
import { useAppSelector } from 'hooks/storeConnect';
import React, { Fragment, useEffect, useState } from 'react';
import { RiFilter2Fill } from 'react-icons/ri';
import { buildURLFilters, getFollowUpState, isMarketer } from 'utils/index';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BottomSheet from 'components/BottomSheet';
import FollowUpsReportTable from './FollowUpsReportTable';
import { formatDate, formatDateRangePickerValue } from 'helpers/dateHelpers';
import { FollowUpCategories, FollowUpCategoriesIndexes } from './reportInterfaces';
import useFetchSalesStages from 'features/settings/prospecting/useFetchSalesStages';
import toast from 'react-hot-toast';
import { PermissionKeys } from 'features/auth/permissions';

const FollowUpsReportPage: React.FC = () => {
	const navigate = useNavigate();
	const { users } = useFetchUsers({});
	const { authUser } = useAppSelector(authState);
	const [urlParams, setUrlParams] = useSearchParams();
	const [followUpFetchParams, setFollowUpFetchParams] = useState<IFetchFollowUpsActionParams>({
		filterBy: {
			userId: isMarketer() ? authUser?.id : undefined,
		},
		include: ['prospects'],
	});
	const { followUps, isFollowUpsLoading } = useFetchFollowUps({
		fetchParams: followUpFetchParams,
		queryKey: followUpFetchParams,
		// enabled: hasPermission(PermissionKeys.VIEW_FOLLOW_UP),
	});
	const [selectedCategoryTabIndex, setSelectedCategoryTabIndex] = useState<FollowUpCategoriesIndexes>(FollowUpCategoriesIndexes.All);
	const [isMobileFiltersOpen, setMobileFiltersOpen] = useState<boolean>(false);
	const { salesStages } = useFetchSalesStages({});

	const pendingFollowUps = followUps?.filter((followUp) => getFollowUpState(followUp).isPending);
	const completedFollowUps = followUps?.filter((followUp) => getFollowUpState(followUp).isCompleted);
	const uncompletedFollowUps = followUps?.filter((followUp) => getFollowUpState(followUp).isUncompleted);

	// Manage url filters
	const dateRange = urlParams.get('dateRange') ?? '';
	const dateType = urlParams.get('dateType') ?? 'followUpDate';
	const userId = !isAdmin() && hasRole(DefaultRoles.Marketer) ? authUser?.id : urlParams.get('userId') ?? '';
	const saleStageId = urlParams.get('saleStageId') ?? '';
	const followUpStatus = urlParams.get('status') ?? FollowUpCategories.All;

	useEffect(() => {
		const urlFilters = dateType
			? {
					[dateType]: dateRange || '',
					userId: userId || '',
					saleStageId: saleStageId || '',
			  }
			: {
					userId: userId || '',
					saleStageId: saleStageId || '',
			  };

		let activeTabIndex: FollowUpCategoriesIndexes;

		switch (followUpStatus) {
			case FollowUpCategories.All:
				activeTabIndex = FollowUpCategoriesIndexes.All;
				break;
			case FollowUpCategories.Pending:
				activeTabIndex = FollowUpCategoriesIndexes.Pending;
				break;
			case FollowUpCategories.Completed:
				activeTabIndex = FollowUpCategoriesIndexes.Completed;
				break;
			case FollowUpCategories.Uncompleted:
				activeTabIndex = FollowUpCategoriesIndexes.Uncompleted;
				break;
			default:
				activeTabIndex = FollowUpCategoriesIndexes.All;
				break;
		}

		setSelectedCategoryTabIndex(activeTabIndex);
		setFollowUpFetchParams((prevValue) => ({
			...prevValue,
			filterBy: _.pickBy(urlFilters, _.identity) as any,
		}));

		// eslint-disable-next-line
	}, [dateRange, dateType, userId, followUpStatus, saleStageId]);

	const onSubmitFilters = (filters: any) => {
		const { dateRange, dateType, saleStageId } = filters;

		const hasDateFilter = filters.dateRange && filters.dateType;

		// Push new filters to url
		const urlFilters = {
			...filters,
			dateRange: hasDateFilter ? formatDateRangePickerValue(dateRange as any) : '',
			dateType: hasDateFilter ? dateType : '',
			saleStageId,
			status: followUpStatus,
		};

		navigate(`/app/reports/follow-ups?${buildURLFilters(_.pickBy(urlFilters, _.identity))}`);
	};

	const getNumberOfFollowUps = (selectedCategoryIndex: number): number => {
		let numberOfFollowUps = 0;

		switch (selectedCategoryIndex) {
			case FollowUpCategoriesIndexes.All:
				numberOfFollowUps = followUps?.length ?? 0;
				break;
			case FollowUpCategoriesIndexes.Pending:
				numberOfFollowUps = pendingFollowUps?.length ?? 0;
				break;
			case FollowUpCategoriesIndexes.Completed:
				numberOfFollowUps = completedFollowUps?.length ?? 0;
				break;
			case FollowUpCategoriesIndexes.Uncompleted:
				numberOfFollowUps = uncompletedFollowUps?.length ?? 0;
				break;
			default:
				numberOfFollowUps = followUps?.length ?? 0;
				break;
		}

		return numberOfFollowUps;
	};

	const exportReportToExcel = () => {
		let reports: { items: any[]; description: string } = { items: [], description: 'All Reminders' };

		switch (selectedCategoryTabIndex) {
			case FollowUpCategoriesIndexes.All:
				reports = { items: followUps ?? [], description: 'All Reminders' };
				break;
			case FollowUpCategoriesIndexes.Pending:
				reports = { items: pendingFollowUps ?? [], description: 'Pending Reminders' };

				break;
			case FollowUpCategoriesIndexes.Completed:
				reports = { items: completedFollowUps ?? [], description: 'Completed Reminders' };
				break;
			case FollowUpCategoriesIndexes.Uncompleted:
				reports = { items: uncompletedFollowUps ?? [], description: 'Uncompleted Reminders' };
				break;
			default:
				reports = { items: followUps ?? [], description: 'All Reminders' };
				break;
		}

		const { items, description } = reports;

		if (!items || items?.length === 0) return toast.error('No data to export. Please make sure your report has at least one record.');

		const sheetStructure = items.map((followUp: IFollowUp) => {
			const { prospect, follow_up_date, created_at, status, sales_stage, user } = followUp;

			console.log(followUp);

			return {
				'Company Name': prospect.company_name,
				Staff: `${user.first_name} ${user.middle_name || ''} ${user.last_name}`,
				Status: getFollowUpState(followUp).isPending ? 'Pending' : getFollowUpState(followUp).isCompleted ? 'Completed' : 'Uncompleted',
				'Follow Up Date': formatDate(follow_up_date, 'Jan 31, 1999'),
				'Last Interaction Date': formatDate(prospect.last_interaction_date, 'Jan 31, 1999'),
				'Insurance Products': prospect.insurance_products.map((product) => product.insurance_product_type.name).join(' | '),
				'Sale Stage': sales_stage.name,
				'Date Created': formatDate(created_at, 'Jan 31, 1999'),
			};
		});
		convertJSONToExcel(sheetStructure, description);
	};

	return (
		<div className='flex flex-col w-full overflow-hidden h-[calc(100vh-var(--appbar-height-mobile))] lg:h-[calc(100vh-var(--appbar-height-desktop))]'>
			<div className='h-[160px] px-4 py-4 bg-gray-100 border w-full flex items-start justify-between'>
				<div>
					<Title className='!font-bold text-gray-800'>Reminders</Title>
					<Text>Total reminders: {followUps && followUps?.length > 0 ? `${followUps.length}` : '0'}</Text>
				</div>

				<div className='inline-flex rounded-md shadow-sm ' role='group'>
					<button
						onClick={() => exportReportToExcel()}
						type='button'
						className='px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-sky-700 focus:z-10 focus:ring-2 focus:ring-sky-700 focus:text-sky-700'
					>
						Export To Excel
					</button>

					{/* <button
						type='button'
						className='px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-sky-700 focus:z-10 focus:ring-2 focus:ring-sky-700 focus:text-sky-700'
					>
						Download PDF
					</button> */}
				</div>
			</div>

			<div className='relative w-full h-[calc(100%-160px)] bg-white'>
				<div className='absolute -top-14 bottom-0 inset-x-0 mx-4 pb-4 h-[calc(100%+60px)] bg-white shadow-sm border rounded-t-xl overflow-hidden'>
					<div className='z-20 h-[55px] sticky inset-x-0 top-0 flex items-center justify-between px-4 lg:px-2 py-2 bg-white border-b'>
						<div className='flex items-center justify-between w-full'>
							<p className='text-sm font-semibold lg:hidden'>{getNumberOfFollowUps(selectedCategoryTabIndex)} record(s)</p>

							<Button
								color='gray'
								variant='secondary'
								onClick={() => setMobileFiltersOpen(true)}
								icon={RiFilter2Fill}
								className='!rounded-full lg:hidden hover:bg-gray-700 hover:!text-gray-100 duration-300'
							>
								Filters
							</Button>

							<div className='hidden lg:block'>
								<Filters
									filters={[
										{
											type: FilterComponentTypes.DateRangeWithSelect,
											label: 'Select Date',
											hideLabel: true,
											dateRange: {
												label: 'Date type',
												name: 'dateRange',
												placeholder: 'Select dates',
												type: FilterComponentTypes.DateRange,
												defaultValue: dateRange ? { from: new Date(dateRange.split(',')[0]), to: new Date(dateRange.split(',')[1]) } : undefined,
											},
											select: {
												label: 'Date type',
												name: 'dateType',
												type: FilterComponentTypes.Select,
												defaultValue: dateType || undefined,
												placeholder: 'Date Type',
												dropdownOptions: [
													{
														id: 1,
														label: 'Follow Up Date',
														value: 'followUpDate',
													},
												],
											},
										},
										{
											type: FilterComponentTypes.Select,
											name: 'userId',
											hideLabel: true,
											label: 'Staff',
											defaultValue: userId?.toString(),
											dropdownOptions:
												users
													?.filter((user) => {
														// return user.roles.includes(DefaultRoles.Marketer) || user.roles.includes(DefaultRoles.MarketingManager)
														return !user.roles.includes(DefaultRoles.SuperAdmin);
													})
													.map((user: any) => ({
														id: user.id,
														label: `${user.first_name} ${user?.middle_name ?? ' '} ${user.last_name}`,
														value: user.id,
													})) ?? [],
											placeholder: 'Select staff',
											permission: PermissionKeys.VIEW_USER_FILTERS,
										},
										{
											label: 'Sale Stage',
											name: 'saleStageId',
											hideLabel: true,
											type: FilterComponentTypes.Select,
											defaultValue: dateType || undefined,
											placeholder: 'Sale Stage',
											dropdownOptions:
												salesStages?.map((stage, index) => ({
													id: stage.id,
													label: stage.name,
													value: stage.id,
												})) ?? [],
										},
									]}
									submitButton={{
										className: `!bg-gray-800 hover:!bg-gray-600 text-white !capitalize border-0 duration-300`,
										label: 'Apply Filters',
										onSubmit: onSubmitFilters,
									}}
								/>
							</div>
						</div>
					</div>

					<div className='h-[calc(100%-40px)] overflow-x-hidden overflow-hidden w-full relative z-10'>
						<Fragment>
							{!followUps && isFollowUpsLoading ? (
								<div className='mt-6'>
									<ContentLoadingCardSpinner containerClassName='!shadow-none !border-0 !ring-0' message='Loading reminders...' />
								</div>
							) : followUps && followUps.length > 0 ? (
								<Fragment>
									<FollowUpsReportTable
										getNumberOfFollowUps={getNumberOfFollowUps}
										followUps={followUps}
										pendingFollowUps={pendingFollowUps}
										completedFollowUps={completedFollowUps}
										uncompletedFollowUps={uncompletedFollowUps}
										isFollowUpsLoading={isFollowUpsLoading}
										selectedCategoryIndex={selectedCategoryTabIndex}
										setSelectedCategoryIndex={setSelectedCategoryTabIndex}
									/>
								</Fragment>
							) : (
								<SimpleEmptyResourceIndicator containerClassName='lg:mt-8 lg:!py-10 !shadow-none !border-0 !ring-0' message='No reminder found' />
							)}
						</Fragment>
					</div>
				</div>
			</div>

			<BottomSheet
				isOpen={isMobileFiltersOpen}
				setOpen={setMobileFiltersOpen}
				closeOnOutSideClick
				header={{
					text: 'Filter Analytics',
				}}
			>
				<Filters
					filters={[
						{
							type: FilterComponentTypes.DateRangeWithSelect,
							label: 'Select Date',
							hideLabel: true,
							dateRange: {
								label: 'Date type',
								name: 'dateRange',
								placeholder: 'Select dates',
								type: FilterComponentTypes.DateRange,
								defaultValue: dateRange ? { from: new Date(dateRange.split(',')[0]), to: new Date(dateRange.split(',')[1]) } : undefined,
							},
							select: {
								label: 'Date type',
								name: 'dateType',
								type: FilterComponentTypes.Select,
								defaultValue: dateType || undefined,
								placeholder: 'Date Type',
								dropdownOptions: [
									{
										id: 1,
										label: 'Follow Up Date',
										value: 'followUpDate',
									},
								],
							},
						},
						{
							type: FilterComponentTypes.Select,
							name: 'userId',
							hideLabel: true,
							label: 'Staff',
							defaultValue: userId?.toString(),
							dropdownOptions:
								users
									?.filter((user) => {
										// return user.roles.includes(DefaultRoles.Marketer) || user.roles.includes(DefaultRoles.MarketingManager)
										return !user.roles.includes(DefaultRoles.SuperAdmin);
									})
									.map((user: any) => ({
										id: user.id,
										label: `${user.first_name} ${user?.middle_name ?? ' '} ${user.last_name}`,
										value: user.id,
									})) ?? [],
							placeholder: 'Select staff',
							permission: PermissionKeys.VIEW_USER_FILTERS,
						},
						{
							label: 'Sale Stage',
							name: 'saleStageId',
							hideLabel: true,
							type: FilterComponentTypes.Select,
							defaultValue: dateType || undefined,
							placeholder: 'Sale Stage',
							dropdownOptions:
								salesStages?.map((stage, index) => ({
									id: stage.id,
									label: stage.name,
									value: stage.id,
								})) ?? [],
						},
					]}
					submitButton={{
						className: `!bg-gray-800 hover:!bg-gray-600 text-white !capitalize border-0 duration-300`,
						label: 'Apply Filters',
						onSubmit: onSubmitFilters,
					}}
				/>
			</BottomSheet>
		</div>
	);
};

export default FollowUpsReportPage;
