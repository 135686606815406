import { useQuery, RefetchOptions, RefetchQueryFilters, QueryObserverResult } from '@tanstack/react-query';
import { fetchDecisionMakers } from '../prospectSettingsQueuries';
import { IFetchDecisionMakersActionParams, IDecisionMaker } from '../prospectSettingsInterfaces';

interface ReturnType {
	decisionMakers: IDecisionMaker[] | undefined;
	isDecisionMakersLoading: boolean;
	refetchDecisionMakers: (options?: RefetchOptions & RefetchQueryFilters) => Promise<QueryObserverResult>;
}

interface Props {
	queryKey?: string;
	fetchParams?: IFetchDecisionMakersActionParams;
	enabled?: boolean;
	onSuccess?: (decisionMakers: IDecisionMaker[]) => void;
}

const useFetchDecisionMakers = ({ fetchParams, queryKey, enabled, onSuccess }: Props): ReturnType => {
	const { data, isLoading, refetch } = useQuery({
		queryKey: [queryKey ?? 'auth/fetch-decisionMakers', fetchParams],
		queryFn: () => fetchDecisionMakers(),
		enabled,
		onSuccess: onSuccess ? (data) => onSuccess?.(data?.data) : undefined,
	});

	const decisionMakerData = data?.data?.data;

	return { isDecisionMakersLoading: isLoading, decisionMakers: decisionMakerData, refetchDecisionMakers: refetch };
};

export default useFetchDecisionMakers;
