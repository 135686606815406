import { Text } from '@tremor/react';
import Menu from 'components/Appbar/AppMobileNavigation/Menu';
import appNavigationMenu from 'components/Appbar/appNavigationMenu';
import { useState } from 'react';

const ReportsNavigationMenu = () => {
	const [activeMenu, setActiveMenu] = useState<string>('settings');

	const menu = appNavigationMenu.find((section) => section.title?.toLowerCase() === 'reports');

	if (!menu) return <div></div>;

	return (
		<div className='pb-4 w-fit lg:w-[var(--app-branding-width-lg)] h-full'>
			<Text className='relative px-4 py-4 mb-2 text-sm font-medium text-black after:bg-gray-200 after:absolute after:inset-x-0 after:bottom-0'>Reports</Text>

			<nav>
				<Menu activeMenu={activeMenu} links={menu.links} setActiveMenu={setActiveMenu} />
			</nav>
		</div>
	);
};

export default ReportsNavigationMenu;
