import { Button, TextInput, Title } from '@tremor/react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { processReactHookFormErrors } from 'helpers';
import { IDecisionMaker, IDecisionMakerFormValues } from '../prospectSettingsInterfaces';
import { createDecisionMaker, updateDecisionMaker } from '../prospectSettingsQueuries';
import InputWithAutocomplete from 'components/InputWithAutocomplete';

interface Props {
	decisionMakers: IDecisionMaker[] | undefined;
	decisionMaker: IDecisionMaker | null;
	refetchDecisionMakers: () => void;
	setCurrentDecisionMaker: (value: IDecisionMaker | null) => void;
	setEditDecisionMakerFormOpen: (value: boolean) => void;
}

const EditDecisionMakerForm = (props: Props) => {
	const { decisionMaker, decisionMakers, setCurrentDecisionMaker, setEditDecisionMakerFormOpen, refetchDecisionMakers } = props;

	const {
		register,
		handleSubmit,
		setError,
		setValue,
		clearErrors,
		formState: { errors },
	} = useForm<IDecisionMakerFormValues>({
		defaultValues: {
			name: decisionMaker?.name,
			description: decisionMaker?.description || '',
		},
	});

	// ============================
	// * Create Decision Maker
	// ============================
	const { isLoading: isCreateDecisionMakerLoading, mutate: createDecisionMakerMutation } = useMutation({
		mutationKey: ['authorization/create-decisionMaker'],
		mutationFn: (params: IDecisionMakerFormValues) => createDecisionMaker(params),
	});

	// ============================
	// * Update Decision Maker
	// ============================
	const { isLoading: isUpdateDecisionMakerLoading, mutate: updateDecisionMakerMutation } = useMutation({
		mutationKey: ['authorization/update-decisionMaker'],
		mutationFn: ({ formValues, decisionMakerId }: { formValues: IDecisionMakerFormValues; decisionMakerId: number }) => updateDecisionMaker({ formValues, decisionMakerId }),
	});

	const onSubmit = (formValues: IDecisionMakerFormValues) => {
		if (decisionMakers?.some((p) => p.name?.toLowerCase() === formValues.name?.toLowerCase())) return setError('name', { message: 'Decision maker already exists.' });

		if (decisionMaker)
			return updateDecisionMakerMutation(
				{ formValues, decisionMakerId: decisionMaker.id },
				{
					onSuccess: () => {
						toast.success('Decision maker updated successfully!');
						setTimeout(() => setCurrentDecisionMaker(null), 500);
						setEditDecisionMakerFormOpen(false);
						refetchDecisionMakers();
					},
					onError: (error) => {
						processReactHookFormErrors(error, 'Failed to update decision maker', setError);
					},
				}
			);

		createDecisionMakerMutation(formValues, {
			onSuccess: () => {
				toast.success('Decision maker created successfully!');
				setEditDecisionMakerFormOpen(false);
				refetchDecisionMakers();
			},
			onError: (error) => {
				processReactHookFormErrors(error, 'Failed to create decision maker', setError);
			},
		});
	};

	return (
		<form className='bg-white' onSubmit={handleSubmit(onSubmit)}>
			<header className={`${decisionMaker ? 'bg-gradient-to-r from-gray-800 to-gray-500' : 'bg-gradient-to-r from-primary-600 to-primary-300'} bg-dark p-6 h-[80px] flex items-center gap-2`}>
				<Title className='text-white'>{decisionMaker ? 'Update' : 'Create'} Decision Maker</Title>
			</header>

			<section className='p-6 space-y-6 text-left'>
				<div className='form-control'>
					<label htmlFor='name' className='required-field-indicator'>
						Name
					</label>

					<div>
						<InputWithAutocomplete
							type='text'
							id='name'
							placeholder='Enter business type name'
							onValueChange={(val: string) => {
								if (val && val !== 'No matches found') clearErrors('name');

								setValue('name', val);
							}}
							menu={decisionMakers ? (decisionMakers?.map((p) => p.name) as string[]) : []}
							{...register('name', {
								required: {
									value: true,
									message: 'Name of business type is required',
								},
							})}
							hasError={errors.name ? true : false}
							errorMessage={errors.name?.message}
						/>
						{/* <TextInput
							type='text'
							id='name'
							{...register('name', {
								required: {
									value: true,
									message: 'Name of decision maker is required',
								},
							})}
							placeholder='Enter decision maker name'
							error={errors.name ? true : false}
							errorMessage={errors.name?.message}
						/> */}
					</div>
				</div>

				<div className='form-control'>
					<label htmlFor='description'>Description</label>

					<div>
						<textarea id='description' {...register('description')} placeholder='Describe decision maker' />
						{errors?.description && <span className='mr-1 text-red-400 form-message'>{errors?.description?.message}</span>}
					</div>
				</div>
			</section>

			<footer className='flex items-center justify-end gap-2 p-6 bg-gray-100'>
				<Button
					onClick={() => {
						setEditDecisionMakerFormOpen(false);
						setTimeout(() => setCurrentDecisionMaker(null), 500);
					}}
					type='button'
					color='gray'
					variant='secondary'
					className='px-6 py-3 duration-300 !rounded-full'
				>
					Cancel
				</Button>

				<Button
					type='submit'
					loading={isCreateDecisionMakerLoading || isUpdateDecisionMakerLoading}
					loadingText={decisionMaker ? 'Updating' : 'Creating'}
					disabled={isCreateDecisionMakerLoading || isUpdateDecisionMakerLoading}
					color={decisionMaker ? 'gray' : undefined}
					className='px-6 py-3 duration-300 !rounded-full'
				>
					{decisionMaker ? 'Update Decision Maker' : 'Create Decision Maker'}
				</Button>
			</footer>
		</form>
	);
};

export default EditDecisionMakerForm;
