import { useForm } from 'react-hook-form';
import AuthBranding from 'components/AuthBranding';
import { IResetPasswordFormTypes } from './authInterfaces';
import { TextInput, Button } from '@tremor/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { resetPassword } from './authQueries';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { processReactHookFormErrors } from 'helpers/index';

const ResetPasswordPage = () => {
	const [searchParams] = useSearchParams();
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<IResetPasswordFormTypes>({});
	const navigate = useNavigate();

	const { isLoading: isResetPasswordLoading, mutate: resetPasswordMutation } = useMutation({
		mutationKey: ['auth/reset-password'],
		mutationFn: (formValues: IResetPasswordFormTypes) => resetPassword(formValues),
	});

	const onSubmit = (formValues: IResetPasswordFormTypes) => {
		formValues.email = formValues.email.trim();
		formValues.password = formValues.password.trim();
		formValues.confirm_password = formValues.confirm_password.trim();

		const { confirm_password, password } = formValues;

		if (password !== confirm_password) {
			setError('password', { message: 'Passwords do not match' });
			return setError('confirm_password', { message: 'Passwords do not match' });
		}

		const token = searchParams.get('token');

		const formDetails: any = {
			...formValues,
			token: token as string,
		};
		delete formDetails.confirm_password;

		resetPasswordMutation(formDetails, {
			onSuccess() {
				toast.success('Successfully updated your password');
				navigate('/auth/login');
			},
			onError(error) {
				processReactHookFormErrors(error, 'Failed to update password', setError);
			},
		});
	};

	if (errors.token?.message) toast.error(errors.token.message, { id: 'token-error' });

	return (
		<div id='login-page' className='w-full h-full'>
			<div className='h-full p-4 bg-gradient-to-br from-gray-200 to-primary-100 lg:p-8 lg:from-white lg:to-white lg:max-w-[400px] mx-auto'>
				{/* Branding */}
				<div>
					<AuthBranding />
				</div>

				<div className='mt-16 sm:mt-[10vh] flex flex-col items-center lg:items-start'>
					<h1 className='text-3xl font-bold text-gray-700 lg:font-semibold'>Reset Password</h1>
					<p className='mt-4 text-gray-500'>Please enter your new password credentials to change your password.</p>
				</div>

				<form onSubmit={handleSubmit(onSubmit)} className='flex flex-col items-center mt-10 lg:items-start'>
					<div className='space-y-4 w-11/12 sm:w-[400px]'>
						<div className='grid gap-1 form-control'>
							<label htmlFor='email' className='text-sm font-medium uppercase lg:font-normal lg:capitalize '>
								Email
							</label>
							<TextInput
								id='email'
								type='text'
								{...register('email', {
									pattern: {
										value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
										message: 'Invalid email.',
									},
								})}
								name='email'
								placeholder='Enter email'
								className='text-lg sm:h-[40px]'
								error={errors.email ? true : false}
								errorMessage={errors.email?.message}
							/>
						</div>

						<div className='grid gap-1 form-control'>
							<label htmlFor='password' className='text-sm font-medium uppercase lg:font-normal lg:capitalize'>
								New Password
							</label>

							<TextInput
								id='password'
								type='password'
								{...register('password')}
								name='password'
								placeholder='Enter new password'
								className='text-lg sm:h-[40px]'
								error={errors.email ? true : false}
								errorMessage={errors.email?.message}
							/>
						</div>

						<div className='grid gap-1 form-control'>
							<label htmlFor='confirm_password' className='text-sm font-medium uppercase lg:font-normal lg:capitalize'>
								Confirm Password
							</label>

							<TextInput
								id='confirm_password'
								type='password'
								{...register('confirm_password')}
								name='confirm_password'
								placeholder='Confirm new password'
								className='text-lg sm:h-[40px]'
								error={errors.email ? true : false}
								errorMessage={errors.email?.message}
							/>
						</div>
					</div>

					<div className='py-8 text-center lg:mt-5'>
						<Button size='xl' type='submit' loading={isResetPasswordLoading} loadingText='Changing Password' className='px-8 border-none !rounded-full uppercase bg-primary-500'>
							Change Password
						</Button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ResetPasswordPage;
