import { useMutation } from '@tanstack/react-query';
import { Button, Callout } from '@tremor/react';
import { logout } from 'features/auth/authQueries';
import { logout as storeLogout } from 'features/auth/authSlice';
import { useAppDispatch } from 'hooks/storeConnect';
import toast from 'react-hot-toast';
import { HiExclamation } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

const BlockedUsersPage = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { mutateAsync: logoutMutationAsync } = useMutation({
		mutationKey: ['auth/logout'],
		mutationFn: () => logout(),
		retry: 0,
	});

	const logoutUser = () => {
		toast.promise(
			logoutMutationAsync(),
			{
				loading: 'Logging out...',
				success: () => {
					dispatch(storeLogout());
					navigate('/auth/login');

					return 'Logged out successfully.';
				},
				error: () => {
					dispatch(storeLogout());
					navigate('/auth/login');

					return 'Logged out successfully.';
				},
			},
			{
				duration: 7000,
				position: 'top-center',
			}
		);
	};

	return (
		<div className='max-w-3xl py-10 mx-auto'>
			<div className='px-2 lg:px-4'>
				<Callout className='mt-4' title='Account Blocked' icon={HiExclamation} color='yellow'>
					You are will not be able to access your account temporarily for security reasons. Please contact your administrator for support.
				</Callout>
			</div>

			<p className='py-4 px-2 lg:px-4 mt-2 flex items-center justify-center'>
				<Button onClick={logoutUser} loadingText='Updating Password' className='border-none !rounded-full px-6 py-3 duration-300'>
					Logout
				</Button>
			</p>
		</div>
	);
};

export default BlockedUsersPage;
