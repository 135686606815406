import { useState } from 'react';
import Menu from './Menu';
import { VscChevronRight } from 'react-icons/vsc';
import { AppNavigationMenu } from 'features/settings/settingsInterfaces';

interface Props {
	activeMenu: string;
	setActiveMenu: (val: string) => void;
	section: AppNavigationMenu;
}

const SidebarSection = (props: Props) => {
	const { section, activeMenu, setActiveMenu } = props;
	const [isSectionOpen, setIsSectionOpen] = useState(section.title ? false : true);

	const countLinksAndMenus = (sidebarData: Props): number => {
		let totalLinks = 0;
		let totalMenus = 0;

		sidebarData.section.links.forEach((link: any) => {
			totalLinks++;

			if (link?.menu) {
				totalMenus += link.menu.length;
			}
		});

		return totalLinks + totalMenus;
	};

	return (
		<section>
			{/* Heading */}
			{section.title && (
				<div className='flex items-center justify-between gap-2 px-4 py-4 border-y border-y-primary-100 bg-primary-50'>
					<div>
						<h2 className='text-sm font-medium uppercase text-primary-700'>{section.title}</h2>
						{section.descriptions && <p className='text-xs text-ash/80'>{section.descriptions}</p>}
					</div>
					<button
						title='Expand/Collapse'
						onClick={() => setIsSectionOpen(!isSectionOpen)}
						className={`${isSectionOpen ? '-rotate-[270deg]' : 'rotate-0'} flex h-7 w-7 shrink-0 items-center justify-center rounded-full text-primary-500 duration-300 hover:bg-primary-100`}
					>
						<VscChevronRight className='w-4 h-4' />
					</button>
				</div>
			)}

			{/* Menu */}
			<div
				className={`h-full overflow-hidden duration-300`}
				style={{
					maxHeight: isSectionOpen ? countLinksAndMenus(props) * 40 + 'px' : '0px',
				}}
			>
				<Menu links={section.links} activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
			</div>
		</section>
	);
};

export default SidebarSection;
