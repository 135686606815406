import Container from 'components/Container';
import { useForm } from 'react-hook-form';
import { Button, Divider, TextInput, Title } from '@tremor/react';
import { useMutation } from '@tanstack/react-query';
import { logout, updateUserPassword } from 'features/auth/authQueries';
import toast from 'react-hot-toast';
import { IChangePasswordActionParams, IChangeProfilePasswordFormTypes, IUser } from 'features/auth/authInterfaces';
import { processReactHookFormErrors } from 'helpers/index';
import { logout as storeLogout } from 'features/auth/authSlice';
import { useAppDispatch } from 'hooks/storeConnect';
import { useNavigate } from 'react-router-dom';

const PasswordSettingsPage = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { mutateAsync: logoutMutationAsync } = useMutation({
		mutationKey: ['auth/logout'],
		mutationFn: () => logout(),
		retry: 0,
	});

	const {
		register,
		setError,
		handleSubmit,
		reset,
		formState: { errors, isDirty },
	} = useForm<IChangeProfilePasswordFormTypes>({});

	const { mutate: updateUserPasswordMutation, isLoading: isUpdateUserPasswordLoading } = useMutation({
		mutationKey: ['auth/update-user-password'],
		mutationFn: (formValues: IChangePasswordActionParams) => updateUserPassword(formValues),
	});

	const onSubmitProfile = (formValues: IChangeProfilePasswordFormTypes) => {
		formValues.new_password = formValues.new_password.trim();
		formValues.confirm_new_password = formValues.confirm_new_password.trim();

		const { new_password, confirm_new_password } = formValues;

		if (new_password !== confirm_new_password) {
			setError('new_password', { message: 'Passwords do not match' });
			return setError('confirm_new_password', { message: 'Passwords do not match' });
		}

		updateUserPasswordMutation(formValues, {
			onSuccess() {
				toast.success('Password updated successfully.', { position: 'top-center' });
				reset();
				logoutUser();
				// Todo: Find out why logging out after password update
			},
			onError(error) {
				processReactHookFormErrors(error, 'Password update failed', setError);
			},
		});

		return;
	};

	const logoutUser = () => {
		toast.promise(
			logoutMutationAsync(),
			{
				loading: 'Logging out...',
				success: () => {
					dispatch(storeLogout());
					navigate('/auth/login');

					return 'Logged out. Now proceed to login with the new password.';
				},
				error: () => {
					dispatch(storeLogout());
					navigate('/auth/login');

					return 'Logged out. Now proceed to login with the new password.';
				},
			},
			{
				duration: 7000,
				position: 'top-center',
			}
		);
	};

	return (
		<Container className='pt-4 pb-8 overflow-y-auto'>
			<div className='flex items-center justify-between'>
				<Title>Password Settings</Title>
			</div>

			<form onSubmit={handleSubmit(onSubmitProfile)}>
				<div className='mt-8 space-y-8'>
					<section className='grid lg:grid-cols-[180px,1fr]'>
						<label htmlFor='old_password' className='required-field-indicator'>
							Previous Password
						</label>

						<div>
							<TextInput
								type='password'
								id='previous-password'
								{...register('old_password', {
									required: {
										value: true,
										message: 'Previous password is required',
									},
								})}
								placeholder='Enter your previous password'
								error={errors.old_password ? true : false}
								errorMessage={errors.old_password?.message}
							/>
						</div>
					</section>

					<Divider />

					<section className='grid lg:grid-cols-[180px,1fr]'>
						<label htmlFor='new_password' className='required-field-indicator'>
							New Password
						</label>

						<div>
							<TextInput
								type='password'
								id='new_password'
								{...register('new_password', {
									required: {
										value: true,
										message: 'New password is required',
									},
								})}
								placeholder='Enter your new password'
								error={errors.new_password ? true : false}
								errorMessage={errors.new_password?.message}
							/>
						</div>
					</section>

					<Divider />

					<section className='grid lg:grid-cols-[180px,1fr]'>
						<label htmlFor='confirm_new_password' className='required-field-indicator'>
							Confirm New Password
						</label>

						<div>
							<TextInput
								type='password'
								id='confirm_new_password'
								{...register('confirm_new_password', {
									required: {
										value: true,
										message: 'Enter new password again',
									},
								})}
								placeholder='Enter new password again'
								error={errors.confirm_new_password ? true : false}
								errorMessage={errors.confirm_new_password?.message}
							/>
						</div>
					</section>

					<Divider />

					<div className='flex items-center lg:justify-end'>
						<Button
							type='submit'
							disabled={!isDirty}
							loading={isUpdateUserPasswordLoading}
							loadingText='Updating Password'
							className='border-none !rounded-full bg-green-500 hover:bg-green-600 px-6 py-3 duration-300'
						>
							Save Changes
						</Button>
					</div>
				</div>
			</form>
		</Container>
	);
};

export default PasswordSettingsPage;
