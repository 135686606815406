import { lsmsAPI } from 'ajax/httpClient';
import { ICreateProspectRequestPayload, IFetchProspectsActionParams, IUpdateProspectFormValues } from './prospectInterfaces';

export const fetchProspects = ({ filterBy, include, page, per_page }: IFetchProspectsActionParams) => {
	const { userId, companyName, createdAt, createdBetween, lastInteractionDate, contactDate, saleStageId, businessTypeId } = filterBy || {};

	return lsmsAPI.get('/prospects', {
		params: {
			'filter[company_name]': companyName,
			'filter[created_between]': createdBetween,
			'filter[last_interaction_date]': lastInteractionDate,
			'filter[contact_date]': contactDate,
			'filter[sales_stage_id]': saleStageId,
			'filter[business_type_id]': businessTypeId,
			'filter[created_at]': createdAt,
			'filter[user_id]': userId,
			include: include?.join(','),
			sort: '-created_at',
			page: page ?? 1,
			per_page: per_page ?? 10,
		},
	});
};

export const fetchSingleProspect = (prospectId: number) =>
	lsmsAPI.get(`/prospects/${prospectId}`, {
		params: {
			include: 'decision_maker,business_type,source_type,insurance_products,user,sales_stage,team_members',
		},
	});

export const createProspect = (formValues: ICreateProspectRequestPayload) => lsmsAPI.post('/prospects', formValues);

export const updateProspect = ({ formValues, prospectId }: { formValues: IUpdateProspectFormValues; prospectId: number }) => lsmsAPI.put(`/prospects/${prospectId}`, formValues);

export const deleteProspect = (prospectId: number) => lsmsAPI.delete(`/prospects/${prospectId}`);
