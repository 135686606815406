import Appbar from 'components/Appbar';
import { authState } from 'features/auth/authSlice';
import { useAppSelector } from 'hooks/storeConnect';
import { Navigate, Outlet } from 'react-router-dom';

const AccountIssuesLayout = () => {
	const { isAuthenticated } = useAppSelector(authState);

	if (!isAuthenticated) {
		return <Navigate to='/auth/login' />;
	}

	return (
		<div className={`relative w-screen h-screen overflow-hidden duration-300`}>
			<div className='h-[calc(var(--appbar-height-mobile))] sm:h-[calc(var(--appbar-height-desktop))] border-b border-gray-50'>
				<Appbar isMenuOpen={false} setMenuOpen={() => null} />
			</div>

			<Outlet />
		</div>
	);
};

export default AccountIssuesLayout;
