import { useQuery, RefetchOptions, RefetchQueryFilters, QueryObserverResult } from '@tanstack/react-query';
import { IFetchVehicleAllocationsActionParams, IVehicleAllocation } from '../navigateInterfaces';
import { fetchVehicleAllocations } from './vehicleAllocationQueries';

interface ReturnType {
	vehicleAllocations: IVehicleAllocation[] | undefined;
	isVehicleAllocationsLoading: boolean;
	refetchVehicleAllocations: (options?: RefetchOptions & RefetchQueryFilters) => Promise<QueryObserverResult>;
	paginationMetadata: APIPaginationMetadata;
}

interface Props {
	queryKey?: any;
	fetchParams?: IFetchVehicleAllocationsActionParams;
	enabled?: boolean;
	onSuccess?: (vehicleAllocations: IVehicleAllocation[]) => void;
}

const useFetchVehicleAllocations = ({ fetchParams, queryKey, enabled, onSuccess }: Props): ReturnType => {
	const { filterBy, includes } = fetchParams || {};

	const { data, isLoading, refetch } = useQuery({
		queryKey: [queryKey ?? 'vehicleAllocations/fetch-vehicleAllocations', fetchParams],
		queryFn: () => fetchVehicleAllocations({ filterBy, includes }),
		enabled,
		onSuccess: onSuccess ? (data) => onSuccess?.(data?.data) : undefined,
	});

	const vehicleAllocations = data?.data?.data;
	const pagination = vehicleAllocations?.meta;

	return { isVehicleAllocationsLoading: isLoading, vehicleAllocations, paginationMetadata: pagination, refetchVehicleAllocations: refetch };
};

export default useFetchVehicleAllocations;
