import { useQuery, RefetchOptions, RefetchQueryFilters, QueryObserverResult } from '@tanstack/react-query';
import { IFetchVehiclesActionParams, IVehicle } from '../navigateInterfaces';
import { fetchVehicles } from './vehicleQueries';

interface ReturnType {
	vehicles: IVehicle[] | undefined;
	isVehiclesLoading: boolean;
	refetchVehicles: (options?: RefetchOptions & RefetchQueryFilters) => Promise<QueryObserverResult>;
	paginationMetadata: APIPaginationMetadata;
}

interface Props {
	queryKey?: any;
	fetchParams?: IFetchVehiclesActionParams;
	enabled?: boolean;
	onSuccess?: (vehicles: IVehicle[]) => void;
}

const useFetchVehicles = ({ fetchParams, queryKey, enabled, onSuccess }: Props): ReturnType => {
	const { filterBy, includes } = fetchParams || {};

	const { data, isLoading, refetch } = useQuery({
		queryKey: [queryKey ?? 'vehicles/fetch-vehicles', fetchParams],
		queryFn: () => fetchVehicles({ filterBy, includes }),
		enabled,
		onSuccess: onSuccess ? (data) => onSuccess?.(data?.data) : undefined,
	});

	const vehicles = data?.data?.data;
	const pagination = vehicles?.meta;

	return { isVehiclesLoading: isLoading, vehicles, paginationMetadata: pagination, refetchVehicles: refetch };
};

export default useFetchVehicles;
