import { Metric, Tab, TabGroup, TabList } from '@tremor/react';
import { useEffect, useState } from 'react';
import { BsPersonVcard, BsPersonVcardFill } from 'react-icons/bs';
import { HiKey, HiOutlineKey } from 'react-icons/hi2';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

const ROLES_TAB_INDEX = 0;
const PERMISSIONS_TAB_INDEX = 1;

const AuthorizationLayout = () => {
	const { pathname } = useLocation();

	const [selectedTabIndex, setSelectedTabIndex] = useState(pathname.includes('roles') ? ROLES_TAB_INDEX : PERMISSIONS_TAB_INDEX);

	const navigate = useNavigate();

	const setActiveTabIndex = () => setSelectedTabIndex(pathname.includes('roles') ? ROLES_TAB_INDEX : PERMISSIONS_TAB_INDEX);

	useEffect(() => {
		setActiveTabIndex();

		// eslint-disable-next-line
	}, [pathname]);

	const handleTabIndexChange = (index: number) => {
		setSelectedTabIndex(index);

		if (index === ROLES_TAB_INDEX) navigate('/app/settings/admin/authorization/roles');
		if (index === PERMISSIONS_TAB_INDEX) navigate('/app/settings/admin/authorization/permissions');
	};

	if (pathname === '/app/settings/admin/authorization') return <Navigate to={'/app/settings/admin/authorization/roles'} />;

	return (
		<div className='w-full h-full'>
			<Metric className='mt-6 px-4'>Authorization</Metric>

			<TabGroup tabIndex={selectedTabIndex} onIndexChange={handleTabIndexChange}>
				<TabList className='mt-4 px-4'>
					<Tab value={'roles'} icon={selectedTabIndex === ROLES_TAB_INDEX ? BsPersonVcardFill : BsPersonVcard}>
						Roles
					</Tab>
					<Tab value={'permissions'} icon={selectedTabIndex === PERMISSIONS_TAB_INDEX ? HiKey : HiOutlineKey}>
						Permissions
					</Tab>
				</TabList>

				<div className='h-[calc(100vh-95px)] pt-4 overflow-hidden overflow-y-auto'>
					<Outlet />
				</div>
			</TabGroup>
		</div>
	);
};

export default AuthorizationLayout;
