import { useQuery, RefetchOptions, RefetchQueryFilters, QueryObserverResult } from '@tanstack/react-query';
import { fetchDepartments } from 'features/settings/departments/departmentQueries';
import { IDepartment, IFetchDepartmentsActionParams } from './departmentInterfaces';

interface ReturnType {
	departments: IDepartment[] | undefined;
	isDepartmentsLoading: boolean;
	isDepartmentsFetching: boolean;
	refetchDepartments: (options?: RefetchOptions & RefetchQueryFilters) => Promise<QueryObserverResult>;
	paginationMetadata: APIPaginationMetadata;
}

interface Props {
	queryKey?: any;
	fetchParams?: IFetchDepartmentsActionParams;
	enabled?: boolean;
	onSuccess?: (departments: IDepartment[]) => void;
}

const useFetchDepartments = ({ fetchParams, queryKey, enabled, onSuccess }: Props): ReturnType => {
	const { data, isLoading, refetch, isFetching } = useQuery({
		queryKey: ['departments/fetch-departments', queryKey, fetchParams],
		queryFn: () => fetchDepartments(fetchParams ?? {}),
		enabled,
		onSuccess: onSuccess ? (data) => onSuccess?.(data?.data) : undefined,
	});

	const departmentData = data?.data?.data;
	const pagination = data?.data?.meta;

	return { isDepartmentsLoading: isLoading, isDepartmentsFetching: isFetching, departments: departmentData, paginationMetadata: pagination, refetchDepartments: refetch };
};

export default useFetchDepartments;
