import { useQuery, RefetchOptions, RefetchQueryFilters, QueryObserverResult } from '@tanstack/react-query';
import { IFetchUserAccountsActionParams, IUser } from 'features/auth/authInterfaces';
import { fetchUsers } from 'features/auth/authQueries';

interface ReturnType {
	users: IUser[] | undefined;
	isUsersLoading: boolean;
	isUsersFetching: boolean;
	refetchUsers: (options?: RefetchOptions & RefetchQueryFilters) => Promise<QueryObserverResult>;
	paginationMetadata: APIPaginationMetadata;
}

interface Props {
	queryKey?: any;
	fetchParams?: IFetchUserAccountsActionParams;
	enabled?: boolean;
	onSuccess?: (users: IUser[]) => void;
}

const useFetchUsers = ({ fetchParams, queryKey, enabled, onSuccess }: Props): ReturnType => {
	const { data, isLoading, isFetching, refetch } = useQuery({
		queryKey: [queryKey ?? 'auth/fetch-users', fetchParams],
		queryFn: () => fetchUsers(fetchParams ?? {}),
		enabled,
		onSuccess: onSuccess ? (data) => onSuccess?.(data?.data) : undefined,
	});

	const usersData = data?.data?.data;
	const users = usersData?.data;
	const pagination = usersData?.meta;

	return { isUsersLoading: isLoading, isUsersFetching: isFetching, users, paginationMetadata: pagination, refetchUsers: refetch };
};

export default useFetchUsers;
