import { lsmsAPI } from 'ajax/httpClient';
import { ICreateInteractionFormValues, IFetchInteractionsActionParams } from './interactionInterfaces';

export const fetchInteractions = (params: IFetchInteractionsActionParams) => {
	const { filterBy, includes, page } = params || {};
	const { createdBetween, saleStageId, prospectId, interactionTypeId, userId } = filterBy || {};

	return lsmsAPI.get('/prospect-interactions', {
		params: {
			'filter[created_between]': createdBetween,
			'filter[prospect_id]': prospectId,
			'filter[sales_stage.id]': saleStageId,
			'filter[interaction_type_id]': interactionTypeId,
			'filter[user_id]': userId,
			include: includes?.join(','),
			page: page ?? 1,
			per_page: 100_000_000_000,
			sort: '-created_at',
		},
	});
};

export const createInteraction = (formValues: ICreateInteractionFormValues) =>
	lsmsAPI.post('/prospect-interactions', formValues, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

export const updateInteraction = ({ formValues, interactionId }: { formValues: ICreateInteractionFormValues; interactionId: number }) => {
	const payload: any = {
		...formValues,
		_method: 'PUT',
	};

	return lsmsAPI.put(`/prospect-interactions/${interactionId}`, payload, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};

export const deleteInteraction = (interactionId: number) => lsmsAPI.delete(`/prospect-interactions/${interactionId}`);
