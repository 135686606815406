import { Title, Text, Divider } from '@tremor/react';
import useFetchPermissions from './useFetchPermissions';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';

const PermissionsPage = () => {
	const { permissions, isPermissionsLoading } = useFetchPermissions({});

	return (
		<div className='px-2 lg:px-4'>
			<div className='flex items-center justify-between'>
				<Title>Permissions</Title>
			</div>

			<section>
				{isPermissionsLoading ? (
					<ContentLoadingCardSpinner message='Loading permissions...' />
				) : permissions && Object.keys(permissions).length > 0 ? (
					<div className='mt-4'>
						{Object.keys(permissions).map((permissiosnGroupName, index) => {
							const isLastIItem = index === Object.keys(permissions).length - 1;

							return (
								<div key={index} className='mb-4'>
									<Text className='font-semibold text-black !text-[14px] capitalize'>{permissiosnGroupName}</Text>

									<div className='flex justify-start gap-x-6 gap-y-2 flex-wrap mt-2'>
										{permissions[permissiosnGroupName].map((permission, index) => (
											<div key={index} className='flex items-center text-gray-500 gap-2 before:inline-block before:w-1.5 before:h-1.5 before:rounded-full before:bg-gray-400'>
												<Text className='capitalize'>{permission.name}</Text>
											</div>
										))}
									</div>

									{!isLastIItem && <Divider className='mt-6' />}
								</div>
							);
						})}
					</div>
				) : (
					<SimpleEmptyResourceIndicator message='No sales permission found' />
				)}
			</section>
		</div>
	);
};

export default PermissionsPage;
