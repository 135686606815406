import {useEffect} from "react";
import useFetchUserProfile from "features/auth/useFetchUserProfile";
import {useAppDispatch} from "../../hooks/storeConnect";
import {updateUserProfile} from "features/auth/authSlice";

const useUpdateUserProfile = () => {
    const dispatch = useAppDispatch();
    const {  user } = useFetchUserProfile({});


    useEffect(() => {

        if(!user) return;

        dispatch(updateUserProfile(user))

        //     eslint-disable-next-line
    }, [user]);
}

export default useUpdateUserProfile;