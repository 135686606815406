import { AppNavigationMenu } from 'features/settings/settingsInterfaces';
import { LiaCarSolid } from 'react-icons/lia';
import { TbLayoutDashboard, TbReportAnalytics } from 'react-icons/tb';
import { HiOutlineKey, HiOutlineShieldCheck } from 'react-icons/hi2';
import { FaChartSimple, FaChartPie } from 'react-icons/fa6';
import { RiUserLine, RiUserSearchLine } from 'react-icons/ri';
import { GoPeople } from 'react-icons/go';
import { BiBuilding, BiBuildingHouse } from 'react-icons/bi';
import { hasPermission, hasRole, hasRoles, isAdmin, isNewUser, isSuperAdmin } from 'helpers/index';
import { DefaultRoles } from 'features/settings/authorization/authorizationInterface';
import { PermissionKeys } from 'features/auth/permissions';

const appNavigationMenu: AppNavigationMenu[] = [
	{
		// title: 'Section Title',
		// descriptions: 'Section description',
		id: 'main-app-links',
		links: [
			{
				name: 'Dashboard',
				description: '',
				url: '/app/dashboard',
				icon: <TbLayoutDashboard className='w-full h-full' />,
			},
			hasRoles([DefaultRoles.Marketer, DefaultRoles.MarketingManager, DefaultRoles.Admin])
				? {
						name: 'Prospects',
						description: '',
						url: '/app/prospects',
						id: 'prospects',
						icon: <RiUserSearchLine className='w-full h-full' />,
						menu: [
							{
								type: 'link',
								name: 'Prospects',
								url: '/app/prospects/list',
							},
							{
								type: 'link',
								name: 'Appointments',
								url: '/app/prospects/appointments',
							},
						],
				  }
				: null,
			hasRoles([DefaultRoles.TransportManager, DefaultRoles.MarketingManager, DefaultRoles.Admin])
				? {
						name: 'Navigate',
						description: '',
						url: '/app/navigate',
						icon: <LiaCarSolid className='w-full h-full' />,
						menu: [
							{
								type: 'link',
								name: 'Vehicle Requests',
								url: '/app/navigate/vehicle-requests',
							},
							hasPermission(PermissionKeys.VIEW_VEHICLE_ALLOCATION)
								? {
										type: 'link',
										name: 'Staff Locations',
										url: '/app/navigate/staff-locations',
								  }
								: null,
							hasPermission(PermissionKeys.VIEW_VEHICLE_AND_DRIVE_STATISTICS)
								? {
										type: 'link',
										name: hasPermission(PermissionKeys.VIEW_VEHICLE) ? 'Vehicles and Drivers' : 'Drivers',
										url: '/app/navigate/vehicle-and-drivers',
								  }
								: null,
						],
				  }
				: hasRole(DefaultRoles.TransportOfficer)
				? {
						name: 'Navigate',
						description: '',
						url: '/app/navigate/assigned-vehicle-requests',
						icon: <LiaCarSolid className='w-full h-full' />,
				  }
				: hasRole(DefaultRoles.Marketer)
				? {
						name: 'Navigate',
						description: '',
						url: '/app/navigate/vehicle-requests',
						icon: <LiaCarSolid className='w-full h-full' />,
				  }
				: null,
			hasRoles([DefaultRoles.TransportManager, DefaultRoles.Admin, DefaultRoles.Marketer, DefaultRoles.MarketingManager])
				? {
						name: 'Reports',
						description: '',
						url: '/app/reports',
						icon: <TbReportAnalytics className='w-full h-full' />,
				  }
				: null,
		],
	},
	{
		title: 'Settings',
		id: 'settings',
		descriptions: 'Update your profile, password and administer user accounts',
		links: [
			{
				name: 'Profile Details',
				icon: <RiUserLine className='w-full h-full' />,
				description: ' ',
				url: '/app/settings/profile',
			},
			{
				name: 'Password Settings',
				icon: <HiOutlineKey className='w-full h-full' />,
				description: ' ',
				url: '/app/settings/password',
			},
			isSuperAdmin() && !isNewUser()
				? {
						name: 'User Authorization',
						icon: <HiOutlineShieldCheck className='w-full h-full' />,
						description: ' ',
						url: '/app/settings/admin/authorization',
						menu: [
							{
								type: 'link',
								name: 'Roles',
								url: '/app/settings/admin/authorization/roles',
							},
							{
								type: 'link',
								name: 'Permissions',
								url: '/app/settings/admin/authorization/permissions',
							},
						],
				  }
				: null,
			isAdmin() && !isNewUser()
				? {
						name: 'Prospecting',
						id: 'prospecting-settings',
						icon: <RiUserSearchLine className='w-full h-full' />,
						description: ' ',
						url: '/app/settings/admin/prospecting',
						menu: [
							{
								type: 'link',
								name: 'Sales Stages',
								url: '/app/settings/admin/prospecting/sales-stages',
							},
							{
								type: 'link',
								name: 'Insurance Products',
								url: '/app/settings/admin/prospecting/insurance-products',
							},
							{
								type: 'link',
								name: 'Decision Makers',
								url: '/app/settings/admin/prospecting/decision-makers',
							},
							{
								type: 'link',
								name: 'Source Types',
								url: '/app/settings/admin/prospecting/source-types',
							},
							{
								type: 'link',
								name: 'Interaction Types',
								url: '/app/settings/admin/prospecting/interaction-types',
							},
							{
								type: 'link',
								name: 'Prospect Business Type',
								url: '/app/settings/admin/prospecting/prospect-business-types',
							},
						],
				  }
				: null,
			isAdmin() && !isNewUser()
				? {
						name: 'Branches',
						icon: <BiBuildingHouse className='w-full h-full' />,
						description: ' ',
						url: '/app/settings/admin/branches',
				  }
				: null,
			isAdmin() && !isNewUser()
				? {
						name: 'Departments',
						icon: <BiBuilding className='w-full h-full' />,
						description: ' ',
						url: '/app/settings/admin/departments',
				  }
				: null,
			isSuperAdmin() && !isNewUser()
				? {
						name: 'User Accounts',
						icon: <GoPeople className='w-full h-full' />,
						description: ' ',
						url: '/app/settings/admin/user-accounts',
				  }
				: null,
		],
	},
	{
		title: 'Reports',
		id: 'reports',
		descriptions: 'Get analytics, and full report from data generated from the system',
		links: [
			{
				name: 'Analytics',
				icon: <FaChartPie className='w-full h-full' />,
				description: ' ',
				url: '/app/reports/analytics',
			},
			{
				name: 'Prospects',
				icon: <FaChartSimple className='w-full h-full rotate-90' />,
				description: ' ',
				url: '/app/reports/prospects',
			},
			{
				name: 'Follow Ups',
				icon: <FaChartSimple className='w-full h-full rotate-90' />,
				description: ' ',
				url: '/app/reports/interactions',
			},
			{
				name: 'Prospective Clients',
				icon: <FaChartSimple className='w-full h-full rotate-90' />,
				description: ' ',
				url: '/app/reports/prospective-clients',
			},
			{
				name: 'Yearly Targets',
				icon: <FaChartSimple className='w-full h-full rotate-90' />,
				description: ' ',
				url: '/app/reports/yearly-targets',
			},
			{
				name: 'Reminders',
				icon: <FaChartSimple className='w-full h-full rotate-90' />,
				description: ' ',
				url: '/app/reports/follow-ups',
			},
			{
				name: 'Staff Closing Percentage',
				icon: <FaChartSimple className='w-full h-full rotate-90' />,
				description: ' ',
				url: '/app/reports/staff-closing-percentage',
			},
			{
				name: 'Expected Revenue',
				icon: <FaChartSimple className='w-full h-full rotate-90' />,
				description: ' ',
				url: '/app/reports/expected-revenue',
			},
			{
				name: 'Achieved Revenue',
				icon: <FaChartSimple className='w-full h-full rotate-90' />,
				description: ' ',
				url: '/app/reports/achieved-revenue',
			},
			{
				name: 'Earnings Per Account',
				icon: <FaChartSimple className='w-full h-full rotate-90' />,
				description: ' ',
				url: '/app/reports/earnings-per-account',
			},
			{
				name: 'Won Prospects',
				icon: <FaChartSimple className='w-full h-full rotate-90' />,
				description: ' ',
				url: '/app/reports/won-prospects',
			},
			{
				name: 'Prospects Per Sales Stage',
				icon: <FaChartSimple className='w-full h-full rotate-90' />,
				description: ' ',
				url: '/app/reports/sales-stages',
			},
			{
				name: 'Prospects Per Business Types',
				icon: <FaChartSimple className='w-full h-full rotate-90' />,
				description: ' ',
				url: '/app/reports/business-types',
			},

			// =================

			// {
			// 	name: 'Number of Won Prospects',
			// 	icon: <FaChartSimple className='w-full h-full rotate-90' />,
			// 	description: ' ',
			// 	url: '/app/reports/number-of-won-prospects',
			// },
			// {
			// 	name: 'Number of Won Opportunities',
			// 	icon: <FaChartSimple className='w-full h-full rotate-90' />,
			// 	description: ' ',
			// 	url: '/app/reports/number-of-won-opportunities',
			// },
		],
	},
];

export default appNavigationMenu;
