import { lsmsAPI } from 'ajax/httpClient';
import { IFetchReportActionParams } from './reportInterfaces';

export const fetchReports = (params: IFetchReportActionParams) => {
	const { createdBetween, userId } = params.filterBy || {};

	return lsmsAPI.get('/reports/marketing', {
		params: {
			'filter[created_between]': createdBetween,
			'filter[user_id]': userId,
		},
	});
};
