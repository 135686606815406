import { useState } from 'react';
import OutsideClickHandler from 'components/OutsideClickHandler';
import SidebarSection from './SidebarSection';
import appNavigationMenu from '../appNavigationMenu';

interface Props {
	isMenuOpen: boolean;
	setMenuOpen: (val: boolean) => void;
}

const AppMobileNavigation = (props: Props) => {
	const { isMenuOpen, setMenuOpen } = props;
	const [activeMenu, setActiveMenu] = useState<string>('products');

	const toggleActiveMenu = (selectedMenuItem: string) => {
		if (!selectedMenuItem) return;

		if (selectedMenuItem === activeMenu) {
			return setActiveMenu('');
		}

		setActiveMenu(selectedMenuItem);
	};

	return (
		<div className='lg:hidden '>
			<OutsideClickHandler onOutsideClick={() => setMenuOpen(false)}>
				<div
					className={`${
						isMenuOpen ? 'translate-x-0' : '-translate-x-[var(--sidebar-width)]'
					} w-[var(--sidebar-width)] h-screen border-r overflow-hidden fixed inset-y-0 left-0 bg-white border-l duration-300`}
				>
					<div className='relative w-full h-full'>
						<h1 className='px-4 text-lg font-medium text-gray-500 h-[calc(var(--appbar-height-mobile)+1px)] sm:h-[calc(var(--appbar-height-desktop)+1px)] bg-white sticky top-0 inset-x-0 items-center flex uppercase border-b'>
							Menu
						</h1>

						<div className='h-full w-full bg-[#f8fafc]'>
							<div
								className='with-scrollbar relative h-[calc(100%-60px)]'
								style={{
									overflow: 'overlay',
								}}
							>
								{appNavigationMenu.map((group, index) => {
									return <SidebarSection activeMenu={activeMenu} setActiveMenu={toggleActiveMenu} key={index} section={group} />;
								})}
							</div>
						</div>
					</div>
				</div>
			</OutsideClickHandler>
		</div>
	);
};

export default AppMobileNavigation;
