import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { IAppointment, ICreateAppointmentFormValues } from './appointments/appointmentInterfaces';
import { Button, DatePicker, Select, SelectItem, Title } from '@tremor/react';
import { useMutation } from '@tanstack/react-query';
import { createAppointment, updateAppointment } from './appointments/appointmentQueries';
import useFetchProspects from './prospects/useFetchProspects';
import { PiBuildingsFill } from 'react-icons/pi';
import { formatDate } from 'helpers/dateHelpers';
import { IProspect } from './prospects/prospectInterfaces';
import SpaceFillerCard from 'components/SpaceFillerCard';
import toast from 'react-hot-toast';
import { hasRoles, isAdmin, processReactHookFormErrors } from 'helpers/index';
import { useAppSelector } from 'hooks/storeConnect';
import { authState } from './auth/authSlice';
import { DefaultRoles } from './settings/authorization/authorizationInterface';
import { isMarketer } from 'utils/index';

interface Props {
	appointment: IAppointment | null;
	prospect?: IProspect;
	afterEdit?: () => void;
}

const ScheduleAppointmentForm = (props: Props) => {
	const { authUser } = useAppSelector(authState);

	const { appointment, afterEdit, prospect } = props;
	const [selectFields, setSelectFields] = useState<ICreateAppointmentFormValues>({
		prospect_id: prospect ? prospect.id : appointment ? appointment?.prospect_id : undefined,
		appointment_date: appointment?.appointment_date,
	} as any);

	const { prospects } = useFetchProspects({
		fetchParams: {
			filterBy: {
				userId: isMarketer() ? authUser?.id : undefined,
			},
			per_page: 100_000_000_000_000,
		},
		queryKey: 'prospects-dropdown',
	});

	// ========================
	// * Create Appointment
	// ========================
	const { isLoading: isCreateAppointmentLoading, mutate: createAppointmentMutation } = useMutation({
		mutationKey: ['appointments/create-appointment'],
		mutationFn: (params: ICreateAppointmentFormValues) => createAppointment(params),
	});

	// =========================
	// * Update Appointment
	// =========================
	const { isLoading: isUpdateAppointmentLoading, mutate: updateAppointmentMutation } = useMutation({
		mutationKey: ['appointments/update-appointment'],
		mutationFn: ({ formValues, appointmentId }: { formValues: ICreateAppointmentFormValues; appointmentId: number }) => updateAppointment({ formValues, appointmentId }),
	});

	const {
		handleSubmit,
		setError,
		setValue,
		clearErrors,
		formState: { errors },
	} = useForm<ICreateAppointmentFormValues>({
		defaultValues: {
			prospect_id: prospect ? prospect.id : appointment ? appointment?.prospect_id : undefined,
			appointment_date: appointment?.appointment_date as any,
		},
	});

	const onSubmit = (formValues: ICreateAppointmentFormValues) => {
		const { appointment_date, prospect_id } = formValues;
		if (appointment_date === null || appointment_date === undefined) {
			return setError('appointment_date', { message: 'Appointment date is required' });
		} else if (prospect_id === null || prospect_id === undefined) {
			return setError('prospect_id', { message: 'Prospect is required' });
		}

		if (appointment)
			return updateAppointmentMutation(
				{ formValues, appointmentId: appointment.id },
				{
					onSuccess: () => {
						toast.success('Appointment rescheduled successfully!');
						afterEdit?.();
					},
					onError: (error) => {
						processReactHookFormErrors(error, 'Failed to reschedule appointment', setError);
					},
				}
			);

		createAppointmentMutation(formValues, {
			onSuccess: () => {
				toast.success('Appointment scheduled successfully!');
				afterEdit?.();
			},
			onError: (error) => {
				processReactHookFormErrors(error, 'Failed to schedule appointment', setError);
			},
		});
	};

	return (
		<form className='bg-white' onSubmit={handleSubmit(onSubmit)}>
			<header className={`${appointment ? 'bg-gradient-to-r from-gray-800 to-gray-500' : 'bg-gradient-to-r from-primary-600 to-primary-300'} bg-dark p-6 h-[80px] flex items-center gap-2`}>
				<Title className='text-white'>{appointment ? 'Reschedule' : 'Schedule'} Appointment</Title>
			</header>

			<section className='p-4 space-y-2 text-left'>
				<div className='w-full form-control'>
					<label htmlFor='prospect_id' className='required-field-indicator'>
						Prospect
					</label>

					<div>
						<Select
							id='prospect_id'
							icon={PiBuildingsFill}
							disabled={prospect ? true : false}
							value={selectFields?.prospect_id?.toString()}
							onValueChange={(val) => {
								setValue('prospect_id', Number(val));
								setSelectFields({
									...selectFields,
									prospect_id: Number(val),
								} as any);
								if (errors.prospect_id) clearErrors('prospect_id');
							}}
							placeholder='Select prospect'
						>
							{prospects && prospects.length > 0 ? (
								prospects?.map((prospect, index) => (
									<SelectItem key={index} value={prospect.id.toString()}>
										{prospect.company_name}
									</SelectItem>
								))
							) : (
								<div className='px-2 py-2'>No prospect</div>
							)}
						</Select>

						{errors?.prospect_id && <span className='form-message'>{errors?.prospect_id?.message}</span>}
					</div>
				</div>

				<div className='w-full form-control'>
					<label htmlFor='appointment_date' className='required-field-indicator'>
						Appointment Date
					</label>

					<div>
						<DatePicker
							id='appointment_date'
							enableClear
							className={`!max-w-full`}
							enableYearNavigation
							minDate={new Date()}
							placeholder={'Select date...'}
							value={selectFields?.appointment_date ? new Date(selectFields?.appointment_date as any) : undefined}
							onValueChange={(val) => {
								setValue('appointment_date', formatDate(val, '1999-12-31') as any);
								setSelectFields({
									...selectFields,
									appointment_date: formatDate(val, '1999-12-31'),
								} as any);
								if (errors.appointment_date) clearErrors('appointment_date');
							}}
						/>
						{errors?.appointment_date && <span className='form-message'>{errors?.appointment_date?.message}</span>}
					</div>
				</div>

				<SpaceFillerCard className='lg:block' />
			</section>

			<footer className='flex items-center justify-end gap-2 p-6 bg-gray-100 border-t'>
				<Button
					onClick={() => {
						afterEdit?.();
					}}
					type='button'
					color='gray'
					variant='secondary'
					className='px-6 py-3 duration-300 !rounded-full'
				>
					Cancel
				</Button>

				<Button
					type='submit'
					loading={isCreateAppointmentLoading || isUpdateAppointmentLoading}
					loadingText={appointment ? 'Updating' : 'Scheduling'}
					disabled={isCreateAppointmentLoading || isUpdateAppointmentLoading}
					color={appointment ? 'gray' : undefined}
					className='px-6 py-3 duration-300 !rounded-full'
				>
					{appointment ? 'Update' : 'Schedule'}
				</Button>
			</footer>
		</form>
	);
};

export default ScheduleAppointmentForm;
