import { useEffect } from 'react';
import { setActiveAppNavigationMenuItem, settingsState } from 'features/settings/settingsSlice';
import { useAppDispatch, useAppSelector } from 'hooks/storeConnect';
import { useLocation } from 'react-router-dom';

const useUpdateActiveNavigationItem = () => {
	const { activeAppNavigationMenuOption } = useAppSelector(settingsState);
	const dispatch = useAppDispatch();
	const { pathname } = useLocation();

	useEffect(() => {
		const isSubPage = activeAppNavigationMenuOption?.url === pathname || activeAppNavigationMenuOption?.menu?.some((option) => pathname === option?.url);

		if (!isSubPage) dispatch(setActiveAppNavigationMenuItem(null));

		// eslint-disable-next-line
	}, [pathname]);
};

export default useUpdateActiveNavigationItem;
