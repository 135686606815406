import { Fragment } from 'react';
import { Card, AreaChart, Title, Text, Button } from '@tremor/react';
import { IMarketingManagerDashboardData } from './dashboardInterfaces';
import { RiLoader4Line } from 'react-icons/ri';
import { CEDI_SYMBOL } from 'config';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

interface ChartDataFields {
	Month: string;
	Achieved: number;
	Expected: number;
}

interface Props {
	data: IMarketingManagerDashboardData | undefined;
	isDataLoading: boolean;
}

const valueFormatter = (number: number) => `${CEDI_SYMBOL} ${Intl.NumberFormat('us').format(number).toString()}`;

export default function ProductionChart(props: Props) {
	const { data, isDataLoading } = props;

	const navigate = useNavigate();

	return (
		<Card className={`w-full min-h-[250px] ${isDataLoading ? 'animate-pulse !bg-gray-100' : ''}`}>
			<div className='items-center justify-between sm:flex'>
				<div>
					<Title>Production Trend</Title>
					<Text>Expected Revenue to Achieved Revenue Graph</Text>
				</div>

				{/* <Button
					icon={IoIosArrowRoundForward}
					iconPosition='right'
					className='hover:!bg-gray-100 duration-300 !px-0 !mt-4 sm:mt-0 py-2 sm:!px-3 !rounded-lg'
					variant='light'
					color='gray'
					size='xs'
					onClick={() => {
						navigate('/app/dashboard/marketing/reports/earnings-per-prospect');
					}}
				>
					View Revenue Per Prospect
				</Button> */}
			</div>

			{isDataLoading ? (
				<div className='flex flex-col items-center justify-center gap-4 p-10'>
					<RiLoader4Line className='w-8 h-8 text-lg text-gray-400 animate animate-spin ' />
					<Text className='!text-base'>Loading production...</Text>
				</div>
			) : data && data.revenue_per_month.length > 0 ? (
				<AreaChart
					animationDuration={500}
					className='mt-4 h-80'
					data={data.revenue_per_month.map(
						(revenue): ChartDataFields => ({
							Expected: revenue.expected_revenue,
							Achieved: revenue.achieved_revenue,
							Month: revenue.month,
						})
					)}
					categories={['Achieved', 'Expected']}
					index='Month'
					colors={['blue', 'rose']}
					valueFormatter={valueFormatter}
				/>
			) : (
				<div className='flex flex-col items-center justify-center gap-4 p-10'>
					<Text className='!text-base'>No revenue generated.</Text>
				</div>
			)}
		</Card>
	);
}
