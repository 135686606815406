import { useQuery, RefetchOptions, RefetchQueryFilters, QueryObserverResult } from '@tanstack/react-query';
import { IFetchVehicleRequestsActionParams, IVehicleRequest } from './vehicleRequestsInterfaces';
import { fetchVehicleRequests } from './vehicleRequestQueries';

interface ReturnType {
	vehicleRequests: IVehicleRequest[] | undefined;
	isVehicleRequestsLoading: boolean;
	refetchVehicleRequests: (options?: RefetchOptions & RefetchQueryFilters) => Promise<QueryObserverResult>;
	paginationMetadata: APIPaginationMetadata;
}

interface Props {
	queryKey?: any;
	fetchParams?: IFetchVehicleRequestsActionParams;
	enabled?: boolean;
	afterFetch?: (appointments: IVehicleRequest[]) => void;
	onSuccess?: (vehicleRequests: IVehicleRequest[]) => void;
}

const useFetchVehicleRequests = ({ fetchParams, queryKey, enabled, onSuccess, afterFetch }: Props): ReturnType => {
	const { filterBy, includes: include, page } = fetchParams || {};

	const { data, isLoading, refetch } = useQuery({
		queryKey: [queryKey ?? 'vehicleRequests/fetch-vehicleRequests', fetchParams],
		queryFn: () => fetchVehicleRequests({ filterBy, includes: include, page }),
		enabled,
		onSuccess: onSuccess ? (data) => onSuccess?.(data?.data) : undefined,
	});

	const vehicleRequests = data?.data.data;
	const pagination = data?.data.meta;

	afterFetch?.(vehicleRequests);

	return { isVehicleRequestsLoading: isLoading, vehicleRequests, paginationMetadata: pagination, refetchVehicleRequests: refetch };
};

export default useFetchVehicleRequests;
