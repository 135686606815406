import { addDays, isAfter } from 'date-fns';
import { AppointmentFulfillmentStatuses, AppointmentStatuses, IAppointment } from 'features/appointments/appointmentInterfaces';
import { ISalesStage } from 'features/settings/prospecting/prospectSettingsInterfaces';
import { GeneralFilterNames, IFilter, INamedFilter } from 'features/settings/settingsInterfaces';
import _ from 'lodash';
import { FollowUpStatuses, IFollowUp } from 'features/followups/followUpInterfaces';
import { hasRoles, isAdmin } from 'helpers/index';
import { DefaultRoles } from 'features/settings/authorization/authorizationInterface';

export const getDefaultFilter = ({ defaultNamedFilters, expectedFilter }: { defaultNamedFilters: INamedFilter[]; expectedFilter: GeneralFilterNames; name?: string }): IFilter =>
	defaultNamedFilters.find((filter) => filter.name === expectedFilter)?.filter as IFilter;

export const sortSaleStages = (salesStages: ISalesStage[]): ISalesStage[] =>
	_.sortBy(salesStages, [
		function (stage) {
			return Number(stage.probability);
		},
	]);

export const geoFindMe = async (): Promise<IMapCoordinate> =>
	new Promise((resolve, reject) => {
		const onFindSuccess = (position: any) => {
			const latitude = position.coords.latitude;
			const longitude = position.coords.longitude;

			resolve({ lat: latitude, lng: longitude });
		};

		const onFindError = (error: GeolocationPositionError) => {
			reject(error);
		};

		navigator.geolocation.getCurrentPosition(onFindSuccess, onFindError, {
			enableHighAccuracy: true,
			maximumAge: 0,
			timeout: 10000,
		});
	});

export const parseLocation = (location: string): ILocationInfo => JSON.parse(location);

export const getAppointmentState = (appointment: IAppointment) => {
	const { fulfillment_status, appointment_date, status } = appointment;

	const isAppointmentLate = isAfter(new Date(), addDays(new Date(appointment_date), 1));

	const isPending: boolean = status === AppointmentStatuses.Pending && fulfillment_status === AppointmentFulfillmentStatuses.Pending && !isAppointmentLate;

	const isApproved: boolean = status === AppointmentStatuses.Approved && fulfillment_status === AppointmentFulfillmentStatuses.Pending && !isAppointmentLate;

	const isFulfilled: boolean = status === AppointmentStatuses.Approved && fulfillment_status === AppointmentFulfillmentStatuses.Fulfilled;

	const isUnfulFilled: boolean = fulfillment_status !== AppointmentFulfillmentStatuses.Fulfilled && isAppointmentLate;

	return { isPending, isApproved, isFulfilled, isUnfulFilled };
};

export const getFollowUpState = (followUp: IFollowUp) => {
	const { follow_up_date, status } = followUp;

	const isFollowUpLate = isAfter(new Date(), addDays(new Date(follow_up_date), 1));

	const isPending: boolean = status === FollowUpStatuses.PENDING && !isFollowUpLate;

	const isCompleted: boolean = status === FollowUpStatuses.COMPLETED;

	const isUncompleted: boolean = status !== FollowUpStatuses.COMPLETED && isFollowUpLate;

	return { isPending, isCompleted, isUncompleted };
};

export const isMarketer = () => !isAdmin() && hasRoles([DefaultRoles.Marketer]);
export const isTransportOfficer = () => !isAdmin() && hasRoles([DefaultRoles.TransportOfficer]);

export const buildURLFilters = (filtersObject: { [key: string]: string | number }) => {
	return Object.keys(filtersObject)
		.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(filtersObject[key])}`)
		.join('&');
};
