import { useState } from 'react';
import _ from 'lodash';
import { Button, DatePicker, DateRangePicker, Select, SelectItem, TextInput } from '@tremor/react';
import { SearchIcon } from './FormActionIcons';
import { FieldErrors, FieldValues } from 'react-hook-form';
import { FilterComponentTypes, IFilter } from 'features/settings/settingsInterfaces';
import SpaceFillerCard from './SpaceFillerCard';
import toast from 'react-hot-toast';
import { useAppSelector } from 'hooks/storeConnect';
import { authState } from 'features/auth/authSlice';

interface Props {
	submitButton: {
		label: string;
		className?: string;
		onSubmit: (formValues: any) => void;
	};
	filters: (IFilter | null)[];
	wrapperClassName?: string;
	fieldErrors?: FieldErrors<FieldValues>;
	resetFilters?: () => void;
}

type FilterValues = {
	[key: string]: any;
};

const Filters = (props: Props) => {
	const { authUser } = useAppSelector(authState);
	const { filters, submitButton, wrapperClassName } = props;

	const getDefaultFilterValues = (): FilterValues => {
		const defaultFilterValues: any = {};

		filters.forEach((filter) => {
			if (!filter) return;

			if (filter.type === FilterComponentTypes.TextInput) defaultFilterValues[filter.name] = filter.defaultValue ?? '';
			if (filter.type === FilterComponentTypes.Select) defaultFilterValues[filter.name] = filter.defaultValue ?? '';
			if (filter.type === FilterComponentTypes.Date) defaultFilterValues[filter.name] = filter.defaultValue ?? null;
			if (filter.type === FilterComponentTypes.DateRange) defaultFilterValues[filter.name] = filter.defaultValue ?? null;
			if (filter.type === FilterComponentTypes.DateRangeWithSelect) defaultFilterValues[filter.select.name] = filter.select.defaultValue ?? null;
			if (filter.type === FilterComponentTypes.DateRangeWithSelect) defaultFilterValues[filter.dateRange.name] = filter.dateRange.defaultValue ?? null;
		});

		return defaultFilterValues;
	};

	const [filterValues, setFilterValues] = useState<{
		[key: string]: any;
	}>(getDefaultFilterValues());

	const hardResetFormValue = () => {
		if (_.isEmpty(filterValues)) return;

		let filters = filterValues;

		const formEntries = Object.entries(filterValues);

		formEntries.forEach((entry) => {
			filters[entry[0]] = getDefaultFilterValues()[entry[0]];
		});

		// Todo: Keep user filter to ensure marketer does not fetch other people's data
		setFilterValues(filters);
	};

	const renderFilters = (filters: (IFilter | null)[]) =>
		filters?.map((filter, index) => {
			if (!filter) return '';
			if (filter.permission && !authUser?.permissions?.includes(filter.permission)) return '';

			// ===================================
			// Date Range Pricker with Select
			// ===================================
			if (filter.type === FilterComponentTypes.DateRangeWithSelect) {
				const { select, dateRange, hideLabel, label } = filter || {};

				return (
					<section className='form-control' key={index}>
						{!hideLabel && <label htmlFor='date-range-with-select-filter'>{label}</label>}
						<div className={`flex items-center w-full`}>
							<DateRangePicker
								className='[&>div]:!rounded-r-none [&>div>div>button]:!rounded-r-none flex-1 max-w-full'
								value={filterValues[dateRange.name]}
								enableSelect={false}
								onValueChange={(value) => {
									if (!filterValues[select.name]) {
										toast.error('Please select a date type first', { id: 'date-type-error' });
									} else {
										setFilterValues({
											...filterValues,
											[dateRange.name]: _.isEmpty(value) ? null : value,
										});
									}
								}}
								selectPlaceholder='Date Type'
								enableYearNavigation
								enableClear={true}
								defaultValue={filterValues[dateRange.name]}
								id='date-range-with-select-filter'
								placeholder={dateRange?.placeholder ?? 'Select date range...'}
							/>

							<Select
								className='[&>button]:!rounded-l-none w-fit !max-w-[1/3]'
								// enableClear={false}
								onValueChange={(value) => {
									if (value)
										return setFilterValues({
											...filterValues,
											[select.name]: value,
										});

									setFilterValues({
										...filterValues,
										[dateRange.name]: null,
										[select.name]: '',
									});
								}}
								defaultValue={filterValues[select.name]}
								defaultChecked
								placeholder={select?.placeholder ?? 'Select an option...'}
							>
								{select.dropdownOptions.map((option, index) => (
									<SelectItem key={index} value={option.value.toString()}>
										{option.label}
									</SelectItem>
								))}
							</Select>
						</div>
					</section>
				);
			}

			const { label, type, className, hideLabel } = filter;

			// ================
			// Text Input
			// ================
			if (type === FilterComponentTypes.TextInput)
				return (
					<section className='form-control' key={index}>
						{!hideLabel && <label htmlFor='text-input-filter'>{label}</label>}

						<TextInput
							value={filterValues[filter.name]}
							className={`${className}`}
							id='text-input-filter'
							onChange={(e) => {
								setFilterValues({
									[filter.name]: e.target.value,
								});
							}}
							icon={SearchIcon}
							defaultValue={filterValues[filter.name]}
							placeholder={filter?.placeholder ?? 'Search...'}
						/>
					</section>
				);

			// ========
			// Date
			// ========
			if (type === FilterComponentTypes.Date)
				return (
					<section className={`form-control`} key={index}>
						{!hideLabel && <label htmlFor='date-filter'>{label}</label>}

						<DatePicker
							id='date-filter'
							value={filterValues[filter.name]}
							defaultValue={filterValues[filter.name]}
							enableClear={true}
							className={`${className} !max-w-full`}
							enableYearNavigation
							placeholder={filter?.placeholder ?? 'Select date...'}
							onValueChange={(value) => {
								setFilterValues({
									...filterValues,
									[filter.name]: value ?? null,
								});
							}}
						/>
					</section>
				);

			// =================
			// Date Range
			// =================
			if (type === FilterComponentTypes.DateRange)
				return (
					<section className='form-control' key={index}>
						{!hideLabel && <label htmlFor='date-range-filter'>{label}</label>}

						<DateRangePicker
							id='date-range-filter'
							value={filterValues[filter.name]}
							enableClear={true}
							className={`${className} !max-w-full`}
							enableYearNavigation
							defaultValue={filter.defaultValue ?? {}}
							placeholder={filter?.placeholder ?? 'Select date range...'}
							onValueChange={(value) => {
								setFilterValues({
									...filterValues,
									[filter.name]: _.isEmpty(value) ? null : value,
								});
							}}
						/>
					</section>
				);

			// ==============
			// Select
			// ==============
			if (type === FilterComponentTypes.Select)
				return (
					<section className='form-control' key={index}>
						{!hideLabel && <label htmlFor='select-filter'>{label}</label>}

						<Select
							id='select-filter'
							enableClear
							className={`${className}`}
							defaultValue={filterValues[filter.name]}
							value={filterValues[filter.name]}
							placeholder={filter?.placeholder ?? 'Select an option...'}
							onValueChange={(value) => {
								setFilterValues({
									...filterValues,
									[filter.name]: value,
								});
							}}
						>
							{filter.dropdownOptions.map((option, index) => (
								<SelectItem key={index} value={option.value.toString()}>
									{option.label}
								</SelectItem>
							))}
						</Select>
					</section>
				);
		});

	return (
		<form
			className='pb-16 lg:pb-0'
			onSubmit={(e) => {
				e.preventDefault();
				submitButton?.onSubmit(filterValues);
				e.stopPropagation();
			}}
		>
			<div className={`px-2 py-4 space-y-4 lg:flex lg:gap-2 lg:items-end lg:space-y-0 lg:py-0 lg:px-0 ${wrapperClassName}`}>
				{renderFilters(filters)}

				<Button type='submit' size='sm' color='green' className={`uppercase !rounded-full hidden lg:block  ${submitButton.className}`}>
					Apply
				</Button>

				<SpaceFillerCard />
			</div>

			<footer className='fixed inset-x-0 bottom-0 flex items-center justify-end gap-2 px-2 py-3 border lg:hidden bg-light'>
				<Button type='button' onClick={hardResetFormValue} size='lg' color='gray' variant='secondary' className='uppercase !rounded-full'>
					Clear Filters
				</Button>

				<Button type='submit' size='lg' color='green' className={`uppercase ${submitButton.className} !rounded-full`}>
					{submitButton.label}
				</Button>
			</footer>
		</form>
	);
};

export default Filters;
