import { Card, DatePicker, Divider, NumberInput, Select, SelectItem, Text, TextInput, Title } from '@tremor/react';
import { useForm } from 'react-hook-form';
import { ICreateProspectFormValues } from '../prospectInterfaces';
import useFetchBusinessTypes from 'features/settings/prospecting/businessType/useFetchBusinessTypes';
import { SiCrowdsource } from 'react-icons/si';
import { MdEmail } from 'react-icons/md';
import { HiUser } from 'react-icons/hi2';
import { BiSolidBriefcaseAlt, BiSolidPhone } from 'react-icons/bi';
import { PiBuildingsFill } from 'react-icons/pi';
import { RiSpeakFill } from 'react-icons/ri';
import { BsPersonLinesFill } from 'react-icons/bs';
import useFetchInteractionTypes from 'features/settings/prospecting/interactionTypes/useFetchInteractionTypes';
import useFetchDecisionMakers from 'features/settings/prospecting/decisionMakers/useFetchDecisionMakers';
import useFetchSourceTypes from 'features/settings/prospecting/sourceTypes/useFetchSourceTypes';
import { useLocation, useNavigate } from 'react-router';
import { createProspectStepperPages } from './CreateProspectStepper';
import CreateProspectFormActionButtons from './CreateProspectFormActionButtons';
import { prospectsState, setCreateProspectFormValues } from '../prospectsSlice';
import { useAppDispatch, useAppSelector } from 'hooks/storeConnect';
import { useEffect, useState } from 'react';
import { formatDate } from 'helpers/dateHelpers';
import { hasPermission } from 'helpers/index';
import { PermissionKeys } from 'features/auth/permissions';

import _ from 'lodash';
import useFetchProspects from '../useFetchProspects';
import InputWithAutocomplete from 'components/InputWithAutocomplete';
import toast from 'react-hot-toast';

const CreateProspectFormCompanyInfoStep = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const dispatch = useAppDispatch();
	const { createProspectFormValues } = useAppSelector(prospectsState);
	const { businessTypes, isBusinessTypesLoading } = useFetchBusinessTypes({});
	const { interactionTypes, isInteractionTypesLoading } = useFetchInteractionTypes({});
	const { decisionMakers, isDecisionMakersLoading } = useFetchDecisionMakers({});
	const { sourceTypes, isSourceTypesLoading } = useFetchSourceTypes({});
	const [selectFields, setSelectFields] = useState(createProspectFormValues?.companyInformationStep);
	const { prospects } = useFetchProspects({});

	// Todo: Fix date picker not accessible on laptops

	const {
		register,
		setValue,
		setError,
		clearErrors,
		handleSubmit,
		formState: { errors },
	} = useForm<ICreateProspectFormValues['companyInformationStep']>({
		defaultValues: createProspectFormValues?.companyInformationStep,
	});

	useEffect(() => {
		console.log({ company_name: selectFields?.company_name });

		//eslint-disable-next-line
	}, [selectFields?.company_name]);
	console.log({ company_name: selectFields?.company_name });

	const onSubmit = (values: ICreateProspectFormValues['companyInformationStep']) => {
		const formValues: any = _.pickBy(values);

		const { business_type_id, interaction_type_id, decision_maker_type_id, source_type_id, contact_date } = formValues;

		if (business_type_id === null || business_type_id === undefined) {
			return setError('business_type_id', { message: 'Business type is required' });
		} else if (interaction_type_id === null || interaction_type_id === undefined) {
			return setError('interaction_type_id', { message: 'Interaction type is required' });
		} else if (decision_maker_type_id === null || decision_maker_type_id === undefined) {
			return setError('decision_maker_type_id', { message: 'Decision maker is required' });
		} else if (source_type_id === null || source_type_id === undefined) {
			return setError('source_type_id', { message: 'Source type is required' });
		} else if (contact_date === null || contact_date === undefined) {
			return setError('contact_date', { message: 'Contact date is required' });
		}

		dispatch(
			setCreateProspectFormValues({
				...createProspectFormValues,
				companyInformationStep: formValues,
			} as any)
		);

		const currentPageIndex = createProspectStepperPages.findIndex((page) => page.url === pathname);
		navigate(createProspectStepperPages[currentPageIndex + 1].url);
	};

	return (
		<form className='px-2 pb-20 lg:px-0 lg:relative' onSubmit={handleSubmit(onSubmit)}>
			<Title className='hidden px-4 text-primary-500 lg:block'>Company Information</Title>

			<div className='relative lg:bg-[#f5f6f7] lg:pt-4 lg:pb-24 lg:px-4 lg:border-t mt-4 w-full lg:h-[calc(100vh-var(--appbar-height-desktop)-var(--create-prospect-page-height)+400px)]'>
				<section className='pb-10 lg:pb-4'>
					<h3 className='text-sm font-medium text-black uppercase'>Company Data</h3>
					<Text className='mb-3 !text-gray-500'>Add details of the company you are prospecting.</Text>

					<div className='grid items-start w-full gap-4 lg:grid-cols-3 lg:gap-x-6'>
						<div className='w-full form-control'>
							<label htmlFor='company_name' className='required-field-indicator'>
								Company Name
							</label>

							<div className='relative'>
								<InputWithAutocomplete
									type='text'
									id='company_name'
									icon={PiBuildingsFill}
									placeholder='Enter company name'
									onValueChange={(val: string) => {
										if (val && val !== 'No matches found') clearErrors('company_name');

										setValue('company_name', val);
									}}
									menu={prospects?.map((p) => p.company_name) as string[]}
									{...register('company_name', {
										required: {
											value: true,
											message: 'Company name is required',
										},
									})}
									hasError={errors.company_name ? true : false}
									errorMessage={errors.company_name?.message}
								/>
							</div>
						</div>

						<div className='w-full form-control'>
							<label htmlFor='type_of_business' className='required-field-indicator'>
								Type of Business
							</label>

							<div>
								<Select
									id='type_of_business'
									placeholder='Select type of business'
									icon={BiSolidBriefcaseAlt}
									disabled={isBusinessTypesLoading}
									value={selectFields?.business_type_id?.toString()}
									enableClear={false}
									onValueChange={(val) => {
										setValue('business_type_id', Number(val));
										setSelectFields({
											...selectFields,
											business_type_id: Number(val),
										} as any);
										if (errors.business_type_id) clearErrors('business_type_id');
									}}
								>
									{businessTypes && businessTypes.length > 0 ? (
										businessTypes?.map((businessType, index) => (
											<SelectItem key={index} value={businessType.id.toString()}>
												{businessType.name}
											</SelectItem>
										))
									) : (
										<div className='px-2 py-2'>No Business Type</div>
									)}
								</Select>
								{errors?.business_type_id && <span className='form-message'>{errors?.business_type_id?.message}</span>}
							</div>
						</div>
					</div>
				</section>

				<Divider className='hidden lg:block' />

				<section>
					<h3 className='text-sm font-medium text-black uppercase'>Contact Person Data</h3>
					<Text className='mb-3 !text-gray-500'>Add details of the person you contacted while prospecting.</Text>

					<div className='grid items-start w-full gap-4 lg:grid-cols-3 lg:gap-6'>
						<div className='w-full form-control'>
							<label htmlFor='contact_name' className='required-field-indicator'>
								Contact Name
							</label>

							<div>
								<TextInput
									type='text'
									id='contact_name'
									icon={HiUser}
									{...register('contact_name', {
										required: {
											value: true,
											message: 'Contact name is required',
										},
									})}
									placeholder='Enter contact name'
									error={errors.contact_name ? true : false}
									errorMessage={errors.contact_name?.message}
								/>
							</div>
						</div>

						<div className='w-full form-control'>
							<label htmlFor='contact_email'>Contact Email</label>

							<div>
								<TextInput
									type='text'
									id='email'
									icon={MdEmail}
									{...register('contact_email')}
									placeholder='Enter contact email'
									error={errors.contact_email ? true : false}
									errorMessage={errors.contact_email?.message}
									onChange={(val) => console.log(val)}
								/>
							</div>
						</div>

						<div className='w-full form-control'>
							<label htmlFor='contact_phone' className='required-field-indicator'>
								Contact Phone
							</label>

							<div>
								<NumberInput
									id='contact_phone'
									icon={BiSolidPhone}
									{...register('contact_phone', {
										required: {
											value: true,
											message: 'Contact phone is required',
										},
									})}
									enableStepper={false}
									placeholder='Enter contact phone'
									error={errors.contact_phone ? true : false}
									errorMessage={errors.contact_phone?.message}
								/>
							</div>
						</div>

						<div className='w-full form-control'>
							<label htmlFor='interaction_type' className='required-field-indicator'>
								Interaction Type
							</label>

							<div>
								<Select
									id='interaction_type'
									icon={RiSpeakFill}
									placeholder='Select interaction type'
									disabled={isInteractionTypesLoading}
									value={selectFields?.interaction_type_id?.toString()}
									enableClear={false}
									onValueChange={(val) => {
										setValue('interaction_type_id', Number(val));

										setSelectFields({
											...selectFields,
											interaction_type_id: Number(val),
										} as any);

										if (errors.interaction_type_id) clearErrors('interaction_type_id');
									}}
								>
									{interactionTypes && interactionTypes.length > 0 ? (
										interactionTypes?.map((interactionType, index) => (
											<SelectItem key={index} value={interactionType.id.toString()}>
												{interactionType.name}
											</SelectItem>
										))
									) : (
										<div className='px-2 py-2'>No interaction type</div>
									)}
								</Select>
								{errors?.interaction_type_id && <span className='form-message'>{errors?.interaction_type_id?.message}</span>}
							</div>
						</div>

						<div className='w-full form-control'>
							<label htmlFor='decision_maker_type' className='required-field-indicator'>
								Decision Maker Type
							</label>

							<div>
								<Select
									id='decision_maker_type'
									icon={BsPersonLinesFill}
									placeholder='Select decision maker type'
									disabled={isDecisionMakersLoading}
									value={selectFields?.decision_maker_type_id?.toString()}
									enableClear={false}
									onValueChange={(val) => {
										setValue('decision_maker_type_id', Number(val));
										setSelectFields({
											...selectFields,
											decision_maker_type_id: Number(val),
										} as any);
										if (errors.decision_maker_type_id) clearErrors('decision_maker_type_id');
									}}
								>
									{decisionMakers && decisionMakers.length > 0 ? (
										decisionMakers?.map((decisionMaker, index) => (
											<SelectItem key={index} value={decisionMaker.id.toString()}>
												{decisionMaker.name}
											</SelectItem>
										))
									) : (
										<div className='px-2 py-2'>No decision maker type</div>
									)}
								</Select>

								{errors?.decision_maker_type_id && <span className='form-message'>{errors?.decision_maker_type_id?.message}</span>}
							</div>
						</div>

						<div className='w-full form-control'>
							<label htmlFor='source_type' className='required-field-indicator'>
								Source Type
							</label>

							<div>
								<Select
									id='source_type'
									icon={SiCrowdsource}
									placeholder='Select source type'
									disabled={isSourceTypesLoading}
									value={selectFields?.source_type_id?.toString()}
									enableClear={false}
									onValueChange={(val) => {
										setValue('source_type_id', Number(val));
										setSelectFields({
											...selectFields,
											source_type_id: Number(val),
										} as any);
										if (errors.source_type_id) clearErrors('source_type_id');
									}}
								>
									{sourceTypes && sourceTypes.length > 0 ? (
										sourceTypes?.map((sourceType, index) => (
											<SelectItem key={index} value={sourceType.id.toString()}>
												{sourceType.name}
											</SelectItem>
										))
									) : (
										<div className='px-2 py-2'>No source type</div>
									)}
								</Select>
								{errors?.source_type_id && <span className='form-message'>{errors?.source_type_id?.message}</span>}
							</div>
						</div>
					</div>
				</section>

				<section className='mt-4 lg:mt-6'>
					<div className='grid items-start w-full gap-4 lg:grid-cols-3 lg:gap-x-4 lg:gap-y-2'>
						<div className='w-full form-control'>
							<label htmlFor='contact_date' className='required-field-indicator'>
								Initial Contact Date
							</label>

							<div>
								<DatePicker
									id='contact_date'
									className={`!max-w-full`}
									enableYearNavigation
									placeholder={'Select date...'}
									value={selectFields?.contact_date ? new Date(selectFields?.contact_date as any) : undefined}
									enableClear={false}
									onValueChange={(val) => {
										setValue('contact_date', formatDate(val, '1999-12-31') as any);
										setSelectFields({
											...selectFields,
											contact_date: formatDate(val, '1999-12-31'),
										} as any);
										if (errors.contact_date) clearErrors('contact_date');
									}}
								/>
								{errors?.contact_date && <span className='form-message'>{errors?.contact_date?.message}</span>}
							</div>
						</div>

						<div className='w-full form-control'>
							<label htmlFor='created_at'>Date Created</label>

							<div>
								<DatePicker disabled id='created_at' defaultValue={new Date()} enableClear className={`!max-w-full`} enableYearNavigation placeholder={'Select date...'} />
							</div>
						</div>

						<Card className='h-[250px] lg:hidden' />
					</div>
				</section>

				{hasPermission(PermissionKeys.CREATE_PROSPECT) && (
					<CreateProspectFormActionButtons
						prevStepButton={{ isDisabled: true }}
						nextStepButton={{
							onClick() {
								if (prospects && prospects?.length > 0) {
									const prospect = prospects.find((prospect) => prospect.company_name.toLowerCase() === selectFields?.company_name.toLowerCase());

									if (prospect) {
										toast.error('Prospect already exists');
									}
								}
							},
							// isDisabled: createProspectFormValues?.companyInformationStep ? false : true,
						}}
					/>
				)}
			</div>
		</form>
	);
};

export default CreateProspectFormCompanyInfoStep;
