import { useEffect, useState } from 'react';

interface Props {
	query: string;
}

const useMediaQuery = ({ query }: Props) => {
	const mediaMatch = window.matchMedia(query);
	const [matches, setMatches] = useState(mediaMatch.matches);

	useEffect(() => {
		const handler = (e: any) => setMatches(e.matches);
		mediaMatch.addEventListener('change', handler);

		return () => mediaMatch.removeEventListener('change', handler);
	}, [mediaMatch]);

	return matches;
};

export default useMediaQuery;
