import { Navigate, Outlet, useLocation } from 'react-router-dom';
import AppMobileNavigation from 'components/Appbar/AppMobileNavigation';
import { Fragment, useEffect, useState } from 'react';
import Appbar from 'components/Appbar';
import { AiOutlineMenu } from 'react-icons/ai';
import { setActiveAppNavigationMenuItem, settingsState } from 'features/settings/settingsSlice';
import { useAppDispatch, useAppSelector } from 'hooks/storeConnect';
import AppNavigation from 'components/AppNavigation';
import useUpdateActiveNavigationItem from 'components/Appbar/useUpdateActiveNavigationItem';
import { CloseIcon } from 'components/FormActionIcons';
import { Button } from '@tremor/react';
import { authState } from 'features/auth/authSlice';
import appNavigationMenu from 'components/Appbar/appNavigationMenu';
import toast from 'react-hot-toast';
import useUpdateUserProfile from 'features/auth/useUpdateUserProfile';

const AppLayout = () => {
	const [isMenuOpen, setMenuOpen] = useState(false);
	const { isAuthenticated, isNewUser, isUserBlocked } = useAppSelector(authState);
	const { activeAppNavigationMenuOption } = useAppSelector(settingsState);
	const dispatch = useAppDispatch();
	const { pathname } = useLocation();
	useUpdateActiveNavigationItem();

	useUpdateUserProfile();

	const isSettingsPage = pathname.includes('settings');

	const hasSubMenu = activeAppNavigationMenuOption?.menu;

	// Auto set active navigation items
	useEffect(() => {
		if (!pathname.includes('/app/prospects/list')) return;

		const prospectsTab = appNavigationMenu[0].links[1];

		const menuOption: any = {
			...prospectsTab,
			icon: 'Icon',
		};

		dispatch(setActiveAppNavigationMenuItem(menuOption || null));

		// 	eslint-disable-next-line
	}, [pathname]);

	if (!isAuthenticated) {
		return <Navigate to='/auth/login' />;
	} else if (isUserBlocked) {
		toast.error('Your account has temporarily been blocked. Please contact your administrator for support.', { id: 'blocked-account-prompt', duration: 7000 });
		return <Navigate to='/app/accounts-issues/redirect/blocked-accounts' />;
	} else if (isNewUser) {
		toast.error('For security reasons, we recommend changing your password after the first login. Kindly proceed to update your password', { id: 'password-change-prompt', duration: 7000 });
		return <Navigate to='/app/accounts-issues/redirect/change-password' />;
	} else if (pathname === '/app') {
		return <Navigate to='/app/dashboard' />;
	}

	return (
		<div className='w-screen h-screen overflow-hidden'>
			<Fragment>
				<Button
					icon={() => <span>{!isMenuOpen ? <AiOutlineMenu className='w-4 h-4' /> : <CloseIcon className='w-5 h-5' />}</span>}
					onClick={() => setMenuOpen(!isMenuOpen)}
					className={`fixed z-40 text-gray-600 lg:hidden flex items-center justify-center h-8 gap-1 px-4 !border-none !rounded-full bottom-6 right-6 bg-gradient-to-br ${
						isMenuOpen ? 'from-primary-100 to-primary-200' : 'from-gray-100 to-gray-200'
					}`}
				>
					<span className={`${isMenuOpen ? 'rotate-180' : ''} duration-200`}></span>

					{!isMenuOpen ? 'Menu' : 'Close'}
				</Button>

				<AppMobileNavigation isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
			</Fragment>

			<div className={`relative w-screen h-screen overflow-hidden duration-300 ${isMenuOpen ? 'translate-x-[var(--sidebar-width)]' : 'translate-x-[0px]'}`}>
				<div className='h-[calc(var(--appbar-height-mobile))] sm:h-[calc(var(--appbar-height-desktop))] border-b border-gray-50'>
					<Appbar isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
				</div>

				<div className='h-[calc(100vh-var(--appbar-height-mobile))] sm:h-[calc(100vh-var(--appbar-height-desktop))]'>
					{hasSubMenu && !isSettingsPage && (
						<div className='sticky inset-x-0 top-0 z-20 h-[var(--app-navigation-height)]'>
							<AppNavigation activeAppNavigationMenuOption={activeAppNavigationMenuOption} />
						</div>
					)}

					<div className='h-[calc(100vh-var(--appbar-height-mobile))]'>
						<Outlet />
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppLayout;
