import { IUser, IYearlyTarget } from 'features/auth/authInterfaces';

export interface IFetchReportFilters {
	createdBetween?: string;
	userId?: number;
}

export type IFetchReportIncludes = '-no-include-yet-';

export interface IFetchReportActionParams {
	include?: IFetchReportIncludes[];
	filterBy?: IFetchReportFilters;
}

export interface IReport {
	total_aggregate: TotalAggregate;
	expected_target_per_staff: IExpectedTargetPerStaff[];
	closing_percentage: IClosingPercentage[];
	earning_per_account: IEarningPerAccount[];
	prospects_by_sales_stage: IProspectsBySaleStage[];
	prospects_by_busines_type: IProspectsByBusinessType[];
	total_follow_ups: ITotalFollowUp[];
	total_interactions: ITotalInteractions[];
	prospective_clients: IProspectiveClient[];
	won_prospects: any[];
}

export interface IProspectiveClient {
	prospect: string;
	stage: string;
	total_follow_ups: number;
	latest_comment: string;
	last_date_of_contact: Date;
	expected_premium: number;
}

export interface ITotalFollowUp {
	done: number;
	pending: number;
}

export interface ITotalInteractions {
	after_win: number;
	before_win: number;
}

export interface IYearlyTargetReport extends IYearlyTarget {
	user: IUser;
}

export interface IClosingPercentage {
	id: number;
	name: string;
	total_prospects: number;
	closed_prospects: number;
	closing_percentage: number;
}

export interface IEarningPerAccount {
	company_name: string;
	total_achieved_premium: number;
	commission: number;
}

export interface IExpectedTargetPerStaff {
	target: number | null;
	id: number;
	user_id: number;
	expected: number;
	won: number;
	commission: number;
	user: IUser;
}

export interface IProspectsBySaleStage {
	id: number;
	name: string;
	count: number;
}
export interface IProspectsByBusinessType {
	id: number;
	name: string;
	count: number;
}

export interface IWonProspect {
	prospect: string;
	achieved_premium: number;
	business_type: string;
	appointment_letter_received: boolean;
}

export interface TotalAggregate {
	expected: number;
	won: number;
	won_opportunities: number;
	avg_achieved_premium: number;
}

export enum FollowUpCategories {
	All = 'all',
	Pending = 'pending',
	Completed = 'completed',
	Uncompleted = 'uncompleted',
}
export enum FollowUpCategoriesIndexes {
	All = 0,
	Pending = 1,
	Completed = 2,
	Uncompleted = 3,
}
