import { Fragment, useState } from 'react';
import { Button, Callout, Divider, Metric, Text, Title } from '@tremor/react';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import useFetchInsuranceProducts from './useFetchInsuranceProducts';
import { IInsuranceProduct } from '../prospectSettingsInterfaces';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { HiExclamation } from 'react-icons/hi';
import toast from 'react-hot-toast';
import { MODAL_LEAVE_SPEED } from 'config';
import { useMutation } from '@tanstack/react-query';
import { deleteInsuranceProduct } from '../prospectSettingsQueuries';
import { hasPermission, titleCase } from 'helpers';
import Modal from 'components/Modal';
import EditInsuranceProductForm from './EditInsuranceProductForm';
import { DeleteIcon, EditIcon } from 'components/FormActionIcons';
import { PermissionKeys } from 'features/auth/permissions';
import ContentBlockedFromView from 'components/ContentBlockedFromView';

const InsuranceProductsPage = () => {
	const [isEditInsuranceProductFormOpen, setEditInsuranceProductFormOpen] = useState(false);
	const [isConfirmDeleteInsuranceProductDialogOpen, setConfirmDeleteInsuranceProductDialogOpen] = useState(false);
	const [currentInsuranceProduct, setCurrentInsuranceProduct] = useState<IInsuranceProduct | null>(null);

	const { isInsuranceProductsLoading, refetchInsuranceProducts, insuranceProducts } = useFetchInsuranceProducts({
		enabled: hasPermission(PermissionKeys.VIEW_INSURANCE_PRODUCT_TYPE),
	});

	// ======================
	// * Delete Insurance Product
	// ======================
	const { isLoading: isDeleteInsuranceProductLoading, mutate: deleteInsuranceProductMutation } = useMutation({
		mutationKey: ['authorization/delete-insuranceProduct'],
		mutationFn: (insuranceProductId: number) => deleteInsuranceProduct(insuranceProductId),
	});

	const onConfirmDeleteInsuranceProduct = () => {
		if (!currentInsuranceProduct) return toast.error('No insurance product selected for this action');

		deleteInsuranceProductMutation(currentInsuranceProduct.id, {
			onSuccess: () => {
				toast.success('Sales stage updated successfully!');
				setEditInsuranceProductFormOpen(false);
			},
			onError: () => {
				toast.error('Failed to delete insurance product');
			},
			onSettled() {
				setTimeout(() => setCurrentInsuranceProduct(null), MODAL_LEAVE_SPEED);
				refetchInsuranceProducts();
			},
		});

		setConfirmDeleteInsuranceProductDialogOpen(false);
	};

	const renderActionButtons = (insuranceProduct: IInsuranceProduct) => (
		<div className='flex items-center gap-6'>
			{hasPermission(PermissionKeys.UPDATE_INSURANCE_PRODUCT_TYPE) && (
				<button
					onClick={() => {
						setCurrentInsuranceProduct(insuranceProduct);
						setEditInsuranceProductFormOpen(true);
					}}
					type='button'
					title='Edit Product'
					className='flex items-center justify-center gap-2 group'
				>
					<div className='flex items-center justify-center text-yellow-600 duration-500 border border-yellow-100 lg:h-10 lg:w-10 lg:bg-yellow-50 lg:rounded-full lg:group-hover:text-white lg:group-hover:bg-gray-700'>
						<EditIcon />
					</div>

					<Text className='lg:font-medium'>Edit</Text>
				</button>
			)}

			{hasPermission(PermissionKeys.DELETE_INSURANCE_PRODUCT_TYPE) && (
				<button
					onClick={() => {
						setCurrentInsuranceProduct(insuranceProduct);
						setConfirmDeleteInsuranceProductDialogOpen(true);
					}}
					type='button'
					title='Delete Product'
					className='flex items-center justify-center gap-2 group '
				>
					<div className='flex items-center justify-center text-red-600 duration-500 border border-red-100 lg:h-10 lg:w-10 lg:bg-red-50 lg:rounded-full lg:group-hover:text-white lg:group-hover:bg-gray-700'>
						<DeleteIcon />
					</div>
					<Text className='lg:font-medium'>Delete</Text>
				</button>
			)}

			{!(hasPermission(PermissionKeys.UPDATE_INSURANCE_PRODUCT_TYPE) && hasPermission(PermissionKeys.DELETE_INSURANCE_PRODUCT_TYPE)) && (
				<Callout title='Not authorized to perform action' icon={HiExclamation} color='yellow' />
			)}
		</div>
	);

	const renderInsuranceProducts = (insuranceProducts: IInsuranceProduct[]) => {
		return insuranceProducts.map((insuranceProduct, index) => (
			<div key={index}>
				<Divider />

				<div className='items-start gap-2 lg:gap-4 lg:items-center grid sm:grid-cols-[1fr,200px]'>
					<div>
						<Title>{insuranceProduct.name}</Title>
						{insuranceProduct?.description && <Text>{insuranceProduct.description}</Text>}
					</div>

					<div className='mt-2 sm:mt-0'>{renderActionButtons(insuranceProduct)}</div>
				</div>
			</div>
		));
	};

	return (
		<Fragment>
			<div className='px-2 py-6 sm:px-4'>
				<div className='flex items-center justify-between'>
					<div className='flex items-center gap-3'>
						<Metric className='hidden sm:block'>Prospecting</Metric>

						{/* Separator */}
						<span className='hidden sm:inline-block w-[1px] h-[40px] bg-black rotate-12' />

						<Title className='lg:mt-1'>Insurance Products</Title>
					</div>

					{hasPermission(PermissionKeys.CREATE_INSURANCE_PRODUCT_TYPE) && (
						<Button className='!rounded-full' onClick={() => setEditInsuranceProductFormOpen(true)}>
							Create Product
						</Button>
					)}
				</div>

				<div>
					{hasPermission(PermissionKeys.VIEW_INSURANCE_PRODUCT_TYPE) ? (
						<Fragment>
							{!insuranceProducts && isInsuranceProductsLoading ? (
								<div className='mt-6'>
									<ContentLoadingCardSpinner message='Loading insurance products...' />
								</div>
							) : insuranceProducts && insuranceProducts.length > 0 ? (
								<Fragment>
									<div className='sm:grid-cols-[1fr,200px] hidden sm:grid sm:mt-6'>
										<h1 className='text-sm font-semibold text-gray-600 uppercase sm:text-xs'>Product</h1>
										<h1 className='text-sm font-semibold text-gray-600 uppercase sm:text-xs'>Action</h1>
									</div>

									{renderInsuranceProducts(insuranceProducts)}
								</Fragment>
							) : (
								<SimpleEmptyResourceIndicator containerClassName='lg:mt-8' message='No insurance product found' />
							)}
						</Fragment>
					) : (
						<div className='mt-6'>
							<ContentBlockedFromView message='You are not authorized to view insurance products.' containerClassName='min-h-[350px]' />
						</div>
					)}
				</div>
			</div>

			<Modal
				width={600}
				closeButton={{ styles: 'bg-gray-200' }}
				isModalOpen={isEditInsuranceProductFormOpen}
				setModalOpen={setEditInsuranceProductFormOpen}
				onCloseModal={() => {
					setEditInsuranceProductFormOpen(false);
					setTimeout(() => setCurrentInsuranceProduct(null), 500);
				}}
				shouldCloseOnOutsideClick={false}
			>
				<EditInsuranceProductForm
					insuranceProducts={insuranceProducts}
					insuranceProduct={currentInsuranceProduct}
					setCurrentInsuranceProduct={setCurrentInsuranceProduct}
					setEditInsuranceProductFormOpen={setEditInsuranceProductFormOpen}
					refetchInsuranceProducts={refetchInsuranceProducts}
				/>
			</Modal>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmDeleteInsuranceProductDialogOpen}
				setOpen={setConfirmDeleteInsuranceProductDialogOpen}
				message='Delete Insurance Product'
				subMessage={`Are you sure you want to delete ${titleCase(currentInsuranceProduct?.name as string) ?? 'this  insurance product'}? This action cannot be reversed.`}
				onClose={() => {
					setTimeout(() => setCurrentInsuranceProduct(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmDeleteInsuranceProduct,
					label: 'Delete',
					isLoading: isDeleteInsuranceProductLoading,
					loadingText: 'Deleting',
					color: 'red',
				}}
				cancelButton={{
					action: () => setConfirmDeleteInsuranceProductDialogOpen(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-500/10`}>
						<HiExclamation className='w-6 h-6 text-red-500' aria-hidden='true' />
					</div>
				)}
			/>
		</Fragment>
	);
};

export default InsuranceProductsPage;
