import { useQuery, RefetchOptions, RefetchQueryFilters, QueryObserverResult } from '@tanstack/react-query';
import { IFetchInteractionsActionParams, IFetchInteractionsActionResponse, IInteraction } from './interactionInterfaces';
import { fetchInteractions } from './interactionQueries';
import _ from 'lodash';

interface ReturnType {
	interactions: IInteraction[] | undefined;
	groupedInteractions: IFetchInteractionsActionResponse | undefined;
	interactionGroupNames: string[] | undefined;
	isInteractionsLoading: boolean;
	isInteractionsFetching: boolean;
	refetchInteractions: (options?: RefetchOptions & RefetchQueryFilters) => Promise<QueryObserverResult>;
	paginationMetadata: APIPaginationMetadata;
}

interface Props {
	queryKey?: any;
	fetchParams?: IFetchInteractionsActionParams;
	enabled?: boolean;
	onSuccess?: (interactions: IInteraction[]) => void;
}

const useFetchInteractions = ({ fetchParams, queryKey, enabled, onSuccess }: Props): ReturnType => {
	const { data, isLoading, isFetching, refetch } = useQuery({
		queryKey: [queryKey, 'interactions/fetch-interactions', fetchParams],
		queryFn: () => fetchInteractions(fetchParams ?? {}),
		enabled,
		onSuccess: onSuccess ? (data) => onSuccess?.(data?.data) : undefined,
	});

	const interactionsData: IFetchInteractionsActionResponse | undefined = data?.data?.data;
	const groupedInteractions = interactionsData;
	const interactions = interactionsData ? _.flatten(Object.values(interactionsData)) : undefined;
	const interactionGroupNames = interactionsData ? _.flatten(Object.keys(interactionsData)) : undefined;

	const pagination = data?.data?.meta;

	return {
		isInteractionsLoading: isLoading,
		isInteractionsFetching: isFetching,
		interactions,
		groupedInteractions,
		interactionGroupNames,
		paginationMetadata: pagination,
		refetchInteractions: refetch,
	};
};

export default useFetchInteractions;
