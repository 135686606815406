import { lsmsAPI } from 'ajax/httpClient';
import { ICreateBranchFormValues, IFetchBranchesActionParams } from './branchInterfaces';

export const fetchBranches = (params: IFetchBranchesActionParams) => {
	const { filterBy, page } = params || {};
	const { name } = filterBy || {};

	return lsmsAPI.get('/branches', {
		params: {
			'filter[name]': name,
			page: page ?? 1,
		},
	});
};

export const createBranch = (formValues: ICreateBranchFormValues) => lsmsAPI.post('/branches', formValues);

export const updateBranch = ({ formValues, branchId }: { formValues: ICreateBranchFormValues; branchId: number }) => lsmsAPI.put(`/branches/${branchId}`, formValues);

export const deleteBranch = (branchId: number) => lsmsAPI.delete(`/branches/${branchId}`);
