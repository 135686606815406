import { Button, Color } from '@tremor/react';
import { FaLongArrowAltLeft, FaLongArrowAltRight } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router';
import { createProspectStepperPages } from './CreateProspectStepper';

interface Props {
	prevStepButton?: {
		isDisabled?: boolean;
	};
	nextStepButton?: {
		isDisabled?: boolean;
		type?: 'submit' | 'button';
		onClick?: () => void;
		label?: string;
		color?: Color;
		hasIcon?: boolean;
		isLoading?: boolean;
		loadingText?: string;
	};
}

const CreateProspectFormActionButtons = ({ prevStepButton, nextStepButton }: Props) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	return (
		<div className='fixed !z-[10000] border-t-gray-300 lg:py-5 bottom-0 inset-x-0 py-4 border-t px-2 bg-gray-100 flex items-center gap-2'>
			<Button
				type='button'
				disabled={prevStepButton?.isDisabled}
				icon={FaLongArrowAltLeft}
				onClick={() => {
					const currentPageIndex = createProspectStepperPages.findIndex((page) => page.url === pathname);
					navigate(createProspectStepperPages[currentPageIndex - 1].url);
				}}
				className='!rounded-full [&>svg]:hidden sm:[&>svg]:inline !bg-black !border-black !text-white'
			>
				Previous Step
			</Button>

			<Button
				type={nextStepButton?.type ?? 'submit'}
				onClick={nextStepButton?.onClick}
				disabled={nextStepButton?.isDisabled}
				iconPosition='right'
				icon={nextStepButton?.hasIcon === undefined ? FaLongArrowAltRight : nextStepButton?.hasIcon === true ? FaLongArrowAltRight : undefined}
				className={`!rounded-full [&>svg]:hidden sm:[&>svg]:inline !text-white ${
					nextStepButton?.color === undefined ? '!bg-black !border-black' : nextStepButton?.color ? ' ' : '!bg-black !border-black'
				}`}
				color={nextStepButton?.color ? nextStepButton?.color : undefined}
				loading={nextStepButton?.isLoading}
				loadingText={nextStepButton?.loadingText}
			>
				{nextStepButton?.label ?? 'Next Step'}
			</Button>
		</div>
	);
};

export default CreateProspectFormActionButtons;
