import { useForm } from 'react-hook-form';
import AuthBranding from 'components/AuthBranding';
import { ILoginActionParams, ILoginFormTypes } from './authInterfaces';
import { TextInput, Button } from '@tremor/react';
import { useMutation } from '@tanstack/react-query';
import { login } from './authQueries';
import { login as storeLogin } from './authSlice';
import { useAppDispatch } from 'hooks/storeConnect';
import { processReactHookFormErrors } from 'helpers/index';

const LoginPage = () => {
	const dispatch = useAppDispatch();
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<ILoginFormTypes>({});

	const { isLoading: isLoginLoading, mutate: loginMutation } = useMutation({
		mutationKey: ['auth/login'],
		mutationFn: (formValues: ILoginActionParams) => login(formValues),
	});

	const onSubmit = (formValues: ILoginFormTypes) => {
		formValues.email = formValues.email.trim();
		formValues.password = formValues.password.trim();

		formValues.email = formValues.email.trim();
		formValues.password = formValues.password.trim();

		loginMutation(formValues, {
			onSuccess(data) {
				dispatch(storeLogin(data.data));
			},
			onError(error) {
				processReactHookFormErrors(error, 'Failed to login, setError', setError);
			},
		});
	};

	return (
		<div id='login-page' className='w-full h-full'>
			<div className='h-full p-4 bg-gradient-to-br from-gray-200 to-primary-100 lg:p-8 lg:from-white lg:to-white lg:max-w-[400px] mx-auto'>
				{/* Branding */}
				<div>
					<AuthBranding />
				</div>

				<div className='mt-16 sm:mt-[10vh] flex flex-col items-center lg:items-start'>
					<h1 className='text-3xl font-bold text-gray-700 lg:font-bold'>LeadCX</h1>
					<p className='mt-4 text-gray-500'>Please log in to your account.</p>
				</div>

				<form onSubmit={handleSubmit(onSubmit)} className='flex flex-col items-center mt-10 lg:items-start'>
					<div className='space-y-4 w-11/12 sm:w-[400px]'>
						<div className='grid gap-1 form-control'>
							<label htmlFor='email' className='text-sm font-medium uppercase lg:font-normal lg:capitalize '>
								Email
							</label>
							<TextInput
								id='email'
								type='text'
								{...register('email', {
									pattern: {
										value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
										message: 'Invalid email.',
									},
								})}
								name='email'
								placeholder='Enter email'
								className='text-lg sm:h-[40px]'
								error={errors.email ? true : false}
								errorMessage={errors.email?.message}
							/>
						</div>

						<div className='grid gap-1 form-control'>
							<label htmlFor='password' className='text-sm font-medium uppercase lg:font-normal lg:capitalize'>
								Password
							</label>
							<TextInput
								id='password'
								type='password'
								{...register('password')}
								name='password'
								placeholder='Enter password'
								className='text-lg sm:h-[40px]'
								error={errors.password ? true : false}
								errorMessage={errors.password?.message}
							/>
						</div>

						<div className='flex justify-center lg:justify-start'>
							<a href='/auth/forgot-password' className='inline-block py-2 underline duration-300 text-primary-500 hover:text-secondary-500'>
								Forgot Password
							</a>
						</div>
					</div>

					<div className='py-8 text-center lg:mt-6'>
						<Button loading={isLoginLoading} loadingText='Logging In' size='xl' iconPosition='right' type='submit' className='uppercase px-8 border-none !rounded-full bg-primary-500'>
							Login
						</Button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default LoginPage;
