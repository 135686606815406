import { Button, TextInput, Title } from '@tremor/react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { processReactHookFormErrors } from 'helpers';
import { IBranch, ICreateBranchFormValues } from './branchInterfaces';
import { createBranch, updateBranch } from './branchQueries';

interface Props {
	branch: IBranch | null;
	refetchBranches: () => void;
	setCurrentBranch: (value: IBranch | null) => void;
	setEditBranchFormOpen: (value: boolean) => void;
}

const EditBranchForm = (props: Props) => {
	const { branch, setCurrentBranch, setEditBranchFormOpen, refetchBranches } = props;

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<ICreateBranchFormValues>({
		defaultValues: {
			name: branch?.name,
			code: branch?.code,
		},
	});

	// =====================
	// * Create Branch
	// =====================
	const { isLoading: isCreateBranchLoading, mutate: createBranchMutation } = useMutation({
		mutationKey: ['authorization/create-branch'],
		mutationFn: (params: ICreateBranchFormValues) => createBranch(params),
	});

	// =====================
	// * Update Branch
	// =====================
	const { isLoading: isUpdateBranchLoading, mutate: updateBranchMutation } = useMutation({
		mutationKey: ['authorization/update-branch'],
		mutationFn: ({ formValues, branchId }: { formValues: ICreateBranchFormValues; branchId: number }) => updateBranch({ formValues: formValues, branchId }),
	});

	const onSubmit = (formValues: ICreateBranchFormValues) => {
		if (branch)
			return updateBranchMutation(
				{ formValues, branchId: branch.id },
				{
					onSuccess: () => {
						toast.success('Branch updated successfully!');
						setTimeout(() => setCurrentBranch(null), 500);
						setEditBranchFormOpen(false);
						refetchBranches();
					},
					onError: (error) => {
						processReactHookFormErrors(error, 'Failed to update branch', setError);
					},
				}
			);

		createBranchMutation(formValues, {
			onSuccess: () => {
				toast.success('Branch created successfully!');
				setEditBranchFormOpen(false);
				refetchBranches();
			},
			onError: (error) => {
				processReactHookFormErrors(error, 'Failed to create branch', setError);
			},
		});
	};

	return (
		<form className='bg-white' onSubmit={handleSubmit(onSubmit)}>
			<header className={`${branch ? 'bg-gradient-to-r from-gray-800 to-gray-500' : 'bg-gradient-to-r from-primary-600 to-primary-300'} bg-dark p-6 h-[80px] flex items-center gap-2`}>
				<Title className='text-white'>{branch ? 'Update' : 'Create'} Branch</Title>
			</header>

			<section className='p-6 space-y-6 text-left'>
				<div>
					<label htmlFor='name' className='required-field-indicator'>
						Name
					</label>

					<div>
						<TextInput
							type='text'
							id='name'
							{...register('name', {
								required: {
									value: true,
									message: 'Branch name is required',
								},
							})}
							placeholder='Enter branch name'
							error={errors.name ? true : false}
							errorMessage={errors.name?.message}
						/>
					</div>
				</div>

				<div>
					<label htmlFor='code' className='required-field-indicator'>
						Code
					</label>

					<div>
						<TextInput
							type='text'
							id='code'
							{...register('code', {
								required: {
									value: true,
									message: 'Branch code is required',
								},
							})}
							placeholder='Enter branch code'
							error={errors.code ? true : false}
							errorMessage={errors.code?.message}
						/>
					</div>
				</div>
			</section>

			<footer className='flex items-center justify-end gap-2 p-6 bg-gray-100'>
				<Button
					onClick={() => {
						setEditBranchFormOpen(false);
						setTimeout(() => setCurrentBranch(null), 500);
					}}
					type='button'
					color='gray'
					variant='secondary'
					className='px-6 py-3 duration-300 !rounded-full'
				>
					Cancel
				</Button>

				<Button
					type='submit'
					loading={isCreateBranchLoading || isUpdateBranchLoading}
					loadingText={branch ? 'Updating Branch' : 'Creating Branch'}
					disabled={isCreateBranchLoading || isUpdateBranchLoading}
					color={branch ? 'gray' : undefined}
					className='px-6 py-3 duration-300 !rounded-full'
				>
					{branch ? 'Update Branch' : 'Create Branch'}
				</Button>
			</footer>
		</form>
	);
};

export default EditBranchForm;
