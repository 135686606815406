import React from 'react';

interface Props {
	className?: string;
}

const AppLogo = ({ className }: Props) => {
	return <img src='/images/loyalty_logo_full.svg' alt='Loyalty Insurance Company Limited Logo' className={`h-[60%] max-w-[200px] max-h-[70px] w-[60%] lg:h-[60%] lg:w-[60%] ${className}`} />;
};

export default AppLogo;
