import { Text, Title } from '@tremor/react';
import { ICreateProspectFormValues } from '../prospectInterfaces';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'hooks/storeConnect';
import { prospectsState, setCreateProspectFormValues } from '../prospectsSlice';
import { sortSaleStages } from 'utils/index';
import useFetchSalesStages from 'features/settings/prospecting/useFetchSalesStages';
import CreateProspectFormActionButtons from './CreateProspectFormActionButtons';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import toast from 'react-hot-toast';
import { createProspectStepperPages } from './CreateProspectStepper';
import { useLocation, useNavigate } from 'react-router';
import { hasPermission } from 'helpers/index';
import { PermissionKeys } from 'features/auth/permissions';

const CreateProspectSalesStagesStep = () => {
	const { createProspectFormValues } = useAppSelector(prospectsState);

	useForm<ICreateProspectFormValues['saleStageStep']>({
		defaultValues: {
			sale_stage_id: createProspectFormValues?.saleStageStep?.sale_stage_id as any,
		},
	});
	const { pathname } = useLocation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { salesStages, isSalesStagesLoading } = useFetchSalesStages({});
	const selectedSaleStage = createProspectFormValues?.saleStageStep?.sale_stage_id;

	return (
		<div>
			<Title className='hidden px-4 text-primary-500 lg:block'>Sale Stage</Title>

			<div className='relative lg:bg-[#f5f6f7] lg:pt-4 lg:pb-64 lg:border-t mt-4 w-full lg:h-[calc(100vh-var(--appbar-height-desktop)-var(--create-prospect-page-height)+200px)]'>
				<section className='px-2 pb-20 sm:pb-24 lg:pb-4 lg:px-4'>
					<h3 className='text-sm font-medium text-black uppercase'>Define prospect stage in sales pipeline</h3>

					<Text className='mb-3 lg:mb-6 !text-gray-500'>Select stage</Text>

					{isSalesStagesLoading ? (
						<ContentLoadingCardSpinner message='Loading sale stages...' />
					) : salesStages && salesStages.length > 0 ? (
						<div className='grid items-center grid-cols-1 gap-4 lg:pb-32 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 sm:gap-y-8'>
							{sortSaleStages(salesStages)?.map((stage, index) => (
								<div key={index} className='flex items-start gap-2'>
									<input
										className='h-5 w-5 mt-0.5 capitalize checked:!bg-primary-600 hover:cursor-pointer hover:!bg-primary-600 focus:!bg-primary-600 focus:ring-primary-600 focus-visible:outline-none'
										type='radio'
										id='sale-stage'
										checked={selectedSaleStage == stage.id}
										value={stage.id}
										readOnly
										onClick={() => {
											dispatch(
												setCreateProspectFormValues({
													...createProspectFormValues,
													saleStageStep: {
														...createProspectFormValues?.saleStageStep,
														sale_stage_id: stage.id,
													},
												} as any)
											);
										}}
									/>
									<div>
										<label key={index} htmlFor='sale-stage' className='font-medium'>
											Stage {index + 1} - <span className='font-bold text-primary-500'>{stage.name}</span>
										</label>
										<Text>{stage.probability}% probability in achieving sale</Text>
									</div>
								</div>
							))}
						</div>
					) : (
						<SimpleEmptyResourceIndicator containerClassName='lg:mt-8 lg:!py-10' message='No sale stage found' />
					)}
				</section>

				{hasPermission(PermissionKeys.CREATE_PROSPECT) && (
					<CreateProspectFormActionButtons
						nextStepButton={{
							onClick: () => {
								if (!selectedSaleStage) return toast.error('Please select a stage');
								const currentPageIndex = createProspectStepperPages.findIndex((page) => page.url === pathname);
								navigate(createProspectStepperPages[currentPageIndex + 1].url);
							},
							isDisabled: !selectedSaleStage,
							type: 'button',
						}}
					/>
				)}
			</div>
		</div>
	);
};

export default CreateProspectSalesStagesStep;
