import { useQuery, RefetchOptions, RefetchQueryFilters, QueryObserverResult } from '@tanstack/react-query';
import { IFetchFollowUpsActionParams, IFollowUp } from './followUpInterfaces';
import { fetchFollowUps } from './followUpQueries';

interface ReturnType {
	followUps: IFollowUp[] | undefined;
	isFollowUpsLoading: boolean;
	refetchFollowUps: (options?: RefetchOptions & RefetchQueryFilters) => Promise<QueryObserverResult>;
	paginationMetadata: APIPaginationMetadata;
}

interface Props {
	queryKey?: any;
	fetchParams?: IFetchFollowUpsActionParams;
	enabled?: boolean;
	onSuccess?: (followUps: IFollowUp[]) => void;
}

const useFetchFollowUps = ({ fetchParams, queryKey, enabled, onSuccess }: Props): ReturnType => {
	const { filterBy, include } = fetchParams || {};

	const { data, isLoading, refetch } = useQuery({
		queryKey: [queryKey, 'prospects/fetch-followUps', fetchParams],
		queryFn: () => fetchFollowUps({ filterBy, include }),
		enabled,
		onSuccess: onSuccess ? (data) => onSuccess?.(data?.data) : undefined,
		retry: 0,
	});

	const followUps = data?.data?.data;
	const pagination = followUps?.meta;

	return { isFollowUpsLoading: isLoading, followUps, paginationMetadata: pagination, refetchFollowUps: refetch };
};

export default useFetchFollowUps;
