import { useQuery, RefetchOptions, RefetchQueryFilters, QueryObserverResult } from '@tanstack/react-query';
import _ from 'lodash';
import { fetchRoles } from './authorizationQueries';
import { IFetchRolesParams, IRole } from './authorizationInterface';

interface ReturnType {
	roles: IRole[] | undefined;
	isRolesLoading: boolean;
	refetchRoles: (options?: RefetchOptions & RefetchQueryFilters) => Promise<QueryObserverResult>;
}

interface Props {
	queryKey?: string;
	fetchParams?: IFetchRolesParams;
	enabled?: boolean;
	onSuccess?: (data: { [key: string]: IRole[] }) => void;
}

const useFetchRoles = ({ fetchParams, queryKey, enabled, onSuccess }: Props): ReturnType => {
	const { data, isLoading, refetch } = useQuery({
		queryKey: [queryKey ?? 'authorization/fetch-roles', fetchParams],
		queryFn: () => fetchRoles(),
		onSuccess: onSuccess ? (data) => onSuccess?.(data?.data) : undefined,
	});

	const groupedRoles = data?.data?.data;

	return { isRolesLoading: isLoading, roles: groupedRoles, refetchRoles: refetch };
};

export default useFetchRoles;
