import { lsmsAPI } from 'ajax/httpClient';
import { ICreateFollowUpFormValues, IFetchFollowUpsActionParams } from './followUpInterfaces';

export const fetchFollowUps = ({ filterBy }: IFetchFollowUpsActionParams) => {
	const { followUpDate, status, saleStageId, userId } = filterBy || {};

	return lsmsAPI.get('/follow-ups', {
		params: {
			['filter[user_id]']: userId,
			['filter[status]']: status,
			['filter[follow_up_date]']: followUpDate,
			['filter[sales_stage.id]']: saleStageId,
			include: 'prospect,user,prospect.insurance_products.insurance_product_type,sales_stage',
			sort: '-follow_up_date',
		},
	});
};

export const createFollowUp = (formValues: ICreateFollowUpFormValues) => lsmsAPI.post('/follow-ups', formValues);

export const updateFollowUp = ({ formValues, followUpId }: { formValues: ICreateFollowUpFormValues; followUpId: number }) => lsmsAPI.put(`/follow-ups/${followUpId}`, formValues);

export const completeFollowUp = (followUpId: number) =>
	lsmsAPI.put(`/follow-ups/${followUpId}`, {
		status: 1,
	});

export const deleteFollowUp = (followUpId: number) => lsmsAPI.delete(`/follow-ups/${followUpId}`);
