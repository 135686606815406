import { Text } from '@tremor/react';
import { useState, Fragment } from 'react';

interface Props {
	text: string;
	maxLength: number;
}

const ReadMoreParagraph = ({ text, maxLength }: Props) => {
	const [showFullText, setShowFullText] = useState(false);

	const toggleShowFullText = () => {
		setShowFullText(!showFullText);
	};

	return (
		<Text>
			{!showFullText && text.length > maxLength ? `${text.slice(0, maxLength)}...` : text}

			{text.length > maxLength && (
				<Fragment>
					&nbsp;
					<button
						type='button'
						title={showFullText ? 'Collapse' : 'Expand'}
						className={`font-medium hover:text-primary-500 ${showFullText ? 'underline' : 'hover:underline'}`}
						onClick={toggleShowFullText}
					>
						{showFullText ? 'Read Less' : 'Read More'}
					</button>
				</Fragment>
			)}
		</Text>
	);
};

export default ReadMoreParagraph;
