import { IUser } from 'features/auth/authInterfaces';
import { IProspect } from 'features/prospects/prospectInterfaces';
import { ISalesStage } from 'features/settings/prospecting/prospectSettingsInterfaces';

export interface IFollowUp {
	id: number;
	follow_up_date: string;
	status: number;
	prospect_id: number;
	user_id: number;
	created_at: string;
	updated_at: string;
	sales_stage_id: number;
	sales_stage: ISalesStage;
	prospect: IProspect;
	user: IUser;
}

export interface ICreateFollowUpFormValues {
	prospect_id: number;
	follow_up_date: string;
}

export interface IFetchFollowUpsActionParams {
	include?: {
		// product?: boolean;
	};
	filterBy?: {
		userId?: number;
		status?: number;
		followUpDate?: string;
		saleStageId?: number;
	};
}
export enum FollowUpStatuses {
	COMPLETED = 1,
	PENDING = 0,
}
