import { useQuery, RefetchOptions, RefetchQueryFilters, QueryObserverResult } from '@tanstack/react-query';
import { fetchInteractionTypes } from '../prospectSettingsQueuries';
import { IFetchInteractionTypesActionParams, IInteractionType } from '../prospectSettingsInterfaces';

interface ReturnType {
	interactionTypes: IInteractionType[] | undefined;
	isInteractionTypesLoading: boolean;
	refetchInteractionTypes: (options?: RefetchOptions & RefetchQueryFilters) => Promise<QueryObserverResult>;
}

interface Props {
	queryKey?: string;
	fetchParams?: IFetchInteractionTypesActionParams;
	enabled?: boolean;
	onSuccess?: (interactionTypes: IInteractionType[]) => void;
}

const useFetchInteractionTypes = ({ fetchParams, queryKey, enabled, onSuccess }: Props): ReturnType => {
	const { data, isLoading, refetch } = useQuery({
		queryKey: [queryKey ?? 'auth/fetch-interactionTypes', fetchParams],
		queryFn: () => fetchInteractionTypes(),
		enabled,
		onSuccess: onSuccess ? (data) => onSuccess?.(data?.data) : undefined,
	});

	const interactionTypeData = data?.data?.data;

	return { isInteractionTypesLoading: isLoading, interactionTypes: interactionTypeData, refetchInteractionTypes: refetch };
};

export default useFetchInteractionTypes;
