import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { Analytics } from '@vercel/analytics/react';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import store, { persistor } from 'store/index';
import queryClient from 'utils/reactQueryClient';

import 'react-tooltip/dist/react-tooltip.css';
import './styles/index.scss';

const persister = createSyncStoragePersister({
	storage: window.localStorage,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<React.StrictMode>
		<PersistQueryClientProvider
			client={queryClient}
			persistOptions={{ persister }}
			onSuccess={() => {
				// resume mutations after initial restore from localStorage was successful
				// queryClient.resumePausedMutations().then(() => {
				// 	queryClient.invalidateQueries();
				// });
			}}
		>
			<BrowserRouter>
				<Provider store={store}>
					<PersistGate loading={null} persistor={persistor}>
						<App />
						<Analytics />
					</PersistGate>
				</Provider>
			</BrowserRouter>

			<ReactQueryDevtools initialIsOpen={false} />
		</PersistQueryClientProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
