import { Fragment, useState } from 'react';
import { Button, Callout, Divider, Metric, Text, Title } from '@tremor/react';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import useFetchBusinessTypes from './useFetchBusinessTypes';
import { IProspectBusinessType } from '../prospectSettingsInterfaces';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { HiExclamation } from 'react-icons/hi';
import toast from 'react-hot-toast';
import { MODAL_LEAVE_SPEED } from 'config';
import { useMutation } from '@tanstack/react-query';
import { deleteProspectBusinessType } from '../prospectSettingsQueuries';
import { hasPermission, titleCase } from 'helpers';
import Modal from 'components/Modal';
import EditProspectBusinessTypeForm from './EditProspectBusinessTypeForm';
import { DeleteIcon, EditIcon } from 'components/FormActionIcons';
import { PermissionKeys } from 'features/auth/permissions';
import ContentBlockedFromView from 'components/ContentBlockedFromView';

const ProspectBusinessTypePage = () => {
	const [isEditBusinessTypeFormOpen, setEditBusinessTypeFormOpen] = useState(false);
	const [isConfirmDeleteBusinessTypeDialogOpen, setConfirmDeleteBusinessTypeDialogOpen] = useState(false);
	const [currentBusinessType, setCurrentBusinessType] = useState<IProspectBusinessType | null>(null);

	const { isBusinessTypesLoading, refetchBusinessTypes, businessTypes } = useFetchBusinessTypes({
		enabled: hasPermission(PermissionKeys.VIEW_BUSINESS_TYPE),
	});

	// ========================
	// * Delete Business Type
	// ========================
	const { isLoading: isDeleteBusinessTypeLoading, mutate: deleteBusinessTypeMutation } = useMutation({
		mutationKey: ['authorization/delete-businessType'],
		mutationFn: (businessTypeId: number) => deleteProspectBusinessType(businessTypeId),
	});

	const onConfirmDeleteBusinessType = () => {
		if (!currentBusinessType) return toast.error('No business type selected for this action');

		deleteBusinessTypeMutation(currentBusinessType.id, {
			onSuccess: () => {
				toast.success('Business type updated successfully!');
				setEditBusinessTypeFormOpen(false);
			},
			onError: () => {
				toast.error('Failed to delete business type');
			},
			onSettled() {
				setTimeout(() => setCurrentBusinessType(null), MODAL_LEAVE_SPEED);
				refetchBusinessTypes();
			},
		});

		setConfirmDeleteBusinessTypeDialogOpen(false);
	};

	const renderActionButtons = (businessType: IProspectBusinessType) => (
		<div className='flex items-center gap-6'>
			{hasPermission(PermissionKeys.UPDATE_BUSINESS_TYPE) && (
				<button
					onClick={() => {
						setCurrentBusinessType(businessType);
						setEditBusinessTypeFormOpen(true);
					}}
					type='button'
					title='Edit Business Type'
					className='flex items-center justify-center gap-2 group'
				>
					<div className='flex items-center justify-center text-yellow-600 duration-500 border border-yellow-100 lg:h-10 lg:w-10 lg:bg-yellow-50 lg:rounded-full lg:group-hover:text-white lg:group-hover:bg-gray-700'>
						<EditIcon />
					</div>

					<Text className='lg:font-medium'>Edit</Text>
				</button>
			)}

			{hasPermission(PermissionKeys.DELETE_BUSINESS_TYPE) && (
				<button
					onClick={() => {
						setCurrentBusinessType(businessType);
						setConfirmDeleteBusinessTypeDialogOpen(true);
					}}
					type='button'
					title='Delete Business Type'
					className='flex items-center justify-center gap-2 group '
				>
					<div className='flex items-center justify-center text-red-600 duration-500 border border-red-100 lg:h-10 lg:w-10 lg:bg-red-50 lg:rounded-full lg:group-hover:text-white lg:group-hover:bg-gray-700'>
						<DeleteIcon />
					</div>
					<Text className='lg:font-medium'>Delete</Text>
				</button>
			)}

			{!(hasPermission(PermissionKeys.UPDATE_BUSINESS_TYPE) && hasPermission(PermissionKeys.DELETE_BUSINESS_TYPE)) && (
				<Callout title='Not authorized to perform action' icon={HiExclamation} color='yellow' />
			)}
		</div>
	);

	const renderBusinessTypes = (businessTypes: IProspectBusinessType[]) =>
		businessTypes.map((businessType, index) => (
			<div key={index}>
				<Divider />

				<div className='items-start gap-2 lg:gap-4 lg:items-center grid sm:grid-cols-[1fr,200px]'>
					<div>
						<Title>{businessType.name}</Title>
						{businessType?.description && <Text>{businessType.description}</Text>}
					</div>

					<div className='mt-2 sm:mt-0'>{renderActionButtons(businessType)}</div>
				</div>
			</div>
		));

	return (
		<Fragment>
			<div className='px-2 py-6 sm:px-4'>
				<div className='flex items-center justify-between'>
					<div className='flex items-center gap-3'>
						<Metric className='hidden sm:block'>Prospecting</Metric>

						{/* Separator */}
						<span className='hidden sm:inline-block w-[1px] h-[40px] bg-black rotate-12' />

						<Title className='lg:mt-1'>Business Types</Title>
					</div>

					{hasPermission(PermissionKeys.CREATE_BUSINESS_TYPE) && (
						<Button className='!rounded-full' onClick={() => setEditBusinessTypeFormOpen(true)}>
							Create Business Type
						</Button>
					)}
				</div>

				<div>
					{hasPermission(PermissionKeys.VIEW_BUSINESS_TYPE) ? (
						<Fragment>
							{!businessTypes && isBusinessTypesLoading ? (
								<div className='mt-6'>
									<ContentLoadingCardSpinner message='Loading business types...' />
								</div>
							) : businessTypes && businessTypes.length > 0 ? (
								<Fragment>
									<div className='sm:grid-cols-[1fr,200px] hidden sm:grid sm:mt-6'>
										<h1 className='text-sm font-semibold text-gray-600 uppercase sm:text-xs'>Business Type</h1>
										<h1 className='text-sm font-semibold text-gray-600 uppercase sm:text-xs'>Action</h1>
									</div>

									{renderBusinessTypes(businessTypes)}
								</Fragment>
							) : (
								<SimpleEmptyResourceIndicator containerClassName='lg:mt-8' message='No business type found' />
							)}
						</Fragment>
					) : (
						<div className='mt-6'>
							<ContentBlockedFromView message='You are not authorized to view business types.' containerClassName='min-h-[350px]' />
						</div>
					)}
				</div>
			</div>

			<Modal
				width={600}
				closeButton={{ styles: 'bg-gray-200' }}
				isModalOpen={isEditBusinessTypeFormOpen}
				setModalOpen={setEditBusinessTypeFormOpen}
				onCloseModal={() => {
					setEditBusinessTypeFormOpen(false);
					setTimeout(() => setCurrentBusinessType(null), 500);
				}}
				shouldCloseOnOutsideClick={false}
			>
				<EditProspectBusinessTypeForm
					businessTypes={businessTypes}
					businessType={currentBusinessType}
					setCurrentBusinessType={setCurrentBusinessType}
					setEditBusinessTypeFormOpen={setEditBusinessTypeFormOpen}
					refetchBusinessTypes={refetchBusinessTypes}
				/>
			</Modal>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmDeleteBusinessTypeDialogOpen}
				setOpen={setConfirmDeleteBusinessTypeDialogOpen}
				message='Delete Business Type'
				subMessage={`Are you sure you want to delete ${titleCase(currentBusinessType?.name as string) ?? 'this  business type'}? This action cannot be reversed.`}
				onClose={() => {
					setTimeout(() => setCurrentBusinessType(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmDeleteBusinessType,
					label: 'Delete',
					isLoading: isDeleteBusinessTypeLoading,
					loadingText: 'Deleting',
					color: 'red',
				}}
				cancelButton={{
					action: () => setConfirmDeleteBusinessTypeDialogOpen(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-500/10`}>
						<HiExclamation className='w-6 h-6 text-red-500' aria-hidden='true' />
					</div>
				)}
			/>
		</Fragment>
	);
};

export default ProspectBusinessTypePage;
