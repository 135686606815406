import { Button, TextInput, Title } from '@tremor/react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { processReactHookFormErrors } from 'helpers';
import { ISourceType, ISourceTypeFormValues } from '../prospectSettingsInterfaces';
import { createSourceType, updateSourceType } from '../prospectSettingsQueuries';

interface Props {
	sourceType: ISourceType | null;
	refetchSourceTypes: () => void;
	setCurrentSourceType: (value: ISourceType | null) => void;
	setEditSourceTypeFormOpen: (value: boolean) => void;
}

const EditSourceTypeForm = (props: Props) => {
	const { sourceType, setCurrentSourceType, setEditSourceTypeFormOpen, refetchSourceTypes } = props;

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<ISourceTypeFormValues>({
		defaultValues: {
			name: sourceType?.name,
			description: sourceType?.description || '',
		},
	});

	// ============================
	// * Create Source Type
	// ============================
	const { isLoading: isCreateSourceTypeLoading, mutate: createSourceTypeMutation } = useMutation({
		mutationKey: ['authorization/create-sourceType'],
		mutationFn: (params: ISourceTypeFormValues) => createSourceType(params),
	});

	// ============================
	// * Update Source Type
	// ============================
	const { isLoading: isUpdateSourceTypeLoading, mutate: updateSourceTypeMutation } = useMutation({
		mutationKey: ['authorization/update-sourceType'],
		mutationFn: ({ formValues, sourceTypeId }: { formValues: ISourceTypeFormValues; sourceTypeId: number }) => updateSourceType({ formValues, sourceTypeId }),
	});

	const onSubmit = (formValues: ISourceTypeFormValues) => {
		if (sourceType)
			return updateSourceTypeMutation(
				{ formValues, sourceTypeId: sourceType.id },
				{
					onSuccess: () => {
						toast.success('User updated successfully!');
						setTimeout(() => setCurrentSourceType(null), 500);
						setEditSourceTypeFormOpen(false);
						refetchSourceTypes();
					},
					onError: (error) => {
						processReactHookFormErrors(error, 'Failed to update source type', setError);
					},
				}
			);

		createSourceTypeMutation(formValues, {
			onSuccess: () => {
				toast.success('User created successfully!');
				setEditSourceTypeFormOpen(false);
				refetchSourceTypes();
			},
			onError: (error) => {
				processReactHookFormErrors(error, 'Failed to create source type', setError);
			},
		});
	};

	return (
		<form className='bg-white' onSubmit={handleSubmit(onSubmit)}>
			<header className={`${sourceType ? 'bg-gradient-to-r from-gray-800 to-gray-500' : 'bg-gradient-to-r from-primary-600 to-primary-300'} bg-dark p-6 h-[80px] flex items-center gap-2`}>
				<Title className='text-white'>{sourceType ? 'Update' : 'Create'} Source Type</Title>
			</header>

			<section className='p-6 text-left space-y-6'>
				<div className='form-control'>
					<label htmlFor='name' className='required-field-indicator'>
						Name
					</label>

					<div>
						<TextInput
							type='text'
							id='name'
							{...register('name', {
								required: {
									value: true,
									message: 'Name of source type is required',
								},
							})}
							placeholder='Enter source type name'
							error={errors.name ? true : false}
							errorMessage={errors.name?.message}
						/>
					</div>
				</div>

				<div className='form-control'>
					<label htmlFor='description'>Description</label>

					<div>
						<textarea id='description' {...register('description')} placeholder='Describe source type' />
						{errors?.description && <span className='form-message mr-1 text-red-400'>{errors?.description?.message}</span>}
					</div>
				</div>
			</section>

			<footer className='p-6 flex items-center gap-2 justify-end bg-gray-100'>
				<Button
					onClick={() => {
						setEditSourceTypeFormOpen(false);
						setTimeout(() => setCurrentSourceType(null), 500);
					}}
					type='button'
					color='gray'
					variant='secondary'
					className='px-6 py-3 duration-300 !rounded-full'
				>
					Cancel
				</Button>

				<Button
					type='submit'
					loading={isCreateSourceTypeLoading || isUpdateSourceTypeLoading}
					loadingText={sourceType ? 'Updating' : 'Creating'}
					disabled={isCreateSourceTypeLoading || isUpdateSourceTypeLoading}
					color={sourceType ? 'gray' : undefined}
					className='px-6 py-3 duration-300 !rounded-full'
				>
					{sourceType ? 'Update Source Type' : 'Create Source Type'}
				</Button>
			</footer>
		</form>
	);
};

export default EditSourceTypeForm;
