import { createColumnHelper } from '@tanstack/react-table';
import { Button, Text, Title } from '@tremor/react';
import BottomSheet from 'components/BottomSheet';
import ContentBlockedFromView from 'components/ContentBlockedFromView';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import Filters from 'components/Filters';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import Table from 'components/Table';
import { authState } from 'features/auth/authSlice';
import { PermissionKeys } from 'features/auth/permissions';
import { IFetchProspectFilters, IFetchProspectsActionParams, IProspect } from 'features/prospects/prospectInterfaces';
import useFetchProspects from 'features/prospects/useFetchProspects';
import { DefaultRoles } from 'features/settings/authorization/authorizationInterface';
import useFetchBusinessTypes from 'features/settings/prospecting/businessType/useFetchBusinessTypes';
import { FilterComponentTypes } from 'features/settings/settingsInterfaces';
import useFetchUsers from 'features/settings/users/useFetchUsers';
import { formatDate, formatDateRangePickerValue } from 'helpers/dateHelpers';
import { convertJSONToExcel, findFarthestFutureDate, formatCurrency, hasPermission, hasRole, hasRoles, isAdmin } from 'helpers/index';
import { useAppSelector } from 'hooks/storeConnect';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { RiFilter2Fill } from 'react-icons/ri';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { buildURLFilters, isMarketer } from 'utils/index';

type Props = {};

const columnHelper = createColumnHelper<IProspect>();

const ProspectsPerBusinessTypeReportPage = (props: Props) => {
	const { users } = useFetchUsers({});
	const navigate = useNavigate();
	const { authUser } = useAppSelector(authState);
	const [isMobileFiltersOpen, setMobileFiltersOpen] = useState<boolean>(false);
	const [urlParams, setUrlParams] = useSearchParams();
	const [fetchParams, setFetchParams] = useState<IFetchProspectsActionParams>({
		filterBy: {
			userId: isMarketer() ? authUser?.id : undefined,
		},
		include: ['user', 'business_type'],
	});
	const { isProspectsLoading, prospects, paginationMetadata } = useFetchProspects({
		fetchParams,
		queryKey: ['reports/prospects', fetchParams],
	});

	const { businessTypes } = useFetchBusinessTypes({});

	// Manage url filters
	const dateRange = urlParams.get('dateRange') ?? '';
	const companyName = urlParams.get('companyName') ?? '';
	const dateType = urlParams.get('prospectId') ?? 'createdBetween';
	const businessTypeId = urlParams.get('businessTypeId') ?? '';
	const userId = !isAdmin() && hasRole(DefaultRoles.Marketer) ? authUser?.id : urlParams.get('userId') ?? '';
	useEffect(() => {
		const urlFilters = dateType
			? {
					[dateType]: dateRange || '',
					userId: userId || '',
					companyName: companyName || '',
					businessTypeId: businessTypeId || '',
			  }
			: {
					userId: userId || '',
					companyName: companyName || '',
					businessTypeId: businessTypeId || '',
			  };

		setFetchParams((prevValue) => ({
			...prevValue,
			filterBy: _.pickBy(urlFilters, _.identity) as any,
		}));

		// eslint-disable-next-line
	}, [dateRange, dateType, userId, companyName, businessTypeId]);

	const tableColumns = [
		columnHelper.accessor('company_name', {
			header: 'Company Name',
			cell: ({ row }) => <div className='sm:font-semibold whitespace-nowrap'>{row.original.company_name}</div>,
		}),
		columnHelper.accessor('business_type', {
			header: 'Business Type',
			cell: ({ row }) => {
				return <div className='text-blue-700 sm:font-semibold whitespace-nowrap'>{row.original.business_type?.name}</div>;
			},
		}),
		columnHelper.accessor('user', {
			header: 'Created By',
			cell: ({ row }) => {
				const { first_name, middle_name, last_name } = row.original.user;

				if (isMarketer()) {
					return <div className='sm:font-semibold whitespace-nowrap'>You</div>;
				}
				return (
					<div className='sm:font-semibold whitespace-nowrap'>
						{first_name} {middle_name ?? ''} {last_name}
					</div>
				);
			},
			enableHiding: false,
		}),
		columnHelper.accessor('last_interaction_date', {
			header: 'Last Interaction Date',
			cell: ({ row }) => formatDate(row.original.last_interaction_date, 'Jan 31, 1999') || 'N/A',
		}),
		columnHelper.accessor('created_at', {
			header: 'Date Created',
			cell: ({ row }) => formatDate(row.original.created_at, 'Jan 31, 1999') || 'N/A',
		}),
	];

	const onSubmitFilters = (filters: IFetchProspectFilters) => {
		const { dateRange, dateType } = filters;

		// Clear previously queried date filters
		const prevFetchFilters: any = {
			...fetchParams.filterBy,
		};
		const dateFilters = ['createdAt', 'lastInteractionDate', 'contactDate', 'createdBetween'];
		dateFilters.forEach((f) => delete prevFetchFilters[f]);

		// Push new filters to url
		const urlFilters =
			filters.dateRange && filters.dateType
				? {
						...prevFetchFilters,
						...filters,
						dateRange: formatDateRangePickerValue(dateRange as any),
						dateType,
				  }
				: { ...prevFetchFilters, ...filters };

		navigate(`/app/reports/business-types?${buildURLFilters(_.pickBy(urlFilters, _.identity))}`);
	};

	const exportReportToExcel = (prospects: IProspect[] | undefined) => {
		if (!prospects || prospects?.length === 0) return toast.error('No data to export. Please make sure your report has at least one record.');

		const sheetStructure = prospects.map((prospect) => {
			const { company_name, business_type, last_interaction_date, user, created_at } = prospect;

			return {
				'Company Name': company_name,
				'Business Type': business_type.name,
				'Created By': `${user?.first_name} ${user?.middle_name ?? ''} ${user?.last_name}`,
				'Last Interaction Date': formatDate(last_interaction_date, 'Jan 31, 1999') || 'N/A',
				'Date Created': formatDate(created_at, 'Jan 31, 1999') || 'N/A',
			};
		});
		convertJSONToExcel(sheetStructure, `Prospects Per Business Type`);
	};

	return (
		<div className='flex flex-col w-full overflow-hidden h-[calc(100vh-var(--appbar-height-mobile))] lg:h-[calc(100vh-var(--appbar-height-desktop))]'>
			<div className='h-[160px] px-4 py-4 bg-gray-100 border w-full flex items-start justify-between'>
				<div>
					<Title className='!font-bold text-gray-800'>Prospects Per Business Type</Title>
					<Text>List of all prospects for a selected business type</Text>
				</div>

				<div className='inline-flex rounded-md shadow-sm ' role='group'>
					<button
						onClick={() => exportReportToExcel(prospects)}
						type='button'
						className='px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-sky-700 focus:z-10 focus:ring-2 focus:ring-sky-700 focus:text-sky-700'
					>
						Export To Excel
					</button>

					{/* <button
						type='button'
						className='px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-sky-700 focus:z-10 focus:ring-2 focus:ring-sky-700 focus:text-sky-700'
					>
						Download PDF
					</button> */}
				</div>
			</div>

			<div className='relative w-full h-[calc(100%-160px)] bg-white'>
				<div className='absolute -top-14 bottom-0 inset-x-0 mx-4 pb-4 h-[calc(100%+60px)] bg-white shadow-sm border rounded-t-xl overflow-hidden'>
					<div className='h-[55px] sticky inset-x-0 top-0 z-20 flex items-center justify-between px-4 lg:px-2 py-2 bg-white border-b'>
						<p className='text-sm font-semibold lg:hidden'>{prospects && prospects?.length > 0 ? `${prospects.length} records` : '0 records'}</p>

						<div className='hidden lg:block'>
							<Filters
								filters={[
									{
										type: FilterComponentTypes.DateRangeWithSelect,
										label: 'Select Date',
										hideLabel: true,
										dateRange: {
											label: 'Date type',
											name: 'dateRange',
											placeholder: 'Select dates',
											type: FilterComponentTypes.DateRange,
											defaultValue: dateRange
												? {
														from: dateRange ? new Date(dateRange.split(',')[0]) : undefined,
														to: dateRange ? new Date(dateRange.split(',')[1]) : undefined,
												  }
												: undefined,
										},
										select: {
											label: 'Date type',
											name: 'dateType',
											type: FilterComponentTypes.Select,
											defaultValue: dateType || undefined,
											placeholder: 'Select type',
											dropdownOptions: [
												{
													id: 1,
													label: 'Date Created',
													value: 'createdBetween',
												},
												{
													id: 2,
													label: 'Last Interaction Date',
													value: 'lastInteractionDate',
												},
											],
										},
									},
									{
										type: FilterComponentTypes.Select,
										name: 'userId',
										hideLabel: true,
										defaultValue: userId?.toString(),
										label: 'Staff',
										dropdownOptions:
											users
												?.filter((user) => {
													// return user.roles.includes(DefaultRoles.Marketer) || user.roles.includes(DefaultRoles.MarketingManager)
													return !user.roles.includes(DefaultRoles.SuperAdmin);
												})
												.map((user: any) => ({
													id: user.id,
													label: `${user.first_name} ${user?.middle_name ?? ' '} ${user.last_name}`,
													value: user.id,
												})) ?? [],
										placeholder: 'Select staff',
										permission: PermissionKeys.VIEW_USER_FILTERS,
									},
									{
										label: 'Business Type',
										name: 'businessTypeId',
										hideLabel: true,
										type: FilterComponentTypes.Select,
										defaultValue: businessTypeId?.toString(),
										placeholder: 'Business type',
										dropdownOptions:
											_.uniqBy(businessTypes, 'name')?.map((businessType) => ({
												id: businessType.id,
												label: businessType.name,
												value: businessType.id,
											})) ?? [],
									},
									// {
									// 	label: 'Prospect',
									// 	name: 'companyName',
									// 	hideLabel: true,
									// 	type: FilterComponentTypes.Select,
									// 	defaultValue: dateType || undefined,
									// 	placeholder: 'Prospect',
									// 	dropdownOptions:
									// 		_.uniqBy(prospectsFilterData, 'company_name')?.map((prospect) => ({
									// 			id: prospect.id,
									// 			label: prospect.company_name,
									// 			value: prospect.company_name,
									// 		})) ?? [],
									// },
								]}
								submitButton={{
									className: `!bg-gray-800 hover:!bg-gray-600 text-white !capitalize border-0 duration-300`,
									label: 'Apply Filters',
									onSubmit: onSubmitFilters,
								}}
							/>
						</div>

						<div className='lg:hidden'>
							<Button
								color='gray'
								variant='secondary'
								onClick={() => setMobileFiltersOpen(true)}
								icon={RiFilter2Fill}
								className='!rounded-full hover:bg-gray-700 hover:!text-gray-100 duration-300 lg:hidden'
							>
								Filters
							</Button>
						</div>
					</div>

					<div className='h-[calc(100%-40px)] overflow-x-hidden overflow-y-auto w-full relative z-10'>
						{hasPermission(PermissionKeys.VIEW_PROSPECT) ? (
							<Fragment>
								{!prospects && isProspectsLoading ? (
									<div className='mt-6'>
										<ContentLoadingCardSpinner containerClassName='!shadow-none !border-0 !ring-0' message='Loading prospects...' />
									</div>
								) : prospects && prospects.length > 0 ? (
									<div className='w-full h-full overflow-x-hidden overflow-y-auto'>
										<div className='w-full top-0 inset-x-0 sticky shadow-sm flex justify-between items-center px-2 h-[36px] z-20 border-b bg-white'>
											<p className='hidden text-sm font-semibold lg:block '>{prospects && prospects?.length > 0 ? `${prospects.length} records` : '0 records'}</p>
										</div>

										<Table
											data={prospects}
											columns={tableColumns}
											pagination={{
												handlePageChange: (page: number) => {
													setFetchParams((prevValue) => ({
														...prevValue,
														page,
													}));
												},
												metadata: paginationMetadata,
											}}
										/>
									</div>
								) : (
									<SimpleEmptyResourceIndicator containerClassName='lg:mt-8 lg:!py-10 !shadow-none !border-0 !ring-0' message='No prospect found' />
								)}
							</Fragment>
						) : (
							<ContentBlockedFromView message='You are not authorized to view prospects.' containerClassName='min-h-[350px] !shadow-none !border-0 !ring-0' />
						)}
					</div>
				</div>
			</div>

			<BottomSheet
				isOpen={isMobileFiltersOpen}
				setOpen={setMobileFiltersOpen}
				closeOnOutSideClick
				header={{
					text: 'Filter Analytics',
				}}
			>
				<Filters
					filters={[
						{
							type: FilterComponentTypes.DateRangeWithSelect,
							label: 'Select Date',
							hideLabel: true,
							dateRange: {
								label: 'Date type',
								name: 'dateRange',
								placeholder: 'Select dates',
								type: FilterComponentTypes.DateRange,
								defaultValue: dateRange ? { from: new Date(dateRange.split(',')[0]), to: new Date(dateRange.split(',')[1]) } : undefined,
							},
							select: {
								label: 'Date type',
								name: 'dateType',
								type: FilterComponentTypes.Select,
								defaultValue: dateType || undefined,
								placeholder: 'Select type',
								dropdownOptions: [
									{
										id: 1,
										label: 'Date Created',
										value: 'createdBetween',
									},
									{
										id: 2,
										label: 'Last Interaction Date',
										value: 'lastInteractionDate',
									},
								],
							},
						},
						{
							type: FilterComponentTypes.Select,
							name: 'userId',
							hideLabel: true,
							defaultValue: userId?.toString(),
							label: 'Staff',
							dropdownOptions:
								users
									?.filter((user) => {
										// return user.roles.includes(DefaultRoles.Marketer) || user.roles.includes(DefaultRoles.MarketingManager)
										return !user.roles.includes(DefaultRoles.SuperAdmin);
									})
									.map((user: any) => ({
										id: user.id,
										label: `${user.first_name} ${user?.middle_name ?? ' '} ${user.last_name}`,
										value: user.id,
									})) ?? [],
							placeholder: 'Select staff',
							permission: PermissionKeys.VIEW_USER_FILTERS,
						},
						{
							label: 'Business Type',
							name: 'businessTypeId',
							hideLabel: true,
							type: FilterComponentTypes.Select,
							defaultValue: businessTypeId?.toString(),
							placeholder: 'Business type',
							dropdownOptions:
								_.uniqBy(businessTypes, 'name')?.map((businessType) => ({
									id: businessType.id,
									label: businessType.name,
									value: businessType.id,
								})) ?? [],
						},
						// {
						// 			label: 'Prospect',
						// 			name: 'companyName',
						// 			hideLabel: true,
						// 			type: FilterComponentTypes.Select,
						// 			defaultValue: dateType || undefined,
						// 			placeholder: 'Prospect',
						// 			dropdownOptions:
						// 				_.uniqBy(prospectsFilterData, 'company_name')?.map((prospect) => ({
						// 					id: prospect.id,
						// 					label: prospect.company_name,
						// 					value: prospect.company_name,
						// 				})) ?? [],
						// 		},
					]}
					submitButton={{
						className: `!bg-gray-800 hover:!bg-gray-600 text-white !capitalize border-0 duration-300`,
						label: 'Apply Filters',
						onSubmit: onSubmitFilters,
					}}
				/>
			</BottomSheet>
		</div>
	);
};

export default ProspectsPerBusinessTypeReportPage;
