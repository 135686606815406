import React, { ReactNode, useEffect, useRef } from 'react';

interface Props {
	children: ReactNode;
	onOutsideClick: () => void;
}

const OutsideClickHandler = (props: Props) => {
	const { children, onOutsideClick } = props;

	const wrapperRef: any = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) onOutsideClick();
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [onOutsideClick]);

	return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideClickHandler;
