import { useState, Fragment, ReactNode, useEffect } from 'react';
import BottomSheet from 'components/BottomSheet';
import ConfirmationDialog from 'components/ConfirmationDialog';
import Container from 'components/Container';
import { CancelAppointmentIcon, CarIcon, ConfirmationDialogIcon, DeleteIcon, EditIcon } from 'components/FormActionIcons';
import { MODAL_LEAVE_SPEED } from 'config';
import { FilterComponentTypes, GeneralFilterNames } from 'features/settings/settingsInterfaces';
import { formatDate, formatDateRangePickerValue } from 'helpers/dateHelpers';
import { getDefaultFilter, isMarketer, parseLocation } from 'utils/index';
import Filters from 'components/Filters';
import useDefaultFilters from 'hooks/useDefaultFilters';
import SlideOver from 'components/SlideOver';
import { IProspect } from 'features/prospects/prospectInterfaces';
import { IFetchVehicleRequestFilters, IFetchVehicleRequestsActionParams, IVehicleRequest, VehicleRequestStatuses } from './vehicleRequestsInterfaces';
import VehicleRequestForm from 'features/navigation/VehicleRequestForm';
import useFetchVehicleRequests from './useFetchVehicleRequests';
import { QueryObserverResult, useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { deleteVehicleRequest, updateVehicleRequestStatus } from './vehicleRequestQueries';
import { Badge, Button, Card, Title } from '@tremor/react';
import { BiFile, BiMessageSquareDots } from 'react-icons/bi';
import { RiFilter2Fill } from 'react-icons/ri';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import Table from 'components/Table';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import { createColumnHelper } from '@tanstack/react-table';
import { DropdownMenu } from 'components/DropdownMenu';
import { DefaultTableDropDownTrigger } from 'components/TableDropdownTrigger';
import { useAppSelector } from 'hooks/storeConnect';
import { TbCalendarCheck } from 'react-icons/tb';
import { AiOutlineStop } from 'react-icons/ai';
import { authState } from 'features/auth/authSlice';
import { hasPermission, hasPermissions, hasRole, hasRoles, isAdmin } from 'helpers/index';
import { DefaultRoles, defaultRoles } from 'features/settings/authorization/authorizationInterface';
import VehicleRequestComments from './VehicleRequestComments';
import { AxiosResponse } from 'axios';
import Modal from 'components/Modal';
import AllocateVehicleForm from '../vehicle-allocations/AllocateVehicleForm';
import { Link } from 'react-router-dom';
import { TiCancelOutline } from 'react-icons/ti';
import { PermissionKeys } from 'features/auth/permissions';
import ContentBlockedFromView from 'components/ContentBlockedFromView';
import useFetchUsers from 'features/settings/users/useFetchUsers';

interface IVehicleRequestTableColumns extends IVehicleRequest {
	action: ReactNode;
}

const columnHelper = createColumnHelper<IVehicleRequestTableColumns>();

const VehicleRequestsPage = () => {
	const { authUser } = useAppSelector(authState);

	const [fetchParams, setFetchParams] = useState<IFetchVehicleRequestsActionParams>({
		filterBy: {
			staffId: isMarketer() ? authUser?.id : undefined,
		},
		includes: ['prospect', 'comments', 'user', 'comments.user', 'vehicle_allocation', 'vehicle_allocation.transport_officer'],
	});
	const [isConfirmApproveVehicleRequestDialog, setConfirmApproveVehicleRequestDialog] = useState(false);
	const [isConfirmDeclineVehicleRequestDialog, setConfirmDeclineVehicleRequestDialog] = useState(false);
	const [isConfirmCancelVehicleRequestDialog, setConfirmCancelVehicleRequestDialog] = useState(false);
	const [isConfirmDeleteVehicleRequestDialogOpen, setConfirmDeleteVehicleRequestDialogOpen] = useState(false);
	const [isMobileFiltersOpen, setMobileFiltersOpen] = useState<boolean>(false);
	const [isVehicleRequestFormOpen, setVehicleRequestFormOpen] = useState(false);
	const [isVehicleAllocationFormOpen, setVehicleAllocationFormOpen] = useState(false);
	const [isCommentFormOpen, setCommentFormOpen] = useState(false);
	const [currentVehicleRequest, setCurrentVehicleRequest] = useState<IVehicleRequest | null>(null);
	const [currentProspect, setCurrentProspect] = useState<IProspect | null>(null);

	const { users } = useFetchUsers({});
	const { defaultNamedFilters } = useDefaultFilters();
	const { isVehicleRequestsLoading, refetchVehicleRequests, vehicleRequests, paginationMetadata } = useFetchVehicleRequests({
		fetchParams,
		queryKey: fetchParams,
	});
	useEffect(() => {
		if (!currentVehicleRequest || !vehicleRequests) return;

		const thisAppointment = vehicleRequests.find((request) => request.id === currentVehicleRequest?.id);
		setCurrentVehicleRequest(thisAppointment as any);
		// eslint-disable-next-line
	}, [vehicleRequests]);

	// ================================
	// * Update Vehicle Request Status
	// ================================
	const { isLoading: isUpdateVehicleRequestStatusLoading, mutate: updateVehicleRequestStatusMutation } = useMutation({
		mutationKey: ['vehicle-requests/update-vehicleRequestStatus'],
		mutationFn: (formValues: { vehicleRequestId: number; requestStatus: VehicleRequestStatuses }) => updateVehicleRequestStatus(formValues),
	});

	// =========================
	// * Delete Vehicle Request
	// =========================
	const { isLoading: isDeleteVehicleRequestLoading, mutate: deleteVehicleRequestMutation } = useMutation({
		mutationKey: ['authorization/delete-vehicleRequest'],
		mutationFn: (vehicleRequestId: number) => deleteVehicleRequest(vehicleRequestId),
	});

	const onSubmitFilters = (filters: IFetchVehicleRequestFilters) => {
		let selectedDateRange: any;

		if (filters.dateRange && filters.dateType) {
			selectedDateRange = { [filters.dateType]: formatDateRangePickerValue(filters.dateRange as any) };
		} else {
			selectedDateRange = {};
		}

		const prevFetchFilters: any = {
			...fetchParams.filterBy,
		};
		delete prevFetchFilters.createdAt;
		delete prevFetchFilters.departureDate;

		setFetchParams((prevValue) => ({
			...prevValue,
			filterBy: {
				...prevFetchFilters,
				...filters,
				...selectedDateRange,
			},
		}));
	};

	const onConfirmApproveVehicleRequest = () => {
		if (!currentVehicleRequest) return toast.error('No vehicle request selected for this action');

		updateVehicleRequestStatusMutation(
			{ vehicleRequestId: currentVehicleRequest.id, requestStatus: VehicleRequestStatuses.Approved },
			{
				onSuccess: () => {
					toast.success('Vehicle request has been approved successfully.');
				},
				onError: () => {
					toast.error('Failed to approve vehicle request.');
				},
				onSettled() {
					setTimeout(() => setCurrentVehicleRequest(null), MODAL_LEAVE_SPEED);
					refetchVehicleRequests();
				},
			}
		);

		setConfirmApproveVehicleRequestDialog(false);
	};

	const onConfirmDeclineVehicleRequest = () => {
		if (!currentVehicleRequest) return toast.error('No vehicle request selected for this action');

		updateVehicleRequestStatusMutation(
			{ vehicleRequestId: currentVehicleRequest.id, requestStatus: VehicleRequestStatuses.Declined },
			{
				onSuccess: () => {
					toast.success('Vehicle request has been declined successfully.');
				},
				onError: () => {
					toast.error('Failed to decline decline request.');
				},
				onSettled() {
					setTimeout(() => setCurrentVehicleRequest(null), MODAL_LEAVE_SPEED);
					refetchVehicleRequests();
				},
			}
		);

		setConfirmDeclineVehicleRequestDialog(false);
	};

	const onConfirmCancelVehicleRequest = () => {
		if (!currentVehicleRequest) return toast.error('No vehicle request selected for this action');

		updateVehicleRequestStatusMutation(
			{ vehicleRequestId: currentVehicleRequest.id, requestStatus: VehicleRequestStatuses.Cancelled },
			{
				onSuccess: () => {
					toast.success('Vehicle request has been cancelled successfully.');
				},
				onError: () => {
					toast.error('Failed to cancel vehicle equest.');
				},
				onSettled() {
					setTimeout(() => setCurrentVehicleRequest(null), MODAL_LEAVE_SPEED);
					refetchVehicleRequests();
				},
			}
		);

		setConfirmCancelVehicleRequestDialog(false);
	};

	const onConfirmDeleteVehicleRequest = () => {
		if (!currentVehicleRequest) return toast.error('No vehicle request selected for this action');

		deleteVehicleRequestMutation(currentVehicleRequest.id, {
			onSuccess: () => {
				toast.success('Vehicle request deleted successfully.');
			},
			onError: () => {
				toast.error('Failed to delete vehicle request.');
			},
			onSettled() {
				setTimeout(() => setCurrentVehicleRequest(null), MODAL_LEAVE_SPEED);
				refetchVehicleRequests();
			},
		});

		setConfirmDeleteVehicleRequestDialogOpen(false);
	};

	const tableColumns = [
		columnHelper.accessor('action', {
			header: 'Actions',
			cell: (info) => {
				const vehicleRequest: IVehicleRequest = info.row.original;
				const { request_status, vehicle_allocation } = vehicleRequest;

				const menuOptions: DropdownMenuOption[] = [];

				hasPermission(PermissionKeys.VIEW_VEHICLE_REQUEST) &&
					menuOptions.push({
						type: 'button',
						text: 'Add Comment',
						action: (e: any) => {
							setCurrentVehicleRequest(vehicleRequest);
							setCommentFormOpen(true);
							e.stopPropagation();
						},
						icon: <BiMessageSquareDots />,
					});

				if (request_status === VehicleRequestStatuses.Approved && hasPermission(PermissionKeys.UPDATE_VEHICLE_ALLOCATION))
					menuOptions.push({
						type: 'button',
						text: vehicle_allocation ? 'Update Allocation' : 'Allocate Vehicle',
						action: (e: any) => {
							setCurrentVehicleRequest(vehicleRequest);
							setVehicleAllocationFormOpen(true);
							e.stopPropagation();
						},
						icon: <CarIcon />,
					});

				if (hasPermission(PermissionKeys.UPDATE_VEHICLE_REQUEST) && request_status === VehicleRequestStatuses.Pending && !hasRoles([DefaultRoles.Marketer, DefaultRoles.MarketingManager]))
					menuOptions.push({
						type: 'button',
						text: 'Edit Request',
						action: (e: any) => {
							setCurrentVehicleRequest(vehicleRequest);
							if (vehicleRequest?.prospect) setCurrentProspect(vehicleRequest?.prospect);
							setVehicleRequestFormOpen(true);
							e.stopPropagation();
						},
						icon: <EditIcon variant='light' />,
					});

				if (hasPermission(PermissionKeys.UPDATE_VEHICLE_REQUEST_STATUS) && request_status !== VehicleRequestStatuses.Approved)
					menuOptions.push({
						type: 'button',
						text: 'Approve Request',
						action: (e: any) => {
							setCurrentVehicleRequest(vehicleRequest);
							setConfirmApproveVehicleRequestDialog(true);
							e.stopPropagation();
						},
						icon: <TbCalendarCheck />,
					});

				if (hasPermission(PermissionKeys.UPDATE_VEHICLE_REQUEST_STATUS) && request_status !== VehicleRequestStatuses.Declined)
					menuOptions.push({
						type: 'button',
						text: 'Decline Request',
						action: (e: any) => {
							setCurrentVehicleRequest(vehicleRequest);
							setConfirmDeclineVehicleRequestDialog(true);
							e.stopPropagation();
						},
						icon: <AiOutlineStop />,
					});

				if (hasPermission(PermissionKeys.DELETE_VEHICLE_REQUEST))
					menuOptions.push({
						type: 'button',
						text: 'Delete Request',
						action: (e: any) => {
							setCurrentVehicleRequest(vehicleRequest);
							setConfirmDeleteVehicleRequestDialogOpen(true);
							e.stopPropagation();
						},
						icon: <DeleteIcon variant='light' />,
					});

				if (menuOptions.length === 0) {
					menuOptions.push({
						type: 'button',
						text: 'NOT AUTHORIZED',
						action: (e: any) => toast.error('You are not authorized perform any action on vehicle requests'),
						icon: <TiCancelOutline />,
					});
				}

				return <DropdownMenu renderTriggerElement={(state) => <DefaultTableDropDownTrigger state={state} />} menuOptions={menuOptions} />;
			},
		}),
		columnHelper.accessor('user', {
			header: 'Staff',
			cell: ({ row }) => (
				<div className='sm:font-semibold'>
					{row.original.user.first_name} {row.original.user.middle_name} {row.original.user.last_name}
				</div>
			),
		}),
		columnHelper.accessor('comments', {
			header: 'Comments',
			cell: ({ row }) => {
				const comments = row.original.comments;

				return (
					<button
						onClick={() => {
							setCommentFormOpen(true);
							setCurrentVehicleRequest(row.original);
						}}
						type='button'
						className='flex items-center gap-2'
					>
						<div className='flex items-center justify-center w-8 h-8 p-1 font-semibold border rounded-full text-primary-700 bg-primary-50 border-primary-100'>{comments?.length}</div>
						{/* Comment{comments.length > 0 ? 's' : ''} */}
					</button>
				);
			},
		}),
		columnHelper.accessor('reason', {
			header: 'Reason',
		}),
		columnHelper.accessor('request_status', {
			header: 'Request Status',
			cell: ({ row }) => {
				const { request_status } = row.original;

				return (
					<Badge
						size='sm'
						className={`ml-1 text-white ${
							request_status === VehicleRequestStatuses.Approved
								? 'bg-green-600'
								: request_status === VehicleRequestStatuses.Pending
								? 'bg-yellow-500'
								: request_status === VehicleRequestStatuses.Declined
								? 'bg-red-600'
								: 'bg-gray-700'
						}`}
					>
						{request_status}
					</Badge>
				);
			},
		}),
		columnHelper.accessor('request_type', {
			header: 'Request Type',
			cell: ({ row }) => <div className='sm:font-semibold'>{row.original.request_type}</div>,
		}),
		columnHelper.accessor('prospect', {
			header: 'Prospect',
			cell: ({ row }) => (row.original.prospect ? <div>{row.original.prospect?.company_name ?? 'N/A'}</div> : <div>N/A</div>),
		}),
		columnHelper.accessor('destination', {
			header: 'Destination',
			cell: ({ row }) => {
				const destination = row.original.destination;
				const { url, name } = parseLocation(destination);

				return url ? (
					<Link title='Open in google map' to={url} target='_blank' className='hover:text-blue-600 hover:underline sm:w-[100px] lg:w-[150px] sm:line-clamp-2 sm:text-ellipsis capitalize'>
						{name}
					</Link>
				) : (
					name || 'No location available'
				);
			},
		}),
		columnHelper.accessor('vehicle_allocation.transport_officer_id', {
			header: 'Transport Officer',
			cell: ({ row }) => {
				const transportOfficer = row.original.vehicle_allocation?.transport_officer;

				return transportOfficer ? (
					<div className='font-semibold'>
						{transportOfficer?.first_name} {transportOfficer?.middle_name} {transportOfficer?.last_name}
					</div>
				) : (
					<Badge size='sm' className={`ml-1 text-white bg-black`}>
						Not Assigned
					</Badge>
				);
			},
		}),
		columnHelper.accessor('departure_date', {
			header: 'Departure',
			cell: ({ row }) => {
				return <div className=''>{formatDate(row.original.departure_date, 'Mon, 31st Jan 1999 at 12:00AM')}</div>;
			},
		}),
		columnHelper.accessor('created_at', {
			header: 'Date Created',
			cell: ({ row }) => formatDate(row.original.created_at, 'Jan 31, 1999'),
		}),
	];

	const resetCurrentVehicleRequest = async () => {
		const vehicleRequestsResponse: QueryObserverResult = await refetchVehicleRequests();
		const axiosResponse: AxiosResponse = vehicleRequestsResponse?.data as any;
		const vehicleRequests: IVehicleRequest[] | undefined = axiosResponse?.data?.data;

		if (!vehicleRequests || !currentVehicleRequest) return;

		const thisVehicleRequest = vehicleRequests.find((request) => request.id === currentVehicleRequest?.id);
		setCurrentVehicleRequest(thisVehicleRequest as any);
	};

	return (
		<Container className='pb-4 lg:pb-0 lg:pl-6 lg:pr-0 !h-[100%]'>
			<div className='h-full pl-px overflow-y-auto lg:pr-6 with-scrollbar'>
				<div className='flex items-center justify-between py-4 border-b lg:border-none lg:pb-0'>
					<Title className='!text-2xl'>Vehicle Requests</Title>

					{hasPermission(PermissionKeys.CREATE_VEHICLE_REQUEST) && (
						<Button
							className='!rounded-full hidden lg:inline-flex'
							icon={BiFile}
							onClick={() => {
								setCurrentProspect(null);
								setCurrentVehicleRequest(null);
								setVehicleRequestFormOpen(true);
							}}
						>
							Request Vehicle
						</Button>
					)}
				</div>

				{hasPermission(PermissionKeys.VIEW_VEHICLE_REQUEST) ? (
					<Fragment>
						<div className='flex items-center justify-between w-full gap-4 mt-6'>
							<Button
								color='gray'
								variant='secondary'
								onClick={() => setMobileFiltersOpen(true)}
								icon={RiFilter2Fill}
								className='!rounded-full hover:bg-gray-700 hover:!text-gray-100 duration-300 lg:hidden'
							>
								Filters
							</Button>

							<div className='hidden lg:flex lg:items-end'>
								<Filters
									filters={[
										{
											type: FilterComponentTypes.Date,
											name: 'departureDate',
											label: 'Departure Date',
											placeholder: 'Select date',
										},
										{
											type: FilterComponentTypes.Select,
											label: 'Select Status',
											name: 'requestStatus',
											placeholder: 'Filter by status',
											dropdownOptions: [
												{
													id: 1,
													label: VehicleRequestStatuses.Approved,
													value: VehicleRequestStatuses.Approved,
												},
												{
													id: 2,
													label: VehicleRequestStatuses.Pending,
													value: VehicleRequestStatuses.Pending,
												},
												{
													id: 3,
													label: VehicleRequestStatuses.Declined,
													value: VehicleRequestStatuses.Declined,
												},
											],
										},
										{
											type: FilterComponentTypes.Select,
											name: 'userId',
											label: 'Staff',
											dropdownOptions:
												users
													?.filter((user) => {
														// return user.roles.includes(DefaultRoles.Marketer) || user.roles.includes(DefaultRoles.MarketingManager);
														return hasPermissions([PermissionKeys.CREATE_VEHICLE_REQUEST]) && !user.roles.includes(DefaultRoles.SuperAdmin);
													})
													.map((user: any) => ({
														id: user.id,
														label: `${user.first_name} ${user?.middle_name ?? ' '} ${user.last_name}`,
														value: user.id,
													})) ?? [],
											placeholder: 'Select staff',
											permission: PermissionKeys.VIEW_USER_FILTERS,
										},
									]}
									submitButton={{
										label: 'Apply Filters',
										onSubmit: onSubmitFilters,
									}}
								/>
							</div>

							{hasPermission(PermissionKeys.CREATE_VEHICLE_REQUEST) && (
								<Button
									className='!rounded-full lg:hidden'
									icon={BiFile}
									onClick={() => {
										setCurrentVehicleRequest(null);
										setCurrentVehicleRequest(null);
										setVehicleRequestFormOpen(true);
									}}
								>
									Request Vehicle
								</Button>
							)}
						</div>

						<div>
							<Fragment>
								{!vehicleRequests && isVehicleRequestsLoading ? (
									<div className='mt-6'>
										<ContentLoadingCardSpinner message='Loading vehicle requests...' />
									</div>
								) : vehicleRequests && vehicleRequests.length > 0 ? (
									<div className='mt-8'>
										<Card className='!p-0 pt-6 overflow-hidden'>
											<Title className='px-4 py-4 border-b'>All Prospects</Title>

											<Table
												data={vehicleRequests}
												columns={tableColumns}
												pagination={{
													handlePageChange: (page: number) => {
														setFetchParams((prevValue) => ({
															...prevValue,
															page,
														}));
													},
													metadata: paginationMetadata,
												}}
											/>
										</Card>
									</div>
								) : (
									<SimpleEmptyResourceIndicator containerClassName='lg:mt-8 lg:!py-10' message='No prospect found' />
								)}
							</Fragment>
						</div>
					</Fragment>
				) : (
					<div className='mt-6'>
						<ContentBlockedFromView message='You are not authorized to view appointments.' containerClassName='min-h-[350px]' />
					</div>
				)}
			</div>

			<BottomSheet
				isOpen={isMobileFiltersOpen}
				setOpen={setMobileFiltersOpen}
				closeOnOutSideClick
				header={{
					text: 'Filter Prospects',
				}}
			>
				<Filters
					filters={[
						{
							type: FilterComponentTypes.Date,
							name: 'departureDate',
							label: 'Departure Date',
							placeholder: 'Select date',
						},
						{
							type: FilterComponentTypes.Select,
							label: 'Select Status',
							name: 'requestStatus',
							placeholder: 'Filter by status',
							dropdownOptions: [
								{
									id: 1,
									label: VehicleRequestStatuses.Approved,
									value: VehicleRequestStatuses.Approved,
								},
								{
									id: 2,
									label: VehicleRequestStatuses.Pending,
									value: VehicleRequestStatuses.Pending,
								},
								{
									id: 3,
									label: VehicleRequestStatuses.Declined,
									value: VehicleRequestStatuses.Declined,
								},
							],
						},
						{
							type: FilterComponentTypes.Select,
							name: 'userId',
							label: 'Staff',
							dropdownOptions:
								users
									?.filter((user) => {
										// return user.roles.includes(DefaultRoles.Marketer) || user.roles.includes(DefaultRoles.MarketingManager);
										return hasPermissions([PermissionKeys.CREATE_VEHICLE_REQUEST]) && !user.roles.includes(DefaultRoles.SuperAdmin);
									})
									.map((user: any) => ({
										id: user.id,
										label: `${user.first_name} ${user?.middle_name ?? ' '} ${user.last_name}`,
										value: user.id,
									})) ?? [],
							placeholder: 'Select staff',
							permission: PermissionKeys.VIEW_USER_FILTERS,
						},
					]}
					submitButton={{
						label: 'Apply Filters',
						onSubmit: onSubmitFilters,
					}}
				/>
			</BottomSheet>

			<Modal
				width={500}
				isModalOpen={isVehicleAllocationFormOpen}
				setModalOpen={setVehicleAllocationFormOpen}
				onCloseModal={() => {
					setVehicleAllocationFormOpen(false);
					setTimeout(() => setCurrentProspect(null), 500);
				}}
			>
				<AllocateVehicleForm
					vehicleRequest={currentVehicleRequest as IVehicleRequest}
					onCloseForm={() => {
						setVehicleAllocationFormOpen(false);
						setTimeout(() => setCurrentProspect(null), 500);
					}}
					afterEdit={() => {
						refetchVehicleRequests();
						setVehicleAllocationFormOpen(false);
						setTimeout(() => setCurrentProspect(null), 500);
					}}
				/>
			</Modal>

			<SlideOver
				title={{
					text: 'Request Vehicle',
					theme: currentVehicleRequest ? 'dark' : 'primary',
					styles: 'text-gray-100',
				}}
				subTitle={{
					text: `${currentVehicleRequest ? 'Update vehicle request' : 'Request vehicle'} for prospecting ${currentProspect?.company_name ? `on ${currentProspect.company_name}` : ''}.`,
					styles: 'text-gray-200',
				}}
				onClose={() => null}
				afterClose={() => {
					setTimeout(() => {
						setCurrentProspect(null);
						setCurrentVehicleRequest(null);
					}, MODAL_LEAVE_SPEED);
				}}
				open={isVehicleRequestFormOpen}
				setOpen={setVehicleRequestFormOpen}
				width={450}
				isScrollable
				shouldCloseOnOutsideClick={false}
			>
				<VehicleRequestForm
					vehicleRequest={currentVehicleRequest}
					afterEdit={() => {
						setVehicleRequestFormOpen(false);
						refetchVehicleRequests();
					}}
				/>
			</SlideOver>

			<SlideOver
				title={{
					text: 'Add Comment',
					theme: 'primary',
					styles: 'text-gray-100',
				}}
				subTitle={{
					text: `Pass comments on ${currentVehicleRequest?.request_type} vehicle request`,
					styles: 'text-gray-200',
				}}
				onClose={() => null}
				afterClose={() => {
					setTimeout(() => {
						setCurrentVehicleRequest(null);
					}, MODAL_LEAVE_SPEED);
				}}
				open={isCommentFormOpen}
				setOpen={setCommentFormOpen}
				width={450}
				isScrollable={false}
				shouldCloseOnOutsideClick={false}
			>
				<VehicleRequestComments
					vehicleRequest={currentVehicleRequest as IVehicleRequest}
					afterChange={async () => {
						await resetCurrentVehicleRequest();
					}}
				/>
			</SlideOver>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmApproveVehicleRequestDialog}
				setOpen={setConfirmApproveVehicleRequestDialog}
				message='Approve Vehicle Request'
				subMessage={`Are you sure you want to approve vehicle request scheduled at ${formatDate(currentVehicleRequest?.departure_date, 'Jan 31, 1999')}. This action cannot be reversed.`}
				onClose={() => {
					setTimeout(() => setCurrentVehicleRequest(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmApproveVehicleRequest,
					label: 'Approve',
					isLoading: isUpdateVehicleRequestStatusLoading,
					loadingText: 'Approving',
					color: 'green',
				}}
				cancelButton={{
					action: () => setConfirmApproveVehicleRequestDialog(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-500/10`}>
						<ConfirmationDialogIcon className='text-green-500' />
					</div>
				)}
			/>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmDeclineVehicleRequestDialog}
				setOpen={setConfirmDeclineVehicleRequestDialog}
				message='Decline Vehicle Request'
				subMessage={`Are you sure you want to decline vehicle request scheduled at ${formatDate(currentVehicleRequest?.departure_date, 'Jan 31, 1999')}. This action cannot be reversed.`}
				onClose={() => {
					setTimeout(() => setCurrentVehicleRequest(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmDeclineVehicleRequest,
					label: 'Decline',
					isLoading: isUpdateVehicleRequestStatusLoading,
					loadingText: 'Declining',
					color: 'rose',
				}}
				cancelButton={{
					action: () => setConfirmDeclineVehicleRequestDialog(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-rose-500/10`}>
						<ConfirmationDialogIcon className='text-rose-500' />
					</div>
				)}
			/>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmDeleteVehicleRequestDialogOpen}
				setOpen={setConfirmDeleteVehicleRequestDialogOpen}
				message='Delete Vehicle Request'
				subMessage={`Are you sure you want to delete vehicle request scheduled at ${formatDate(currentVehicleRequest?.departure_date, 'Jan 31, 1999')}. This action cannot be reversed.`}
				onClose={() => {
					setTimeout(() => setCurrentVehicleRequest(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmDeleteVehicleRequest,
					label: 'Delete',
					isLoading: isDeleteVehicleRequestLoading,
					loadingText: 'Deleting',
					color: 'red',
				}}
				cancelButton={{
					action: () => setConfirmDeleteVehicleRequestDialogOpen(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-500/10`}>
						<ConfirmationDialogIcon className='text-red-500' />
					</div>
				)}
			/>
		</Container>
	);
};

export default VehicleRequestsPage;
