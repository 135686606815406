import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/index';
import SliceNames from 'store/sliceNames';
import {IAuthState, ILoginActionResponse, IUser, UserAccountState, UserDurationStatus} from './authInterfaces';

const initialState: IAuthState = {
	token: null,
	authUser: null,
	isAuthenticated: false,
	isNewUser: false,
	isUserBlocked: false,
	users: null,
	authError: null,
};

export const authSlice = createSlice({
	name: SliceNames.Auth,
	initialState,
	reducers: {
		login: (state, { payload }: PayloadAction<ILoginActionResponse>) => {
			localStorage.setItem('token', payload.data.token);
			state.token = payload.data.token;
			state.isAuthenticated = true;
			state.authUser = payload.data.user as any;
			state.isNewUser = payload.data.user.new_user === UserDurationStatus.New;
			state.isUserBlocked = payload.data.user.status === UserAccountState.Disabled;
		},
		updateUserProfile: (state, {payload} : PayloadAction<IUser>) => {
			state.authUser = {
				...state.authUser,
				...payload,
			}
		},
		logout: (state) => {
			localStorage.removeItem('token');
			state.token = initialState.token;
			state.authUser = initialState.authUser;
			state.isAuthenticated = initialState.isAuthenticated;
			state.users = initialState.users;
			state.authError = initialState.authError;
		},
	},
});

export const { login, logout, updateUserProfile } = authSlice.actions;
export default authSlice.reducer;
export const authState = (state: RootState) => state.auth;
