import { lsmsAPI } from 'ajax/httpClient';
import {
	IChangePasswordActionParams,
	ICreateUserFormValues,
	ICreateUserTargetActionParams,
	IFetchUserAccountsActionParams,
	IForgotPasswordActionTypes,
	ILoginActionParams,
	ILoginActionResponse,
	IResetPasswordFormTypes,
} from './authInterfaces';
import { AxiosResponse } from 'axios';

export const login = (formValues: ILoginActionParams): Promise<AxiosResponse<ILoginActionResponse>> =>
	lsmsAPI.post('/auth/login', formValues, {
		params: {
			include: 'branch,department',
		},
	});

export const logout = () => lsmsAPI.post(`/auth/logout`, {});

export const forgotPassword = (formValues: IForgotPasswordActionTypes) =>
	lsmsAPI.post('/auth/send-reset-link', formValues, {
		headers: {
			Accept: 'application/json',
		},
	});

export const resetPassword = (formValues: IResetPasswordFormTypes) => lsmsAPI.post('/auth/reset-password', formValues);

export const fetchUserProfile = () => lsmsAPI.get(`/user/get-profile`);

export const updateUserPassword = (formValues: IChangePasswordActionParams) => lsmsAPI.post(`/user/change-password`, formValues);

// ===============
// User Accounts
// ===============
export const fetchUsers = (params: IFetchUserAccountsActionParams) => {
	const { filterBy, includes, page } = params || {};
	const { name, roleId } = filterBy || {};

	return lsmsAPI.get('/admin/users', {
		params: {
			'filter[name]': name,
			'filter[role_id]': roleId,
			include: includes?.join(','),
			page: page ?? 1,
			per_page: 1_000_000_000,
		},
	});
};

export const createUser = (formValues: ICreateUserFormValues) => lsmsAPI.post('/admin/users', formValues);

export const updateUser = ({ formValues, userId }: { formValues: ICreateUserFormValues; userId: number }) => lsmsAPI.put(`/admin/users/${userId}`, formValues);

export const deleteUser = (userId: number) => lsmsAPI.delete(`/admin/users/${userId}`);

// ==============
// User Targets
// ==============
export const fetchUserTargets = () => lsmsAPI.get('/user/targets');

export const createUserTarget = (formValues: ICreateUserTargetActionParams) => lsmsAPI.post('/user/targets', formValues);

export const updateUserTarget = ({ formValues, targetId }: { formValues: ICreateUserTargetActionParams; targetId: number }) => lsmsAPI.put(`/user/targets/${targetId}`, formValues);

export const deleteUserTarget = (targetId: number) => lsmsAPI.delete(`/user/targets/${targetId}`);
