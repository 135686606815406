import { FollowUpCategories, IReport } from 'features/reports/reportInterfaces';
import { hasRole, isAdmin } from 'helpers/index';
import { DefaultRoles } from 'features/settings/authorization/authorizationInterface';
import { useAppSelector } from 'hooks/storeConnect';
import { authState } from 'features/auth/authSlice';
import StatisticsCardGroup from 'components/StatisticsCard/StatisticsCardGroup';
import StatisticsCardItem from 'components/StatisticsCard/StatisticsCardItem';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IMarketingManagerDashboardData } from 'features/dashboard/dashboardInterfaces';

interface Props {
	data: IMarketingManagerDashboardData | undefined;
	isDataLoading: boolean;
	reports: IReport | undefined;
	isReportsLoading: boolean;
}

const DefaultReportMetrics = ({ data, isDataLoading, reports, isReportsLoading }: Props) => {
	const [urlParams, setUrlParams] = useSearchParams();
	const navigate = useNavigate();
	const { authUser } = useAppSelector(authState);
	if (!authUser) return <div></div>;

	// Todo: Change first name to user id
	const prospectClosingPercentage = reports?.closing_percentage.find((percentage) => percentage.name.toLowerCase().includes(authUser.first_name.toLowerCase()))?.closing_percentage ?? 0;

	// Manage url filters
	const dateRange = urlParams.get('dateRange') ?? '';
	const userId = urlParams.get('userId') ?? '';

	return (
		<StatisticsCardGroup alt>
			{(isAdmin() || hasRole(DefaultRoles.MarketingManager)) && (
				<StatisticsCardItem
					action={{
						onClick: (data: any) => {
							navigate(`/app/reports/prospects?dateRange=${dateRange}&userId=${userId}`);
						},
					}}
					alt
					title='Total Prospects'
					metric={(data?.total_prospects ?? 0) as any}
					loading={isDataLoading}
				/>
			)}

			<StatisticsCardItem
				alt
				action={{
					onClick: (data: any) => {
						navigate(`/app/reports/expected-revenue?dateRange=${dateRange}&userId=${userId}`);
					},
				}}
				unitPrefix='GH₵'
				title='Total Expected Revenue'
				metric={(data?.total_expected_revenue ?? 0) as any}
				loading={isDataLoading}
			/>

			<StatisticsCardItem
				alt
				action={{
					onClick: (data: any) => {
						navigate(`/app/reports/achieved-revenue?dateRange=${dateRange}&userId=${userId}`);
					},
				}}
				unitPrefix='GH₵'
				title='Total Achieved Revenue'
				metric={(data?.total_achieved_revenue ?? 0) as any}
				loading={isDataLoading}
			/>

			<StatisticsCardItem
				alt
				action={{
					onClick: (data: any) => {
						navigate(`/app/reports/interactions?dateRange=${dateRange}&userId=${userId}`);
					},
				}}
				title='Total Follow Ups'
				metric={((reports?.total_interactions[0].after_win || 0) + (reports?.total_interactions[0].before_win || 0) ?? 0) as any}
				loading={isDataLoading}
			/>
			<StatisticsCardItem
				alt
				action={{
					onClick: (data: any) => {
						navigate(`/app/reports/interactions?dateRange=${dateRange}&userId=${userId}`);
					},
				}}
				title='Follow Ups After Win'
				metric={(reports?.total_interactions[0].after_win ?? 0) as any}
				loading={isDataLoading}
			/>
			<StatisticsCardItem
				alt
				action={{
					onClick: (data: any) => {
						navigate(`/app/reports/interactions?dateRange=${dateRange}&userId=${userId}`);
					},
				}}
				title='Follow Ups Before Win'
				metric={(reports?.total_interactions[0].before_win ?? 0) as any}
				loading={isDataLoading}
			/>

			<StatisticsCardItem
				alt
				action={{
					onClick: (data: any) => {
						navigate(`/app/reports/follow-ups?status=${FollowUpCategories.Pending}&dateRange=${dateRange}&userId=${userId}`);
					},
				}}
				title='Total Pending Reminders'
				metric={(reports?.total_follow_ups[reports?.total_follow_ups.length - 1]?.pending ?? 0) as any}
				loading={isDataLoading}
			/>
			<StatisticsCardItem
				alt
				action={{
					onClick: (data: any) => {
						navigate(`/app/reports/follow-ups?status=${FollowUpCategories.Completed}&dateRange=${dateRange}&userId=${userId}`);
					},
				}}
				title='Total Completed Reminders'
				metric={(reports?.total_follow_ups[reports?.total_follow_ups.length - 1]?.done ?? 0) as any}
				loading={isDataLoading}
			/>

			<StatisticsCardItem
				alt
				// action={{
				// 	onClick: (data: any) => {
				// 		navigate(`/app/reports/average-achieved-premium?dateRange=${dateRange}&userId=${userId}`);
				// 	},
				// }}
				unitPrefix='GH₵'
				title='Average Achieved Premium'
				metric={(reports?.total_aggregate.avg_achieved_premium ?? 0) as any}
				loading={isReportsLoading}
			/>

			<StatisticsCardItem
				alt
				// action={{
				// 	onClick: (data: any) => {
				// 		navigate(`/app/reports/number-of-won-opportunities?dateRange=${dateRange}&userId=${userId}`);
				// 	},
				// }}
				title='Number of Won Opportunities'
				metric={(reports?.total_aggregate.won_opportunities ?? 0) as any}
				loading={isReportsLoading}
			/>

			<StatisticsCardItem
				alt
				action={{
					onClick: (data: any) => {
						navigate(`/app/reports/yearly-targets?dateRange=${dateRange}&userId=${userId}`);
					},
				}}
				unitPrefix='GH₵'
				title='Yearly Target'
				metric={(data?.total_yearly_target ?? 0) as any}
				loading={isDataLoading}
			/>

			<StatisticsCardItem
				alt
				action={
					isAdmin() || hasRole(DefaultRoles.MarketingManager)
						? {
								// hoverMessage: 'View all staff closing percentage',
								onClick(data) {
									navigate(`/app/reports/staff-closing-percentage?dateRange=${dateRange}&userId=${userId}`);
								},
								actionLabel: 'View for all staff',
								className: `!text-cyan-500 hover:!text-blue-500`,
						  }
						: undefined
				}
				title='Your Prospects Closing Percentage'
				unitSuffix='%'
				metric={(prospectClosingPercentage ?? 0) as any}
				loading={isReportsLoading}
			/>
		</StatisticsCardGroup>
	);
};

export default DefaultReportMetrics;
