import { IProspectsBySaleStage, IReport } from 'features/reports/reportInterfaces';
import { useAppSelector } from 'hooks/storeConnect';
import { authState } from 'features/auth/authSlice';
import StatisticsCardGroup from 'components/StatisticsCard/StatisticsCardGroup';
import StatisticsCardItem from 'components/StatisticsCard/StatisticsCardItem';
import { Text, Title, Card } from '@tremor/react';
import { RiLoader4Line } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import useFetchBusinessTypes from 'features/settings/prospecting/businessType/useFetchBusinessTypes';

interface Props {
	isReportLoading: boolean;
	prospectsByBusinessType: IReport['prospects_by_busines_type'] | undefined;
}

const ProspectBusinessTypesAnalytics = ({ isReportLoading, prospectsByBusinessType }: Props) => {
	const navigate = useNavigate();
	const { businessTypes } = useFetchBusinessTypes({});
	const { authUser } = useAppSelector(authState);
	if (!authUser) return <div></div>;

	return (
		<div>
			<Title className='mb-2 !text-base'>Prospect Business Types</Title>

			{isReportLoading ? (
				<Card className='flex flex-col items-center justify-center gap-4 p-10'>
					<RiLoader4Line className='w-8 h-8 text-lg text-gray-400 animate animate-spin ' />
					<Text className='!text-base'>Loading business types...</Text>
				</Card>
			) : prospectsByBusinessType && prospectsByBusinessType.length > 0 ? (
				<div className=''>
					<StatisticsCardGroup alt>
						{prospectsByBusinessType.map((businessType: IProspectsBySaleStage, index: number) => {
							const businessTypeId: any = businessTypes?.find((b) => b.name.toLowerCase().includes(businessType.name.toLowerCase()))?.id ?? '';

							return (
								<StatisticsCardItem
									alt
									action={{
										onClick: (data: any) => {
											navigate(`/app/reports/business-types?businessTypeId=${businessTypeId}`);
										},
									}}
									key={index}
									title={businessType.name}
									metric={businessType?.count.toString()}
								/>
							);
						})}
					</StatisticsCardGroup>
				</div>
			) : (
				<Card className='flex items-center justify-center w-full h-full'>
					<Text className={'!text-base font-semibold'}>No business type found</Text>
				</Card>
			)}
		</div>
	);
};

export default ProspectBusinessTypesAnalytics;
