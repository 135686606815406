import { useState, Fragment, ReactNode } from 'react';
import Container from 'components/Container';
import useFetchUsers from './useFetchUsers';
import { Badge, Button, Card, Metric, Text, Title } from '@tremor/react';
import Modal from 'components/Modal';
import ConfirmationDialog from 'components/ConfirmationDialog';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import { createColumnHelper } from '@tanstack/react-table';
import { IUser, IFetchUserAccountsActionParams, UserAccountState } from 'features/auth/authInterfaces';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import { MODAL_LEAVE_SPEED } from 'config';
import { formatCurrency, hasPermission, titleCase } from 'helpers';
import EditUserForm from './EditUserForm';
import { deleteUser } from 'features/auth/authQueries';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { HiExclamation } from 'react-icons/hi';
import { DropdownMenu } from 'components/DropdownMenu';
import { DefaultTableDropDownTrigger } from 'components/TableDropdownTrigger';
import { DeleteIcon, UserIcon, ViewIcon } from 'components/FormActionIcons';
import Table from 'components/Table';
import SlideOver from 'components/SlideOver';
import UserTargetsSection from './UserTargetsSection';
import SearchInput from 'components/SearchInput';
import { TiCancelOutline } from 'react-icons/ti';
import { PermissionKeys } from 'features/auth/permissions';

interface IUserTableColumns extends IUser {
	action: ReactNode;
}

const columnHelper = createColumnHelper<IUserTableColumns>();

const UserAccountsManagementPage = () => {
	const [fetchParams, setFetchParams] = useState<IFetchUserAccountsActionParams>({});
	const [isEditUserFormOpen, setEditUserFormOpen] = useState(false);
	const [isConfirmDeleteUserDialogOpen, setConfirmDeleteUserDialogOpen] = useState(false);
	const [currentUser, setCurrentUser] = useState<IUser | null>(null);
	const [isUserTargetsSliderOpen, setUserTargetsSliderOpen] = useState<boolean>(false);

	const { isUsersLoading, refetchUsers, users, isUsersFetching, paginationMetadata } = useFetchUsers({
		fetchParams,
		queryKey: fetchParams,
		enabled: hasPermission(PermissionKeys.VIEW_USER),
	});

	// ======================
	// * Delete User
	// ======================
	const { isLoading: isDeleteUserLoading, mutate: deleteUserMutation } = useMutation({
		mutationKey: ['authorization/delete-user'],
		mutationFn: (userId: number) => deleteUser(userId),
	});

	const onConfirmDeleteUser = () => {
		if (!currentUser) return toast.error('No user selected for this action');

		deleteUserMutation(currentUser.id, {
			onSuccess: () => {
				toast.success('User account deleted successfully.');
				setEditUserFormOpen(false);
			},
			onError: () => {
				toast.error('Failed to delete user account.');
			},
			onSettled() {
				setTimeout(() => setCurrentUser(null), MODAL_LEAVE_SPEED);
				refetchUsers();
			},
		});

		setConfirmDeleteUserDialogOpen(false);
	};

	const tableColumns = [
		columnHelper.accessor('action', {
			header: 'Actions',
			cell: (info) => {
				const user = info.row.original;

				const menuOptions = [
					{
						type: 'button',
						text: 'Update User',
						action: (e: any) => {
							setCurrentUser(user);
							setEditUserFormOpen(true);
							e.stopPropagation();
						},
						icon: <UserIcon />,
					},
					{
						type: 'button',
						text: 'Manage Yearly Targets',
						action: (e: any) => {
							setCurrentUser(user);
							setUserTargetsSliderOpen(true);
							e.stopPropagation();
						},
						icon: <ViewIcon />,
					},
					// {
					// 	type: 'button',
					// 	text: 'Delete User',
					// 	action: (e: any) => {
					// 		setCurrentUser(user);
					// 		setConfirmDeleteUserDialogOpen(true);
					// 		e.stopPropagation();
					// 	},
					// 	icon: <DeleteIcon />,
					// },
				];

				if (menuOptions.length === 0) {
					menuOptions.push({
						type: 'button',
						text: 'NOT AUTHORIZED',
						action: (e: any) => toast.error('You are not authorized perform any action on users'),
						icon: <TiCancelOutline />,
					});
				}

				return <DropdownMenu renderTriggerElement={(state) => <DefaultTableDropDownTrigger state={state} />} menuOptions={menuOptions as any} />;
			},
		}),
		columnHelper.accessor((row) => `${row.first_name} ${row.middle_name || ' '} ${row.last_name}`, {
			cell: (info) => info.getValue(),
			header: 'Name',
			id: 'name',
		}),
		columnHelper.accessor('email', {
			cell: (info) => info.getValue(),
			header: 'Email',
			id: 'email',
		}),
		columnHelper.accessor((row) => row.roles, {
			cell: (info) => (
				<div className='flex items-center divide-x'>
					{info?.getValue().length > 0
						? info?.getValue().map((role, index) => (
								<div key={index} className='flex px-1 first-of-type:pl-0 last-of-type:pr-0 py-0.5 items-center text-gray-500 gap-2'>
									<Text className='capitalize'>{role}</Text>
								</div>
						  ))
						: 'N/a'}
				</div>
			),
			header: 'Role',
			id: 'role',
		}),
		columnHelper.accessor('yearly_targets', {
			cell: ({ row }) => {
				const { yearly_targets } = row.original;
				const lastTargetIndex = yearly_targets?.length - 1;
				const target = yearly_targets?.[lastTargetIndex]?.yearly_target;

				return target !== undefined ? formatCurrency(target) : 'N/A';
			},
			header: 'Current Target',
			id: 'yearly_target',
		}),
		columnHelper.accessor('status', {
			cell: ({ row }) => {
				// return target !== undefined ? formatCurrency(target) : 'N/A';

				return (
					<Badge size='sm' className={`text-white ${row.original.status === UserAccountState.Enabled ? 'bg-green-600' : 'bg-red-600'}`}>
						{row.original.status === UserAccountState.Enabled ? 'Active' : 'Blocked'}
					</Badge>
				);
			},
			header: 'Status',
			id: 'status',
		}),
		columnHelper.accessor((row) => `${row.branch.name}`, {
			cell: (info) => info.getValue(),
			header: 'Branch',
			id: 'branch',
		}),
		columnHelper.accessor((row) => `${row.department.name}`, {
			cell: (info) => info.getValue(),
			header: 'Department',
			id: 'department',
		}),
	];

	return (
		<Container className='px-2 pt-4 pb-8 overflow-y-auto'>
			<Metric className='!text-2xl mb-4'>User Accounts</Metric>

			<div className='flex items-start justify-between gap-6'>
				<SearchInput
					onSearch={(term) =>
						setFetchParams((prevValue) => ({
							...prevValue,
							filterBy: {
								...prevValue.filterBy,
								name: term,
							},
						}))
					}
					isLoading={isUsersFetching && fetchParams.filterBy?.name ? true : false}
					className='lg:max-w-[400px]'
					placeholder='Search user...'
				/>

				<Button className='!rounded-full' onClick={() => setEditUserFormOpen(true)}>
					Create User
				</Button>
			</div>

			<div>
				<Fragment>
					{!users && isUsersLoading ? (
						<div className='mt-6'>
							<ContentLoadingCardSpinner message='Loading users...' />
						</div>
					) : users && users.length > 0 ? (
						<div className='mt-8'>
							<Card className='!p-0 pt-6 overflow-hidden'>
								<Title className='px-4 py-4 border-b'>All Users</Title>

								<Table
									data={users}
									columns={tableColumns}
									useInbuiltPagination
									// pagination={{
									// 	handlePageChange: (page: number) => {
									// 		setFetchParams((prevValue) => ({
									// 			...prevValue,
									// 			page,
									// 		}));
									// 	},
									// 	metadata: paginationMetadata,
									// }}
								/>
							</Card>
						</div>
					) : (
						<SimpleEmptyResourceIndicator containerClassName='lg:mt-8 lg:!py-10' message='No user found' />
					)}
				</Fragment>
			</div>

			<SlideOver
				title={{
					text: 'User Targets',
				}}
				subTitle={{
					text: 'User annual targets',
				}}
				onClose={() => null}
				open={isUserTargetsSliderOpen}
				setOpen={setUserTargetsSliderOpen}
				width={400}
				isScrollable
				afterClose={() => {
					setTimeout(() => setCurrentUser(null), MODAL_LEAVE_SPEED);
				}}
				shouldCloseOnOutsideClick={false}
			>
				<UserTargetsSection refetchUsers={refetchUsers} currentUser={currentUser} setCurrentUser={setCurrentUser} setUserTargetsSliderOpen={setUserTargetsSliderOpen} />
			</SlideOver>

			<Modal
				width={600}
				closeButton={{ styles: 'bg-gray-200' }}
				isModalOpen={isEditUserFormOpen}
				setModalOpen={setEditUserFormOpen}
				onCloseModal={() => {
					setEditUserFormOpen(false);
					setTimeout(() => setCurrentUser(null), 500);
				}}
				shouldCloseOnOutsideClick={false}
			>
				<EditUserForm user={currentUser} setCurrentUser={setCurrentUser} setEditUserFormOpen={setEditUserFormOpen} refetchUsers={refetchUsers} />
			</Modal>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmDeleteUserDialogOpen}
				setOpen={setConfirmDeleteUserDialogOpen}
				message='Delete User'
				subMessage={`Are you sure you want to delete ${titleCase(currentUser?.first_name as string) ?? 'this  user'}? This action cannot be reversed.`}
				onClose={() => {
					setTimeout(() => setCurrentUser(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmDeleteUser,
					label: 'Delete',
					isLoading: isDeleteUserLoading,
					loadingText: 'Deleting',
					color: 'red',
				}}
				cancelButton={{
					action: () => setConfirmDeleteUserDialogOpen(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-500/10`}>
						<HiExclamation className='w-6 h-6 text-red-500' aria-hidden='true' />
					</div>
				)}
			/>
		</Container>
	);
};

export default UserAccountsManagementPage;
