import { useState } from 'react';
import { Badge, List, ListItem, Text, Title } from '@tremor/react';
import InfoCard from 'components/InfoCard';
import { useAppSelector } from 'hooks/storeConnect';
import { prospectsState } from '../prospectsSlice';
import { formatDate, formatDateComparison } from 'helpers/dateHelpers';
import { formatCurrency, hasPermission } from 'helpers/index';
import useFetchInteractionTypes from 'features/settings/prospecting/interactionTypes/useFetchInteractionTypes';
import useFetchBusinessTypes from 'features/settings/prospecting/businessType/useFetchBusinessTypes';
import useFetchDecisionMakers from 'features/settings/prospecting/decisionMakers/useFetchDecisionMakers';
import useFetchSourceTypes from 'features/settings/prospecting/sourceTypes/useFetchSourceTypes';
import useFetchSalesStages from 'features/settings/prospecting/useFetchSalesStages';
import withMap, { MapComponentProps } from 'components/withMap';
import { Navigate } from 'react-router-dom';
import CreateProspectFormActionButtons from './CreateProspectFormActionButtons';
import { ICreateProspectRequestPayload } from '../prospectInterfaces';
import { createProspect } from '../prospectQueries';
import { useMutation } from '@tanstack/react-query';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { MODAL_LEAVE_SPEED } from 'config';
import toast from 'react-hot-toast';
import { AppointmentIcon } from 'components/FormActionIcons';
import { HiExclamation } from 'react-icons/hi';
import { useNavigate } from 'react-router';
import PreviewProspectLocationMap from '../PreviewProspectLocationMap';
import { PermissionKeys } from 'features/auth/permissions';

interface Props extends MapComponentProps {}

const CreateProspectFormReviewStep = (props: Props) => {
	const {} = props;
	const [isConfirmCreateProspectDialogOpen, setConfirmCreateProspectDialogOpen] = useState(false);
	const [isConfirmCreateAppointmentDialogOpen, setConfirmCreateAppointmentDialogOpen] = useState(false);
	const [createProspectRequestPayload, setCreateProspectRequestPayload] = useState<ICreateProspectRequestPayload | null>(null);

	const navigate = useNavigate();
	const { interactionTypes } = useFetchInteractionTypes({});
	const { businessTypes } = useFetchBusinessTypes({});
	const { decisionMakers } = useFetchDecisionMakers({});
	const { sourceTypes } = useFetchSourceTypes({});
	const { salesStages } = useFetchSalesStages({});

	const { createProspectFormValues } = useAppSelector(prospectsState);
	const { companyInformationStep, insuranceProductsStep, locationStep, saleStageStep } = createProspectFormValues || {};

	const { business_type_id, company_name, contact_date, contact_email, contact_name, contact_phone, decision_maker_type_id, interaction_type_id, source_type_id } = companyInformationStep || {};
	const selectedSaleStage = salesStages?.find((stage) => stage.id == saleStageStep?.sale_stage_id);

	// ====================
	// * Create Prospect
	// ====================
	const { isLoading: isCreateProspectLoading, mutateAsync: createProspectMutationAsync } = useMutation({
		mutationKey: ['prospects/create-prospect'],
		mutationFn: (payload: ICreateProspectRequestPayload) => createProspect(payload),
	});

	const onCreateProspect = () => {
		if (!createProspectFormValues) return;

		const { companyInformationStep, insuranceProductsStep, locationStep, saleStageStep } = createProspectFormValues || {};

		const requestPayload: ICreateProspectRequestPayload = {
			...companyInformationStep,
			insurance: insuranceProductsStep.map((product) => {
				const prod: any = { ...product };

				delete prod.insurance_product;
				return prod;
			}),
			location: JSON.stringify(locationStep),
			sales_stage_id: saleStageStep.sale_stage_id,
		} as any;

		setCreateProspectRequestPayload(requestPayload);
		setConfirmCreateProspectDialogOpen(true);
	};

	const onConfirmCreateProspect = () => {
		if (!createProspectRequestPayload) return;

		setConfirmCreateProspectDialogOpen(false);

		toast.promise(createProspectMutationAsync(createProspectRequestPayload), {
			loading: 'Creating prospect...',
			success: () => {
				setConfirmCreateAppointmentDialogOpen(true);
				return 'Prospect created successfully';
			},
			error: () => {
				setConfirmCreateProspectDialogOpen(false);

				return 'Failed to create prospect';
			},
		});
	};

	// Redirect if form is not completed
	if (!companyInformationStep) {
		toast.error('Complete company information form', { id: 'notice-1' });
		return <Navigate to='/app/prospects/create/company-information' />;
	} else if (!insuranceProductsStep) {
		toast.error('Add at least one insurance product.', { id: 'notice-1' });
		return <Navigate to='/app/prospects/create/insurance-products' />;
	} else if (!locationStep) {
		toast.error('Set company location.', { id: 'notice-1' });
		return <Navigate to='/app/prospects/create/company-location' />;
	} else if (!saleStageStep) {
		toast.error('Sales stage step is not completed.', { id: 'notice-1' });
		return <Navigate to='/app/prospects/create/sale-stage' />;
	}

	return (
		<section className='px-2 pb-20 lg:pb-4 lg:px-4'>
			<Title className='hidden px-4 text-primary-500 lg:block'>Review Prospect Data</Title>

			<div className='grid mt-4 w-full lg:h-[calc(100vh-var(--appbar-height-desktop)-var(--create-prospect-page-height)-140px)] items-start sm:grid-cols-2 lg:grid-cols-3 gap-4'>
				{/* ======================================
          // Company and Contact Person Information
          ========================================*/}
				<InfoCard
					header={{
						type: 'filled',
						primaryText: 'Company & Contact Person Information',
					}}
					containerClassName='border border-gray-300 h-full'
				>
					<List>
						<ListItem className='px-4 !py-4 sm:!py-3 sm:flex-col 2xl:flex-row sm:items-start'>
							<h4 className='!font-semibold text-xs lg:text-sm  text-black '>Company Name</h4>
							<Text>{company_name || 'N/A'}</Text>
						</ListItem>
						<ListItem className='px-4 !py-4 sm:!py-3 sm:flex-col 2xl:flex-row sm:items-start'>
							<h4 className='!font-semibold text-xs lg:text-sm  text-black '>Type Of Business</h4>
							<Text>{businessTypes?.find((type) => type.id === business_type_id)?.name || 'N/A'}</Text>
						</ListItem>
						<ListItem className='px-4 !py-4 sm:!py-3 sm:flex-col 2xl:flex-row sm:items-start'>
							<h4 className='!font-semibold text-xs lg:text-sm  text-black '>Person Contacted</h4>
							<Text>{contact_name || 'N/A'}</Text>
						</ListItem>
						<ListItem className='px-4 !py-4 sm:!py-3 sm:flex-col 2xl:flex-row sm:items-start'>
							<h4 className='!font-semibold text-xs lg:text-sm  text-black '>Contact Person Phone Number</h4>
							<Text>{contact_phone || 'N/A'}</Text>
						</ListItem>
						<ListItem className='px-4 !py-4 sm:!py-3 sm:flex-col 2xl:flex-row sm:items-start'>
							<h4 className='!font-semibold text-xs lg:text-sm  text-black '>Contact Person Email</h4>
							<Text>{contact_email || 'N/A'}</Text>
						</ListItem>
						<ListItem className='px-4 !py-4 sm:!py-3 sm:flex-col 2xl:flex-row sm:items-start'>
							<h4 className='!font-semibold text-xs lg:text-sm  text-black '>Source Type</h4>
							<Text>{sourceTypes?.find((type) => type.id === source_type_id)?.name || 'N/A'}</Text>
						</ListItem>
						<ListItem className='px-4 !py-4 sm:!py-3 sm:flex-col 2xl:flex-row sm:items-start'>
							<h4 className='!font-semibold text-xs lg:text-sm  text-black '>Decision Maker</h4>
							<Text>{decisionMakers?.find((type) => type.id === decision_maker_type_id)?.name || 'N/A'}</Text>
						</ListItem>
						<ListItem className='px-4 !py-4 sm:!py-3 sm:flex-col 2xl:flex-row sm:items-start'>
							<h4 className='!font-semibold text-xs lg:text-sm  text-black '>Interaction Type</h4>
							<Text>{interactionTypes?.find((type) => type.id === interaction_type_id)?.name || 'N/A'}</Text>
						</ListItem>
						<ListItem className='px-4 !py-4 sm:!py-3 sm:flex-col 2xl:flex-row sm:items-start'>
							<h4 className='!font-semibold text-xs lg:text-sm  text-black '>Contact Date</h4>
							<Text>{formatDate(contact_date, 'January 31st, 1999') || 'N/A'}</Text>
						</ListItem>
						<ListItem className='px-4 !py-4 sm:!py-3 sm:flex-col 2xl:flex-row sm:items-start'>
							<h4 className='!font-semibold text-xs lg:text-sm  text-black '>Date Created</h4>
							<Text>{formatDate(new Date(), 'January 31st, 1999') || 'N/A'}</Text>
						</ListItem>
					</List>
				</InfoCard>

				{/* =====================
          // Insurance Information
          =======================*/}
				<InfoCard
					header={{
						type: 'filled',
						primaryText: 'Insurance Information',
					}}
					containerClassName='border border-gray-300 h-full'
				>
					<div className='grid gap-4 p-2'>
						{/* Products Summary */}
						{(() => {
							const totalExpectedPremium = insuranceProductsStep?.reduce((acc, product) => acc + product.expected_premium, 0) ?? 0;
							const totalAchievedPremium = insuranceProductsStep?.reduce((acc, product) => acc + product.achieved_premium, 0) ?? 0;
							const totalExpectedCommission =
								insuranceProductsStep?.reduce((acc, product) => {
									const achievedCommission = product.achieved_premium ? product.expected_premium / product.commission_rate : 0;
									return achievedCommission + acc;
								}, 0) ?? 0;
							const totalAchievedCommission =
								insuranceProductsStep?.reduce((acc, product) => {
									const achievedCommission = product.achieved_premium ? product.achieved_premium / product.commission_rate : 0;
									return achievedCommission + acc;
								}, 0) ?? 0;

							return (
								<ul className='grid gap-2'>
									<li className='px-4 py-4 border border-gray-300 rounded-lg '>
										<Text className='text-xs font-medium uppercase'>Total Expected Premium</Text>
										<Text className='font-bold !text-[16px] text-blue-600'>{formatCurrency(totalExpectedPremium) || 'N/A'}</Text>
									</li>
									<li className='px-4 py-4 border border-green-400 rounded-lg '>
										<Text className='text-xs font-medium uppercase'>Total Achieved Premium</Text>
										<Text className='font-bold !text-[16px] text-green-600'>{formatCurrency(totalAchievedPremium) || 'N/A'}</Text>
									</li>
									<li className='px-4 py-4 border border-yellow-300 rounded-lg '>
										<Text className='text-xs font-medium uppercase'>Total Expected Commission</Text>
										<Text className='font-bold !text-[16px] text-yellow-600'>{formatCurrency(totalExpectedCommission) || 'N/A'}</Text>
									</li>
									<li className='px-4 py-4 border border-orange-300 rounded-lg '>
										<Text className='text-xs font-medium uppercase'>Total Achieved Commission</Text>
										<Text className='font-bold !text-[16px] text-orange-600'>{formatCurrency(totalAchievedCommission) || 'N/A'}</Text>
									</li>
								</ul>
							);
						})()}

						<Title>
							Products <Badge color={'gray'}>{insuranceProductsStep?.length}</Badge>
						</Title>

						{insuranceProductsStep?.map((product, index) => {
							const { achieved_premium, commission_rate, expected_close_date, expected_premium, insurance_product } = product;
							const { durationType, formattedDuration } = formatDateComparison(expected_close_date as string);

							return (
								<InfoCard
									key={index}
									header={{
										type: achieved_premium ? 'success' : durationType === 'past' ? 'danger' : 'light',
										primaryText: insurance_product?.name as string,
										superText: 'Insurance Product',
									}}
									containerClassName='border border-gray-300 h-full'
								>
									<div className='w-full h-full overflow-hidden overflow-y-auto'>
										<List>
											<ListItem className='px-4 grid grid-cols-2 gap-6 !py-4 sm:!py-3 sm:flex-col sm:items-start'>
												<div>
													<h4 className='!font-semibold text-sm lg:text-xs text-black'>Expected Premium</h4>
													<Text className='!text-base text-blue-600 font-semibold'>{formatCurrency(expected_premium) || 'N/A'}</Text>
												</div>
												<div>
													<h4 className='!font-semibold text-sm lg:text-xs text-black'>Achieved Premium</h4>
													<Text className='!text-base text-green-600 font-semibold'>{formatCurrency(achieved_premium) || 'N/A'}</Text>
												</div>
											</ListItem>

											<ListItem className='px-4 grid grid-cols-2 gap-6 !py-4 sm:!py-3 sm:flex-col sm:items-start'>
												<div>
													<h4 className='!font-semibold text-sm lg:text-xs text-black'>{achieved_premium ? 'Achieved' : 'Expected'} Commission</h4>
													<Text className='!text-base text-orange-600 font-semibold'>{formatCurrency((achieved_premium || expected_premium) / commission_rate) || 'N/A'}</Text>
												</div>
												<div>
													<h4 className='!font-semibold text-sm lg:text-xs text-black'>Commission Rate</h4>
													<Text className='!text-base text-gray-600 font-semibold'>{`${commission_rate?.toFixed(2)}%` || 'N/A'}</Text>
												</div>
											</ListItem>

											<ListItem className='px-4 !py-4 sm:!py-3 sm:flex-col sm:items-start'>
												<div>
													<h4 className='!font-semibold text-sm lg:text-xs text-black'>Expected Close Date</h4>
													<Text className='!text-base'>
														{formatDate(expected_close_date, 'January 31st, 1999') || 'N/A'}{' '}
														{formattedDuration && (
															<span className={`!text-xs capitalize font-semibold ${durationType === 'now' ? 'text-yellow-500' : durationType === 'past' ? 'text-red-500' : 'text-green-500'}`}>
																({formattedDuration || 'N/A'})
															</span>
														)}
													</Text>
												</div>
											</ListItem>
										</List>
									</div>
								</InfoCard>
							);
						})}
					</div>
				</InfoCard>

				<div className='lg:h-full lg:overflow-hidden gap-4 grid lg:grid-rows-[110px,1fr] sm:grid-cols-2 lg:grid-cols-1 sm:col-span-2 lg:col-span-1'>
					{/* ============
            // Sale Stage
            ==============*/}
					<InfoCard
						header={{
							type: 'filled',
							primaryText: 'Sales Stage',
						}}
						containerClassName='sm:col-span-2 lg:col-span-1 border border-gray-300 h-'
					>
						<div className='p-4'>
							{true ? (
								<Text className='!text-base font-semibold'>
									<span className='text-primary-500'>{selectedSaleStage?.name}</span> - {selectedSaleStage?.probability}% probability of achieving sale
								</Text>
							) : (
								<Text>N/A</Text>
							)}
						</div>
					</InfoCard>

					{/* ========================
            // Location
            ==========================*/}
					<InfoCard
						header={{
							type: 'filled',
							primaryText: 'Company Location',
						}}
						containerClassName='sm:col-span-2 lg:col-span-1 min-h-[340px] lg:min-h-[200px] border border-gray-300'
					>
						<div className='relative w-full h-full'>
							<PreviewProspectLocationMap locationInformation={locationStep} />
						</div>
					</InfoCard>
				</div>
			</div>

			{hasPermission(PermissionKeys.CREATE_PROSPECT) && (
				<CreateProspectFormActionButtons
					nextStepButton={{
						onClick: () => onCreateProspect(),
						isDisabled: !selectedSaleStage,
						color: 'green',
						label: 'Create Prospect',
						hasIcon: false,
					}}
				/>
			)}

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmCreateProspectDialogOpen}
				setOpen={setConfirmCreateProspectDialogOpen}
				message='Create Prospect'
				subMessage={`Are you sure you want to add ${company_name} to your prospects? This action cannot be reversed.`}
				onClose={() => {
					setTimeout(() => null, MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmCreateProspect,
					label: 'Create',
					color: 'green',
					loadingText: 'Processing...',
					isLoading: isCreateProspectLoading,
				}}
				cancelButton={{
					action: () => setConfirmCreateProspectDialogOpen(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-500/10`}>
						<HiExclamation className='w-6 h-6 text-green-500' aria-hidden='true' />
					</div>
				)}
			/>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmCreateAppointmentDialogOpen}
				setOpen={setConfirmCreateAppointmentDialogOpen}
				message='Set Appointment'
				subMessage={`Would you like to set an appointment with ${company_name}?`}
				onClose={() => {
					setTimeout(() => setCreateProspectRequestPayload(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: () => navigate('/app/prospects/appointments'),
					label: 'Set Appointment',
					color: 'gray',
				}}
				cancelButton={{
					action: () => navigate('/app/prospects/list'),
					label: 'Back to Prospects',
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-500/10`}>
						<AppointmentIcon variant={'solid'} className='w-6 h-6 text-gray-500' aria-hidden='true' />
					</div>
				)}
			/>
		</section>
	);
};

export default withMap(CreateProspectFormReviewStep);
