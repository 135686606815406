import { Button, Text, Title } from '@tremor/react';
import Filters from 'components/Filters';
import { PermissionKeys } from 'features/auth/permissions';
import { DefaultRoles } from 'features/settings/authorization/authorizationInterface';
import { FilterComponentTypes } from 'features/settings/settingsInterfaces';
import useFetchUsers from 'features/settings/users/useFetchUsers';
import { hasRoles } from 'helpers/index';
import React from 'react';
import { RiFilter2Fill } from 'react-icons/ri';

const NumberOfWonOpportunitiesReportPage: React.FC = () => {
	const { users } = useFetchUsers({});

	const onSubmitFilters = () => {};

	return (
		<div className='flex flex-col w-full overflow-hidden h-[calc(100vh-var(--appbar-height-mobile))] lg:h-[calc(100vh-var(--appbar-height-desktop))]'>
			<div className='h-[160px] px-4 py-4 bg-gray-100 border w-full flex items-start justify-between'>
				<div>
					<Title className='!font-bold text-gray-800'>Yearly Targets</Title>
					<Text>Staff yearly targets breakdown.</Text>
				</div>

				<div className='inline-flex rounded-md shadow-sm ' role='group'>
					<button
						// onClick={() => exportReportToExcel(prospects)}
						type='button'
						className='px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-sky-700 focus:z-10 focus:ring-2 focus:ring-sky-700 focus:text-sky-700'
					>
						Export To Excel
					</button>

					{/* <button
						type='button'
						className='px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-sky-700 focus:z-10 focus:ring-2 focus:ring-sky-700 focus:text-sky-700'
					>
						Download PDF
					</button> */}
				</div>
			</div>

			<div className='relative w-full h-[calc(100%-160px)] bg-white'>
				<div className='absolute -top-14 bottom-0 inset-x-0 mx-4 pb-4 h-[calc(100%+60px)] bg-white shadow-sm border rounded-t-xl overflow-hidden'>
					<div className='h-[55px] sticky inset-x-0 top-0 z-20 flex items-center justify-between px-4 py-2 bg-white border-b'>
						<p className='text-sm font-semibold'>1,000 records found</p>

						<div className='hidden lg:block'>
							<Filters
								filters={[
									{
										type: FilterComponentTypes.DateRangeWithSelect,
										label: 'Select Date',
										hideLabel: true,
										dateRange: {
											label: 'Date type',
											name: 'dateRange',
											placeholder: 'Select dates',
											type: FilterComponentTypes.DateRange,
										},
										select: {
											label: 'Date type',
											name: 'dateType',
											type: FilterComponentTypes.Select,
											placeholder: 'Select type',
											dropdownOptions: [
												{
													id: 1,
													label: 'Date Created',
													value: 'createdBetween',
												},
												{
													id: 2,
													label: 'Last Interaction Date',
													value: 'lastInteractionDate',
												},
												{
													id: 3,
													label: 'Contact Date',
													value: 'contactDate',
												},
											],
										},
									},
									{
										type: FilterComponentTypes.Select,
										name: 'userId',
										hideLabel: true,
										label: 'Staff',
										dropdownOptions:
											users
												?.filter((user) => {
													// return user.roles.includes(DefaultRoles.Marketer) || user.roles.includes(DefaultRoles.MarketingManager)
													return !user.roles.includes(DefaultRoles.SuperAdmin);
												})
												.map((user: any) => ({
													id: user.id,
													label: `${user.first_name} ${user?.middle_name ?? ' '} ${user.last_name}`,
													value: user.id,
												})) ?? [],
										placeholder: 'Select staff',
										permission: PermissionKeys.VIEW_USER_FILTERS,
									},
								]}
								submitButton={{
									className: `!bg-gray-800 hover:!bg-gray-600 text-white !capitalize border-0 duration-300`,
									label: 'Apply Filters',
									onSubmit: onSubmitFilters,
								}}
							/>
						</div>

						<div className='lg:hidden'>
							<Button
								color='gray'
								variant='secondary'
								// onClick={() => setMobileFiltersOpen(true)}
								icon={RiFilter2Fill}
								className='!rounded-full hover:bg-gray-700 hover:!text-gray-100 duration-300 lg:hidden'
							>
								Filters
							</Button>
						</div>
					</div>

					<div className='h-[calc(100%-40px)] overflow-x-hidden overflow-y-auto w-full'>{/* Table here */}</div>
				</div>
			</div>
		</div>
	);
};

export default NumberOfWonOpportunitiesReportPage;
