import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store/index';
import { SettingsState } from './settingsInterfaces';
import SliceNames from 'store/sliceNames';

const initialState: SettingsState = {
	isMobileSidebarOpen: true,
	activeAppNavigationMenuOption: null,
	settingsError: null,
};

export const SettingsSlice = createSlice({
	name: SliceNames.Settings,
	initialState,
	reducers: {
		setAppSidebarOpen: (state, action: PayloadAction<boolean>) => {
			state.isMobileSidebarOpen = action.payload;
		},
		setActiveAppNavigationMenuItem: (state, action: PayloadAction<any>) => {
			state.activeAppNavigationMenuOption = action.payload;
		},
	},
});

//================ Exports & Actions ====================
export const { setAppSidebarOpen, setActiveAppNavigationMenuItem } = SettingsSlice.actions;
export const settingsState = (state: RootState) => state.settings;
export default SettingsSlice.reducer;
