import { useQuery, RefetchOptions, RefetchQueryFilters, QueryObserverResult } from '@tanstack/react-query';
import { fetchBranches } from 'features/settings/branches/branchQueries';
import { IBranch, IFetchBranchesActionParams } from './branchInterfaces';

interface ReturnType {
	branches: IBranch[] | undefined;
	isBranchesLoading: boolean;
	isBranchesFetching: boolean;
	refetchBranches: (options?: RefetchOptions & RefetchQueryFilters) => Promise<QueryObserverResult>;
	paginationMetadata: APIPaginationMetadata;
}

interface Props {
	queryKey?: any;
	fetchParams?: IFetchBranchesActionParams;
	enabled?: boolean;
	onSuccess?: (branches: IBranch[]) => void;
}

const useFetchBranches = ({ fetchParams, queryKey, enabled, onSuccess }: Props): ReturnType => {
	const { data, isLoading, refetch, isFetching } = useQuery({
		queryKey: ['branches/fetch-branches', queryKey, fetchParams],
		queryFn: () => fetchBranches(fetchParams ?? {}),
		enabled,
		onSuccess: onSuccess ? (data) => onSuccess?.(data?.data) : undefined,
	});

	const branchData = data?.data?.data;
	const pagination = data?.data?.meta;

	return { isBranchesLoading: isLoading, isBranchesFetching: isFetching, branches: branchData, paginationMetadata: pagination, refetchBranches: refetch };
};

export default useFetchBranches;
