import { Button, Card, Text } from '@tremor/react';

interface Props {
	message: string;
	containerClassName?: string;
	messageStyle?: string;
	actionButton?: {
		text: string;
		onClick: () => void;
		style?: string;
	};
}

const SimpleEmptyResourceIndicator = ({ message, containerClassName, messageStyle, actionButton }: Props) => {
	return (
		<Card className={`mt-4 flex items-center justify-center flex-col gap-6 py-6 lg:py-16 bg-white ${containerClassName}`}>
			<Text className={messageStyle}>{message}</Text>

			{actionButton && (
				<Button type='button' onClick={() => actionButton.onClick()} className={`!rounded-full ${actionButton?.style}`} variant='secondary' color='gray' size='lg'>
					{actionButton?.text}
				</Button>
			)}
		</Card>
	);
};

export default SimpleEmptyResourceIndicator;
