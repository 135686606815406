import { ReactNode } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Button, Card, Text, Title } from '@tremor/react';
import Table from 'components/Table';
import { ICompletedTrip, ITransportManagerDashboardData } from './dashboardInterfaces';
import { RiLoader4Line } from 'react-icons/ri';
import { parseLocation } from 'utils/index';
import { convertJSONToExcel } from 'helpers/index';
import toast from 'react-hot-toast';
import { formatDate } from 'helpers/dateHelpers';
import { SiMicrosoftexcel } from 'react-icons/si';

interface ICompletedTripsTableColumns extends ICompletedTrip {
	action: ReactNode;
}

interface Props {
	data: ITransportManagerDashboardData | undefined;
	isDataLoading: boolean;
}

const columnHelper = createColumnHelper<ICompletedTripsTableColumns>();

const DashboardCompletedTrips = ({ data, isDataLoading }: Props) => {
	const completedTrips = data?.completed_trips;

	const tableColumns = [
		// columnHelper.accessor('action', {
		// 	header: 'Actions',
		// 	cell: (info) => {
		// 		return (
		// 			<DropdownMenu
		// 				renderTriggerElement={(state) => <DefaultTableDropDownTrigger state={state} />}
		// 				menuOptions={[
		// 					{
		// 						type: 'button',
		// 						text: 'View Rep',
		// 						action: (e: any) => {
		// 							toast.success('Will open a page with the rep details');
		// 							e.stopPropagation();
		// 						},
		// 						icon: <GrFormViewHide />,
		// 					},
		// 				]}
		// 			/>
		// 		);
		// 	},
		// }),
		columnHelper.accessor('vehicle_request.user_id', {
			cell: ({ row }) => {
				const { first_name, middle_name, last_name } = row.original.vehicle_request.user || {};

				return (
					<span className='text-base font-semibold'>
						{first_name} {middle_name} {last_name}
					</span>
				);
			},
			header: 'Staff',
		}),
		columnHelper.accessor('transport_officer', {
			cell: ({ row }) => {
				const { first_name, middle_name, last_name } = row.original.transport_officer || {};

				return (
					<span className='text-base font-semibold'>
						{first_name} {middle_name} {last_name}
					</span>
				);
			},
			header: 'Transport Officer',
		}),
		columnHelper.accessor('start_location', {
			cell: ({ row }) => <span>{parseLocation(row.original?.start_location).name}</span>,
			header: 'Start Location',
		}),
		columnHelper.accessor('end_location', {
			cell: ({ row }) => <span>{parseLocation(row.original?.end_location).name}</span>,
			header: 'Destination',
		}),
	];

	const exportReportToExcel = (trips: ICompletedTrip[] | undefined) => {
		if (!trips || trips?.length === 0) return toast.error('No data to export. Please make sure your report has at least one record.');

		const sheetStructure = trips.map((trip) => {
			const { transport_officer, start_location, end_location, vehicle, vehicle_request } = trip;
			const staff = vehicle_request.user_id;

			return {
				'Transport Officer': `${transport_officer.first_name} ${transport_officer.middle_name ?? ''} ${transport_officer.last_name}`,
				'Start Location': start_location, // Todo: Parse and extract location
				'End Location': end_location, // Todo: Parse and extract location
				Staff: `${transport_officer.first_name} ${transport_officer.middle_name ?? ''} ${transport_officer.last_name}`,
				Vehicle: `${vehicle.model} (${vehicle.number})`, // Todo: Parse and extract location
			};
		});

		convertJSONToExcel(sheetStructure, `Completed Trips - ${formatDate(new Date(), 'Jan 31, 1999')}`);
	};

	return (
		<Card className='!p-0 pt-6 overflow-hidden !w-full !h-full'>
			<div className='flex items-center justify-between px-4 py-3 border-b'>
				<Title>Completed Trips</Title>

				<Button
					icon={SiMicrosoftexcel}
					iconPosition='left'
					className='hover:!bg-gray-100 duration-300 py-2 px-3 !rounded-lg'
					variant='light'
					color='gray'
					size='xs'
					onClick={() => exportReportToExcel(completedTrips)}
				>
					Export Trips
				</Button>
			</div>

			{isDataLoading ? (
				<div className='flex flex-col items-center justify-center gap-4 p-10'>
					<RiLoader4Line className='w-8 h-8 text-lg text-gray-400 animate animate-spin ' />
					<Text className='!text-base'>Loading completed trips...</Text>
				</div>
			) : completedTrips && completedTrips.length > 0 ? (
				<Table data={completedTrips} columns={tableColumns} />
			) : (
				<div className='flex items-center justify-center w-full h-full'>
					<Text className={'!text-base font-semibold py-16'}>No trip found</Text>
				</div>
			)}
		</Card>
	);
};

export default DashboardCompletedTrips;
