import { useQuery, RefetchOptions, RefetchQueryFilters, QueryObserverResult } from '@tanstack/react-query';
import { IAppointment, IFetchAppointmentsActionParams } from './appointmentInterfaces';
import { fetchAppointments } from './appointmentQueries';

interface ReturnType {
	appointments: IAppointment[] | undefined;
	isAppointmentsLoading: boolean;
	refetchAppointments: (options?: RefetchOptions & RefetchQueryFilters) => Promise<QueryObserverResult>;
	paginationMetadata: APIPaginationMetadata;
}

interface Props {
	queryKey?: any;
	fetchParams?: IFetchAppointmentsActionParams;
	enabled?: boolean;
	afterFetch?: (appointments: IAppointment[]) => void;
	onSuccess?: (appointments: IAppointment[]) => void;
}

const useFetchAppointments = ({ fetchParams, queryKey, enabled, onSuccess, afterFetch }: Props): ReturnType => {
	const { filterBy, include } = fetchParams || {};

	const { data, isLoading, refetch } = useQuery({
		queryKey: [queryKey ?? 'appointments/fetch-appointments', fetchParams],
		queryFn: () => fetchAppointments({ filterBy, include }),
		enabled,
		onSuccess: onSuccess ? (data) => onSuccess?.(data?.data) : undefined,
	});

	const appointments = data?.data?.data;
	const pagination = data?.data?.meta;

	afterFetch?.(appointments);

	return { isAppointmentsLoading: isLoading, appointments, paginationMetadata: pagination, refetchAppointments: refetch };
};

export default useFetchAppointments;
