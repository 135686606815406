import { ReactNode } from 'react';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { FiChevronDown } from 'react-icons/fi';
import { MenuTriggerState } from 'react-stately';

interface Props {
	buttonContent?: ReactNode;
	className?: string;
}

const TableDropdownTrigger = (props: Props) => {
	const { buttonContent, className } = props;

	return (
		<button
			title='Toggle Dropdown'
			type='button'
			className={`flex-row-reverse px-1 py-1 text-base bg-white border rounded shadow outline-none border-gray-50 hover:text-gray-700 text-gray-500 ${className}`}
		>
			<BiDotsHorizontalRounded className='hidden text-2xl sm:inline' />

			<span className='sm:hidden'>{buttonContent ?? <BiDotsHorizontalRounded className='text-2xl' />}</span>
		</button>
	);
};

interface DefaultTableDropDownTriggerProps {
	state: MenuTriggerState;
}

const DefaultTableDropDownTrigger = ({ state }: DefaultTableDropDownTriggerProps) => (
	<TableDropdownTrigger
		className='rounded-lg !text-black'
		buttonContent={
			<span className='px-3 py-1.5 flex items-center uppercase gap-2 !text-xs'>
				Select Action
				<FiChevronDown className={`text-lg duration-200 ${state.isOpen ? '-rotate-180' : ''}`} />
			</span>
		}
	/>
);

export {DefaultTableDropDownTrigger};

export default TableDropdownTrigger;
