import { Button, Text, Title } from '@tremor/react';
import { ReactNode } from 'react';
import Modal from './Modal';
import { Color } from '@tremor/react';

interface Props {
	message: string;
	isPortrait: boolean;
	subMessage?: string;
	isOpen: boolean;
	setOpen: (value: boolean) => void;
	onClose: () => void;
	confirmButton: {
		label: string;
		styles?: string;
		color?: Color;
		action: () => void;
		isLoading?: boolean;
		loadingText?: string;
	};
	cancelButton?: {
		action: () => void;
		label?: string;
		style?: string;
	};
	renderIcon: () => ReactNode;
	shouldCloseOnOutsideClick?: boolean;
}

const ConfirmationDialog = (props: Props) => {
	const { isOpen, setOpen, onClose, renderIcon, message, subMessage, isPortrait, cancelButton, confirmButton, shouldCloseOnOutsideClick } = props;

	return (
		<Modal
			isModalOpen={isOpen}
			setModalOpen={setOpen}
			onCloseModal={() => {
				setOpen(false);
				onClose();
			}}
			container={{
				styles: 'shadow-xl bg-white rounded-md',
			}}
			shouldCloseOnOutsideClick={shouldCloseOnOutsideClick}
			modalPosition='center'
			width={500}
		>
			<div className='transform overflow-hidden rounded-lg text-left transition-all sm:w-full'>
				<div className='bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4'>
					<div className={`flex ${isPortrait ? 'flex-col items-center justify-center gap-4 text-center' : 'items-start gap-2 justify-start text-left'}`}>
						{/* Icon */}
						{renderIcon()}

						{/* Message */}
						<div className='sm:ml-4'>
							<Title>{message}</Title>

							{subMessage && (
								<div className='mt-2'>
									<Text className='!text-base font-medium text-black/60'>{subMessage}</Text>
								</div>
							)}
						</div>
					</div>
				</div>

				<div className={`${isPortrait ? 'justify-center' : 'justify-end'} flex gap-2 border-t border-t-gray-200 bg-gray-50 px-4 py-3 sm:px-6`}>
					<Button
						type='button'
						color={confirmButton.color}
						onClick={() => confirmButton.action()}
						loading={confirmButton.isLoading}
						loadingText={confirmButton.loadingText}
						className={`px-6 py-3 !rounded-full !text-sm !font-normal text-white ${confirmButton.styles}`}
						disabled={confirmButton.isLoading}
					>
						{confirmButton.label || 'Confirm'}
					</Button>

					{cancelButton && (
						<Button
							type='button'
							color='gray'
							variant='secondary'
							onClick={() => {
								cancelButton.action();
								onClose();
							}}
							className={`px-6 py-3 !rounded-full ${confirmButton.styles}`}
						>
							{cancelButton.label || 'Cancel'}
						</Button>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default ConfirmationDialog;
