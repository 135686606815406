import { useQuery, RefetchOptions, RefetchQueryFilters, QueryObserverResult } from '@tanstack/react-query';
import { IVehicleDriverStat } from './dashboardInterfaces';
import { fetchVehicleDriverStats } from './dashboardQueries';

interface ReturnType {
	vehicleDriverStats: IVehicleDriverStat | undefined;
	isVehicleDriverStatsLoading: boolean;
	isVehicleDriverStatsFetching: boolean;
	refetchVehicleDriverStats: (options?: RefetchOptions & RefetchQueryFilters) => Promise<QueryObserverResult>;
}

interface Props {
	queryKey?: any;
	enabled?: boolean;
	onSuccess?: (vehicleDriverStats: IVehicleDriverStat[]) => void;
}

const useFetchVehicleDriverStats = ({ queryKey, enabled, onSuccess }: Props): ReturnType => {
	const { data, isLoading, isFetching, refetch } = useQuery({
		queryKey: [queryKey ?? 'auth/fetch-vehicleDriverStats'],
		queryFn: () => fetchVehicleDriverStats(),
		enabled,
		onSuccess: onSuccess ? (data) => onSuccess?.(data?.data) : undefined,
	});

	const vehicleDriverStatsData = data?.data;
	const vehicleDriverStats = vehicleDriverStatsData?.data;

	return { isVehicleDriverStatsLoading: isLoading, isVehicleDriverStatsFetching: isFetching, vehicleDriverStats, refetchVehicleDriverStats: refetch };
};

export default useFetchVehicleDriverStats;
