import { Card, Title, DonutChart, Text, Flex } from '@tremor/react';
import { IMarketingManagerDashboardData } from './dashboardInterfaces';

interface InsuranceProductChatData {
	product: string;
	share: number;
}

interface Props {
	data: IMarketingManagerDashboardData | undefined;
	isDataLoading: boolean;
}

const ProductRevenueShareChart = ({ data, isDataLoading }: Props) => {
	const valueFormatter = (number: number) => `GH₵ ${Intl.NumberFormat('us').format(number).toString()}`;

	return (
		<Card className='!h-full'>
			<Title>Insurance Product Revenue Share</Title>
			<Text>Percentage of each product type to total won revenue</Text>

			{isDataLoading && true ? (
				<div className='flex flex-col items-center justify-center gap-4 p-10'>
					<div className='w-40 h-40 bg-gray-400 rounded-full animate animate-pulse' />
				</div>
			) : data && data.insurance_product_percentage.length > 0 ? (
				<Flex justifyContent='between' className='gap-4' alignItems='center'>
					<DonutChart
						className='mt-6 mr-auto'
						showLabel={true}
						variant='donut'
						data={data?.insurance_product_percentage?.map(
							(product): InsuranceProductChatData => ({
								product: product?.insurance_product_type?.name,
								share: product?.total_premium,
							})
						)}
						category='share'
						index='product'
						valueFormatter={valueFormatter}
						colors={['slate', 'violet', 'blue', 'rose', 'cyan', 'amber']}
					/>
				</Flex>
			) : (
				<div className='flex flex-col items-center justify-center gap-4 p-10'>
					<Text className='!text-base'>No revenue generated.</Text>
				</div>
			)}
		</Card>
	);
};

export default ProductRevenueShareChart;
