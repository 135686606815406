import { useQuery, RefetchOptions, RefetchQueryFilters, QueryObserverResult } from '@tanstack/react-query';
import _ from 'lodash';
import { fetchPermissions } from './authorizationQueries';
import { IFetchPermissionsParams, IPermission } from './authorizationInterface';

interface ReturnType {
	permissions: { [key: string]: IPermission[] } | undefined;
	isPermissionsLoading: boolean;
	refetchPermissions: (options?: RefetchOptions & RefetchQueryFilters) => Promise<QueryObserverResult>;
	mergedPermissions: IPermission[];
}

interface Props {
	queryKey?: string;
	fetchParams?: IFetchPermissionsParams;
	enabled?: boolean;
	onSuccess?: (data: { [key: string]: IPermission[] }) => void;
}

const useFetchPermissions = ({ fetchParams, queryKey, enabled, onSuccess }: Props): ReturnType => {
	const { data, isLoading, refetch } = useQuery({
		queryKey: [queryKey ?? 'authorization/fetch-permissions', fetchParams],
		queryFn: () => fetchPermissions(),
		onSuccess: onSuccess ? (data) => onSuccess?.(data?.data) : undefined,
	});

	const groupedPermissions = data?.data?.data;
	const mergedPermissions: IPermission[] = groupedPermissions && Object.keys(groupedPermissions).length > 0 ? _.flatten(Object.values(groupedPermissions)) : [];

	return { isPermissionsLoading: isLoading, permissions: groupedPermissions, mergedPermissions, refetchPermissions: refetch };
};

export default useFetchPermissions;
