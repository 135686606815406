import { IInsuranceProductsFormValue } from '../prospectInterfaces';
import { Button, DatePicker, NumberInput, Select, SelectItem, Title } from '@tremor/react';
import { useForm, useWatch } from 'react-hook-form';
import { useState, useEffect, useRef } from 'react';
import { AiOutlinePercentage } from 'react-icons/ai';
import { useAppDispatch, useAppSelector } from 'hooks/storeConnect';
import useFetchInsuranceProducts from 'features/settings/prospecting/insuranceProducts/useFetchInsuranceProducts';
import { prospectsState, setCreateProspectFormValues } from '../prospectsSlice';
import { formatDate } from 'helpers/dateHelpers';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { MODAL_LEAVE_SPEED } from 'config';
import { uuid } from 'short-uuid';

interface Props {
	product: IInsuranceProductsFormValue | null;
	setCurrentProduct: (product: IInsuranceProductsFormValue | null) => void;
	setFormOpen: (val: boolean) => void;
	isConfirmDeleteProductDialogOpen?: boolean;
	updateProspectFormProducts?: IInsuranceProductsFormValue[];
	setUpdateProspectFormProducts?: (products: IInsuranceProductsFormValue) => IInsuranceProductsFormValue;
}

const EditCreateProspectInsuranceProductForm = (props: Props) => {
	const { product, setCurrentProduct, setFormOpen, isConfirmDeleteProductDialogOpen, updateProspectFormProducts, setUpdateProspectFormProducts } = props;

	const { insuranceProducts, isInsuranceProductsLoading } = useFetchInsuranceProducts({});
	const dispatch = useAppDispatch();
	const location = useLocation();
	const { createProspectFormValues } = useAppSelector(prospectsState);
	const [expectedCloseDate, setExpectedCloseDate] = useState<Date | undefined>();
	const [selectProduct, setSelectProduct] = useState<string>();
	const formRef = useRef<HTMLFormElement | null>(null);

	const isOnCreateProspectPage = location.pathname.includes('create');

	const {
		register,
		setValue,
		setError,
		handleSubmit,
		reset,
		clearErrors,
		control,
		formState: { errors },
	} = useForm<IInsuranceProductsFormValue>({
		reValidateMode: 'onBlur',
		mode: 'all',
	});
	const { achieved_premium, expected_premium, commission_rate } = useWatch({ control });

	// ==================================================
	// Populate form with product to be edited
	// ==================================================
	useEffect(() => {
		if (!product || isConfirmDeleteProductDialogOpen) return;

		setValue('commission', product?.commission);
		setValue('commission_rate', product?.commission_rate);
		setValue('achieved_premium', product?.achieved_premium);
		setValue('expected_premium', product?.expected_premium);
		setValue('expected_close_date', product?.expected_close_date);
		setValue('insurance_product_type_id', product?.insurance_product_type_id);
		setSelectProduct(product?.insurance_product_type_id?.toString());
		setExpectedCloseDate(new Date(product?.expected_close_date));
	}, [product]);

	const onSubmit = (formValues: IInsuranceProductsFormValue, e: any) => {
		if (!formValues.expected_close_date) return setError('expected_close_date', { message: 'Expected close date is required.' });
		if (!formValues.insurance_product_type_id) return setError('insurance_product_type_id', { message: 'Product is require required.' });

		let updatedProducts;
		const isUpdateForm = updateProspectFormProducts ? true : false;
		const existingProducts = updateProspectFormProducts ? updateProspectFormProducts ?? [] : createProspectFormValues?.insuranceProductsStep ?? [];

		let formSubmission: IInsuranceProductsFormValue = {
			insurance_product_type_id: formValues.insurance_product_type_id,
			achieved_premium: Number(formValues.achieved_premium),
			expected_premium: Number(formValues.expected_premium),
			commission: Number(formValues.commission),
			commission_rate: Number(formValues.commission_rate),
			expected_close_date: formatDate(formValues.expected_close_date, '1999-12-31'),
			insurance_product: insuranceProducts?.find((product) => product.id === formValues.insurance_product_type_id) as any,
		};
		let submission: any = {
			...formSubmission,
			item_id: uuid(),
		};

		if (product) {
			// =============================
			// Update product in table
			// =============================
			const currentEditIndex = existingProducts.findIndex((p: any) => {
				let currentEditingProduct: any = product;

				if (isUpdateForm) {
					return p?.id ? p.id === product.id : p.item_id === currentEditingProduct?.item_id;
				} else {
					return p.item_id === currentEditingProduct?.item_id;
				}
				//  p_.isEqual(p.insurance_product_type_id, product.insurance_product_type_id)
			});
			// const isProductAdded = existingProducts.some((item, index: number) => item.insurance_product_type_id === submission.insurance_product_type_id && index !== currentEditIndex);
			// if (isProductAdded) return setError('insurance_product_type_id', { message: 'Selected product already exists' });

			updatedProducts = [...existingProducts];
			updatedProducts.splice(currentEditIndex, 1, {
				...submission,
			});

			if (setUpdateProspectFormProducts) {
				// Update prospect form
				setUpdateProspectFormProducts(updatedProducts as any);
			} else {
				// Create prospect form
				dispatch(
					setCreateProspectFormValues({
						...createProspectFormValues,
						insuranceProductsStep: updatedProducts,
					} as any)
				);
			}
			setTimeout(() => setCurrentProduct(null), MODAL_LEAVE_SPEED);
		} else {
			// =============================
			// Add new product to table
			// =============================
			// const isProductAdded = existingProducts.some((item) => item.insurance_product_type_id === submission.insurance_product_type_id);
			// if (isProductAdded) return setError('insurance_product_type_id', { message: 'Selected product already exists' });

			if (setUpdateProspectFormProducts) {
				// Update prospect form
				const updatedProducts = updateProspectFormProducts ? [submission, ...updateProspectFormProducts] : [submission];
				setUpdateProspectFormProducts(updatedProducts as any);
			} else {
				// Create prospect form
				const updatedProducts = createProspectFormValues?.insuranceProductsStep ? [submission, ...createProspectFormValues?.insuranceProductsStep] : [submission];

				dispatch(
					setCreateProspectFormValues({
						...createProspectFormValues,
						insuranceProductsStep: updatedProducts,
					} as any)
				);
			}
		}

		setFormOpen(false);
		reset();
		setExpectedCloseDate(null as any);
		setSelectProduct(null as any);

		return;
	};

	return (
		<form ref={formRef} onSubmit={handleSubmit(onSubmit)} className='w-full'>
			<header className={`bg-dark p-6 h-[80px] flex items-center gap-2 ${isOnCreateProspectPage ? 'lg:hidden' : ''}`}>
				<Title className='text-white'>{product ? 'Update' : 'Add'} Product</Title>
			</header>

			<div className={`grid text-left px-3 sm:px-4 py-3 items-start gap-2 lg:gap-x-2 2xl:gap-x-4 w-full ${isOnCreateProspectPage ? 'lg:grid-cols-6  lg:p-0' : 'grid-cols-1'}`}>
				{/* Product */}
				<div className='form-control w-full'>
					<label htmlFor='product_type' className='required-field-indicator'>
						Product
					</label>

					<div>
						<Select
							id='product_type'
							placeholder='Select product type'
							disabled={isInsuranceProductsLoading}
							value={selectProduct}
							onChange={(val) => {
								setValue('insurance_product_type_id', Number(val));
								setSelectProduct(val as any);
								if (errors.insurance_product_type_id) clearErrors('insurance_product_type_id');
							}}
							enableClear={false}
							defaultValue={product?.insurance_product_type_id.toString()}
						>
							{insuranceProducts && insuranceProducts.length > 0 ? (
								insuranceProducts?.map((insuranceProduct, index) => (
									<SelectItem key={index} value={insuranceProduct.id.toString()}>
										{insuranceProduct.name}
									</SelectItem>
								))
							) : (
								<div className='py-2 px-2'>No insurance product</div>
							)}
						</Select>
						{errors?.insurance_product_type_id && <span className='form-message'>{errors?.insurance_product_type_id?.message}</span>}
					</div>
				</div>

				{/* Expected Close Date */}
				<div className='form-control w-full'>
					<label htmlFor='expected_close_date' className='required-field-indicator'>
						Expected Close Date
					</label>

					<div>
						<DatePicker
							id='expected_close_date'
							enableClear={false}
							className={`!max-w-full`}
							enableYearNavigation
							placeholder={'Select date...'}
							value={expectedCloseDate as any}
							onValueChange={(val) => {
								setValue('expected_close_date', val as any);
								setExpectedCloseDate(val);
								if (errors.expected_close_date) clearErrors('expected_close_date');
							}}
						/>

						{errors?.expected_close_date && <span className='form-message'>{errors?.expected_close_date?.message}</span>}
					</div>
				</div>

				{/* Expected Premium */}
				<div className='form-control w-full'>
					<label htmlFor='expected_premium' className='required-field-indicator'>
						Expected Premium
					</label>

					<div>
						<NumberInput
							id='expected_premium'
							{...register('expected_premium', {
								required: {
									value: true,
									message: 'Expected premium is required',
								},
							})}
							onValueChange={(value) => {
								if (!_.isNumber(Number(value))) return;

								return commission_rate && !achieved_premium ? setValue('commission', value * (Number(commission_rate) / 100)) : null;
							}}
							step='0.01'
							enableStepper={false}
							placeholder='Enter expected premium'
							error={errors.expected_premium ? true : false}
							errorMessage={errors.expected_premium?.message}
						/>
					</div>
				</div>

				{/* Achieved Premium */}
				<div className='form-control w-full'>
					<label htmlFor='achieved_premium'>Achieved Premium</label>

					<div>
						<NumberInput
							id='achieved_premium'
							{...register('achieved_premium')}
							onValueChange={(value) => {
								if (isNaN(value) && expected_premium && commission_rate) return setValue('commission', Number(expected_premium) * (Number(commission_rate) / 100));

								if (!_.isNumber(Number(value))) return;

								return commission_rate ? setValue('commission', value * (Number(commission_rate) / 100)) : null;
							}}
							step='0.01'
							enableStepper={false}
							placeholder='Enter achieved premium'
							error={errors.achieved_premium ? true : false}
							errorMessage={errors.achieved_premium?.message}
						/>
					</div>
				</div>

				{/* Commission Rate */}
				<div className='form-control w-full'>
					<label htmlFor='commission_rate' className='required-field-indicator'>
						Commission Rate
					</label>

					<div>
						<NumberInput
							id='commission_rate'
							icon={AiOutlinePercentage}
							{...register('commission_rate', {
								required: {
									value: true,
									message: 'Commission rate is required',
								},
							})}
							onValueChange={(value) => {
								if (!_.isNumber(Number(value))) return;

								return achieved_premium ? setValue('commission', Number(achieved_premium) * (value / 100)) : expected_premium ? setValue('commission', Number(expected_premium) * (value / 100)) : null;
							}}
							step='0.01'
							enableStepper={false}
							placeholder='Percentage rate'
							error={errors.commission_rate ? true : false}
							errorMessage={errors.commission_rate?.message}
						/>
					</div>
				</div>

				{/* Commission */}
				<div className='form-control w-full'>
					<label htmlFor='commission' className='required-field-indicator'>
						{achieved_premium ? 'Achieved' : 'Expected'} Commission
					</label>

					<div>
						<NumberInput
							id='commission'
							{...register('commission', {
								required: {
									value: true,
									message: 'Commission is required',
								},
							})}
							step='0.01'
							enableStepper={false}
							placeholder='Enter commission'
							error={errors.commission ? true : false}
							errorMessage={errors.commission?.message}
						/>
					</div>
				</div>
			</div>

			<footer className={`p-4  flex items-center gap-2 justify-end bg-gray-100 ${isOnCreateProspectPage ? 'lg:px-0 lg:py-2' : 'border-t'}`}>
				{isOnCreateProspectPage && (
					<Button
						type='button'
						onClick={() => {
							reset();
							setExpectedCloseDate(null as any);
							setSelectProduct(null as any);
							setCurrentProduct(null);
						}}
						color='red'
						variant='secondary'
						className='px-6 py-3 duration-300 !rounded-full hidden lg:inline'
					>
						Clear <span className='lg:inline hidden'>Form</span>
					</Button>
				)}

				<Button
					onClick={() => {
						setFormOpen(false);
						setTimeout(() => setCurrentProduct(null), 500);
					}}
					type='button'
					color='gray'
					variant='secondary'
					className='px-6 py-3 lg:hidden duration-300 !rounded-full'
				>
					Cancel
				</Button>

				<Button
					type='button'
					onClick={() => {
						handleSubmit(onSubmit)();
					}}
					color={product && !isConfirmDeleteProductDialogOpen ? 'orange' : 'green'}
					className='px-6 py-3 duration-300 !rounded-full'
				>
					{product && !isConfirmDeleteProductDialogOpen ? 'Update Product' : 'Add Product'}
				</Button>
			</footer>
		</form>
	);
};

export default EditCreateProspectInsuranceProductForm;
