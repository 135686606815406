import { Fragment, useRef } from 'react';
import { IVehicleRequest, IVehicleRequestComment, IVehicleRequestCommentFormValues } from './vehicleRequestsInterfaces';
import { Button, Callout, Icon, List, ListItem, Text, Title } from '@tremor/react';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import { useForm } from 'react-hook-form';
import { BiMessageSquareDots, BiSolidSend } from 'react-icons/bi';
import { createVehicleRequestComment } from './vehicleRequestQueries';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { hasPermission, processReactHookFormErrors } from 'helpers/index';
import { useAppSelector } from 'hooks/storeConnect';
import { authState } from 'features/auth/authSlice';
import { formatDate } from 'helpers/dateHelpers';
import { HiUser } from 'react-icons/hi2';
import ReadMoreParagraph from 'components/ReadMoreParagraph';
import { PermissionKeys } from 'features/auth/permissions';
import { HiExclamation } from 'react-icons/hi';

interface Props {
	vehicleRequest: IVehicleRequest;
	afterChange?: () => void;
}

const VehicleRequestComments = (props: Props) => {
	const { vehicleRequest, afterChange } = props;
	const comments = vehicleRequest?.comments ?? [];

	const { authUser } = useAppSelector(authState);
	const scrollClampRef = useRef<HTMLDivElement>(null);

	// ============================
	// * Create Vehicle Request
	// ============================
	const { isLoading: isCreateVehicleRequestCommentLoading, mutate: createVehicleRequestCommentMutation } = useMutation({
		mutationKey: ['vehicle-requests/create-vehicle-request-comment'],
		mutationFn: (params: IVehicleRequestCommentFormValues) => createVehicleRequestComment(params),
	});

	const {
		register,
		handleSubmit,
		setError,
		reset,
		formState: { errors, isSubmitting, isDirty },
	} = useForm<IVehicleRequestCommentFormValues>({
		defaultValues: {
			vehicle_request_id: vehicleRequest?.id,
		},
	});

	const onSubmit = (formValues: IVehicleRequestCommentFormValues) => {
		createVehicleRequestCommentMutation(formValues, {
			onSuccess: () => {
				toast.success('Comment added successfully!');
				reset();
				afterChange?.();
			},
			onError: (error) => {
				processReactHookFormErrors(error, 'Failed to create comment', setError);
			},
		});
	};

	const renderComments = (comments: IVehicleRequestComment[]) =>
		comments.map((comment, index) => {
			const { comment: message, user, created_at } = comment;

			return (
				<li key={index} className='!p-0 rounded-xl border border-gray-300 overflow-hidden'>
					<div className='w-full'>
						<div className='flex items-center gap-2 px-2 py-2 bg-gray-100 border-b border-gray-300'>
							{/* Icon */}
							<Icon icon={HiUser} size='md' variant='shadow' color={user.id === authUser?.id ? undefined : 'gray'} className='!rounded-full' />

							<Text className='!font-medium'>{user?.id === authUser?.id ? 'You' : `${user.first_name} ${user.last_name}`}</Text>

							{created_at && <Text className='ml-auto text-sm'>{formatDate(new Date(created_at), '01/31/1999, 12:00 AM')}</Text>}
						</div>

						<div className='py-3 pl-3 pr-2'>
							<ReadMoreParagraph maxLength={300} text={message} />
						</div>
					</div>
				</li>
			);
		});

	return (
		<div className='h-[calc(100vh-80px)] w-full'>
			<Fragment>
				{comments.length > 0 ? (
					<ul className='space-y-4 z-10 px-2 py-3 lg:px-4 lg:space-y-5 h-[calc(100%-210px)] overflow-y-auto with-scrollbar relative'>
						{renderComments(comments)}

						{/* Scroll clamp */}
						<div ref={scrollClampRef} />
					</ul>
				) : (
					<div className='px-2 pt-2 pb-10 lg:px-4'>
						<SimpleEmptyResourceIndicator containerClassName='lg:!py-16 !mt-0 !rounded-2xl' message='No comment found' />
					</div>
				)}
			</Fragment>

			<form onSubmit={handleSubmit(onSubmit)} className='z-20 border-t mx-4 pt-1 lg:mx-2 mb-2 flex-col flex xl:px-4 h-[200px] relative bg-white'>
				<div className='form-control w-full h-[calc(100%-50px)] pb-[10px]'>
					<textarea
						id='comment'
						disabled={!hasPermission(PermissionKeys.UPDATE_VEHICLE_REQUEST)}
						autoFocus
						{...register('comment', {
							required: {
								value: true,
								message: 'Comment is required',
							},
						})}
						placeholder='Write comment...'
						className=' w-full h-full !rounded-2xl !bg-gray-100 !border-gray-300'
					/>
					{errors?.comment && <span className='mr-1 text-red-400 form-message'>{errors?.comment?.message}</span>}
				</div>

				<div className='flex justify-start lg:flex-end'>
					{hasPermission(PermissionKeys.UPDATE_VEHICLE_REQUEST) ? (
						<Button
							type='submit'
							size='md'
							iconPosition='right'
							icon={BiSolidSend}
							loading={isCreateVehicleRequestCommentLoading}
							loadingText={'Adding Comment...'}
							disabled={isCreateVehicleRequestCommentLoading || !isDirty || isSubmitting}
							color={'green'}
							className='duration-300 !rounded-full'
						>
							Send
						</Button>
					) : (
						<Callout title='Unauthorized to comment' icon={HiExclamation} color='yellow' />
					)}
				</div>
			</form>
		</div>
	);
};

export default VehicleRequestComments;
