import BottomSheet from 'components/BottomSheet';
import { FilterComponentTypes } from 'features/settings/settingsInterfaces';
import { QueryObserverResult, useMutation } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import { Badge, Button, Card, Title } from '@tremor/react';
import ConfirmationDialog from 'components/ConfirmationDialog';
import Container from 'components/Container';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import { DropdownMenu } from 'components/DropdownMenu';
import { CancelAppointmentIcon, CarIcon, AppointmentIcon, ConfirmationDialogIcon, DeleteIcon, EditIcon, ArrowForwardIcon } from 'components/FormActionIcons';
import Modal from 'components/Modal';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import SlideOver from 'components/SlideOver';
import Table from 'components/Table';
import { DefaultTableDropDownTrigger } from 'components/TableDropdownTrigger';
import { MODAL_LEAVE_SPEED } from 'config';
import ScheduleAppointmentForm from 'features/ScheduleAppointmentForm';
import { AppointmentFulfillmentStatuses, AppointmentStatuses, IAppointment, IFetchAppointmentFilters, IFetchAppointmentsActionParams } from 'features/appointments/appointmentInterfaces';
import { deleteAppointment, updateAppointmentStatus } from 'features/appointments/appointmentQueries';
import useFetchAppointments from 'features/appointments/useFetchAppointments';
import Filters from 'components/Filters';
import { formatDate, formatDateRangePickerValue } from 'helpers/dateHelpers';
import { useState, ReactNode, Fragment, useEffect } from 'react';
import toast from 'react-hot-toast';
import { RiFilter2Fill } from 'react-icons/ri';
import { getAppointmentState, isMarketer } from 'utils/index';
import useFetchProspects from 'features/prospects/useFetchProspects';
import UpcomingAppointmentsSection from './AppointmentsSection';
import { TbCalendarCheck } from 'react-icons/tb';
import { authState } from 'features/auth/authSlice';
import { useAppSelector } from 'hooks/storeConnect';
import { IUser } from 'features/auth/authInterfaces';
import { hasPermission, hasPermissions, hasRoles, isAdmin } from 'helpers/index';
import { DefaultRoles } from 'features/settings/authorization/authorizationInterface';
import { AiOutlineStop } from 'react-icons/ai';
import AppointmentComments from './AppointmentComments';
import { AxiosResponse } from 'axios';
import VehicleRequestForm from 'features/navigation/VehicleRequestForm';
import { BiMessageSquareDots } from 'react-icons/bi';
import useFetchUsers from 'features/settings/users/useFetchUsers';
import { PermissionKeys } from 'features/auth/permissions';
import { TiCancelOutline } from 'react-icons/ti';
import ContentBlockedFromView from 'components/ContentBlockedFromView';

interface IAppointmentsTableColumns extends IAppointment {
	action: ReactNode;
}

const columnHelper = createColumnHelper<IAppointmentsTableColumns>();

export const getAppointmentTableDropDownMenuOptions = (
	appointment: IAppointment,
	options: {
		setCurrentAppointment: (val: any) => void;
		setVehicleRequestFormOpen: (val: any) => void;
		setScheduleAppointmentModalOpen: (val: any) => void;
		setConfirmApproveAppointmentDialog: (val: any) => void;
		setConfirmCancelAppointmentDialog: (val: any) => void;
		setConfirmDeclineAppointmentDialog: (val: any) => void;
		setConfirmDeleteAppointmentDialogOpen: (val: any) => void;
		setCommentFormOpen: (val: any) => void;
		authUser: IUser;
	}
) => {
	const {
		setCurrentAppointment,
		setVehicleRequestFormOpen,
		setScheduleAppointmentModalOpen,
		setConfirmApproveAppointmentDialog,
		setConfirmCancelAppointmentDialog,
		setConfirmDeclineAppointmentDialog,
		setConfirmDeleteAppointmentDialogOpen,
		setCommentFormOpen,
		authUser,
	} = options;
	const menuOptions: DropdownMenuOption[] = [];
	const { status } = appointment;

	hasPermission(PermissionKeys.VIEW_APPOINTMENT) &&
		menuOptions.push({
			type: 'button',
			text: 'Add Comment',
			action: (e: any) => {
				setCurrentAppointment(appointment);
				setCommentFormOpen(true);
				e.stopPropagation();
			},
			icon: <BiMessageSquareDots />,
		});

	if (status === AppointmentStatuses.Approved && hasPermission(PermissionKeys.CREATE_VEHICLE_REQUEST) && appointment.fulfillment_status !== AppointmentFulfillmentStatuses.Fulfilled) {
		menuOptions.push({
			type: 'button',
			text: 'Request Vehicle',
			action: (e: any) => {
				setCurrentAppointment(appointment);
				setVehicleRequestFormOpen(true);
				e.stopPropagation();
			},
			icon: <CarIcon variant='light' />,
		});
	}

	if (hasPermission(PermissionKeys.UPDATE_APPOINTMENT) && hasRoles([DefaultRoles.Admin, DefaultRoles.MarketingManager]))
		menuOptions.push({
			type: 'button',
			text: 'Reschedule',
			action: (e: any) => {
				setCurrentAppointment(appointment);
				setScheduleAppointmentModalOpen(true);
				e.stopPropagation();
			},
			icon: <EditIcon variant='light' />,
		});

	if (hasPermission(PermissionKeys.UPDATE_APPOINTMENT_STATUS) && status !== AppointmentStatuses.Approved)
		menuOptions.push({
			type: 'button',
			text: 'Approve Appointment',
			action: (e: any) => {
				setCurrentAppointment(appointment);
				setConfirmApproveAppointmentDialog(true);
				e.stopPropagation();
			},
			icon: <TbCalendarCheck />,
		});

	if (hasPermission(PermissionKeys.UPDATE_APPOINTMENT_STATUS) && status !== AppointmentStatuses.Declined)
		menuOptions.push({
			type: 'button',
			text: 'Decline Appointment',
			action: (e: any) => {
				setCurrentAppointment(appointment);
				setConfirmDeclineAppointmentDialog(true);
				e.stopPropagation();
			},
			icon: <AiOutlineStop />,
		});

	if (hasPermission(PermissionKeys.UPDATE_APPOINTMENT_STATUS) && status !== AppointmentStatuses.Cancelled)
		menuOptions.push({
			type: 'button',
			text: 'Cancel Appointment',
			action: (e: any) => {
				setCurrentAppointment(appointment);
				setConfirmCancelAppointmentDialog(true);
				e.stopPropagation();
			},
			icon: <CancelAppointmentIcon />,
		});

	if (hasPermission(PermissionKeys.DELETE_APPOINTMENT))
		menuOptions.push({
			type: 'button',
			text: 'Delete Appointment',
			action: (e: any) => {
				setCurrentAppointment(appointment);
				setConfirmDeleteAppointmentDialogOpen(true);
				e.stopPropagation();
			},
			icon: <DeleteIcon variant='light' />,
		});

	if (menuOptions.length === 0) {
		menuOptions.push({
			type: 'button',
			text: 'NOT AUTHORIZED',
			action: (e: any) => toast.error('You are not authorized perform any action on appointments'),
			icon: <TiCancelOutline />,
		});
	}

	return menuOptions;
};

const AppointmentsPage = () => {
	const { authUser } = useAppSelector(authState);

	const [isScheduleAppointmentModalOpen, setScheduleAppointmentModalOpen] = useState(false);
	const [isUpcomingAppointmentsSliderOpen, setUpcomingAppointmentsSlideOpen] = useState<boolean>(false);
	const [isMobileFiltersOpen, setMobileFiltersOpen] = useState<boolean>(false);
	const [isConfirmFulfillAppointmentDialog, setConfirmFulfillAppointmentDialog] = useState(false);
	const [isConfirmApproveAppointmentDialog, setConfirmApproveAppointmentDialog] = useState(false);
	const [isConfirmCancelAppointmentDialog, setConfirmCancelAppointmentDialog] = useState(false);
	const [isConfirmDeclineAppointmentDialog, setConfirmDeclineAppointmentDialog] = useState(false);
	const [isConfirmDeleteAppointmentDialogOpen, setConfirmDeleteAppointmentDialogOpen] = useState(false);
	const [isVehicleRequestFormOpen, setVehicleRequestFormOpen] = useState(false);
	const [isCommentFormOpen, setCommentFormOpen] = useState(false);
	const [currentAppointment, setCurrentAppointment] = useState<IAppointment | null>(null);

	const [fetchParams, setFetchParams] = useState<IFetchAppointmentsActionParams>({
		filterBy: {
			staffId: isMarketer() ? authUser?.id : undefined,
		},
		include: ['prospect', 'sales_stage', 'user', 'comments'],
	});
	const { isAppointmentsLoading, refetchAppointments, appointments, paginationMetadata } = useFetchAppointments({
		fetchParams: fetchParams,
		queryKey: [fetchParams],
	});

	useEffect(() => {
		if (!currentAppointment || !appointments) return;

		const thisAppointment = appointments.find((appointment) => appointment.id === currentAppointment?.id);
		setCurrentAppointment(thisAppointment as any);
		// eslint-disable-next-line
	}, [appointments]);

	useFetchUsers({
		enabled: hasPermission(PermissionKeys.VIEW_USER),
	});
	const { prospects } = useFetchProspects({
		queryKey: ['prospects-filter'],
		fetchParams: {
			filterBy: {
				userId: isMarketer() ? authUser?.id : undefined,
			},
			per_page: 100_000_000_000_000,
		},
	});

	const { users } = useFetchUsers({});

	// ================================
	// * Update Vehicle Request Status
	// ================================
	const { isLoading: isUpdateAppointmentStatusLoading, mutate: updateAppointmentStatusMutation } = useMutation({
		mutationKey: ['appointment/update-appointmentStatus'],
		mutationFn: (formValues: { appointmentId: number; status?: AppointmentStatuses; fulfillment_status?: AppointmentFulfillmentStatuses }) => updateAppointmentStatus(formValues),
	});

	// =====================
	// * Delete Appointment
	// =====================
	const { isLoading: isDeleteAppointmentLoading, mutate: deleteAppointmentMutation } = useMutation({
		mutationKey: ['appointment/delete-appointment'],
		mutationFn: (appointmentId: number) => deleteAppointment(appointmentId),
	});

	const onConfirmApproveAppointment = () => {
		if (!currentAppointment) return toast.error('No appointment selected for this action');

		updateAppointmentStatusMutation(
			{
				appointmentId: currentAppointment.id,
				status: AppointmentStatuses.Approved,
			},
			{
				onSuccess: () => {
					toast.success('Appointment has been approved successfully.');
					setScheduleAppointmentModalOpen(false);
				},
				onError: () => {
					toast.error('Failed to approve appointment.');
				},
				onSettled() {
					setTimeout(() => setCurrentAppointment(null), MODAL_LEAVE_SPEED);
					refetchAppointments();
				},
			}
		);

		setConfirmApproveAppointmentDialog(false);
	};
	const onConfirmFulfillAppointment = () => {
		if (!currentAppointment) return toast.error('No appointment selected for this action');

		updateAppointmentStatusMutation(
			{
				appointmentId: currentAppointment.id,
				fulfillment_status: AppointmentFulfillmentStatuses.Fulfilled,
			},
			{
				onSuccess: () => {
					toast.success('Appointment has been fulfilled successfully.');
					setConfirmFulfillAppointmentDialog(false);
				},
				onError: () => {
					toast.error('Failed to fulfill appointment.');
				},
				onSettled() {
					setTimeout(() => setCurrentAppointment(null), MODAL_LEAVE_SPEED);
					refetchAppointments();
				},
			}
		);

		setConfirmApproveAppointmentDialog(false);
	};

	const onConfirmDeclineAppointment = () => {
		if (!currentAppointment) return toast.error('No appointment selected for this action');

		updateAppointmentStatusMutation(
			{
				appointmentId: currentAppointment.id,
				status: AppointmentStatuses.Declined,
			},
			{
				onSuccess: () => {
					toast.success('Appointment declined successfully.');
					setScheduleAppointmentModalOpen(false);
				},
				onError: () => {
					toast.error('Failed to declined appointment.');
				},
				onSettled() {
					setTimeout(() => setCurrentAppointment(null), MODAL_LEAVE_SPEED);
					refetchAppointments();
				},
			}
		);

		setConfirmCancelAppointmentDialog(false);
	};

	const onConfirmCancelAppointment = () => {
		if (!currentAppointment) return toast.error('No appointment selected for this action');

		updateAppointmentStatusMutation(
			{
				appointmentId: currentAppointment.id,
				status: AppointmentStatuses.Cancelled,
			},
			{
				onSuccess: () => {
					toast.success('Appointment cancelled successfully.');
					setScheduleAppointmentModalOpen(false);
				},
				onError: () => {
					toast.error('Failed to cancel appointment.');
				},
				onSettled() {
					setTimeout(() => setCurrentAppointment(null), MODAL_LEAVE_SPEED);
					refetchAppointments();
				},
			}
		);

		setConfirmCancelAppointmentDialog(false);
	};

	const onConfirmDeleteAppointment = () => {
		if (!currentAppointment) return toast.error('No appointment selected for this action');

		deleteAppointmentMutation(currentAppointment.id, {
			onSuccess: () => {
				toast.success('Appointment deleted successfully.');
				setScheduleAppointmentModalOpen(false);
			},
			onError: () => {
				toast.error('Failed to delete appointment.');
			},
			onSettled() {
				setTimeout(() => setCurrentAppointment(null), MODAL_LEAVE_SPEED);
				refetchAppointments();
			},
		});

		setConfirmDeleteAppointmentDialogOpen(false);
	};

	const tableColumns = [
		columnHelper.accessor('action', {
			header: 'Actions',
			cell: (info) => {
				const appointment: IAppointment = info.row.original;

				return (
					<DropdownMenu
						renderTriggerElement={(state) => <DefaultTableDropDownTrigger state={state} />}
						menuOptions={getAppointmentTableDropDownMenuOptions(appointment, {
							authUser: authUser as any,
							setConfirmApproveAppointmentDialog,
							setConfirmDeclineAppointmentDialog,
							setConfirmCancelAppointmentDialog,
							setConfirmDeleteAppointmentDialogOpen,
							setCurrentAppointment,
							setScheduleAppointmentModalOpen,
							setVehicleRequestFormOpen,
							setCommentFormOpen,
						})}
					/>
				);
			},
		}),
		columnHelper.accessor('prospect.company_name', {
			header: 'Company Name',
			cell: ({ row }) => <div className='sm:font-semibold'>{row.original.prospect.company_name}</div>,
		}),
		columnHelper.accessor('prospect.sales_stage', {
			header: 'Sale Stage',
			cell: ({ row }) => <div className='sm:font-semibold'>{row.original.sales_stage.name}</div>,
		}),
		columnHelper.accessor('appointment_date', {
			header: 'Appointment Date',
			cell: ({ row }) => <div>{formatDate(row.original.appointment_date, 'Jan 31, 1999')}</div>,
		}),
		columnHelper.accessor('comments', {
			header: 'Comments',
			cell: ({ row }) => {
				const comments = row.original.comments;

				return (
					<button
						onClick={() => {
							setCommentFormOpen(true);
							setCurrentAppointment(row.original);
						}}
						type='button'
						className='flex items-center gap-2'
					>
						<div className='flex items-center justify-center w-8 h-8 p-1 font-semibold border rounded-full text-primary-700 bg-primary-50 border-primary-100'>{comments?.length}</div>
						{/* Comment{comments.length > 0 ? 's' : ''} */}
					</button>
				);
			},
		}),

		columnHelper.accessor('status', {
			header: 'Status',
			cell: ({ row }) => {
				const { isApproved, isFulfilled, isPending } = getAppointmentState(row.original);

				return (
					<Badge size='sm' className={`ml-1 text-white ${isPending ? 'bg-gray-500' : isApproved ? 'bg-yellow-500' : isFulfilled ? 'bg-green-600' : 'bg-red-700'}`}>
						{isPending ? 'Pending' : isApproved ? 'Approved' : isFulfilled ? 'Fulfilled' : 'Unfulfilled'}
					</Badge>
				);
			},
		}),
		columnHelper.accessor('prospect.sales_stage', {
			header: 'Created By',
			cell: ({ row }) => (
				<div className='sm:font-semibold'>
					{row.original.user.first_name} {row.original.user.middle_name} {row.original.user.last_name}
				</div>
			),
		}),
		columnHelper.accessor('created_at', {
			header: 'Date Created',
			cell: ({ row }) => formatDate(row.original.created_at, 'Jan 31, 1999'),
		}),
	];

	const resetCurrentAppointment = async () => {
		const appointmentsResponse: QueryObserverResult = await refetchAppointments();
		const axiosResponse: AxiosResponse = appointmentsResponse?.data as any;
		const appointments: IAppointment[] | undefined = axiosResponse?.data?.data;

		if (!appointments || !currentAppointment) return;

		const thisAppointment = appointments.find((request) => request.id === currentAppointment?.id);
		setCurrentAppointment(thisAppointment as any);
	};

	const onSubmitFilters = (filters: IFetchAppointmentFilters) => {
		let selectedDateRange: any;

		if (filters.dateRange && filters.dateType) {
			selectedDateRange = { [filters.dateType]: formatDateRangePickerValue(filters.dateRange as any) };
		} else {
			selectedDateRange = {};
		}

		const prevFetchFilters: any = {
			...fetchParams.filterBy,
		};
		delete prevFetchFilters.createdAt;
		delete prevFetchFilters.createdBetween;
		delete prevFetchFilters.appointmentDate;

		setFetchParams((prevValue) => ({
			...prevValue,
			filterBy: {
				...prevFetchFilters,
				...filters,
				...selectedDateRange,
			},
		}));
	};

	return (
		<Container className='pb-4 lg:pb-0 lg:pl-6 lg:pr-0 !h-[100%]'>
			<div className='lg:grid lg:grid-cols-[1fr,320px] lg:divide-x h-[100%] overflow-hidden'>
				<div className='h-full overflow-y-auto lg:pr-6 with-scrollbar'>
					<div className='flex items-center justify-between py-4 border-b lg:border-none lg:pb-0'>
						<Title className='!text-2xl'>Appointments</Title>

						<button
							onClick={() => {
								setUpcomingAppointmentsSlideOpen(true);
							}}
							type='button'
							title='Categorized appointments'
							className='flex items-center gap-2 text-base font-semibold duration-300 lg:text-sm lg:hidden hover:text-primary-500'
						>
							Categories <ArrowForwardIcon className='w-6 h-5' />
						</button>

						{hasPermission(PermissionKeys.CREATE_APPOINTMENT) && (
							<Button onClick={() => setScheduleAppointmentModalOpen(true)} className='!rounded-full !gap-2 hidden lg:inline-flex' icon={() => <AppointmentIcon variant='light' />}>
								Schedule Appointment
							</Button>
						)}
					</div>

					{hasPermission(PermissionKeys.VIEW_APPOINTMENT) ? (
						<Fragment>
							<div className='flex items-center justify-between w-full gap-4 mt-6 lg:items-end'>
								<Button
									color='gray'
									variant='secondary'
									onClick={() => setMobileFiltersOpen(true)}
									icon={RiFilter2Fill}
									className='!rounded-full hover:bg-gray-700 hover:!text-gray-100 duration-300 lg:hidden'
								>
									Filters
								</Button>

								<div className='hidden lg:flex lg:items-end'>
									<Filters
										filters={[
											{
												type: FilterComponentTypes.DateRangeWithSelect,
												label: 'Select Date',
												dateRange: {
													label: 'Select Dates',
													name: 'dateRange',
													placeholder: 'Select dates',
													type: FilterComponentTypes.DateRange,
												},
												select: {
													label: 'Date type',
													name: 'dateType',
													type: FilterComponentTypes.Select,
													placeholder: 'Select type',
													dropdownOptions: [
														{
															id: 1,
															label: 'Appointment Date',
															value: 'appointmentDate',
														},
														{
															id: 2,
															label: 'Date Created',
															value: 'createdBetween',
														},
													],
												},
											},
											{
												type: FilterComponentTypes.Select,
												label: 'Select Prospect',
												name: 'prospectId',
												placeholder: 'Filter by prospect',
												dropdownOptions:
													prospects?.map((prospect) => ({
														id: prospect.id,
														label: prospect.company_name,
														value: prospect.id,
													})) ?? [],
											},
											{
												type: FilterComponentTypes.Select,
												label: 'Select Status',
												name: 'status',
												placeholder: 'Filter by status',
												dropdownOptions: [
													{
														id: 1,
														label: AppointmentStatuses.Approved,
														value: AppointmentStatuses.Approved,
													},
													{
														id: 2,
														label: AppointmentStatuses.Pending,
														value: AppointmentStatuses.Pending,
													},
													{
														id: 3,
														label: AppointmentStatuses.Declined,
														value: AppointmentStatuses.Declined,
													},
													{
														id: 4,
														label: AppointmentStatuses.Cancelled,
														value: AppointmentStatuses.Cancelled,
													},
												],
											},
											{
												type: FilterComponentTypes.Select,
												name: 'staffId',
												label: 'Staff',
												dropdownOptions:
													users
														?.filter((user) => {
															// return user.roles.includes(DefaultRoles.Marketer) || user.roles.includes(DefaultRoles.MarketingManager)
															return hasPermissions([PermissionKeys.CREATE_APPOINTMENT]) && !user.roles.includes(DefaultRoles.SuperAdmin);
														})
														.map((user: any) => ({
															id: user.id,
															label: `${user.first_name} ${user?.middle_name ?? ' '} ${user.last_name}`,
															value: user.id,
														})) ?? [],
												placeholder: 'Select staff',
												permission: PermissionKeys.VIEW_USER_FILTERS,
											},
										]}
										submitButton={{
											label: 'Apply Filters',
											onSubmit: onSubmitFilters,
										}}
									/>
								</div>

								<Button onClick={() => setScheduleAppointmentModalOpen(true)} className='!rounded-full !gap-2 lg:hidden' icon={() => <AppointmentIcon variant='light' />}>
									Schedule Appointment
								</Button>
							</div>

							<Fragment>
								{!appointments && isAppointmentsLoading ? (
									<div className='mt-6'>
										<ContentLoadingCardSpinner message='Loading appointments...' />
									</div>
								) : appointments && appointments.length > 0 ? (
									<Card className='!p-0 pt-6 mt-6 overflow-hidden'>
										<Title className='px-4 py-4 border-b'>All Appointments</Title>

										<Table
											data={appointments}
											columns={tableColumns}
											pagination={{
												handlePageChange: (page: number) => {
													setFetchParams((prevValue) => ({
														...prevValue,
														page,
													}));
												},
												metadata: paginationMetadata,
											}}
										/>
									</Card>
								) : (
									<SimpleEmptyResourceIndicator containerClassName='lg:mt-8 lg:!py-10' message='No appointment found' />
								)}
							</Fragment>
						</Fragment>
					) : (
						<div className='mt-6'>
							<ContentBlockedFromView message='You are not authorized to view appointments.' containerClassName='min-h-[350px]' />
						</div>
					)}
				</div>

				{/*================ 
            Follow Ups
        ==================*/}
				<div className='h-[100%] overflow-hidden hidden lg:block'>
					<UpcomingAppointmentsSection
						appointments={appointments}
						isAppointmentsLoading={isAppointmentsLoading}
						refetchAppointments={refetchAppointments}
						onFulfillAppointment={(appointment) => {
							setCurrentAppointment(appointment);
							setConfirmFulfillAppointmentDialog(true);
						}}
					/>
				</div>
			</div>

			<BottomSheet
				isOpen={isMobileFiltersOpen}
				setOpen={setMobileFiltersOpen}
				closeOnOutSideClick
				header={{
					text: 'Filter Appointments',
				}}
			>
				<Filters
					filters={[
						{
							type: FilterComponentTypes.DateRangeWithSelect,
							label: 'Select Date',
							dateRange: {
								label: 'Select Dates',
								name: 'dateRange',
								placeholder: 'Select dates',
								type: FilterComponentTypes.DateRange,
							},
							select: {
								label: 'Date type',
								name: 'dateType',
								type: FilterComponentTypes.Select,
								placeholder: 'Select type',
								dropdownOptions: [
									{
										id: 1,
										label: 'Appointment Date',
										value: 'appointmentDate',
									},
									{
										id: 2,
										label: 'Date Created',
										value: 'createdBetween',
									},
								],
							},
						},
						{
							type: FilterComponentTypes.Select,
							label: 'Select Prospect',
							name: 'prospectId',
							placeholder: 'Filter by prospect',
							dropdownOptions:
								prospects?.map((prospect) => ({
									id: prospect.id,
									label: prospect.company_name,
									value: prospect.id,
								})) ?? [],
						},
						{
							type: FilterComponentTypes.Select,
							label: 'Select Status',
							name: 'status',
							placeholder: 'Filter by status',
							dropdownOptions: [
								{
									id: 1,
									label: AppointmentStatuses.Approved,
									value: AppointmentStatuses.Approved,
								},
								{
									id: 2,
									label: AppointmentStatuses.Pending,
									value: AppointmentStatuses.Pending,
								},
								{
									id: 3,
									label: AppointmentStatuses.Declined,
									value: AppointmentStatuses.Declined,
								},
								{
									id: 4,
									label: AppointmentStatuses.Cancelled,
									value: AppointmentStatuses.Cancelled,
								},
							],
						},
						{
							type: FilterComponentTypes.Select,
							name: 'staffId',
							label: 'Staff',
							dropdownOptions:
								users
									?.filter((user) => {
										// return user.roles.includes(DefaultRoles.Marketer) || user.roles.includes(DefaultRoles.MarketingManager)
										return hasPermissions([PermissionKeys.CREATE_APPOINTMENT]) && !user.roles.includes(DefaultRoles.SuperAdmin);
									})
									.map((user: any) => ({
										id: user.id,
										label: `${user.first_name} ${user?.middle_name ?? ' '} ${user.last_name}`,
										value: user.id,
									})) ?? [],
							placeholder: 'Select staff',
							permission: PermissionKeys.VIEW_USER_FILTERS,
						},
					]}
					submitButton={{
						label: 'Apply Filters',
						onSubmit: onSubmitFilters,
					}}
				/>
			</BottomSheet>

			<SlideOver
				title={{
					text: 'Upcoming Appointments',
				}}
				subTitle={{
					text: 'Approved appointments',
				}}
				onClose={() => null}
				open={isUpcomingAppointmentsSliderOpen}
				setOpen={setUpcomingAppointmentsSlideOpen}
				width={600}
				isScrollable
				afterClose={() => null}
				shouldCloseOnOutsideClick={true}
			>
				<UpcomingAppointmentsSection
					appointments={appointments}
					isAppointmentsLoading={isAppointmentsLoading}
					refetchAppointments={refetchAppointments}
					onFulfillAppointment={(appointment) => {
						setCurrentAppointment(appointment);
						setConfirmFulfillAppointmentDialog(true);
					}}
				/>
			</SlideOver>

			<SlideOver
				title={{
					text: 'Request Vehicle',
					theme: 'primary',
					styles: 'text-gray-100',
				}}
				subTitle={{
					text: `Request vehicle for prospecting on ${currentAppointment?.prospect.company_name}.`,
					styles: 'text-gray-200',
				}}
				onClose={() => null}
				open={isVehicleRequestFormOpen}
				setOpen={setVehicleRequestFormOpen}
				width={400}
				isScrollable
				afterClose={() => null}
				shouldCloseOnOutsideClick={false}
			>
				<VehicleRequestForm
					prospect={currentAppointment?.prospect}
					afterEdit={() => {
						refetchAppointments();
						setVehicleRequestFormOpen(false);
						setCurrentAppointment(null);
					}}
				/>
			</SlideOver>

			<SlideOver
				title={{
					text: 'Add Comment',
					theme: 'primary',
					styles: 'text-gray-100',
				}}
				subTitle={{
					text: `Pass comments on vehicle request ${formatDate(currentAppointment?.appointment_date, 'Jan 31, 1999')}`,
					styles: 'text-gray-200',
				}}
				onClose={() => null}
				afterClose={() => {
					setTimeout(() => {
						setCurrentAppointment(null);
					}, MODAL_LEAVE_SPEED);
				}}
				open={isCommentFormOpen}
				setOpen={setCommentFormOpen}
				width={450}
				isScrollable={false}
				shouldCloseOnOutsideClick={false}
			>
				<AppointmentComments
					appointment={currentAppointment as IAppointment}
					afterChange={async () => {
						await resetCurrentAppointment();
					}}
				/>
			</SlideOver>

			<Modal
				width={600}
				closeButton={{ styles: 'bg-gray-200' }}
				isModalOpen={isScheduleAppointmentModalOpen}
				setModalOpen={setScheduleAppointmentModalOpen}
				onCloseModal={() => {
					setScheduleAppointmentModalOpen(false);
					setTimeout(() => setCurrentAppointment(null), 500);
				}}
			>
				<ScheduleAppointmentForm
					prospect={currentAppointment?.prospect}
					appointment={currentAppointment as IAppointment}
					afterEdit={() => {
						refetchAppointments();
						setScheduleAppointmentModalOpen(false);
						setTimeout(() => setCurrentAppointment(null), 500);
					}}
				/>
			</Modal>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmFulfillAppointmentDialog}
				setOpen={setConfirmFulfillAppointmentDialog}
				message='Fulfill Appointment'
				subMessage={`Are you sure you want to fulfill appointment scheduled at ${formatDate(currentAppointment?.appointment_date, 'Jan 31, 1999')}. This action cannot be reversed.`}
				onClose={() => {
					setTimeout(() => setCurrentAppointment(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmFulfillAppointment,
					label: 'Fulfill',
					isLoading: isUpdateAppointmentStatusLoading,
					loadingText: 'Fulfilling',
					color: 'green',
				}}
				cancelButton={{
					action: () => setConfirmFulfillAppointmentDialog(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-500/10`}>
						<ConfirmationDialogIcon className='text-green-500' />
					</div>
				)}
			/>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmApproveAppointmentDialog}
				setOpen={setConfirmApproveAppointmentDialog}
				message='Approve Appointment'
				subMessage={`Are you sure you want to approve appointment scheduled at ${formatDate(
					currentAppointment?.appointment_date,
					'Jan 31, 1999'
				)}. The only action that can be taken after approval is vehicle request.`}
				onClose={() => {
					setTimeout(() => setCurrentAppointment(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmApproveAppointment,
					label: 'Approve',
					isLoading: isUpdateAppointmentStatusLoading,
					loadingText: 'Approving',
					color: 'green',
				}}
				cancelButton={{
					action: () => setConfirmApproveAppointmentDialog(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-500/10`}>
						<ConfirmationDialogIcon className='text-green-500' />
					</div>
				)}
			/>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmCancelAppointmentDialog}
				setOpen={setConfirmCancelAppointmentDialog}
				message='Cancel Appointment'
				subMessage={`Are you sure you want to cancel appointment scheduled at ${formatDate(currentAppointment?.appointment_date, 'Jan 31, 1999')}. This action cannot be reversed.`}
				onClose={() => {
					setTimeout(() => setCurrentAppointment(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmCancelAppointment,
					label: 'Proceed',
					isLoading: isUpdateAppointmentStatusLoading,
					loadingText: 'Processing',
					color: 'orange',
				}}
				cancelButton={{
					action: () => setConfirmCancelAppointmentDialog(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-500/10`}>
						<ConfirmationDialogIcon className='text-orange-500' />
					</div>
				)}
			/>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmDeclineAppointmentDialog}
				setOpen={setConfirmDeclineAppointmentDialog}
				message='Decline Appointment'
				subMessage={`Are you sure you want to decline appointment scheduled at ${formatDate(currentAppointment?.appointment_date, 'Jan 31, 1999')}. This action cannot be reversed.`}
				onClose={() => {
					setTimeout(() => setCurrentAppointment(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmDeclineAppointment,
					label: 'Decline',
					isLoading: isUpdateAppointmentStatusLoading,
					loadingText: 'Declining',
					color: 'rose',
				}}
				cancelButton={{
					action: () => setConfirmDeclineAppointmentDialog(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-rose-500/10`}>
						<ConfirmationDialogIcon className='text-rose-500' />
					</div>
				)}
			/>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmDeleteAppointmentDialogOpen}
				setOpen={setConfirmDeleteAppointmentDialogOpen}
				message='Delete Appointment'
				subMessage={`Are you sure you want to delete appointment scheduled at ${formatDate(currentAppointment?.appointment_date, 'Jan 31, 1999')}. This action cannot be reversed.`}
				onClose={() => {
					setTimeout(() => setCurrentAppointment(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmDeleteAppointment,
					label: 'Delete',
					isLoading: isDeleteAppointmentLoading,
					loadingText: 'Deleting',
					color: 'red',
				}}
				cancelButton={{
					action: () => setConfirmDeleteAppointmentDialogOpen(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-500/10`}>
						<ConfirmationDialogIcon className='text-red-500' />
					</div>
				)}
			/>
		</Container>
	);
};

export default AppointmentsPage;
