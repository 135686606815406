import { Navigate, Outlet, useLocation } from 'react-router-dom';

const NavigateLayout = () => {
	const { pathname } = useLocation();

	if (pathname === '/app/navigate') return <Navigate to={'/app/navigate/vehicle-requests'} />;

	return (
		<div className='bg-gray-50 w-full overflow-y-auto h-[calc(100vh-var(--appbar-height-mobile)-var(--app-navigation-height))] sm:h-[calc(100vh-var(--appbar-height-desktop)-var(--app-navigation-height))]'>
			<Outlet />
		</div>
	);
};

export default NavigateLayout;
