import { Callout } from '@tremor/react';
import PasswordSettingsPage from 'features/settings/PasswordSettingsPage';
import { HiExclamation } from 'react-icons/hi';

const FirstTimeUsersPasswordResetPage = () => {
	return (
		<div className='max-w-3xl mx-auto'>
			<div className='px-2 lg:px-4'>
				<Callout className='mt-4' title='Change password' icon={HiExclamation} color='yellow'>
					New users are required to update their password for security reasons. Please complete the form below to change your password, and thereafter, log in with the new password.
				</Callout>
			</div>

			<PasswordSettingsPage />
		</div>
	);
};

export default FirstTimeUsersPasswordResetPage;
