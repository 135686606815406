import { Button, NumberInput, TextInput, Title } from '@tremor/react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { processReactHookFormErrors } from 'helpers';
import { ISalesStage, ISalesStageFormValues } from '../prospectSettingsInterfaces';
import { createSalesStage, updateSalesStage } from '../prospectSettingsQueuries';

interface Props {
	salesStage: ISalesStage | null;
	refetchSalesStages: () => void;
	setCurrentSalesStage: (value: ISalesStage | null) => void;
	setEditSalesStageFormOpen: (value: boolean) => void;
}

const EditSalesStageForm = (props: Props) => {
	const { salesStage, setCurrentSalesStage, setEditSalesStageFormOpen, refetchSalesStages } = props;

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<ISalesStageFormValues>({
		defaultValues: {
			name: salesStage?.name,
			probability: salesStage?.probability,
		},
	});

	// =====================
	// * Create Sales Stage
	// =====================
	const { isLoading: isCreateSalesStageLoading, mutate: createSalesStageMutation } = useMutation({
		mutationKey: ['authorization/create-salesStage'],
		mutationFn: (params: ISalesStageFormValues) => createSalesStage(params),
	});

	// =====================
	// * Update Sales Stage
	// =====================
	const { isLoading: isUpdateSalesStageLoading, mutate: updateSalesStageMutation } = useMutation({
		mutationKey: ['authorization/update-salesStage'],
		mutationFn: ({ formValues, salesStageId }: { formValues: ISalesStageFormValues; salesStageId: number }) => updateSalesStage({ formValues: formValues, salesStageId }),
	});

	const onSubmit = (formValues: ISalesStageFormValues) => {
		if (salesStage)
			return updateSalesStageMutation(
				{ formValues, salesStageId: salesStage.id },
				{
					onSuccess: () => {
						toast.success('SalesStage updated successfully!');
						setTimeout(() => setCurrentSalesStage(null), 500);
						setEditSalesStageFormOpen(false);
						refetchSalesStages();
					},
					onError: (error) => {
						processReactHookFormErrors(error, 'Failed to update sales stage', setError);
					},
				}
			);

		createSalesStageMutation(formValues, {
			onSuccess: () => {
				toast.success('SalesStage created successfully!');
				setEditSalesStageFormOpen(false);
				refetchSalesStages();
			},
			onError: (error) => {
				processReactHookFormErrors(error, 'Failed to create sales stage', setError);
			},
		});
	};

	return (
		<form className='bg-white' onSubmit={handleSubmit(onSubmit)}>
			<header className={`${salesStage ? 'bg-gradient-to-r from-gray-800 to-gray-500' : 'bg-gradient-to-r from-primary-600 to-primary-300'} bg-dark p-6 h-[80px] flex items-center gap-2`}>
				<Title className='text-white'>{salesStage ? 'Update' : 'Create'} SalesStage</Title>
			</header>

			<section className='p-6 space-y-6 text-left'>
				<div>
					<label htmlFor='name' className='required-field-indicator'>
						Name
					</label>

					<div>
						<TextInput
							type='text'
							id='name'
							{...register('name', {
								required: {
									value: true,
									message: 'Sale stage name is required',
								},
							})}
							placeholder='Enter sales stage name'
							error={errors.name ? true : false}
							errorMessage={errors.name?.message}
						/>
					</div>
				</div>

				<div>
					<label htmlFor='winning_probability' className='required-field-indicator'>
						Winning Probability(%)
					</label>

					<div>
						<NumberInput
							min={0}
							id='winning_probability'
							{...register('probability', {
								required: {
									value: true,
									message: '',
								},
							})}
							placeholder='Enter stage winning probability'
							error={errors.name ? true : false}
							errorMessage={errors.name?.message}
						/>
					</div>
				</div>
			</section>

			<footer className='flex items-center justify-end gap-2 p-6 bg-gray-100'>
				<Button
					onClick={() => {
						setEditSalesStageFormOpen(false);
						setTimeout(() => setCurrentSalesStage(null), 500);
					}}
					type='button'
					color='gray'
					variant='secondary'
					className='px-6 py-3 duration-300 !rounded-full'
				>
					Cancel
				</Button>

				<Button
					type='submit'
					loading={isCreateSalesStageLoading || isUpdateSalesStageLoading}
					loadingText={salesStage ? 'Updating Stage' : 'Creating Stage'}
					disabled={isCreateSalesStageLoading || isUpdateSalesStageLoading}
					color={salesStage ? 'gray' : undefined}
					className='px-6 py-3 duration-300 !rounded-full'
				>
					{salesStage ? 'Update Stage' : 'Create Stage'}
				</Button>
			</footer>
		</form>
	);
};

export default EditSalesStageForm;
