import { useQuery, RefetchOptions, RefetchQueryFilters, QueryObserverResult } from '@tanstack/react-query';
import { fetchInsuranceProducts } from '../prospectSettingsQueuries';
import { IFetchInsuranceProductsActionParams, IInsuranceProduct } from '../prospectSettingsInterfaces';

interface ReturnType {
	insuranceProducts: IInsuranceProduct[] | undefined;
	isInsuranceProductsLoading: boolean;
	refetchInsuranceProducts: (options?: RefetchOptions & RefetchQueryFilters) => Promise<QueryObserverResult>;
}

interface Props {
	queryKey?: string;
	fetchParams?: IFetchInsuranceProductsActionParams;
	enabled?: boolean;
	onSuccess?: (insuranceProducts: IInsuranceProduct[]) => void;
}

const useFetchInsuranceProducts = ({ fetchParams, queryKey, enabled, onSuccess }: Props): ReturnType => {
	const { data, isLoading, refetch } = useQuery({
		queryKey: [queryKey ?? 'auth/fetch-insuranceProducts', fetchParams],
		queryFn: () => fetchInsuranceProducts(),
		enabled,
		onSuccess: onSuccess ? (data) => onSuccess?.(data?.data) : undefined,
	});

	const insuranceProductData = data?.data?.data;

	return { isInsuranceProductsLoading: isLoading, insuranceProducts: insuranceProductData, refetchInsuranceProducts: refetch };

	// eslint-disable-next-line
};

export default useFetchInsuranceProducts;
