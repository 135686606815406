import appNavigationMenu from 'components/Appbar/appNavigationMenu';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import ReportsNavigationMenu from './ReportsNavigationMenu';

const ReportsLayout = () => {
	const { pathname } = useLocation();

	if (pathname === '/app/reports') return <Navigate to={'/app/reports/analytics'} />;

	const getSettingsNavigationMenuLinks = () => appNavigationMenu.find((item) => item.id === 'settings')?.links.find((link) => link?.id === 'prospecting-settings');

	return (
		<div className='w-full divide-x grid lg:grid-cols-[var(--app-branding-width-lg),1fr] h-[calc(100vh-var(--appbar-height-mobile))] lg:h-[calc(100vh-var(--appbar-height-desktop))]'>
			<div className='hidden lg:block'>
				<ReportsNavigationMenu />
			</div>

			<div className='h-full overflow-y-auto'>
				<Outlet />
			</div>
		</div>
	);
};

export default ReportsLayout;
