import { useState, Fragment, ReactNode } from 'react';
import Container from 'components/Container';
import useFetchBranches from './useFetchBranches';
import { Button, Card, Metric, Title } from '@tremor/react';
import Modal from 'components/Modal';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { DefaultTableDropDownTrigger } from 'components/TableDropdownTrigger';
import { DropdownMenu } from 'components/DropdownMenu';
import { createColumnHelper } from '@tanstack/react-table';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import { MODAL_LEAVE_SPEED } from 'config';
import { hasPermission, titleCase } from 'helpers';
import EditBranchForm from './EditBranchForm';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { HiExclamation } from 'react-icons/hi';
import { IBranch, IFetchBranchesActionParams } from './branchInterfaces';
import { deleteBranch } from './branchQueries';
import Table from 'components/Table';
import { DeleteIcon, EditIcon } from 'components/FormActionIcons';
import SearchInput from 'components/SearchInput';
import { TiCancelOutline } from 'react-icons/ti';
import { PermissionKeys } from 'features/auth/permissions';
import ContentBlockedFromView from 'components/ContentBlockedFromView';

interface BranchesTableColumns extends IBranch {
	action: ReactNode;
}

const columnHelper = createColumnHelper<BranchesTableColumns>();

const BranchesPage = () => {
	const [fetchParams, setFetchParams] = useState<IFetchBranchesActionParams>({});
	const [isEditBranchFormOpen, setEditBranchFormOpen] = useState(false);
	const [isConfirmDeleteBranchDialogOpen, setConfirmDeleteBranchDialogOpen] = useState(false);
	const [currentBranch, setCurrentBranch] = useState<IBranch | null>(null);

	const { isBranchesLoading, refetchBranches, branches, isBranchesFetching, paginationMetadata } = useFetchBranches({
		queryKey: fetchParams,
		fetchParams,
	});

	// ======================
	// * Delete Branch
	// ======================
	const { isLoading: isDeleteBranchLoading, mutate: deleteBranchMutation } = useMutation({
		mutationKey: ['authorization/delete-branch'],
		mutationFn: (branchId: number) => deleteBranch(branchId),
	});

	const onConfirmDeleteBranch = () => {
		if (!currentBranch) return toast.error('No branch selected for this action');

		deleteBranchMutation(currentBranch.id, {
			onSuccess: () => {
				toast.success('Branch deleted successfully.');
				setEditBranchFormOpen(false);
			},
			onError: () => {
				toast.error('Failed to delete branch.');
			},
			onSettled() {
				setTimeout(() => setCurrentBranch(null), MODAL_LEAVE_SPEED);
				refetchBranches();
			},
		});

		setConfirmDeleteBranchDialogOpen(false);
	};

	const tableColumns = [
		columnHelper.accessor('action', {
			header: 'Actions',
			cell: (info) => {
				const menuOptions = [];

				if (hasPermission(PermissionKeys.UPDATE_BRANCH))
					menuOptions.push({
						type: 'button',
						text: 'Update',
						action: (e: any) => {
							setCurrentBranch(info.row.original);
							setEditBranchFormOpen(true);
							e.stopPropagation();
						},
						icon: <EditIcon />,
					});
				if (hasPermission(PermissionKeys.DELETE_BRANCH))
					menuOptions.push({
						type: 'button',
						text: 'Delete',
						action: (e: any) => {
							setCurrentBranch(info.row.original);
							setConfirmDeleteBranchDialogOpen(true);
							e.stopPropagation();
						},
						icon: <DeleteIcon />,
					});

				if (menuOptions.length === 0) {
					menuOptions.push({
						type: 'button',
						text: 'NOT AUTHORIZED',
						action: (e: any) => toast.error('You are not authorized perform any action on branches'),
						icon: <TiCancelOutline />,
					});
				}

				return <DropdownMenu renderTriggerElement={(state) => <DefaultTableDropDownTrigger state={state} />} menuOptions={menuOptions as any} />;
			},
		}),
		columnHelper.accessor('name', {
			cell: (info) => info.getValue(),
			header: 'Branch Name',
			id: 'branch_name',
		}),
		columnHelper.accessor('code', {
			cell: (info) => info.getValue(),
			header: 'Branch Code',
			id: 'branch_code',
		}),
	];

	return (
		<Container className='px-2 pt-4 pb-8 overflow-y-auto'>
			<Metric className='!text-2xl mb-4'>Branches</Metric>

			<div className='flex items-start justify-between gap-6'>
				{hasPermission(PermissionKeys.VIEW_BRANCH) && (
					<SearchInput
						onSearch={(term) =>
							setFetchParams((prevValue) => ({
								...prevValue,
								filterBy: {
									...prevValue.filterBy,
									name: term,
								},
							}))
						}
						isLoading={isBranchesFetching && fetchParams.filterBy?.name ? true : false}
						className='lg:max-w-[400px]'
						placeholder='Search branch name...'
					/>
				)}

				{hasPermission(PermissionKeys.UPDATE_BRANCH) && (
					<Button className='!rounded-full' onClick={() => setEditBranchFormOpen(true)}>
						Create Branch
					</Button>
				)}
			</div>

			<div>
				{hasPermission(PermissionKeys.VIEW_BRANCH) ? (
					<Fragment>
						{!branches && isBranchesLoading ? (
							<div className='mt-6'>
								<ContentLoadingCardSpinner message='Loading branches...' />
							</div>
						) : branches && branches.length > 0 ? (
							<div className='mt-8'>
								<Card className='!p-0 pt-6 overflow-hidden'>
									<Title className='px-4 py-4 border-b'>All Branches</Title>

									<Table
										data={branches}
										columns={tableColumns}
										pagination={{
											handlePageChange: (page: number) => {
												setFetchParams((prevValue) => ({
													...prevValue,
													page,
												}));
											},
											metadata: paginationMetadata,
										}}
									/>
								</Card>
							</div>
						) : (
							<SimpleEmptyResourceIndicator containerClassName='lg:mt-8 lg:!py-10' message='No branch found' />
						)}
					</Fragment>
				) : (
					<div className='mt-6'>
						<ContentBlockedFromView message='You are not authorized to view branches.' containerClassName='min-h-[350px]' />
					</div>
				)}
			</div>

			<Modal
				width={600}
				closeButton={{ styles: 'bg-gray-200' }}
				isModalOpen={isEditBranchFormOpen}
				setModalOpen={setEditBranchFormOpen}
				onCloseModal={() => {
					setEditBranchFormOpen(false);
					setTimeout(() => setCurrentBranch(null), 500);
				}}
				shouldCloseOnOutsideClick={false}
			>
				<EditBranchForm branch={currentBranch} setCurrentBranch={setCurrentBranch} setEditBranchFormOpen={setEditBranchFormOpen} refetchBranches={refetchBranches} />
			</Modal>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmDeleteBranchDialogOpen}
				setOpen={setConfirmDeleteBranchDialogOpen}
				message='Delete Branch'
				subMessage={`Are you sure you want to delete ${titleCase(currentBranch?.name as string) ?? 'this  branch'}? This action cannot be reversed.`}
				onClose={() => {
					setTimeout(() => setCurrentBranch(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmDeleteBranch,
					label: 'Delete',
					isLoading: isDeleteBranchLoading,
					loadingText: 'Deleting',
					color: 'red',
				}}
				cancelButton={{
					action: () => setConfirmDeleteBranchDialogOpen(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-500/10`}>
						<HiExclamation className='w-6 h-6 text-red-500' aria-hidden='true' />
					</div>
				)}
			/>
		</Container>
	);
};

export default BranchesPage;
