import { useEffect } from 'react';
import { motion } from 'framer-motion';
import { NavLink, Navigate, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { BsFileEarmark, BsFileEarmarkFill } from 'react-icons/bs';
import { useAppDispatch, useAppSelector } from 'hooks/storeConnect';
import { prospectsState, setActiveProspect } from '../prospectsSlice';
import { Button, Icon, Metric } from '@tremor/react';
import { AppointmentIcon, InteractionIcon } from 'components/FormActionIcons';
import { useQuery } from '@tanstack/react-query';
import { fetchSingleProspect } from '../prospectQueries';
import toast from 'react-hot-toast';
import { Tooltip } from 'react-tooltip';
import { IoArrowBackSharp } from 'react-icons/io5';
import queryClient from 'utils/reactQueryClient';

const ProspectDetailLayout = () => {
	const { id } = useParams();
	const { pathname } = useLocation();
	const { activeProspect } = useAppSelector(prospectsState);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const pages = [
		{
			name: 'Details',
			url: `/app/prospects/${id}/information`,
			iconInactive: <BsFileEarmark className='w-full h-full' />,
			iconActive: <BsFileEarmarkFill className='w-full h-full' />,
		},
		{
			name: 'Appointments',
			url: `/app/prospects/${id}/appointments`,
			iconInactive: <AppointmentIcon variant='light' className='w-full h-full' />,
			iconActive: <AppointmentIcon variant='solid' className='w-full h-full' />,
		},
		{
			name: 'Interactions',
			url: `/app/prospects/${id}/interactions`,
			iconInactive: <InteractionIcon className='w-full h-full' />,
			iconActive: <InteractionIcon className='w-full h-full' />,
		},
	];

	useQuery({
		queryKey: ['prospects/fetch-single-prospects'],
		queryFn: () => fetchSingleProspect(Number(id)),
		onSuccess(data) {
			dispatch(setActiveProspect(data.data.data));
		},
		onError: () => {
			toast.error('Error loading prospect information', { id: 'prospect-detail-error' });
		},
	});

	useEffect(() => {
		return () => {
			queryClient.invalidateQueries({
				queryKey: ['prospects/fetch-single-prospects'],
			});
		};
	}, []);

	if (pathname === `/app/prospects/${id}`) return <Navigate to={`/app/prospects/${id}/information`} />;

	return (
		<div className='h-[calc(100vh-var(--appbar-height-mobile))] sm:h-[calc(100vh-var(--appbar-height-desktop))] overflow-y-auto'>
			{activeProspect?.company_name && (
				<div className='h-[80px] sm:h-[100px] lg:gap-4 gap-2 px-2 sm:px-4 flex items-center'>
					<Button data-tooltip-id='leave-prospects-page' data-tooltip-content={'Back to Prospects List'} variant='light' onClick={() => navigate('/app/prospects')}>
						<Icon icon={IoArrowBackSharp} variant={'solid'} color='gray' className='!rounded-full' />
					</Button>

					<Tooltip id='leave-prospects-page' />

					<Metric className='!text-2xl sm:!text-3xl line-clamp-1'>{activeProspect?.company_name}</Metric>
				</div>
			)}

			<div className='bg-white shadow lg:shadow-sm sticky top-0 z-20 px-2 overflow-hidden border-b sm:px-4'>
				<div className='flex items-end h-full gap-4 sm:gap-6'>
					{pages.map((page, index) => {
						const { name, url, iconActive, iconInactive } = page;
						const isPageMatch = pathname === url;
						return (
							<NavLink
								to={url as string}
								key={index}
								className={({ isActive }) =>
									`flex items-center gap-1 sm:gap-2 relative whitespace-nowrap py-2 sm:text-sm lg:text-base font-normal capitalize !outline-none duration-300 hover:text-primary-300 ${
										isPageMatch ? 'text-primary-600' : 'text-gray-800/90'
									}`
								}
							>
								{isPageMatch && (
									<motion.span
										layoutId='prospect-detail-navigation-bubble'
										className={`${isPageMatch ? 'bg-primary-500' : 'bg-transparent'} absolute inset-x-0 bottom-0 z-10 inline-block h-[3px]`}
										style={{ borderRadius: 9999 }}
										transition={{ type: 'spring', bounce: 0.3, duration: 0.8 }}
									/>
								)}

								<div className={`w-5 h-5 ${isPageMatch ? 'text-primary-500' : 'text-gray-600'}`}>{isPageMatch ? iconActive : iconInactive}</div>
								{name}
							</NavLink>
						);
					})}
				</div>
			</div>

			<Outlet />
		</div>
	);
};

export default ProspectDetailLayout;
