import { Button, Card, Icon, Metric, Text, Title } from '@tremor/react';
import useFetchReports from './useFetchReports';
import { IoArrowBackSharp } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { RiLoader4Line } from 'react-icons/ri';
import { ReactNode } from 'react';
import { IExpectedTargetPerStaff } from './reportInterfaces';
import { createColumnHelper } from '@tanstack/react-table';
import { convertJSONToExcel, formatCurrency } from 'helpers/index';
import Table from 'components/Table';
import toast from 'react-hot-toast';
import { formatDate } from 'helpers/dateHelpers';
import { SiMicrosoftexcel } from 'react-icons/si';

interface IStaffTargetsTableColumns extends IExpectedTargetPerStaff {
	action: ReactNode;
	product_closing_percentage: number;
}

const columnHelper = createColumnHelper<IStaffTargetsTableColumns>();

// Todo: Click to open staff info page - That is when that page is created
const tableColumns = [
	// columnHelper.accessor('action', {
	// 	header: 'Actions',
	// 	cell: (info) => {
	// 		return (
	// 			<DropdownMenu
	// 				renderTriggerElement={(state) => <DefaultTableDropDownTrigger state={state} />}
	// 				menuOptions={[
	// 					{
	// 						type: 'button',
	// 						text: 'View Rep',
	// 						action: (e: any) => {
	// 							toast.success('Will open a page with the rep details');
	// 							e.stopPropagation();
	// 						},
	// 						icon: <GrFormViewHide />,
	// 					},
	// 				]}
	// 			/>
	// 		);
	// 	},
	// }),

	columnHelper.accessor('user', {
		cell: ({ row }) => {
			const { first_name, last_name, middle_name } = row.original.user;

			return (
				<span className='font-semibold'>
					{first_name} {middle_name} {last_name}
				</span>
			);
		},
		header: 'Staff',
	}),
	columnHelper.accessor('expected', {
		cell: ({ row }) => <span className={`text-blue-600`}>{formatCurrency(row.original.expected || 0)}</span>,
		header: 'Expected Premium',
	}),
	columnHelper.accessor('won', {
		cell: ({ row }) => <span className='text-green-600'>{formatCurrency(row.original.won || 0)}</span>,
		header: 'Achieved Premium',
	}),
	columnHelper.accessor('commission', {
		cell: ({ row }) => <span className='text-green-600'>{formatCurrency(row.original.commission || 0)}</span>,
		header: 'Commission',
	}),
	columnHelper.accessor('target', {
		cell: ({ row }) => <span className='text-gray-800'>{formatCurrency(row.original.target || 0)}</span>,
		header: 'Yearly Target - ' + new Date().getFullYear(),
	}),
	columnHelper.accessor('product_closing_percentage', {
		cell: ({ row }) => {
			const { expected, won } = row.original;
			if (!won || !expected) return <span className='font-semibold'>0%</span>;

			return <span className='font-semibold'>{((expected / won) * 100).toFixed(2)}%</span>;
		},
		header: 'Product Closing Percentage',
	}),
];

const exportReportToExcel = (targets: IExpectedTargetPerStaff[]) => {
	if (!targets || targets?.length === 0) return toast.error('No data to export. Please make sure your report has at least one record.');

	const sheetStructure = targets.map((targetItem) => {
		const { expected, won, target, user, commission } = targetItem;

		const productClosingPercentage = !won || !expected ? 0 : (expected / won) * 100;

		return {
			Staff: `${user.first_name} ${user.middle_name || ''} ${user.last_name}`,
			'Expected Premium': formatCurrency(expected ?? 0),
			'Achieved Premium': formatCurrency(won ?? 0),
			Commission: formatCurrency(commission ?? 0),
			[`Yearly Target (${new Date().getFullYear()})`]: formatCurrency(target ?? 0),
			'Product Closing Percentage': formatCurrency(productClosingPercentage ?? 0),
		};
	});

	convertJSONToExcel(sheetStructure, `Staff targets - ${formatDate(new Date(), 'Jan 31, 1999')}`);
};

const TargetPerStaffReportPage = () => {
	const navigate = useNavigate();
	const { reports, isReportsLoading } = useFetchReports({
		fetchParams: {},
		queryKey: 'reports/target-per-staff',
	});

	return (
		<div className='px-2 !py-2 sm:px-4'>
			<div className='flex items-center justify-between py-2'>
				<Button
					className='items-center gap-2 !flex'
					variant='light'
					color='gray'
					onClick={() => navigate('/app/dashboard/marketing')}
					icon={() => {
						return <Icon icon={IoArrowBackSharp} variant={'solid'} color='gray' className='!rounded-full' />;
					}}
				>
					Back to Dashboard
				</Button>
			</div>

			<div className='flex items-center justify-between mb-6'>
				<Metric className='!text-2xl sm:!text-3xl line-clamp-1'>Staff Targets</Metric>

				{reports?.earning_per_account && (
					<Button
						icon={SiMicrosoftexcel}
						className='duration-300 py-2 px-4 !rounded-full'
						variant='primary'
						color='emerald'
						size='xs'
						onClick={() => exportReportToExcel(reports?.expected_target_per_staff)}
					>
						Export as excel
					</Button>
				)}
			</div>

			<Card className='!p-0 pt-6 overflow-hidden !w-full !h-full'>
				<div className='flex items-center justify-between px-4 py-3 border-b'>
					<Title>All Staff Targets</Title>
				</div>

				{isReportsLoading ? (
					<div className='flex flex-col items-center justify-center gap-4 p-10'>
						<RiLoader4Line className='w-8 h-8 text-lg text-gray-400 animate animate-spin ' />
						<Text className='!text-base'>Loading top staff...</Text>
					</div>
				) : reports && reports?.expected_target_per_staff.length > 0 ? (
					<div className=''>
						<Table data={reports.expected_target_per_staff} columns={tableColumns} />
					</div>
				) : (
					<div className='flex items-center justify-center w-full h-full'>
						<Text className={'!text-base font-semibold'}>No staff found</Text>
					</div>
				)}
			</Card>
		</div>
	);
};

export default TargetPerStaffReportPage;
