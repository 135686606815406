import { useEffect } from 'react';
import { useAppSelector } from 'hooks/storeConnect';
import { Outlet, useLocation } from 'react-router-dom';
import { authState } from './authSlice';

const AuthLayout = () => {
	const { pathname } = useLocation();
	const { isAuthenticated } = useAppSelector(authState);

	useEffect(() => {
		if (isAuthenticated) window.location.replace('/app/dashboard');
	}, [isAuthenticated]);

	return (
		<div id='auth-layout'>
			<div className='w-screen h-screen overflow-hidden lg:grid lg:grid-cols-2'>
				{/* Left Content */}
				<Outlet />

				{/* Right Content */}
				<div className='relative hidden w-full h-full text-gray-200 lg:block'>
					{/* Auth Images */}
					<img src='/images/auth_layout.svg' alt='Login Page Background' className={`absolute inset-0 z-10 object-cover w-full h-full`} />

					{/* Overlay */}
					{/* <div
						className={`w-full h-full absolute inset-0 z-20 ${
							pathname.includes('login') ? 'bg-opacity-[0.8] bg-primary-700' : pathname.includes('forgot') ? 'bg-opacity-[0.5] bg-primary-700' : 'bg-opacity-[0.7] bg-primary-700'
						}`}
					>
						<img
							src='/images/auth_app_features_intro_timeline.svg'
							alt='App features introduction'
							className='absolute z-30 object-fill object-bottom h-full -ml-7 -bottom-32 2xl:w-[65%] lg:w-[92%] xl:w-[75%]'
						/>
					</div> */}
				</div>
			</div>
		</div>
	);
};

export default AuthLayout;
