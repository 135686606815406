import { motion } from 'framer-motion';
import { NavLink, useLocation } from 'react-router-dom';
import appNavigationMenu from './appNavigationMenu';
import { AppNavigationMenuItem } from 'features/settings/settingsInterfaces';
import { useAppDispatch } from 'hooks/storeConnect';
import { setActiveAppNavigationMenuItem } from 'features/settings/settingsSlice';

const DesktopNavigationMenu = () => {
	const location = useLocation();
	const dispatch = useAppDispatch();

	const onLinkSelected = (menu: AppNavigationMenuItem | undefined) => {
		const menuOption: any = {
			...menu,
			icon: 'Icon',
		};
		dispatch(setActiveAppNavigationMenuItem(menuOption || null));
	};

	return (
		<nav className='hidden h-full border-r divide-x lg:flex'>
			{appNavigationMenu[0].links?.map((link, index) => {
				const { name, url } = link || {};

				if (!url) return '';

				const isPageMatch = location.pathname.includes(url);

				return (
					<NavLink
						to={url as string}
						key={index}
						onClick={() => onLinkSelected(link)}
						className={({ isActive }) =>
							`relative whitespace-nowrap py-1.5 h-full flex items-center px-4 text-sm font-medium uppercase !outline-none duration-300 hover:text-primary-400 ${
								isPageMatch ? 'text-primary-500' : 'text-gray-800/90'
							}`
						}
					>
						{isPageMatch && (
							<motion.span
								layoutId='bubble'
								className={`${isPageMatch ? 'bg-primary-500' : 'bg-transparent'} absolute inset-x-0 bottom-0 z-10 inline-block h-[4px]`}
								style={{ borderRadius: 9999 }}
								transition={{ type: 'spring', bounce: 0.25, duration: 0.8 }}
							/>
						)}

						{name}
					</NavLink>
				);
			})}
		</nav>
	);
};

export default DesktopNavigationMenu;
