import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { logout } from 'features/auth/authQueries';
import { logout as storeLogout } from 'features/auth/authSlice';
import { isAdmin, isNewUser, isSuperAdmin, processReactHookFormErrors } from 'helpers/index';
import { useAppDispatch } from 'hooks/storeConnect';
// import { Features } from 'features/configurations/features';
// import { hasFeature, isGeneralAdmin } from 'helpers';
import _ from 'lodash';
import { ReactElement } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router';

interface Props {
	addIconToOptions?: { optionText: string; icon: ReactElement }[];
}

const useProfileMenu = ({ addIconToOptions }: Props) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { mutateAsync: logoutMutationAsync } = useMutation({
		mutationKey: ['auth/logout'],
		mutationFn: () => logout(),
		retry: 0,
	});

	const profileOptions: DropdownMenu = _.compact([
		{
			type: 'section',
			title: 'Account Settings',
			options: _.compact([
				{ type: 'link', text: 'Profile Details', url: '/app/settings/profile' },
				{ type: 'link', text: 'Password Settings', url: '/app/settings/password' },
			]),
		},
		isSuperAdmin() && !isNewUser()
			? {
					type: 'link',
					text: 'Authorization',
					url: '/app/settings/admin/authorization',
			  }
			: null,
		isAdmin() && !isNewUser()
			? {
					type: 'link',
					text: 'Prospecting',
					url: '/app/settings/admin/prospecting',
			  }
			: null,
		isAdmin() && !isNewUser()
			? {
					type: 'link',
					text: 'Branches',
					url: '/app/settings/admin/branches',
			  }
			: null,
		isAdmin() && !isNewUser()
			? {
					type: 'link',
					text: 'Departments',
					url: '/app/settings/admin/departments',
			  }
			: null,
		isSuperAdmin() && !isNewUser()
			? {
					type: 'link',
					text: 'User Accounts',
					url: '/app/settings/admin/user-accounts',
			  }
			: null,
		{
			type: 'button',
			text: 'Logout',
			action: (e: any) => {
				toast.promise(
					new Promise<void>((resolve) => {
						setTimeout(() => {
							resolve();
						}, 2000);
					}),
					{
						loading: 'Logging out...',
						success: () => {
							dispatch(storeLogout());
							navigate('/auth/login');

							logoutMutationAsync();

							return 'Logged out';
						},
						error: (err) => {
							dispatch(storeLogout());
							navigate('/auth/login');

							return 'Logged out';
						},
					},
					{
						position: 'top-right',
					}
				);
			},
			className: '!bg-primary-500 !text-white !py-3 hover:!bg-primary-600',
		},
	]);

	const addOrReplaceOptionIcon = (menu: DropdownMenu, searchList: { optionText: string; icon: ReactElement }[]): DropdownMenu => {
		return menu.map((option) => {
			if (option.type === 'section') {
				const updatedOptions = option.options.map((option) => {
					const matchedSearch = searchList.find((searchItem) => searchItem.optionText.toLowerCase() === option.text.toLowerCase());
					if (matchedSearch) {
						return { ...option, icon: matchedSearch.icon };
					}
					return option;
				});

				return { ...option, options: updatedOptions };
			}

			const matchedSearch = searchList.find((searchItem) => searchItem.optionText.toLowerCase() === option.text.toLowerCase());
			if (matchedSearch) {
				return { ...option, icon: matchedSearch.icon };
			}

			return option;
		});
	};

	if (addIconToOptions) return addOrReplaceOptionIcon(profileOptions, addIconToOptions);

	return profileOptions;
};

export default useProfileMenu;
