import { lsmsAPI } from 'ajax/httpClient';
import { IRoleFormValues } from './authorizationInterface';

export const fetchRoles = () =>
	lsmsAPI.get('/admin/roles', {
		params: {
			include: 'permissions',
		},
	});

export const createRole = (formValues: IRoleFormValues) => lsmsAPI.post('/admin/roles', formValues);

export const updateRole = ({ formValues, roleId }: { formValues: IRoleFormValues; roleId: number }) => lsmsAPI.put(`/admin/roles/${roleId}`, formValues);

export const deleteRole = (roleId: number) => lsmsAPI.delete(`/admin/roles/${roleId}`);

export const fetchPermissions = () => lsmsAPI.get('/admin/permissions');
