import AppLogoEmblem from 'components/Appbar/AppLogoEmblem';
import { Link } from 'react-router-dom';

const AppBranding = () => {
	return (
		<Link to={'/'} className='block w-full h-full'>
			<div className='bg-gradient-to-r from-[#151515] to-gray-600 w-fit lg:w-[var(--app-branding-width-lg)] flex items-center gap-2 h-full lg:px-4 px-2'>
				<AppLogoEmblem className='!h-[40px] !w-[40px] shrink-0 sm:!h-[48px] sm:!w-[48px] lg:!h-[60px] lg:!w-[60px]' />

				<div>
					<h1 className='text-xl font-semibold sm:text-2xl text-gray-100 uppercase tracking-[0.2em] sm:tracking-[0.25em]'>LeadCX</h1>
					<p className='-mt-1 text-sm font-light text-gray-300'>Loyalty Insurance Ltd.</p>
				</div>
			</div>
		</Link>
	);
};

export default AppBranding;
