import { useState } from 'react';
import { Button, DateRangePicker, Select, SelectItem, Subtitle, Tab, TabGroup, TabList, TabPanel, TabPanels, Title } from '@tremor/react';
import { Fragment } from 'react';
import { FollowUpStatuses, IFetchFollowUpsActionParams, IFollowUp } from './followUpInterfaces';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import FollowUp from './FollowUp';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { HiExclamation } from 'react-icons/hi';
import { MODAL_LEAVE_SPEED } from 'config';
import { hasPermission, hasRoles, isAdmin, titleCase } from 'helpers/index';
import { completeFollowUp, deleteFollowUp } from './followUpQueries';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import ScheduleFollowUpForm from './ScheduleFollowUpForm';
import Modal from 'components/Modal';
import { RiFilter2Fill } from 'react-icons/ri';
import { CloseIcon } from 'components/FormActionIcons';
import { formatDateRangePickerValue } from 'helpers/dateHelpers';
import OutsideClickHandler from 'components/OutsideClickHandler';
import useFetchSalesStages from 'features/settings/prospecting/useFetchSalesStages';
import { useNavigate } from 'react-router-dom';
import FollowUpTypesTabs from './FollowUpTypesTabs';
import { PermissionKeys } from 'features/auth/permissions';
import ContentBlockedFromView from 'components/ContentBlockedFromView';
import { DefaultRoles } from 'features/settings/authorization/authorizationInterface';
import { useAppSelector } from '../../hooks/storeConnect';
import { authState } from 'features/auth/authSlice';
import { getFollowUpState, isMarketer } from 'utils/index';

interface Props {
	followUps: IFollowUp[] | undefined;
	isFollowUpsLoading: boolean;
	refetchFollowUps: () => void;
	setFetchParams: (val: IFetchFollowUpsActionParams) => void;
	fetchParams: IFetchFollowUpsActionParams;
}

const FollowUpsSection = (props: Props) => {
	const { fetchParams, setFetchParams, followUps, isFollowUpsLoading, refetchFollowUps } = props;
	const { authUser } = useAppSelector(authState);

	const [filters, setFilters] = useState({
		status: undefined,
		createdBetween: undefined,
		saleStageId: undefined,
		userId: isMarketer() ? authUser?.id : undefined,
	} as any);
	const navigate = useNavigate();
	// let [activeTab, setActiveTab] = useState(tabs[0].id);
	const [isFiltersOpen, setFiltersOpen] = useState<boolean>(false);
	const [currentFollowUp, setCurrentFollowUp] = useState<IFollowUp | null>(null);
	const [isModifyFollowUpScheduleFormOpen, setModifyFollowUpScheduleFormOpen] = useState(false);
	const [isConfirmDeleteFollowUpDialogOpen, setConfirmDeleteFollowUpDialogOpen] = useState(false);
	const [isConfirmCompleteFollowUpDialogOpen, setConfirmCompleteFollowUpDialogOpen] = useState(false);

	const { salesStages, isSalesStagesLoading } = useFetchSalesStages({});

	const pendingFollowUps = followUps?.filter((followUp) => getFollowUpState(followUp).isPending);
	const completedFollowUps = followUps?.filter((followUp) => getFollowUpState(followUp).isCompleted);
	const uncompletedFollowUps = followUps?.filter((followUp) => getFollowUpState(followUp).isUncompleted);

	// =====================
	// * Delete Follow Up
	// =====================
	const { isLoading: isDeleteFollowUpLoading, mutate: deleteFollowUpMutation } = useMutation({
		mutationKey: ['authorization/delete-follow-up'],
		mutationFn: (followUpId: number) => deleteFollowUp(followUpId),
	});

	// ======================
	// * Complete Follow Up
	// ======================
	const { isLoading: isCompleteFollowUpLoading, mutate: completeFollowUpMutation } = useMutation({
		mutationKey: ['authorization/complete-follow-up'],
		mutationFn: (followUpId: number) => completeFollowUp(followUpId),
	});

	const afterEdit = () => {
		setTimeout(() => setCurrentFollowUp(null), MODAL_LEAVE_SPEED);
		refetchFollowUps();
	};

	const onCompleteFollowUp = (followUp: IFollowUp) => {
		setCurrentFollowUp(followUp);
		setConfirmCompleteFollowUpDialogOpen(true);
	};

	const onRescheduleFollowUp = (followUp: IFollowUp) => {
		setCurrentFollowUp(followUp);
		setModifyFollowUpScheduleFormOpen(true);
	};

	const onDeleteFollowUp = (followUp: IFollowUp) => {
		setCurrentFollowUp(followUp);
		setConfirmDeleteFollowUpDialogOpen(true);
	};

	const onConfirmCompleteFollowUp = () => {
		if (!currentFollowUp) return toast.error('No reminder selected for this action');

		completeFollowUpMutation(currentFollowUp.id, {
			onSuccess: () => {
				toast.success(`Reminder completed successfully. Please include a description of the interaction that took place between you and ${titleCase(currentFollowUp.prospect.company_name)}.`, {
					duration: 10000,
				});
				navigate(`/app/prospects/${currentFollowUp?.prospect.id}/interactions`);
				afterEdit();
				setConfirmDeleteFollowUpDialogOpen(false);
			},
			onError: () => {
				toast.error('Failed to complete reminder.');
			},
		});

		setConfirmCompleteFollowUpDialogOpen(false);
	};

	const onConfirmDeleteFollowUp = () => {
		if (!currentFollowUp) return toast.error('No reminder selected for this action');

		deleteFollowUpMutation(currentFollowUp.id, {
			onSuccess: () => {
				toast.success('Reminder deleted successfully.');
				setConfirmDeleteFollowUpDialogOpen(false);
				afterEdit();
			},
			onError: () => {
				toast.error('Failed to delete reminder.');
			},
		});

		setConfirmDeleteFollowUpDialogOpen(false);
	};

	const renderFollowUps = (followUpList: IFollowUp[] | undefined) =>
		followUpList?.map((followUp: IFollowUp, index: number) => {
			return <FollowUp onCompleteFollowUp={onCompleteFollowUp} onRescheduleFollowUp={onRescheduleFollowUp} onDeleteFollowUp={onDeleteFollowUp} followUp={followUp} key={index} />;
		});

	const renderFilterFollowUps = () => (
		<OutsideClickHandler onOutsideClick={() => setFiltersOpen(false)}>
			<div className={`absolute pb-2 inset-x-0 shadow-lg z-20 w-full rounded-b border-b bg-white duration-300 ${isFiltersOpen ? 'top-[90px]' : '-top-[250px]'}`}>
				<div className='flex items-center justify-between w-full gap-4 px-2 pb-1 mt-2'>
					<Subtitle className='!text-black font-semibold'>Filters</Subtitle>

					<button title='Close Filters' onClick={() => setFiltersOpen(false)} className='flex items-center justify-center w-6 h-6 text-white bg-red-600 rounded-md shrink-0 grow-0'>
						<CloseIcon />
					</button>
				</div>

				<div className='px-2 pb-2 space-y-0.5 w-full'>
					<div className='w-full'>
						<label htmlFor='follow-up-date-filter' className='label'>
							Reminders Dates
						</label>

						<DateRangePicker
							id='follow-up-date-filter'
							className='!min-w-full'
							enableYearNavigation
							value={filters.createdBetween}
							onValueChange={(val) =>
								setFilters({
									...filters,
									createdBetween: val,
								})
							}
						/>
					</div>

					{/* <div className='w-full'>
						<label htmlFor='follow-up-status' className='label'>
							Status
						</label>

						<Select enableClear={false} onChange={(val) => setFilters({ ...filters, status: val ? Number(val) : undefined })} value={filters.status?.toString()}>
							{followUpStatusFilters?.map((status, index) => (
								<SelectItem key={index} value={status?.value?.toString() as any}>
									{status.name}
								</SelectItem>
							))}
						</Select>
					</div> */}

					<div className='w-full'>
						<label htmlFor='sale-stage-filter' className='label'>
							Sale Stage
						</label>

						<Select onChange={(val) => setFilters({ ...filters, saleStageId: val ? Number(val) : undefined })} value={filters.saleStageId?.toString()} disabled={isSalesStagesLoading}>
							{salesStages && salesStages.length > 0 ? (
								salesStages?.map((stage, index) => (
									<SelectItem key={index} value={stage.id.toString()}>
										{stage.name}
									</SelectItem>
								))
							) : (
								<div className='px-2 py-2'>No sale stage</div>
							)}
						</Select>
					</div>

					<div className='flex items-center gap-2 pt-2'>
						<Button
							type='button'
							color='gray'
							onClick={() => {
								const newFilters = {
									status: '',
									createdBetween: null,
									saleStageId: '',
									userId: isMarketer() ? authUser?.id : undefined,
								};
								setFilters(newFilters);
								setFetchParams({ ...fetchParams, filterBy: newFilters as any });
							}}
						>
							Clear Filters
						</Button>

						<Button
							type='button'
							color='green'
							className='flex-1'
							onClick={() => {
								setFetchParams({
									...fetchParams,
									filterBy: {
										...fetchParams.filterBy,
										...filters,
										followUpDate: filters.createdBetween?.to ? formatDateRangePickerValue(filters.createdBetween) : '',
									},
								});
								setFiltersOpen(false);
							}}
						>
							Apply Filters
						</Button>
					</div>
				</div>
			</div>
		</OutsideClickHandler>
	);

	return (
		<TabGroup>
			<div className='w-full h-full bg-white'>
				<div className='relative border-t w-full h-[calc(100vh-var(--appbar-height-desktop)-var(--app-navigation-height))]'>
					<div className='py-2 !h-[85px] bg-white sticky z-30 top-[0px] lg:px-2 shadow-sm inset-x-0 px-2 space-y-2 border-b'>
						<div className='flex items-center justify-between gap-2'>
							<Title>Reminders</Title>

							{hasPermission(PermissionKeys.VIEW_FOLLOW_UP) && (
								<Button
									color='gray'
									variant='secondary'
									size='xs'
									onClick={() => setFiltersOpen(true)}
									icon={RiFilter2Fill}
									className='!rounded-full !py-1 hover:!bg-black hover:!text-gray-100 duration-300'
								>
									Filters
								</Button>
							)}
						</div>

						<TabList variant='solid' className='space-x-0 border'>
							<Tab className='px-2 text-sm py-0.5'>All</Tab>
							<Tab className='px-2 text-sm py-0.5'>Pending</Tab>
							<Tab className='px-2 text-sm py-0.5'>Completed</Tab>
							<Tab className='px-2 text-sm py-0.5'>Uncompleted</Tab>
						</TabList>
					</div>

					<Fragment>{renderFilterFollowUps()}</Fragment>

					<div className='h-[calc(100%-85px)] relative z-10'>
						{!followUps && isFollowUpsLoading ? (
							<div className='p-2'>
								<ContentLoadingCardSpinner message='Loading reminders...' />
							</div>
						) : followUps && followUps.length > 0 ? (
							<Fragment>
								{hasPermission(PermissionKeys.VIEW_FOLLOW_UP) ? (
									<TabPanels className='h-full px-2 overflow-x-hidden overflow-y-auto with-scrollbar'>
										<TabPanel className='w-full h-full space-y-2'>{renderFollowUps(followUps)}</TabPanel>
										<TabPanel className='w-full h-full space-y-2'>{renderFollowUps(pendingFollowUps)}</TabPanel>
										<TabPanel className='w-full h-full space-y-2'>{renderFollowUps(completedFollowUps)}</TabPanel>
										<TabPanel className='w-full h-full space-y-2'>{renderFollowUps(uncompletedFollowUps)}</TabPanel>
									</TabPanels>
								) : (
									<ContentBlockedFromView message='You are not authorized to view reminders.' containerClassName='min-h-[350px]' />
								)}
							</Fragment>
						) : (
							<div className='p-2'>
								<SimpleEmptyResourceIndicator containerClassName='!mt-1' message='No follow up found' />
							</div>
						)}
					</div>
				</div>

				<Modal
					width={600}
					closeButton={{ styles: 'bg-gray-200' }}
					isModalOpen={isModifyFollowUpScheduleFormOpen}
					setModalOpen={setModifyFollowUpScheduleFormOpen}
					onCloseModal={() => {
						setModifyFollowUpScheduleFormOpen(false);
						setTimeout(() => setCurrentFollowUp(null), 500);
					}}
				>
					<ScheduleFollowUpForm
						prospect={currentFollowUp?.prospect as any}
						followUp={currentFollowUp as IFollowUp}
						afterEdit={() => {
							refetchFollowUps();
							setModifyFollowUpScheduleFormOpen(false);
							setTimeout(() => setCurrentFollowUp(null), 500);
						}}
					/>
				</Modal>

				<ConfirmationDialog
					isPortrait={true}
					isOpen={isConfirmCompleteFollowUpDialogOpen}
					setOpen={setConfirmCompleteFollowUpDialogOpen}
					message='Complete Reminder'
					subMessage={`Confirm reminder on  ${titleCase(currentFollowUp?.prospect.company_name as string)} is completed. This action cannot be reversed.`}
					onClose={() => {
						setTimeout(() => setCurrentFollowUp(null), MODAL_LEAVE_SPEED);
					}}
					confirmButton={{
						action: onConfirmCompleteFollowUp,
						label: 'Complete',
						isLoading: isCompleteFollowUpLoading,
						loadingText: 'Completing',
						color: 'green',
					}}
					cancelButton={{
						action: () => setConfirmCompleteFollowUpDialogOpen(false),
					}}
					renderIcon={() => (
						<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-500/10`}>
							<HiExclamation className='w-6 h-6 text-green-500' aria-hidden='true' />
						</div>
					)}
				/>

				<ConfirmationDialog
					isPortrait
					isOpen={isConfirmDeleteFollowUpDialogOpen}
					setOpen={setConfirmDeleteFollowUpDialogOpen}
					message='Delete Reminder'
					subMessage={`Are you sure you want to delete reminder on ${titleCase(currentFollowUp?.prospect.business_type?.name as string)}? This action cannot be reversed.`}
					onClose={() => {
						setTimeout(() => setCurrentFollowUp(null), MODAL_LEAVE_SPEED);
					}}
					confirmButton={{
						action: onConfirmDeleteFollowUp,
						label: 'Delete',
						isLoading: isDeleteFollowUpLoading,
						loadingText: 'Deleting',
						color: 'red',
					}}
					cancelButton={{
						action: () => setConfirmDeleteFollowUpDialogOpen(false),
					}}
					renderIcon={() => (
						<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-500/10`}>
							<HiExclamation className='w-6 h-6 text-red-500' aria-hidden='true' />
						</div>
					)}
				/>
			</div>
		</TabGroup>
	);
};

export default FollowUpsSection;
