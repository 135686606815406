import { Card } from '@tremor/react';
import { FaLowVision } from 'react-icons/fa';

interface Props {
	message: string;
	containerClassName?: string;
}

const ContentBlockedFromView = (props: Props) => {
	return (
		<Card className={`flex h-full w-full flex-col items-center justify-center gap-5 overflow-hidden rounded bg-white py-5 ${props.containerClassName}`}>
			<FaLowVision className='w-10 h-10 text-gray-500' />

			<div className='text-center'>
				<h2 className='text-lg font-semibold text-gray-500'>Content Blocked From View</h2>
				<p className='text-sm font-medium opacity-60'>{props.message}</p>
			</div>
		</Card>
	);
};

export default ContentBlockedFromView;
