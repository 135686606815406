import React, { useEffect, useState } from 'react';
import withMap, { MapComponentProps } from 'components/withMap';
import RecentStaffLocations from './vehicle-requests/RecentStaffLocations';
import useFetchVehicleAllocations from './vehicle-allocations/useFetchVehicleAllocations';
import { IFetchVehicleAllocationsActionParams, IVehicleAllocation } from './navigateInterfaces';
import { parseLocation } from 'utils/index';
import { loyaltyInsuranceHeadOfficeLocationInfo } from 'features/prospects/prospectsSlice';
import { CloseIcon, LocationIcon, UserIcon } from 'components/FormActionIcons';
import { Badge, Button, Text } from '@tremor/react';
import { Link } from 'react-router-dom';
import SlideOver from 'components/SlideOver';
import useMediaQuery from 'hooks/useMediaQuery';
import { formatDate } from 'helpers/dateHelpers';
import { VehicleRequestTypes } from './vehicle-requests/vehicleRequestsInterfaces';

interface Props extends MapComponentProps {}

const StaffLocationsPage = React.forwardRef((props: Props, ref) => {
	const { mapRef, setMapPosition, setMapPositions } = props;

	const isMinTablet = useMediaQuery({ query: '(min-width: 1024px)' });

	const [vehicleAllocationsFetchParams, setVehicleAllocationsFetchParams] = useState<IFetchVehicleAllocationsActionParams>({
		includes: ['vehicle_request.user', 'transport_officer'],
		filterBy: {},
	});
	const [isMobileStaffListSliderOpen, setMobileStaffListSliderOpen] = useState<boolean>(isMinTablet ? false : true);
	const [selectedAllocation, setSelectedAllocation] = useState<IVehicleAllocation | null>(null);

	const { vehicleAllocations, isVehicleAllocationsLoading } = useFetchVehicleAllocations({ fetchParams: vehicleAllocationsFetchParams });

	useEffect(() => {
		plotAllFetchedAllocations();
		// eslint-disable-next-line
	}, [selectedAllocation, vehicleAllocations]);

	const plotAllFetchedAllocations = () => {
		if (selectedAllocation) return;
		if (!vehicleAllocations || vehicleAllocations?.length == 0) return setMapPosition?.(loyaltyInsuranceHeadOfficeLocationInfo.coordinate);

		let positions: IMapCoordinate[] = [];

		for (let i = 0; i < vehicleAllocations?.length; i++) {
			const endLocation = vehicleAllocations[i]?.end_location;
			const startLocation = vehicleAllocations[i]?.start_location;

			if (endLocation) {
				const endLocationCoordinates = parseLocation(endLocation)?.coordinate;

				if (endLocationCoordinates) {
					positions.push(endLocationCoordinates);
				}
			} else if (startLocation) {
				const startLocationCoordinates = parseLocation(startLocation)?.coordinate;

				if (startLocationCoordinates) {
					positions.push(startLocationCoordinates);
				}
			}

			continue;
		}

		setMapPositions?.(positions);
	};

	const getPresentLocationCoordinates = (vehicleAllocation: IVehicleAllocation) => {
		const endLocationCoordinates = vehicleAllocation.end_location ? parseLocation(vehicleAllocation.end_location).coordinate : null;
		const startLocationCoordinates = vehicleAllocation.start_location ? parseLocation(vehicleAllocation.start_location).coordinate : null;

		return endLocationCoordinates || startLocationCoordinates || null;
	};

	const handleAllocationSelect = (allocation: IVehicleAllocation) => {
		if (allocation.id === selectedAllocation?.id) {
			setSelectedAllocation(null);
			setMapPosition?.(null);
			// vehicleAllocations?.forEach((allocation) => {
			//   const {} = allocation;
			// })
			return;
		}

		const selectedLocationCoordinate = getPresentLocationCoordinates(allocation);
		if (selectedLocationCoordinate) {
			setSelectedAllocation(allocation);
			setMobileStaffListSliderOpen(false);
		}
		setMapPosition?.(selectedLocationCoordinate);
	};

	return (
		<div className='grid !h-[100%] w-full lg:grid-cols-[1fr,320px] border-gray-300 border-r'>
			<div className='relative w-full h-full min-h-[calc(100vh-var(--appbar-height-desktop)-var(--app-navigation-height))]'>
				{/* Map */}
				<div className='w-full h-full relative z-10 min-h-[calc(100vh-var(--appbar-height-desktop)-var(--app-navigation-height))]' ref={mapRef as any} />

				{/* Mobile Staff Locations Toggler */}
				<Button
					onClick={() => setMobileStaffListSliderOpen(true)}
					className='lg:hidden !rounded-full !gap-2 absolute z-40 text-white flex items-center justify-center h-8 px-4 py-5 !border-none bottom-6 left-6'
					icon={() => <UserIcon variant='light' />}
				>
					Search Staff
				</Button>

				{/* Selected Location Info */}
				{selectedAllocation && (
					<div className='absolute z-40 top-4 backdrop-blur-md bg-black/10 flex border-black/5 inset-x-4 shadow-lg border rounded-lg py-3 px-4'>
						<LocationIcon variant='solid' className='h-8 w-8 text-red-500' />

						{(() => {
							const { end_location, start_location } = selectedAllocation;
							const { name, url } = parseLocation(end_location || start_location);
							const { transport_officer, updated_at, vehicle_request } = selectedAllocation || {};

							return (
								<div className='flex-1'>
									<Text className='!font-bold !text-xl flex items-center capitalize line-clamp-1 text-ellipsis !text-gray-600'>{name}</Text>

									{url && (
										<Link to={url} className='text-xs uppercase font-light text-blue-500 underline' target='_blank'>
											Open in google map
										</Link>
									)}

									<div className='flex items-start divide-x divide-gray-400 mt-1'>
										<div className='pr-2'>
											<label className='text-[10px] uppercase text-gray-500'>Staff</label>
											<Text className='!font-medium'>
												{vehicle_request?.user?.first_name} {vehicle_request?.user?.middle_name} {vehicle_request?.user?.last_name}
											</Text>
										</div>

										{/* <div>
											<label className='text-[10px] uppercase text-gray-500'>Transport Officer</label>
											<Text className='!font-medium'>
												{transport_officer?.first_name} {transport_officer?.middle_name} {transport_officer?.last_name}
											</Text>
										</div> */}

										<div className='pl-3'>
											<label className='text-[10px] uppercase text-gray-500'>Time Recorded</label>
											<Text className='!font-medium'>{formatDate(updated_at, 'Mon, 31st Jan 1999 at 12:00AM')}</Text>
										</div>

										{/* <div className='mt-1'>
											{vehicle_request.request_type === VehicleRequestTypes.Prospecting ? (
												<Badge className='font-semibold bg-primary-500 text-white'>Prospecting</Badge>
											) : (
												<Badge color={'gray'} className='font-semibold !bg-gray-600 text-white'>
													General
												</Badge>
											)}
										</div> */}
									</div>
								</div>
							);
						})()}

						<button
							title='Clear Selected Trip'
							type='button'
							onClick={() => setSelectedAllocation(null)}
							className='h-7 w-7 rounded-lg bg-red-600 shrink-0 grow-0 text-white flex items-center justify-center '
						>
							<CloseIcon />
						</button>
					</div>
				)}
			</div>

			<div className='w-full hidden lg:block h-full min-h-[calc(100vh-var(--appbar-height-desktop)-var(--app-navigation-height))]'>
				<RecentStaffLocations
					setFetchParams={setVehicleAllocationsFetchParams}
					fetchParams={vehicleAllocationsFetchParams}
					handleAllocationSelect={handleAllocationSelect}
					selectedAllocation={selectedAllocation}
					vehicleAllocations={vehicleAllocations}
					isVehicleAllocationsLoading={isVehicleAllocationsLoading}
				/>
			</div>

			<SlideOver onClose={() => null} open={isMobileStaffListSliderOpen} setOpen={setMobileStaffListSliderOpen} width={600} isScrollable afterClose={() => null} shouldCloseOnOutsideClick={true}>
				<RecentStaffLocations
					setFetchParams={setVehicleAllocationsFetchParams}
					fetchParams={vehicleAllocationsFetchParams}
					handleAllocationSelect={handleAllocationSelect}
					selectedAllocation={selectedAllocation}
					vehicleAllocations={vehicleAllocations}
					isVehicleAllocationsLoading={isVehicleAllocationsLoading}
				/>
			</SlideOver>
		</div>
	);
});

export default withMap(StaffLocationsPage);
