import { Button, TextInput, Title } from '@tremor/react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { processReactHookFormErrors } from 'helpers';
import { IDepartment, ICreateDepartmentFormValues } from './departmentInterfaces';
import { createDepartment, updateDepartment } from './departmentQueries';

interface Props {
	department: IDepartment | null;
	refetchDepartments: () => void;
	setCurrentDepartment: (value: IDepartment | null) => void;
	setEditDepartmentFormOpen: (value: boolean) => void;
}

const EditDepartmentForm = (props: Props) => {
	const { department, setCurrentDepartment, setEditDepartmentFormOpen, refetchDepartments } = props;

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<ICreateDepartmentFormValues>({
		defaultValues: {
			name: department?.name,
			code: department?.code,
		},
	});

	// =====================
	// * Create Department
	// =====================
	const { isLoading: isCreateDepartmentLoading, mutate: createDepartmentMutation } = useMutation({
		mutationKey: ['authorization/create-department'],
		mutationFn: (params: ICreateDepartmentFormValues) => createDepartment(params),
	});

	// =====================
	// * Update Department
	// =====================
	const { isLoading: isUpdateDepartmentLoading, mutate: updateDepartmentMutation } = useMutation({
		mutationKey: ['authorization/update-department'],
		mutationFn: ({ formValues, departmentId }: { formValues: ICreateDepartmentFormValues; departmentId: number }) => updateDepartment({ formValues: formValues, departmentId }),
	});

	const onSubmit = (formValues: ICreateDepartmentFormValues) => {
		if (department)
			return updateDepartmentMutation(
				{ formValues, departmentId: department.id },
				{
					onSuccess: () => {
						toast.success('Department updated successfully!');
						setTimeout(() => setCurrentDepartment(null), 500);
						setEditDepartmentFormOpen(false);
						refetchDepartments();
					},
					onError: (error) => {
						processReactHookFormErrors(error, 'Failed to update department', setError);
					},
				}
			);

		createDepartmentMutation(formValues, {
			onSuccess: () => {
				toast.success('Department created successfully!');
				setEditDepartmentFormOpen(false);
				refetchDepartments();
			},
			onError: (error) => {
				processReactHookFormErrors(error, 'Failed to create department', setError);
			},
		});
	};

	return (
		<form className='bg-white' onSubmit={handleSubmit(onSubmit)}>
			<header className={`${department ? 'bg-gradient-to-r from-gray-800 to-gray-500' : 'bg-gradient-to-r from-primary-600 to-primary-300'} bg-dark p-6 h-[80px] flex items-center gap-2`}>
				<Title className='text-white'>{department ? 'Update' : 'Create'} Department</Title>
			</header>

			<section className='p-6 space-y-6 text-left'>
				<div>
					<label htmlFor='name' className='required-field-indicator'>
						Name
					</label>

					<div>
						<TextInput
							type='text'
							id='name'
							{...register('name', {
								required: {
									value: true,
									message: 'Department name is required',
								},
							})}
							placeholder='Enter department name'
							error={errors.name ? true : false}
							errorMessage={errors.name?.message}
						/>
					</div>
				</div>

				<div>
					<label htmlFor='code' className='required-field-indicator'>
						Code
					</label>

					<div>
						<TextInput
							type='text'
							id='code'
							{...register('code', {
								required: {
									value: true,
									message: 'Department code is required',
								},
							})}
							placeholder='Enter department code'
							error={errors.code ? true : false}
							errorMessage={errors.code?.message}
						/>
					</div>
				</div>
			</section>

			<footer className='flex items-center justify-end gap-2 p-6 bg-gray-100'>
				<Button
					onClick={() => {
						setEditDepartmentFormOpen(false);
						setTimeout(() => setCurrentDepartment(null), 500);
					}}
					type='button'
					color='gray'
					variant='secondary'
					className='px-6 py-3 duration-300 !rounded-full'
				>
					Cancel
				</Button>

				<Button
					type='submit'
					loading={isCreateDepartmentLoading || isUpdateDepartmentLoading}
					loadingText={department ? 'Updating Department' : 'Creating Department'}
					disabled={isCreateDepartmentLoading || isUpdateDepartmentLoading}
					color={department ? 'gray' : undefined}
					className='px-6 py-3 duration-300 !rounded-full'
				>
					{department ? 'Update Department' : 'Create Department'}
				</Button>
			</footer>
		</form>
	);
};

export default EditDepartmentForm;
