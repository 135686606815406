import { Button, DatePicker, Select, SelectItem, Text, Title } from '@tremor/react';
import { IVehicleRequest, IVehicleRequestFormValues, VehicleRequestTypes } from 'features/navigate/vehicle-requests/vehicleRequestsInterfaces';
import { useForm, useWatch } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { formatDate } from 'helpers/dateHelpers';
import withMap, { MapComponentProps } from 'components/withMap';
import { CarIcon } from 'components/FormActionIcons';
import { createVehicleRequest, updateVehicleRequest } from 'features/navigate/vehicle-requests/vehicleRequestQueries';
import { useMutation } from '@tanstack/react-query';
import { IProspect } from 'features/prospects/prospectInterfaces';
import { PiBuildingsFill } from 'react-icons/pi';
import useFetchProspects from 'features/prospects/useFetchProspects';
import toast from 'react-hot-toast';
import { hasRoles, isAdmin, processReactHookFormErrors } from 'helpers/index';
import PlacesCombobox from 'components/PlacesCombobox';
import { DefaultRoles } from 'features/settings/authorization/authorizationInterface';
import { useAppSelector } from 'hooks/storeConnect';
import { authState } from 'features/auth/authSlice';
import { isMarketer } from 'utils/index';

interface Props extends MapComponentProps {
	vehicleRequest?: IVehicleRequest | null;
	afterEdit?: () => void;
	prospect?: IProspect;
}

const vehicleRequestTypes = [
	{
		name: VehicleRequestTypes.General,
		value: VehicleRequestTypes.General,
	},
	{
		name: VehicleRequestTypes.Prospecting,
		value: VehicleRequestTypes.Prospecting,
	},
];

const VehicleRequestForm = (props: Props) => {
	const { authUser } = useAppSelector(authState);

	const { mapRef, placesSearch, getPlaceInfoFromPrediction, setMapPosition, vehicleRequest, prospect, afterEdit, isMapLoaded } = props;
	const { predictions, setMapSearchText } = placesSearch || {};

	const { prospects } = useFetchProspects({
		fetchParams: {
			filterBy: {
				userId: isMarketer() ? authUser?.id : undefined,
			},
			per_page: 100_000_000_000_000,
		},
		queryKey: 'prospects-dropdown',
	});

	const [selectFields, setSelectFields] = useState<IVehicleRequestFormValues>({
		...vehicleRequest,
		request_type: prospect ? VehicleRequestTypes.Prospecting : vehicleRequest ? vehicleRequest.request_type : undefined,
		prospect_id: prospect ? prospect.id : vehicleRequest ? vehicleRequest?.prospect_id : undefined,
	} as any);

	// ===========================
	// * Create Vehicle Request
	// ===========================
	const { isLoading: isCreateVehicleRequestLoading, mutate: createVehicleRequestMutation } = useMutation({
		mutationKey: ['authorization/create-vehicle-request'],
		mutationFn: (params: IVehicleRequestFormValues) => createVehicleRequest(params),
	});

	// ===========================
	// * Update Vehicle Request
	// ===========================
	const { isLoading: isUpdateVehicleRequestLoading, mutate: updateVehicleRequestMutation } = useMutation({
		mutationKey: ['authorization/update-vehicle-request'],
		mutationFn: ({ formValues, vehicleRequestId }: { formValues: IVehicleRequestFormValues; vehicleRequestId: number }) => updateVehicleRequest({ formValues, vehicleRequestId }),
	});

	const {
		register,
		handleSubmit,
		setError,
		clearErrors,
		control,
		getValues,
		setValue,
		formState: { errors },
	} = useForm<IVehicleRequestFormValues>({
		defaultValues: {
			...vehicleRequest,
			request_type: prospect ? VehicleRequestTypes.Prospecting : vehicleRequest ? vehicleRequest.request_type : undefined,
			prospect_id: prospect ? prospect.id : vehicleRequest ? vehicleRequest?.prospect_id : undefined,
			departure_time: vehicleRequest ? vehicleRequest.departure_date.split(' ')[1] : undefined,
			destination: prospect
				? prospect?.location
				: vehicleRequest?.request_type === VehicleRequestTypes.Prospecting
				? vehicleRequest.prospect.location
				: vehicleRequest
				? vehicleRequest.destination
				: undefined, //Todo: Replace with actual destination
			reason: vehicleRequest ? vehicleRequest.reason : prospect && selectFields.request_type !== VehicleRequestTypes.General ? `Prospecting on ${prospect.company_name}` : '',
		},
	});
	const { destination, request_type } = useWatch({ control });

	useEffect(() => {
		if (request_type === VehicleRequestTypes.General && destination) setMapPosition?.(JSON.parse(destination)?.coordinate);
		// eslint-disable-next-line
	}, [request_type]);

	const onSubmit = (formValues: IVehicleRequestFormValues) => {
		const { request_type, departure_date, departure_time, destination } = formValues;

		if (request_type === null || request_type === undefined) {
			return setError('request_type', { message: 'Request type is required' });
		} else if (departure_date === null || departure_date === undefined) {
			return setError('departure_date', { message: 'Departure date is required' });
		} else if (destination === null || destination === undefined) {
			return setError('destination', { message: 'Destination is required' });
		}

		let requestPayload: any = {
			...formValues,
			departure_date: formatDate(new Date(departure_date), '1999-12-31') + 'T' + departure_time,
		};

		if (vehicleRequest)
			return updateVehicleRequestMutation(
				{ formValues: requestPayload, vehicleRequestId: vehicleRequest.id },
				{
					onSuccess: () => {
						toast.success('Vehicle request updated successfully!');
						afterEdit?.();
					},
					onError: (error) => {
						processReactHookFormErrors(error, 'Failed to update vehicle request', setError);
					},
				}
			);

		createVehicleRequestMutation(requestPayload, {
			onSuccess: () => {
				toast.success('Vehicle request created successfully!');
				afterEdit?.();
			},
			onError: (error) => {
				processReactHookFormErrors(error, 'Failed to create vehicle request', setError);
			},
		});
	};

	const formatPredictions = () => {
		return predictions && predictions?.length > 0
			? predictions?.map((result, index) => ({
					id: index,
					name: result.description,
					value: result,
			  }))
			: [];
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className='flex flex-col relative w-full h-[calc(100vh-80px)]'>
			<div className='p-4 space-y-2 h-[calc(100%-70px)] overflow-y-auto'>
				<div className='relative z-30 w-full form-control'>
					<label htmlFor='request_type' className='required-field-indicator'>
						Request Type
					</label>

					<div>
						<Select
							id='request_type'
							placeholder='Select request type'
							disabled={prospect || vehicleRequest ? true : false}
							value={selectFields?.request_type}
							icon={CarIcon}
							enableClear={false}
							onValueChange={(val) => {
								setValue('request_type', val);
								setSelectFields({
									...selectFields,
									request_type: val,
								} as any);
								if (errors.request_type) clearErrors('request_type');
							}}
						>
							{vehicleRequestTypes && vehicleRequestTypes.length > 0 ? (
								vehicleRequestTypes?.map((type, index) => (
									<SelectItem key={index} value={type.value}>
										{type.name}
									</SelectItem>
								))
							) : (
								<div className='px-2 py-2'>No request type</div>
							)}
						</Select>
						{errors?.request_type && <span className='form-message'>{errors?.request_type?.message}</span>}
					</div>
				</div>

				{request_type === VehicleRequestTypes.Prospecting && (
					<div className='w-full form-control'>
						<label htmlFor='prospect_id' className='required-field-indicator'>
							Prospect
						</label>

						<div>
							<Select
								id='prospect_id'
								icon={PiBuildingsFill}
								disabled={prospect ? true : false}
								enableClear={false}
								value={selectFields?.prospect_id?.toString()}
								onValueChange={(val) => {
									setValue('prospect_id', Number(val));
									const prospect = prospects?.find((p) => p.id.toString() === val);
									setValue('destination', prospect?.location as string);
									setSelectFields({
										...selectFields,
										prospect_id: Number(val),
									} as any);

									if (errors.prospect_id) clearErrors('prospect_id');
									if (errors.destination) clearErrors('destination');
								}}
								placeholder='Select prospect'
							>
								{prospects && prospects.length > 0 ? (
									prospects?.map((prospect, index) => (
										<SelectItem key={index} value={prospect.id.toString()}>
											{prospect.company_name}
										</SelectItem>
									))
								) : (
									<div className='px-2 py-2'>No prospect</div>
								)}
							</Select>

							{errors?.prospect_id && <span className='form-message'>{errors?.prospect_id?.message}</span>}
						</div>
					</div>
				)}

				{request_type === VehicleRequestTypes.General && (
					<div className='relative z-20'>
						<Title className='!text-base !font-semibold mb-2'>Destination</Title>

						<div className='px-3 pt-3 pb-4 space-y-2 border border-gray-300 rounded-xl bg-gray-50'>
							<div className='relative z-20 w-full form-control'>
								<label htmlFor='destination' className='required-field-indicator'>
									Location
								</label>

								<div>
									<PlacesCombobox
										menu={formatPredictions()}
										placeholder='Search an address'
										onSearch={(value) => {
											if (!value) setMapSearchText?.('Loyalty Insurance Ghana');

											setMapSearchText?.(value);
										}}
										selectedItemName={destination ? JSON.parse(destination).name : null}
										onPlaceChange={(place: google.maps.places.AutocompletePrediction) => {
											getPlaceInfoFromPrediction?.(place, (placeInfo: google.maps.places.PlaceResult) => {
												const { geometry, url, name } = placeInfo;
												const { lat, lng } = geometry?.location || {};

												setValue(
													'destination',
													JSON.stringify({
														coordinate: { lat: lat?.(), lng: lng?.() },
														url,
														name,
													})
												);
											});
										}}
									/>
								</div>
							</div>

							<div className='relative z-10 w-full mt-1 overflow-hidden border border-gray-300 h-52 rounded-xl'>
								{!getValues('destination') ? (
									<div className='flex flex-col items-center justify-center w-full h-full'>
										<Text className='text-lg'>No address selected.</Text>
									</div>
								) : (
									<div className='relative w-full h-full' ref={mapRef} />
								)}
							</div>
						</div>
					</div>
				)}

				<div>
					<Title className='!text-base !font-semibold mb-2'>Departure</Title>

					<div className='px-3 pt-3 pb-4 space-y-2 border border-gray-300 rounded-xl bg-gray-50'>
						<div className='w-full form-control'>
							<label htmlFor='departure_date' className='required-field-indicator'>
								Date
							</label>

							<div>
								<DatePicker
									id='departure_date'
									enableClear={false}
									className={`!max-w-full`}
									enableYearNavigation
									minDate={new Date()}
									placeholder={'Select date...'}
									value={selectFields?.departure_date ? new Date(selectFields?.departure_date as any) : undefined}
									onValueChange={(val) => {
										setValue('departure_date', formatDate(val, '1999-12-31') as any);
										setSelectFields({
											...selectFields,
											departure_date: formatDate(val, '1999-12-31'),
										} as any);
										if (errors.departure_date) clearErrors('departure_date');
									}}
								/>
								{errors?.departure_date && <span className='form-message'>{errors?.departure_date?.message}</span>}
							</div>
						</div>

						<div className='w-full form-control'>
							<label htmlFor='departure_time' className='required-field-indicator'>
								Time
							</label>

							<div>
								<input
									id='departure_time'
									type='time'
									className={`!max-w-full input-custom !py-1.5 !px-3`}
									placeholder={'Select time...'}
									{...register('departure_time', {
										required: {
											value: true,
											message: 'Departure time is required',
										},
									})}
								/>
								{errors?.departure_time && <span className='form-message'>{errors?.departure_time?.message}</span>}
							</div>
						</div>
					</div>
				</div>

				<div className='form-control'>
					<label htmlFor='reason' className={'required-field-indicator'}>
						Narration
					</label>

					<div>
						<textarea
							id='reason'
							{...register('reason', {
								required: {
									value: true,
									message: 'Reason for requesting vehicle is required',
								},
							})}
							placeholder='Enter reason for requesting vehicle'
							className='min-h-[200px]'
						/>
						{errors?.reason && <span className='mr-1 text-red-400 form-message'>{errors?.reason?.message}</span>}
					</div>
				</div>
			</div>

			{/* Actions */}
			<section className='h-[70px] border-t space-y-2 p-4 flex items-center gap-4 w-full bg-gray-100'>
				<Button
					type={'submit'}
					disabled={isUpdateVehicleRequestLoading || isCreateVehicleRequestLoading}
					color={vehicleRequest ? 'orange' : 'green'}
					loading={isUpdateVehicleRequestLoading || isCreateVehicleRequestLoading}
					loadingText={'Updating vehicle request...'}
					className='!rounded-full'
				>
					{vehicleRequest ? 'Update' : 'Create'} Vehicle Request
				</Button>
			</section>
		</form>
	);
};

export default withMap(VehicleRequestForm);
