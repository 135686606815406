import { Fragment, useState } from 'react';
import { Button, Callout, Divider, Metric, Text, Title } from '@tremor/react';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import useFetchDecisionMakers from './useFetchDecisionMakers';
import { IDecisionMaker } from '../prospectSettingsInterfaces';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { HiExclamation } from 'react-icons/hi';
import toast from 'react-hot-toast';
import { MODAL_LEAVE_SPEED } from 'config';
import { useMutation } from '@tanstack/react-query';
import { deleteDecisionMaker } from '../prospectSettingsQueuries';
import { hasPermission, titleCase } from 'helpers';
import Modal from 'components/Modal';
import EditDecisionMakerForm from './EditDecisionMakerForm';
import { DeleteIcon, EditIcon } from 'components/FormActionIcons';
import { PermissionKeys } from 'features/auth/permissions';
import ContentBlockedFromView from 'components/ContentBlockedFromView';

const DecisionMakersPage = () => {
	const [isEditDecisionMakerFormOpen, setEditDecisionMakerFormOpen] = useState(false);
	const [isConfirmDeleteDecisionMakerDialogOpen, setConfirmDeleteDecisionMakerDialogOpen] = useState(false);
	const [currentDecisionMaker, setCurrentDecisionMaker] = useState<IDecisionMaker | null>(null);

	const { isDecisionMakersLoading, refetchDecisionMakers, decisionMakers } = useFetchDecisionMakers({
		enabled: hasPermission(PermissionKeys.VIEW_DECISION_MAKER_TYPE),
	});

	// ======================
	// * Delete Decision maker
	// ======================
	const { isLoading: isDeleteDecisionMakerLoading, mutate: deleteDecisionMakerMutation } = useMutation({
		mutationKey: ['authorization/delete-decisionMaker'],
		mutationFn: (decisionMakerId: number) => deleteDecisionMaker(decisionMakerId),
	});

	const onConfirmDeleteDecisionMaker = () => {
		if (!currentDecisionMaker) return toast.error('No decision maker selected for this action');

		deleteDecisionMakerMutation(currentDecisionMaker.id, {
			onSuccess: () => {
				toast.success('Decision maker updated successfully!');
				setEditDecisionMakerFormOpen(false);
			},
			onError: () => {
				toast.error('Failed to delete decision maker');
			},
			onSettled() {
				setTimeout(() => setCurrentDecisionMaker(null), MODAL_LEAVE_SPEED);
				refetchDecisionMakers();
			},
		});

		setConfirmDeleteDecisionMakerDialogOpen(false);
	};

	const renderActionButtons = (decisionMaker: IDecisionMaker) => (
		<div className='flex items-center gap-6'>
			{hasPermission(PermissionKeys.UPDATE_DECISION_MAKER_TYPE) && (
				<button
					onClick={() => {
						setCurrentDecisionMaker(decisionMaker);
						setEditDecisionMakerFormOpen(true);
					}}
					type='button'
					title='Edit Decision Maker'
					className='flex items-center justify-center gap-2 group'
				>
					<div className='flex items-center justify-center text-yellow-600 duration-500 border border-yellow-100 lg:h-10 lg:w-10 lg:bg-yellow-50 lg:rounded-full lg:group-hover:text-white lg:group-hover:bg-gray-700'>
						<EditIcon />
					</div>

					<Text className='lg:font-medium'>Edit</Text>
				</button>
			)}

			{hasPermission(PermissionKeys.DELETE_DECISION_MAKER_TYPE) && (
				<button
					onClick={() => {
						setCurrentDecisionMaker(decisionMaker);
						setConfirmDeleteDecisionMakerDialogOpen(true);
					}}
					type='button'
					title='Delete Decision Maker'
					className='flex items-center justify-center gap-2 group '
				>
					<div className='flex items-center justify-center text-red-600 duration-500 border border-red-100 lg:h-10 lg:w-10 lg:bg-red-50 lg:rounded-full lg:group-hover:text-white lg:group-hover:bg-gray-700'>
						<DeleteIcon />
					</div>
					<Text className='lg:font-medium'>Delete</Text>
				</button>
			)}

			{!(hasPermission(PermissionKeys.UPDATE_DECISION_MAKER_TYPE) && hasPermission(PermissionKeys.DELETE_DECISION_MAKER_TYPE)) && (
				<Callout title='Not authorized to perform action' icon={HiExclamation} color='yellow' />
			)}
		</div>
	);

	const renderDecisionMakers = (decisionMakers: IDecisionMaker[]) =>
		decisionMakers.map((decisionMaker, index) => (
			<div key={index}>
				<Divider />

				<div className='items-start gap-2 lg:gap-4 lg:items-center grid sm:grid-cols-[1fr,200px]'>
					<div>
						<Title>{decisionMaker.name}</Title>
						{decisionMaker?.description && <Text>{decisionMaker.description}</Text>}
					</div>

					<div className='mt-2 sm:mt-0'>{renderActionButtons(decisionMaker)}</div>
				</div>
			</div>
		));

	return (
		<Fragment>
			<div className='px-2 py-6 sm:px-4'>
				<div className='flex items-center justify-between'>
					<div className='flex items-center gap-3'>
						<Metric className='hidden sm:block'>Prospecting</Metric>

						{/* Separator */}
						<span className='hidden sm:inline-block w-[1px] h-[40px] bg-black rotate-12' />

						<Title className='lg:mt-1'>Decision Makers</Title>
					</div>

					{hasPermission(PermissionKeys.CREATE_DECISION_MAKER_TYPE) && (
						<Button className='!rounded-full' onClick={() => setEditDecisionMakerFormOpen(true)}>
							Create Decision Maker
						</Button>
					)}
				</div>

				<div>
					{hasPermission(PermissionKeys.VIEW_DECISION_MAKER_TYPE) ? (
						<Fragment>
							{!decisionMakers && isDecisionMakersLoading ? (
								<div className='mt-6'>
									<ContentLoadingCardSpinner message='Loading decision makers...' />
								</div>
							) : decisionMakers && decisionMakers.length > 0 ? (
								<Fragment>
									<div className='sm:grid-cols-[1fr,200px] hidden sm:grid sm:mt-6'>
										<h1 className='text-sm font-semibold text-gray-600 uppercase sm:text-xs'>Decision Maker</h1>
										<h1 className='text-sm font-semibold text-gray-600 uppercase sm:text-xs'>Action</h1>
									</div>

									{renderDecisionMakers(decisionMakers)}
								</Fragment>
							) : (
								<SimpleEmptyResourceIndicator containerClassName='lg:mt-8' message='No decision maker found' />
							)}
						</Fragment>
					) : (
						<div className='mt-6'>
							<ContentBlockedFromView message='You are not authorized to view decision makers types.' containerClassName='min-h-[350px]' />
						</div>
					)}
				</div>
			</div>

			<Modal
				width={600}
				closeButton={{ styles: 'bg-gray-200' }}
				isModalOpen={isEditDecisionMakerFormOpen}
				setModalOpen={setEditDecisionMakerFormOpen}
				onCloseModal={() => {
					setEditDecisionMakerFormOpen(false);
					setTimeout(() => setCurrentDecisionMaker(null), 500);
				}}
				shouldCloseOnOutsideClick={false}
			>
				<EditDecisionMakerForm
					decisionMakers={decisionMakers}
					decisionMaker={currentDecisionMaker}
					setCurrentDecisionMaker={setCurrentDecisionMaker}
					setEditDecisionMakerFormOpen={setEditDecisionMakerFormOpen}
					refetchDecisionMakers={refetchDecisionMakers}
				/>
			</Modal>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmDeleteDecisionMakerDialogOpen}
				setOpen={setConfirmDeleteDecisionMakerDialogOpen}
				message='Delete Decision maker'
				subMessage={`Are you sure you want to delete ${titleCase(currentDecisionMaker?.name as string) ?? 'this  decision maker'}? This action cannot be reversed.`}
				onClose={() => {
					setTimeout(() => setCurrentDecisionMaker(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmDeleteDecisionMaker,
					label: 'Delete',
					isLoading: isDeleteDecisionMakerLoading,
					loadingText: 'Deleting',
					color: 'red',
				}}
				cancelButton={{
					action: () => setConfirmDeleteDecisionMakerDialogOpen(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-500/10`}>
						<HiExclamation className='w-6 h-6 text-red-500' aria-hidden='true' />
					</div>
				)}
			/>
		</Fragment>
	);
};

export default DecisionMakersPage;
