import { useState, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Select, SelectItem, Title } from '@tremor/react';
import { useMutation } from '@tanstack/react-query';
import { PiBuildingsFill } from 'react-icons/pi';
import toast from 'react-hot-toast';
import { processReactHookFormErrors } from 'helpers/index';
import { IProspect } from 'features/prospects/prospectInterfaces';
import { ICreateInteractionFormValues, IInteraction } from './interactionInterfaces';
import useFetchProspects from 'features/prospects/useFetchProspects';
import { createInteraction, updateInteraction } from './interactionQueries';
import { useLocation } from 'react-router-dom';
import useFetchSalesStages from 'features/settings/prospecting/useFetchSalesStages';
import { MAX_IMAGE_SIZE } from 'config';
import { RiDragDropFill, RiSpeakFill } from 'react-icons/ri';
import DragAndDrop from 'components/DragAndDrop';
import { CloseIcon } from 'components/FormActionIcons';
import _ from 'lodash';
import useFetchInteractionTypes from 'features/settings/prospecting/interactionTypes/useFetchInteractionTypes';
import { useAppSelector } from 'hooks/storeConnect';
import { prospectsState } from 'features/prospects/prospectsSlice';

interface Props {
	interaction: IInteraction | null;
	prospect: IProspect;
	setEditInteractionModalOpen: (val: boolean) => void;
	afterEdit?: () => void;
}

const EditInteractionForm = (props: Props) => {
	const { interaction, afterEdit, prospect, setEditInteractionModalOpen } = props;
	const [selectFields, setSelectFields] = useState<ICreateInteractionFormValues>({
		prospect_id: prospect ? prospect.id : interaction ? interaction?.prospect.id : undefined,
		sale_stage_id: prospect.sales_stage?.id,
		interaction_type_id: interaction?.interaction_type.id,
		files: [],
	} as any);

	const { pathname } = useLocation();
	const { activeProspect } = useAppSelector(prospectsState);
	const { isProspectsLoading, prospects } = useFetchProspects({});
	const { salesStages, isSalesStagesLoading } = useFetchSalesStages({});
	const { interactionTypes, isInteractionTypesLoading } = useFetchInteractionTypes({});

	// ========================
	// * Create Interaction
	// ========================
	const { isLoading: isCreateInteractionLoading, mutate: createInteractionMutation } = useMutation({
		mutationKey: ['interactions/create-interaction'],
		mutationFn: (params: ICreateInteractionFormValues) => createInteraction(params),
	});

	// =========================
	// * Update Interaction
	// =========================
	const { isLoading: isUpdateInteractionLoading, mutate: updateInteractionMutation } = useMutation({
		mutationKey: ['interactions/update-interaction'],
		mutationFn: ({ formValues, interactionId }: { formValues: ICreateInteractionFormValues; interactionId: number }) => updateInteraction({ formValues, interactionId }),
	});

	const {
		handleSubmit,
		setError,
		register,
		setValue,
		clearErrors,
		formState: { errors },
	} = useForm<ICreateInteractionFormValues>({
		defaultValues: {
			prospect_id: prospect ? prospect.id : interaction ? interaction?.prospect.id : undefined,
			sales_stage_id: prospect.sales_stage?.id,
			interaction_type_id: interaction?.interaction_type.id,
		},
	});

	const handleFilesDrop = (files: File[]) => {
		let fileList: File[] = selectFields.files;

		for (var i = 0; i < files.length; i++) {
			// Clear errors
			if (errors.files) {
				clearErrors();
			}

			// // Check file type
			// if (!ACCEPTED_IMAGE_TYPES.includes(files[i]?.type)) {
			// 	setError('images', {
			// 		message: 'Select file type is not supported.',
			// 	});z
			// 	continue;
			// }

			// Check file capacity
			if (files[i]?.size >= MAX_IMAGE_SIZE) {
				setError('files', {
					message: 'File size cannot be more than 20MB.',
				});
				continue;
			}

			// eslint-disable-next-line
			if (fileList.some((f) => f.name === files[i].name)) continue;
			fileList.push(files[i]);
		}

		const newFiles = _.uniqBy(fileList, 'name');
		setSelectFields({
			...selectFields,
			files: newFiles,
		});
		setValue('files', newFiles);
	};

	const onSubmit = (formValues: ICreateInteractionFormValues) => {
		const { prospect_id } = formValues;

		if (prospect_id === null || prospect_id === undefined) {
			return setError('prospect_id', { message: 'Prospect is required' });
		}

		if (interaction)
			return updateInteractionMutation(
				{ formValues, interactionId: interaction.id },
				{
					onSuccess: () => {
						toast.success('Interaction created successfully');
						afterEdit?.();
					},
					onError: (error) => {
						processReactHookFormErrors(error, 'Failed to created interaction', setError);
					},
				}
			);

		createInteractionMutation(formValues, {
			onSuccess: () => {
				toast.success('Comment updated successfully!');
				afterEdit?.();
			},
			onError: (error) => {
				processReactHookFormErrors(error, 'Failed to update interaction', setError);
			},
		});
	};

	return (
		<form className='bg-white' onSubmit={handleSubmit(onSubmit)}>
			<header className={`${interaction ? 'bg-gradient-to-r from-gray-800 to-gray-500' : 'bg-gradient-to-r from-primary-600 to-primary-300'} bg-dark p-6 h-[80px] flex items-center gap-2`}>
				<Title className='text-white'>{interaction ? 'Update' : 'Create'} Interaction</Title>
			</header>

			<section className='p-4 space-y-2 text-left'>
				<div className='w-full form-control'>
					<label htmlFor='interaction_type' className='required-field-indicator'>
						Interaction Type
					</label>

					<div>
						<Select
							id='interaction_type'
							placeholder='Select interaction type'
							disabled={isInteractionTypesLoading}
							value={selectFields?.interaction_type_id?.toString()}
							onValueChange={(val) => {
								setValue('interaction_type_id', Number(val));

								setSelectFields({
									...selectFields,
									interaction_type_id: Number(val),
								} as any);

								if (errors.interaction_type_id) clearErrors('interaction_type_id');
							}}
						>
							{interactionTypes && interactionTypes.length > 0 ? (
								interactionTypes?.map((interactionType, index) => (
									<SelectItem key={index} value={interactionType.id.toString()}>
										{interactionType.name}
									</SelectItem>
								))
							) : (
								<div className='px-2 py-2'>No interaction type</div>
							)}
						</Select>
						{errors?.interaction_type_id && <span className='form-message'>{errors?.interaction_type_id?.message}</span>}
					</div>
				</div>

				<div className='w-full form-control'>
					<label htmlFor='sales_stage_id' className='required-field-indicator'>
						Sale Stage
					</label>

					<div>
						<Select
							id='sales_stage_id'
							disabled={isSalesStagesLoading}
							value={selectFields?.sales_stage_id?.toString()}
							defaultChecked={true}
							defaultValue={activeProspect?.sales_stage.id.toString() || ''}
							onValueChange={(val) => {
								setValue('sales_stage_id', Number(val));
								setSelectFields({
									...selectFields,
									sales_stage_id: Number(val),
								} as any);
								if (errors.sales_stage_id) clearErrors('sales_stage_id');
							}}
							placeholder='Select a sale stage this interaction happened'
						>
							{salesStages && salesStages.length > 0 ? (
								salesStages?.map((stage, index) => (
									<SelectItem key={index} value={stage.id.toString()}>
										{stage.name}
									</SelectItem>
								))
							) : (
								<div className='px-2 py-2'>No sale stage</div>
							)}
						</Select>
						{errors?.sales_stage_id && <span className='form-message'>{errors?.sales_stage_id?.message}</span>}
					</div>
				</div>

				{!pathname.includes('/interactions') && (
					<div className='w-full form-control'>
						<label htmlFor='prospect_id' className='required-field-indicator'>
							Prospect
						</label>

						<div>
							<Select
								id='prospect_id'
								icon={PiBuildingsFill}
								disabled={isProspectsLoading}
								value={selectFields?.prospect_id?.toString()}
								onValueChange={(val) => {
									setValue('prospect_id', Number(val));
									setSelectFields({
										...selectFields,
										prospect_id: Number(val),
									} as any);
									if (errors.prospect_id) clearErrors('prospect_id');
								}}
							>
								{prospects && prospects.length > 0 ? (
									prospects?.map((prospect, index) => (
										<SelectItem key={index} value={prospect.id.toString()}>
											{prospect.company_name}
										</SelectItem>
									))
								) : (
									<div className='px-2 py-2'>No prospect</div>
								)}
							</Select>

							{errors?.prospect_id && <span className='form-message'>{errors?.prospect_id?.message}</span>}
						</div>
					</div>
				)}

				<div className='form-control'>
					<label htmlFor='comment' className='required-field-indicator'>
						Comment
					</label>

					<div>
						<textarea
							id='comment'
							{...register('comment', {
								required: {
									value: true,
									message: 'Product comment is required',
								},
							})}
							placeholder={`Enter the details of the interaction you had with your contact at ${prospect.company_name}`}
							className='min-h-[120px] lg:min-h-[180px] placeholder:max-w-sm'
						/>
						{errors?.comment && <span className='mr-1 text-red-400 form-message'>{errors?.comment?.message}</span>}
					</div>
				</div>

				<div>
					<label htmlFor='attachments' className='text-sm font-semibold text-gray-600'>
						Attachments
					</label>

					<DragAndDrop handleDrop={handleFilesDrop} containerClassName='py-2 w-full h-full drag-and-drop'>
						<div className='relative z-20 w-full h-full drag-and-drop__wrapper'>
							<label htmlFor='file-upload' className={`relative z-10 p-2 flex flex-col items-center justify-center cursor-pointer w-full h-full space-y-0.5 text-center`}>
								<input id='file-upload' type='file' multiple onChange={(e: any) => handleFilesDrop(e.target.files)} className={`sr-only absolute w-full h-full opacity-0`} />

								<span className='w-[35px] h-[35px] rounded-full mx-auto inline-flex items-center justify-center text-white bg-gray-600' aria-hidden='true'>
									<RiDragDropFill className='w-5 h-5' />
								</span>

								<span className='py-2 text-xs text-center text-gray-700 uppercase'>
									Drop Files Here <br /> <div className='text-xs lowercase'>or</div>
								</span>

								{/* Button */}
								<div className='px-4 text-sm text-gray-700 capitalize !py-1.5 ring-[1px] ring-gray-200 bg-white hover:bg-gray-50 transition duration-300 rounded-lg'>Browse Files</div>
							</label>
						</div>
					</DragAndDrop>

					{errors.files && <p className='text-sm text-red-500'>{errors.files.message}</p>}

					<Fragment>
						{selectFields?.files && selectFields?.files?.length > 0 && (
							<div className='flex flex-wrap items-start gap-1 mt-2'>
								{selectFields.files.map((selectFile, key) => (
									<div className='flex items-center overflow-hidden h-[30px] rounded-full border border-gray-900 bg-gray-800 pl-3 text-gray-100 divide-x flex-wrap' key={key}>
										<span className='inline-block max-w-[140px] pr-1 line-clamp-1 text-xs whitespace-nowrap text-ellipsis'>{selectFile.name}</span>

										<button
											onClick={() => {
												const remainingFiles = selectFields.files.filter((file) => {
													return selectFile.name !== file.name;
												});

												setSelectFields((prevValues) => {
													return {
														...prevValues,
														files: remainingFiles,
													};
												});
											}}
											type='button'
											title='Delete File'
											className='bg-black duration-200 hover:bg-red-600 h-full z-20 pr-1 pl-0.5'
										>
											<CloseIcon className='w-4 h-4' />
										</button>
									</div>
								))}
							</div>
						)}
					</Fragment>
				</div>
			</section>

			<footer className='flex items-center justify-end gap-2 p-6 bg-gray-100 border-t'>
				<Button
					onClick={() => {
						setEditInteractionModalOpen(false);
					}}
					type='button'
					color='gray'
					variant='secondary'
					className='px-6 py-3 duration-300 !rounded-full'
				>
					Cancel
				</Button>

				<Button
					type='submit'
					loading={isCreateInteractionLoading || isUpdateInteractionLoading}
					loadingText={interaction ? 'Updating' : 'Saving'}
					disabled={isCreateInteractionLoading || isUpdateInteractionLoading}
					color={interaction ? 'gray' : undefined}
					className='px-6 py-3 duration-300 !rounded-full'
				>
					{interaction ? 'Update' : 'Save Interaction'}
				</Button>
			</footer>
		</form>
	);
};

export default EditInteractionForm;
