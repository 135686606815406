import { Badge, Text } from '@tremor/react';
import { UserIcon } from 'components/FormActionIcons';
import { authState } from 'features/auth/authSlice';
import { formatDate } from 'helpers/dateHelpers';
import { useAppSelector } from 'hooks/storeConnect';
import { LiaDownloadSolid } from 'react-icons/lia';
import { IInteraction } from './interactionInterfaces';
import { Tooltip } from 'react-tooltip';
import { HOST_URL } from 'config';
import { downloadFileFromURL } from 'helpers/index';
import ReadMoreParagraph from 'components/ReadMoreParagraph';

interface Props {
	interaction: IInteraction;
}

const Interaction = ({ interaction }: Props) => {
	const { authUser } = useAppSelector(authState);

	const { comment, created_at, sales_stage, user, files, interaction_type } = interaction;

	return (
		<div className='flex items-start gap-2 even:w-full odd:w-11/12'>
			{/* Icon */}
			<div className='flex items-center justify-center w-8 h-8 bg-gray-100 border rounded-full shrink-0 lg:w-10 lg:h-10'>
				<UserIcon variant='solid' className='text-gray-500 lg:h-6 lg:w-6' />
			</div>

			{/* Message Body */}
			<div className='flex-1 border rounded-lg bg-gray-50 lg:p-2'>
				{/* Top */}
				<header className='flex items-center justify-between gap-2 px-3 pt-3 mb-2'>
					<div className='flex items-center'>
						<Text className='font-bold'>{user?.id === authUser?.id ? 'You' : `${user.first_name} ${user.last_name}`}</Text>
						{interaction_type && <Text className='text-xs pl-2 py-1 ml-2 border-l hidden lg:inline font-semibold !text-black'>{interaction_type.name}</Text>}
					</div>

					<div>
						<Badge className='text-white capitalize bg-primary-900'>{sales_stage.name}</Badge>
					</div>
				</header>

				{/* Center */}
				<div className='px-3 pt-2 pb-3'>
					<ReadMoreParagraph text={comment} maxLength={400} />
				</div>

				<div className='flex items-center justify-between gap-4 px-2 pt-1 pb-2 border-t lg:hidden'>
					{interaction_type && <Text className='text-xs font-semibold !text-black'>{interaction_type.name}</Text>}
					{created_at && <Text className='ml-auto text-sm'>{formatDate(new Date(created_at), '01/31/1999, 12:00 AM')}</Text>}
				</div>

				<footer className='flex items-center justify-between gap-2 px-2 py-2 border-t '>
					<div>
						{/* Attachments Footer */}
						{files && files.length > 0 && (
							<div className='flex flex-wrap gap-2'>
								{files.map((file, index) => (
									<button
										key={index}
										type='button'
										title='Download Attachment'
										className='hover:bg-green-200 hover:text-black duration-300 px-2 py-0.5 flex divide-x rounded-full items-center border bg-white text-sm'
										data-tooltip-id='comment-attachment-download'
										data-tooltip-content={'Download Attachment'}
										data-tooltip-place='top'
										onClick={() => {
											const fileUrl = `${HOST_URL}/${file.file_path}`;

											downloadFileFromURL(fileUrl, file.file_name + file.file_ext);
										}}
									>
										<span className='text-xs font-medium'>
											{file.file_name}.{file.file_ext}
										</span>{' '}
										<span className='inline-flex items-center justify-center p-2 ml-2 border-l'>
											<LiaDownloadSolid className='w-4 h-4' />
										</span>
									</button>
								))}

								<Tooltip id='comment-attachment-download' />
							</div>
						)}
					</div>

					{created_at && <Text className='hidden ml-auto text-sm lg:block'>{formatDate(new Date(created_at), '01/31/1999, 12:00 AM')}</Text>}
				</footer>
			</div>
		</div>
	);
};

export default Interaction;
