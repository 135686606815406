import Sheet from 'react-modal-sheet';
import { ReactNode, forwardRef } from 'react';
import { CloseIcon } from './FormActionIcons';
import { Text, Title } from '@tremor/react';
import OutsideClickHandler from './OutsideClickHandler';

interface Props {
	isOpen: boolean;
	setOpen: (value: boolean) => void;
	header?: {
		text: string;
		style?: string;
		subText?: string;
		subTextStyle?: string;
	};
	children: ReactNode;
	closeOnOutSideClick?: boolean;
}

const BottomSheet = forwardRef((props: Props, ref) => {
	const { isOpen, setOpen, closeOnOutSideClick, children, header } = props;

	return (
		<Sheet ref={ref} isOpen={isOpen} onClose={() => setOpen(false)} detent='content-height'>
			<OutsideClickHandler onOutsideClick={() => (closeOnOutSideClick ? setOpen(false) : null)}>
				<Sheet.Container>
					{header && (
						<Sheet.Header className={`py-2 px-2 text-lg h-14 flex justify-center flex-col text-gray-800 border-b relative`}>
							<Title className={header.style}>{header.text}</Title>

							{header.subText && <Text className={`${header.subTextStyle}`}>{header.subText}</Text>}

							<button
								title='Close'
								onClick={() => setOpen(false)}
								className={`flex items-center justify-center rounded-lg border bg-gray-100 p-1 text-lg text-ash duration-200 hover:bg-red-500 hover:text-white absolute top-1/2 -translate-y-1/2 right-3`}
							>
								<CloseIcon />
							</button>
						</Sheet.Header>
					)}

					<Sheet.Content>
						<Sheet.Scroller>
							<div className='relative h-full w-full'>{children}</div>
						</Sheet.Scroller>
					</Sheet.Content>
				</Sheet.Container>
			</OutsideClickHandler>

			<Sheet.Backdrop />
		</Sheet>
	);
});

export default BottomSheet;
