import { Button, DateRangePicker, Select, SelectItem, Title } from '@tremor/react';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import { Fragment, ReactNode } from 'react';
import { IFetchInteractionsActionParams, IInteraction } from './interactionInterfaces';
import { IInteractionType, ISalesStage } from 'features/settings/prospecting/prospectSettingsInterfaces';
import { formatDateRangePickerValue } from 'helpers/dateHelpers';
import { useAppSelector } from 'hooks/storeConnect';
import { prospectsState } from 'features/prospects/prospectsSlice';

interface Props {
	interactions: IInteraction[] | undefined;
	isInteractionsLoading: boolean;
	renderInteractions: (interactions: IInteraction[]) => ReactNode;
	filters: any;
	setFilters: any;
	interactionTypes: IInteractionType[] | undefined;
	isInteractionTypesLoading: boolean;
	salesStages: ISalesStage[] | undefined;
	isSalesStagesLoading: boolean;
	fetchParams: IFetchInteractionsActionParams;
	setFetchParams: (val: IFetchInteractionsActionParams) => void;
}

const InteractionsPane = (props: Props) => {
	const { interactions, isInteractionsLoading, renderInteractions, filters, setFilters, interactionTypes, isInteractionTypesLoading, isSalesStagesLoading, salesStages, fetchParams, setFetchParams } =
		props;

	const { activeProspect } = useAppSelector(prospectsState);

	return (
		<Fragment>
			{/* Mobile Interactions : SM */}
			<section className='space-y-4 lg:hidden'>
				<Fragment>
					{!interactions || isInteractionsLoading ? (
						<div className='mt-4'>
							<ContentLoadingCardSpinner message='Loading interactions...' />
						</div>
					) : interactions && interactions.length > 0 ? (
						<Fragment>{renderInteractions(interactions)}</Fragment>
					) : (
						<SimpleEmptyResourceIndicator containerClassName='lg:!mt-4' message='No interaction found' />
					)}
				</Fragment>
			</section>

			{/* Desktop Interactions : LG */}
			<section className='hidden lg:flex overflow-hidden  lg:h-[calc(100vh-var(--appbar-height-desktop)-var(--app-navigation-height)-var(--prospect-detail-page-height))] border border-gray-300 divide-x divide-gray-300 rounded-xl'>
				{/* LG:Left: Interaction Filters */}
				<div className='h-full lg:w-[400px] xl:w-[500px]'>
					<header className='h-[45px] bg-gray-200 py-2 px-4 border-b border-gray-300'>
						<Title className='!text-[16px]'>Filter Interactions</Title>
					</header>

					<div className='w-full flex flex-col h-[calc(100%-45px)] overflow-auto with-scrollbar'>
						{/* Filters */}
						<div className='flex items-center w-full h-full gap-2 px-4 py-4 space-y-1 lg:flex-col lg:items-start bg-gray-50'>
							<div className='w-full'>
								<label htmlFor='interactions-date-filter' className='label'>
									Interaction Dates
								</label>

								<DateRangePicker
									id='interactions-date-filter'
									className='!min-w-full'
									enableYearNavigation
									value={filters.createdBetween}
									enableClear={false}
									onValueChange={(val) =>
										setFilters({
											...filters,
											createdBetween: val,
										})
									}
								/>
							</div>

							<div className='w-full'>
								<label htmlFor='sale-stage-filter' className='label'>
									Sale Stage
								</label>

								<Select
									onChange={(val) => setFilters({ ...filters, saleStageId: val ? Number(val) : undefined })}
									value={filters.saleStageId?.toString()}
									disabled={isSalesStagesLoading}
									placeholder='Filter by sale stage'
									enableClear={false}
								>
									{salesStages && salesStages.length > 0 ? (
										salesStages?.map((stage, index) => (
											<SelectItem key={index} value={stage.id.toString()}>
												{stage.name}
											</SelectItem>
										))
									) : (
										<div className='px-2 py-2'>No sale stage</div>
									)}
								</Select>
							</div>

							<div className='w-full'>
								<label htmlFor='interaction_type_id' className='label'>
									Interaction Type
								</label>

								<Select
									placeholder='Select by interaction type'
									onChange={(val) => setFilters({ ...filters, interactionTypeId: val ? Number(val) : undefined })}
									value={filters.interactionTypeId?.toString()}
									disabled={isInteractionTypesLoading}
									enableClear={false}
								>
									{interactionTypes && interactionTypes.length > 0 ? (
										interactionTypes?.map((type, index) => (
											<SelectItem key={index} value={type.id.toString()}>
												{type.name}
											</SelectItem>
										))
									) : (
										<div className='px-2 py-2'>No interaction type</div>
									)}
								</Select>
							</div>

							<div className='flex items-center gap-2 pt-2'>
								<Button
									type='button'
									color='gray'
									onClick={() => {
										const newFilters = {
											prospectId: activeProspect?.id,
											createdBetween: null,
											interactionTypeId: '',
											saleStageId: '',
										};
										setFilters(newFilters);
										setFetchParams({ ...fetchParams, filterBy: newFilters as any });
									}}
								>
									Clear Filters
								</Button>

								<Button
									type='button'
									color='green'
									className='flex-1'
									onClick={() => {
										setFetchParams({
											...fetchParams,
											filterBy: {
												prospectId: activeProspect?.id,
												createdBetween: filters.createdBetween?.to ? formatDateRangePickerValue(filters.createdBetween) : '',
												interactionTypeId: filters.interactionTypeId,
												saleStageId: filters.saleStageId,
											},
										});
									}}
								>
									Apply Filters
								</Button>
							</div>
						</div>

						{/* Filtered Dates */}
						{/* <div className='flex-1'>
							<Subtitle className='w-full px-4 pb-2 pt-4 !text-black font-semibold rounded-t-xl'>Filtered Interaction Dates</Subtitle>

							{}
							<List className='border-b'>{renderInteractionDates(interactions)}</List>
						</div> */}
					</div>
				</div>

				{/* LG:Right: Interactions */}
				<div className='flex-1 h-full'>
					<header className='h-[45px] bg-gray-200 py-2 px-4 border-b border-gray-300'>
						<Title className='!text-[16px]'>Comments</Title>
					</header>

					<div className='py-6 pl-4 pr-6 space-y-6 w-full h-[calc(100%-45px)] overflow-y-auto with-scrollbar'>
						<Fragment>
							{!interactions || isInteractionsLoading ? (
								<div className='mt-4'>
									<ContentLoadingCardSpinner message='Loading interactions...' />
								</div>
							) : interactions && interactions.length > 0 ? (
								<Fragment>{renderInteractions(interactions)}</Fragment>
							) : (
								<SimpleEmptyResourceIndicator containerClassName='lg:!mt-4' message='No interaction found' />
							)}
						</Fragment>
					</div>
				</div>
			</section>
		</Fragment>
	);
};

export default InteractionsPane;
