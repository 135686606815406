import { Fragment } from 'react';
import { Card, Flex, ProgressCircle, Text, Title } from '@tremor/react';
import { formatCurrency, hasRoles, isAdmin } from 'helpers/index';
import { IMarketingManagerDashboardData } from './dashboardInterfaces';
import { DefaultRoles } from 'features/settings/authorization/authorizationInterface';
import { isMarketer } from 'utils/index';

interface Props {
	data: IMarketingManagerDashboardData | undefined;
	isDataLoading: boolean;
	selectedDateRange: string | undefined;
}

const YearlyProductionPercentageChart = ({ data, isDataLoading, selectedDateRange }: Props) => {
	const total_yearly_target_achieved = (isMarketer() ? data?.total_achieved_revenue : data?.total_achieved_yearly_target) || 0;

	const percentageAchieved = data && data?.total_yearly_target !== 0 ? ((total_yearly_target_achieved / data.total_yearly_target) * 100).toFixed(2) : 0;

	return (
		<Card className='!h-full'>
			<Title>
				Percentage of Yearly Target Achieved
				{selectedDateRange?.includes(',') ? `, ${new Date(selectedDateRange.split(',')[0]).getFullYear()}` : selectedDateRange ? `, ${new Date(selectedDateRange).getFullYear()}` : ''}
			</Title>

			{isDataLoading && true ? (
				<div className='flex flex-col items-center justify-center gap-4 p-10'>
					<div className='w-40 h-40 bg-gray-400 rounded-full animate animate-pulse' />
				</div>
			) : data ? (
				<Fragment>
					<Text className='font-medium text-gray-700'>
						<span className='text-primary-500'>{formatCurrency(total_yearly_target_achieved)}</span>/<span className='text-green-600'>{formatCurrency(data.total_yearly_target)}</span> (
						{Number(percentageAchieved)}%)
					</Text>
					<Flex className='gap-4 mt-6 mr-auto' flexDirection='col' justifyContent='center'>
						<ProgressCircle value={Number(percentageAchieved)} size='xl'>
							<span className='text-xs font-medium text-gray-700'>{Number(percentageAchieved)}%</span>
						</ProgressCircle>
					</Flex>
				</Fragment>
			) : (
				<div className='flex flex-col items-center justify-center gap-4 p-10'>
					<Text className='!text-base'>No target achieved.</Text>
				</div>
			)}
		</Card>
	);
};

export default YearlyProductionPercentageChart;
