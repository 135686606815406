import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { isAfter, parseISO } from 'date-fns';
import _ from 'lodash';
import { DefaultRoles } from 'features/settings/authorization/authorizationInterface';
import { IUser, UserDurationStatus } from 'features/auth/authInterfaces';
import { IPermissionKeys } from 'features/auth/permissions';
const XLSX = require('xlsx');

export const processReactHookFormErrors = (error: any, altMessage: string, setError?: any) => {
	if (error instanceof AxiosError && error.response?.data.message?.toLowerCase() === 'validation error' && setError) {
		const errors = Object.entries(error.response?.data.errors);

		errors.forEach((err: any) => {
			setError(err[0], { message: err[1][0] });
		});

		return;
	}

	toast.error(error.response.data.message || altMessage);
};

export const titleCase = (str: string) => {
	if (!str) return ' ';

	var splitStr = str.toLowerCase().split(' ');
	for (var i = 0; i < splitStr.length; i++) {
		// You do not need to check if i is larger than splitStr length, as your for does that for you
		// Assign it back to the array
		splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
	}
	// Directly return the joined string
	return splitStr.join(' ');
};

export const formatCurrency = (amount: number, symbol: string = 'GH₵'): string =>
	`${symbol}${Number(amount).toLocaleString('en', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	})}`;

export const findFarthestFutureDate = (dates: string[]): string | null => {
	if (dates.length === 0) return null;

	let farthestDate = parseISO(dates[0]);

	for (let i = 1; i < dates.length; i++) {
		const currentDate = parseISO(dates[i]);

		if (isAfter(currentDate, farthestDate)) {
			farthestDate = currentDate;
		}
	}

	return farthestDate.toISOString();
};

export const reverseText = (text: string) => text.split('-').reverse().join('-');

export const objectWithZeros = (obj: any) => _.mapValues(obj, (value) => (value === null ? 0 : value));

export const downloadFileFromURL = (fileUrl: string, fileName: string) => {
	const link = document.createElement('a');
	link.href = fileUrl;
	link.target = '_blank';
	link.download = fileName;
	link.style.display = 'none';
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

//Get Logged In User
const persistedAuthState = localStorage.getItem('persist:root') ? JSON?.parse(localStorage.getItem('persist:root') as string)?.auth : '';
const user: IUser = persistedAuthState ? JSON.parse(persistedAuthState)?.authUser : null;
export const hasRole = (checkRole: string, subject?: IUser) => {
	if (!user && !subject) return;
	if (isAdmin()) return true;

	const roles = subject ? subject.roles : user?.roles;
	if (!roles || roles.length === 0) return false;

	return roles.some((role) => role.toLowerCase() === checkRole.toLowerCase());
};

export const hasRoles = (checkRoles: string[], subject?: IUser) => {
	if (!user && !subject) return;
	if (isAdmin()) return true;

	const roles = subject ? subject.roles : user?.roles;
	if (!roles || roles.length === 0) return false;

	return roles.some((role) => checkRoles.some((checkRole) => role.toLowerCase() === checkRole.toLowerCase()));
};

export const isSuperAdmin = (subject?: IUser) => {
	if (!user && !subject) return;

	const roles = subject ? subject.roles : user?.roles;
	if (!roles || roles.length === 0) return false;

	return roles?.some((role) => role?.toLowerCase() === DefaultRoles.SuperAdmin.toLowerCase());
};

export const isAdmin = (subject?: IUser) => {
	if (!user && !subject) return;
	if (isSuperAdmin()) return true;

	const roles = subject ? subject.roles : user?.roles;
	if (!roles || roles.length === 0) return false;

	return roles?.some((role) => role?.toLowerCase() === DefaultRoles.Admin.toLowerCase());
};

export const isNewUser = (): boolean => {
	return user?.new_user === UserDurationStatus.New;
};

export const hasPermission = <T extends keyof IPermissionKeys>(permission: T) => {
	if (!user) return;
	if (isSuperAdmin()) return true;

	const userPermissions = user?.permissions;
	if (!userPermissions || userPermissions.length === 0) return false;

	return userPermissions?.includes(permission);
};

export const hasPermissions = (permissions: (keyof IPermissionKeys)[]) => {
	if (!user) return false;
	if (isSuperAdmin()) return true;

	const userPermissions = user?.permissions;
	if (!userPermissions || userPermissions.length === 0) return false;

	// Check if any of the user's permissions match the provided permissions
	return permissions.some((perm) => userPermissions.includes(perm));
};

export const convertJSONToExcel = (data: any[], filename: string) => {
	const workSheet = XLSX.utils.json_to_sheet(data);
	const workBook = XLSX.utils.book_new();

	XLSX.utils.book_append_sheet(workBook, workSheet, filename);
	// Generate buffer
	XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });

	// Binary string
	XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });

	XLSX.writeFile(workBook, `${filename}.xlsx`);
};
