import { Fragment, useState } from 'react';
import { Button, Callout, Divider, Metric, Text, Title } from '@tremor/react';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import useFetchSalesStages from '../useFetchSalesStages';
import { ISalesStage } from '../prospectSettingsInterfaces';
import _ from 'lodash';
import ConfirmationDialog from 'components/ConfirmationDialog';
import toast from 'react-hot-toast';
import { MODAL_LEAVE_SPEED } from 'config';
import { useMutation } from '@tanstack/react-query';
import { deleteSalesStage } from '../prospectSettingsQueuries';
import { hasPermission, titleCase } from 'helpers';
import Modal from 'components/Modal';
import EditSalesStageForm from './EditSalesStageForm';
import { ConfirmationDialogIcon, DeleteIcon, EditIcon } from 'components/FormActionIcons';
import { sortSaleStages } from 'utils/index';
import { PermissionKeys } from 'features/auth/permissions';
import ContentBlockedFromView from 'components/ContentBlockedFromView';
import { HiExclamation } from 'react-icons/hi';

const SalesStagesPage = () => {
	const [isEditSalesStageFormOpen, setEditSalesStageFormOpen] = useState(false);
	const [isConfirmDeleteSalesStageDialogOpen, setConfirmDeleteSalesStageDialogOpen] = useState(false);
	const [currentSalesStage, setCurrentSalesStage] = useState<ISalesStage | null>(null);

	const { isSalesStagesLoading, refetchSalesStages, salesStages } = useFetchSalesStages({
		enabled: hasPermission(PermissionKeys.VIEW_SALES_STAGE),
	});

	// ======================
	// * Delete Sales Stage
	// ======================
	const { isLoading: isDeleteSalesStageLoading, mutate: deleteSalesStageMutation } = useMutation({
		mutationKey: ['authorization/delete-salesStage'],
		mutationFn: (salesStageId: number) => deleteSalesStage(salesStageId),
	});

	const onConfirmDeleteSalesStage = () => {
		if (!currentSalesStage) return toast.error('No sales stage selected for this action');

		deleteSalesStageMutation(currentSalesStage.id, {
			onSuccess: () => {
				toast.success('Sales stage updated successfully!');
				setEditSalesStageFormOpen(false);
			},
			onError: () => {
				toast.error('Failed to delete sales stage');
			},
			onSettled() {
				setTimeout(() => setCurrentSalesStage(null), MODAL_LEAVE_SPEED);
				refetchSalesStages();
			},
		});

		setConfirmDeleteSalesStageDialogOpen(false);
	};

	const renderActionButtons = (salesStage: ISalesStage) => (
		<div className='flex items-center gap-6'>
			{hasPermission(PermissionKeys.UPDATE_SALES_STAGE) && (
				<button
					onClick={() => {
						setCurrentSalesStage(salesStage);
						setEditSalesStageFormOpen(true);
					}}
					type='button'
					title='Edit Stage'
					className='flex items-center justify-center gap-2 group'
				>
					<div className='flex items-center justify-center text-yellow-600 duration-500 border border-yellow-100 lg:h-10 lg:w-10 lg:bg-yellow-50 lg:rounded-full lg:group-hover:text-white lg:group-hover:bg-gray-700'>
						<EditIcon />
					</div>

					<Text className='lg:font-medium'>Edit</Text>
				</button>
			)}

			{hasPermission(PermissionKeys.DELETE_SALES_STAGE) && (
				<button
					onClick={() => {
						setCurrentSalesStage(salesStage);
						setConfirmDeleteSalesStageDialogOpen(true);
					}}
					type='button'
					title='Delete Stage'
					className='flex items-center justify-center gap-2 group '
				>
					<div className='flex items-center justify-center text-red-600 duration-500 border border-red-100 lg:h-10 lg:w-10 lg:bg-red-50 lg:rounded-full lg:group-hover:text-white lg:group-hover:bg-gray-700'>
						<DeleteIcon />
					</div>
					<Text className='lg:font-medium'>Delete</Text>
				</button>
			)}

			{!(hasPermission(PermissionKeys.UPDATE_SALES_STAGE) && hasPermission(PermissionKeys.DELETE_SALES_STAGE)) && (
				<Callout title='Not authorized to perform action' icon={HiExclamation} color='yellow' />
			)}
		</div>
	);

	const renderSalesStages = (salesStages: ISalesStage[]) =>
		sortSaleStages(salesStages).map((salesStage, index) => (
			<div key={index} className=''>
				<Divider />

				<div className='flex items-start gap-2 lg:gap-4 sm:items-center'>
					{/* Icon */}
					<div className='px-4 py-3 mt-2 bg-gray-100 border rounded-xl lg:flex lg:items-center lg:gap-2'>
						<div className='flex items-center justify-center text-lg font-semibold text-white border rounded-full bg-primary-500 w-14 h-14'>{salesStage.probability}%</div>
						<Text className='!text-base hidden lg:block lg:text-gray-600'>Stage {index + 1}</Text>
					</div>

					<div>
						<Title>{salesStage.name}</Title>
						<Text>{salesStage.probability}% chance the deal will be won.</Text>
						<div className='mt-4 sm:hidden'>{renderActionButtons(salesStage)}</div>
					</div>

					<div className='hidden ml-auto sm:block'>{renderActionButtons(salesStage)}</div>
				</div>
			</div>
		));

	return (
		<Fragment>
			<div className='px-2 py-6 sm:px-4'>
				<div className='flex items-center justify-between'>
					<div className='flex items-center gap-3'>
						<Metric className='hidden sm:block'>Prospecting</Metric>

						{/* Separator */}
						<span className='hidden sm:inline-block w-[1px] h-[40px] bg-black rotate-12' />

						<Title className='lg:mt-1'>Sales Stages</Title>
					</div>

					{hasPermission(PermissionKeys.CREATE_SALES_STAGE) && (
						<Button className='!rounded-full' onClick={() => setEditSalesStageFormOpen(true)}>
							Create Sales Stage
						</Button>
					)}
				</div>

				<div>
					{hasPermission(PermissionKeys.VIEW_SALES_STAGE) ? (
						<Fragment>
							{!salesStages && isSalesStagesLoading ? (
								<div className='mt-6'>
									<ContentLoadingCardSpinner message='Loading sales stages...' />
								</div>
							) : salesStages && salesStages.length > 0 ? (
								<Fragment>{renderSalesStages(salesStages)}</Fragment>
							) : (
								<SimpleEmptyResourceIndicator containerClassName='lg:mt-8' message='No sales stage found' />
							)}
						</Fragment>
					) : (
						<div className='mt-6'>
							<ContentBlockedFromView message='You are not authorized to view sales stages.' containerClassName='min-h-[350px]' />
						</div>
					)}
				</div>
			</div>

			<Modal
				width={600}
				closeButton={{ styles: 'bg-gray-200' }}
				isModalOpen={isEditSalesStageFormOpen}
				setModalOpen={setEditSalesStageFormOpen}
				onCloseModal={() => {
					setEditSalesStageFormOpen(false);
					setTimeout(() => setCurrentSalesStage(null), 500);
				}}
				shouldCloseOnOutsideClick={false}
			>
				<EditSalesStageForm salesStage={currentSalesStage} setCurrentSalesStage={setCurrentSalesStage} setEditSalesStageFormOpen={setEditSalesStageFormOpen} refetchSalesStages={refetchSalesStages} />
			</Modal>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmDeleteSalesStageDialogOpen}
				setOpen={setConfirmDeleteSalesStageDialogOpen}
				message='Delete Sales Stage'
				subMessage={`Are you sure you want to delete ${titleCase(currentSalesStage?.name as string) + ' sales stage' ?? 'this  sales stage'}? This action cannot be reversed.`}
				onClose={() => {
					setTimeout(() => setCurrentSalesStage(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmDeleteSalesStage,
					label: 'Delete',
					isLoading: isDeleteSalesStageLoading,
					loadingText: 'Deleting',
					color: 'red',
				}}
				cancelButton={{
					action: () => setConfirmDeleteSalesStageDialogOpen(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-500/10`}>
						<ConfirmationDialogIcon className='w-6 h-6 text-red-500' aria-hidden='true' />
					</div>
				)}
			/>
		</Fragment>
	);
};

export default SalesStagesPage;
