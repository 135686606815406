import { Button, TextInput, Title } from '@tremor/react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { processReactHookFormErrors } from 'helpers';
import { IInteractionType, IInteractionTypeFormValues } from '../prospectSettingsInterfaces';
import { createInteractionType, updateInteractionType } from '../prospectSettingsQueuries';

interface Props {
	interactionType: IInteractionType | null;
	refetchInteractionTypes: () => void;
	setCurrentInteractionType: (value: IInteractionType | null) => void;
	setEditInteractionTypeFormOpen: (value: boolean) => void;
}

const EditInteractionTypeForm = (props: Props) => {
	const { interactionType, setCurrentInteractionType, setEditInteractionTypeFormOpen, refetchInteractionTypes } = props;

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<IInteractionTypeFormValues>({
		defaultValues: {
			name: interactionType?.name,
			description: interactionType?.description || '',
		},
	});

	// ==============================
	// * Create Interaction Type
	// ==============================
	const { isLoading: isCreateInteractionTypeLoading, mutate: createInteractionTypeMutation } = useMutation({
		mutationKey: ['authorization/create-InteractionType'],
		mutationFn: (params: IInteractionTypeFormValues) => createInteractionType(params),
	});

	// ==============================
	// * Update Interaction Type
	// ==============================
	const { isLoading: isUpdateInteractionTypeLoading, mutate: updateInteractionTypeMutation } = useMutation({
		mutationKey: ['authorization/update-InteractionType'],
		mutationFn: ({ formValues, InteractionTypeId }: { formValues: IInteractionTypeFormValues; InteractionTypeId: number }) => updateInteractionType({ formValues, InteractionTypeId }),
	});

	const onSubmit = (formValues: IInteractionTypeFormValues) => {
		if (interactionType)
			return updateInteractionTypeMutation(
				{ formValues, InteractionTypeId: interactionType.id },
				{
					onSuccess: () => {
						toast.success('Interaction Type updated successfully!');
						setTimeout(() => setCurrentInteractionType(null), 500);
						setEditInteractionTypeFormOpen(false);
						refetchInteractionTypes();
					},
					onError: (error) => {
						processReactHookFormErrors(error, 'Failed to update Interaction Type', setError);
					},
				}
			);

		createInteractionTypeMutation(formValues, {
			onSuccess: () => {
				toast.success('Interaction Type created successfully!');
				setEditInteractionTypeFormOpen(false);
				refetchInteractionTypes();
			},
			onError: (error) => {
				processReactHookFormErrors(error, 'Failed to create Interaction Type', setError);
			},
		});
	};

	return (
		<form className='bg-white' onSubmit={handleSubmit(onSubmit)}>
			<header className={`${interactionType ? 'bg-gradient-to-r from-gray-800 to-gray-500' : 'bg-gradient-to-r from-primary-600 to-primary-300'} bg-dark p-6 h-[80px] flex items-center gap-2`}>
				<Title className='text-white'>{interactionType ? 'Update' : 'Create'} Interaction Type</Title>
			</header>

			<section className='p-6 text-left space-y-6'>
				<div className='form-control'>
					<label htmlFor='name' className='required-field-indicator'>
						Name
					</label>

					<div>
						<TextInput
							type='text'
							id='name'
							{...register('name', {
								required: {
									value: true,
									message: 'Name of Interaction Type is required',
								},
							})}
							placeholder='Enter Interaction Type name'
							error={errors.name ? true : false}
							errorMessage={errors.name?.message}
						/>
					</div>
				</div>

				<div className='form-control'>
					<label htmlFor='description'>Description</label>

					<div>
						<textarea id='description' {...register('description')} placeholder='Describe Interaction Type' />
						{errors?.description && <span className='form-message mr-1 text-red-400'>{errors?.description?.message}</span>}
					</div>
				</div>
			</section>

			<footer className='p-6 flex items-center gap-2 justify-end bg-gray-100'>
				<Button
					onClick={() => {
						setEditInteractionTypeFormOpen(false);
						setTimeout(() => setCurrentInteractionType(null), 500);
					}}
					type='button'
					color='gray'
					variant='secondary'
					className='px-6 py-3 duration-300 !rounded-full'
				>
					Cancel
				</Button>

				<Button
					type='submit'
					loading={isCreateInteractionTypeLoading || isUpdateInteractionTypeLoading}
					loadingText={interactionType ? 'Updating' : 'Creating'}
					disabled={isCreateInteractionTypeLoading || isUpdateInteractionTypeLoading}
					color={interactionType ? 'gray' : undefined}
					className='px-6 py-3 duration-300 !rounded-full'
				>
					{interactionType ? 'Update Interaction Type' : 'Create Interaction Type'}
				</Button>
			</footer>
		</form>
	);
};

export default EditInteractionTypeForm;
