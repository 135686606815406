import { lsmsAPI } from 'ajax/httpClient';
import {
	IDecisionMakerFormValues,
	IInsuranceProductFormValues,
	IInteractionTypeFormValues,
	IProspectBusinessTypeFormValues,
	ISalesStageFormValues,
	ISourceTypeFormValues,
} from './prospectSettingsInterfaces';

// =================
//  Sales Stages
// =================
export const fetchSalesStages = () => lsmsAPI.get('/sales-stages');

export const createSalesStage = (formValues: ISalesStageFormValues) => lsmsAPI.post('/sales-stages', formValues);

export const updateSalesStage = ({ formValues, salesStageId }: { formValues: ISalesStageFormValues; salesStageId: number }) => lsmsAPI.put(`/sales-stages/${salesStageId}`, formValues);

export const deleteSalesStage = (salesStageId: number) => lsmsAPI.delete(`/sales-stages/${salesStageId}`);

// ==========================
//  Insurance Product Types
// ==========================
export const fetchInsuranceProducts = () => lsmsAPI.get('/insurance-product-types');

export const createInsuranceProduct = (formValues: IInsuranceProductFormValues) => lsmsAPI.post('/insurance-product-types', formValues);

export const updateInsuranceProduct = ({ formValues, insuranceProductId }: { formValues: IInsuranceProductFormValues; insuranceProductId: number }) =>
	lsmsAPI.put(`/insurance-product-types/${insuranceProductId}`, formValues);

export const deleteInsuranceProduct = (insuranceProductId: number) => lsmsAPI.delete(`/insurance-product-types/${insuranceProductId}`);

// ==========================
//  Decision Makers
// ==========================
export const fetchDecisionMakers = () => lsmsAPI.get('/decision-maker-types');

export const createDecisionMaker = (formValues: IDecisionMakerFormValues) => lsmsAPI.post('/decision-maker-types', formValues);

export const updateDecisionMaker = ({ formValues, decisionMakerId }: { formValues: IDecisionMakerFormValues; decisionMakerId: number }) =>
	lsmsAPI.put(`/decision-maker-types/${decisionMakerId}`, formValues);

export const deleteDecisionMaker = (decisionMakerId: number) => lsmsAPI.delete(`/decision-maker-types/${decisionMakerId}`);

// ==========================
//   Source Types
// ==========================
export const fetchSourceTypes = () => lsmsAPI.get('/source-types');

export const createSourceType = (formValues: ISourceTypeFormValues) => lsmsAPI.post('/source-types', formValues);

export const updateSourceType = ({ formValues, sourceTypeId }: { formValues: ISourceTypeFormValues; sourceTypeId: number }) => lsmsAPI.put(`/source-types/${sourceTypeId}`, formValues);

export const deleteSourceType = (sourceTypeId: number) => lsmsAPI.delete(`/source-types/${sourceTypeId}`);

// ==========================
//   Interaction Media
// ==========================
export const fetchInteractionTypes = () => lsmsAPI.get('/interaction-types');

export const createInteractionType = (formValues: IInteractionTypeFormValues) => lsmsAPI.post('/interaction-types', formValues);

export const updateInteractionType = ({ formValues, InteractionTypeId }: { formValues: IInteractionTypeFormValues; InteractionTypeId: number }) =>
	lsmsAPI.put(`/interaction-types/${InteractionTypeId}`, formValues);

export const deleteInteractionType = (InteractionTypeId: number) => lsmsAPI.delete(`/interaction-types/${InteractionTypeId}`);

// ===========================
//   Prospect Business Types
// ===========================
export const fetchProspectBusinessTypes = () => lsmsAPI.get('/business-types');

export const createProspectBusinessType = (formValues: IProspectBusinessTypeFormValues) => lsmsAPI.post('/business-types', formValues);

export const updateProspectBusinessType = ({ formValues, businessTypeId }: { formValues: IProspectBusinessTypeFormValues; businessTypeId: number }) =>
	lsmsAPI.put(`/business-types/${businessTypeId}`, formValues);

export const deleteProspectBusinessType = (businessTypeId: number) => lsmsAPI.delete(`/business-types/${businessTypeId}`);
