import { useState, useEffect } from 'react';
import { TextInput } from '@tremor/react';
import { FiLoader } from 'react-icons/fi';
import { SearchIcon } from './FormActionIcons';

interface Props {
	onSearch: (searchTerm: string) => void;
	isLoading: boolean;
	className?: string;
	placeholder?: string;
	isDisabled?: boolean;
	isFluid?: boolean;
	debounceTimer?: number;
	rest?: any;
}

const SearchInput = (props: Props) => {
	const { isLoading, placeholder, debounceTimer, onSearch, isDisabled, isFluid, className, ...rest } = props;

	const [term, setTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);

	useEffect(() => {
		document.addEventListener('clearSearchBox', onClearSearch);

		return () => {
			document.removeEventListener('clearSearchBox', onClearSearch);
		};
		// eslint-disable-next-line
	}, []);

	const onClearSearch = () => {
		setTerm('');
		setDebouncedTerm('');
	};

	useEffect(() => {
		// > Set timeout and return clean up function
		const timerId = setTimeout(() => {
			setDebouncedTerm(term);
		}, debounceTimer);
		// > Return clean up function to clear previous setTimeOut
		return () => {
			clearTimeout(timerId);
		};
		//eslint-disable-next-line
	}, [term]);

	useEffect(() => {
		// > Execute search if debouncedTerm finally goes through in first useEffect
		onSearch(debouncedTerm.trim());
		//eslint-disable-next-line
	}, [debouncedTerm]);

	return (
		<TextInput
			onChange={(e) => setTerm(e.target.value)}
			className={`${className} ${isFluid ? '!w-full' : 'max-w-[300px]'}`}
			disabled={isDisabled}
			icon={isLoading ? () => <FiLoader className='w-5 h-5 ml-3 animate-spin' /> : SearchIcon}
			placeholder={placeholder ?? 'Search...'}
			{...rest}
		/>
	);
};

SearchInput.defaultProps = {
	debounceTimer: 750,
};

export default SearchInput;
