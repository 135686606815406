import { motion } from 'framer-motion';
import { AppNavigationMenuItem, AppNavigationSubMenuItem } from 'features/settings/settingsInterfaces';
import { NavLink, useLocation } from 'react-router-dom';

const AppNavigation = ({ activeAppNavigationMenuOption }: { activeAppNavigationMenuOption: AppNavigationMenuItem }) => {
	const { name, url, menu } = activeAppNavigationMenuOption || {};
	const location = useLocation();

	const getMenuLinks = (): AppNavigationSubMenuItem[] | null => {
		return menu
			? ([
					{
						...activeAppNavigationMenuOption,
						name: name,
						url: url,
					},
					...menu,
			  ] as any)
			: null;
	};

	return (
		<div className='sticky top-0 z-20 h-full px-2 sm:px-4 overflow-hidden border-t shadow-sm border-t-gray-200 bg-white shadow-gray-400/50 lg:px-6'>
			<div className='flex items-end h-full gap-6'>
				{getMenuLinks()?.map((page, index) => {
					const { name, url, menu } = page || {};

					if (!url || menu) return '';

					const isPageMatch = location.pathname === url;

					return (
						<NavLink
							to={url as string}
							key={index}
							className={({ isActive }) =>
								`relative whitespace-nowrap py-2 sm:text-sm lg:text-base font-normal capitalize !outline-none duration-300 hover:text-primary-300 ${
									isPageMatch ? 'text-primary-600' : 'text-gray-800/90'
								}`
							}
						>
							{isPageMatch && (
								<motion.span
									layoutId='subnavigation-bubble'
									className={`${isPageMatch ? 'bg-primary-500' : 'bg-transparent'} absolute inset-x-0 bottom-0 z-10 inline-block h-[3px]`}
									style={{ borderRadius: 9999 }}
									transition={{ type: 'spring', bounce: 0.3, duration: 0.8 }}
								/>
							)}

							{name}
						</NavLink>
					);
				})}
			</div>
		</div>
	);
};

export default AppNavigation;
