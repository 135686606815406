import { lsmsAPI } from 'ajax/httpClient';
import { IFetchMarketerDashboardFilters, IFetchMarketingManagerDashboardFilters, IFetchTransportManagerDashboardFilters, IFetchTransportOfficerDashboardFilters } from './dashboardInterfaces';

// ======================
// Marketer Dashboard
// ======================
export const fetchMarketerDashboard = (filters: IFetchMarketerDashboardFilters) => {
	const { createdBetween, userId } = filters || {};

	return lsmsAPI.get('/dashboard/prospect-marketer', {
		params: {
			'filter[created_between]': createdBetween,
			'filter[user_id]': userId,
		},
	});
};

// ==============================
// Marketing Manager Dashboard
// ==============================
export const fetchMarketingManagerDashboard = (filters: IFetchMarketingManagerDashboardFilters) => {
	const { createdBetween, userId } = filters || {};

	return lsmsAPI.get('/dashboard/prospect-manager', {
		params: {
			'filter[created_between]': createdBetween,
			'filter[user_id]': userId,
		},
	});
};

// ==============================
// Transport Officer Dashboard
// ==============================
export const fetchTransportOfficerDashboard = (filters: IFetchTransportOfficerDashboardFilters) => {
	const { createdBetween, transportOfficerId } = filters || {};

	return lsmsAPI.get('/dashboard/navigation-officer', {
		params: {
			'filter[created_between]': createdBetween,
			'filter[transport_officer.id]': transportOfficerId,
			include: 'transport_officer,vehicle_request,vehicle_request.user',
		},
	});
};

// ================================
// Transport Manager Dashboard
// ================================
export const fetchTransportManagerDashboard = (filters: IFetchTransportManagerDashboardFilters) => {
	const { createdBetween, transportOfficerId } = filters || {};

	return lsmsAPI.get('/dashboard/navigation-manager', {
		params: {
			'filter[created_between]': createdBetween,
			'filter[transport_officer.id]': transportOfficerId,
			include: 'transport_officer,vehicle_request,vehicle_request.user',
		},
	});
};

// =============================
// Fetch Vehicle/Driver Stats
// =============================
export const fetchVehicleDriverStats = () => lsmsAPI.get('/dashboard/vehicle-driver-stat');
