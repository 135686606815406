import { useEffect } from 'react';
import { useState, useRef } from 'react';

const DragAndDrop = (props) => {
	const [dragging, setDragging] = useState(false);
	const dropRef = useRef();

	let dragCounter = 0;

	const handleDrag = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDragIn = (e) => {
		e.preventDefault();
		e.stopPropagation();
		dragCounter++;
		if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
			setDragging(true);
		}
	};

	const handleDragOut = (e) => {
		e.preventDefault();
		e.stopPropagation();
		dragCounter--;
		if (dragCounter === 0) {
			setDragging(false);
		}
	};

	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setDragging(false);
		if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
			props.handleDrop(e.dataTransfer.files);
			e.dataTransfer.clearData();
			dragCounter = 0;
		}
	};

	useEffect(() => {
		let div = dropRef.current;
		div.addEventListener('dragenter', handleDragIn);
		div.addEventListener('dragleave', handleDragOut);
		div.addEventListener('dragover', handleDrag);
		div.addEventListener('drop', handleDrop);

		return () => {
			let div = dropRef.current;
			if (div) {
				div.removeEventListener('dragenter', handleDragIn);
				div.removeEventListener('dragleave', handleDragOut);
				div.removeEventListener('dragover', handleDrag);
				div.removeEventListener('drop', handleDrop);
			}
		};
	}, []);

	return (
		<div
			className={`relative w-full h-full rounded-xl border-dashed border-2 bg-gray-100 border-gray-300 hover:border-gray-400 ${dragging ? '!border-primary-300 !bg-primary-50': ''} ${props.containerClassName}`}
			ref={dropRef}
		>
			{props.children}
		</div>
	);
};

export default DragAndDrop;