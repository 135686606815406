import { Button, Card, TextInput, Title } from '@tremor/react';
import { useForm } from 'react-hook-form';
import { IRole, IRoleFormValues } from './authorizationInterface';
import { MultiSelect, MultiSelectItem } from '@tremor/react';
import useFetchPermissions from './useFetchPermissions';
import { createRole, updateRole } from './authorizationQueries';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { processReactHookFormErrors } from 'helpers';
import { useState } from 'react';

interface Props {
	role: IRole | null;
	refetchRoles: () => void;
	setCurrentRole: (value: IRole | null) => void;
	setEditRoleFormOpen: (value: boolean) => void;
}

const EditRoleForm = (props: Props) => {
	const { role, setCurrentRole, setEditRoleFormOpen, refetchRoles } = props;
	const defaultPermissions: string[] | undefined = role?.permissions.map((permission) => permission.name);
	const [selectedPermissions, setSelectedPermissions] = useState<string[]>(defaultPermissions ?? []);
	const canUpdateRoleName = (role ? role?.id : 7) < 7;

	const {
		register,
		handleSubmit,
		setValue,
		setError,
		clearErrors,
		formState: { errors },
	} = useForm<IRoleFormValues>({
		defaultValues: {
			name: role?.name,
			permissions: defaultPermissions,
		},
	});
	const { mergedPermissions, isPermissionsLoading } = useFetchPermissions({});

	// ===================
	// * Create Role
	// ===================
	const { isLoading: isCreateRoleLoading, mutate: createRoleMutation } = useMutation({
		mutationKey: ['authorization/create-role'],
		mutationFn: (params: IRoleFormValues) => createRole(params),
	});

	// =================
	// * Update Role
	// =================
	const { isLoading: isUpdateRoleLoading, mutate: updateRoleMutation } = useMutation({
		mutationKey: ['authorization/update-role'],
		mutationFn: ({ formValues, roleId }: { formValues: IRoleFormValues; roleId: number }) => updateRole({ formValues: formValues, roleId }),
	});

	const onSubmit = (formValues: IRoleFormValues) => {
		console.log(formValues);

		if (role)
			return updateRoleMutation(
				{ formValues, roleId: role.id },
				{
					onSuccess: () => {
						toast.success('Role updated successfully!');
						setTimeout(() => setCurrentRole(null), 500);
						setEditRoleFormOpen(false);
						refetchRoles();
					},
					onError: (error) => {
						processReactHookFormErrors(error, 'Failed to update role', setError);
					},
				}
			);

		createRoleMutation(formValues, {
			onSuccess: () => {
				toast.success('Role created successfully!');
				setEditRoleFormOpen(false);
				refetchRoles();
			},
			onError: (error) => {
				processReactHookFormErrors(error, 'Failed to create role', setError);
			},
		});
	};

	console.log(role);

	return (
		<form className='bg-white' onSubmit={handleSubmit(onSubmit)}>
			<header className={`${role ? 'bg-gradient-to-r from-gray-800 to-gray-500' : 'bg-gradient-to-r from-primary-600 to-primary-300'} bg-dark p-6 h-[80px] flex items-center gap-2`}>
				<Title className='text-white'>{role ? 'Update' : 'Create'} Role</Title>
			</header>

			<section className='p-6 text-left'>
				<div>
					<label htmlFor='name' className='required-field-indicator'>
						Name
					</label>

					<div>
						<TextInput
							disabled={canUpdateRoleName}
							type='text'
							id='name'
							{...register('name', {
								required: {
									value: true,
									message: 'Role name is required',
								},
							})}
							placeholder='Enter role name'
							error={errors.name ? true : false}
							errorMessage={errors.name?.message}
						/>
					</div>
				</div>

				<div className='mt-4'>
					<label htmlFor='name'>Permissions</label>

					<div>
						<MultiSelect
							onChange={(val: any) => {
								setSelectedPermissions(val);
								setValue('permissions', val);
								if (errors.permissions) clearErrors('permissions');
							}}
							value={selectedPermissions}
							disabled={isPermissionsLoading}
							placeholder='Select Permissions'
						>
							{mergedPermissions.map((permission, index) => (
								<MultiSelectItem defaultChecked={defaultPermissions?.some((p) => p === permission.name)} key={index} value={permission.name} className='capitalize'>
									{permission.name}
								</MultiSelectItem>
							))}
						</MultiSelect>
						{errors.permissions && <p className='mr-1 text-sm font-light text-red-500 text-red'>{errors.permissions.message}</p>}
					</div>
				</div>

				<Card className='h-[200px] mt-6'></Card>
			</section>

			<footer className='flex items-center justify-end gap-2 p-6 bg-gray-100'>
				<Button
					onClick={() => {
						setEditRoleFormOpen(false);
						setTimeout(() => setCurrentRole(null), 500);
					}}
					type='button'
					color='gray'
					variant='secondary'
					className='px-6 py-3 duration-300 !rounded-full'
				>
					Cancel
				</Button>

				<Button
					type='submit'
					loading={isCreateRoleLoading || isUpdateRoleLoading}
					loadingText={role ? 'Updating Role' : 'Creating Role'}
					disabled={isCreateRoleLoading || isUpdateRoleLoading}
					color={role ? 'gray' : undefined}
					className='px-6 py-3 duration-300 !rounded-full'
				>
					{role ? 'Update Role' : 'Create Role'}
				</Button>
			</footer>
		</form>
	);
};

export default EditRoleForm;
