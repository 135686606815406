import { useQuery, RefetchOptions, RefetchQueryFilters, QueryObserverResult } from '@tanstack/react-query';
import { IFetchProspectsActionParams, IProspect } from 'features/prospects/prospectInterfaces';
import { fetchProspects } from 'features/prospects/prospectQueries';

interface ReturnType {
	prospects: IProspect[] | undefined;
	isProspectsLoading: boolean;
	refetchProspects: (options?: RefetchOptions & RefetchQueryFilters) => Promise<QueryObserverResult>;
	paginationMetadata: APIPaginationMetadata;
}

interface Props {
	queryKey?: any;
	fetchParams?: IFetchProspectsActionParams;
	enabled?: boolean;
	onSuccess?: (prospects: IProspect[]) => void;
}

const useFetchProspects = ({ fetchParams, queryKey, enabled, onSuccess }: Props): ReturnType => {
	const { data, isLoading, refetch } = useQuery({
		queryKey: [queryKey, 'prospects/fetch-prospects'],
		queryFn: () => fetchProspects(fetchParams ?? {}),
		enabled,
		onSuccess: onSuccess ? (data) => onSuccess?.(data?.data) : undefined,
	});

	const prospects = data?.data?.data;
	const pagination = data?.data?.meta;

	return { isProspectsLoading: isLoading, prospects, paginationMetadata: pagination, refetchProspects: refetch };
};

export default useFetchProspects;
