import { lsmsAPI } from 'ajax/httpClient';
import { ICreateDepartmentFormValues, IFetchDepartmentsActionParams } from './departmentInterfaces';

export const fetchDepartments = (params: IFetchDepartmentsActionParams) => {
	const { filterBy, page } = params || {};
	const { name } = filterBy || {};

	return lsmsAPI.get('/departments', {
		params: {
			'filter[name]': name,
			page: page ?? 1,
		},
	});
};

export const createDepartment = (formValues: ICreateDepartmentFormValues) => lsmsAPI.post('/departments', formValues);

export const updateDepartment = ({ formValues, departmentId }: { formValues: ICreateDepartmentFormValues; departmentId: number }) => lsmsAPI.put(`/departments/${departmentId}`, formValues);

export const deleteDepartment = (departmentId: number) => lsmsAPI.delete(`/departments/${departmentId}`);
