import { lsmsAPI } from 'ajax/httpClient';
import { ICreateVehicleAllocationFormValues, IUpdateTripLocationActionParams } from './vehicleAllocationInterfaces';
import { IFetchVehicleAllocationsActionParams } from '../navigateInterfaces';

export const fetchVehicleAllocations = ({ filterBy, includes, page }: IFetchVehicleAllocationsActionParams) => {
	const { departureDate, transportOfficerId, createdAt, userId } = filterBy || {};

	return lsmsAPI.get('/vehicle-allocations', {
		params: {
			'filter[departure_date]': departureDate,
			'filter[created_at]': createdAt,
			'filter[transport_officer.id]': transportOfficerId,
			'filter[vehicle_request.user.id]': userId,
			include: includes?.join(','),
			page,
		},
	});
};

export const createVehicleAllocation = (formValues: ICreateVehicleAllocationFormValues) => lsmsAPI.post('/vehicle-allocations', formValues);

export const updateVehicleAllocation = (formValues: ICreateVehicleAllocationFormValues, vehicleAllocationId: number) => lsmsAPI.put(`/vehicle-allocations/${vehicleAllocationId}`, formValues);

export const updateTripLocation = (formValues: IUpdateTripLocationActionParams, vehicleAllocationId: number) => lsmsAPI.put(`/vehicle-allocations/${vehicleAllocationId}`, formValues);
