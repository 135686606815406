import { Button, TextInput, Title } from '@tremor/react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { processReactHookFormErrors } from 'helpers';
import { IVehicle } from '../navigateInterfaces';
import { ICreateVehicleFormValues } from './vehicleInterfaces';
import { createVehicle, updateVehicle } from './vehicleQueries';

interface Props {
	vehicle: IVehicle | null;
	afterEdit: () => void;
	onClose: () => void;
}

const EditVehicleForm = (props: Props) => {
	const { vehicle, afterEdit, onClose } = props;

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<ICreateVehicleFormValues>({
		defaultValues: {
			model: vehicle ? vehicle?.model : undefined,
			number: vehicle ? vehicle?.number : undefined,
			color: vehicle ? vehicle?.color : undefined,
		},
		mode: 'onChange',
	});

	// ============================
	// * Create Vehicle
	// ============================
	const { isLoading: isCreateVehicleLoading, mutate: createVehicleMutation } = useMutation({
		mutationKey: ['vehicles/create-vehicle'],
		mutationFn: (formValues: ICreateVehicleFormValues) => createVehicle(formValues),
	});

	// ============================
	// * Update Vehicle
	// ============================
	const { isLoading: isUpdateVehicleLoading, mutate: updateVehicleMutation } = useMutation({
		mutationKey: ['vehicles/update-vehicle'],
		mutationFn: ({ formValues, vehicleId }: { formValues: ICreateVehicleFormValues; vehicleId: number }) => updateVehicle(vehicleId, formValues),
	});

	const onSubmit = (formValues: ICreateVehicleFormValues) => {
		if (vehicle)
			return updateVehicleMutation(
				{ formValues, vehicleId: vehicle.id },
				{
					onSuccess: () => {
						toast.success('Vehicle updated successfully!');
						afterEdit();
					},
					onError: (error) => {
						processReactHookFormErrors(error, 'Failed to update vehicle', setError);
					},
				}
			);

		createVehicleMutation(formValues, {
			onSuccess: () => {
				toast.success('Vehicle created successfully!');
				afterEdit();
			},
			onError: (error) => {
				processReactHookFormErrors(error, 'Failed to create vehicle', setError);
			},
		});
	};

	return (
		<form className='bg-white' onSubmit={handleSubmit(onSubmit)}>
			<header className={`${vehicle ? 'bg-gradient-to-r from-gray-800 to-gray-500' : 'bg-gradient-to-r from-primary-600 to-primary-300'} bg-dark p-6 h-[80px] flex items-center gap-2`}>
				<Title className='text-white'>{vehicle ? 'Update' : 'Create'} Vehicle</Title>
			</header>

			<section className='p-6 text-left space-y-3'>
				<div className='form-control'>
					<label htmlFor='model' className='required-field-indicator'>
						Model
					</label>

					<div>
						<TextInput
							type='text'
							id='model'
							{...register('model', {
								required: 'Model of vehicle is required',
							})}
							placeholder='Enter vehicle model'
							error={errors.model ? true : false}
							errorMessage={errors.model?.message}
						/>
					</div>
				</div>

				<div className='form-control'>
					<label htmlFor='number' className='required-field-indicator'>
						Registration Number
					</label>

					<div>
						<TextInput
							type='text'
							id='number'
							{...register('number', {
								required: 'Car number is required',
							})}
							placeholder='Enter car number'
							error={errors.number ? true : false}
							errorMessage={errors.number?.message}
						/>
					</div>
				</div>

				<div className='form-control sm:col-span-2 lg:col-span-1'>
					<label htmlFor='color'>Color</label>

					<div>
						<TextInput type='text' id='color' {...register('color')} placeholder='Enter color' error={errors.color ? true : false} errorMessage={errors.color?.message} />
					</div>
				</div>
			</section>

			<footer className='p-6 flex items-center gap-2 justify-end bg-gray-100 border-t'>
				<Button onClick={() => onClose()} type='button' color='gray' variant='secondary' className='px-6 py-3 duration-300 !rounded-full'>
					Cancel
				</Button>

				<Button
					type='submit'
					loading={isCreateVehicleLoading || isUpdateVehicleLoading}
					loadingText={vehicle ? 'Updating' : 'Creating'}
					disabled={isCreateVehicleLoading || isUpdateVehicleLoading}
					color={vehicle ? 'gray' : undefined}
					className='px-6 py-3 duration-300 !rounded-full'
				>
					{vehicle ? 'Update Vehicle' : 'Create Vehicle'}
				</Button>
			</footer>
		</form>
	);
};

export default EditVehicleForm;
