import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Accordion, AccordionBody, AccordionHeader, AccordionList, Badge, Button, Divider, Title } from '@tremor/react';
import { Fragment } from 'react';
import { deleteRole } from './authorizationQueries';
import { IRole } from './authorizationInterface';
import { HiExclamation } from 'react-icons/hi';
import Modal from 'components/Modal';
import EditRoleForm from './EditRoleForm';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import toast from 'react-hot-toast';
import { hasPermission, titleCase } from 'helpers';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { MODAL_LEAVE_SPEED } from 'config';
import useFetchRoles from './useFetchUserRoles';
import { PermissionKeys } from 'features/auth/permissions';
import _ from 'lodash';

const RolesPage = () => {
	const [isEditRoleFormOpen, setEditRoleFormOpen] = useState(false);
	const [isConfirmDeleteRoleDialogOpen, setConfirmDeleteRoleDialogOpen] = useState(false);
	const [currentRole, setCurrentRole] = useState<IRole | null>(null);

	const { roles, isRolesLoading, refetchRoles } = useFetchRoles({});

	// =================
	// * Delete Role
	// =================
	const { isLoading: isDeleteRoleLoading, mutate: deleteRoleMutation } = useMutation({
		mutationKey: ['authorization/delete-role'],
		mutationFn: (roleId: number) => deleteRole(roleId),
	});

	const onConfirmDeleteRole = () => {
		if (!currentRole) return toast.error('No role selected for this action');

		deleteRoleMutation(currentRole.id, {
			onSuccess: () => {
				toast.success('Role updated successfully!');
				setEditRoleFormOpen(false);
				setTimeout(() => setCurrentRole(null), MODAL_LEAVE_SPEED);
			},
			onError: () => {
				toast.error('Failed to delete role');
			},
			onSettled() {
				refetchRoles();
			},
		});

		setConfirmDeleteRoleDialogOpen(false);
	};

	const renderActionButtons = (role: IRole): React.ReactNode => {
		const canDeleteRole = role.id > 6;

		return (
			<div className='flex flex-wrap gap-2 mt-2'>
				{hasPermission(PermissionKeys.DELETE_ROLE) && canDeleteRole && (
					<button
						onClick={() => {
							setCurrentRole(role);
							setConfirmDeleteRoleDialogOpen(true);
						}}
						title='Delete Role'
						className='pr-2 mr-2 text-sm text-red-600 border-r hover:underline'
					>
						Delete Role
					</button>
				)}
				{hasPermission(PermissionKeys.DELETE_ROLE) && (
					<button
						onClick={() => {
							setCurrentRole(role);
							setEditRoleFormOpen(true);
						}}
						title='Update Permissions'
						className='text-sm text-green-600 hover:underline'
					>
						Update Permissions
					</button>
				)}
			</div>
		);
	};

	const renderRolesOnDesktop = (roles: IRole[]) =>
		_.reverse(roles).map((role, index) => {
			return (
				<div key={index}>
					<div className='grid grid-cols-[300px,1fr]'>
						<div>
							<h3 className='text-gray-500 capitalize'>{role.name}</h3>

							{renderActionButtons(role)}
						</div>

						{role.name.toLowerCase() === 'super admin' ? (
							<div className='flex flex-wrap items-end gap-2'>
								<Badge size='lg' className='!max-h-7 bg-black capitalize text-white'>
									All permissions are granted
								</Badge>
							</div>
						) : role.permissions.length > 0 ? (
							<div className='flex flex-wrap gap-2'>
								{role.permissions.map((permission, index) => (
									<Badge key={index} size='lg' className='!max-h-7 bg-stone-600 capitali text-white'>
										{permission.name}
									</Badge>
								))}
							</div>
						) : (
							<SimpleEmptyResourceIndicator message={'No permission found'} />
						)}
					</div>

					<Divider />
				</div>
			);
		});

	const renderRolesOnMobile = (roles: IRole[]) =>
		_.reverse(roles).map((role, index) => {
			return (
				<Accordion key={index}>
					<AccordionHeader className='capitalize'>{role.name}</AccordionHeader>

					<AccordionBody>
						<div className='mb-6'>{renderActionButtons(role)}</div>

						{role.name.toLowerCase() === 'super admin' ? (
							<div className='flex flex-wrap items-end gap-2'>
								<Badge size='lg' className='!max-h-7 mt-2 bg-black capitalize text-white'>
									All permissions are granted
								</Badge>
							</div>
						) : role.permissions.length > 0 ? (
							<div className='flex flex-wrap gap-1'>
								{role.permissions.map((permission, index) => (
									<Badge key={index} className='!max-h-7 bg-gray-700 text-white capitalize'>
										{permission.name}
									</Badge>
								))}
							</div>
						) : (
							<SimpleEmptyResourceIndicator message={'No permission found'} />
						)}
					</AccordionBody>
				</Accordion>
			);
		});

	return (
		<div className='h-full px-2 sm:px-4'>
			<div className='flex items-center justify-between'>
				<Title>User Roles</Title>

				{hasPermission(PermissionKeys.CREATE_ROLE) && (
					<Button className='!rounded-full' onClick={() => setEditRoleFormOpen(true)}>
						Create New Role
					</Button>
				)}
			</div>

			<Fragment>
				{isRolesLoading ? (
					<Fragment>
						<ContentLoadingCardSpinner message='Loading roles...' />
					</Fragment>
				) : roles && roles.length > 0 ? (
					<div className='mt-5'>
						{/* ====================
            // Large Screens
            ======================*/}
						<div className='hidden sm:block'>
							<header className='grid grid-cols-[300px,1fr] items-center gap-2 font-semibold'>
								<h2>Roles</h2>
								<h2>Assigned Permissions</h2>
							</header>

							<div className='mt-4'>{renderRolesOnDesktop(roles)}</div>
						</div>

						{/* ====================
            // Mobile Screens
            ======================*/}
						<AccordionList className='sm:hidden'>{renderRolesOnMobile(roles)}</AccordionList>
					</div>
				) : (
					<SimpleEmptyResourceIndicator message='No role found' />
				)}
			</Fragment>

			<Modal
				width={600}
				closeButton={{ styles: 'bg-gray-200' }}
				isModalOpen={isEditRoleFormOpen}
				setModalOpen={setEditRoleFormOpen}
				onCloseModal={() => {
					setEditRoleFormOpen(false);
					setTimeout(() => setCurrentRole(null), 500);
				}}
				shouldCloseOnOutsideClick={false}
			>
				<EditRoleForm role={currentRole} setCurrentRole={setCurrentRole} setEditRoleFormOpen={setEditRoleFormOpen} refetchRoles={refetchRoles} />
			</Modal>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmDeleteRoleDialogOpen}
				setOpen={setConfirmDeleteRoleDialogOpen}
				message='Delete Role'
				subMessage={`Are you sure you want to delete ${titleCase(currentRole?.name as string) + ' role' ?? 'this  role'}? This action cannot be reversed.`}
				onClose={() => {
					setTimeout(() => setCurrentRole(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmDeleteRole,
					label: 'Delete',
					isLoading: isDeleteRoleLoading,
					loadingText: 'Deleting',
					color: 'red',
				}}
				cancelButton={{
					action: () => setConfirmDeleteRoleDialogOpen(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-500/10`}>
						<HiExclamation className='w-6 h-6 text-red-500' aria-hidden='true' />
					</div>
				)}
			/>
		</div>
	);
};

export default RolesPage;
