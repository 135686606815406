/* This example requires Tailwind CSS v2.0+ */
import { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { HiX } from 'react-icons/hi';
import useMediaQuery from 'hooks/useMediaQuery';
import { Text, Title } from '@tremor/react';

interface Props {
	open: boolean;
	setOpen: (value: boolean) => void;
	onClose: () => void;
	children: ReactNode;
	width: number;
	isScrollable: boolean;
	title?: {
		text?: string;
		styles?: string;
		theme?: 'dark' | 'primary' | 'light';
	};
	subTitle?: {
		text?: string;
		styles?: string;
	};
	container?: {
		styles?: string;
	};
	closeButton?: {
		styles?: string;
	};
	afterClose?: () => void;
	className?: string;
	shouldCloseOnOutsideClick?: boolean;
}

export default function SlideOver(props: Props) {
	const { open, setOpen, title, subTitle, width, children, container, isScrollable, shouldCloseOnOutsideClick, afterClose } = props;

	const isMinTablet = useMediaQuery({ query: '(min-width: 640px)' });

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as='div'
				className='fixed inset-0 z-[var(--slideover-z-index)] overflow-hidden'
				onClose={() => {
					setOpen(false); //Todo: Implement onClose
					afterClose?.();
				}}
			>
				<div className='absolute inset-0 overflow-hidden'>
					<Transition.Child as={Fragment} enter='ease-in-out duration-500' enterFrom='opacity-0' enterTo='opacity-100' leave='ease-in-out duration-500' leaveFrom='opacity-100' leaveTo='opacity-0'>
						<Dialog.Overlay className={`absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity ${!shouldCloseOnOutsideClick ? 'pointer-events-none' : ''}`} />
					</Transition.Child>

					<div className='fixed inset-y-0 right-0 flex max-w-full pl-10'>
						<Transition.Child
							as={Fragment}
							enter='transform transition ease-in-out duration-500 sm:duration-700'
							enterFrom='translate-x-full'
							enterTo='translate-x-0'
							leave='transform transition ease-in-out duration-500 sm:duration-700'
							leaveFrom='translate-x-0'
							leaveTo='translate-x-full'
						>
							<div className={`relative ${container ? width : 'max-w-md lg:max-w-3xl'}`} style={{ width: isMinTablet ? width : '90vw' }}>
								<Transition.Child
									as={Fragment}
									enter='ease-in-out duration-500'
									enterFrom='opacity-0'
									enterTo='opacity-100'
									leave='ease-in-out duration-500'
									leaveFrom='opacity-100'
									leaveTo='opacity-0'
								>
									<div className='absolute top-0 left-0 flex pt-4 pr-2 -ml-8 sm:-ml-10 sm:pr-4'>
										<button
											type='button'
											className='text-gray-300 rounded-md hover:text-white focus:outline-none focus:ring-2 focus:ring-white'
											onClick={() => {
												setOpen(false);
												afterClose?.();
											}}
										>
											<span className='sr-only'>Close panel</span>
											<HiX className='w-6 h-6' aria-hidden='true' />
										</button>
									</div>
								</Transition.Child>

								<div className={`flex h-full flex-col overflow-x-hidden bg-white shadow-xl ${isScrollable ? 'overflow-y-scroll' : 'overflow-hidden'}`}>
									{(title?.text || subTitle?.text) && (
										<Dialog.Title
											as='div'
											className={`h-[80px] px-4 py-4 border-b bg-gradient-to-r line-clamp-1 text-ellipsis ${
												title?.theme === 'primary'
													? 'from-primary-600 to-primary-300 text-white'
													: title?.theme === 'dark'
													? 'from-gray-800 to-gray-500 text-white'
													: 'from-white to-white text-gray-800'
											}`}
										>
											{title?.text && <Title className={title.styles}>{title?.text ?? ' '}</Title>}

											{subTitle?.text && <Text className={`text-sm capitalize text-opacity-80 text-ellipsis line-clamp-1 ${subTitle?.styles}`}>{subTitle.text}</Text>}
										</Dialog.Title>
									)}

									<div className='relative flex h-[100vh-80px] flex-1 overflow-hidden'>{children}</div>
								</div>
							</div>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
