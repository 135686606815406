import { ReactNode } from 'react';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { createColumnHelper } from '@tanstack/react-table';
import { Card, Title, Text, Button } from '@tremor/react';
import Table from 'components/Table';
import { IMarketingManagerDashboardData, ITopSellingStaff } from './dashboardInterfaces';
import { RiLoader4Line } from 'react-icons/ri';
import { formatCurrency } from 'helpers/index';
import { useNavigate } from 'react-router-dom';

interface IBestStaffTableColumns extends ITopSellingStaff {
	action: ReactNode;
	product_closing_percentage: number;
}

interface Props {
	data: IMarketingManagerDashboardData | undefined;
	isDataLoading: boolean;
}

const columnHelper = createColumnHelper<IBestStaffTableColumns>();

const TopSellingReps = ({ data, isDataLoading }: Props) => {
	const topSellingReps = data?.top_selling_staffs;
	const navigate = useNavigate();

	// Todo: Click to open staff info page - That is when that page is created
	const tableColumns = [
		// columnHelper.accessor('action', {
		// 	header: 'Actions',
		// 	cell: (info) => {
		// 		return (
		// 			<DropdownMenu
		// 				renderTriggerElement={(state) => <DefaultTableDropDownTrigger state={state} />}
		// 				menuOptions={[
		// 					{
		// 						type: 'button',
		// 						text: 'View Rep',
		// 						action: (e: any) => {
		// 							toast.success('Will open a page with the rep details');
		// 							e.stopPropagation();
		// 						},
		// 						icon: <GrFormViewHide />,
		// 					},
		// 				]}
		// 			/>
		// 		);
		// 	},
		// }),

		columnHelper.accessor('user', {
			cell: ({ row }) => {
				const { first_name, last_name, middle_name } = row.original.user || {};

				return (
					<span className='font-semibold'>
						{first_name} {middle_name} {last_name}
					</span>
				);
			},
			header: 'Staff',
		}),
		columnHelper.accessor('total_expected_premium', {
			cell: ({ row }) => <span className={`text-blue-600`}>{formatCurrency(row.original.total_expected_premium)}</span>,
			header: 'Expected Premium',
		}),
		columnHelper.accessor('total_achieved_premium', {
			cell: ({ row }) => <span className='text-green-600'>{formatCurrency(row.original.total_achieved_premium)}</span>,
			header: 'Achieved Premium',
		}),
		// columnHelper.accessor('', {
		// 	cell: ({ row }) => <span className='text-yellow-600'>{formatCurrency(row.original.target || 0)}</span>,
		// 	header: 'Yearly Target - ' + new Date().getFullYear(),
		// }),

		// columnHelper.accessor('product_closing_percentage', {
		// 	cell: ({ row }) => {
		// 		const { total_achieved_premium, total_expected_premium } = row.original;

		// 		return <span className='font-semibold'>{((total_achieved_premium / total_expected_premium) * 100).toFixed(2)}%</span>;
		// 	},
		// 	header: 'Product Closing Percentage',
		// }),
	];

	return (
		<Card className='!p-0 pt-6 overflow-hidden !w-full !h-full'>
			<div className='flex items-center justify-between px-4 py-3 border-b'>
				<Title>Top Selling Reps</Title>

				{/* <Button
					icon={IoIosArrowRoundForward}
					iconPosition='right'
					className='hover:!bg-gray-100 duration-300 py-2 px-3 !rounded-lg'
					variant='light'
					color='gray'
					size='xs'
					onClick={() => {
						navigate('/app/dashboard/marketing/reports/target-per-staff');
					}}
				>
					All Staff Targets
				</Button> */}
			</div>

			{isDataLoading ? (
				<div className='flex flex-col items-center justify-center gap-4 p-10'>
					<RiLoader4Line className='w-8 h-8 text-lg text-gray-400 animate animate-spin ' />
					<Text className='!text-base'>Loading top staff...</Text>
				</div>
			) : topSellingReps && topSellingReps.length > 0 ? (
				<Table data={topSellingReps} columns={tableColumns} />
			) : (
				<div className='flex items-center justify-center w-full h-full'>
					<Text className={'!text-base font-semibold'}>No staff found</Text>
				</div>
			)}
		</Card>
	);
};

export default TopSellingReps;
