import { IUser } from 'features/auth/authInterfaces';
import { IProspect } from 'features/prospects/prospectInterfaces';
import { IVehicleAllocation } from '../navigateInterfaces';

export interface IVehicleRequest {
	id: number;
	user_id: number;
	departure_date: string;
	destination: string; // Todo: Change to lat/lng type
	reason: string;
	request_status: VehicleRequestStatuses;
	request_type: VehicleRequestTypes;
	prospect_id: number;
	created_at: string;
	updated_at: string;
	user: IUser;
	prospect: IProspect;
	comments: IVehicleRequestComment[];
	vehicle_allocation: IVehicleAllocation;
}

export enum VehicleRequestStatuses {
	Pending = 'Pending',
	Declined = 'Declined',
	Approved = 'Approved',
	Cancelled = 'Cancelled',
}

export enum VehicleRequestTypes {
	Prospecting = 'Prospecting',
	General = 'General',
}

export type IFetchVehicleRequestIncludes = 'user' | 'prospect' | 'comments' | 'comments.user' | 'vehicle_allocation' | 'vehicle_allocation.transport_officer';
export type IFetchVehicleRequestFilters = {
	dateRange?: VehicleRequestStatuses;
	dateType?: VehicleRequestStatuses;
	requestStatus?: VehicleRequestStatuses;
	staffId?: number;
	departureDate?: number;
	createdAt?: string;
};
export interface IFetchVehicleRequestsActionParams {
	includes?: IFetchVehicleRequestIncludes[];
	filterBy?: IFetchVehicleRequestFilters;
	page?: number;
}

export interface IVehicleRequestComment {
	id: number;
	vehicle_request_id: number;
	comment: string;
	user_id: number;
	created_at: string;
	updated_at: string;
	user: IUser;
}

export interface IVehicleRequestFormValues {
	departure_date: string;
	departure_time: string;
	destination?: string;
	request_purpose: string;
	request_type: string;
	prospect_id: number;
	reason: string;
	comment: string;
}

export interface IFetchVehicleRequestsActionParams {}

export interface IVehicleRequestCommentFormValues {
	comment: string;
	vehicle_request_id: number;
}
