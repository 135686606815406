// export const HOST_URL = `http://172.105.94.162`;
export const HOST_URL = `https://salesapi.loyaltyinsurancegh.com`;
// export const HOST_URL = `http://127.0.0.1:8000`;
export const CEDI_SYMBOL = `GH₵`;
export const API_URL = `${HOST_URL}/api/v1`;
export const MODAL_LEAVE_SPEED = 500;
export const ACCEPTED_IMAGE_TYPES = ['image/jpg', 'image/jpeg', 'image/png'];
export const MAX_IMAGE_SIZE = 20000000; //20MB

export const TRANSPORT_OFFICER_ROLE_ID = 6;
