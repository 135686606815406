import { useState, Fragment } from 'react';
import { AppointmentFulfillmentStatuses, IAppointment } from './appointmentInterfaces';
import { Badge, Button, Text, Title } from '@tremor/react';
import { formatDate } from 'helpers/dateHelpers';
import SlideOver from 'components/SlideOver';
import VehicleRequestForm from 'features/navigation/VehicleRequestForm';
import { isAfter } from 'date-fns';
import { hasPermission, hasRole, hasRoles, isAdmin } from 'helpers/index';
import { DefaultRoles } from 'features/settings/authorization/authorizationInterface';
import { useAppSelector } from 'hooks/storeConnect';
import { authState } from 'features/auth/authSlice';
import { getAppointmentState } from 'utils/index';
import { PermissionKeys } from 'features/auth/permissions';

interface Props {
	appointment: IAppointment;
	refetchAppointments: () => void;
	onFulfillAppointment: (appointment: IAppointment) => void;
	showControls?: boolean;
}

const AppointmentCard = ({ appointment, onFulfillAppointment, showControls }: Props) => {
	const [isVehicleRequestFormOpen, setVehicleRequestFormOpen] = useState(false);

	const { authUser } = useAppSelector(authState);

	const { prospect, appointment_date, sales_stage, user } = appointment;

	const { isApproved, isFulfilled, isPending, isUnfulFilled } = getAppointmentState(appointment);

	return (
		<Fragment>
			<div
				className={`bg-light space-y-2 border border-l-2 p-3 rounded-xl ${
					isPending ? 'border-l-gray-500' : isApproved ? 'border-l-yellow-500' : isFulfilled ? 'border-l-green-500' : 'border-l-red-500'
				}`}
			>
				<div className='flex items-center justify-between'>
					<Text className='text-xs font-semibold capitalize'>{sales_stage.name}</Text>
					<Text className='ml-auto text-xs font-medium'>{formatDate(new Date(appointment_date), 'Jan 31, 1999')}</Text>
				</div>

				{/* Mid Content */}
				<div className='pr-4'>
					<Title className='!text-base capitalize'>{prospect.company_name}</Title>
				</div>

				{/* Actions */}
				{showControls && (
					<div className='mt-4'>
						{isFulfilled ? (
							<Badge size='xl' className='ml-1 text-white bg-green-600'>
								Fulfilled
							</Badge>
						) : isUnfulFilled ? (
							<Badge size='xl' className='ml-1 text-white bg-black'>
								Unfulfilled
							</Badge>
						) : (
							<div className='flex items-center justify-start gap-2 mt-4'>
								{hasPermission(PermissionKeys.CREATE_VEHICLE_REQUEST) && (
									<Button onClick={() => setVehicleRequestFormOpen(true)} color='gray' size='xs' className='!rounded-full !font-normal'>
										Request Vehicle
									</Button>
								)}

								{hasRoles([DefaultRoles.Marketer, DefaultRoles.Admin, DefaultRoles.MarketingManager]) && (
									<Button onClick={() => onFulfillAppointment(appointment)} color='green' variant='secondary' size='xs' className='!rounded-full !font-normal'>
										Fulfill Now
									</Button>
								)}
							</div>
						)}
					</div>
				)}

				{hasRoles([DefaultRoles.MarketingManager, DefaultRoles.Admin]) && (
					<Text className='mt-2 text-xs text-gray-500'>
						<span className='font-medium text-black'>Scheduled By: </span>
						{authUser?.id === user.id ? 'You' : `${user.first_name} ${user.middle_name ?? ''} ${user.last_name}`}
					</Text>
				)}
			</div>

			<SlideOver
				title={{
					text: 'Request Vehicle',
					theme: 'primary',
					styles: 'text-gray-100',
				}}
				subTitle={{
					text: `Complete this form to request vehicle`,
					styles: 'text-gray-200',
				}}
				onClose={() => null}
				open={isVehicleRequestFormOpen}
				setOpen={setVehicleRequestFormOpen}
				width={400}
				isScrollable
				afterClose={() => null}
				shouldCloseOnOutsideClick={false}
			>
				<VehicleRequestForm
					prospect={prospect}
					afterEdit={() => {
						setVehicleRequestFormOpen(false);
					}}
				/>
			</SlideOver>
		</Fragment>
	);
};

export default AppointmentCard;
