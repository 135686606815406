import { Button, Title } from '@tremor/react';
import Container from 'components/Container';
import Filters from 'components/Filters';
import { authState } from 'features/auth/authSlice';
import { IDashboardFilters, IMarketingManagerDashboardData } from 'features/dashboard/dashboardInterfaces';
import { DefaultRoles } from 'features/settings/authorization/authorizationInterface';
import { FilterComponentTypes } from 'features/settings/settingsInterfaces';
import useFetchUsers from 'features/settings/users/useFetchUsers';
import { hasRole, hasRoles, isAdmin } from 'helpers/index';
import { useAppSelector } from 'hooks/storeConnect';
import { useEffect, useState } from 'react';
import useFetchReports from './useFetchReports';
import { useQuery } from '@tanstack/react-query';
import { fetchMarketerDashboard, fetchMarketingManagerDashboard } from 'features/dashboard/dashboardQueries';
import { formatDateRangePickerValue } from 'helpers/dateHelpers';
import { buildURLFilters, isMarketer } from 'utils/index';
import ProductionChart from 'features/dashboard/ProductionChart';
import YearlyProductionPercentageChart from 'features/dashboard/YearlyProductionPercentageChart';
import ProductRevenueShareChart from 'features/dashboard/ProductRevenueShareChart';
import TopSellingReps from 'features/dashboard/TopSellingReps';
import SalesStageAnalytics from './SalesStageAnalytics';
import DefaultReportMetrics from './DefaultReportMetrics';
import { RiFilter2Fill } from 'react-icons/ri';
import BottomSheet from 'components/BottomSheet';
import { useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import ProspectBusinessTypesAnalytics from './ProspectBusinessTypesAnalytics';
import { PermissionKeys } from 'features/auth/permissions';

const AnalyticsReportPage = () => {
	const navigate = useNavigate();
	const [isMobileFiltersOpen, setMobileFiltersOpen] = useState<boolean>(false);
	const { users } = useFetchUsers({});
	const { authUser } = useAppSelector(authState);
	const [urlParams, setUrlParams] = useSearchParams();
	const [fetchFilters, setFetchFilters] = useState<IDashboardFilters>({
		userId: !isAdmin() && hasRoles([DefaultRoles.TransportOfficer, DefaultRoles.Marketer]) ? authUser?.id : undefined,
	});

	// Fetch reports
	const { reports, isReportsLoading } = useFetchReports({
		fetchParams: {
			filterBy: fetchFilters,
		},
		queryKey: ['reports/reports', fetchFilters],
	});

	// Fetch Marketing Manager Dashboard
	const { data: marketingManagerData, isLoading: isMarketingManagerDashboardLoading } = useQuery({
		queryKey: ['reports/fetch-marketing-manager', fetchFilters],
		queryFn: () => fetchMarketingManagerDashboard(fetchFilters),
		enabled: hasRoles([DefaultRoles.MarketingManager, DefaultRoles.Admin]),
	});
	// Fetch Marketer Dashboard
	const { data: marketerData, isLoading: isMarketerDashboardLoading } = useQuery({
		queryKey: ['reports/fetch-marketer', fetchFilters],
		queryFn: () => fetchMarketerDashboard(fetchFilters),
		enabled: isMarketer(),
	});

	const marketingManagerDashboardData: IMarketingManagerDashboardData | undefined = marketingManagerData?.data.data;
	const marketerDashboardData: IMarketingManagerDashboardData | undefined = marketerData?.data.data;

	// Manage url filters
	const dateRange = urlParams.get('dateRange');
	const userId = !isAdmin() && hasRole(DefaultRoles.Marketer) ? authUser?.id : urlParams.get('userId') ?? '';

	useEffect(() => {
		const urlFilters: IDashboardFilters = {
			createdBetween: dateRange || undefined,
			userId: Number(userId) || undefined,
		};

		setFetchFilters(_.pickBy(urlFilters, _.identity));

		// eslint-disable-next-line
	}, [dateRange, userId]);

	const onSubmitFilters = (filters: IDashboardFilters) => {
		const urlFilters = {
			...fetchFilters,
			userId: filters.userId,
			dateRange: filters.createdBetween ? formatDateRangePickerValue(filters?.createdBetween as any) : undefined,
		};

		delete urlFilters.createdBetween;

		navigate(`/app/reports/analytics?${buildURLFilters(_.pickBy(urlFilters, _.identity) as any)}`);
	};

	return (
		<Container className='py-3 mt-2'>
			<div className='flex items-center justify-between'>
				<Title className='!font-bold text-gray-800'>Analytics</Title>

				<div className='hidden lg:block'>
					<Filters
						filters={[
							{
								type: FilterComponentTypes.DateRange,
								name: 'createdBetween',
								label: 'Dates',
								hideLabel: true,
								placeholder: 'Select date range',
								defaultValue: dateRange ? { from: new Date(dateRange.split(',')[0]), to: new Date(dateRange.split(',')[1]) } : undefined,
							},
							{
								type: FilterComponentTypes.Select,
								name: 'userId',
								label: 'Staff',
								hideLabel: true,
								defaultValue: userId?.toString(),
								dropdownOptions:
									users
										?.filter((user) => {
											// return user.roles.includes(DefaultRoles.Marketer) || user.roles.includes(DefaultRoles.MarketingManager)
											return !user.roles.includes(DefaultRoles.SuperAdmin);
										})
										.map((user: any) => ({
											id: user.id,
											label: `${user.first_name} ${user?.middle_name ?? ' '} ${user.last_name}`,
											value: user.id,
										})) ?? [],
								placeholder: 'Select staff',
								permission: PermissionKeys.VIEW_USER_FILTERS,
							},
						]}
						submitButton={{
							className: `!bg-gray-800 hover:!bg-gray-600 text-white !capitalize border-0 duration-300`,
							label: 'Apply Filters',
							onSubmit: onSubmitFilters,
						}}
					/>
				</div>

				<div className='lg:hidden'>
					<Button
						color='gray'
						variant='secondary'
						onClick={() => setMobileFiltersOpen(true)}
						icon={RiFilter2Fill}
						className='!rounded-full hover:bg-gray-700 hover:!text-gray-100 duration-300 lg:hidden'
					>
						Filters
					</Button>
				</div>
			</div>

			{!isAdmin() ? (
				<div className='mt-2'>
					{/* ======= Marketer =======*/}
					{hasRole(DefaultRoles.Marketer) && (
						<div className='pb-8 mt-4'>
							<div className='space-y-6'>
								<DefaultReportMetrics data={marketerDashboardData} isDataLoading={isMarketerDashboardLoading} reports={reports} isReportsLoading={isReportsLoading} />

								<SalesStageAnalytics isReportLoading={isReportsLoading} prospectsBySalesStage={reports?.prospects_by_sales_stage} />

								<ProspectBusinessTypesAnalytics isReportLoading={isReportsLoading} prospectsByBusinessType={reports?.prospects_by_busines_type} />
							</div>

							<div className='mt-8 grid gap-6 2xl:grid-cols-2 p-[2px]'>
								<div className='w-full'>
									<ProductionChart data={marketerDashboardData} isDataLoading={isMarketerDashboardLoading} />
								</div>

								<div className='grid w-full gap-6 pb-8 2xl:pb-0 sm:grid-cols-2'>
									<div className='z-20 w-full lg:self-stretch'>
										<ProductRevenueShareChart data={marketerDashboardData} isDataLoading={isMarketerDashboardLoading} />
									</div>

									<div className='w-full lg:self-stretch'>
										<YearlyProductionPercentageChart selectedDateRange={fetchFilters.createdBetween} data={marketerDashboardData} isDataLoading={isMarketerDashboardLoading} />
									</div>
								</div>
							</div>
						</div>
					)}

					{/* ======= Marketing Manager =======*/}
					{hasRole(DefaultRoles.MarketingManager) && (
						<div className='pb-8 mt-4'>
							<div className='flex flex-col gap-4'>
								<div className='space-y-6'>
									<DefaultReportMetrics data={marketingManagerDashboardData} isDataLoading={isMarketingManagerDashboardLoading} reports={reports} isReportsLoading={isReportsLoading} />

									<SalesStageAnalytics isReportLoading={isReportsLoading} prospectsBySalesStage={reports?.prospects_by_sales_stage} />

									<ProspectBusinessTypesAnalytics isReportLoading={isReportsLoading} prospectsByBusinessType={reports?.prospects_by_busines_type} />
								</div>
							</div>

							<div className='grid w-full gap-6 mt-8 2xl:gap-8 sm:grid-cols-2 lg:flex'>
								<div className='w-full lg:self-stretch lg:w-3/5 sm:col-span-2'>
									<TopSellingReps data={marketingManagerDashboardData} isDataLoading={isMarketingManagerDashboardLoading} />
								</div>
								<div className='z-20 w-full lg:self-stretch lg:w-1/5'>
									<ProductRevenueShareChart data={marketingManagerDashboardData} isDataLoading={isMarketingManagerDashboardLoading} />
								</div>
								<div className='w-full lg:self-stretch lg:w-1/5'>
									<YearlyProductionPercentageChart selectedDateRange={fetchFilters.createdBetween} data={marketingManagerDashboardData} isDataLoading={isMarketingManagerDashboardLoading} />
								</div>
							</div>

							<div className='w-full mt-8'>
								<ProductionChart data={marketingManagerDashboardData} isDataLoading={isMarketingManagerDashboardLoading} />
							</div>
						</div>
					)}
				</div>
			) : (
				<div className='pb-8 mt-4'>
					<div className='flex flex-col gap-4'>
						<div className='space-y-6'>
							<DefaultReportMetrics data={marketingManagerDashboardData} isDataLoading={isMarketingManagerDashboardLoading} reports={reports} isReportsLoading={isReportsLoading} />

							<SalesStageAnalytics isReportLoading={isReportsLoading} prospectsBySalesStage={reports?.prospects_by_sales_stage} />

							<ProspectBusinessTypesAnalytics isReportLoading={isReportsLoading} prospectsByBusinessType={reports?.prospects_by_busines_type} />
						</div>
					</div>

					<div className='grid w-full gap-6 mt-8 2xl:gap-8 sm:grid-cols-2 lg:flex'>
						<div className='w-full lg:self-stretch lg:w-3/5 sm:col-span-2'>
							<TopSellingReps data={marketingManagerDashboardData} isDataLoading={isMarketingManagerDashboardLoading} />
						</div>
						<div className='z-20 w-full lg:self-stretch lg:w-1/5'>
							<ProductRevenueShareChart data={marketingManagerDashboardData} isDataLoading={isMarketingManagerDashboardLoading} />
						</div>
						<div className='w-full lg:self-stretch lg:w-1/5'>
							<YearlyProductionPercentageChart selectedDateRange={fetchFilters.createdBetween} data={marketingManagerDashboardData} isDataLoading={isMarketingManagerDashboardLoading} />
						</div>
					</div>

					<div className='w-full mt-8'>
						<ProductionChart data={marketingManagerDashboardData} isDataLoading={isMarketingManagerDashboardLoading} />
					</div>
				</div>
			)}

			<BottomSheet
				isOpen={isMobileFiltersOpen}
				setOpen={setMobileFiltersOpen}
				closeOnOutSideClick
				header={{
					text: 'Filter Analytics',
				}}
			>
				<Filters
					filters={[
						{
							type: FilterComponentTypes.DateRange,
							name: 'createdBetween',
							label: 'Dates',
							hideLabel: true,
							placeholder: 'Select date range',
							defaultValue: dateRange ? { from: new Date(dateRange.split(',')[0]), to: new Date(dateRange.split(',')[1]) } : undefined,
						},
						{
							type: FilterComponentTypes.Select,
							name: 'userId',
							label: 'Staff',
							hideLabel: true,
							defaultValue: userId?.toString(),
							dropdownOptions:
								users
									?.filter((user) => {
										// return user.roles.includes(DefaultRoles.Marketer) || user.roles.includes(DefaultRoles.MarketingManager)
										return !user.roles.includes(DefaultRoles.SuperAdmin);
									})
									.map((user: any) => ({
										id: user.id,
										label: `${user.first_name} ${user?.middle_name ?? ' '} ${user.last_name}`,
										value: user.id,
									})) ?? [],
							placeholder: 'Select staff',
							permission: PermissionKeys.VIEW_USER_FILTERS,
						},
					]}
					submitButton={{
						className: `!bg-gray-800 hover:!bg-gray-600 text-white !capitalize border-0 duration-300`,
						label: 'Apply Filters',
						onSubmit: onSubmitFilters,
					}}
				/>
			</BottomSheet>
		</Container>
	);
};

export default AnalyticsReportPage;
