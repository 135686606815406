import { BiEditAlt, BiMessageDetail, BiSolidMessageAltDetail, BiSolidTimeFive, BiSolidTrash, BiTimeFive, BiTrash } from 'react-icons/bi';
import { HiExclamation, HiOutlineArrowNarrowRight, HiSearch } from 'react-icons/hi';
import { RiCarFill, RiCarLine, RiUserLine } from 'react-icons/ri';
import { VscClose } from 'react-icons/vsc';
import { FaRegEye, FaSearchLocation } from 'react-icons/fa';
import { PiDotsThreeVerticalBold } from 'react-icons/pi';
import { TbClockCancel } from 'react-icons/tb';
import { HiUser } from 'react-icons/hi2';
import { FaMagnifyingGlassLocation } from 'react-icons/fa6';
import { IoLocationOutline, IoLocationSharp } from 'react-icons/io5';
import { AiTwotoneEdit } from 'react-icons/ai';

interface FormActionIconProps {
	variant?: 'solid' | 'light';
	className?: string;
}

export const CarIcon = (props: FormActionIconProps) => (props.variant === 'solid' ? <RiCarFill className={`${props.className} h-5 w-5`} /> : <RiCarLine className={`${props.className} h-5 w-5`} />);

export const UserIcon = (props: FormActionIconProps) => (props.variant === 'solid' ? <HiUser className={`${props.className} h-5 w-5`} /> : <RiUserLine className={`${props.className} h-5 w-5`} />);

export const AppointmentIcon = (props: FormActionIconProps) =>
	props.variant === 'solid' ? <BiSolidTimeFive className={`${props.className} h-5 w-5`} /> : <BiTimeFive className={`${props.className} h-5 w-5`} />;

export const InteractionIcon = (props: FormActionIconProps) =>
	props.variant === 'solid' ? <BiSolidMessageAltDetail className={`${props.className} h-5 w-5`} /> : <BiMessageDetail className={`${props.className} h-5 w-5`} />;

export const SearchIcon = (props: FormActionIconProps) => <HiSearch className={`${props.className} h-5 w-5`} />;

export const DeleteIcon = (props: FormActionIconProps) =>
	props.variant === 'solid' ? <BiSolidTrash className={`${props.className} h-5 w-5`} /> : <BiTrash className={`${props.className} h-5 w-5`} />;

export const EditIcon = (props: FormActionIconProps) =>
	props.variant === 'solid' ? <AiTwotoneEdit className={`${props.className} h-5 w-5`} /> : <BiEditAlt className={`${props.className} h-5 w-5`} />;

export const ArrowForwardIcon = (props: FormActionIconProps) => <HiOutlineArrowNarrowRight className={`${props.className} h-5 w-5`} />;

export const CloseIcon = (props: FormActionIconProps) => <VscClose className={`${props.className} h-5 w-5`} />;

export const CancelAppointmentIcon = (props: FormActionIconProps) => <TbClockCancel className={`${props.className} h-5 w-5`} />;

export const ViewIcon = (props: FormActionIconProps) => <FaRegEye className={`${props.className} h-5 w-5`} />;

export const VerticalDotsIcons = (props: FormActionIconProps) => <PiDotsThreeVerticalBold className={`${props.className} h-5 w-5`} />;

export const ConfirmationDialogIcon = (props: FormActionIconProps) => <HiExclamation className={`${props.className} h-6 w-6`} />;
export const LocationIcon = (props: FormActionIconProps) =>
	props.variant === 'solid' ? <IoLocationSharp className={`${props.className} h-5 w-5`} /> : <IoLocationOutline className={`${props.className} h-5 w-5`} />;
export const SearchLocation = (props: FormActionIconProps) =>
	props.variant === 'solid' ? <FaMagnifyingGlassLocation className={`${props.className} h-5 w-5`} /> : <FaSearchLocation className={`${props.className} h-5 w-5`} />;
