import { useState } from 'react';
import { Button, NumberInput, Title, Select, SelectItem, Text } from '@tremor/react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { processReactHookFormErrors } from 'helpers';
import { ICreateUserTargetActionParams, IUser, IYearlyTarget } from 'features/auth/authInterfaces';
import { createUserTarget, updateUserTarget } from 'features/auth/authQueries';

interface Props {
	currentUser: IUser;
	currentTarget?: IYearlyTarget | null;
	setCurrentTarget: (value: IYearlyTarget | null) => void;
	setEditUserTargetFormOpen: (value: boolean) => void;
	afterEdit: () => void;
}

const EditUserTargetForm = (props: Props) => {
	const { currentUser, currentTarget, setCurrentTarget, setEditUserTargetFormOpen , afterEdit} = props;

	const [selectUserId, setSelectUserId] = useState(currentUser.id.toString());

	const {
		register,
		handleSubmit,
		setError,
		setValue,
		getValues,
		formState: { errors },
	} = useForm<ICreateUserTargetActionParams>({
		defaultValues: {
			user_id: currentUser.id.toString() as any,
			yearly_target: currentTarget?.yearly_target,
		},
	});

	// ============================
	// * Create Target
	// ============================
	const { isLoading: isCreateTargetLoading, mutate: createTargetMutation } = useMutation({
		mutationKey: ['auth/create-user-target'],
		mutationFn: (params: ICreateUserTargetActionParams) => createUserTarget(params),
	});

	// ============================
	// * Update Target
	// ============================
	const { isLoading: isUpdateTargetLoading, mutate: updateTargetMutation } = useMutation({
		mutationKey: ['auth/update-user-target'],
		mutationFn: ({ formValues, targetId }: { formValues: ICreateUserTargetActionParams; targetId: number }) => updateUserTarget({ formValues, targetId }),
	});

	const onSubmit = (formValues: ICreateUserTargetActionParams) => {
		if (!getValues('user_id'))
			return setError('user_id', {
				message: 'Please select a user',
			});

		if (currentTarget)
			return updateTargetMutation(
				{ formValues, targetId: currentTarget.id },
				{
					onSuccess: async () => {
						toast.success('User target updated successfully!');

						afterEdit();
					},
					onError: (error) => {
						processReactHookFormErrors(error, 'Failed to update user target', setError);
					},
				}
			);

		createTargetMutation(formValues, {
			onSuccess: () => {
				toast.success('User target created successfully!');

				afterEdit();
			},
			onError: (error) => {
				processReactHookFormErrors(error, 'Failed to create user target', setError);
			},
		});
	};

	return (
		<form className='bg-white' onSubmit={handleSubmit(onSubmit)}>
			<header className='bg-dark p-6 h-[90px] flex flex-col items-start gap-1'>
				<Title className='text-white'>{currentTarget ? 'Update' : 'Create'} Target</Title>

				<Text className='text-gray-300'>
					{currentTarget ? 'Update' : 'Add'} {currentTarget ? new Date(currentTarget.created_at).getFullYear() : 'yearly'} target for {currentUser?.first_name}
				</Text>
			</header>

			<section className='p-6 text-left space-y-3'>
				<div className='form-control'>
					<label htmlFor='user' className='required-field-indicator'>
						User
					</label>

					<div>
						<Select
							id='user'
							value={selectUserId}
							placeholder='Select user'
							disabled={true}
							onChange={(val) => {
								setSelectUserId(val as any);
								setValue('user_id', Number(val));
							}}
						>
							<SelectItem value={currentUser.id.toString()}>
								{currentUser.first_name} {currentUser?.middle_name} {currentUser.last_name}
							</SelectItem>
						</Select>

						{errors?.user_id && <span className='form-message'>{errors?.user_id?.message}</span>}
					</div>
				</div>

				<div className='form-control'>
					<label htmlFor='yearly_target' className='required-field-indicator'>
						Target
					</label>

					<div>
						<NumberInput
							id='yearly_target'
							{...register('yearly_target', {
								required: {
									value: true,
									message: 'Target is required',
								},
							})}
							min={0}
							step='0.01'
							defaultValue={0}
							placeholder='Enter yearly target'
							error={errors.yearly_target ? true : false}
							errorMessage={errors.yearly_target?.message}
						/>
					</div>
				</div>
			</section>

			<footer className='p-6 flex items-center gap-2 justify-end bg-gray-100'>
				<Button
					onClick={() => {
						setEditUserTargetFormOpen(false);
						setTimeout(() => setCurrentTarget(null), 500);
					}}
					type='button'
					color='gray'
					variant='secondary'
					className='px-6 py-3 duration-300 !rounded-full'
				>
					Cancel
				</Button>

				<Button
					type='submit'
					loading={isCreateTargetLoading || isUpdateTargetLoading}
					loadingText={currentTarget ? 'Updating' : 'Creating'}
					disabled={isCreateTargetLoading || isUpdateTargetLoading}
					color='green'
					className='px-6 py-3 duration-300 !rounded-full'
				>
					{currentTarget ? 'Update Target' : 'Create Target'}
				</Button>
			</footer>
		</form>
	);
};

export default EditUserTargetForm;
