/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { ReactNode } from 'react';
import { MenuTriggerState, OverlayTriggerState, useMenuTriggerState } from 'react-stately';
import { Popover } from './Popover';
import { Link } from 'react-router-dom';

interface DropdownMenuProps {
	renderTriggerElement: (buttonState: MenuTriggerState) => ReactNode;
	buttonWrapperClass?: string;
	menuOptions: DropdownMenu;
	width?: number;
	dropdownOffset?: number;
}

// =========================================================================================
// Menu Section
// =========================================================================================
export function DropdownMenu(props: DropdownMenuProps) {
	// Create state based on the incoming props
	let state = useMenuTriggerState(props as any);

	// Get props for the menu trigger and menu elements
	let ref: any = React.useRef();

	return (
		<div className='relative inline-block'>
			<div
				onClick={(e) => {
					state.isOpen ? state.close() : state.open();
					e.stopPropagation();
				}}
				ref={ref}
				className={`${props.buttonWrapperClass}`}
			>
				{props.renderTriggerElement(state)}
			</div>

			{state.isOpen && (
				<Popover state={state} triggerRef={ref as any} placement='bottom start' offset={props.dropdownOffset}>
					<Menu width={props.width} state={state} {...props} menu={props.menuOptions} />
				</Popover>
			)}
		</div>
	);
}

// =========================================================================================
// Menu
// =========================================================================================
interface MenuProps {
	menu: DropdownMenu;
	state: OverlayTriggerState;
	width?: number;
}

const Menu = (props: MenuProps) => (
	<ul
		className={`shadow-xs min-w-[200px] overflow-hidden rounded-md pb-1 focus:outline-none ${props.menu[0].type === 'section' ? '' : 'pt-1'}`}
		style={{
			width: props.width,
		}}
	>
		{props.menu.map((item, key) => {
			if (item.type === 'button' || item.type === 'link') return <MenuItem state={props.state} key={key} item={item} />;

			return <MenuSection state={props.state} section={item} key={key} />;
		})}
	</ul>
);

// =========================================================================================
// Menu Section
// =========================================================================================
interface MenuSectionProps {
	section: DropdownMenuOptionSection;
	state: OverlayTriggerState;
}

const MenuSection = ({ section, state }: MenuSectionProps) => (
	<>
		<div tabIndex={0} className={`${section.title ? 'border-y border-gray-100 bg-gray-50 px-4 py-1.5 text-xs font-semibold uppercase text-gray-500' : 'mx-2 border-t border-gray-200'} focus:none`}>
			{section.title}
		</div>

		<li>
			<ul>
				{section.options.map((item, index) => (
					<MenuItem state={state} item={item} key={index} />
				))}
			</ul>
		</li>
	</>
);

// =========================================================================================
// Menu Item
// =========================================================================================
interface MenuItemProps {
	item: DropdownMenuOptionLink | DropdownMenuOptionButton;
	state: OverlayTriggerState;
}

function MenuItem({ item, state }: MenuItemProps) {
	const defaultItemStyle = `text-gray-600 relative w-full inline-block cursor-pointer select-none rounded py-2 pl-3 pr-9 text-sm flex items-center gap-4 duration-100 text-ash focus:outline-none hover:bg-primary-200 hover:text-white disabled:opacity-80 ${item.className}`;

	return item.type === 'button' ? (
		<li className='relative my-0.5 w-full px-1'>
			<button
				disabled={item?.disabled}
				onClick={(e) => {
					item.action(e);
					state.close();
				}}
				className={defaultItemStyle}
			>
				{item.icon && <span className='w-5 h-5 shrink-0'>{React.cloneElement(item.icon, { className: '!w-full !h-full' })}</span>}

				{item.text}
			</button>
		</li>
	) : (
		<li className='relative my-0.5 w-full px-1' onClick={() => state.close()}>
			<Link to={item.url} className={defaultItemStyle}>
				{item.icon && <span className='w-5 h-5 shrink-0'>{React.cloneElement(item.icon, { className: '!w-full !h-full' })}</span>}
				{item.text}
			</Link>
		</li>
	);
}
