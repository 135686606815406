import { Outlet } from 'react-router-dom';
import SettingsNavigation from './SettingsNavigation';

const SettingsLayout = () => {
	return (
		<div className='w-full divide-x grid lg:grid-cols-[var(--app-branding-width-lg),1fr] h-[calc(100vh-var(--appbar-height-mobile))] lg:h-[calc(100vh-var(--appbar-height-desktop))]'>
			<div className='hidden lg:block'>
				<SettingsNavigation />
			</div>

			<div className='h-full overflow-y-auto'>
				<Outlet />
			</div>
		</div>
	);
};

export default SettingsLayout;
