import { useState, ReactNode, Fragment } from 'react';
import { Badge, Button, Card, Text, Title } from '@tremor/react';
import Container from 'components/Container';
import { ArrowForwardIcon, DeleteIcon, EditIcon, ViewIcon } from 'components/FormActionIcons';
import { RiFilter2Fill, RiSpeakFill, RiTeamFill } from 'react-icons/ri';
import { BiSolidMessageSquareDots } from 'react-icons/bi';
import { BiFile } from 'react-icons/bi';
import useFetchProspects from './useFetchProspects';
import { IFetchProspectFilters, IFetchProspectsActionParams, IProspect } from './prospectInterfaces';
import { deleteProspect } from './prospectQueries';
import { QueryObserverResult, useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { MODAL_LEAVE_SPEED } from 'config';
import { createColumnHelper } from '@tanstack/react-table';
import { DropdownMenu } from 'components/DropdownMenu';
import { DefaultTableDropDownTrigger } from 'components/TableDropdownTrigger';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import Modal from 'components/Modal';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { formatCurrency, hasPermission, hasPermissions, hasRoles, isAdmin, titleCase } from 'helpers';
import { HiExclamation } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import Table from 'components/Table';
import FollowUpsSection from 'features/followups/FollowUpsSection';
import SlideOver from 'components/SlideOver';
import ScheduleFollowUpForm from 'features/followups/ScheduleFollowUpForm';
import ManageProspectingTeamForm from './team-members/ManageProspectingTeamForm';
import Filters from 'components/Filters';
import BottomSheet from 'components/BottomSheet';
import { FilterComponentTypes } from 'features/settings/settingsInterfaces';
import useDefaultFilters from 'hooks/useDefaultFilters';
import { findFarthestFutureDate, formatDate, formatDateRangePickerValue } from 'helpers/dateHelpers';
import { AxiosResponse } from 'axios';
import UpdateProspectInformationForm from './UpdateProspectInformationForm';
import { initialProspectFormValues, setActiveProspect, setCreateProspectFormValues } from './prospectsSlice';
import { useAppDispatch, useAppSelector } from 'hooks/storeConnect';
import useFetchFollowUps from 'features/followups/useFetchFollowUps';
import { IFetchFollowUpsActionParams } from 'features/followups/followUpInterfaces';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import { DefaultRoles } from 'features/settings/authorization/authorizationInterface';
import { authState } from 'features/auth/authSlice';
import { TiCancelOutline } from 'react-icons/ti';
import { PermissionKeys } from 'features/auth/permissions';
import ContentBlockedFromView from 'components/ContentBlockedFromView';
import useFetchUsers from 'features/settings/users/useFetchUsers';
import { isMarketer } from 'utils/index';

interface IProspectsTableColumns extends IProspect {
	action: ReactNode;
}

const MAX_SHOW_PRODUCTS = 2;
const columnHelper = createColumnHelper<IProspectsTableColumns>();

const ProspectsListPage = () => {
	const { authUser } = useAppSelector(authState);

	const [isFollowUpsSliderOpen, setFollowUpsSlideOpen] = useState<boolean>(false);
	const [isMobileFiltersOpen, setMobileFiltersOpen] = useState<boolean>(false);
	const [isEditProspectFormOpen, setEditProspectFormOpen] = useState(false);
	const [isManageTeamMemberFormOpen, setManageProspectingTeamFormOpen] = useState(false);
	const [isScheduleFollowFormOpen, setScheduleFollowFormOpen] = useState(false);
	const [isConfirmDeleteProspectDialogOpen, setConfirmDeleteProspectDialogOpen] = useState(false);
	const [currentProspect, setCurrentProspect] = useState<IProspect | null>(null);
	const [fetchParams, setFetchParams] = useState<IFetchProspectsActionParams>({
		filterBy: {
			userId: isMarketer() ? authUser?.id : undefined,
		},
		include: ['insurance_products', 'decision_maker', 'team_members', 'user'],
	});
	const { users } = useFetchUsers({});

	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { isProspectsLoading, refetchProspects, prospects, paginationMetadata } = useFetchProspects({
		fetchParams,
		queryKey: ['prospects-list', fetchParams],
	});

	const [followUpFetchParams, setFollowUpFetchParams] = useState<IFetchFollowUpsActionParams>({
		filterBy: {
			userId: isMarketer() ? authUser?.id : undefined,
		},
		include: ['prospects'],
	});

	const { followUps, isFollowUpsLoading, refetchFollowUps } = useFetchFollowUps({
		fetchParams: followUpFetchParams,
		queryKey: followUpFetchParams,
		// enabled: hasPermission(PermissionKeys.VIEW_FOLLOW_UP),
	});

	// ===================
	// * Delete Prospect
	// ===================
	const { isLoading: isDeleteProspectLoading, mutate: deleteProspectMutation } = useMutation({
		mutationKey: ['authorization/delete-prospect'],
		mutationFn: (prospectId: number) => deleteProspect(prospectId),
	});

	const onConfirmDeleteProspect = () => {
		if (!currentProspect) return toast.error('No prospect selected for this action');

		deleteProspectMutation(currentProspect.id, {
			onSuccess: () => {
				toast.success('Prospect deleted successfully.');
				setEditProspectFormOpen(false);
			},
			onError: () => {
				toast.error('Failed to delete prospect.');
			},
			onSettled() {
				setTimeout(() => setCurrentProspect(null), MODAL_LEAVE_SPEED);
				refetchProspects();
			},
		});

		setConfirmDeleteProspectDialogOpen(false);
	};

	const tableColumns = [
		columnHelper.accessor('action', {
			header: 'Actions',
			cell: (info) => {
				const prospect: IProspect = info.row.original;

				const menuOptions = [];

				hasPermission(PermissionKeys.VIEW_PROSPECT) &&
					menuOptions.push({
						type: 'button',
						text: 'View Details',
						action: (e: any) => {
							dispatch(setActiveProspect(prospect));

							navigate(`/app/prospects/${prospect.id}`);
							e.stopPropagation();
						},
						icon: <ViewIcon />,
					});
				// hasPermission(PermissionKeys.VIEW_INTERACTION) &&
				menuOptions.push({
					type: 'button',
					text: 'Interact',
					action: (e: any) => {
						dispatch(setActiveProspect(prospect));
						navigate(`/app/prospects/${prospect.id}/interactions`);
					},
					icon: <RiSpeakFill className='w-6 h-6' />,
				});
				hasPermission(PermissionKeys.UPDATE_PROSPECT) &&
					menuOptions.push({
						type: 'button',
						text: 'Edit Prospect',
						action: (e: any) => {
							setCurrentProspect(prospect);
							setEditProspectFormOpen(true);
							e.stopPropagation();
						},
						icon: <EditIcon variant='solid' />,
					});
				hasPermission(PermissionKeys.CREATE_FOLLOW_UP) &&
					menuOptions.push({
						type: 'button',
						text: 'Schedule Reminder',
						action: (e: any) => {
							setCurrentProspect(prospect);
							setScheduleFollowFormOpen(true);
							e.stopPropagation();
						},
						icon: <BiSolidMessageSquareDots className='w-6 h-6' />,
					});
				hasPermission(PermissionKeys.VIEW_TEAM_MEMBER) &&
					menuOptions.push({
						type: 'button',
						text: 'Manage Team',
						action: (e: any) => {
							setCurrentProspect(prospect);
							setManageProspectingTeamFormOpen(true);
							e.stopPropagation();
						},
						icon: <RiTeamFill className='w-6 h-6' />,
					});
				hasPermission(PermissionKeys.DELETE_PROSPECT) &&
					menuOptions.push({
						type: 'button',
						text: 'Delete Prospect',
						action: (e: any) => {
							setCurrentProspect(prospect);
							setConfirmDeleteProspectDialogOpen(true);
							e.stopPropagation();
						},
						icon: <DeleteIcon variant='solid' />,
					});

				if (menuOptions.length === 0) {
					menuOptions.push({
						type: 'button',
						text: 'NOT AUTHORIZED',
						action: (e: any) => toast.error('You are not authorized perform any action on propects'),
						icon: <TiCancelOutline />,
					});
				}

				return <DropdownMenu renderTriggerElement={(state) => <DefaultTableDropDownTrigger state={state} />} menuOptions={menuOptions as any} />;
			},
		}),
		columnHelper.accessor('company_name', {
			header: 'Company Name',
			cell: ({ row }) => <div className='sm:font-semibold whitespace-nowrap'>{row.original.company_name}</div>,
		}),
		columnHelper.accessor((row) => row.insurance_products, {
			header: 'Expected Premium',
			cell: (info) => <div className='font-semibold text-blue-500'>{formatCurrency(info.row.original.insurance_products.reduce((prevValue, product) => product.expected_premium + prevValue, 0))}</div>,
		}),
		columnHelper.accessor((row) => row.insurance_products, {
			header: 'Achieved Premium',
			cell: (info) => (
				<div className='font-semibold text-green-500'>{formatCurrency(info.row.original.insurance_products.reduce((prevValue, product) => product.achieved_premium + prevValue, 0))}</div>
			),
		}),
		columnHelper.accessor((row) => row.insurance_products, {
			header: 'Achieved Commission',
			cell: (info) => (
				<div className='font-semibold text-orange-500'>
					{formatCurrency(info.row.original.insurance_products.reduce((prevValue, product) => product.achieved_premium / product.commission_rate + prevValue, 0))}
				</div>
			),
		}),
		columnHelper.accessor((row) => row.contact_phone, {
			header: 'Insurance Products',
			cell: ({ row }) => {
				const products = _.uniqBy(row.original.insurance_products, 'insurance_product_type_id');

				const IS_MORE_THAN_PRODUCT_TO_SHOW = products.length > MAX_SHOW_PRODUCTS;

				return (
					<div className='flex items-center divide-x divide-gray-400'>
						{products.length > 0
							? products.slice(0, MAX_SHOW_PRODUCTS).map((product, index) => (
									<div key={index} className='flex px-1 first-of-type:pl-0 last-of-type:pr-0 py-0.5 items-center text-gray-500 gap-2'>
										<Text className='capitalize'>{product?.insurance_product_type?.name}</Text>
									</div>
							  ))
							: 'N/a'}

						{IS_MORE_THAN_PRODUCT_TO_SHOW && (
							<Fragment>
								<Badge
									size='xs'
									className='ml-1 text-black bg-gray-300'
									data-tooltip-id='app-prospect-list-item-tooltip'
									data-tooltip-content={products.map((product) => product.insurance_product_type.name).join(' | ')}
								>
									+{products.length - MAX_SHOW_PRODUCTS}{' '}
								</Badge>
								<Tooltip id={'app-prospect-list-item-tooltip'} place='top' variant='dark' />
							</Fragment>
						)}
					</div>
				);
			},
		}),
		columnHelper.accessor('contact_name', {
			header: 'Contact Person',
			cell: ({ row }) => <div className='sm:font-semibold'>{row.original.contact_name}</div>,
		}),
		columnHelper.accessor('contact_phone', {
			header: 'Contact Phone',
			cell: ({ row }) => (
				<div className='text-blue-600'>
					<a href={`tel:${row.original.contact_phone}`}>{row.original.contact_phone}</a>
				</div>
			),
		}),
		columnHelper.accessor('decision_maker', {
			header: 'Decision Maker',
			cell: ({ row }) => row.original.decision_maker.name,
		}),
		columnHelper.accessor('user', {
			header: 'Created By',
			cell: ({ row }) => {
				const { first_name, middle_name, last_name } = row.original?.user || {};

				if (isMarketer()) {
					return <div className='sm:font-semibold whitespace-nowrap'>You</div>;
				}
				return (
					<div className='sm:font-semibold whitespace-nowrap'>
						{first_name} {middle_name ?? ''} {last_name}
					</div>
				);
			},
			enableHiding: false,
		}),
		columnHelper.accessor('last_interaction_date', {
			header: 'Last Interaction Date',
			cell: ({ row }) => formatDate(row.original.last_interaction_date, 'Jan 31, 1999') || 'N/A',
		}),
		columnHelper.accessor('insurance_products.expected_close_date', {
			header: 'Expected Close Date',
			cell: ({ row }) => {
				const expectedCloseDates = row.original.insurance_products.map((p) => p.expected_close_date);

				return formatDate(findFarthestFutureDate(expectedCloseDates) as string, 'Jan 31, 1999');
			},
		}),
	];

	const resetCurrentProspect = async () => {
		const prospectsResponse: QueryObserverResult = await refetchProspects();
		const axiosResponse: AxiosResponse = prospectsResponse?.data as any;
		const prospects: IProspect[] | undefined = axiosResponse?.data?.data;

		if (!prospects) return;

		const thisProspect = prospects.find((user) => user.id === currentProspect?.id);
		setCurrentProspect(thisProspect as any);
	};

	const onSubmitFilters = (filters: IFetchProspectFilters) => {
		let selectedDateRange: any;

		if (filters.dateRange && filters.dateType) {
			selectedDateRange = { [filters.dateType]: formatDateRangePickerValue(filters.dateRange as any) };
		} else {
			selectedDateRange = {};
		}

		const prevFetchFilters: any = {
			...fetchParams.filterBy,
		};
		delete prevFetchFilters.createdAt;
		delete prevFetchFilters.lastInteractionDate;
		delete prevFetchFilters.contactDate;
		delete prevFetchFilters.createdBetween;

		setFetchParams((prevValue) => ({
			...prevValue,
			filterBy: {
				...prevFetchFilters,
				...filters,
				...selectedDateRange,
			},
		}));
	};

	return (
		<Container className='pb-4 lg:pb-0 lg:pl-6 lg:pr-0 !h-[100%]'>
			<div className='lg:grid lg:grid-cols-[1fr,320px] lg:divide-x h-[100%] overflow-hidden'>
				{/*======================== 
            Main Content
         ========================*/}
				<div className='h-full pl-px overflow-y-auto lg:pr-6 with-scrollbar'>
					<div className='flex items-center justify-between py-4 border-b lg:border-none lg:pb-0'>
						<Title className='!text-2xl'>Prospects</Title>

						{hasPermission(PermissionKeys.VIEW_FOLLOW_UP) && (
							<button
								onClick={() => {
									setFollowUpsSlideOpen(true);
								}}
								type='button'
								title='Reminders'
								className='flex items-center gap-2 text-base font-semibold duration-300 lg:text-sm lg:hidden hover:text-primary-500'
							>
								Reminders <ArrowForwardIcon className='w-6 h-5' />
							</button>
						)}

						{hasPermission(PermissionKeys.CREATE_PROSPECT) && (
							<Button
								className='!rounded-full hidden lg:inline-flex'
								icon={BiFile}
								onClick={() => {
									dispatch(setCreateProspectFormValues(initialProspectFormValues as any));

									navigate('/app/prospects/create/company-information');
								}}
							>
								Create Prospect
							</Button>
						)}
					</div>

					<div className='flex items-center justify-between w-full gap-4 mt-6'>
						{hasPermission(PermissionKeys.VIEW_PROSPECT) && (
							<Button
								color='gray'
								variant='secondary'
								onClick={() => setMobileFiltersOpen(true)}
								icon={RiFilter2Fill}
								className='!rounded-full hover:bg-gray-700 hover:!text-gray-100 duration-300 lg:hidden'
							>
								Filters
							</Button>
						)}

						{hasPermission(PermissionKeys.VIEW_PROSPECT) && (
							<div className='hidden lg:flex lg:items-end'>
								<Filters
									filters={[
										{
											type: FilterComponentTypes.TextInput,
											name: 'companyName',
											label: 'Company Name',
											placeholder: 'Company name',
										},
										{
											type: FilterComponentTypes.DateRangeWithSelect,
											label: 'Select Date',
											dateRange: {
												label: 'Date type',
												name: 'dateRange',
												placeholder: 'Select dates',
												type: FilterComponentTypes.DateRange,
											},
											select: {
												label: 'Date type',
												name: 'dateType',
												type: FilterComponentTypes.Select,
												placeholder: 'Select type',
												dropdownOptions: [
													{
														id: 1,
														label: 'Date Created',
														value: 'createdBetween',
													},
													{
														id: 2,
														label: 'Last Interaction Date',
														value: 'lastInteractionDate',
													},
													{
														id: 3,
														label: 'Contact Date',
														value: 'contactDate',
													},
												],
											},
										},
										{
											type: FilterComponentTypes.Select,
											name: 'userId',
											label: 'Staff',
											dropdownOptions:
												users
													?.filter((user) => {
														// return user.roles.includes(DefaultRoles.Marketer) || user.roles.includes(DefaultRoles.MarketingManager);
														return hasPermissions([PermissionKeys.CREATE_PROSPECT]) && !user.roles.includes(DefaultRoles.SuperAdmin);
													})
													.map((user: any) => ({
														id: user.id,
														label: `${user.first_name} ${user?.middle_name ?? ' '} ${user.last_name}`,
														value: user.id,
													})) ?? [],
											placeholder: 'Select staff',
											permission: PermissionKeys.VIEW_USER_FILTERS,
										},
									]}
									submitButton={{
										label: 'Apply Filters',
										onSubmit: onSubmitFilters,
									}}
								/>
							</div>
						)}

						{hasPermission(PermissionKeys.CREATE_PROSPECT) && (
							<Button className='!rounded-full lg:hidden' icon={BiFile} onClick={() => navigate('/app/prospects/create/company-information')}>
								Create Prospect
							</Button>
						)}
					</div>

					<div>
						{hasPermission(PermissionKeys.VIEW_PROSPECT) ? (
							<Fragment>
								{!prospects && isProspectsLoading ? (
									<div className='mt-6 !shadow-none !border-0'>
										<ContentLoadingCardSpinner message='Loading prospects...' />
									</div>
								) : prospects && prospects.length > 0 ? (
									<div className='mt-8'>
										<Card className='!p-0 pt-6 overflow-hidden'>
											<Title className='px-4 py-4 border-b'>All Prospects</Title>

											<Table
												data={prospects}
												columns={tableColumns}
												pagination={{
													handlePageChange: (page: number) => {
														setFetchParams((prevValue) => ({
															...prevValue,
															page,
														}));
													},
													metadata: paginationMetadata,
												}}
											/>
										</Card>
									</div>
								) : (
									<SimpleEmptyResourceIndicator containerClassName='lg:mt-8 lg:!py-10' message='No prospect found' />
								)}
							</Fragment>
						) : (
							<ContentBlockedFromView message='You are not authorized to view prospects.' containerClassName='min-h-[350px]' />
						)}
					</div>
				</div>

				{/*================ 
            Follow Ups
        ==================*/}
				<div className='h-[100%] overflow-hidden hidden lg:block'>
					<FollowUpsSection
						setFetchParams={setFollowUpFetchParams}
						fetchParams={followUpFetchParams}
						followUps={followUps}
						isFollowUpsLoading={isFollowUpsLoading}
						refetchFollowUps={refetchFollowUps}
					/>
				</div>
			</div>

			<BottomSheet
				isOpen={isMobileFiltersOpen}
				setOpen={setMobileFiltersOpen}
				closeOnOutSideClick
				header={{
					text: 'Filter Prospects',
				}}
			>
				<Filters
					filters={[
						{
							type: FilterComponentTypes.TextInput,
							name: 'companyName',
							label: 'Company Name',
							placeholder: 'Company name',
						},
						{
							type: FilterComponentTypes.DateRangeWithSelect,
							label: 'Select Date',
							dateRange: {
								label: 'Date type',
								name: 'dateRange',
								placeholder: 'Select dates',
								type: FilterComponentTypes.DateRange,
							},
							select: {
								label: 'Date type',
								name: 'dateType',
								type: FilterComponentTypes.Select,
								placeholder: 'Select type',
								dropdownOptions: [
									{
										id: 1,
										label: 'Date Created',
										value: 'createdBetween',
									},
									{
										id: 2,
										label: 'Last Interaction Date',
										value: 'lastInteractionDate',
									},
									{
										id: 3,
										label: 'Contact Date',
										value: 'contactDate',
									},
								],
							},
						},
						{
							type: FilterComponentTypes.Select,
							name: 'userId',
							label: 'Staff',
							hideLabel: true,
							dropdownOptions:
								users
									?.filter((user) => {
										// return user.roles.includes(DefaultRoles.Marketer) || user.roles.includes(DefaultRoles.MarketingManager)
										return hasPermissions([PermissionKeys.CREATE_PROSPECT]) && !user.roles.includes(DefaultRoles.SuperAdmin);
									})
									.map((user: any) => ({
										id: user.id,
										label: `${user.first_name} ${user?.middle_name ?? ' '} ${user.last_name}`,
										value: user.id,
									})) ?? [],
							placeholder: 'Select staff',
							permission: PermissionKeys.VIEW_USER_FILTERS,
						},
					]}
					submitButton={{
						label: 'Apply Filters',
						onSubmit: onSubmitFilters,
					}}
				/>
			</BottomSheet>

			<Modal
				width={500}
				closeButton={{ styles: 'bg-gray-200' }}
				isModalOpen={isScheduleFollowFormOpen}
				setModalOpen={setScheduleFollowFormOpen}
				onCloseModal={() => {
					setScheduleFollowFormOpen(false);
					setTimeout(() => setCurrentProspect(null), 500);
				}}
			>
				<ScheduleFollowUpForm
					prospect={currentProspect as IProspect}
					afterEdit={() => {
						refetchFollowUps();
						setScheduleFollowFormOpen(false);
						setTimeout(() => setCurrentProspect(null), 500);
					}}
				/>
			</Modal>

			<SlideOver
				title={{
					text: currentProspect?.company_name,
					theme: 'dark',
					styles: 'text-gray-200',
				}}
				subTitle={{
					text: 'Edit prospect information',
					styles: 'text-gray-300',
				}}
				onClose={() => setEditProspectFormOpen(false)}
				open={isEditProspectFormOpen}
				setOpen={setEditProspectFormOpen}
				width={750}
				isScrollable={false}
				afterClose={() => null}
				shouldCloseOnOutsideClick={false}
			>
				<UpdateProspectInformationForm
					prospect={currentProspect as IProspect}
					afterUpdate={() => {
						refetchProspects();
						setEditProspectFormOpen(false);
						setTimeout(() => setCurrentProspect(null), 700);
					}}
				/>
			</SlideOver>

			<SlideOver
				title={{
					text: 'Prospecting Team',
					theme: 'primary',
					styles: 'text-gray-100',
				}}
				subTitle={{
					text: `Manage ${currentProspect?.company_name ?? ''} prospecting team`,
					styles: 'text-gray-200',
				}}
				onClose={() => setManageProspectingTeamFormOpen(false)}
				open={isManageTeamMemberFormOpen}
				setOpen={setManageProspectingTeamFormOpen}
				width={450}
				isScrollable
				afterClose={() => null}
				shouldCloseOnOutsideClick={false}
			>
				<ManageProspectingTeamForm
					prospect={currentProspect as IProspect}
					afterChange={async () => {
						await resetCurrentProspect();
					}}
				/>
			</SlideOver>

			<SlideOver onClose={() => null} open={isFollowUpsSliderOpen} setOpen={setFollowUpsSlideOpen} width={600} isScrollable afterClose={() => null} shouldCloseOnOutsideClick={true}>
				<FollowUpsSection setFetchParams={setFollowUpFetchParams} fetchParams={followUpFetchParams} followUps={followUps} isFollowUpsLoading={isFollowUpsLoading} refetchFollowUps={refetchFollowUps} />
			</SlideOver>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmDeleteProspectDialogOpen}
				setOpen={setConfirmDeleteProspectDialogOpen}
				message='Delete Prospect'
				subMessage={`Are you sure you want to delete ${titleCase(currentProspect?.company_name as string) ?? 'this prospect'}? This action cannot be reversed.`}
				onClose={() => {
					setTimeout(() => setCurrentProspect(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmDeleteProspect,
					label: 'Delete',
					isLoading: isDeleteProspectLoading,
					loadingText: 'Deleting',
					color: 'red',
				}}
				cancelButton={{
					action: () => setConfirmDeleteProspectDialogOpen(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-500/10`}>
						<HiExclamation className='w-6 h-6 text-red-500' aria-hidden='true' />
					</div>
				)}
			/>
		</Container>
	);
};

export default ProspectsListPage;
