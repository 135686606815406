import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';

import authSlice from 'features/auth/authSlice';
import prospectsSlice from 'features/prospects/prospectsSlice';
import settingsSlice from 'features/settings/settingsSlice';
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import SliceNames from 'store/sliceNames';

const { Auth, Settings, Prospects } = SliceNames;

const persistConfig = {
	key: 'root',
	storage,
	blacklist: [Prospects],
	whitelist: [Auth, Settings],
};

const rootReducer = combineReducers({
	auth: authSlice,
	settings: settingsSlice,
	prospects: prospectsSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== 'production',
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
export const persistor = persistStore(store);
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
