import { IBranch } from 'features/settings/branches/branchInterfaces';
import { IDepartment } from 'features/settings/departments/departmentInterfaces';
import { PermissionKeys } from './permissions';

export interface IAuthState {
	token: string | null;
	authUser: IUser | null;
	isAuthenticated: boolean;
	isNewUser: boolean;
	isUserBlocked: boolean;
	users: IUser[] | null;
	authError: any;
}
export interface ILoginFormTypes {
	email: string;
	password: string;
}
export interface IForgotPasswordFormTypes {
	email: string;
}
export interface IForgotPasswordActionTypes {}
export interface IResetPasswordFormTypes {
	password: string;
	confirm_password: string;
}
export interface IResetPasswordFormTypes {
	email: string;
	password: string;
	token?: string;
}

export interface ILoginActionParams extends ILoginFormTypes {}
export interface ILoginActionResponse {
	status: boolean;
	message: string;
	data: {
		user: ILoginActionUserData;
		token: string;
	};
}
export interface ILoginActionUserData {
	id: number;
	first_name: string;
	middle_name: null;
	last_name: string;
	new_user: UserDurationStatus;
	status: UserAccountState;
	email: string;
}

export interface IUserProfileFormValues {
	first_name: string;
	middle_name: string;
	last_name: string;
	email: string;
	department: string;
	branch: string;
	yearly_target: number;
}

export interface IFetchUserProfileActionParams {}

export interface IChangeProfilePasswordFormTypes {
	old_password: string;
	new_password: string;
	confirm_new_password: string;
}

export interface IChangePasswordActionParams extends IChangeProfilePasswordFormTypes {}

// ===============
// User Accounts
// ===============
export interface IUser {
	id: number;
	first_name: string;
	middle_name: string;
	last_name: string;
	status: UserAccountState;
	new_user: UserDurationStatus;
	email: string;
	roles: string[];
	created_at: Date;
	updated_at: Date;
	yearly_targets: IYearlyTarget[];
	department: IDepartment;
	permissions: PermissionKeys[];
	branch: IBranch;
}
export enum UserAccountState {
	Enabled = 'Enabled',
	Disabled = 'Disabled',
}
export enum UserDurationStatus {
	New = 1,
	Old = 0,
}

export interface ICreateUserFormValues {
	first_name: string;
	middle_name: string;
	last_name: string;
	email: string;
	branch_id: number;
	status: UserAccountState;
	department_id: number;
	yearly_target: number;
	role: string;
}

export interface IYearlyTarget {
	id: number;
	user_id: number;
	yearly_target: number;
	created_at: Date;
	updated_at: Date;
}

export interface ICreateUserTargetActionParams {
	user_id: number;
	yearly_target: number;
}

export interface IFetchUserFilters {
	name?: string;
	roleId?: number;
}
export type IFetchUserIncludes = 'branch' | 'department' | 'targets';
export interface IFetchUserAccountsActionParams {
	filterBy?: IFetchUserFilters;
	includes?: IFetchUserIncludes[];
	page?: number;
}
