import { AppNavigationMenuItem, AppNavigationSubMenuButton, AppNavigationSubMenuItem } from 'features/settings/settingsInterfaces';
import { NavLink, useLocation } from 'react-router-dom';

interface Props {
	menu: AppNavigationSubMenuItem[];
	activeAppMenuOption: AppNavigationMenuItem;
	onLinkSelected: (item: AppNavigationMenuItem) => void;
	countMenusAndSubmenus: (menu: AppNavigationSubMenuItem[]) => number;
}

const SubMenu = (props: Props) => {
	const { menu, activeAppMenuOption, countMenusAndSubmenus, onLinkSelected } = props;
	const { pathname } = useLocation();

	return (
		<ul
			style={{
				maxHeight: `${countMenusAndSubmenus(menu) * 40}px`,
			}}
			className='relative block my-1 ml-7 before:absolute before:h-full before:w-0 before:border-l before:border-gray-600/30'
		>
			{menu.map((link, index) => {
				const { name, menu, url, type, disabled } = link || {};

				if (disabled) return '';

				if (type === 'link')
					return (
						<li key={index} className='group/submenu'>
							<NavLink to={url as string} onClick={() => onLinkSelected(activeAppMenuOption)} key={index} className={'inline-block w-full'}>
								{({ isActive }) => (
									<span
										className={`${
											pathname === url ? '!bg-gray-600/70 !text-white' : ''
										} relative inline-block w-full py-1.5 pl-4 text-[13px] text-gray-600/80 duration-200 before:absolute before:inset-y-0 before:left-0 before:w-0 before:border-l before:border-l-transparent group-hover/submenu:text-primary-500 group-hover/submenu:before:border-primary-500`}
									>
										{name}
									</span>
								)}
							</NavLink>

							{menu && <SubMenu menu={menu} activeAppMenuOption={activeAppMenuOption} onLinkSelected={onLinkSelected} countMenusAndSubmenus={countMenusAndSubmenus} />}
						</li>
					);

				const buttonLink: AppNavigationSubMenuButton = link as any;
				return (
					<button key={index} className='group/submenu' onClick={buttonLink?.action}>
						<span
							className={`${
								pathname === url ? '!bg-gray-600/70 !text-white' : ''
							} relative inline-block w-full py-1.5 pl-4 text-[13px] text-gray-600/80 duration-200 before:absolute before:inset-y-0 before:left-0 before:w-0 before:border-l before:border-l-transparent group-hover/submenu:text-primary-500 group-hover/submenu:before:border-primary-500`}
						>
							{name}
						</span>
					</button>
				);
			})}
		</ul>
	);
};

export default SubMenu;
