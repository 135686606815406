import { Navigate, Outlet, useLocation } from 'react-router-dom';

const ProspectsLayout = () => {
	const { pathname } = useLocation();

	if (pathname === '/app/prospects') return <Navigate to={'/app/prospects/list'} />;

	return (
		<div className='bg-gray-50 w-full overflow-y-auto h-[calc(100vh-var(--appbar-height-mobile)-var(--app-navigation-height))] sm:h-[calc(100vh-var(--appbar-height-desktop)-var(--app-navigation-height))]'>
			<Outlet />
		</div>
	);
};

export default ProspectsLayout;
