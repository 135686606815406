import Container from 'components/Container';
import { Fragment } from 'react';
import { Card, List, ListItem, Text, Title } from '@tremor/react';
import useFetchUserProfile from 'features/auth/useFetchUserProfile';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';

const ProfileSettingsPage = () => {
	const { isUserProfileLoading, user } = useFetchUserProfile({});

	return (
		<Container className='px-4 pt-4 pb-8 overflow-y-auto '>
			<Title className='mb-4 !text-xl'>Profile Information</Title>

			{!user && isUserProfileLoading ? (
				<Fragment>
					<ContentLoadingCardSpinner message='Please wait while we load your profile...' />
				</Fragment>
			) : (
				<div className='flex flex-col lg:grid lg:grid-cols-[400px,1fr] xl:grid-cols-[500px,1fr] gap-8'>
					<Card className='rounded-xl !p-0 overflow-hidden'>
						<Title className='flex items-center justify-between px-4 py-3 bg-gray-100 border-b'>Personal Data</Title>

						<List>
							{/* Name*/}
							<ListItem className='p-4'>
								<div className='grid w-full space-y-2'>
									<h3 className='text-sm font-medium text-black uppercase'>Name</h3>

									{user?.first_name && (
										<div className='flex items-center justify-between !w-full'>
											<span className='text-sm font-medium text-gray-800'>First Name</span>
											<Text className='!text-base'>{user?.first_name || 'N/A'}</Text>
										</div>
									)}
									{user?.middle_name && (
										<div className='flex items-center justify-between'>
											<span className='text-sm font-medium text-gray-800'>Middle Name</span>
											<Text className='!text-base'>{user?.middle_name || 'N/A'}</Text>
										</div>
									)}

									{user?.last_name && (
										<div className='flex items-center justify-between'>
											<span className='text-sm font-medium text-gray-800'>Last Name</span>
											<Text className='!text-base'>{user?.last_name || 'N/A'}</Text>
										</div>
									)}
								</div>
							</ListItem>
						</List>
					</Card>

					<Card className='rounded-xl !p-0  overflow-hidden'>
						<Title className='flex items-center justify-between px-4 py-3 bg-gray-100 border-b'>Organizational Data</Title>

						<List>
							{/* Organizational Data*/}
							<ListItem className='p-4'>
								<div className='grid w-full space-y-2'>
									<h3 className='text-sm font-medium text-black uppercase'>Branch</h3>

									{user?.branch?.name && (
										<div className='flex items-center justify-between'>
											<span className='text-sm font-medium text-gray-800'>Branch Name</span>
											<Text className='!text-base'>{user?.branch.name || 'N/A'}</Text>
										</div>
									)}
									{user?.branch?.code && (
										<div className='flex items-center justify-between'>
											<span className='text-sm font-medium text-gray-800'>Branch Code</span>
											<Text className='!text-base'>{user?.branch.code || 'N/A'}</Text>
										</div>
									)}
								</div>
							</ListItem>

							<ListItem className='p-4'>
								<div className='grid w-full space-y-2'>
									<h3 className='text-sm font-medium text-black uppercase'>Department</h3>

									{user?.department?.name && (
										<div className='flex items-center justify-between'>
											<span className='text-sm font-medium text-gray-800'>Department Name</span>
											<Text className='!text-base'>{user?.department.name || 'N/A'}</Text>
										</div>
									)}
									{user?.department.code && (
										<div className='flex items-center justify-between'>
											<span className='text-sm font-medium text-gray-800'>Department Code</span>
											<Text className='!text-base'>{user?.department.code || 'N/A'}</Text>
										</div>
									)}
								</div>
							</ListItem>

							{/* Roles */}
							<ListItem className='p-4'>
								{user?.roles && (
									<div className='grid w-full space-y-2'>
										<h3 className='text-xs font-medium text-black uppercase'>Assigned Roles</h3>
										<div className='flex flex-wrap justify-start mt-2 gap-x-6 gap-y-2'>
											{user?.roles.map((role, index) => (
												<div key={index} className='flex items-center text-gray-500 gap-2 before:inline-block before:w-1.5 before:h-1.5 before:rounded-full before:bg-gray-400'>
													<Text className='capitalize'>{role}</Text>
												</div>
											))}
										</div>
									</div>
								)}
							</ListItem>
						</List>
					</Card>
				</div>
			)}
		</Container>
	);
};

export default ProfileSettingsPage;
