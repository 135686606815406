import AppBranding from './AppBranding';
import AppbarProfileMenu from './AppbarProfileMenu';
import { DropdownMenu } from '../DropdownMenu';
import { BiLogOutCircle } from 'react-icons/bi';
import useProfileMenu from './AppbarProfileMenu/useProfileMenu';
import { RiMenu2Line } from 'react-icons/ri';
import { Tooltip } from 'react-tooltip';
import DesktopNavigationMenu from './DesktopNavigationMenu';
import { isNewUser } from 'helpers/index';

interface Props {
	isMenuOpen: boolean;
	setMenuOpen: (val: boolean) => void;
}

const Appbar = (props: Props) => {
	const { isMenuOpen, setMenuOpen } = props;
	const profileOptions = useProfileMenu({
		addIconToOptions: [{ optionText: 'logout', icon: <BiLogOutCircle /> }],
	});

	return (
		<div className='flex justify-between w-full h-full shadow bg-gray-50'>
			<div className='flex items-center h-full gap-2 sm:gap-0'>
				<AppBranding />

				{/* SM: Mobile SideMenu Toggler */}
				<button
					type='button'
					title='Toggle Mobile Menu'
					onClick={() => setMenuOpen(!isMenuOpen)}
					className='items-center justify-center hidden w-8 h-8 ml-2 text-gray-500 duration-300 rounded lg:hidden sm:flex hover:bg-blue-50 hover:text-primary-400'
					data-tooltip-id='app-section-tooltip'
					data-tooltip-content={isMenuOpen ? 'Hide Side Menu' : 'Show Side Menu'}
				>
					<RiMenu2Line className={`h-6 w-6 ${isMenuOpen ? '-scale-x-100' : ''}`} />
				</button>

				{!isNewUser() && <DesktopNavigationMenu />}

				<Tooltip id={'app-section-tooltip'} place='bottom' variant='dark' />
			</div>

			<div className='flex items-center justify-end gap-4 pr-2'>
				{/* <AppbarNotifications /> */}

				<DropdownMenu menuOptions={profileOptions} width={300} renderTriggerElement={(state) => <AppbarProfileMenu triggerButtonState={state} />} dropdownOffset={10} />
			</div>
		</div>
	);
};

export default Appbar;
