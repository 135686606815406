import { IProspectsBySaleStage, IReport } from 'features/reports/reportInterfaces';
import { useAppSelector } from 'hooks/storeConnect';
import { authState } from 'features/auth/authSlice';
import StatisticsCardGroup from 'components/StatisticsCard/StatisticsCardGroup';
import StatisticsCardItem from 'components/StatisticsCard/StatisticsCardItem';
import { Text, Title, Card } from '@tremor/react';
import { RiLoader4Line } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import useFetchSalesStages from 'features/settings/prospecting/useFetchSalesStages';

interface Props {
	isReportLoading: boolean;
	prospectsBySalesStage: IReport['prospects_by_sales_stage'] | undefined;
}

const SalesStageAnalytics = ({ isReportLoading, prospectsBySalesStage }: Props) => {
	const navigate = useNavigate();
	const { salesStages } = useFetchSalesStages({});
	const { authUser } = useAppSelector(authState);
	if (!authUser) return <div></div>;

	return (
		<div>
			<Title className='mb-2 !text-base'>Prospect Sales Stages</Title>

			{isReportLoading ? (
				<Card className='flex flex-col items-center justify-center gap-4 p-10'>
					<RiLoader4Line className='w-8 h-8 text-lg text-gray-400 animate animate-spin ' />
					<Text className='!text-base'>Loading sales stages...</Text>
				</Card>
			) : prospectsBySalesStage && prospectsBySalesStage.length > 0 ? (
				<div className=''>
					<StatisticsCardGroup alt>
						{prospectsBySalesStage.map((stage: IProspectsBySaleStage, index: number) => {
							const saleStageId: any = salesStages?.find((s) => s.name.toLowerCase().includes(stage.name.toLowerCase()))?.id ?? '';

							return (
								<StatisticsCardItem
									alt
									action={{
										onClick: () => {
											navigate(`/app/reports/sales-stages?saleStageId=${saleStageId}`);
										},
									}}
									key={index}
									title={stage.name}
									metric={stage?.count.toString()}
								/>
							);
						})}
					</StatisticsCardGroup>
				</div>
			) : (
				<Card className='flex items-center justify-center w-full h-full'>
					<Text className={'!text-base font-semibold'}>No sale stage found</Text>
				</Card>
			)}
		</div>
	);
};

export default SalesStageAnalytics;
