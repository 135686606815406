import { DateRangePickerValue } from '@tremor/react';
import { format, formatDistanceToNow, isAfter, isBefore, isToday, parseISO } from 'date-fns';
import _ from 'lodash';

export const findFarthestFutureDate = (dates: string[]): string | null => {
	if (dates.length === 0) return null;

	let farthestDate = parseISO(dates[0]);

	for (let i = 1; i < dates.length; i++) {
		const currentDate = parseISO(dates[i]);

		if (isAfter(currentDate, farthestDate)) {
			farthestDate = currentDate;
		}
	}

	return farthestDate.toISOString(); // Convert to string if needed
};

export const formatDate = (date: Date | string | undefined | null, template: DateFormats): string => {
	if (!date) return '';

	let formatStr;

	switch (template) {
		case '1999/12/31':
			formatStr = 'yyyy/MM/dd';
			break;
		case '31/12/1999':
			formatStr = 'dd/MM/yyyy';
			break;
		case '1999-12-31':
			formatStr = 'yyyy-MM-dd';
			break;
		case '1999-12-31T11:00':
			formatStr = 'yyyy-MM-dd HH:mm';
			break;
		case '31-12-1999':
			formatStr = 'dd-MM-yyyy';
			break;
		case 'Jan 31, 1999':
			formatStr = 'PP';
			break;
		case 'January 31st, 1999':
			formatStr = 'PPP';
			break;
		case 'Mon, 31st Jan 1999 at 12:00AM':
			formatStr = 'EEE, do MMM yyyy | h:mmaa';
			break;
		case '01/31/1999, 12:00 AM':
			formatStr = 'Pp';
			break;
	}

	return format(typeof date === 'object' ? date : new Date(date), formatStr);
};

export const formatDateRangePickerValue = (range?: DateRangePickerValue | null): string | undefined => {
	if (!range || !range.from) return undefined;

	const from = formatDate(range?.from, '1999-12-31');
	const to = formatDate(range?.to, '1999-12-31');

	return `${from},${to}`;
};

export const getUniqueDates = (values: string[]): string[] =>
	_.uniqBy(values, function (date) {
		return date;
	});

export const formatDateComparison = (
	targetDate: string
): {
	formattedDuration: string;
	durationType: 'future' | 'now' | 'past';
} => {
	const parsedDate = parseISO(targetDate);
	const today = new Date();

	if (isToday(parsedDate)) {
		return {
			durationType: 'now',
			formattedDuration: 'today',
		};
	}

	const distanceToNow = formatDistanceToNow(parsedDate, { addSuffix: true });

	if (isBefore(parsedDate, today)) {
		return {
			durationType: 'past',
			formattedDuration: distanceToNow,
		};
	}

	return {
		durationType: 'future',
		formattedDuration: distanceToNow,
	};
};

export const convertDateRangeFilterToEnglish = (dateRange: string | null | undefined) => {
	if (!dateRange) return 'No Date';

	const uniqueDates = getUniqueDates(dateRange.split(','));

	return !uniqueDates
		? 'No Date'
		: uniqueDates?.length === 1
		? format(new Date(uniqueDates[0]), 'do MMM yyyy')
		: `${format(new Date(uniqueDates[0]), 'do MMM yyyy HH:mm:ss')} - ${format(new Date(uniqueDates[1]), 'do MMM yyyy HH:mm:ss')}`;
};
