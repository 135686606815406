import { authState } from 'features/auth/authSlice';
import { useAppSelector } from 'hooks/storeConnect';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const RootLayout = () => {
	const { isAuthenticated } = useAppSelector(authState);
	const { pathname } = useLocation();

	if (pathname === '/' && !isAuthenticated) return <Navigate to='/auth/login' />;
	else if (pathname === '/' && isAuthenticated) return <Navigate to='/app/dashboard' />;
	return <Outlet />;
};

export default RootLayout;
