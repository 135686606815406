import { Badge, Tab, TabGroup, TabList, TabPanel, TabPanels, Text } from '@tremor/react';
import { Fragment } from 'react';
import { buildURLFilters, getFollowUpState } from 'utils/index';
import { Tooltip } from 'react-tooltip';
import { formatDate, formatDateComparison } from 'helpers/dateHelpers';
import { createColumnHelper } from '@tanstack/react-table';
import { IFollowUp } from 'features/followups/followUpInterfaces';
import Table from 'components/Table';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import { useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { FollowUpCategories, FollowUpCategoriesIndexes } from './reportInterfaces';

interface Props {
	followUps: IFollowUp[] | undefined;
	pendingFollowUps: IFollowUp[] | undefined;
	completedFollowUps: IFollowUp[] | undefined;
	uncompletedFollowUps: IFollowUp[] | undefined;
	isFollowUpsLoading: boolean;
	selectedCategoryIndex: FollowUpCategoriesIndexes;
	setSelectedCategoryIndex: (index: FollowUpCategoriesIndexes) => void;
	getNumberOfFollowUps: (selectedCategoryIndex: number) => number;
}

const columnHelper = createColumnHelper<IFollowUp>();

const FollowUpsReportTable = (props: Props) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const { followUps, isFollowUpsLoading, selectedCategoryIndex, setSelectedCategoryIndex, completedFollowUps, pendingFollowUps, uncompletedFollowUps, getNumberOfFollowUps } = props;

	const tableColumns = [
		columnHelper.accessor('prospect', {
			header: 'Company Name',
			cell: ({ row }) => <div className='sm:font-semibold whitespace-nowrap'>{row.original.prospect.company_name}</div>,
		}),
		columnHelper.accessor('user', {
			header: 'Staff',
			cell: ({ row }) => {
				const { first_name, middle_name, last_name } = row.original.user;

				return (
					<div className='sm:font-semibold whitespace-nowrap text-slate-500'>
						{first_name} {middle_name || ''} {last_name}
					</div>
				);
			},
		}),
		columnHelper.accessor('sales_stage', {
			header: 'Sale Stage',
			cell: ({ row }) => <div className='capitalize whitespace-nowrap'>{row.original.sales_stage.name}</div>,
		}),
		columnHelper.accessor('prospect.insurance_products', {
			header: 'Insurance Products',
			cell: ({ row }) => {
				const products = row.original.prospect.insurance_products;

				const MAX_SHOW_PRODUCTS = 2;
				const IS_MORE_THAN_PRODUCT_TO_SHOW = row.original.prospect.insurance_products.length > MAX_SHOW_PRODUCTS;

				return (
					<span className='flex items-center divide-x'>
						{/* Insurance Products */}
						{products.length > 0
							? products.slice(0, MAX_SHOW_PRODUCTS).map((product, index) => (
									<span key={index} className={`flex px-1 first-of-type:pl-0 py-0 items-center text-gray-500 gap-2 ${IS_MORE_THAN_PRODUCT_TO_SHOW ? '' : 'last-of-type:pr-0'}`}>
										<Text className='capitalize'>{product.insurance_product_type.name}</Text>
									</span>
							  ))
							: 'N/a'}

						{IS_MORE_THAN_PRODUCT_TO_SHOW && (
							<Fragment>
								<Badge
									size='xs'
									className='ml-1 text-black bg-gray-300'
									data-tooltip-id='app-follow-products-tooltip'
									data-tooltip-content={products.map((product) => product.insurance_product_type.name).join(' | ')}
								>
									+{products.length - MAX_SHOW_PRODUCTS}{' '}
								</Badge>
								<Tooltip id={'app-follow-products-tooltip'} place='left' variant='dark' />
							</Fragment>
						)}
					</span>
				);
			},
		}),
		columnHelper.accessor('user', {
			header: 'Status',
			cell: ({ row }) => {
				const { isCompleted, isUncompleted } = getFollowUpState(row.original);

				return (
					<div>
						{isUncompleted ? (
							<Badge size='xl' className='ml-1 text-white bg-red-600'>
								Unfulfilled
							</Badge>
						) : isCompleted ? (
							<Badge size='xl' className='ml-1 text-white bg-green-600'>
								Completed
							</Badge>
						) : (
							<Badge size='xl' className='ml-1 text-white bg-yellow-600'>
								Pending
							</Badge>
						)}
					</div>
				);
			},
		}),
		columnHelper.accessor('follow_up_date', {
			header: 'Follow Up Date',
			cell: ({ row }) => {
				const { follow_up_date } = row.original;
				const { formattedDuration } = formatDateComparison(follow_up_date);

				return (
					<div className='text-gray-500 whitespace-nowrap'>
						{formatDate(follow_up_date, 'Jan 31, 1999')} | <span className='font-medium text-cyan-700'>{formattedDuration.charAt(0).toUpperCase() + formattedDuration.slice(1)}</span>
					</div>
				);
			},
		}),
		columnHelper.accessor('prospect.last_interaction_date', {
			header: 'Last Interaction Date',
			cell: ({ row }) => {
				const { prospect } = row.original;
				const { formattedDuration } = formatDateComparison(prospect.last_interaction_date);

				return (
					<div className='text-gray-500 whitespace-nowrap'>
						{formatDate(prospect.last_interaction_date, 'Jan 31, 1999')} | <span className='font-medium text-cyan-700'>{formattedDuration.charAt(0).toUpperCase() + formattedDuration.slice(1)}</span>
					</div>
				);
			},
		}),
	];

	const onTabChange = (index: FollowUpCategoriesIndexes) => {
		let tabName;

		switch (index) {
			case FollowUpCategoriesIndexes.All:
				tabName = FollowUpCategories.All;
				break;
			case FollowUpCategoriesIndexes.Pending:
				tabName = FollowUpCategories.Pending;
				break;
			case FollowUpCategoriesIndexes.Completed:
				tabName = FollowUpCategories.Completed;
				break;
			case FollowUpCategoriesIndexes.Uncompleted:
				tabName = FollowUpCategories.Uncompleted;
				break;
			default:
				tabName = FollowUpCategories.All;
				break;
		}

		const urlFilters = {
			status: tabName,
			userId: searchParams.get('userId') || '',
			dateType: searchParams.get('dateType') || '',
			dateRange: searchParams.get('dateRange') || '',
			saleStageId: searchParams.get('saleStageId') || '',
		};

		navigate(`/app/reports/follow-ups?${buildURLFilters(_.pickBy(urlFilters, _.identity))}`);
		setSelectedCategoryIndex(index);
	};

	return (
		<TabGroup index={selectedCategoryIndex} onChange={(index: any) => onTabChange(index)} className='w-full h-full overflow-x-hidden overflow-y-auto'>
			<div className='w-full top-0 inset-x-0 sticky shadow-sm flex justify-between items-center px-2 h-[48px] z-20 border-b bg-white'>
				<p className='hidden text-sm font-semibold lg:block '>{getNumberOfFollowUps(selectedCategoryIndex)} record(s)</p>

				<TabList variant='solid' className='space-x-0 border'>
					<Tab className='px-2'>
						All <span className='hidden sm:inline'>Follow Ups</span>
					</Tab>
					<Tab className='px-2'>Pending</Tab>
					<Tab className='px-2'>Completed</Tab>
					<Tab className='px-2'>Uncompleted</Tab>
				</TabList>
			</div>

			{/* Main Content */}
			<div className='p-2 overflow-y-auto h-[calc(100%-34px)] with-scrollbar'>
				{!followUps && isFollowUpsLoading ? (
					<div className=''>
						<ContentLoadingCardSpinner message='Loading follow ups...' />
					</div>
				) : followUps && followUps.length > 0 ? (
					<TabPanels>
						<TabPanel className='space-y-3'>
							<Table data={followUps} columns={tableColumns} />
						</TabPanel>
						<TabPanel className='space-y-3'>
							<Table data={pendingFollowUps} columns={tableColumns} />
						</TabPanel>
						<TabPanel className='space-y-3'>
							<Table data={completedFollowUps} columns={tableColumns} />
						</TabPanel>
						<TabPanel className='space-y-3'>
							<Table data={uncompletedFollowUps} columns={tableColumns} />
						</TabPanel>
					</TabPanels>
				) : (
					<SimpleEmptyResourceIndicator containerClassName='!mt-1' message='No follow up found' />
				)}
			</div>
		</TabGroup>
	);
};

export default FollowUpsReportTable;
