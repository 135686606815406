import { useQuery, RefetchOptions, RefetchQueryFilters, QueryObserverResult } from '@tanstack/react-query';
import { fetchReports } from 'features/reports/reportQueries';
import { IFetchReportActionParams, IReport } from './reportInterfaces';

interface ReturnType {
	reports: IReport | undefined;
	isReportsLoading: boolean;
	refetchReports: (options?: RefetchOptions & RefetchQueryFilters) => Promise<QueryObserverResult>;
}

interface Props {
	queryKey?: any;
	fetchParams?: IFetchReportActionParams;
	enabled?: boolean;
	onSuccess?: (reports: IReport[]) => void;
}

const useFetchReports = ({ fetchParams, queryKey, enabled, onSuccess }: Props): ReturnType => {
	const { filterBy, include } = fetchParams || {};

	const { data, isLoading, refetch } = useQuery({
		queryKey: [queryKey, 'reports/fetch-reports'],
		queryFn: () => fetchReports({ filterBy, include }),
		enabled,
		onSuccess: onSuccess ? (data) => onSuccess?.(data?.data) : undefined,
	});

	const reports: IReport | undefined = data?.data?.data;

	return { isReportsLoading: isLoading, reports, refetchReports: refetch };
};

export default useFetchReports;
