import { Badge, Button, DatePicker, Icon, Select, SelectItem, Subtitle, Text, Title } from '@tremor/react';
import useFetchUsers from 'features/settings/users/useFetchUsers';
import { Fragment, useState } from 'react';
import { HiUser } from 'react-icons/hi2';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import { IFetchVehicleAllocationsActionParams, IFetchVehicleAllocationsFilters, IVehicleAllocation } from '../navigateInterfaces';
import { formatDate } from 'helpers/dateHelpers';
import OutsideClickHandler from 'components/OutsideClickHandler';
import { CloseIcon } from 'components/FormActionIcons';
import { RiFilter2Fill } from 'react-icons/ri';
import { parseLocation } from 'utils/index';
import { Link } from 'react-router-dom';
import { VehicleRequestTypes } from './vehicleRequestsInterfaces';
import { hasPermission } from 'helpers/index';
import { PermissionKeys } from 'features/auth/permissions';

interface Props {
	vehicleAllocations: IVehicleAllocation[] | undefined;
	isVehicleAllocationsLoading: boolean;
	handleAllocationSelect: (allocation: IVehicleAllocation) => void;
	selectedAllocation: IVehicleAllocation | null;
	fetchParams: IFetchVehicleAllocationsActionParams;
	setFetchParams: (params: IFetchVehicleAllocationsActionParams) => void;
}

const RecentStaffLocations = (props: Props) => {
	const { vehicleAllocations, selectedAllocation, isVehicleAllocationsLoading, handleAllocationSelect, fetchParams, setFetchParams } = props;

	const [isFiltersOpen, setFiltersOpen] = useState<boolean>(false);
	const [filters, setFilters] = useState<IFetchVehicleAllocationsFilters>({
		userId: undefined as any,
		transportOfficerId: undefined as any,
		departureDate: undefined as any,
	});

	const { isUsersLoading, users } = useFetchUsers({
		queryKey: ['staff-locations'],
		enabled: hasPermission(PermissionKeys.VIEW_USER),
	});

	const activeAllocations = vehicleAllocations?.filter((allocation) => (allocation?.start_location ? true : false));

	const renderFilterStaffLocations = () => (
		<OutsideClickHandler onOutsideClick={() => setFiltersOpen(false)}>
			<div className={`absolute pb-2 inset-x-0 shadow-lg z-20 w-full rounded-b-xl border-b bg-white duration-300 ${isFiltersOpen ? 'top-[60px]' : '-top-[250px]'}`}>
				<div className='flex items-center justify-between gap-4 px-2 pb-1 mt-2 w-full'>
					<Subtitle className='!text-black font-semibold'>Filter Vehicle Allocations</Subtitle>

					<button title='Close Filters' type='button' onClick={() => setFiltersOpen(false)} className='h-7 w-7 rounded-lg bg-red-600 shrink-0 grow-0 text-white flex items-center justify-center'>
						<CloseIcon />
					</button>
				</div>

				<div className='px-2 pb-2 space-y-0.5 w-full'>
					<div className='w-full'>
						<label htmlFor='follow-up-date-filter' className='label'>
							Departure Date
						</label>

						<DatePicker
							id='follow-up-date-filter'
							className='!min-w-full'
							enableClear={false}
							enableYearNavigation
							value={filters.createdAt}
							onValueChange={(val) =>
								setFilters({
									...filters,
									createdAt: val,
								})
							}
						/>
					</div>

					<div className='w-full'>
						<label htmlFor='sale-stage-filter' className='label'>
							Staff
						</label>

						<Select onChange={(val) => setFilters({ ...filters, userId: val ? Number(val) : undefined })} value={filters.userId?.toString()} disabled={isUsersLoading}>
							{users && users.length > 0 ? (
								users?.map((user, index) => (
									<SelectItem key={index} value={user.id.toString()}>
										{user.first_name} {user.middle_name} {user.last_name}
									</SelectItem>
								))
							) : (
								<div className='py-2 px-2'>No staff</div>
							)}
						</Select>
					</div>

					<div className='flex items-center gap-2 pt-2'>
						<Button
							type='button'
							color='gray'
							onClick={() => {
								const newFilters = { userId: '', createdAt: null };
								setFilters(newFilters as any);
								setFetchParams({ ...fetchParams, filterBy: newFilters as any });
							}}
						>
							Clear Filters
						</Button>

						<Button
							type='button'
							color='green'
							className='flex-1'
							onClick={() => {
								setFetchParams({
									...fetchParams,
									filterBy: {
										...filters,
										departureDate: filters.createdAt ? formatDate(filters.createdAt, '1999-12-31') : '',
									},
								});
								setFiltersOpen(false);
							}}
						>
							Apply Filters
						</Button>
					</div>
				</div>
			</div>
		</OutsideClickHandler>
	);

	const renderAllocations = (vehicleAllocations: IVehicleAllocation[]) =>
		vehicleAllocations.map((allocation, index) => {
			const { vehicle_request, start_location, end_location, updated_at, transport_officer } = allocation;

			const endLocation = end_location ? parseLocation(end_location) : null;
			const startLocation = start_location ? parseLocation(start_location) : null;
			const lastLocationUrl = endLocation?.url || startLocation?.url || null;
			const lastLocationName = end_location ? parseLocation(end_location)?.name || 'N/A' : start_location ? parseLocation(start_location)?.name || 'N/A' : 'No Location Specified';

			return (
				<div
					onClick={() => handleAllocationSelect(allocation)}
					className={`group duration-300 hover:cursor-pointer flex items-start gap-4 p-4 ${selectedAllocation?.id === allocation.id ? 'bg-primary-100' : 'bg-white hover:bg-primary-50'}`}
					key={index}
				>
					<Icon icon={HiUser} size='md' variant='shadow' className='!rounded-full group-hover:!text-gray-500' />

					<div>
						<Title className='!text-base'>
							{vehicle_request?.user?.first_name} {vehicle_request?.user?.middle_name} {vehicle_request?.user?.last_name}
						</Title>

						<div>
							<label className='text-[10px] uppercase text-gray-500'>Last Location</label>
							{lastLocationUrl ? <p className='sm:w-[100px] lg:w-[150px] sm:line-clamp-2 sm:text-ellipsis capitalize'>{lastLocationName}</p> : <Text className='!font-medium'>{lastLocationName}</Text>}
						</div>

						<div>
							<label className='text-[10px] uppercase text-gray-500'>Transport Officer</label>
							<Text className='!font-medium'>
								{transport_officer?.first_name} {transport_officer?.middle_name} {transport_officer?.last_name}
							</Text>
						</div>

						<div>
							<label className='text-[10px] uppercase text-gray-500'>Time Recorded</label>
							<Text className='!font-medium'>{formatDate(updated_at, 'Mon, 31st Jan 1999 at 12:00AM')}</Text>
						</div>

						<div className='mt-1'>
							{vehicle_request.request_type === VehicleRequestTypes.Prospecting ? (
								<Badge className='font-semibold bg-primary-500 text-white'>Prospecting</Badge>
							) : (
								<Badge color={'gray'} className='font-semibold !bg-gray-600 text-white'>
									General
								</Badge>
							)}
						</div>
					</div>
				</div>
			);
		});

	return (
		<div className='w-full bg-white border-l'>
			<div className='relative border-t w-full h-[100%]'>
				<div className='h-[60px] bg-white sticky z-30 top-[0px] lg:px-2 shadow-sm inset-x-0 px-2 flex items-center justify-between gap-2 border-b justify-col'>
					<Title>Staff Locations</Title>

					<Button color='gray' variant='secondary' size='xs' onClick={() => setFiltersOpen(true)} icon={RiFilter2Fill} className='!rounded-full hover:!bg-black hover:!text-gray-100 duration-300'>
						Filters
					</Button>
				</div>

				<Fragment>{renderFilterStaffLocations()}</Fragment>

				<div className='min-h-[calc(100vh-var(--appbar-height-desktop)-var(--app-navigation-height)-60px)] bg-white overflow-y-auto with-scrollbar w-full overflow-x-clip'>
					{!activeAllocations && isVehicleAllocationsLoading ? (
						<div className='p-2'>
							<ContentLoadingCardSpinner message='Loading staff locations...' />
						</div>
					) : activeAllocations && activeAllocations.length > 0 ? (
						<div className='divide-y'>{renderAllocations(activeAllocations)}</div>
					) : (
						<div className='p-2'>
							<SimpleEmptyResourceIndicator containerClassName='!mt-1' message='No trip found' />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default RecentStaffLocations;
