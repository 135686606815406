import { Button, NumberInput, TextInput, Title } from '@tremor/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { Select, SelectItem } from '@tremor/react';
import toast from 'react-hot-toast';
import { processReactHookFormErrors } from 'helpers';
import { ICreateUserFormValues, IUser, UserAccountState } from 'features/auth/authInterfaces';
import { createUser, updateUser } from 'features/auth/authQueries';
import useFetchDepartments from '../departments/useFetchDepartments';
import useFetchBranches from '../branches/useFetchBranches';
import useFetchRoles from '../authorization/useFetchUserRoles';
import { Switch } from '@headlessui/react';
import { HiOutlineCheck } from 'react-icons/hi2';

interface Props {
	user: IUser | null;
	refetchUsers: () => void;
	setCurrentUser: (value: IUser | null) => void;
	setEditUserFormOpen: (value: boolean) => void;
}

const EditUserForm = (props: Props) => {
	const { user, setCurrentUser, setEditUserFormOpen, refetchUsers } = props;

	const { departments, isDepartmentsLoading } = useFetchDepartments({});
	const { branches, isBranchesLoading } = useFetchBranches({});
	const { roles, isRolesLoading } = useFetchRoles({});

	const [selectFields, setSelectedFields] = useState({
		status: user ? user.status : UserAccountState.Enabled,
		role: user?.roles[0],
		branch_id: user?.branch.id.toString(),
		department_id: user?.department.id.toString(),
	});

	const {
		register,
		handleSubmit,
		setError,
		setValue,
		getValues,
		clearErrors,
		formState: { errors },
	} = useForm<ICreateUserFormValues>({
		defaultValues: {
			first_name: user ? user?.first_name : undefined,
			middle_name: user ? user?.middle_name : undefined,
			last_name: user ? user?.last_name : undefined,
			email: user ? user?.email : undefined,
			branch_id: user ? user?.branch.id : undefined,
			department_id: user ? user?.department.id : undefined,
		},
		mode: 'onChange',
	});

	// ============================
	// * Create User
	// ============================
	const { isLoading: isCreateUserLoading, mutate: createUserMutation } = useMutation({
		mutationKey: ['authorization/create-user'],
		mutationFn: (params: ICreateUserFormValues) => createUser(params),
	});

	// ============================
	// * Update User
	// ============================
	const { isLoading: isUpdateUserLoading, mutate: updateUserMutation } = useMutation({
		mutationKey: ['authorization/update-user'],
		mutationFn: ({ formValues, userId }: { formValues: ICreateUserFormValues; userId: number }) => updateUser({ formValues, userId }),
	});

	const onSubmit = (formValues: ICreateUserFormValues) => {
		if (!getValues('branch_id'))
			return setError('branch_id', {
				message: 'Please select a branch',
			});
		if (!getValues('department_id'))
			return setError('department_id', {
				message: 'Please select a department',
			});

		if (user)
			return updateUserMutation(
				{ formValues, userId: user.id },
				{
					onSuccess: () => {
						toast.success('User account updated successfully!');
						setTimeout(() => setCurrentUser(null), 500);
						setEditUserFormOpen(false);
						refetchUsers();
					},
					onError: (error) => {
						processReactHookFormErrors(error, 'Failed to update user', setError);
					},
				}
			);

		createUserMutation(formValues, {
			onSuccess: () => {
				toast.success('User account created successfully!');
				setEditUserFormOpen(false);
				refetchUsers();
			},
			onError: (error) => {
				processReactHookFormErrors(error, 'Failed to create user account', setError);
			},
		});
	};

	return (
		<form className='bg-white' onSubmit={handleSubmit(onSubmit)}>
			<header className={`${user ? 'bg-gradient-to-r from-gray-800 to-gray-500' : 'bg-gradient-to-r from-primary-600 to-primary-300'} bg-dark p-6 h-[80px] flex items-center gap-2`}>
				<Title className='text-white'>{user ? 'Update' : 'Create'} User</Title>
			</header>

			<section className='p-6 space-y-3 text-left'>
				<div className='grid sm:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-2'>
					<div className='form-control'>
						<label htmlFor='first_name' className='required-field-indicator'>
							First Name
						</label>

						<div>
							<TextInput
								type='text'
								id='first_name'
								{...register('first_name', {
									required: {
										value: true,
										message: 'First name of user is required',
									},
								})}
								placeholder='Enter first name'
								error={errors.first_name ? true : false}
								errorMessage={errors.first_name?.message}
							/>
						</div>
					</div>

					<div className='form-control'>
						<label htmlFor='middle_name'>Middle Name</label>

						<div>
							<TextInput
								type='text'
								id='middle_name'
								{...register('middle_name')}
								placeholder='Enter middle name'
								error={errors.middle_name ? true : false}
								errorMessage={errors.middle_name?.message}
							/>
						</div>
					</div>

					<div className='form-control sm:col-span-2 lg:col-span-1'>
						<label htmlFor='last_name' className='required-field-indicator'>
							Last Name
						</label>

						<div>
							<TextInput
								type='text'
								id='last_name'
								{...register('last_name', {
									required: {
										value: true,
										message: 'Last name of user is required',
									},
								})}
								placeholder='Enter last name'
								error={errors.last_name ? true : false}
								errorMessage={errors.last_name?.message}
							/>
						</div>
					</div>
				</div>

				<div className='form-control'>
					<label htmlFor='email' className='required-field-indicator'>
						Email
					</label>

					<div>
						<TextInput
							type='text'
							id='email'
							{...register('email', {
								required: {
									value: true,
									message: 'Email of user is required',
								},
								pattern: {
									value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
									message: 'Invalid email.',
								},
							})}
							placeholder='Enter user email'
							error={errors.email ? true : false}
							errorMessage={errors.email?.message}
						/>
					</div>
				</div>

				<div className='grid sm:grid-cols-2 gap-x-4 gap-y-2'>
					<div className='form-control'>
						<label htmlFor='branch' className='required-field-indicator'>
							Branch
						</label>

						<div>
							<Select
								value={selectFields.branch_id?.toString()}
								onValueChange={(val) => {
									setValue('branch_id', Number(val));
									setSelectedFields({
										...selectFields,
										branch_id: val,
									});
									if (errors.branch_id) clearErrors('branch_id');
								}}
								id='branch'
								placeholder='Select branch'
								disabled={isBranchesLoading}
							>
								{branches && branches.length > 0 ? (
									branches?.map((branch, index) => (
										<SelectItem key={index} value={branch.id.toString()}>
											{branch.name}
										</SelectItem>
									))
								) : (
									<div></div>
								)}
							</Select>
							{errors?.branch_id && <span className='form-message'>{errors?.branch_id?.message}</span>}
						</div>
					</div>

					<div className='form-control'>
						<label htmlFor='department' className='required-field-indicator'>
							Department
						</label>

						<div>
							<Select
								value={selectFields.department_id?.toString()}
								onValueChange={(val) => {
									setValue('department_id', Number(val));
									setSelectedFields({
										...selectFields,
										department_id: val,
									});
									if (errors.department_id) clearErrors('department_id');
								}}
								id='department'
								placeholder='Select department'
								disabled={isDepartmentsLoading}
							>
								{departments && departments.length > 0 ? departments?.map((department) => <SelectItem value={department.id.toString()}>{department.name}</SelectItem>) : <div></div>}
							</Select>

							{errors?.department_id && <span className='form-message'>{errors?.department_id?.message}</span>}
						</div>
					</div>
				</div>

				{user && (
					<div className='items-center justify-between space-y-2 form-control sm:flex'>
						<div className='grid'>
							<label htmlFor='role' className='required-field-indicator'>
								Block
							</label>
							<span className='text-xs text-gray-500'>Toggle to block or activate user account</span>
						</div>

						<div className='flex items-center justify-center gap-2 px-4 py-2 border rounded-lg w-fit'>
							<span>Blocked</span>

							<Switch
								checked={selectFields.status === UserAccountState.Enabled ? true : false}
								disabled={user ? false : true}
								onChange={(value: boolean) => {
									const selectedOption = value === true ? UserAccountState.Enabled : UserAccountState.Disabled;
									setValue('status', selectedOption);
									setSelectedFields({
										...selectFields,
										status: selectedOption,
									});
									if (errors.status) clearErrors('status');
								}}
								className={`${selectFields.status === UserAccountState.Enabled ? 'bg-green-600' : 'bg-zinc-400'}
            relative inline-flex h-[32px] w-[54px] shrink-0 cursor-pointer rounded-full border border-transparent text-sm transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
							>
								<span
									className={`${selectFields.status === UserAccountState.Enabled ? 'translate-x-[24px] text-green-600' : 'translate-x-[2px]'}
                pointer-events-none inline-flex h-[27px] w-[27px] translate-y-[1.5px] transform items-center justify-center rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
								>
									{selectFields.status === UserAccountState.Enabled && <HiOutlineCheck className='w-5 h-5' />}
								</span>
							</Switch>
							<span>Active</span>
						</div>
					</div>
				)}

				<div className='form-control'>
					<label htmlFor='role' className='required-field-indicator'>
						Role
					</label>

					<div>
						<Select
							id='role'
							value={selectFields.role}
							onValueChange={(val) => {
								setValue('role', val);
								setSelectedFields({
									...selectFields,
									role: val,
								});
								if (errors.role) clearErrors('role');
							}}
							placeholder='Select role'
							disabled={isRolesLoading}
						>
							{roles && roles.length > 0 ? roles?.map((role) => <SelectItem value={role.name.toString()}>{role.name}</SelectItem>) : <div></div>}
						</Select>
						{errors?.role && <span className='form-message'>{errors?.role?.message}</span>}
					</div>
				</div>

				{!user && (
					<div className='form-control'>
						<label htmlFor='yearly_target' className='required-field-indicator'>
							Yearly Target
						</label>

						<div>
							<NumberInput
								id='yearly_target'
								{...register('yearly_target', {
									required: {
										value: true,
										message: 'Target of user is required',
									},
								})}
								min={0}
								defaultValue={0}
								placeholder="Enter user's target"
								error={errors.yearly_target ? true : false}
								errorMessage={errors.yearly_target?.message}
							/>
						</div>
					</div>
				)}
			</section>

			<footer className='flex items-center justify-end gap-2 p-6 bg-gray-100 border-t'>
				<Button
					onClick={() => {
						setEditUserFormOpen(false);
						setTimeout(() => setCurrentUser(null), 500);
					}}
					type='button'
					color='gray'
					variant='secondary'
					className='px-6 py-3 duration-300 !rounded-full'
				>
					Cancel
				</Button>

				<Button
					type='submit'
					loading={isCreateUserLoading || isUpdateUserLoading}
					loadingText={user ? 'Updating' : 'Creating'}
					disabled={isCreateUserLoading || isUpdateUserLoading}
					color={user ? 'gray' : undefined}
					className='px-6 py-3 duration-300 !rounded-full'
				>
					{user ? 'Update User' : 'Create User'}
				</Button>
			</footer>
		</form>
	);
};

export default EditUserForm;
