import { Fragment, useState, ReactNode } from 'react';
import { Badge, Button, Callout, Card, Title } from '@tremor/react';
import Container from 'components/Container';
import Filters from 'components/Filters';
import { FilterComponentTypes } from 'features/settings/settingsInterfaces';
import useDefaultFilters from 'hooks/useDefaultFilters';
import { geoFindMe, parseLocation } from 'utils/index';
import useFetchVehicleAllocations from './useFetchVehicleAllocations';
import ContentLoadingCardSpinner from 'components/ContentLoadingCardSpinner';
import Table from 'components/Table';
import SimpleEmptyResourceIndicator from 'components/SimpleEmptyResourceIndicator';
import { createColumnHelper } from '@tanstack/react-table';
import { IFetchVehicleAllocationsFilters, IVehicleAllocation } from '../navigateInterfaces';
import { authState } from 'features/auth/authSlice';
import { useAppSelector } from 'hooks/storeConnect';
import BottomSheet from 'components/BottomSheet';
import { RiFilter2Fill } from 'react-icons/ri';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { ConfirmationDialogIcon } from 'components/FormActionIcons';
import { MODAL_LEAVE_SPEED } from 'config';
import { formatDate } from 'helpers/dateHelpers';
import { useMutation } from '@tanstack/react-query';
import { updateTripLocation } from './vehicleAllocationQueries';
import { IUpdateTripLocationActionParams } from './vehicleAllocationInterfaces';
import toast from 'react-hot-toast';
import { IoHandLeft } from 'react-icons/io5';
import withMap, { MapComponentProps } from 'components/withMap';
import { Link } from 'react-router-dom';
import { loyaltyInsuranceHeadOfficeLocationInfo } from 'features/prospects/prospectsSlice';
import { hasPermission } from 'helpers/index';
import { PermissionKeys } from 'features/auth/permissions';
import { HiExclamation } from 'react-icons/hi';

interface IVehicleAllocationTableColumns extends IVehicleAllocation {
	action: ReactNode;
	status: any;
}
const columnHelper = createColumnHelper<IVehicleAllocationTableColumns>();

interface Props extends MapComponentProps {}

const TransportOfficerVehicleAllocationsPage = (props: Props) => {
	const { getPlaceInfoFromCoordinate } = props;

	const { authUser } = useAppSelector(authState);
	const [fetchFilters, setFetchFilters] = useState<IFetchVehicleAllocationsFilters>({
		transportOfficerId: authUser?.id,
	});
	const [isMobileFiltersOpen, setMobileFiltersOpen] = useState<boolean>(false);
	const [isConfirmStartTripDialog, setConfirmStartTripDialog] = useState<boolean>(false);
	const [isConfirmEndTripDialog, setConfirmEndTripDialog] = useState<boolean>(false);
	const [currentTrip, setCurrentTrip] = useState<IVehicleAllocation | null>(null);

	const { isVehicleAllocationsLoading, vehicleAllocations, refetchVehicleAllocations } = useFetchVehicleAllocations({
		fetchParams: {
			filterBy: fetchFilters,
			includes: ['vehicle', 'vehicle_request', 'vehicle_request.user'],
		},
	});

	// ================
	// * Update Trip
	// ================
	const { isLoading: isUpdateTripMutationLoading, mutateAsync: updateTripMutationAsync } = useMutation({
		mutationKey: ['vehicle-requests/update-vehicleRequestStatus'],
		mutationFn: (formValues: { tripLocations: IUpdateTripLocationActionParams; vehicleRequestId: number }) => updateTripLocation(formValues.tripLocations, formValues.vehicleRequestId),
	});

	const onSubmitFilters = (filters: IFetchVehicleAllocationsFilters) => {
		setFetchFilters({
			...fetchFilters,
			departureDate: filters.departureDate ? formatDate(filters?.departureDate as any, '1999-12-31') : undefined,
		});
	};

	const tableColumns = [
		columnHelper.accessor('vehicle_request.user', {
			header: 'Staff',
			cell: ({ row }) => {
				const { first_name, middle_name, last_name } = row.original.vehicle_request.user;

				return (
					<div className='sm:font-semibold'>
						{first_name} {middle_name} {last_name}
					</div>
				);
			},
		}),
		columnHelper.accessor('vehicle_request.destination', {
			header: 'Destination',
			cell: ({ row }) => {
				const destination = row.original.vehicle_request.destination;
				const { url, name } = parseLocation(destination);

				return url ? (
					<Link title='Open in google map' to={url} target='_blank' className='hover:text-blue-600 hover:underline sm:w-[100px] lg:w-[150px] sm:line-clamp-2 sm:text-ellipsis capitalize'>
						{name}
					</Link>
				) : (
					name || 'No location available'
				);
			},
		}),
		columnHelper.accessor('vehicle_request.departure_date', {
			header: 'Departure',
		}),
		columnHelper.accessor('vehicle', {
			header: 'Vehicle',
			cell: ({ row }) => {
				const { vehicle } = row.original;

				return (
					<div className='font-medium'>
						{vehicle.model} ({vehicle.number})
					</div>
				);
			},
		}),
		columnHelper.accessor('status', {
			header: 'Trip Status',
			cell: ({ row }) => {
				const { start_location, end_location } = row.original;

				const isActive = start_location && !end_location;
				const isCompleted = start_location && end_location;

				return (
					<Badge size='xl' className={`text-white ${isActive ? 'bg-yellow-500' : isCompleted ? 'bg-green-500' : 'bg-gray-700'}`}>
						{isActive ? 'Active' : isCompleted ? 'Completed' : 'Pending'}
					</Badge>
				);
			},
		}),
		columnHelper.accessor('created_at', {
			header: 'Date Created',
			cell: ({ row }) => formatDate(row.original.created_at, 'Mon, 31st Jan 1999 at 12:00AM'),
		}),
		columnHelper.accessor('action', {
			header: 'Action',
			cell: ({ row }) => {
				const { start_location, end_location } = row.original;

				return (
					<div className='flex items-center gap-2'>
						{hasPermission(PermissionKeys.UPDATE_VEHICLE_ALLOCATION) ? (
							<Fragment>
								<Button
									color='green'
									disabled={start_location}
									onClick={() => {
										setCurrentTrip(row.original);
										setConfirmStartTripDialog(true);
									}}
									className='!rounded-full !px-5'
								>
									Start
								</Button>
								<Button
									color='rose'
									disabled={end_location || !start_location}
									onClick={() => {
										setCurrentTrip(row.original);
										setConfirmEndTripDialog(true);
									}}
									className='!rounded-full !px-5'
								>
									End
								</Button>
							</Fragment>
						) : (
							<Callout title='Not authorized to perform action' icon={HiExclamation} color='yellow' />
						)}
					</div>
				);
			},
		}),
	];

	const onConfirmStartTripDialogOpen = async () => {
		if (!currentTrip) return toast.error('No trip selected for this action');

		// try {
		// 	const coordinate = await geoFindMe();

		// 	getPlaceInfoFromCoordinate?.(coordinate, (placeInfo) => {
		// 		const { geometry } = placeInfo;
		// 		const { lat, lng } = geometry?.location || {};

		// 		console.log({ lat, lng });

		// 		toast.promise(
		// 			updateTripMutationAsync({
		// 				vehicleRequestId: currentTrip.id,
		// 				tripLocations: {
		// 					start_location: JSON.stringify({
		// 						coordinate: { lat: lat?.(), lng: lng?.() },
		// 						url: `https://www.google.com/maps/place/Loyalty+Insurance+Company+Limited/@5.6317787,-0.246421,17z/data=!3m1!4b1!4m6!3m5!1s0xfdf9949ad475a39:0xd02a43aef869c0a9!8m2!3d5.6317734!4d-0.2438461!16s%2Fg%2F11fmddwgq7?entry=ttu`,
		// 						name: `Loyalty Insurance Company Limited`,
		// 					}),
		// 				},
		// 			}),
		// 			{
		// 				loading: 'Starting trip...',
		// 				success: () => {
		// 					return 'Trip started successfully';
		// 				},
		// 				error: () => {
		// 					return 'Failed to start trip.';
		// 				},
		// 			}
		// 		);

		// 		setConfirmStartTripDialog(false);
		// 	});
		// } catch (error: any) {
		// 	toast.error(error.message ?? 'Unable to retrieve your location', { id: 'reverse-geocoder-error' });
		// }

		// const pos = await new Promise((resolve, reject) => {
		// 	navigator.geolocation.getCurrentPosition(resolve, reject);
		// });

		toast.promise(
			updateTripMutationAsync({
				vehicleRequestId: currentTrip.id,
				tripLocations: {
					start_location: JSON.stringify(loyaltyInsuranceHeadOfficeLocationInfo),
				},
			}),
			{
				loading: 'Starting trip...',
				success: () => {
					refetchVehicleAllocations();
					return 'Trip started successfully';
				},
				error: () => {
					return 'Failed to start trip.';
				},
			}
		);

		setConfirmStartTripDialog(false);
	};

	const onConfirmEndTripDialogOpen = () => {
		if (!currentTrip) return toast.error('No trip selected for this action');

		toast.promise(
			updateTripMutationAsync({
				vehicleRequestId: currentTrip.id,
				tripLocations: {
					end_location: JSON.stringify({
						coordinate: { lat: 5.6422801, lng: -0.1483413 },
						url: `https://maps.app.goo.gl/KcGPYb8XCeEeCPKc9`,
						name: `Loyalty Insurance, Accra`,
					}),
				},
			}),
			{
				loading: 'Ending trip...',
				success: () => {
					refetchVehicleAllocations();
					return 'Trip ended successfully';
				},
				error: () => {
					return 'Failed to end trip.';
				},
			}
		);

		setConfirmEndTripDialog(false);
	};

	return (
		<Fragment>
			<Container className='p-4 !h-[100%]'>
				<div className='flex items-center justify-between'>
					<Title className='!text-2xl'>Assigned Vehicle Allocations</Title>

					<Button
						color='gray'
						variant='secondary'
						onClick={() => setMobileFiltersOpen(true)}
						icon={RiFilter2Fill}
						className='!rounded-full hover:bg-gray-700 hover:!text-gray-100 duration-300 lg:hidden !mt-4'
					>
						Filters
					</Button>

					<div className='hidden lg:flex lg:items-end'>
						<Filters
							filters={[
								{
									type: FilterComponentTypes.Date,
									name: 'departureDate',
									label: 'Departure Date',
									placeholder: 'Select date',
									className: 'min-w-[200px]',
									hideLabel: true,
								},
							]}
							submitButton={{
								label: 'Apply Filters',
								onSubmit: onSubmitFilters,
							}}
						/>
					</div>
				</div>

				<div>
					<Fragment>
						{!vehicleAllocations && isVehicleAllocationsLoading ? (
							<div className='mt-6'>
								<ContentLoadingCardSpinner message='Loading trips...' />
							</div>
						) : vehicleAllocations && vehicleAllocations.length > 0 ? (
							<div className='mt-8'>
								<Card className='!p-0 pt-6 overflow-hidden'>
									<Title className='px-4 py-4 border-b'>Your Assigned Requests</Title>

									<Table data={vehicleAllocations} columns={tableColumns} useInbuiltPagination />
								</Card>
							</div>
						) : (
							<SimpleEmptyResourceIndicator containerClassName='lg:mt-8 lg:!py-20' message='No trip found' />
						)}
					</Fragment>
				</div>

				<BottomSheet
					isOpen={isMobileFiltersOpen}
					setOpen={setMobileFiltersOpen}
					closeOnOutSideClick
					header={{
						text: 'Filter Vehicle Allocations',
					}}
				>
					<Filters
						filters={[
							{
								type: FilterComponentTypes.Date,
								label: 'Departure Date',
								name: 'departureDate',
								placeholder: 'Select date',
							},
						]}
						submitButton={{
							label: 'Apply Filters',
							onSubmit: onSubmitFilters,
						}}
					/>
				</BottomSheet>
			</Container>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmStartTripDialog}
				setOpen={setConfirmStartTripDialog}
				message='Start Vehicle Request'
				subMessage={`Are you sure you want to start trip scheduled at ${formatDate(currentTrip?.vehicle_request.departure_date, 'Mon, 31st Jan 1999 at 12:00AM')}. This action cannot be reversed.`}
				onClose={() => {
					setTimeout(() => setCurrentTrip(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmStartTripDialogOpen,
					label: 'Start Now',
					isLoading: isUpdateTripMutationLoading,
					loadingText: 'Starting...',
					color: 'green',
				}}
				cancelButton={{
					action: () => setConfirmStartTripDialog(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-500/10`}>
						<ConfirmationDialogIcon className='text-green-500' />
					</div>
				)}
			/>

			<ConfirmationDialog
				isPortrait
				isOpen={isConfirmEndTripDialog}
				setOpen={setConfirmEndTripDialog}
				message='End Trip'
				subMessage={`Are you sure you want to end trip ${formatDate(currentTrip?.vehicle_request.departure_date, 'Mon, 31st Jan 1999 at 12:00AM')}. This action cannot be reversed.`}
				onClose={() => {
					setTimeout(() => setCurrentTrip(null), MODAL_LEAVE_SPEED);
				}}
				confirmButton={{
					action: onConfirmEndTripDialogOpen,
					label: 'End Now',
					isLoading: isUpdateTripMutationLoading,
					loadingText: 'Ending...',
					color: 'rose',
				}}
				cancelButton={{
					action: () => setConfirmEndTripDialog(false),
				}}
				renderIcon={() => (
					<div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-rose-500/10`}>
						<IoHandLeft className='w-6 h-6 text-rose-500' />
					</div>
				)}
			/>
		</Fragment>
	);
};

export default withMap(TransportOfficerVehicleAllocationsPage);
